import { useEvent } from "effector-react/scope";

import { paymentManagement } from "@/widgets/payment";

import { useIsUserAuthenticated } from "@/entities/user";

import { Breakpoint } from "@/shared/lib/responsive";
import { ButtonRounded, Image, Typography } from "@/shared/ui";
import BigmobileBackground from "@/shared/ui/assets/images/zvuk-landing/community-bigmobile.webp";
import DesktopBackground from "@/shared/ui/assets/images/zvuk-landing/community-desktop.webp";
import MobileBackground from "@/shared/ui/assets/images/zvuk-landing/community-mobile.webp";
import TabletBackground from "@/shared/ui/assets/images/zvuk-landing/community-tablet.webp";

import { subscribeButtonClicked } from "../analytics";

export const JoinCommunity = () => {
  const handlers = useEvent({
    paymentModalOpened: paymentManagement.paymentModalOpened,
    subscribeButtonClicked,
  });
  const isAuthenticated = useIsUserAuthenticated();

  const handleClick = () => {
    handlers.paymentModalOpened(paymentManagement.PaymentModalType.Subscription);
    handlers.subscribeButtonClicked("Зарегистрироваться");
  };

  if (isAuthenticated) return null;

  return (
    <div className="relative min-h-[230px] md:min-h-[312px] xl:min-h-[520px] 3xl:min-h-[832px]">
      <Image
        lazy
        src={DesktopBackground}
        screens={[
          {
            breakpoint: Breakpoint.S,
            src: MobileBackground,
          },
          {
            breakpoint: Breakpoint.M,
            src: BigmobileBackground,
          },
          {
            breakpoint: Breakpoint.XL,
            src: TabletBackground,
          },
          {
            breakpoint: Breakpoint.XXL,
            src: DesktopBackground,
          },
        ]}
        width={1920}
        height={780}
        className="min-h-[230px] md:min-h-[312px] xl:min-h-[520px] 3xl:min-h-[832px] object-cover w-full"
        alt="присоединяйся к музе и создавай новые миры вместе с нами"
      />
      <div className="absolute block top-mn-26 left-0 px-mn-26 xl:top-mn-50 w-full">
        <Typography
          tag="h3"
          size="m-h3"
          className="w-full md:w-[550px] lg:w-[800px] 2xl:w-[1220px] uppercase font-m-sb-display font-semibold block text-m-white mb-mn-20 xl:mb-mn-38"
        >
          присоединяйтесь к&nbsp;музе и&nbsp;создавайте новые миры вместе с&nbsp;нами
        </Typography>
        <ButtonRounded
          variant="tertiary"
          onClick={handleClick}
          className="!text-m-white !border-m-white w-[calc(100%-26px)] xl:h-[50px] 3xl:h-[50px] xl:text-m-lg md:w-auto md:hover:!border-m-orange"
        >
          Зарегистрироваться
        </ButtonRounded>
      </div>
    </div>
  );
};
