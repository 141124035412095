import { attach, createEvent, sample } from "effector";

import { paths } from "@/pages/paths";

import { subscriptionFormSubmitted } from "@/widgets/section-b2b/model/b2b";

import { createAnalyticsEvent } from "@/shared/analytics/model/analytics";
import { getCourseByIdFx } from "@/shared/api/modules/course";

enum LANDING_EVENTS {
  TOPIC_CLICKED = "click_menu",
  SUBSCRIBE_CLICKED = "click_subscribe",
  LEAD_BLOCK_CLICKED = "open_contact_cert",
  LEAD_FORM_SUBMITTED = "send_contact_cert",
  TEST_CLICKED = "click_banner",
  COURSES_CLICKED = "click_course_catalog",
  COURSE_CLICKED = "click_course",
  B2B_FORM_SUBMITTED = "send_contact_b2b",
}

export const topicButtonClicked = createAnalyticsEvent<string>((topic) => ({
  event_name: LANDING_EVENTS.TOPIC_CLICKED,
  link_type: "main",
  link_text: topic,
}));

export const subscribeButtonClicked = createAnalyticsEvent<string>((text) => ({
  event_name: LANDING_EVENTS.SUBSCRIBE_CLICKED,
  link_type: "main",
  link_text: text,
}));

export const leadBlockClicked = createAnalyticsEvent<string>((text) => ({
  event_name: LANDING_EVENTS.LEAD_BLOCK_CLICKED,
  link_type: "main",
  link_text: text,
}));

export const leadFormSubmitted = createAnalyticsEvent<string>((text) => ({
  event_name: LANDING_EVENTS.LEAD_FORM_SUBMITTED,
  link_type: "main",
  link_text: text,
}));

export const testClicked = createAnalyticsEvent(() => ({
  event_name: LANDING_EVENTS.TEST_CLICKED,
  link_type: "main",
  link_text: "Пройти тест",
}));

export const courseListClicked = createAnalyticsEvent(() => ({
  event_name: LANDING_EVENTS.TEST_CLICKED,
  link_type: "main",
  link_text: "К курсам",
  link_url: `${window.location.origin}${paths.catalog}`,
}));

export const courseClicked = createEvent<string>();
const getCourseDataFx = attach({ effect: getCourseByIdFx });

export const courseDataLoaded = createAnalyticsEvent<{
  course_id: string;
  course_name: string;
  price: number;
  link_url: string;
  course_format: string;
}>(({ course_id, course_name, price, link_url, course_format }) => ({
  course_id,
  event_name: LANDING_EVENTS.COURSE_CLICKED,
  course_format,
  link_type: "main",
  course_name,
  price,
  link_url,
  link_text: "К курсу",
}));

sample({
  clock: courseClicked,
  fn: (courseId) => ({ courseId }),
  target: getCourseDataFx,
});

sample({
  source: getCourseDataFx.doneData,
  fn: ({ id, courseFormat, price, title }) => ({
    course_id: id,
    course_name: title,
    price,
    course_format: courseFormat || "",
    link_url: `${window.location.origin}${paths.courseView(id)}`,
  }),
  target: courseDataLoaded,
});

export const B2BFormSubmitted = createAnalyticsEvent<string>((text) => ({
  event_name: LANDING_EVENTS.B2B_FORM_SUBMITTED,
  link_type: "main",
  link_text: text,
}));

sample({
  clock: subscriptionFormSubmitted,
  fn: () => "Отправить заявку",
  target: B2BFormSubmitted,
});
