import { createAnalyticsEvent } from "@/shared/analytics/model/analytics";

enum JAZZ_EVENTES {
  COURSE_CLICKED = "click_course",
  COURSES_ARROWS = "view_course_compilation",
  EXTENAL_CLICK = "click_external_link",
}

export const courseClickedSendAnalytics = createAnalyticsEvent<{
  course_id: string;
  course_name: string;
  link_type?: string;
}>(({ course_id, course_name, link_type }) => ({
  course_id,
  event_name: JAZZ_EVENTES.COURSE_CLICKED,
  format_course: "online_course",
  course_name,
  link_type: link_type ?? "top",
  link_text: "К курсу",
}));

export const arrowClickedSendAnalytics = createAnalyticsEvent(() => ({
  event_name: JAZZ_EVENTES.COURSES_ARROWS,
}));

export const jazz100ClickedSendAnalytics = createAnalyticsEvent(() => ({
  event_name: JAZZ_EVENTES.EXTENAL_CLICK,
  link_text: "100 лет российскому джазу",
  link_url: "https://jazz100.ru/",
  link_type: "top",
}));

export const generalPartnerClickedSendAnalytics = createAnalyticsEvent(() => ({
  event_name: JAZZ_EVENTES.EXTENAL_CLICK,
  link_text: "Генеральный партнер",
  link_url: "https://ibmg.ru/",
  link_type: "footer",
}));
