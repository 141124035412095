import { useEvent } from "effector-react/scope";
import { FC } from "react";

import { Modal } from "@/shared/ui";

import { clickedSupportContact } from "../analytics";

export const SupportContacts: FC<{ onClose: () => void }> = ({ onClose }) => {
  const openFeedback = useEvent(clickedSupportContact);
  return (
    <Modal.Surface>
      <Modal.Header close={onClose} />
      <Modal.Title>
        Напишите <br />
        службе поддержки
      </Modal.Title>
      <Modal.Body className="px-[18px]">
        <p className="pt-1 pb-mn-20 3xl:pb-mn-26 text-white text-center m-typography-modal-input">
          <span className="text-center block">
            На почту
            <a
              className="ml-1 text-m-orange underline"
              href="mailto:muse-help@edutoria.ru"
              onClick={openFeedback}
            >
              muse-help@edutoria.ru
            </a>
          </span>
          Мы постараемся вам помочь
        </p>
      </Modal.Body>
    </Modal.Surface>
  );
};
