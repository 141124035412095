import { RouteConfig } from "react-router-config";

import { CatalogPage } from "./catalog";
import { CourseFeedbacksViewPage } from "./course-feedbacks-view";
import { CourseViewPage } from "./course-view";
import { Error404Page } from "./error404";
import { HomePage } from "./home";
import { Jazz100Page } from "./jazz100";
import { paths } from "./paths";
import { PaymentSuccess } from "./payment-success";
import { SearchPage } from "./search";
import { SpringPage } from "./spring";
import { StudentCoursesPage } from "./student-courses";
import { StudentProfilePage } from "./student-profile";
import { SubscriptionsPage } from "./subscriptions";
import { TheatrePage } from "./theatre";

export type RouteConfigParams = RouteConfig & { private?: boolean } & { hidden?: true };

export const routes: RouteConfigParams[] = [
  { exact: true, path: paths.home(), component: HomePage },
  { exact: true, path: paths.catalog(), component: CatalogPage },
  { exact: true, path: paths.courseView(), component: CourseViewPage },
  { exact: true, path: paths.courseFeedbacksView(), component: CourseFeedbacksViewPage },
  { exact: true, path: paths.studentProfile(), component: StudentProfilePage, private: true },
  { exact: true, path: paths.studentCourses(), component: StudentCoursesPage, private: true },
  { exact: true, path: paths.paymentClose(), component: PaymentSuccess },
  { exact: true, path: paths.subscriptions(), component: SubscriptionsPage },
  { exact: true, path: paths.jazz100(), component: Jazz100Page },
  { exact: true, path: paths.theatre(), component: TheatrePage },
  { exact: true, path: paths.spring(), component: SpringPage },
  { exact: true, path: paths.search(), component: SearchPage },
  { path: "*", component: Error404Page },
];
