import { useEvent } from "effector-react/scope";

import { analyticsEvents } from "@/shared/analytics";
import { GifteryCode } from "@/shared/lib/giftery/giftery-code";
import { ButtonRounded, Typography } from "@/shared/ui";
import img from "@/shared/ui/assets/images/spring-landing/9sc-1920.webp";

export const BuyCertificateSection = () => {
  const sendAnalytics = useEvent(analyticsEvents.springPage.openSpringCert);

  const handleClick = () => {
    sendAnalytics();
  };

  return (
    <section className="flex flex-col md:flex-row">
      <div className="bg-m-white h-[370px] md:h-auto pt-[25px] px-mn-20 md:py-mn-50 md:px-mn-30 3xl:pt-mn-74 3xl:px-[46px] uppercase font-bold w-full md:w-[65%]">
        <Typography tag="h3" size="m-h3" className="text-m-blue mb-mn-10 md:mb-mn-20 3xl:mb-mn-30">
          Вдохновение в&nbsp;подарок
        </Typography>
        <Typography tag="p" size="m-h5" className="mb-mn-10 md:mb-mn-20 3xl:mb-mn-30 text-m-dark">
          Оформите сертификат и&nbsp;подарите доступ
          <br className="hidden md:block" /> к&nbsp;восьми авторским онлайн-курсам Музы
          <br />
          <span className="text-m-orange">на 12 месяцев</span>
        </Typography>

        <div className="mb-[36px] 3xl:mb-[54px] flex items-center text-[30px] md:text-[32px] xl:text-[40px] 3xl:text-[62px]">
          <span className="gradient-text pt-0">2490₽</span>
        </div>

        <ButtonRounded
          data-giftery-widget="20402"
          data-giftery-widget-color="272264"
          linkOptions={{
            to: "https://widget.giftery.cards/?productId=20402&color=007aff",
            target: "_blank",
            rel: "noreferrer",
          }}
          className="border-[2px] w-full md:w-auto md:text-[18px] py-[12px] 3xl:text-[27px] !h-auto 3xl:py-[20px] px-[60px]"
          variant="tertiary"
          onClick={handleClick}
        >
          Купить сертификат
        </ButtonRounded>
      </div>

      <div className="w-full md:w-[40%]">
        <img
          src={img}
          className="object-cover w-full md:h-[500px] 2xl:h-full 3xl:h-[750px]"
          alt=""
        />
      </div>

      <GifteryCode />
    </section>
  );
};
