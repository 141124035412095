import React from "react";

import loadable from "@loadable/component";

import { CardSkeleton } from "../card/card-skeleton";

export const Slider = loadable(() => import("./slider").then((component) => component.Slider), {
  ssr: false,
});

export const CertificateSlider = loadable(
  () => import("./slider").then((component) => component.Slider),
  {
    fallback: (
      <>
        <div className="hidden md:flex flex-row justify-center w-full overflow-hidden">
          {Array(3)
            .fill(0)
            .map((_, i) => (
              <div key={i} className="mr-[12px]">
                <CardSkeleton />
              </div>
            ))}
        </div>

        <div className="md:hidden flex flex-row justify-center w-full overflow-hidden">
          <div className="relative -right-[108px] opacity-60 scale-[0.8]">
            <CardSkeleton />
          </div>
          <div className="absolute z-10">
            <CardSkeleton />
          </div>
          <div className="relative -left-[108px] opacity-60 scale-[0.8]">
            <CardSkeleton />
          </div>
        </div>
      </>
    ),
  },
);

export const CardSlider = loadable(
  () => import("./card-slider").then((component) => component.CardSlider),
  {
    fallback: (
      <>
        <div className="hidden md:flex flex-row justify-center w-full overflow-hidden">
          {Array(3)
            .fill(0)
            .map((_, i) => (
              <div key={i} className="mr-[12px] w-full">
                <CardSkeleton className="w-full" />
              </div>
            ))}
        </div>

        <div className="md:hidden flex flex-row justify-center w-full overflow-hidden">
          <div className="relative -right-[108px] opacity-60 scale-[0.8]">
            <CardSkeleton />
          </div>
          <div className="absolute z-10">
            <CardSkeleton />
          </div>
          <div className="relative -left-[108px] opacity-60 scale-[0.8]">
            <CardSkeleton />
          </div>
        </div>
      </>
    ),
  },
);

export { BaseCarousel } from "./carousel";

export type { SliderOptions } from "./types";

export { SliderControls, SliderBullets, SliderCountSeparator } from "./slider-attributes";
