import { useEvent } from "effector-react/scope";
import { FC } from "react";

import { openAuthModal } from "@/app/authorization-modals/model";
import { ConfirmationCodeModal, useConfirmCode } from "@/feature/confirm-code-modal";
import { useCountdownTimer } from "@/feature/countdown-button";

import { confirmCodeChangePassword, countdownTimerChangePassword } from "../model/password-change";

export const ResetPasswordConfirmCode: FC = () => {
  const handlers = useEvent({
    close: openAuthModal.close,
    supportContactsOnPassword: openAuthModal.supportContactsOnPassword,
  });

  const confirmCodePasswordChange = useConfirmCode(confirmCodeChangePassword);
  const countdownTimer = useCountdownTimer(countdownTimerChangePassword);

  return (
    <ConfirmationCodeModal
      onClose={handlers.close}
      onSupportContactsClick={handlers.supportContactsOnPassword}
      countdownTimerModel={countdownTimer}
      confirmCode={confirmCodePasswordChange}
    />
  );
};
