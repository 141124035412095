import { createEffect, createEvent, createStore, sample, Store, Event } from "effector";

import type { TopicId, TopicShort } from "@app/api";

import { updateUrlSearchParams } from "@/entities/navigation";

type CreateTopicFilters = {
  name: string;
  syncWithUrl?: boolean;
  defaultTopic?: TopicShort;
};

export type TopicFilter = {
  $selectedTopicsIds: Store<TopicId[]>;
  $allTopics: Store<TopicShort[]>;
  topicSelected: Event<TopicShort>;
  topicsSettled: Event<TopicShort[]>;
  initialized: Event<TopicShort["id"][]>;
  reset: Event<void>;
  $hasAppliedFilters: Store<boolean>;
  $isDisabled: Store<boolean>;
  disabledAllTopic: Event<boolean>;
};

export const createTopicFilters = ({
  name,
  defaultTopic,
  syncWithUrl = true,
}: CreateTopicFilters): TopicFilter => {
  const topicsSettled = createEvent<TopicShort[]>();
  const topicSelected = createEvent<TopicShort>();
  const initialized = createEvent<TopicId[]>();
  const reset = createEvent<void>();
  const disabledAllTopic = createEvent<boolean>();

  const synchronizeWithUrlFx = createEffect(async ({ topicIds }: { topicIds: string[] }) => {
    const value = topicIds.length ? topicIds.join(",") : defaultTopic?.id;
    await updateUrlSearchParams({ [name]: value });
  });

  const $allTopics = createStore<TopicShort[]>([]).on(topicsSettled, (_, topics) => {
    return defaultTopic ? [defaultTopic, ...topics] : topics;
  });

  const $selectedTopicsIds = createStore<TopicId[]>(defaultTopic ? [defaultTopic.id] : [])
    .on(topicSelected, (topics, topic) => {
      if (topic.id === defaultTopic?.id) {
        return [defaultTopic.id];
      }

      if (topics.includes(topic.id)) {
        return topics.filter((id) => id !== topic.id);
      }

      return [...topics, topic.id].filter((id) => id !== defaultTopic?.id);
    })
    .on(initialized, (current, topics) => (topics.length ? topics : current))
    .reset(reset);

  const $hasAppliedFilters = $selectedTopicsIds.map(
    (topics) => topics.length > 0 && topics.some((id) => id !== defaultTopic?.id),
  );

  const $isDisabled = createStore<boolean>(false).on(disabledAllTopic, (_, state) => state);

  sample({
    source: $selectedTopicsIds,
    filter: (topics) => topics.length === 0,
    target: reset,
  });

  if (syncWithUrl) {
    sample({
      clock: topicSelected,
      source: $selectedTopicsIds,
      fn: (selectedTopics) => ({
        topicIds: selectedTopics,
      }),
      target: synchronizeWithUrlFx,
    });
  }

  return {
    topicSelected,
    $selectedTopicsIds,
    topicsSettled,
    $allTopics,
    initialized,
    reset,
    $hasAppliedFilters,
    $isDisabled,
    disabledAllTopic,
  };
};
