import clsx from "clsx";
import { FC, MouseEventHandler, ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";

import { Spinner } from "@/shared/ui";

import style from "./style.module.scss";

type ButtonProps = {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  ariaLabel?: string;
  type?: "button" | "submit" | "reset";
  id?: string;
};

type LinkOptions = {
  nativeLink?: boolean;
  to: string;
} & LinkProps;

export type ButtonRoundedProps = {
  variant?: "primary" | "secondary" | "tertiary" | "quaternary" | "legacy" | "outline";
  fontSize?: string;
  href?: string;
  linkOptions?: LinkOptions;
  isLoading?: boolean;
} & ButtonProps;

const baseClasses =
  "uppercase rounded-full gpu overflow-hidden h-[30px] tracking-[0.25px] leading-none";

const primary = ({ disabled }: { disabled: boolean }) =>
  clsx(
    "text-m-white font-m-sb-text font-semibold",
    disabled
      ? "bg-m-dark-grey"
      : "duration-700 transition-[background] bg-m-gradient-magenta-orange bg-size-200 hover:bg-right-center",
  );

const secondary = ({ disabled }: { disabled: boolean }) =>
  clsx(
    "text-m-white bg-transparent border border-solid transition-opacity font-semibold",
    disabled
      ? "text-m-dark-grey border-m-dark-grey"
      : "duration-700 transition-border hover:border-m-orange",
  );

const tertiary = ({ disabled }: { disabled: boolean }) =>
  `bg-none border border-solid font-semibold ${
    disabled
      ? "text-m-dark-grey border-m-dark-grey"
      : "hover:border-m-orange border-m-blue text-m-blue"
  }`;

const quaternary = ({ disabled }: { disabled: boolean }) =>
  clsx(
    "bg-m-white rounded-full transition-all uppercase font-m-sb-text font-semibold",
    disabled ? "text-m-white !bg-m-dark-grey" : "text-m-orange hover:bg-m-white-80",
  );

const legacy = ({ disabled }: { disabled: boolean }) =>
  clsx(
    "leading-none bg-transparent border border-m-dark-grey text-m-dark-grey border-solid uppercase hover:border-m-orange group",
    {
      "pointer-events-none": disabled,
    },
  );

export const ButtonOutline = ({
  type = "button",
  ariaLabel,
  className,
  onClick,
  children,
  disabled,
  ...rest
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      aria-label={ariaLabel}
      className={clsx(`${baseClasses} bg-transparent font-m-sb-display font-bold`, className)}
      onClick={onClick}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
};

export const ButtonRounded = ({
  children,
  onClick,
  className,
  disabled = false,
  type = "button",
  variant = "primary",
  fontSize = "m-xs",
  ariaLabel,
  linkOptions,
  isLoading = false,
  ...rest
}: ButtonRoundedProps) => {
  const disabledBtn = disabled || isLoading;

  const classes = clsx(
    {
      [primary({ disabled: disabledBtn })]: variant === "primary",
      [secondary({ disabled: disabledBtn })]: variant === "secondary",
      [tertiary({ disabled: disabledBtn })]: variant === "tertiary",
      [quaternary({ disabled: disabledBtn })]: variant === "quaternary",
      [legacy({ disabled: disabledBtn })]: variant === "legacy",
    },
    `${baseClasses} px-mv-4 3xl:h-[36px] 3xl:px-[47px] text-${fontSize}`,
    className,
  );

  if (linkOptions) {
    if (linkOptions.nativeLink) {
      return (
        <a
          href={linkOptions.to}
          className={clsx(classes, "flex justify-center max-w-max items-center text-center")}
          onClick={onClick}
          {...rest}
        >
          {children}
        </a>
      );
    }

    return (
      <Link
        className={clsx(classes, "flex justify-center items-center text-center")}
        onClick={onClick}
        {...linkOptions}
        {...rest}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      aria-label={ariaLabel}
      className={classes}
      onClick={onClick}
      disabled={disabledBtn}
      type={type}
      {...rest}
    >
      {isLoading && (
        <div className="absolute left-[50%] top-[50%] -translate-y-[50%] -translate-x-[50%]">
          <Spinner.Pulse size={10} color="white" />
        </div>
      )}
      <div
        className={clsx({
          invisible: isLoading,
        })}
      >
        {children}
      </div>
    </button>
  );
};

export const ButtonCircle = ({
  children,
  onClick,
  className,
  disabled,
  type = "button",
  ariaLabel,
  ...rest
}: ButtonProps) => {
  const classes = clsx(
    {
      "rounded-full uppercase gpu overflow-hidden rounded-full bg-transparent flex items-center justify-center max-w-mv-6 max-h-mv-6":
        true,
    },
    className,
  );

  return (
    <button
      className={classes}
      onClick={onClick}
      disabled={disabled}
      type={type}
      aria-label={ariaLabel}
      {...rest}
    >
      {children}
    </button>
  );
};

interface NavButtonProps {
  onClick?: MouseEventHandler;
  variant?: "primary" | "secondary";
  className?: string;
}

export const NavButton: FC<NavButtonProps> = ({
  children,
  onClick,
  variant = "primary",
  className,
}) => (
  <button
    className={clsx(
      {
        [style.NavButtonSecondary]: variant === "secondary",
        [style.NavButtonPrimary]: variant === "primary",
      },
      style.NavButton,
      className,
    )}
    onClick={onClick}
  >
    {children}
  </button>
);
