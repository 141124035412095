import { contract, withHatch } from "framework";

import * as model from "./model";
import * as page from "./page";

contract({
  model,
  page,
});

export const SubscriptionsPage = withHatch(model.hatch, page.Subscriptions);
