import React from "react";

import { Footer } from "@/widgets/footer";

import { SEO } from "@/shared/ui";

import {
  MakeGiftSection,
  StartSection,
  AuthorDescriptionTopicSingle,
  AuthorDescriptionTopicRhombusTop,
  AuthorDescriptionTopicRhombusBottom,
  BuyCertificateSection,
  SliderCourses,
  CTA_CERTIFICATE,
} from "./modules/landing-blocks";

export const SpringPage = () => {
  return (
    <>
      <SEO title="">
        <meta name="robots" content="noindex, nofollow" />
      </SEO>
      <StartSection />
      <MakeGiftSection />
      <AuthorDescriptionTopicRhombusTop />
      <AuthorDescriptionTopicSingle />
      <SliderCourses />
      <AuthorDescriptionTopicRhombusBottom />
      <div id={CTA_CERTIFICATE}>
        <BuyCertificateSection />
      </div>
      <Footer />
    </>
  );
};
