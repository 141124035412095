import clsx from "clsx";
import { ReactNode } from "react";

import styles from "./layout.module.scss";

type ContainerProps = {
  children: ReactNode;
  className?: string;
  mode?: "default" | "fluid" | "full" | "fixed" | "fluid-fixed";
};

export const Container = ({ children, className, mode = "default", ...props }: ContainerProps) => {
  return (
    <div
      className={clsx(
        "mx-auto",
        {
          [styles.ContainerDefault]: mode === "default",
          [styles.ContainerFluid]: mode === "fluid",
          [styles.ContainerFull]: mode === "full",
          [styles.ContainerFixed]: mode === "fixed",
          [styles.ContainerFluidFixed]: mode === "fluid-fixed",
        },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
