export enum ProductType {
  MuseSubscription = "Подписка Музы",
  Subscription = "Подписка",
  Course = "Курс",
}

export enum SubscriptionType {
  Instant = "instant",
  Recurring = "recurring",
}
