import clsx from "clsx";
import { ReactNode } from "react";

import { ReactComponent as ArrowDirection } from "@/shared/ui/assets/images/icons/arrow-direction.svg";
import { ReactComponent as Arrow } from "@/shared/ui/assets/images/icons/arrow.svg";

import { ButtonCircle } from "../button/button";

type ControlsProps = {
  classNameContainer?: string;
  controlsClass?: string;
  onSlidePrev?: () => void;
  onSlideNext?: () => void;
  renderBetweenControls?: ReactNode;
  navigationRenderPreset?: "arrow" | "circle" | "empty";
  classNameControlLeft?: string;
  classNameControlRight?: string;
};

export const SliderBullets = <T,>({ data, activeSlide }: { data: T[]; activeSlide: number }) => {
  return (
    <div className="flex gap-2">
      {data.map((_, currentIndex) => (
        <div
          key={currentIndex}
          className={clsx(
            "h-mv-2 w-mv-2 rounded-full",
            currentIndex === activeSlide ? "bg-m-orange" : "bg-m-grey",
          )}
        />
      ))}
    </div>
  );
};

export const SliderBulletsRectangle = <T,>({
  data,
  activeSlide,
  setSliderInstance,
}: {
  data: T[];
  activeSlide: number;
  setSliderInstance: (value: number) => void;
}) => {
  return (
    <div className="flex gap-[10px] md:gap-2">
      {data.length > 1 &&
        data.map((_, currentIndex) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            key={currentIndex}
            onClick={() => {
              setSliderInstance(currentIndex);
            }}
            className="pt-[24px] md:pt-[12px] xl:pt-[15px] cursor-pointer"
          >
            <div
              className={clsx(
                "h-[8px] md:h-[4px] w-[70px] xl:h-[5px] xl:w-[134px] rounded-full",
                currentIndex === activeSlide ? "bg-m-orange" : "bg-m-grey",
              )}
            />
          </div>
        ))}
    </div>
  );
};

export const SliderCountSeparator = <T,>({
  data,
  activeSlide,
}: {
  data: T[];
  activeSlide: number;
}) => (
  <div className="font-m-sb-display text-m-5xl min-w-[70px] m-auto flex justify-center">
    {activeSlide + 1} / {data.length}
  </div>
);

export const SliderControls = ({
  classNameContainer,
  controlsClass,
  onSlideNext,
  onSlidePrev,
  renderBetweenControls,
  navigationRenderPreset = "circle",
  classNameControlLeft,
  classNameControlRight,
}: ControlsProps) => {
  const handlePrev = () => {
    if (onSlidePrev) {
      onSlidePrev();
    }
  };

  const handleNext = () => {
    if (onSlideNext) {
      onSlideNext();
    }
  };

  const renderPreset = (onClick: () => void, direction: "prev" | "next", className?: string) => {
    return {
      circle: (
        <ButtonCircle
          ariaLabel="Slide change"
          className={clsx(
            "h-10 w-10 hover:opacity-90",
            {
              "rotate-180": direction === "next",
            },
            controlsClass,
            className,
          )}
          onClick={onClick}
        >
          <Arrow />
        </ButtonCircle>
      ),
      arrow: (
        <button
          onClick={onClick}
          className={clsx("py-[10px] px-[15px]", {
            "rotate-180": direction === "prev",
            className,
          })}
        >
          <ArrowDirection />
        </button>
      ),
      empty: null,
    };
  };

  return (
    <div className={clsx("flex items-center gap-2 xl:gap-[10px] 3xl:gap-mv-3", classNameContainer)}>
      {renderPreset(handlePrev, "prev", classNameControlLeft)[navigationRenderPreset]}
      {renderBetweenControls}
      {renderPreset(handleNext, "next", classNameControlRight)[navigationRenderPreset]}
    </div>
  );
};
