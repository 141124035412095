import clsx from "clsx";
import { useEvent, useStore } from "effector-react/scope";
import React, { useState } from "react";

import { RecommendationFilter } from "@app/api";

import { paths } from "@/pages/paths";

import { $authenticatedUserId } from "@/entities/user";

import { useDevices } from "@/shared/lib/responsive";
import { ButtonRounded, DrawerBottom, Hint, Modal, SwitchToggle } from "@/shared/ui";

import { openSignInModal } from "@/feature/sign-in";

import { FilterPanel } from "./filter-panel";

const HintText = () => {
  const titleClasses =
    "text-m-arctic mb-mn-10 3xl:mb-mn-16 uppercase font-semibold text-m-sm 3xl:text-m-2xl";

  const paragraphClasses = "text-m-white text-m-sm 3xl:text-m-base";
  return (
    <article>
      <div>
        <h1 className={titleClasses}>Умные рекомендации</h1>
        <p className={paragraphClasses}>
          Подборка профессий и&nbsp;курсов, которые подойдут именно вам
        </p>
      </div>
    </article>
  );
};

const ModalInvitingUserLogin = ({ onClose }: { onClose: () => void }) => {
  const openSignModal = useEvent(openSignInModal);

  return (
    <Modal.Surface className="pb-mn-50">
      <Modal.Header close={onClose} />
      <Modal.Title>Войти в аккаунт</Modal.Title>
      <Modal.Body>
        <Modal.Description className="text-center">
          Чтобы воспользоваться персонализированной подборкой образовательных программ
          и&nbsp;онлайн-курсов, учитывающей{" "}
          <a
            data-testid="recommendation-doc-link"
            href={paths.recommendationDoc()}
            target="_blank"
            rel="nofollow noreferrer"
            className="text-m-orange underline"
          >
            ваши&nbsp;интересы
          </a>
        </Modal.Description>
        <div className="flex justify-center mt-mn-20 xl:mt-mn-26">
          <ButtonRounded
            data-testid="recommendation-move-btn"
            onClick={() => {
              onClose();
              openSignModal();
            }}
          >
            Перейти
          </ButtonRounded>
        </div>
      </Modal.Body>
    </Modal.Surface>
  );
};

type FilterRecommendationProps = {
  value: RecommendationFilter;
  onChange: (payload: RecommendationFilter) => void;
};

export const FilterRecommendation = ({ value, onChange }: FilterRecommendationProps) => {
  const userId = useStore($authenticatedUserId);
  const [isOpenInviteModal, setIsOpenInviteModal] = useState(false);
  const { isMobile } = useDevices();

  const handleToggleSwitch = () => {
    if (!userId) {
      setIsOpenInviteModal(true);
      return;
    }

    onChange(value === "rec_off" ? "rec_on" : "rec_off");
  };

  const renderHint = (isHintOpen: boolean, setOpenHint: (isHintOpen: boolean) => void) => {
    if (!isMobile) {
      return (
        <Hint
          isHintVisible={isHintOpen}
          animationShowMode="bottomTop"
          className="-left-[20px] -top-[140px] 3xl:-top-[150px]"
        >
          <HintText />
        </Hint>
      );
    }

    return (
      <DrawerBottom fullWidth isVisible={isHintOpen} onClose={() => setOpenHint(false)}>
        <HintText />
        <ButtonRounded className="mt-mn-26" onClick={() => setOpenHint(false)}>
          Понятно
        </ButtonRounded>
      </DrawerBottom>
    );
  };

  return (
    <>
      <FilterPanel
        groupTitle={
          <span
            className={clsx(
              {
                "text-m-white": value === "rec_on",
                "text-m-dark-grey": value === "rec_off",
              },
              "font-semibold text-m-sm 3xl:text-m-xl uppercase",
            )}
          >
            Рекомендации
          </span>
        }
        onRenderHint={renderHint}
        additionalComponent={
          <SwitchToggle
            data-testid={value}
            checked={value === "rec_on"}
            onToggle={handleToggleSwitch}
          />
        }
      />
      <Modal
        closeOnClickOverlay={false}
        isOpen={isOpenInviteModal}
        close={() => setIsOpenInviteModal(false)}
        render={() => <ModalInvitingUserLogin onClose={() => setIsOpenInviteModal(false)} />}
      />
    </>
  );
};
