import clsx from "clsx";

import { TopicShort } from "@app/api";

type TopicChipProps = {
  topic: TopicShort;
  onClick: (topic: TopicShort) => void;
  selectedTopics?: TopicShort["id"][];
  isDisabled?: boolean;
};

type FilterTopicsProps = {
  topics: TopicShort[];
  onClick: (topic: TopicShort) => void;
  selectedTopics?: TopicShort["id"][];
  isDisabled?: boolean;
};

const TopicChip = ({ topic, onClick, selectedTopics, isDisabled }: TopicChipProps) => {
  const isSelected = selectedTopics?.includes(topic.id);
  const id = isSelected ? `topic-chip_${topic.id}_active` : `topic-chip_${topic.id}`;

  return (
    <button
      id={id}
      data-testid={id}
      onClick={() => onClick(topic)}
      disabled={isDisabled}
      className={clsx(
        { "bg-m-white text-m-orange": isSelected },
        { "bg-m-white-10 text-m-white": !isSelected },
        { "opacity-20": isDisabled },
        { "md:hover:text-m-orange md:hover:bg-m-white": !isDisabled },
        "uppercase text-m-white rounded-[30px] font-semibold",
        "py-[7px] px-[18px] md:py-[12px] md:px-[30px] 3xl:py-[18px] 3xl:px-[45px]",
        "text-m-2xs md:text-m-xs 3xl:text-m-lg",
      )}
    >
      {topic.name}
    </button>
  );
};

export const FilterTopicChips = ({
  topics,
  onClick,
  selectedTopics,
  isDisabled,
}: FilterTopicsProps) => {
  return (
    <div className="flex flex-wrap gap-2.5 3xl:gap-4">
      {topics.map((topic) => (
        <TopicChip
          key={topic.id}
          topic={topic}
          onClick={onClick}
          selectedTopics={selectedTopics}
          isDisabled={isDisabled}
        />
      ))}
    </div>
  );
};
