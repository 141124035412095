import { apiInstance } from "../../bff";
import { createGqlEffect } from "../../client";

export const getLessonContent = createGqlEffect(apiInstance.lesson.getLessonContent);

export const getCompletedCourseLessonsIds = createGqlEffect(
  apiInstance.lesson.getCompletedCourseLessonsIds,
);

export const checkIsLessonCompleted = createGqlEffect(apiInstance.lesson.checkIsLessonCompleted);

export const markLessonAsCompleted = createGqlEffect(apiInstance.lesson.markLessonAsCompleted);

export const getCourseTotalLessonsCount = createGqlEffect(
  apiInstance.lesson.getCourseTotalLessonsCount,
);

export const getCourseCompletedLessonsCount = createGqlEffect(
  apiInstance.lesson.getCourseCompletedLessonsCount,
);
