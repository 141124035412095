import { useEvent, useStore } from "effector-react/scope";

import type { Pagination } from "./create-pagination";

export const usePagination = (paginationUnit: Pagination) => {
  return {
    loadMore: useEvent(paginationUnit.loadMore),
    reset: useEvent(paginationUnit.reset),
    allLoaded: useStore(paginationUnit.$allLoaded),
    pending: useStore(paginationUnit.$pending),
    limit: paginationUnit.limit,
  };
};
