import { useEvent, useStore } from "effector-react/scope";
import React, { FC, FormEventHandler } from "react";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

import { ButtonRounded, ModalNotification, Input, Modal } from "@/shared/ui";

import { openAuthModal } from "@/app/authorization-modals/model";
import { ConfirmationCodeModal, useConfirmCode } from "@/feature/confirm-code-modal";
import { useCountdownTimer } from "@/feature/countdown-button";

import {
  confirmCodePhone,
  countdownTimerPhone,
  PromocodeModalType,
  $pendingActivatePromocode,
  $promoCode,
  $promoCodeErrorMessage,
  $promocodeModalType,
  formSubmitted,
  phoneSubmitted,
  promoCodeButtonClicked,
  promocodeFieldReset,
  promoCodeInputChanged,
  promocodeModalReset,
  promocodeModalTypeChanged,
  successButtonClicked,
  warningConfirmed,
  $phoneValue,
  $addPhoneError,
} from "../model/promocode";
import { PromocodePaymentWidget } from "./promocode-payment-widget";

const validationSchema = object().shape({
  phone: string()
    .matches(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, "Неверный формат номера телефона")
    .required("Введите номер телефона"),
});

const PhoneInputModal = () => {
  const { control, formState, getValues } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    delayError: 1000,
    defaultValues: {
      phone: "",
    },
  });
  const phoneError = useStore($addPhoneError);

  const handlers = useEvent({ promocodeModalReset, phoneSubmitted });

  const handleSubmit = () => {
    const phoneValue = getValues("phone");
    handlers.phoneSubmitted(phoneValue);
  };

  const renderError = (invalid: boolean, message: string) => {
    if (invalid) return message;
    if (phoneError) return phoneError;
    return "";
  };

  return (
    <Modal.Surface>
      <Modal.Header close={handlers.promocodeModalReset} />
      <Modal.Body className="flex items-center flex-col pb-mn-50">
        <Modal.Title>Укажите номер телефона</Modal.Title>
        <Modal.Description className="text-center mb-mn-20 3xl:mb-mn-26">
          Мы&nbsp;пришлём на&nbsp;него проверочный код для&nbsp;подтверждения. Это нужно,
          чтобы&nbsp;активировать&nbsp;промокод
        </Modal.Description>
        <div className="mb-mn-20 3xl:mb-mn-26 w-full">
          <Controller
            control={control}
            name="phone"
            render={({ field, fieldState }) => {
              const invalid = fieldState.invalid && field.value.replace(/[^\d]/g, "").length > 1;
              return (
                <>
                  <Input.Masked
                    autoFocus
                    isError={invalid || Boolean(phoneError)}
                    placeholder="+7 (___) ___-__-__"
                    maskOptions={{ mask: "+7 (999) 999-99-99" }}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    onBlur={field.onBlur}
                  />
                  <Input.Description
                    className="text-left m-typography-modal-caption"
                    isError={invalid || Boolean(phoneError)}
                  >
                    {renderError(invalid, fieldState?.error?.message ?? "")}
                  </Input.Description>
                </>
              );
            }}
          />
        </div>
        <ButtonRounded disabled={!formState.isValid} onClick={handleSubmit} variant="primary">
          Сохранить
        </ButtonRounded>
      </Modal.Body>
    </Modal.Surface>
  );
};

const WarningAboutCurrentOldSubscription = () => {
  const handlers = useEvent({ promocodeModalReset, formSubmitted, warningConfirmed });

  return (
    <ModalNotification
      type="warning"
      onClose={handlers.promocodeModalReset}
      title={
        <>
          Как только вы введете промокод, <br />у вас активируется новая подписка
          на&nbsp;12&nbsp;месяцев
        </>
      }
      message={
        <>После активации промокода вы&nbsp;подключите годовую подписку по&nbsp;новому тарифу.</>
      }
      renderButtons={[
        {
          title: "Продолжить",
          onClick: handlers.warningConfirmed,
          variant: "primary",
        },
        {
          title: "Отменить",
          onClick: handlers.promocodeModalReset,
          variant: "secondary",
        },
      ]}
    />
  );
};

const PromocodeForNewUsers = () => {
  const handlers = useEvent({
    promocodeModalReset,
    formSubmitted,
    warningConfirmed,
    promocodeModalTypeChanged,
    promocodeFieldReset,
  });

  const tryAgain = () => {
    handlers.promocodeFieldReset();
    handlers.promocodeModalTypeChanged(PromocodeModalType.PROMOCODE_INPUT);
  };

  return (
    <ModalNotification
      type="warning"
      onClose={handlers.promocodeModalReset}
      title="Промокод только для новых пользователей"
      renderButtons={[
        {
          title: "Попробовать еще раз",
          onClick: tryAgain,
          variant: "primary",
        },
      ]}
    />
  );
};

const PromocodeCodeInput = () => {
  const handlers = useEvent({ promoCodeInputChanged });
  const promocodeValue = useStore($promoCode);
  const promoCodeErrorMessage = useStore($promoCodeErrorMessage);

  return (
    <>
      <Input
        maxLength={255}
        value={promocodeValue}
        onChange={(e) => handlers.promoCodeInputChanged(e.target.value)}
        placeholder="GJRT34FG"
        isError={Boolean(promoCodeErrorMessage)}
      />
      <Input.Description className="text-left" isError={Boolean(promoCodeErrorMessage)}>
        {promoCodeErrorMessage}
      </Input.Description>
    </>
  );
};

const PromocodeInputModal = () => {
  const handlers = useEvent({ promocodeModalReset, formSubmitted });
  const isLoading = useStore($pendingActivatePromocode);
  const value = useStore($promoCode);

  const submit: FormEventHandler = (event) => {
    event.preventDefault();
    handlers.formSubmitted();
  };

  return (
    <Modal.Surface>
      <Modal.Header close={handlers.promocodeModalReset} />
      <Modal.Body className="text-center pb-[39px] xl:pb-[47px] 3xl:pb-[62px]">
        <Modal.Title>Введите промокод</Modal.Title>

        <form onSubmit={submit}>
          <PromocodeCodeInput />

          <div className="mt-[20px]">
            <ButtonRounded type="submit" isLoading={isLoading} disabled={value.length === 0}>
              Применить
            </ButtonRounded>
          </div>
        </form>
      </Modal.Body>
    </Modal.Surface>
  );
};

const ErrorPopup = () => {
  const handlers = useEvent({ promocodeModalReset });

  return (
    <ModalNotification
      type="error"
      title="Что-то пошло не так."
      message="Попробуйте повторить позже."
      onClose={handlers.promocodeModalReset}
    />
  );
};

const SuccessPopup = () => {
  const handlers = useEvent({
    promocodeModalReset,
    successButtonClicked,
  });

  return (
    <ModalNotification
      type="success"
      onClose={handlers.promocodeModalReset}
      message="Промокод успешно активирован"
      title="Поздравляем"
      renderButtons={[
        {
          onClick: handlers.successButtonClicked,
          title: "ОК",
          variant: "secondary",
        },
      ]}
    />
  );
};

const ConfirmPhoneModal = () => {
  const countdownTimer = useCountdownTimer(countdownTimerPhone);
  const confirmPhone = useConfirmCode(confirmCodePhone);
  const phone = useStore($phoneValue);
  const handlers = useEvent({
    onSupportContact: openAuthModal.supportContactsOnDelete,
    closeModal: promocodeModalReset,
  });

  return (
    <ConfirmationCodeModal
      sender="phone"
      onClose={handlers.closeModal}
      onSupportContactsClick={() => {
        handlers.onSupportContact();
        handlers.closeModal();
      }}
      description={`Код отправлен на номер ${phone}`}
      countdownTimerModel={countdownTimer}
      confirmCode={confirmPhone}
    />
  );
};

const Popup = () => {
  const modalType = useStore($promocodeModalType);
  const closeModal = useEvent(promocodeModalReset);

  const promocodeType = useStore($promocodeModalType);

  const renderPromocodeModal: Record<string, FC> = {
    [PromocodeModalType.PROMOCODE_INPUT]: PromocodeInputModal,
    [PromocodeModalType.ERROR]: ErrorPopup,
    [PromocodeModalType.SUCCESS]: SuccessPopup,
    [PromocodeModalType.PROMOCODE_PAYMENT_WIDGET]: PromocodePaymentWidget,
    [PromocodeModalType.WARNING_HAS_OLD_SUBSCRIPTION]: WarningAboutCurrentOldSubscription,
    [PromocodeModalType.PROMOCODE_FOR_NEW_USERS]: PromocodeForNewUsers,
    [PromocodeModalType.PHONE_SUBMIT]: PhoneInputModal,
    [PromocodeModalType.PHONE_CONFIRM]: ConfirmPhoneModal,
  };

  const Component = renderPromocodeModal[promocodeType];

  return (
    <Modal
      closeOnClickOverlay={false}
      isOpen={modalType !== PromocodeModalType.DEFAULT}
      close={closeModal}
      render={Component}
    />
  );
};

const Button = () => {
  const handlers = useEvent({ promoCodeButtonClicked });
  return <ButtonRounded onClick={handlers.promoCodeButtonClicked}>Ввести промокод</ButtonRounded>;
};

export const Promocode = {
  Popup,
  Button,
};
