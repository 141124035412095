export enum Gender {
  MALE = "male",
  FEMALE = "female",
}

export interface AvatarUploader {
  content_type?: string;
  dir_id?: string;
  dir_type?: string;
  entity_id?: string;
  entity_type?: string;
  file_data?: string;
  file_name?: string;
}

export type UpdateUser = {
  userId: string;
  imageUrl: string;
};
