import { useEvent, useStore } from "effector-react/scope";
import { KeyboardEvent } from "react";

import { KeyboardEventKey } from "@/shared/constants";
import searchClear from "@/shared/ui/assets/images/icons/search-clear.svg";
import searchImage from "@/shared/ui/assets/images/icons/search.svg";

import { $searchValue, searchChanged, searchCleared, searchStarted } from "../model/search-courses";
import styles from "./search-bar.module.scss";

type SearchBarProps = {
  onAfterSearchClick?: () => void;
};

export const SearchBar = ({ onAfterSearchClick }: SearchBarProps) => {
  const search = useStore($searchValue);
  const handlers = useEvent({ searchChanged, searchCleared, searchStarted });

  const handlerKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyboardEventKey.Enter && search.length) {
      handlers.searchStarted();
      onAfterSearchClick?.();
    }
  };

  return (
    <div className="relative w-full">
      <img className="absolute bottom-[5px] left-0" src={searchImage} alt="Поиск" />
      <input
        onKeyDown={handlerKeyDown}
        value={search}
        onChange={(e) => handlers.searchChanged(e.target.value)}
        aria-label="search"
        className={styles.SearchBar}
        type="text"
      />
      {search.length > 0 && (
        <button onClick={() => handlers.searchCleared()} className="absolute bottom-[5px] right-0">
          <img src={searchClear} alt="Очистить поиск" />
        </button>
      )}
    </div>
  );
};
