import { CourseHeaderBlockSkeleton } from "@/widgets/course-header-block";

import { useDevices } from "@/shared/lib/responsive";
import { Section, SkeletonLoader } from "@/shared/ui";

export const CourseVideoPlayerSkeleton = () => {
  const { isMobile } = useDevices();

  return (
    <>
      <CourseHeaderBlockSkeleton />
      <Section className="relative bg-m-dark-blue min-h-[650px] xl:min-h-[500px] 3xl:min-h-[668px]">
        <Section.Content className="xl:pb-[67px] 2xl:pb-[92px] pt-mv-4 xl:pt-mv-5 3xl:pt-mv-6">
          <div className="flex items-center flex-col xl:flex-row">
            <div className="grow w-full">
              <div className="relative pt-[56.25%]">
                <SkeletonLoader
                  className="absolute top-0 left-0 rounded-[1.25rem] h-full w-full leading-none"
                  variant="blue"
                  height="100%"
                />
              </div>
            </div>
            <div className="pt-mv-4 xl:py-mv-4 w-full xl:max-w-[436px] 2xl:max-w-[512px] px-mv-4">
              <div>
                <SkeletonLoader
                  count={7}
                  className="h-[36px] 2xl:h-[52px] mb-2 last:mb-0"
                  variant="blue"
                />
              </div>
            </div>
          </div>
        </Section.Content>
      </Section>
      {isMobile && <div className="my-mv-5 h-[30px]" />}
    </>
  );
};
