import { createStore, sample } from "effector";

import { analyticsEvents } from "@/shared/analytics";

import { confirmCodeSignUp } from "./model/confirm-code";
import { submittedSignUpForm, SignUpFormData } from "./model/sign-up";

const $signUpFormData = createStore<SignUpFormData | null>(null).on(
  submittedSignUpForm,
  (_, payload) => payload,
);

sample({
  clock: submittedSignUpForm,
  target: [analyticsEvents.signUpMail, analyticsEvents.signUpPsw],
});

sample({
  clock: confirmCodeSignUp.sendAgain,
  target: analyticsEvents.clickRepeatMailPsw,
});

sample({
  clock: confirmCodeSignUp.requestFx.done,
  source: $signUpFormData,
  fn: (form) => ({
    method: "email",
    link_text: form?.subscribed ? "Согласие на рассылку" : "Без согласия",
  }),
  target: analyticsEvents.signUp,
});

sample({
  clock: confirmCodeSignUp.requestFx.failData,
  fn: () => ({ link_text: "Неверный код" }),
  target: analyticsEvents.errorFormSignUp,
});

sample({
  clock: confirmCodeSignUp.confirmCodeSubmitted,
  target: analyticsEvents.enterEmailPsw,
});
