import { Helmet } from "react-helmet-async";

import { isClient } from "../is-client";

export const GifteryCode = () =>
  isClient ? (
    <Helmet>
      <script type="text/javascript" src="https://launcher.giftery.cards/js/index.js" />
    </Helmet>
  ) : null;
