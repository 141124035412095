import clsx from "clsx";
import React, { ReactNode } from "react";

import { parse } from "@/shared/lib/html";
import { useProgressiveLoadImage } from "@/shared/lib/image/use-progressive-load-image";
import { ButtonRounded, Typography } from "@/shared/ui";
import arrow from "@/shared/ui/assets/images/icons/arrow-direction.svg";
import quotes from "@/shared/ui/assets/images/promo/quotes.svg";

import { CarouselPreview } from "./carousel-preview";

const WelcomeSection = ({
  header,
  mainImage: { desktop, mobile, low: mainImgLow },
  contentRight,
}: {
  header: ReactNode;
  mainImage: { desktop: string; mobile: string; low: string };
  contentRight: ReactNode;
}) => {
  const [src, { blur }] = useProgressiveLoadImage(mainImgLow, desktop);

  return (
    <div className="relative bg-m-blue" style={{ minHeight: "min(50vw, 100vh)" }}>
      <div>{header}</div>
      {!blur && (
        <picture>
          <source srcSet={src} media="(min-width: 768px)" />
          <img className="w-full" src={mobile} alt="100 лет российскому джазу" />
        </picture>
      )}
      <div className="absolute transform -translate-x-1/2 w-[54%] top-[36%] left-1/2 md:left-auto md:transform-none md:w-[27vw] md:top-[13vw] md:right-[11vw] 3xl:right-[15vw] 3xl:top-[13vw] 3x:w-[23vw]">
        {contentRight}
      </div>
    </div>
  );
};

const QuoteHistory = ({
  imgTopLeft,
  imgBottomRight,
  contentTopRight,
  contentBottomLeft,
}: {
  imgTopLeft: string;
  imgBottomRight: { desktop: string; mobile: string };
  contentTopRight: ReactNode;
  contentBottomLeft: ReactNode;
}) => (
  <div className="flex flex-col xl:flex-row flex-wrap bg-m-dark-blue text-m-white">
    <picture className="w-full basis-[49%] shrink-0 hidden xl:block">
      <img src={imgTopLeft} className="hidden xl:block h-auto w-full basis-[49%] shrink-0" alt="" />
    </picture>

    <div
      className="px-mn-20 xl:pl-mn-74 xl:pr-mn-50 pt-[153px] xl:pt-[302px] 3xl:pt-[396px] 3xl:pl-mn-111 3xl:pr-[40px] bg-no-repeat bg-[top_50px_right_20px] xl:bg-[top_38px_right_130px] 3xl:bg-[top_50px_right_195px] xl:bg-[length:176px] 3xl:bg-[length:264px] basis-[51%]"
      style={{ backgroundImage: `url(${quotes})` }}
    >
      <div className="pb-mn-38 font-m-sb-display font-semibold m-typography-h3 3xl:leading-none uppercase">
        {contentTopRight}
      </div>
    </div>

    <div className="pl-mn-20 pr-mn-26 py-mn-38 xl:px-mn-74 xl:py-[108px] 3xl:py-[160px] 3xl:px-[150px] m-typography-text font-bold xl:font-normal basis-[49%]">
      {contentBottomLeft}
    </div>

    <picture className="w-full basis-[51%] shrink-0 order-first xl:order-last">
      <source srcSet={imgBottomRight.desktop} media="(max-width: 1279px)" />
      <img
        src={imgBottomRight.mobile}
        className="w-full basis-[51%] shrink-0 xl:-mt-[84px]"
        alt=""
      />
    </picture>
  </div>
);

const ContentInformationWithImage = ({
  title,
  description,
  img,
  redirectUrl,
  reverse = false,
  onArrowClick,
}: {
  title: string | ReactNode;
  description: ReactNode;
  img: string;
  redirectUrl?: string;
  reverse?: boolean;
  onArrowClick?: () => void;
}) => {
  const paddingsHorizontal = "pr-mn-20 xl:pr-0 pl-mn-20 xl:pl-mn-74 3xl:pl-[110px]";
  const paddingsHorizontalReverse = "pl-mn-20 xl:pl-0 pr-mn-20 xl:pr-mn-74 3xl:pr-[110px]";

  const descriptionHorizontal = "xl:mr-[124px] 3xl:mr-[186px]";
  const descriptionHorizontalReverse = "xl:ml-[124px] 3xl:ml-[186px]";

  return (
    <section className="bg-m-dark-blue pb-mn-50 xl:pb-0 pt-mn-50 3xl:pt-mn-74">
      <h1
        className={clsx(
          "3xl:w-[1730px] whitespace-normal mb-mn-74 xl:mb-mn-50 3xl:mb-mn-74 uppercase text-m-white font-bold m-typography-h3 pr-mn-20 pl-mn-20 xl:pl-mn-74 xl:pr-mn-111 3xl:pl-[110px]",
        )}
      >
        {typeof title === "string" ? parse(title) : title}
      </h1>
      <div
        className={clsx(
          "flex items-center",
          { "flex-row-reverse": reverse },
          reverse ? paddingsHorizontalReverse : paddingsHorizontal,
        )}
      >
        <div
          className={clsx(
            "text-m-white flex flex-col items-end m-typography-text",
            reverse ? descriptionHorizontalReverse : descriptionHorizontal,
          )}
        >
          {description}
          {redirectUrl && (
            <a href={redirectUrl} target="_self" rel="noreferrer" onClick={onArrowClick}>
              <img
                src={arrow}
                className="cursor-pointer arrow-right-animation w-[50px] mt-mn-50 xl:w-[80px] xl:mt-[40px] 3xl:w-[118px] 3xl:mt-[112px]"
                alt=""
              />
            </a>
          )}
        </div>
        <img className="hidden xl:block xl:w-[50vw] 3xl:w-[49vw]" src={img} alt="" />
      </div>
    </section>
  );
};

const BigPreviewImage = ({
  subTitle,
  title,
  className,
  img: { desktop, mobile, low },
  onClick,
  buttonText = "Смотреть курс",
}: {
  subTitle: string;
  title: string;
  className?: string;
  img: { desktop?: string; mobile?: string; low?: string };
  onClick?: () => void;
  buttonText?: string;
}) => {
  const [src] = useProgressiveLoadImage(low ?? "", desktop ?? "");

  return (
    <section className={clsx("relative w-full bg-m-dark-blue", className)}>
      {src ? (
        <picture className="w-full">
          {desktop && <source srcSet={src} media="(min-width: 768px)" />}
          <img className="w-full" src={mobile || src} alt={subTitle} />
        </picture>
      ) : (
        <div className="min-h-[60vh]" />
      )}
      <article className="absolute bottom-0 pl-mn-20 pb-mn-26 xl:pl-mn-74 xl:pb-mn-38 3xl:pl-mn-111 3xl:pb-mn-50 z-10">
        <h2 className="text-m-arctic font-bold text-m-xs xl:text-m-base 3xl:text-m-3xl mb-mn-16 3xl:mb-mn-26 uppercase">
          {subTitle}
        </h2>
        <Typography
          tag="h1"
          size="m-h2"
          className="uppercase font-bold text-m-white whitespace-pre mb-mn-16 3xl:mb-mn-26"
        >
          {title}
        </Typography>
        <ButtonRounded onClick={onClick}>{buttonText}</ButtonRounded>
      </article>
    </section>
  );
};

const Partner = ({
  title,
  leftImage: { desktopImage, mobileImage = desktopImage },
  children,
}: {
  title: string | ReactNode;
  leftImage: { desktopImage: string; mobileImage?: string };
  children: ReactNode;
}) => {
  return (
    <section className="w-full xl:h-[555px] 3xl:h-[832px] flex-col-reverse flex xl:flex-row">
      <picture className="w-full h-full">
        <source srcSet={desktopImage} media="(min-width: 768px)" />
        <img className="w-full h-full object-cover" src={mobileImage} alt="#" />
      </picture>
      <div className="shrink-0 xl:basis-[33%] h-[370px] xl:h-auto pt-mn-38 px-mn-20 xl:px-mn-26 3xl:pt-[58px] 3xl:px-[40px]">
        <h1
          className="
          gradient-text
          m-typography-h3 uppercase
          mb-[76px] 3xl:mb-[115px]
          font-bold
          "
        >
          {title}
        </h1>
        {children}
      </div>
    </section>
  );
};

export type PreviewSliderProps = {
  title: string;
  subTitle: string;
  img: string;
  url?: string;
  imageClassName?: string;
  onClick?: () => void;
  buttonText?: string;
};

const BlockSliderInfo = ({
  title,
  subTitle,
  img,
  imageClassName,
  url,
  onClick,
  buttonText = "Перейти к курсу",
}: PreviewSliderProps) => {
  const handleClick = () => {
    if (url) {
      onClick?.();
      window.location.href = url;
    }
  };

  return (
    <a href={url} onClick={onClick}>
      <div>
        <img
          src={img}
          alt=""
          className={clsx("absolute w-full object-cover h-[670px] md:h-full", imageClassName)}
        />
        <div className="pl-mn-26 pt-mn-26 3xl:pl-mn-38 absolute bottom-[95px]">
          <Typography tag="h2" size="m-h6" className="z-10 relative mb-mn-16 text-m-arctic">
            {subTitle}
          </Typography>
          <h1 className="z-10 mb-mn-16 relative !leading-none 3xl:text-[54px] xl:text-[36px] text-[28px] whitespace-pre">
            {title}
          </h1>

          <ButtonRounded className="block md:hidden" onClick={handleClick}>
            {buttonText}
          </ButtonRounded>
        </div>
      </div>
    </a>
  );
};

type AuthorDescriptionTopicRhombusProps = {
  renderTopAuthor: ReactNode;
  renderBottomAuthor: ReactNode;
  topClassBackground?: string;
  bottomClassBackground?: string;
  renderTopDescription: string | ReactNode;
  renderBottomDescription: string | ReactNode;
  classNameContainer?: string;
  topRightDescriptionClasses?: string;
  classNameTopContainer?: string;
};

const RhombusBlock = ({
  renderTopAuthor,
  renderBottomAuthor,
  topClassBackground,
  bottomClassBackground,
  renderTopDescription,
  renderBottomDescription,
  classNameContainer,
  topRightDescriptionClasses,
  classNameTopContainer,
}: AuthorDescriptionTopicRhombusProps) => {
  const baseDescriptionClasses =
    "m-typography-lead h-full items-center flex text-m-white px-mn-20 md:px-mn-26";

  return (
    <section className={clsx("bg-m-dark-blue", classNameContainer)}>
      <div className={clsx("flex flex-col md:flex-row", classNameTopContainer)}>
        {renderTopAuthor}

        <div
          className={clsx(
            "py-mn-74 md:pb-[50px] xl:pb-[100px] md:pt-0 flex items-center w-full md:w-[65%]",
            topClassBackground,
          )}
        >
          <div
            className={clsx(
              "md:py-mn-74 xl:py-[100px] xl:pl-[58px] xl:pr-mn-74 2xl:pl-[88px] 2xl:pr-[180px] leading-[130%]",
              baseDescriptionClasses,
              topRightDescriptionClasses,
            )}
          >
            {typeof renderTopDescription === "string" ? (
              <p>{parse(renderTopDescription)}</p>
            ) : (
              renderTopDescription
            )}
          </div>
        </div>
      </div>

      <div
        className={clsx(
          "flex flex-col-reverse md:flex-row md:-mt-[50px] xl:-mt-[100px]",
          bottomClassBackground,
        )}
      >
        <div className="py-mn-74 xl:pb-0 md:pt-[50px] xl:pt-[100px] flex items-center w-full md:w-[65%]">
          <div
            className={clsx(
              "md:pt-mn-74 xl:py-[100px] xl:pl-[74px] xl:pr-[58px] 2xl:pr-[88px] leading-[130%]",
              baseDescriptionClasses,
            )}
          >
            {typeof renderBottomDescription === "string" ? (
              <p>{parse(renderBottomDescription)}</p>
            ) : (
              renderBottomDescription
            )}
          </div>
        </div>

        {renderBottomAuthor}
      </div>
    </section>
  );
};

const SliderAndPromoText = ({
  slides,
  content,
  onArrowClick,
  carouselClassName,
  leftContainerClassName,
  contentClassName,
}: {
  slides: JSX.Element[];
  content: string | ReactNode;
  onArrowClick?: () => void;
  leftContainerClassName?: string;
  carouselClassName?: string;
  contentClassName?: string;
}) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div
        className={clsx(
          "flex min-h-[370px] md:min-h-[556px] 3xl:min-h-[835px] items-end",
          leftContainerClassName,
        )}
      >
        <div
          className={clsx(
            "font-m-sb-display font-semibold m-typography-h3 text-m-blue uppercase p-mn-26 xl:px-mn-38 3xl:py-mn-50 pr-[10px]",
            contentClassName,
          )}
        >
          {content}
        </div>
      </div>
      <div
        className={clsx(
          "block h-[670px] md:h-auto shrink-0 3xl:w-[633px] xl:w-[422px] md:w-[40vw] w-full bg-m-blue text-m-white uppercase font-m-sb-display font-semibold text-m-3xl leading-none 3xl:text-m-5xl 3xl:leading-[1.1]",
          carouselClassName,
        )}
      >
        <CarouselPreview data={slides} onArrowClick={onArrowClick} />
      </div>
    </div>
  );
};

export const LandingBlock = {
  WelcomeSection,
  BigPreviewImage,
  ContentInformationWithImage,
  BlockSliderInfo,
  SliderAndPromoText,
  Partner,
  QuoteHistory,
  RhombusBlock,
};
