import { createEvent, createStore, attach, sample, createEffect } from "effector";

import { CoursePayInfo } from "@app/api";

import { PaymentApi, CourseFormatEnum, ProductApi } from "@/shared/api";
import { getFullReadablePeriod } from "@/shared/lib/get-full-readable-period";

import { FullSubscriptionPayInfo, PaymentType } from "../types";

export const subscriptionInfoLoaded = createEvent();
export const coursePayInfoLoaded = createEvent<{ courseId: string }>();
export const readyToSubscriptionPay = createEvent<{ platformId: string }>();
export const subscriptionPayReset = createEvent();
export const subscriptionSelected = createEvent<FullSubscriptionPayInfo>();
export const paymentTypeChanged = createEvent<PaymentType>();
export const paymentTypeReset = createEvent();
export const subscriptionCanceled = createEvent<{ platformId: string }>();
export const loadPaymentCourseType = createEvent<CourseFormatEnum>();

export const fetchSubscriptionFx = createEffect(async () => {
  const payInfo = await PaymentApi.getPayInfoSubscription({});
  const fullPayInfoSubscription = await Promise.all(
    payInfo.map(async (subscription) => ({
      ...subscription,
      trialInfo: await ProductApi.getTrialInfo(subscription.platformId),
    })),
  );

  return fullPayInfoSubscription.map((subscription) => ({
    ...subscription,
    trialInfo: {
      ...subscription.trialInfo,
      fullPeriodString: getFullReadablePeriod(
        subscription.trialInfo?.period || 0,
        subscription.trialInfo?.periodType,
      ),
    },
  }));
});

export const getPayInfoByCourseFx = attach({ effect: PaymentApi.getPayInfoByCourse });
export const cancelSubscriptionFx = attach({ effect: PaymentApi.cancelSubscription });

export const $selectedSubscription = createStore<FullSubscriptionPayInfo | null>(null)
  .on(subscriptionSelected, (_, subscription) => subscription)
  .reset(subscriptionPayReset);

export const $subscriptionsPayment = createStore<FullSubscriptionPayInfo[]>([]).on(
  fetchSubscriptionFx.doneData,
  (_, subscription) => [...subscription].sort((a, b) => a.definedTermPeriod - b.definedTermPeriod),
);

export const $isLoadingSubscriptionData = fetchSubscriptionFx.pending;

export const $coursePayment = createStore<CoursePayInfo | null>(null).on(
  getPayInfoByCourseFx.doneData,
  (_, [coursePayment]) => coursePayment,
);

export const $paymentType = createStore<PaymentType | null>(null)
  .on(paymentTypeChanged, (_, payload) => payload)
  .reset(paymentTypeReset);

export const $paymentCourseType = createStore<CourseFormatEnum | null>(null).on(
  loadPaymentCourseType,
  (_, format) => format,
);

sample({
  clock: readyToSubscriptionPay,
  source: $subscriptionsPayment,
  fn: (subscriptions, { platformId }) =>
    subscriptions.find(
      (subscription) => subscription.platformId === platformId,
    ) as FullSubscriptionPayInfo,
  target: subscriptionSelected,
});

sample({
  clock: readyToSubscriptionPay,
  fn: () => PaymentType.Subscription,
  target: paymentTypeChanged,
});

sample({
  clock: subscriptionInfoLoaded,
  target: fetchSubscriptionFx,
});

sample({
  clock: coursePayInfoLoaded,
  target: getPayInfoByCourseFx,
});

sample({
  clock: subscriptionCanceled,
  fn: (subscription) => subscription.platformId,
  target: cancelSubscriptionFx,
});
