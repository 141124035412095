import { createEvent, createStore, restore, sample, attach } from "effector";
import { object } from "yup";

import { $currentUser, userFormSchema } from "@/entities/user";

import { AuthApi } from "@/shared/api";
import { confirmError, DEFAULT_API_ERROR_MESSAGE_EXTRA } from "@/shared/api/constants";
import { errorMessages } from "@/shared/lib/form";
import { onlyDigits } from "@/shared/lib/string";

import { openAuthModal } from "@/app/authorization-modals/model";
import { createConfirmCodeModel } from "@/feature/confirm-code-modal";
import { createCountdownTimer } from "@/feature/countdown-button";

export const resetPasswordError = {
  request_password_reset_failed: "Пользователь не найден. Повторите попытку",
  action_not_created_timeout: "Ошибка доступа. Повторите попытку",
  action_not_created: "Ошибка доступа. Повторите попытку",
  action_change_status_failed: "Что-то пошло не так,\n попробуйте еще раз",
  user_disabled: "Пользователь не найден. Повторите попытку",
};

const errorModalCase: Set<keyof typeof resetPasswordError> = new Set([
  "action_change_status_failed",
]);

export interface PasswordForgotFormValues {
  username: string;
}

export const passwordForgotFormSchema = object().shape({
  username: userFormSchema.login.required(errorMessages.required),
});

export const clearForgotPasswordValues = createEvent();
export const clickedForgotPassword = createEvent();
export const forgotPasswordCodeSubmitted = createEvent<PasswordForgotFormValues>();
export const resetForgotPasswordError = createEvent();

export const $forgotPasswordUsername = restore(
  forgotPasswordCodeSubmitted.map(({ username }) => username),
  "",
);
export const $forgotPasswordUserToken = createStore("");
export const $forgotPasswordError = createStore("");

export const resetPasswordFx = attach({ effect: AuthApi.resetPassword });

export const $isPending = resetPasswordFx.pending;

$forgotPasswordError.reset(
  clearForgotPasswordValues,
  resetPasswordFx.done,
  resetForgotPasswordError,
);

sample({
  clock: clickedForgotPassword,
  target: openAuthModal.forgotPassword,
});

sample({
  clock: forgotPasswordCodeSubmitted,
  fn: ({ username }) => {
    if (username.includes("@")) return { username };
    return { username: `+${onlyDigits(username)}` };
  },
  target: resetPasswordFx,
});

export const $resetPasswordResult = createStore<{ token: string; timeout: number } | null>(null).on(
  resetPasswordFx.doneData.map((data) => ({ token: data.token, timeout: data.timeout })),
  (_, payload) => payload,
);

sample({
  clock: resetPasswordFx.doneData,
  target: openAuthModal.forgotPasswordConfirmCode,
});

export const confirmCodePassword = createConfirmCodeModel({
  source: $resetPasswordResult,
  sendFx: AuthApi.resetPasswordConfirmSms,
});

export const countdownTimerResetPassword = createCountdownTimer({});

sample({
  clock: confirmCodePassword.sendAgain,
  source: { username: $forgotPasswordUsername, currentUser: $currentUser },
  fn: ({ username, currentUser }) => ({ username: currentUser?.email ?? username }),
  target: resetPasswordFx,
});

sample({
  clock: confirmCodePassword.requestFx.doneData,
  fn: ({ token }) => token,
  target: [$forgotPasswordUserToken, openAuthModal.resetNewPassword],
});

sample({
  clock: confirmCodePassword.requestFx.failData,
  filter: ({ type }) => !confirmError[type],
  target: openAuthModal.forgotPasswordError,
});

sample({
  clock: resetPasswordFx.doneData,
  fn: ({ timeout }) => timeout,
  target: countdownTimerResetPassword.startCountdown,
});

sample({
  clock: resetPasswordFx.failData,
  fn: ({ type }) => resetPasswordError[type] ?? DEFAULT_API_ERROR_MESSAGE_EXTRA,
  target: $forgotPasswordError,
});

sample({
  clock: resetPasswordFx.failData,
  filter: ({ type }) => {
    if (!resetPasswordError[type]) return true;
    return errorModalCase.has(type as keyof typeof resetPasswordError);
  },
  target: openAuthModal.forgotPasswordError,
});

// clear forgot password values
sample({ clock: openAuthModal.close, target: clearForgotPasswordValues });
