import { createAnalyticsEvent } from "../../model/analytics";

enum SpringPageEvents {
  OPEN_CONTACT_SPRING = "open_contact_spring",
  OPEN_CONTACT_SPRING_1 = "open_contact_spring_1",
  OPEN_CONTACT_SPRING_2 = "open_contact_spring_2",
  OPEN_SPRING_CERT = "open_spring_cert",
}

export const openContactSpring = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: SpringPageEvents.OPEN_CONTACT_SPRING,
  link_text: "Подарить сертификат",
  link_type: "spring",
}));

export const openContactSpring1 = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: SpringPageEvents.OPEN_CONTACT_SPRING_1,
  link_text: "Подарочный сертификат",
  link_type: "spring",
}));

export const openContactSpring2 = createAnalyticsEvent(
  ({ course_id, course_name }: { course_id: string; course_name: string }) => ({
    event: "autoEvent",
    event_name: SpringPageEvents.OPEN_CONTACT_SPRING_2,
    link_text: "Подарочный сертификат",
    link_type: "spring",
    course_id,
    course_name,
  }),
);

export const openSpringCert = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: SpringPageEvents.OPEN_SPRING_CERT,
  link_text: "Купить сертификат",
  link_type: "spring",
}));
