import { sample } from "effector";

import { StatusTest } from "@/entities/lesson";

import { analyticsEvents } from "@/shared/analytics";

import { $courseName, $courseId, $coursePrice } from "../../model";
import {
  certificateFormSubmitted,
  studentTriedToGetCertificate,
} from "./model/certificate-issueing";
import {
  resultCalculating,
  $resultPercentageTest,
  $testResult,
  answerChecked,
  testRepeat,
} from "./model/lesson-test";

sample({
  clock: testRepeat,
  source: {
    course_id: $courseId,
    course_name: $courseName,
  },
  target: analyticsEvents.clickTryTestAgain,
});

sample({
  clock: resultCalculating,
  source: {
    course_id: $courseId,
    course_name: $courseName,
    price: $coursePrice,
    progress: $resultPercentageTest,
    result: $testResult,
  },
  filter: ({ result }) => result === StatusTest.Success,
  target: analyticsEvents.completeTutorialCourse,
});

sample({
  clock: studentTriedToGetCertificate,
  source: {
    course_id: $courseId,
    course_name: $courseName,
    price: $coursePrice,
  },
  target: analyticsEvents.openFormCertificate,
});

sample({
  clock: certificateFormSubmitted,
  source: {
    course_id: $courseId,
    course_name: $courseName,
    price: $coursePrice,
  },
  target: analyticsEvents.sendFormCertificate,
});

sample({
  clock: answerChecked,
  source: {
    course_id: $courseId,
    course_name: $courseName,
  },
  fn: ({ course_id, course_name }, test) => ({
    course_id,
    course_name,
    task_id: test.testId,
  }),
  target: analyticsEvents.interactionBlockTest,
});
