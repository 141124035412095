import { useStore } from "effector-react/scope";
import React from "react";
import { CatalogListItems } from "widgets/catalog";

import { UserCertificate } from "@app/api";

import {
  $userCertificates,
  FutureCertificateInfo,
  ProfileCertificate,
} from "@/feature/profile-certificates";

const renderCertificate = (certificate: UserCertificate, index: number) => (
  <ProfileCertificate key={`${certificate.courseName} - ${index}`} certificate={certificate} />
);

const useGetCertificatesCards = (): JSX.Element[] => {
  const certificates = useStore($userCertificates);
  return certificates.map(renderCertificate);
};

export function CertificatesTab() {
  const certificateCards = useGetCertificatesCards();

  return (
    <CatalogListItems
      renderCatalog={[
        {
          name: "certificates",
          renderElements: () => certificateCards,
          isHidden: certificateCards.length === 0,
        },
      ]}
      isEmpty={certificateCards.length === 0}
      EmptyLayout={<FutureCertificateInfo />}
    />
  );
}
