import clsx from "clsx";
import { ReactNode, createElement } from "react";

export type TypographyProps = {
  children: ReactNode;
  tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  /** size: adaptive sizes from UI kit */
  size?:
    | "m-h1"
    | "m-h2"
    | "m-h3"
    | "m-h4"
    | "m-h5"
    | "m-h6"
    | "m-text"
    | "m-label"
    | "m-lead"
    | "m-caption"
    | "m-input"
    | "m-text-small";
  className?: string;
};

const textSize: Record<Required<TypographyProps>["size"], string> = {
  "m-h1": "m-typography-h1",
  "m-h2": "m-typography-h2",
  "m-h3": "m-typography-h3",
  "m-h4": "m-typography-h4",
  "m-h5": "m-typography-h5",
  "m-h6": "m-typography-h6",
  "m-text": "m-typography-text",
  "m-label": "m-typography-label",
  "m-lead": "m-typography-lead",
  "m-caption": "m-typography-caption",
  "m-input": "m-typography-input",
  "m-text-small": "m-typography-text-small",
};

export const Typography = ({ children, tag, className, size, ...rest }: TypographyProps) => {
  const defaultClassName = `text-m-white ${textSize[size || "m-text"]}`;

  const classes = clsx(
    {
      [defaultClassName]: true,
    },
    className,
  );

  return createElement(tag, { className: classes, ...rest }, children);
};
