import React, { forwardRef } from "react";

import style from "./style.module.scss";
import { DivProps } from "./types";

export const Root: React.FC<DivProps> = ({ children, ...rest }) => (
  // eslint-disable-next-line react/jsx-boolean-value
  <div data-modal-root={true} {...rest}>
    {children}
  </div>
);

export const Screen = forwardRef<HTMLDivElement, DivProps>(function Screen(
  { children, ...rest },
  ref,
) {
  return (
    <div ref={ref} className={style.Screen} {...rest}>
      {children}
    </div>
  );
});

export const Container = forwardRef<HTMLDivElement, DivProps>(function Container(
  { children, ...rest },
  ref,
) {
  return (
    // eslint-disable-next-line
    <div ref={ref} className={style.Container} role="dialog" aria-modal={true} {...rest}>
      {children}
    </div>
  );
});

export const Overlay: React.FC<DivProps> = ({ children, ...rest }) => (
  <div className={style.Overlay} {...rest}>
    {children}
  </div>
);
