import React from "react";

import { Level } from "@app/api";

import { Radio } from "@/shared/ui";

import { filterLabels } from "../lib/filter-labels";
import { FilterPanel } from "./filter-panel";
import { classNames } from "./radio-clsx";

type FilterLevelProps = {
  value: Level | "any";
  onChange: (value: Level | "any") => void;
  isDisabled: boolean;
};

export const FilterLevel = ({ value, onChange, isDisabled }: FilterLevelProps) => {
  return (
    <FilterPanel groupTitle="Уровень" disabled={isDisabled}>
      <Radio
        className={classNames}
        id="level-any"
        checked={value === "any"}
        onChange={() => onChange("any")}
        status="default"
        label="Любой"
        disabled={isDisabled}
        hasCircle={false}
      />
      <Radio
        className={classNames}
        id="level-beginner"
        checked={value === "beginner"}
        onChange={() => onChange("beginner")}
        status="default"
        label={filterLabels.beginner}
        disabled={isDisabled}
        hasCircle={false}
      />
      <Radio
        className={classNames}
        id="level-pro"
        checked={value === "professional"}
        onChange={() => onChange("professional")}
        status="default"
        label={filterLabels.professional}
        disabled={isDisabled}
        hasCircle={false}
      />
    </FilterPanel>
  );
};
