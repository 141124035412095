import clsx from "clsx";
import { ReactNode } from "react";

import { paths } from "@/pages/paths";

import { historyPush } from "@/entities/navigation";

import { scrollToElement } from "@/shared/lib/scroll";
import { Container, Typography } from "@/shared/ui";
import arrowBlue from "@/shared/ui/assets/images/icons/arrow-direction-blue.svg";

type DataLink = {
  title: ReactNode;
  description: ReactNode;
  onClick: () => void;
};

const LinkBlock = ({
  title,
  description,
  onClick,
  className,
}: DataLink & { className: string }) => (
  <div role="presentation" className="cursor-pointer" onClick={onClick}>
    <div
      className={clsx(
        "flex flex-col h-[140px] md:h-auto px-mn-20 pt-mn-20 pb-[22px] md:pb-[5.2vw] xl:px-mn-26 xl:pt-mn-26 xl:pb-[40px] 3xl:pt-mn-38 3xl:px-mn-39 3xl:pb-[70px] container-with-arrow",
        className,
      )}
    >
      <div className="flex justify-between items-start">
        <Typography tag="h3" size="m-h5" className="mb-mn-10 uppercase font-bold gradient-text">
          {title}
        </Typography>
        <img className="w-[38px] 3xl:w-[57px] arrow-right-animation" src={arrowBlue} alt="" />
      </div>
      <Typography tag="p" size="m-text" className="text-m-dark">
        {description}
      </Typography>
    </div>
  </div>
);

export const AboutProject = () => {
  const scrollToSection = "dpo-programs";

  const data: DataLink[] = [
    {
      title: (
        <>
          Профессиональное <br /> образование
        </>
      ),
      description: (
        <>
          программы дополнительного
          <br /> профессионального образования
          <br />
          в&nbsp;области культуры и&nbsp;искусства
        </>
      ),
      onClick: () => historyPush(paths.catalogProgram()),
    },
    {
      title: (
        <>
          Сообщество
          <br className="hidden md:block xl:hidden" /> творческих
          <br /> профессионалов
        </>
      ),
      description: (
        <>
          прямые эфиры, вебинары и&nbsp;мастер-классы с&nbsp;экспертами и&nbsp;практиками креативных
          индустрий
        </>
      ),
      onClick: () => scrollToElement(scrollToSection),
    },
    {
      title: "Авторские курсы",
      description: (
        <>от Игоря Бутмана, Владимира Федосеева, Владимира Варнавы и&nbsp;других звёзд</>
      ),
      onClick: () => historyPush(paths.catalogCourses()),
    },
  ];

  return (
    <section className="flex flex-col md:flex-row justify-between md:grid md:grid-cols-[55%_45%] xl:grid-cols-[67%_33%] 3xl:h-[853px]">
      <Container
        mode="full"
        className="bg-m-dark-blue py-mn-50 xl:pt-[74px] 3xl:pt-[100px] md:pb-[135px] 3xl:pb-[265px] font-bold text-m-white"
      >
        <Typography tag="h2" size="m-h2" className="mb-mn-20 uppercase font-m-sb-display">
          <span className="gradient-text">МУЗА</span> — <br />
          онлайн-университет <br /> творческих
          <br />
          профессий
        </Typography>
        <p className="m-typography-lead font-m-sb-display leading-[130%]">
          Осваивайте творческие профессии, <br className="block xl:hidden" /> изучайте
          <br className="hidden xl:block" /> культуру и&nbsp;искусство,{" "}
          <br className="block xl:hidden" /> выбирайте новое хобби.
          <br /> <span className="gradient-text">Вместе с Музой</span>
        </p>
      </Container>
      <div className="flex w-full">
        <ul>
          {data.map((item, index) => (
            <li key={index}>
              <LinkBlock
                {...item}
                className={index === 1 ? "bg-m-white 3xl:h-[306px]" : "bg-m-grey 3xl:h-[277px]"}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
