import { string } from "yup";

import { errorMessages } from "@/shared/lib/form";
import { trimSpaceAndDash } from "@/shared/lib/strings";

const nameRegExp = /^[A-Za-zА-яЁё-\s]*$/gi;

const getNameSchema = () =>
  string()
    .transform(trimSpaceAndDash)
    .trim()
    .max(20, errorMessages.max(20))
    .required(errorMessages.required)
    .matches(nameRegExp, errorMessages.match);

export const getFirstNameSchema = getNameSchema;

export const getLastNameSchema = getNameSchema;
