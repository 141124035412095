import React, { ReactNode } from "react";

import { paths } from "@/pages/paths";

import { Breakpoint } from "@/shared/lib/responsive";
import { ButtonRounded, Container, Image, Typography } from "@/shared/ui";
import desktop1 from "@/shared/ui/assets/images/adults-landing/onboarding/desktop/muse-1.webp";
import desktop2 from "@/shared/ui/assets/images/adults-landing/onboarding/desktop/muse-2.webp";
import desktop3 from "@/shared/ui/assets/images/adults-landing/onboarding/desktop/muse-3.webp";
import desktop4 from "@/shared/ui/assets/images/adults-landing/onboarding/desktop/muse-4.webp";
import desktop5 from "@/shared/ui/assets/images/adults-landing/onboarding/desktop/muse-5.webp";
import mobile1 from "@/shared/ui/assets/images/adults-landing/onboarding/mobile/muse-m-1.webp";
import mobile2 from "@/shared/ui/assets/images/adults-landing/onboarding/mobile/muse-m-2.webp";
import mobile3 from "@/shared/ui/assets/images/adults-landing/onboarding/mobile/muse-m-3.webp";
import mobile4 from "@/shared/ui/assets/images/adults-landing/onboarding/mobile/muse-m-4.webp";
import mobile5 from "@/shared/ui/assets/images/adults-landing/onboarding/mobile/muse-m-5.webp";
import tablet1 from "@/shared/ui/assets/images/adults-landing/onboarding/tablet/muse-t-1.webp";
import tablet2 from "@/shared/ui/assets/images/adults-landing/onboarding/tablet/muse-t-2.webp";
import tablet3 from "@/shared/ui/assets/images/adults-landing/onboarding/tablet/muse-t-3.webp";
import tablet4 from "@/shared/ui/assets/images/adults-landing/onboarding/tablet/muse-t-4.webp";
import tablet5 from "@/shared/ui/assets/images/adults-landing/onboarding/tablet/muse-t-5.webp";
import arrow from "@/shared/ui/assets/images/icons/arrow-direction.svg";

import { useCatalogPageAnalytics } from "../../analytics";
import { CarouselPhotos } from "./carousel-photos";

type PhotoProps = {
  src: {
    mobile: string;
    tablet: string;
    desktop: string;
  };
  label?: ReactNode;
  title?: ReactNode;
  url?: string;
};

const photos: PhotoProps[] = [
  {
    src: {
      mobile: mobile1,
      tablet: tablet1,
      desktop: desktop1,
    },
    label: (
      <>
        Владимир Варнава, <br />
        артист балета, хореограф
      </>
    ),
    title: <>«Современная хореография с&nbsp;Владимиром Варнавой»</>,
    url: paths.courseView("4d17ed03-6949-46fb-b7f1-378bded4ce6a"),
  },
  {
    src: {
      mobile: mobile2,
      tablet: tablet2,
      desktop: desktop2,
    },
    label: (
      <>
        Алексей Pixelord, <br />
        музыкант, NFT-художник
      </>
    ),
    title: <>«Создание аудиовизуальных NFT»</>,
    url: paths.courseView("198674bd-6cfd-47d7-b169-cd090b1fc8b9"),
  },
  {
    src: {
      mobile: mobile3,
      tablet: tablet3,
      desktop: desktop3,
    },
    label: (
      <>
        Фантине Притула, <br />
        певица, композитор
      </>
    ),
    title: <>«Джазовый вокал с&nbsp;Фантине»</>,
    url: paths.courseView("05fce4c8-bbf0-4566-be16-20c0612aefd6"),
  },
  {
    src: {
      mobile: mobile4,
      tablet: tablet4,
      desktop: desktop4,
    },
    label: (
      <>
        Игорь Бутман,
        <br />
        народный артист России, саксофонист
      </>
    ),
    title: <>«Джазовый стандарт Игоря Бутмана»</>,
    url: paths.courseView("ebf9a1ac-c2f9-44df-85e9-7dbd53fa8494"),
  },
  {
    src: {
      mobile: mobile5,
      tablet: tablet5,
      desktop: desktop5,
    },
    label: (
      <>
        Евгений Лебедев, <br />
        пианист, композитор, участник LRK Trio
      </>
    ),
    title: <>«Игра в бисер: курс-концерт Евгения Лебедева»</>,
    url: paths.courseView("65a90582-7ef2-4c97-bec9-bc35c53bd782"),
  },
];

const Photo = ({ src, label, title, url }: PhotoProps) => {
  return (
    <div
      role="presentation"
      onClick={() => window.open(url, "_blank")}
      className="relative cursor-pointer"
    >
      <Image
        screens={[
          {
            breakpoint: Breakpoint.S,
            src: src.mobile,
          },
          {
            breakpoint: Breakpoint.L,
            src: src.tablet,
          },
          {
            breakpoint: Breakpoint.XL,
            src: src.desktop,
          },
        ]}
        lazy
        src={src.desktop}
        className="h-full object-cover w-full"
        alt=""
      />
      <div className="absolute left-[20px] md:left-[26px] xl:left-[32px] 3xl:left-[48px] bottom-mn-26 3xl:bottom-[44px]">
        <Typography
          tag="span"
          size="m-caption"
          className="text-m-white normal-case font-normal inline-block"
        >
          {label}
        </Typography>

        <Typography
          tag="h5"
          size="m-h5"
          className="text-m-white uppercase font-semibold font-m-sb-display mt-mn-10 md:mt-mn-20 mr-[65px]"
        >
          {title}
        </Typography>
      </div>
    </div>
  );
};

export const OnboardBlock = () => {
  const catalogAnalytics = useCatalogPageAnalytics();
  return (
    <section className="bg-m-dark-blue">
      <Container className="md:pb-0 3xl:py-mv-8 px-0" mode="fluid-fixed">
        <div className="flex w-full items-center flex-col-reverse xl:flex-row">
          <CarouselPhotos
            onButtonPrevClick={() => catalogAnalytics.sliderClicked("Подборки курсов")}
            onButtonNextClick={() => catalogAnalytics.sliderClicked("Подборки курсов")}
            photos={photos.map(({ src, label, title, url }) => (
              <Photo label={label} title={title} src={src} url={url} />
            ))}
          />
          <div className="flex pt-mn-38 md:pt-mn-50 px-mn-20 md:px-mn-26 xl:pl-0 3xl:pr-mn-38 items-start xl:items-end flex-col w-full">
            <div>
              <Typography
                className="mb-mn-20 3xl:mb-mn-30 uppercase font-semibold font-m-sb-display"
                tag="h3"
                size="m-h3"
              >
                <span className="gradient-text">МУЗА</span> —<br />
                онлайн-университет
                <br />
                творческих
                <br />
                профессий от сбера
              </Typography>
              <div className="flex justify-end flex-col">
                <Typography tag="p" size="m-text">
                  Осваивайте творческие профессии, изучайте <br />
                  культуру и&nbsp;искусство, выбирайте новое хобби.
                  <br />
                  <span className="text-m-orange">Вместе с Музой</span>
                </Typography>
                <div className="flex items-center xl:ml-[30%] my-mn-26 md:mb-[40px] xl:mb-0 md:mt-[40px] 3xl:mt-[45px] w-max container-with-arrow">
                  <Image
                    src={arrow}
                    alt="Подробнее"
                    className="w-[40px] xl:w-[70px] 3xl:w-[108px] arrow-right-animation"
                  />
                  <ButtonRounded
                    onClick={catalogAnalytics.moreAboutClicked}
                    linkOptions={{ to: paths.home() }}
                    className="ml-mn-30"
                  >
                    Подробнее
                  </ButtonRounded>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
