import { createEvent, createStore, Effect, sample, Store, combine, Unit } from "effector";

import { CommonError } from "@/shared/api";
import { confirmError, DEFAULT_API_ERROR_MESSAGE } from "@/shared/api/constants";

type CodeConfirmPayload = {
  code: string;
  token: string;
};

type ResponseEffect = {
  expired_at: string;
  token: string;
  timeout: number;
};

type StorePayload = {
  token: string;
  timeout: number;
};

type CreateConfirmCodePayload = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reset?: Unit<any>[];
  sendFx: Effect<CodeConfirmPayload, ResponseEffect, CommonError>;
  source: Store<StorePayload | null>;
};

export const createConfirmCodeModel = ({ reset, source, sendFx }: CreateConfirmCodePayload) => {
  const confirmCodeChanged = createEvent<string>();
  const confirmCodeSubmitted = createEvent();
  const sendAgain = createEvent();

  const resetAll = createEvent();

  const $confirmCode = createStore("")
    .on(confirmCodeChanged, (_, value) => value)
    .reset(resetAll);

  const $timeout = createStore(0);
  const $error = createStore("").reset(resetAll);

  if (reset) {
    sample({
      clock: reset,
      filter: Boolean,
      target: resetAll,
    });
  }

  sample({
    clock: sendFx.finally,
    target: resetAll,
  });

  sample({
    clock: source,
    filter: Boolean,
    fn: (data) => data.timeout,
    target: $timeout,
  });

  const $data = combine([$confirmCode, source], ([code, payload]) => ({
    code,
    token: payload?.token ?? "",
  }));

  sample({
    clock: sendFx.failData,
    fn: ({ type }) => confirmError[type] ?? DEFAULT_API_ERROR_MESSAGE,
    target: $error,
  });

  sample({
    clock: confirmCodeSubmitted,
    source: $data,
    target: sendFx,
  });

  return {
    confirmCodeChanged,
    confirmCodeSubmitted,
    sendAgain,
    $confirmCode,
    requestFx: sendFx,
    $error,
  };
};

export type ConfirmCodeModel = ReturnType<typeof createConfirmCodeModel>;
