import { createEvent, sample } from "effector";
import { useEvent } from "effector-react/scope";
import { FC } from "react";

import { authModalCloseClicked, openAuthModal } from "@/app/authorization-modals/model";
import { ConfirmationCodeModal, useConfirmCode } from "@/feature/confirm-code-modal";
import { useCountdownTimer } from "@/feature/countdown-button";

import { confirmCodeSignUp, countdownTimerSignUp } from "../../model/confirm-code";

const repeatSmsError = createEvent();

sample({
  source: repeatSmsError,
  target: openAuthModal.supportContactsOnSignUp,
});

export const SignUpConfirmationCode: FC = () => {
  const handlers = useEvent({
    authModalCloseClicked,
    supportContactsOnSignUp: openAuthModal.supportContactsOnSignUp,
  });

  const confirmCode = useConfirmCode(confirmCodeSignUp);
  const countdownTimer = useCountdownTimer(countdownTimerSignUp);

  return (
    <ConfirmationCodeModal
      onSupportContactsClick={handlers.supportContactsOnSignUp}
      onClose={handlers.authModalCloseClicked}
      countdownTimerModel={countdownTimer}
      confirmCode={confirmCode}
    />
  );
};
