import { createEffect, createStore, sample } from "effector";
import { createGate } from "effector-react";

import { Course } from "@app/api";

import { CourseApi } from "@/shared/api";

export const PopularCoursesGate = createGate();

const getPopularCoursesFx = createEffect(async () => {
  const course = await CourseApi.getPopularCourses();
  return course;
});

export const $popularCourses = createStore<Course[]>([]).on(
  getPopularCoursesFx.doneData.map((courses) => courses),
  (_, courses) => courses,
);

export const $isPopularCourseLoading = getPopularCoursesFx.pending;

sample({
  clock: PopularCoursesGate.open,
  target: getPopularCoursesFx,
});
