import clsx from "clsx";
import React, { useRef, useEffect, useImperativeHandle, forwardRef } from "react";

import { CONFIRMATION_CODE_LENGTH } from "@/shared/constants";

const allowedCharactersValues = ["alpha", "numeric", "alphanumeric"] as const;

type Props = {
  allowedCharacters?: (typeof allowedCharactersValues)[number];
  ariaLabel?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  length?: number;
  containerClassName?: string;
  inputClassName?: string;
  isPassword?: boolean;
  onChange: (res: string) => void;
  isError: boolean;
};

type InputMode = "text" | "numeric";

type InputType = "text" | "password" | "tel";

type InputProps = {
  type: InputType;
  inputMode: InputMode;
  pattern: string;
  min?: string;
  max?: string;
};

export type AuthCodeRef = {
  focus: () => void;
  clear: () => void;
};

const propsMap: { [key: string]: InputProps } = {
  alpha: {
    type: "text",
    inputMode: "text",
    pattern: "[a-zA-Z]{1}",
  },

  alphanumeric: {
    type: "text",
    inputMode: "text",
    pattern: "[a-zA-Z0-9]{1}",
  },

  numeric: {
    type: "tel", // https://github.com/facebook/react/issues/16554
    inputMode: "numeric",
    pattern: "[0-9]{1}",
    min: "0",
    max: "9",
  },
};

export const CodeInput = forwardRef<AuthCodeRef, Props>(
  (
    {
      allowedCharacters = "alphanumeric",
      ariaLabel,
      autoFocus = true,
      disabled,
      length = CONFIRMATION_CODE_LENGTH,
      containerClassName,
      inputClassName,
      isPassword = false,
      onChange,
      isError,
    },
    ref,
  ) => {
    if (Number.isNaN(length) || length < 1) {
      throw new Error("Length should be a number and greater than 0");
    }

    if (!allowedCharactersValues.some((value) => value === allowedCharacters)) {
      throw new Error("Invalid value for allowedCharacters. Use alpha, numeric, or alphanumeric");
    }

    const inputsRef = useRef<Array<HTMLInputElement>>([]);
    const inputProps = propsMap[allowedCharacters];

    const sendResult = () => {
      const res = inputsRef.current.map((input) => input.value).join("");

      if (onChange) {
        onChange(res);
      }
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputsRef.current) {
          inputsRef.current[0].focus();
        }
      },
      clear: () => {
        if (inputsRef.current) {
          for (let i = 0; i < inputsRef.current.length; i += 1) {
            inputsRef.current[i].value = "";
          }
          inputsRef.current[0].focus();
        }
        sendResult();
      },
    }));

    useEffect(() => {
      if (autoFocus) {
        inputsRef.current[0].focus();
      }
    }, []);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, nextElementSibling },
      } = e;
      if (value.length > 1) {
        e.target.value = value.charAt(0);
        if (nextElementSibling !== null) {
          (nextElementSibling as HTMLInputElement).focus();
        }
      } else if (value.match(inputProps.pattern)) {
        if (nextElementSibling !== null) {
          (nextElementSibling as HTMLInputElement).focus();
        }
      } else {
        e.target.value = "";
      }
      sendResult();
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;
      const target = e.target as HTMLInputElement;
      if (key === "Backspace") {
        if (target.value === "") {
          if (target.previousElementSibling !== null) {
            const t = target.previousElementSibling as HTMLInputElement;
            t.value = "";
            t.focus();
            e.preventDefault();
          }
        } else {
          target.value = "";
        }
        sendResult();
      }
    };

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      e.target.select();
    };

    const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedValue = e.clipboardData.getData("Text");

      let currentInput = 0;

      for (let i = 0; i < pastedValue.length; i += 1) {
        const pastedCharacter = pastedValue.charAt(i);
        const currentValue = inputsRef.current[currentInput].value;
        if (pastedCharacter.match(inputProps.pattern)) {
          if (!currentValue) {
            inputsRef.current[currentInput].value = pastedCharacter;
            if (inputsRef.current[currentInput].nextElementSibling !== null) {
              (inputsRef.current[currentInput].nextElementSibling as HTMLInputElement).focus();
              currentInput += 1;
            }
          }
        }
      }
      sendResult();

      e.preventDefault();
    };

    const inputs = [];
    for (let i = 0; i < length; i += 1) {
      inputs.push(
        <input
          key={i}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onFocus={handleOnFocus}
          onPaste={handleOnPaste}
          {...inputProps}
          type={isPassword ? "password" : inputProps.type}
          ref={(el: HTMLInputElement) => {
            inputsRef.current[i] = el;
          }}
          maxLength={1}
          placeholder="9"
          className={clsx(inputClassName, { "bg-m-incorrect": isError })}
          autoComplete={i === 0 ? "one-time-code" : "off"}
          aria-label={ariaLabel ? `${ariaLabel}. Character ${i + 1}.` : `Character ${i + 1}.`}
          disabled={disabled}
        />,
      );
    }

    return <div className={containerClassName}>{inputs}</div>;
  },
);
