import loadable from "@loadable/component";

export { FilterTopics } from "./ui/filters";

export const AsideFilters = loadable(
  () => import("./ui/aside-filters").then((component) => component.AsideFilters),
  {
    ssr: false,
  },
);
export const SettingFilterButton = loadable(
  () => import("./ui/mobile-filters").then((component) => component.SettingFilterButton),
  {
    ssr: false,
  },
);

export { AppliedFiltersList } from "./ui/aplied-filters-list";
export { filterBuilder } from "./lib/filter-builder";
