import clsx from "clsx";
import { MouseEvent } from "react";

import styles from "./chip.module.scss";

type ChipProps = {
  text: string;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const Chip = ({ text, className, onClick }: ChipProps) => {
  return (
    <span role="presentation" onClick={onClick} className={clsx(styles.Chip, className)}>
      {text}
    </span>
  );
};
