import { createLessonTest } from "@/feature/lesson-test";

import { resetTest, $correctTestAnswers, $tests, allTestStatusesUpdated } from "../../../model";

export const {
  resultCalculating,
  resultCalculated,
  certFormCanceled,
  testPassed,
  testRepeat,
  testPassedChecked,
  $selectedAnswers,
  $resultPercentageTest,
  $testResult,
  $isResultCalculated,
  $isTestPassed,
  answerChecked,
} = createLessonTest({ allTestStatusesUpdated, resetTest, $correctTestAnswers, $tests });
