import { sample } from "effector";

import { analyticsModel } from "@/shared/analytics";

import { $hasUserMuseSubscription } from "./model/user-product";

sample({
  clock: $hasUserMuseSubscription,
  fn: (hasSubscription) => (hasSubscription ? 1 : 0),
  target: analyticsModel.$analyticsHasSubscription,
});
