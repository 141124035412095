import clsx from "clsx";
import { FC, ReactNode } from "react";

import { ButtonRounded } from "@/shared/ui";
import type { ButtonRoundedProps } from "@/shared/ui";
import SuccessIcon from "@/shared/ui/assets/images/icons/done-green.svg";
import ErrorIcon from "@/shared/ui/assets/images/icons/error-status.svg";
import WarningIcon from "@/shared/ui/assets/images/icons/warning-status.svg";

import { Modal } from "../modal";

type ButtonsProps = {
  onClick: () => void;
  title: string;
  className?: string;
  variant?: ButtonRoundedProps["variant"];
};

type NotificationType = "success" | "error" | "warning";

type NotificationBodyProps = {
  message?: string | ReactNode;
  title?: string | ReactNode;
  renderButtons?: Array<ButtonsProps>;
  type: NotificationType;
  bodyClassName?: string;
  caption?: string;
};

type NotificationProps = {
  onClose?: () => void;
} & NotificationBodyProps;

type ModalNotificationProps = {
  type: NotificationType;
} & NotificationProps;

const getDefaultTitle = (type: ModalNotificationProps["type"]) => {
  const title = {
    error: "Ошибка",
    success: "Успех",
    warning: "Внимание",
  };

  return title[type];
};

const renderIcon = (type: ModalNotificationProps["type"]) => {
  const icons = {
    success: SuccessIcon,
    error: ErrorIcon,
    warning: WarningIcon,
  };

  return (
    <img
      src={icons[type]}
      alt={type}
      className="m-auto w-[48px] h-[48px] 3xl:w-[54px] 3xl:h-[54px] mb-mn-20 3xl:mb-mn-26"
    />
  );
};

const COLOR_CLASSES = {
  success: "text-m-success",
  error: "text-m-error",
  warning: "text-m-warning",
};

export const ModalNotificationBody: FC<NotificationBodyProps> = ({
  message,
  type,
  title,
  renderButtons = [],
  bodyClassName,
  caption,
}) => {
  return (
    <Modal.Body
      className={clsx("text-center pb-[80px] mt-[32px] whitespace-pre-line", bodyClassName)}
    >
      {renderIcon(type)}
      <Modal.Title className={clsx(COLOR_CLASSES[type], "mb-mn-20 3xl:mb-mn-26")}>
        {title || getDefaultTitle(type)}
      </Modal.Title>
      <Modal.Description className="mb-mn-20 3xl:mb-mn-26">{message}</Modal.Description>
      {caption && <Modal.Caption>{caption}</Modal.Caption>}

      <Modal.Buttons className="flex justify-center">
        {renderButtons.map(({ onClick, title: btnTitle, className, variant }, index) => (
          <ButtonRounded
            key={index}
            onClick={onClick}
            variant={variant || "secondary"}
            className={className}
          >
            {btnTitle}
          </ButtonRounded>
        ))}
      </Modal.Buttons>
    </Modal.Body>
  );
};

export const ModalNotification: FC<ModalNotificationProps> = ({
  onClose,
  message,
  caption,
  title,
  renderButtons = [],
  type,
  bodyClassName,
}) => {
  if (renderButtons?.length > 2) {
    throw new Error("ModalNotification: buttons can't be more than 2");
  }

  return (
    <Modal.Surface>
      {onClose && <Modal.Header close={onClose} />}
      <ModalNotificationBody
        bodyClassName={bodyClassName}
        type={type}
        caption={caption}
        title={title}
        message={message}
        renderButtons={renderButtons}
      />
    </Modal.Surface>
  );
};
