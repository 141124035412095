import clsx from "clsx";
import React, { ReactNode } from "react";

import styles from "./radio.module.scss";

type RadioProps = {
  id: string;
  checked: boolean;
  onChange: () => void;
  className?: {
    container?: string;
    radio?: string;
    label?: string;
    circleCheck?: string;
  };
  status: "success" | "fail" | "default";
  label?: ReactNode | string;
  disabled?: boolean;
  hasCircle?: boolean;
};

export const Radio = ({
  id,
  checked,
  onChange,
  className,
  status,
  label,
  disabled,
  hasCircle = true,
}: RadioProps) => {
  const successStatus = status === "success" && checked;
  const failStatus = status === "fail" && checked;
  const defaultStatus = status === "default" && checked;

  const classes = clsx(
    styles.Radio,
    {
      "bg-m-gradient border-none": defaultStatus,
      "border border-m-dark bg-none": successStatus,
      "border border-m-dark-magenta bg-none": failStatus,
    },
    className?.radio,
    {
      "border-m-dark opacity-20": disabled,
    },
  );

  return (
    <div className={clsx("flex", className?.container)}>
      <button
        role="radio"
        id={`radio_${id}`}
        data-testid={`radio_${id}`}
        aria-labelledby={id}
        aria-checked={checked}
        className={classes}
        onClick={onChange}
        disabled={disabled}
      >
        {hasCircle && checked && (
          <div
            className={clsx(
              styles.CircleCheck,
              {
                "bg-m-white": defaultStatus,
                "bg-m-dark": successStatus,
                "bg-m-dark-magenta": failStatus,
              },
              {
                "bg-m-dark border-m-dark": disabled,
              },
              className?.circleCheck,
            )}
          />
        )}
      </button>
      {label && (
        <label
          id={`label_${id}`}
          data-testid={`label_${id}`}
          className={clsx(styles.Label, className?.label, {
            "text-m-dark": disabled,
            "text-m-orange": checked,
          })}
          role="presentation"
          aria-label="radio"
          onClick={(e) => {
            if (disabled) return;
            e.preventDefault();
            onChange();
          }}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  );
};
