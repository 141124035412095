import clsx from "clsx";
import React, { ReactNode } from "react";

import { parse } from "@/shared/lib/html";
import { LandingBlock, Typography } from "@/shared/ui";
import fashionAuthorImage from "@/shared/ui/assets/images/spring-landing/3sc-1920.webp";
import fashionAuthorImageMobile from "@/shared/ui/assets/images/spring-landing/3sc-mobile.webp";
import poetryAuthorImage from "@/shared/ui/assets/images/spring-landing/4sc-1920.webp";
import poetryAuthorImageMobile from "@/shared/ui/assets/images/spring-landing/4sc-mobile.webp";
import choreographerAuthorImage from "@/shared/ui/assets/images/spring-landing/5sc-1920.webp";
import choreographerAuthorImageMobile from "@/shared/ui/assets/images/spring-landing/5sc-mobile.webp";
import jazzAuthorImage from "@/shared/ui/assets/images/spring-landing/7sc-1920.webp";
import jazzAuthorImageMobile from "@/shared/ui/assets/images/spring-landing/7sc-mobile.webp";
import kruzhokAuthorImage from "@/shared/ui/assets/images/spring-landing/8sc-1920.webp";
import kruzhokAuthorImageMobile from "@/shared/ui/assets/images/spring-landing/8sc-mobile.webp";
import beInOpen from "@/shared/ui/assets/images/spring-landing/be-in-open.svg";

import styles from "./spring-landing.module.scss";

type AuthorPreviewProps = {
  img: { desktop: string; mobile: string };
  authorName: string;
  children: ReactNode;
};

const AuthorPreview = ({ img, children, authorName }: AuthorPreviewProps) => {
  return (
    <div className="w-full md:w-[65%] relative font-bold uppercase">
      <picture>
        <source srcSet={img.desktop} media="(min-width: 768px)" />
        <img className="object-cover w-full h-full" src={img.mobile} alt={authorName} />
      </picture>
      <div className="absolute left-mn-20 bottom-mn-26 md:left-mn-26 md:bottom-mn-38 xl:left-mn-74 2xl:bottom-[58px] 2xl:left-mn-111">
        {children}
      </div>
    </div>
  );
};

const DefaultAuthorDescription = ({
  authorDescription,
  courseDescription,
}: {
  authorDescription: string;
  courseDescription: string;
}) => {
  return (
    <>
      <Typography tag="h6" size="m-h6" className="mb-[15px] 2xl:mb-[22px] text-m-arctic">
        {parse(authorDescription)}
      </Typography>
      <Typography tag="h3" size="m-h3" className="text-m-white">
        {parse(courseDescription)}
      </Typography>
    </>
  );
};

export const AuthorDescriptionTopicRhombusTop = () => {
  return (
    <LandingBlock.RhombusBlock
      renderTopAuthor={
        <AuthorPreview
          authorName="Фантине"
          img={{ desktop: jazzAuthorImage, mobile: jazzAuthorImageMobile }}
        >
          <DefaultAuthorDescription
            authorDescription="Фантине, финалистка&lt;br&gt;шоу «Голос–9»"
            courseDescription="Джазовый вокал"
          />
        </AuthorPreview>
      }
      topClassBackground={styles.JazzBg}
      bottomClassBackground={styles.PoetryBg}
      renderBottomAuthor={
        <AuthorPreview
          authorName="Леонид Клейн"
          img={{ desktop: poetryAuthorImage, mobile: poetryAuthorImageMobile }}
        >
          <DefaultAuthorDescription
            authorDescription="Леонид Клейн, филолог, писатель"
            courseDescription="Зачем читать стихи?"
          />
        </AuthorPreview>
      }
      renderTopDescription="Считаешь Эми Уайнхаус иконой современного джаза и&nbsp;мечтаешь петь как Уитни Хьюстон? Авторский курс Фантине,
      российско-доминиканской певицы, солистки Московского джазового оркестра и&nbsp;финалистки шоу &laquo;Голос&mdash;9&raquo; на&nbsp;Первом
      канале&nbsp;&mdash; навигатор в&nbsp;мире первых шагов для начинающего вокалиста и&nbsp;возможность погрузиться в&nbsp;атмосферу блюзовых
      ритмов начала XX&nbsp;века. Фантине поделится ценными инсайтами и&nbsp;творческими лайфхаками&nbsp;&mdash; от&nbsp;поиска вдохновения и&nbsp;вокального
      дыхания до&nbsp;написания музыкальных композиций"
      renderBottomDescription="Цитировать Люсю Чеботину уже не&nbsp;модно.
      Теперь на&nbsp;лучших светских раутах принято разговаривать стихами Ахматовой и&nbsp;Блока,
      так что ответ на&nbsp;вопрос: &laquo;Зачем читать стихи?&raquo;&nbsp;&mdash; лежит на&nbsp;поверхности.
      Приобретение душевной тонкости и&nbsp;отличного вкуса идёт бонусом вместе с&nbsp;курсом от&nbsp;филолога и&nbsp;писателя Леонида Клейна"
    />
  );
};

export const AuthorDescriptionTopicRhombusBottom = () => {
  return (
    <LandingBlock.RhombusBlock
      renderTopAuthor={
        <AuthorPreview
          authorName="Максим Муратов"
          img={{ desktop: fashionAuthorImage, mobile: fashionAuthorImageMobile }}
        >
          <DefaultAuthorDescription
            authorDescription="Максим Муратов, fashion-журналист"
            courseDescription="Мода XX века:&lt;br&gt;мечты о будущем"
          />
          <img
            className="absolute scale-[.6] md:scale-75 2xl:scale-100 -right-[60px] -top-[18px] md:-top-[140px] xl:-top-[50px] xl:-right-[86px] 2xl:w-[117px] 2xl:-top-[38px] 2xl:-right-[10px] object-none"
            src={beInOpen}
            alt="Be In Open"
          />
        </AuthorPreview>
      }
      renderBottomAuthor={
        <AuthorPreview
          authorName="Юля Шве"
          img={{ desktop: kruzhokAuthorImage, mobile: kruzhokAuthorImageMobile }}
        >
          <DefaultAuthorDescription
            authorDescription="Юля Шве, художница, арт-директор"
            courseDescription="Kruzhok Workshop:&lt;br&gt;создание одежды&lt;br&gt;в технике апсайкл"
          />
        </AuthorPreview>
      }
      topClassBackground={styles.FashionBg}
      bottomClassBackground={styles.KruzhokBg}
      renderTopDescription="Модными нарядами от-кутюр легко удивить на&nbsp;светской вечеринке. А&nbsp;блеснуть
            знаниями о&nbsp;влиянии русской эстетики на&nbsp;парижскую моду, стиле New Look, который
            придумал великий французский кутюрье Кристиан Диор, и&nbsp;арт-концептуализме Мартина
            Маржелы может не&nbsp;каждый. Курс самого модного журналиста и&nbsp;fashion-критика
            Максима Муратова не&nbsp;только научит разбираться в&nbsp;истории моды
            и&nbsp;современных трендах, но&nbsp;и&nbsp;вдохновит на&nbsp;fashion-эксперименты
            со&nbsp;стилем"
      renderBottomDescription="Тренд на&nbsp;апсайклинг не&nbsp;сбавляет обороты. С&nbsp;этой техникой работают самые
      модные бренды современности. Miu Miu, Balenciaga, Marni и&nbsp;JW&nbsp;Anderson создают вещи из&nbsp;винтажных
      сокровищ, выпускают коллекции из&nbsp;ранее использованной одежды и&nbsp;отшивают лимитированные капсулы из&nbsp;тканей
      прошлых сезонов. На&nbsp;авторском курсе Юли Шве, художницы, арт-директора творческого объединения &laquo;Кружок&raquo;,
      вы&nbsp;попробуете себя в&nbsp;роли fashion-дизайнера, создадите модные джинсы в&nbsp;технике
      апсайкл и&nbsp;станете звездой модной хроники"
    />
  );
};

export const AuthorDescriptionTopicSingle = () => {
  return (
    <section className="bg-m-dark-blue">
      <div className="flex flex-col md:flex-row">
        <AuthorPreview
          authorName="Владимир Варнава"
          img={{ desktop: choreographerAuthorImage, mobile: choreographerAuthorImageMobile }}
        >
          <DefaultAuthorDescription
            authorDescription="Владимир Варнава, хореограф&lt;br&gt;Мариинского театра"
            courseDescription="Современная &lt;br&gt;хореография"
          />
        </AuthorPreview>

        <div
          className={clsx(
            "py-mn-74 xl:py-0 flex items-center w-full md:w-[65%]",
            styles.ChoreographerBg,
          )}
        >
          <Typography
            tag="p"
            size="m-lead"
            className="text-m-white px-mn-20 md:px-mn-26 xl:pl-[58px] xl:pr-[120px] 2xl:pl-[88px] 2xl:pr-[180px] leading-[130%]"
          >
            Отличать Айседору Дункан от&nbsp;Марты Грэм, разобраться, как повлиял
            на&nbsp;современный танец Вацлав Нижинский, понять, в&nbsp;чём разница между балетом
            и&nbsp;&laquo;контемпорари&raquo;, и&nbsp;больше не&nbsp;засыпать на&nbsp;современных
            балетных постановках&nbsp;&mdash; задача-минимум, с&nbsp;которой вы&nbsp;точно
            справитесь после просмотра курса от&nbsp;невероятного молодого танцовщика
            и&nbsp;хореографа великого Мариинского театра Владимира Варнавы
          </Typography>
        </div>
      </div>
    </section>
  );
};
