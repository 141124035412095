import { useEvent } from "effector-react/scope";

import { useCallbackOnEnter } from "@/shared/lib/keyboard";
import { ModalNotification } from "@/shared/ui";

import { authModalCloseClicked } from "@/app/authorization-modals/model";

import { deleteStudentProfileAttempted } from "../model/delete-profile";

export const DeleteStudentProfileModal = () => {
  const handlers = useEvent({
    close: authModalCloseClicked,
    delete: deleteStudentProfileAttempted,
  });

  useCallbackOnEnter(handlers.close);

  return (
    <ModalNotification
      type="warning"
      onClose={handlers.close}
      title={
        <>
          Вы точно хотите
          <br /> удалить аккаунт?
        </>
      }
      message={
        <>
          К сожалению, вместе с&nbsp;учётной записью будет&nbsp;удалён весь прогресс обучения
          и&nbsp;полученные сертификаты
        </>
      }
      renderButtons={[
        {
          title: "Ещё подумаю",
          onClick: () => handlers.close(),
          variant: "primary",
        },
        {
          title: "Удалить",
          onClick: () => handlers.delete(),
          variant: "secondary",
        },
      ]}
    />
  );
};
