import * as React from "react";

import { Spinner } from "@/shared/ui";
import { ReactComponent as CircleCheck } from "@/shared/ui/assets/images/icons/circle-check-gradient.svg";

import { useCourseProgress, useMapNumberToRoundedPercentage } from "../../hooks";
import styles from "./styles.module.scss";

type CourseProgressIndicatorProps = {
  courseId: string;
};

type PercentageIndicatorProps = {
  radius?: number;
  value: number;
  content?: React.ReactNode;
};

export const ProgressPercentageIndicator = ({
  value,
  radius = 20.5,
  content,
}: PercentageIndicatorProps) => {
  const diameter = radius * 2;

  const cx = diameter / 2;
  const cy = cx;

  const circumference = 2 * Math.PI * radius;
  const strokeOffset = circumference - (circumference * value) / 100;

  return (
    <div className={styles.Wrapper} style={{ height: diameter, width: diameter }}>
      <svg className={styles.SVG} height={diameter} width={diameter}>
        <defs>
          <linearGradient id="gradient">
            <stop offset="0%" stopColor="#FF8000" />
            <stop offset="100%" stopColor="#FF3C8A" />
          </linearGradient>
        </defs>

        {value !== 100 && (
          <circle
            cx={cx}
            cy={cy}
            r="19"
            stroke="#EAEAF0"
            strokeWidth="2"
            fill="#FFFFFF"
            fillOpacity={0}
          />
        )}

        <circle
          className={value === 100 ? "progress-done" : "progress-pending"}
          cx={cx}
          cy={cy}
          r={radius - 2}
          stroke="url(#gradient)"
          strokeWidth={value !== 100 ? "4" : "2"}
          fill="#FFFFFF"
          fillOpacity={0}
          strokeDasharray={circumference}
          strokeDashoffset={strokeOffset}
        />
      </svg>

      <div className={styles.Content}>
        {content || (
          <span className="font-sans text-m-blue font-semibold text-m-xs leading-[18px]">
            {value}%
          </span>
        )}
      </div>
    </div>
  );
};

const CourseProgressIndicatorContent = ({ courseId }: CourseProgressIndicatorProps) => {
  const { fetchState, courseProgress } = useCourseProgress(courseId);
  const courseProgressRatio = useMapNumberToRoundedPercentage(courseProgress);

  if (fetchState.done || fetchState.refetching) {
    return <ProgressPercentageIndicator value={courseProgressRatio} />;
  }

  if (fetchState.pending) {
    return <Spinner.Clip />;
  }

  return null;
};

const IconWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-m-white rounded-[12px] w-[35px] h-[35px] 3xl:w-[43px] 3xl:h-[43px] flex items-center justify-center">
    {children}
  </div>
);

export const CourseCompletionIndicator = () => (
  <IconWrapper>
    <div className="scale-[0.9] 3xl:scale-[1]">
      <CircleCheck />
    </div>
  </IconWrapper>
);

export const CourseProgressIndicator = ({ courseId }: CourseProgressIndicatorProps) => (
  <IconWrapper>
    <div className="scale-[0.68] 3xl:scale-[0.8]">
      <CourseProgressIndicatorContent courseId={courseId} />
    </div>
  </IconWrapper>
);
