import { createEffect, sample } from "effector";
import posthog from "posthog-js";

import { userSettled } from "@/entities/user";

import { CONFIG } from "@/shared/config";

export const initializePostHog = () => {
  posthog.init(CONFIG.POSTHOG_KEY, {
    api_host: CONFIG.POSTHOG_URL,
  });
};

sample({
  clock: userSettled,
  fn: (user) => user?.id,
  target: createEffect((id: string | undefined) => {
    posthog.identify(id);
  }),
});
