import { useEvent, useStore } from "effector-react/scope";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

import {
  $currentUser,
  getFirstNameSchema,
  getLastNameSchema,
  gotNewPersonalInfo,
  userFormSchema,
} from "@/entities/user";

import { UserInfoInput } from "@/shared/api";
import { errorMessages } from "@/shared/lib/form";
import { isEmpty } from "@/shared/lib/object";
import { trimSpaceAndDash } from "@/shared/lib/strings";
import { ButtonRounded, Checkbox, Input } from "@/shared/ui";
import { ReactComponent as Bin } from "@/shared/ui/assets/images/icons/bin.svg";

import { openAuthModal } from "@/app/authorization-modals/model";

import { $editMode, changedEditMode } from "../../model/edit-mode";
import { ProfileField } from "./profile-field";

const dateRegExp = /^(0?[1-9]|[12][0-9]|3[01])[.](0?[1-9]|1[012])[.](19|20)\d\d$/gm;

const profileFormSchema = object().shape({
  email: userFormSchema.email,
  first_name: getFirstNameSchema(),
  last_name: getLastNameSchema(),
  birthday: string().matches(dateRegExp, {
    message: errorMessages.date,
    excludeEmptyString: true,
  }),
  gender: string(),
});

const SHOW_ERRORS_DELAY = 1000; // 1s

export const SectionForm = () => {
  const user = useStore($currentUser);

  const defaultValues = {
    email: user?.email,
    first_name: user?.first_name,
    last_name: user?.last_name,
    birthday: user?.birthday,
    gender: user?.gender,
  };

  const { control, handleSubmit, formState, setValue, getValues, trigger, reset, clearErrors } =
    useForm<UserInfoInput>({
      defaultValues,
      resolver: yupResolver(profileFormSchema),
      delayError: SHOW_ERRORS_DELAY,
      mode: "all",
    });

  const editMode = useStore($editMode);
  const changedEditModeEvent = useEvent(changedEditMode);

  const gotNewPersonalInfoEvent = useEvent(gotNewPersonalInfo);
  const changePasswordEvent = useEvent(openAuthModal.profilePasswordChange);
  const deleteUserEvent = useEvent(openAuthModal.deleteUser);

  const handleOnSaveClick = () => {
    const fn = getValues("first_name");
    const ln = getValues("last_name");
    if (fn) setValue("first_name", trimSpaceAndDash(fn));
    if (ln) setValue("last_name", trimSpaceAndDash(ln));
    handleSubmit(gotNewPersonalInfoEvent)();
    changedEditModeEvent(false);
  };

  const handleOnCancelClick = () => {
    reset();
    changedEditModeEvent(false);
    setTimeout(() => {
      clearErrors();
    }, SHOW_ERRORS_DELAY);
  };

  const handleOnFieldClick = (fieldName: string) => {
    if (editMode) return;
    changedEditModeEvent(true);
    if (fieldName === "gender") {
      setTimeout(() => {
        setValue("gender", getValues("gender"), { shouldDirty: true, shouldTouch: true });
      }, 0);
    }
  };

  return (
    <form
      className="contents"
      onSubmit={(e) => {
        e.preventDefault();
        handleOnSaveClick();
      }}
    >
      <Controller
        name="first_name"
        control={control}
        render={({ fieldState, field }) => {
          return (
            <ProfileField
              label="Имя"
              name="first_name"
              className="overflow-hidden"
              errorMessage={editMode ? fieldState.error?.message : ""}
              disabled={!editMode}
              onBlur={() => {
                if (field.value) setValue("first_name", trimSpaceAndDash(field.value));
                trigger("first_name");
              }}
              onClick={handleOnFieldClick}
            >
              <Input
                onChange={field.onChange}
                value={field.value || ""}
                placeholder=""
                autoComplete="off"
                maxLength={20}
                onKeyDown={(e) => {
                  if (!field.value || field.value.length === 0) return;
                  const target = e.target as HTMLInputElement;
                  const prevChar = field.value[(target.selectionStart ?? 0) - 1];
                  const SpaceAndDash = " -";
                  if (SpaceAndDash.includes(e.key) && prevChar === e.key) {
                    e.preventDefault();
                  }
                }}
              />
            </ProfileField>
          );
        }}
      />
      <Controller
        name="last_name"
        control={control}
        render={({ fieldState, field }) => {
          return (
            <ProfileField
              label="Фамилия"
              name="last_name"
              className="overflow-hidden"
              errorMessage={editMode ? fieldState.error?.message : ""}
              disabled={!editMode}
              onBlur={() => {
                if (field.value) setValue("last_name", trimSpaceAndDash(field.value));
                trigger("last_name");
              }}
              onClick={handleOnFieldClick}
            >
              <Input
                onChange={field.onChange}
                value={field.value || ""}
                placeholder=""
                autoComplete="off"
                maxLength={20}
                onKeyDown={(e) => {
                  if (!field.value || field.value.length === 0) return;
                  const target = e.target as HTMLInputElement;
                  const prevChar = field.value[(target.selectionStart ?? 0) - 1];
                  const SpaceAndDash = " -";
                  if (SpaceAndDash.includes(e.key) && prevChar === e.key) {
                    e.preventDefault();
                  }
                }}
              />
            </ProfileField>
          );
        }}
      />
      <Controller
        name="birthday"
        control={control}
        render={({ fieldState, field }) => {
          return (
            <ProfileField
              label="День рождения"
              name="birthday"
              errorMessage={editMode ? fieldState.error?.message : ""}
              disabled={!editMode}
              className="pr-[30px] lg:pr-[72px] z-10"
              onClick={handleOnFieldClick}
            >
              <Input.InputDate
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value || ""}
                isError={fieldState.invalid}
                disabled={!editMode}
              />
            </ProfileField>
          );
        }}
      />
      <Controller
        name="gender"
        control={control}
        render={({ fieldState, field }) => {
          const FEMALE = {
            title: "женский",
            value: "female",
          };
          const MALE = {
            title: "мужской",
            value: "male",
          };

          return (
            <ProfileField
              label="Пол"
              name="gender"
              errorMessage={fieldState.error?.message}
              disabled={!editMode}
              onClick={handleOnFieldClick}
            >
              <div
                tabIndex={0}
                className="flex whitespace-nowrap items-center flex-row text-m-white"
                role="button"
              >
                <Checkbox
                  className="ml-0 md:ml-4 mr-mv-0 mt-0 flex-2 text-m-white font-m-sb-display leading-5 text-m-sm"
                  checkBoxClassName="bg-m-white"
                  status="default"
                  id={FEMALE.title}
                  checked={field.value === FEMALE.value}
                  onChange={() =>
                    field.onChange({
                      target: { value: field.value === FEMALE.value ? "" : FEMALE.value },
                    })
                  }
                  disabled={!editMode}
                  title={FEMALE.title}
                />
                <Checkbox
                  className="ml-4 mr-mv-0 flex-2 text-m-white font-m-sb-display leading-5 text-m-sm"
                  checkBoxClassName="bg-m-white"
                  status="default"
                  id={MALE.title}
                  checked={field.value === MALE.value}
                  onChange={() =>
                    field.onChange({
                      target: { value: field.value === MALE.value ? "" : MALE.value },
                    })
                  }
                  disabled={!editMode}
                  title={MALE.title}
                />
              </div>
            </ProfileField>
          );
        }}
      />
      <Controller
        name="email"
        control={control}
        render={({ fieldState, field }) => {
          return (
            <ProfileField
              label="Адрес электронной почты"
              name="email"
              errorMessage={editMode ? fieldState.error?.message : ""}
              disabled
            >
              <Input
                onChange={field.onChange}
                value={field.value || ""}
                placeholder=""
                autoComplete="off"
                maxLength={20}
              />
            </ProfileField>
          );
        }}
      />
      <ProfileField
        label="Пароль"
        name="password"
        disabled
        hasEditButton={!editMode}
        onEditButtonClick={changePasswordEvent}
      >
        <Input
          onChange={() => ""}
          value="********"
          placeholder="********"
          className="lg:h-6 lg:self-baseline"
        />
      </ProfileField>
      <div className="flex flex-col text-center w-full mt-mv-2 lg:mt-5 xl:mb-[77px] lg:flex-row">
        {!editMode && (
          <div className="flex justify-center w-full lg:justify-start">
            <ButtonRounded
              variant="secondary"
              onClick={() => {
                changedEditModeEvent(true);
              }}
              className="mr-2"
            >
              Редактировать
            </ButtonRounded>
          </div>
        )}
        {editMode && (
          <>
            <div>
              <ButtonRounded
                variant="quaternary"
                disabled={
                  formState.isSubmitting || !formState.isValid || isEmpty(formState.dirtyFields)
                }
                className="mr-4"
                type="submit"
              >
                Сохранить
              </ButtonRounded>
              <ButtonRounded variant="secondary" onClick={handleOnCancelClick}>
                Отменить
              </ButtonRounded>
            </div>
            <div className="mt-[32px] lg:mt-0 lg:ml-auto">
              <ButtonRounded variant="legacy" onClick={deleteUserEvent}>
                <span className="inline-flex items-center">
                  <Bin className="inline-block mr-[10px]" />
                  <span className="relative top-[1px]">Удалить аккаунт</span>
                </span>
              </ButtonRounded>
            </div>
          </>
        )}
      </div>
    </form>
  );
};
