import clsx from "clsx";
import { FC } from "react";

import orangeGradient from "@/shared/ui/assets/images/home/background_gradient_orange.webp";
import purpleGradient from "@/shared/ui/assets/images/home/background_gradient_purple.webp";
import logoBg from "@/shared/ui/assets/images/home/muse_background.svg";

type BackgroundProps = {
  variant?: "normal" | "reverse";
};

export const Background: FC<BackgroundProps> = ({ children, variant = "normal" }) => {
  const backgroundClasses = clsx("fixed w-screen h-screen bg-m-blue z-0", {
    "transofrm rotate-[180deg] bg-background-reverse md:bg-background-reverse":
      variant === "reverse",
  });

  const gradientLayerClasses = clsx("absolute h-full w-full", {
    "relative right-[10%]": variant === "reverse",
  });

  const firstGradientClasses = clsx(`absolute
    bg-no-repeat
    bg-contain
    w-[580px]
    h-[580px]
    left-0
    top-0
    transform
    -translate-x-[30%]
    -translate-y-[20%]
    lg:w-[860px]
    lg:h-[860px]
    2xl:h-[120%]
    2xl:w-[1000px]
    2xl:-translate-y-[10%]
    2xl:-translate-x-[10%]`);

  const secondGradientClasses = clsx(`absolute
    bg-no-repeat
    bg-contain
    w-[580px]
    h-[580px]
    right-0
    bottom-0
    transform
    translate-x-[50%]
    translate-y-[20%]
    lg:w-[860px]
    lg:h-[860px]
    2xl:h-[120%]
    2xl:w-[1000px]
    2xl:translate-y-[10%]
    2xl:translate-x-[50%]`);

  return (
    <>
      <div className={backgroundClasses}>
        <div
          className="absolute top-0 left-0 w-[140vw] h-screen bg-[length:780px] bg-no-repeat bg-top-center transform -translate-x-[40vw] md:bg-center md:bg-[length:80%] md:-translate-x-[25%]"
          style={{ backgroundImage: `url(${logoBg})` }}
        />
        <div className={gradientLayerClasses}>
          <div
            className={firstGradientClasses}
            style={{
              backgroundImage: `url(${variant === "normal" ? purpleGradient : orangeGradient})`,
            }}
          />
          <div
            className={secondGradientClasses}
            style={{
              backgroundImage: `url(${variant === "normal" ? orangeGradient : purpleGradient})`,
            }}
          />
        </div>
      </div>
      <div className="relative z-10">{children}</div>
    </>
  );
};
