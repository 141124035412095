import { CONFIG } from "@/shared/config";
import { Cookies } from "@/shared/lib/auth-lib";

import { DEFAULT_API_ERROR_MESSAGE, DEFAULT_API_ERROR_TYPE } from "../constants";
import { CommonError } from "../types";
import { AuthAPIError } from "./auth-api-error";
import {
  fetchCsrfToken,
  $cookiesForRequest,
  Answer,
  request,
  parseCookieStringToJson,
} from "./http";

export const AuthUrls = {
  REFRESH_TOKEN: `/${CONFIG.AUTH_API_VERSION_V3}/token/refresh`,
  LOGOUT: `/${CONFIG.AUTH_API_VERSION_V3}/sign-out`,
  LOGIN_BY_SBER_ID: `/${CONFIG.AUTH_API_VERSION_V3}/sber/login/web?redirect_url=`,
  RESET_PASSWORD: `/${CONFIG.AUTH_API_VERSION_V3}/password/recovery`,
  RESET_PASSWORD_CONFIRM: `/${CONFIG.AUTH_API_VERSION_V3_1}/action-confirmation`,
  RESET_PASSWORD_CONFIRM_SMS: `/${CONFIG.AUTH_API_VERSION_V3}/actions/2phase`,
  CHECK_USER: `/${CONFIG.AUTH_API_VERSION_V3}/me/check`,
  SIGN_IN: `/${CONFIG.AUTH_API_VERSION_V3}/sign-in`,
  SIGN_UP: `/${CONFIG.AUTH_API_VERSION_V3}/sign-up`,
  ADD_EMAIL: `/${CONFIG.AUTH_API_VERSION_V3}/email/add`,
  ADD_EMAIL_CONFIRM: `/${CONFIG.AUTH_API_VERSION_V3_1}/action-confirmation`,
  SIGNUP_CONFIRM: `/${CONFIG.AUTH_API_VERSION_V3_1}/action-confirmation`,
  DELETE_USER: `/${CONFIG.AUTH_API_VERSION_V3}/me`,
  CSRF: `/${CONFIG.AUTH_API_VERSION_V3}/csrf`,
  SIGNUP_BY_SBER_ID: `/${CONFIG.AUTH_API_VERSION_V3}/sber-id/init?redirect_uri=`,
  PASSWORD_CHANGE: `/${CONFIG.AUTH_API_VERSION_V3}/password/change`,
  PASSWORD_CHANGE_2PHASE: `/${CONFIG.AUTH_API_VERSION_V3}/actions/2phase`,
  PASSWORD_CHANGE_CONFIRM: `/${CONFIG.AUTH_API_VERSION_V3_1}/action-confirmation`,
  CHANGE_EMAIL: `/${CONFIG.AUTH_API_VERSION_V3}/email/change`,
  CONFIRM_CODE: `/${CONFIG.AUTH_API_VERSION_V3_1}/action-confirmation`,
  CONFIRM_CODE_2PHASE: `/${CONFIG.AUTH_API_VERSION_V3}/actions/2phase`,
  OFFER_CONFIRM: `/${CONFIG.AUTH_API_VERSION_V3_1}/offer-confirmation`,
  ADD_PHONE: `/${CONFIG.AUTH_API_VERSION_V3}/phone/add`,
};

const CSRF_KEY = "_csrf";

export const authBySberId = (url: string | null) => {
  const redirectURL = url || window.location.href;

  window.location.href = `${CONFIG.CLIENT_REST_API_URL}${AuthUrls.SIGNUP_BY_SBER_ID}${redirectURL}`;
};

export const clearCsrfToken = () => {
  Cookies.remove(CSRF_KEY);
};

interface TokensResponse {
  access_token: string;
  refresh_token: string;
}

const isSuccess = <S>(response: Answer): response is Answer<S> => {
  return response.status === 200;
};

const isError = (response: Answer): response is Answer<CommonError> => {
  return response.status !== 200;
};

export const updateRefreshToken = async (): Promise<TokensResponse | null> => {
  // eslint-disable-next-line effector/no-getState
  const cookie = $cookiesForRequest.getState();
  let csrfToken;

  try {
    if (CONFIG.BUILD_ON_CLIENT) {
      csrfToken = await fetchCsrfToken();
    } else {
      csrfToken = parseCookieStringToJson(cookie)[CSRF_KEY];
    }

    const response = await request({
      path: AuthUrls.REFRESH_TOKEN,
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Cookie": cookie,
      },
    });

    if (isSuccess<TokensResponse>(response)) {
      return response.body;
    }

    if (isError(response)) {
      throw new AuthAPIError(
        response.body.message ?? DEFAULT_API_ERROR_MESSAGE,
        response.status,
        DEFAULT_API_ERROR_TYPE,
      );
    }

    return null;
  } catch (error) {
    throw new Error(DEFAULT_API_ERROR_MESSAGE);
  }
};
