import clsx from "clsx";
import { useEvent, useStore } from "effector-react/scope";
import { useEffect } from "react";

import { StatusTest } from "@/entities/lesson";
import { useUserNameOrDefault } from "@/entities/user";

import { useNameFontSize } from "@/shared/lib/adjust-font-size/use-adjust-font-size";
import { scrollToElement } from "@/shared/lib/scroll/scroll-to-element";
import { ButtonRounded } from "@/shared/ui";

import { $hasCourseAccess, $tests, $correctTestAnswers } from "../../../../model";
import { SCROLL_TO_LESSON_TABS } from "../../../constants";
import {
  nameFontSizeCalculated,
  studentTriedToGetCertificate,
} from "../../model/certificate-issueing";
import {
  $testResult,
  answerChecked,
  resultCalculating,
  $isTestPassed,
  $selectedAnswers,
  $isResultCalculated,
  testRepeat,
} from "../../model/lesson-test";
import { TestQuestion } from "../test-question/test-question";

const FailLayout = () => {
  const repeatTest = useEvent(testRepeat);

  const handleRepeat = () => {
    repeatTest();
    scrollToElement(SCROLL_TO_LESSON_TABS);
  };

  return (
    <>
      <p className="text-m-dark-magenta font-m-sb-display text-m-base xl:text-m-xl 3xl:text-m-3xl mb-mv-3 text-center w-full xl:w-[430px] 3xl:w-full">
        Возможно, тебе не хватило вдохновения для успешного прохождения теста
      </p>
      <ButtonRounded onClick={handleRepeat}>Попробовать еще раз</ButtonRounded>
    </>
  );
};

const ResetLayout = () => {
  const repeatTest = useEvent(testRepeat);

  const handleRepeat = () => {
    repeatTest();
    scrollToElement(SCROLL_TO_LESSON_TABS);
  };

  return <ButtonRounded onClick={handleRepeat}>Пройти тест ещё раз</ButtonRounded>;
};

const SuccessLayout = () => {
  const handlers = useEvent({ studentTriedToGetCertificate, nameFontSizeCalculated });
  const { firstName, lastName } = useUserNameOrDefault();

  const nameFontSize = useNameFontSize(
    `${firstName} ${lastName}`,
    480,
    "font-semibold text-[42px]",
  );

  useEffect(() => {
    handlers.nameFontSizeCalculated(nameFontSize);
  }, [nameFontSize]);

  return (
    <>
      <p className="mx-auto w-[328px] font-m-sb-display font-semibold text-m-xl-base leading-[22px] text-m-dark xl:w-[441px] 3xl:w-[617px] 3xl:text-m-3xl 3xl:leading-[26px]">
        Поздравляем вас с&nbsp;успешным выполнением заданий и&nbsp;завершением курса!
      </p>
      <p className="mx-auto w-[300px] mt-[16px] font-sans text-m-xs leading-[14px] text-m-dark-grey xl:w-[441px] 3xl:w-[617px] 3xl:mt-[24px] 3xl:text-m-sm 3xl:leading-[17px]">
        Не потеряйте данные теста и&nbsp;получите подтверждение своих знаний
      </p>

      <ButtonRounded
        className="mt-[16px] 3xl:mt-[24px]"
        onClick={handlers.studentTriedToGetCertificate}
      >
        Получить сертификат
      </ButtonRounded>
    </>
  );
};

const DefaultLayout = () => {
  const handlers = useEvent({ testSubmitted: resultCalculating });
  const isTestPassed = useStore($isTestPassed);

  return (
    <>
      {!isTestPassed && (
        <p className="text-max-3xl mb-mv-3 text-m-dark-grey text-center">
          Необходимо ответить на все вопросы теста
        </p>
      )}
      <ButtonRounded disabled={!isTestPassed} onClick={handlers.testSubmitted}>
        Завершить тестирование
      </ButtonRounded>
    </>
  );
};

const bottomTestLayout = {
  [StatusTest.Success]: <SuccessLayout />,
  [StatusTest.Fail]: <FailLayout />,
  [StatusTest.Default]: <DefaultLayout />,
  [StatusTest.Reset]: <ResetLayout />,
};

export const CourseTest = () => {
  const handlers = useEvent({ answerChecked });
  const tests = useStore($tests);
  const correctedAnswers = useStore($correctTestAnswers);
  const result = useStore($testResult);
  const selectedAnswers = useStore($selectedAnswers);
  const hasCourseAccess = useStore($hasCourseAccess);
  const wrapperClasses = clsx("relative", { "h-[60vh] overflow-hidden": !hasCourseAccess });

  const isResultCalculated = useStore($isResultCalculated);

  if (!tests.length || !correctedAnswers.length) return null;

  return (
    <div className={wrapperClasses}>
      <div>
        {tests.map(({ status, id, data }) => {
          return (
            <TestQuestion
              isTestPassed={isResultCalculated}
              selectedVariant={selectedAnswers[id]}
              testStatus={status}
              setListSelectedAnswer={(checked) => handlers.answerChecked(checked)}
              key={id}
              testId={id}
              test={data}
            />
          );
        })}
      </div>

      {hasCourseAccess && (
        <div className="flex flex-col justify-end items-center min-h-[168px]">
          {bottomTestLayout[result]}
        </div>
      )}
    </div>
  );
};
