import clsx from "clsx";
import { useStore } from "effector-react/scope";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { paths } from "@/pages/paths";

import { userPurchasesModel } from "@/entities/user-purchases";

import { formatDate } from "@/shared/lib/date";

import styles from "@/feature/student-profile-form/ui/section-form/section-profile.module.scss";

export const CoursesList = ({ coursesButton }: { coursesButton: ReactNode }) => {
  const boughtCourses = useStore(userPurchasesModel.$boughtCourses);

  return (
    <div className="w-full mt-mv-6 lg:mt-mv-8 3xl:mt-mv-9" id="courses">
      <h2 className="uppercase text-white font-semibold text-center text-m-base lg:text-m-xl 3xl:text-4xl lg:text-left mb-mv-3 lg:mb-mv-4 3xl:mb-mv-5">
        Мои покупки
      </h2>
      {boughtCourses.map((course) => (
        <Link key={course.id} to={paths.courseView(course.id)}>
          <div className={`${clsx(styles.ProfileField, styles.isDisabled)} h-auto`}>
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full">
              <div className="flex">
                <span className="lg:hidden inline-block text-m-sm lg:text-m-base text-m-orage">
                  Курс:
                </span>
                <span className="text-m-white text-m-sm p-0 lg:text-m-base font-semibold pl-mv-0 lg:pl-0">
                  {course.name}
                </span>
              </div>
              <div className="flex mt-mv-0 lg:mt-0">
                <span className=" inline-block text-m-sm lg:text-m-base text-m-orage">
                  Доступен до:
                </span>
                <span className="text-m-white text-m-sm p-0 3xl:text-m-base font-semibold pl-mv-0 leading-[24px]">
                  {formatDate(course.expiredDate, "dd.MM.yyyy")}
                </span>
              </div>
            </div>
          </div>
        </Link>
      ))}

      <div className="mt-[16px] xl:mt-[32px] text-center xl:text-left">{coursesButton}</div>
    </div>
  );
};
