import { useEffect } from "react";

import { scrollToElement } from "./scroll-to-element";

export const useAnchorScroll = () => {
  const pageLoadHandler = () => {
    const hash = typeof window === "undefined" ? "" : window.location.hash.replace(/^#/g, "");
    scrollToElement(hash);
  };

  useEffect(() => {
    setTimeout(pageLoadHandler, 0);
  }, []);
};
