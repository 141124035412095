import { useEvent } from "effector-react/scope";
import React from "react";

import { ButtonRounded, Container, Typography } from "@/shared/ui";

import { resetAllFilters } from "../../model";

export const EmptyCatalogLayout = () => {
  const reset = useEvent(resetAllFilters);

  return (
    <Container mode="fluid-fixed">
      <div className="flex flex-col">
        <Typography
          tag="h5"
          size="m-h5"
          className="uppercase md:mb-mn-20 3xl:mb-mn-26 font-semibold font-m-sb-display"
        >
          <span className="text-m-orange">Ничего не найдено,</span>
          <br />
          вы можете изменить запрос
          <br />
          или посмотреть другие курсы
        </Typography>

        <div className="hidden md:flex">
          <ButtonRounded
            data-testid="filter-reset-button-empty"
            onClick={reset}
            variant="secondary"
          >
            Сбросить фильтры
          </ButtonRounded>
        </div>
      </div>
    </Container>
  );
};
