import { UserProduct } from "@app/api";

import { isMuseSubscription } from "@/entities/user-purchases";

type SubscriptionInfo = {
  isTrialActive: boolean;
  hasPreviousTrial: boolean;
  userSubscription: UserProduct | null;
};

export const enum TrialState {
  /* active trial subscription */
  TRIAL_ACTIVE,
  /* has subscription and trial was used */
  TRIAL_INACTIVE_BUT_HAS_SUBSCRIPTION,
  TRIAL_WAS_USED,
  TRIAL_NOT_USED,
}

export const getTrialState = (subscription: SubscriptionInfo): TrialState => {
  const { isTrialActive, hasPreviousTrial, userSubscription } = subscription;

  if (isMuseSubscription(userSubscription)) {
    return isTrialActive ? TrialState.TRIAL_ACTIVE : TrialState.TRIAL_INACTIVE_BUT_HAS_SUBSCRIPTION;
  }

  return hasPreviousTrial ? TrialState.TRIAL_WAS_USED : TrialState.TRIAL_NOT_USED;
};

export const enum SubscriptionState {
  USER_HAS_NEVER_SUBSCRIPTION,
  USER_HAS_SUBSCRIPTION,
  USER_HAS_NOT_SUBSCRIPTION_BUT_HAS_PREVIOUS_SUBSCRIPTION,
  DEFAULT,
}

export const getSubscriptionState = (subscription: SubscriptionInfo): SubscriptionState => {
  const { hasPreviousTrial, userSubscription } = subscription;

  const hasSubscription = isMuseSubscription(userSubscription);

  if (!hasPreviousTrial && !hasSubscription) {
    return SubscriptionState.USER_HAS_NEVER_SUBSCRIPTION;
  }

  if (hasSubscription) {
    return SubscriptionState.USER_HAS_SUBSCRIPTION;
  }

  if (!hasSubscription && hasPreviousTrial) {
    return SubscriptionState.USER_HAS_NOT_SUBSCRIPTION_BUT_HAS_PREVIOUS_SUBSCRIPTION;
  }

  return SubscriptionState.DEFAULT;
};
