import { createEffect, createEvent, createStore, sample } from "effector";
import { string } from "yup";

import { UserInfoInput } from "@app/api";

import { UserApi } from "@/shared/api";
import { DEFAULT_API_ERROR_MESSAGE } from "@/shared/api/constants";
import { errorMessages, passwordRegex } from "@/shared/lib/form";
import { validator } from "@/shared/lib/validators";

import { refreshTokenFx, userSettled } from "../model/auth";
import { User } from "../types";

export const getUserFx = createEffect(UserApi.getUser);
export const updateUserFx = createEffect(UserApi.updateUser);

export const gotNewPersonalInfo = createEvent<UserInfoInput>();
export const resetUpdateUserError = createEvent();
export const userDataLoadedStatus = createEvent<boolean>();

export const $currentUser = createStore<User | null>(null);
export const $userUpdateError = createStore("");

sample({
  clock: [refreshTokenFx.done, getUserFx.done],
  source: $currentUser,
  filter: Boolean,
  target: userSettled,
});

export const $isUserDataLoaded = createStore<boolean>(false).on(
  userDataLoadedStatus,
  (_, state) => state,
);

$currentUser
  .on(getUserFx.doneData, (oldUser, user) => user ?? oldUser)
  .on(updateUserFx.done, (oldUserInfo, { params }) => ({
    ...UserApi.mapToUserEntity({ ...oldUserInfo, ...params.userInfo }), // TODO - remove
  }));

$currentUser.watch((e) => e);

$userUpdateError
  .on(updateUserFx.fail, () => DEFAULT_API_ERROR_MESSAGE)
  .reset(resetUpdateUserError, updateUserFx);

sample({
  source: gotNewPersonalInfo,
  fn: (userInfo) => ({ userInfo }),
  target: updateUserFx,
});

export const userFormSchema = {
  email: string().test(
    "customEmailValidator",
    errorMessages.email,
    (value) => !value || validator.isEmail(value),
  ),
  password: string().matches(passwordRegex),
  login: string()
    .max(60, errorMessages.max(60))
    // eslint-disable-next-line func-names
    .test("login", function (value) {
      if (!value) return this.createError({ message: errorMessages.required, path: "username" });

      return (
        validator.isEmail(value) ||
        this.createError({ message: errorMessages.email, path: "username" })
      );
    }),
};
