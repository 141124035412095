import { Cookies } from "@/shared/lib/auth-lib";

export function getGAObject() {
  // eslint-disable-next-line
  const gaKey = (window["GoogleAnalyticsObject"] as string | undefined) ?? "ga";

  return window[gaKey];
}

export function getGATracker() {
  const ga = getGAObject();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return ga?.getAll?.()?.[0];
}

export function getFromCookies(): string | undefined {
  const value = Cookies.get("_ga");
  return value?.split(".").slice(-2).join(".");
}

export function getClientId(): string | undefined {
  const tracker = getGATracker();

  return (tracker?.get("clientId") as string | undefined) ?? getFromCookies();
}
