import clsx from "clsx";
import { useStore, useEvent, useGate } from "effector-react/scope";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { CurrentCourseId } from "@/entities/course/types";
import { ChartBlock } from "@/entities/feedback/ui/chart-block";
import { FeedbackCard } from "@/entities/feedback/ui/feedback-card";
import type { StarRatingProps } from "@/entities/feedback/ui/star-rating";

import { throttle } from "@/shared/lib/lodash";
import { useDevices } from "@/shared/lib/responsive";
import { ButtonRounded, Section, Typography } from "@/shared/ui";
import DefaultAvatar from "@/shared/ui/assets/images/user-avatar/default.jpeg";

import {
  $displayShowMoreButton,
  $feedbacksFullInfo,
  $feedbacksStatistic,
  FeedbackListGate,
  onShowMoreClickEvent,
} from "../../model";

const FIXED_OFFSET_TOP = 50;
export const FeedbacksList = ({ className }: { className: string }) => {
  const { courseId }: CurrentCourseId = useParams();
  useGate(FeedbackListGate, { courseId });

  const desktopChartBlock = useRef<HTMLDivElement>(null);
  const desktopChartBlockContainer = useRef<HTMLDivElement>(null);

  const onShowMoreClick = useEvent(onShowMoreClickEvent);

  const { isDesktop } = useDevices();

  const feedbacks = useStore($feedbacksFullInfo);
  const feedbacksStatistic = useStore($feedbacksStatistic);
  const displayShowMoreButton = useStore($displayShowMoreButton);

  useEffect(() => {
    if (!desktopChartBlock.current || !desktopChartBlockContainer.current) {
      return;
    }

    const offsetChecker = throttle(() => {
      if (!desktopChartBlock.current || !desktopChartBlockContainer.current) {
        return;
      }

      const { top, left } = desktopChartBlockContainer.current.getBoundingClientRect();
      if (top < FIXED_OFFSET_TOP) {
        if (desktopChartBlock.current.style.position !== "fixed") {
          desktopChartBlock.current.style.position = "fixed";
          desktopChartBlock.current.style.top = `${FIXED_OFFSET_TOP}px`;
          desktopChartBlock.current.style.left = `${left}px`;
        }
      } else {
        desktopChartBlock.current.style.position = "static";
      }
    });

    document.addEventListener("scroll", offsetChecker);

    return () => {
      document.removeEventListener("scroll", offsetChecker);
    };
  }, [desktopChartBlock.current]);

  const hasFeedbacks = feedbacks.length > 0;

  return (
    <Section className={clsx("bg-m-dark-blue", className)}>
      <Section.Content className="pt-mv-5 sm:pt-mv-6 2xl:pt-mv-9">
        <div className="flex flex-col xl:flex-row xl:justify-between xl:pr-mv-5 3xl:pr-0">
          <div>
            {!isDesktop && hasFeedbacks && (
              <div className="pb-[53px]">
                {feedbacksStatistic && <ChartBlock {...feedbacksStatistic} />}
              </div>
            )}

            <Typography tag="h2" size="m-h4" className="text-left text-m-white leading-none mb-mv-3">
              ОТЗЫВЫ
            </Typography>
            {hasFeedbacks ? (
              <>
                <div className="pb-[71px]" />
                <div className="flex flex-col gap-[40px]">
                  {feedbacks.map((feedback) => (
                    <FeedbackCard
                      key={feedback.id}
                      title={feedback.name}
                      text={feedback.feedback || ""}
                      rating={feedback.rating as StarRatingProps["value"]}
                      avatarSrc={feedback.avatar || DefaultAvatar}
                    />
                  ))}

                  <div className="w-full flex items-center justify-center pb-[60px]">
                    {displayShowMoreButton && (
                      <ButtonRounded
                        variant="secondary"
                        className="font-semibold w-auto"
                        onClick={onShowMoreClick}
                      >
                        Показать еще
                      </ButtonRounded>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <Typography
                tag="h3"
                size="m-caption"
                className="text-center sm:text-left text-m-dark-grey w-auto sm:w-[250px] xl:w-auto pb-mn-38"
              >
                У курса еще нет отзывов
              </Typography>
            )}
          </div>

          {isDesktop && hasFeedbacks && feedbacksStatistic && (
            <div ref={desktopChartBlockContainer}>
              <ChartBlock {...feedbacksStatistic} ref={desktopChartBlock} />
            </div>
          )}
        </div>
      </Section.Content>
    </Section>
  );
};
