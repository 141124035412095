import { useEvent, useStore } from "effector-react/scope";
import { useEffect } from "react";
import { Redirect } from "react-router";

import { history } from "@/entities/navigation";
import { $refreshRequestStatus, useIsUserAuthenticated } from "@/entities/user";

import { openSignInModal, agreementModal } from "@/feature/sign-in";

import { paths } from "./paths";
import { routes } from "./routes";

const RedirectToHomePage = () => {
  const handlers = useEvent({ openSignInModal });
  const isOpenAgreementModal = useStore(agreementModal.$isOpen);

  useEffect(() => {
    if (isOpenAgreementModal) return;
    handlers.openSignInModal();
  }, []);

  return <Redirect to={paths.home()} />;
};

export const PrivateRoute = () => {
  const isAuthenticated = useIsUserAuthenticated();
  const location = history?.location.pathname;
  const currentRoute = routes.find((route) => route.path === location);
  const refreshRequestStatus = useStore($refreshRequestStatus);

  if (refreshRequestStatus === "initial" || refreshRequestStatus === "pending") return null;

  if (!currentRoute?.private) return null;

  if (!isAuthenticated) {
    return <RedirectToHomePage />;
  }

  return null;
};
