import { SkeletonLoader } from "../skeleton-loader";

export const BankCardSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <SkeletonLoader variant="blue" className="h-[48px]" />
        <div className="flex flex-row justify-between mt-[8px]">
          <SkeletonLoader variant="blue" className="h-[48px] w-[100px]" />
          <SkeletonLoader variant="blue" className="h-[48px] w-[120px]" />
        </div>
        <SkeletonLoader variant="blue" className="h-[20px] w-[200px] mt-[1rem]" />
        <SkeletonLoader variant="blue" className="h-[48px] mt-[1rem]" />
        <SkeletonLoader variant="blue" className="h-[48px] mt-[1.5rem]" />
      </div>
    </div>
  );
};
