import { ReactNode, useEffect } from "react";

import { DOMAIN_SITE, paths } from "@/pages/paths";

import { Footer } from "@/widgets/footer";
import { Header } from "@/widgets/header";
import { SubscriptionSectionCondition, SubscriptionSectionBenefits } from "@/widgets/payment";

import { scrollToTop } from "@/shared/lib/scroll";
import { ContentHeightLayout, SEO } from "@/shared/ui";

const SubscriptionContainer = ({ children }: { children: ReactNode }) => {
  return <div className="xl:max-w-[90vw] 3xl:max-w-[1600px] m-auto">{children}</div>;
};

export const Subscriptions = () => {
  useEffect(scrollToTop, []);
  return (
    <>
      <SEO title="Подписка" canonical={`${DOMAIN_SITE}${paths.subscriptions()}`} />
      <Header.Desktop />
      <Header.Mobile />
      <ContentHeightLayout className="bg-m-dark-blue">
        <SubscriptionContainer>
          <div className="pt-[32px] px-mn-20 md:pt-[68px] md:px-mn-38 xl:px-mn-50 3xl:pt-[100px] 3x:px-[62px]">
            <SubscriptionSectionCondition alwaysShowCondition />
          </div>
        </SubscriptionContainer>

        <div className="bg-m-blue">
          <SubscriptionContainer>
            <SubscriptionSectionBenefits />
          </SubscriptionContainer>
        </div>
      </ContentHeightLayout>
      <Footer />
    </>
  );
};
