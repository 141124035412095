import clsx from "clsx";
import { useEvent } from "effector-react/scope";
import React, { ReactNode } from "react";

import { DOMAIN_SITE, paths } from "@/pages/paths";

import { BestSpeakers } from "@/widgets/best-speakers";
import { Footer } from "@/widgets/footer";
import { Header } from "@/widgets/header";
import { SectionB2b } from "@/widgets/section-b2b";
import { SpecificProject } from "@/widgets/specific-project";

import { ButtonRounded, ScrollToTop, SEO } from "@/shared/ui";
import announcementImage from "@/shared/ui/assets/images/home/new-landing/neuro-1920.webp";

import {
  WelcomeSection,
  AnnouncementBlock,
  JoinCommunity,
  OurPrograms,
  Partners,
  AboutProject,
  ActualEventsSlider,
} from "./modules/landing-blocks";
import { courseListClicked } from "./modules/landing-blocks/analytics";

const paddingTop = "pt-mn-50 md:pt-mn-74 3xl:pt-mn-111";

const paddingTopMax74 = "pt-mn-50 3xl:pt-mn-74";

const LandingContainer = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div
    className={clsx(
      "px-mn-20 md:px-mn-26 3xl:px-mn-38 child:pb-mn-50 md:child:pb-mn-74 3xl:child:pb-mn-111",
      className,
    )}
  >
    {children}
  </div>
);

const SEOMeta = () => {
  const ldJson = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Муза",
    "alternateName": "Муза",
    "url": `${DOMAIN_SITE}`,
  };

  return (
    <SEO
      title="Муза — онлайн-университет творческих профессий"
      description="Онлайн-курсы по искусству и профессиональное образование в области творческих индустрий: цифровые технологии, дизайн, нейросети, музыка, рисунок, режиссура, актерское мастерство."
      canonical={`${DOMAIN_SITE}/`}
    >
      <meta property="og:site_name" content="Муза" />
      <meta property="og:type" content="website" />
      <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
    </SEO>
  );
};

export const HomePage = () => {
  const coursesListClicked = useEvent(courseListClicked);

  return (
    <ScrollToTop>
      <SEOMeta />
      <Header.Mobile />

      <Header.Desktop className="absolute w-full z-10 bg-transparent" subscribeButtonFixed />
      <WelcomeSection />
      <div className="bg-m-dark-blue">
        <AboutProject />
        <ActualEventsSlider />
        <LandingContainer>
          <div className={paddingTop}>
            <BestSpeakers
              hideBottomArrow
              title="Курсы со звездами"
              subTitle={
                <div className="flex flex-col">
                  <span className="font-bold font-m-sb-display">
                    Учитесь творческому мышлению у&nbsp;звезд культуры,{" "}
                    <br className="hidden md:block" />
                    искусства и&nbsp;креативных индустрий
                  </span>
                  <ButtonRounded
                    variant="tertiary"
                    className="!text-m-white !border-m-white mt-mn-16 w-[116px] md:hidden font-sans flex items-center"
                    onClick={coursesListClicked}
                    linkOptions={{ to: paths.catalogCourses() }}
                  >
                    К курсам
                  </ButtonRounded>
                </div>
              }
            />
          </div>
        </LandingContainer>
        <AnnouncementBlock
          img={announcementImage}
          title={
            <>
              основы работы <br />с нейросетями
            </>
          }
          description={
            <>
              Первый и&nbsp;единственный курс,
              <br className="md:hidden" /> в&nbsp;котором <br className="hidden md:block" />
              собрано
              <br className="md:hidden" /> всё самое актуальное о&nbsp;нейросетях
            </>
          }
          url={paths.neuro()}
        />
        <OurPrograms />
        <SpecificProject />
        <Partners className={clsx("bg-m-white", paddingTopMax74)} />
        <SectionB2b />
        <JoinCommunity />
      </div>
      <Footer />
    </ScrollToTop>
  );
};
