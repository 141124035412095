import { createDomain, createStore, sample } from "effector";
import { createHatch } from "framework";
import { combineEvents } from "patronum/combine-events";

import { getUserFx } from "@/entities/user";
import { userPurchasesModel } from "@/entities/user-purchases";

import { getUserAvatarFx } from "@/feature/student-profile-form";

export const hatch = createHatch(createDomain("StudentProfile"));

export const $profileDataLoaded = createStore<boolean>(false);
const dataLoaded = combineEvents({
  events: [
    getUserFx.finally,
    getUserAvatarFx.finally,
    userPurchasesModel.getUserProductsFx.finally,
    userPurchasesModel.getUserCardsInfoFx.finally,
  ],
});

sample({
  clock: hatch.enter,
  target: userPurchasesModel.getUserCardsInfoFx,
});

sample({
  clock: dataLoaded,
  fn: () => true,
  target: $profileDataLoaded,
});
