import clsx from "clsx";
import { useEvent } from "effector-react/scope";
import React, { ReactNode, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { boolean, object, string } from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

import { errorMessages } from "@/shared/lib/form";
import { isEmpty } from "@/shared/lib/object";
import { NAME_REGEXP } from "@/shared/lib/regexp";
import { trimSpaceAndDash } from "@/shared/lib/strings";
import { ButtonRounded, Checkbox, Typography } from "@/shared/ui";

import { formSubscribeSubmitted } from "../model/form-subscribe";
import styles from "./form-subscribe.module.scss";

const defaultValues = {
  name: "",
  surname: "",
  email: "",
  agreement: true,
};

const formSchema = object().shape({
  name: string()
    .transform(trimSpaceAndDash)
    .trim()
    .max(50, errorMessages.max(50))
    .required(errorMessages.required)
    .matches(NAME_REGEXP, errorMessages.match),
  surname: string()
    .transform(trimSpaceAndDash)
    .trim()
    .max(50, errorMessages.max(50))
    .required(errorMessages.required)
    .matches(NAME_REGEXP, errorMessages.match),
  email: string()
    .required(errorMessages.required)
    .test("customEmailValidator", errorMessages.email, (value) => !value || isEmail(value)),
  agreement: boolean().notOneOf([false], errorMessages.required),
});

const ErrorMessage = ({
  message = "Поле не заполнено",
  isShow,
}: {
  message?: string;
  isShow: boolean;
}) => {
  return (
    <div className="min-h-[24px] mt-[4px] flex items-center">
      {isShow && (
        <Typography tag="span" size="m-caption" className="text-m-magenta">
          {message}
        </Typography>
      )}
    </div>
  );
};

type InputWrapperProps = {
  invalid: boolean;
  isDirty: boolean;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  maxLength?: number;
  ariaLabel: string;
  name: string;
  errorMessage?: string;
};

const InputWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex w-full flex-col xl:w-[17.4vw] last:w-auto md:mr-[8px] xl:mr-mn-20 mb-[6px] md:mb-0">
      {children}
    </div>
  );
};

const InputSubscribe = ({
  isDirty,
  invalid,
  name,
  ariaLabel,
  value,
  maxLength = undefined,
  placeholder,
  onChange,
  errorMessage = "",
}: InputWrapperProps) => {
  return (
    <InputWrapper>
      <input
        className={clsx(styles.subscribeInput, {
          [styles.error]: invalid,
          [styles.dirty]: isDirty && !invalid,
        })}
        value={value}
        onChange={({ target }) => onChange(target.value)}
        placeholder={placeholder}
        maxLength={maxLength}
        aria-label={ariaLabel}
        name={name}
      />
      <ErrorMessage isShow={invalid} message={errorMessage} />
    </InputWrapper>
  );
};

type SubmitButtonProps = {
  isSubmitted: boolean;
  isValid: boolean;
  fields: { [key in keyof typeof defaultValues]?: string | boolean };
  isSubmitting: boolean;
};

const SubmitButton = ({ isSubmitted, isSubmitting, fields, isValid }: SubmitButtonProps) => {
  return (
    <ButtonRounded
      className={clsx(
        `md:ml-mn-10 xl:ml-[44px] 3xl:ml-[60px] w-[230px] md:w-[220px] border-[2px] font-bold xl:w-[290px] 3xl:w-[435px] h-[35px] 3xl:h-[66px] xl:!h-[50px] xl:!text-m-lg whitespace-nowrap disabled:text-m-dark-grey disabled:!border-m-dark-grey !bg-transparent text-m-white`,
        {
          "hover:border-m-orange border-m-white": isValid,
        },
      )}
      variant="secondary"
      type="submit"
      disabled={isSubmitting || !isValid || isEmpty(fields)}
    >
      {isSubmitted ? "Готово" : "Присоединиться"}
    </ButtonRounded>
  );
};

export const FormSubscribe = () => {
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues,
    resolver: yupResolver(formSchema),
    delayError: 1000,
    mode: "all",
  });

  useEffect(() => {
    if (formState.isSubmitted) {
      setTimeout(() => {
        reset();
      }, 2000);
    }
  }, [formState.isSubmitted]);

  const formSubmitted = useEvent(formSubscribeSubmitted);

  return (
    <div className="hidden md:block mb-mn-26 xl:mb-mn-50 3xl:mb-[64px]">
      <form onSubmit={handleSubmit(formSubmitted)} className="flex flex-row w-full">
        <div>
          <div className="flex flex-row justify-between md:mb-[12px]">
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <InputSubscribe
                  maxLength={50}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  placeholder="Имя"
                  ariaLabel="name"
                  name="name"
                  errorMessage={fieldState.error?.message}
                  isDirty={fieldState.isDirty}
                  invalid={fieldState.invalid}
                />
              )}
            />
            <Controller
              name="surname"
              control={control}
              render={({ field, fieldState }) => (
                <InputSubscribe
                  maxLength={50}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  placeholder="Фамилия"
                  ariaLabel="surname"
                  name="surname"
                  errorMessage={fieldState.error?.message}
                  isDirty={fieldState.isDirty}
                  invalid={fieldState.invalid}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <InputSubscribe
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  placeholder="E-mail"
                  ariaLabel="email"
                  name="email"
                  errorMessage={fieldState.error?.message}
                  isDirty={fieldState.isDirty}
                  invalid={fieldState.invalid}
                />
              )}
            />
            <Controller
              name="agreement"
              control={control}
              render={({ field, fieldState }) => (
                <InputWrapper>
                  <div className="flex items-center mt-[6px]">
                    <Checkbox
                      checkBoxClassName="border-m-white"
                      status={fieldState.invalid ? "fail" : "default"}
                      checked={field.value}
                      title=""
                      onChange={() => field.onChange(!field.value)}
                      labelClassName="text-m-dark"
                    />
                    <Typography tag="span" size="m-caption" className="text-m-white whitespace-pre">
                      Даю{" "}
                      <a
                        href="https://assets.edutoria.ru/documents/emailmarketing.pdf"
                        target="__blank"
                        className="underline"
                      >
                        согласие
                        <br />
                        на рассылку
                      </a>{" "}
                      по e-mail
                    </Typography>
                  </div>
                  <ErrorMessage isShow={fieldState.invalid} message={fieldState.error?.message} />
                </InputWrapper>
              )}
            />
          </div>
        </div>
        <SubmitButton
          isSubmitted={formState.isSubmitted}
          isValid={formState.isValid}
          fields={formState.dirtyFields}
          isSubmitting={formState.isSubmitting}
        />
      </form>

      <div className="flex flex-col-reverse items-start md:flex-row">
        <div className="text-m-white m-typography-caption">
          Продолжая, вы соглашаетесь с{" "}
          <a
            className="underline"
            href="https://edutoria.ru/termsofuse/user/agreement"
            target="__blank"
          >
            Пользовательским&nbsp;соглашением
          </a>{" "}
          и{" "}
          <a
            href="https://edutoria.ru/termsofuse/user/privacypolicy"
            target="__blank"
            className="underline"
          >
            Положением&nbsp;о&nbsp;конфиденциальности
          </a>
        </div>
      </div>
    </div>
  );
};
