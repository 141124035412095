import loadable from "@loadable/component";

export { OnboardBlock } from "./onboard-block";
export { StudentWork } from "./student-work";
export { WelcomeBlock } from "./welcome-block";
export { SocialMediaBlock } from "./social-media-block";

export const StatisticsBlock = loadable(
  () => import("./statistics-block").then((component) => component.StatisticsBlock),
  {
    ssr: false,
  },
);
