import clsx from "clsx";
import { Link } from "react-router-dom";

import { Course } from "@app/api";

import { paths } from "@/pages/paths";

import { SpeakerEntity } from "@/entities/speaker";

import { useDevices } from "@/shared/lib/responsive";
import { Section } from "@/shared/ui";

import { CourseShare } from "@/feature/course-share";
import { LikeButton } from "@/feature/favorite-courses";

export const CourseHeader = ({
  title,
  speaker,
  titleLink,
}: {
  title: string;
  speaker: SpeakerEntity | null;
  titleLink?: string;
}) => {
  const speakerName = `${speaker?.name ?? ""} ${speaker?.surname ?? ""}`;
  const speakerShortDescription = `${speaker?.shortDescription ?? ""}`;

  return (
    <div>
      {titleLink ? (
        <Link to={titleLink}>
          <h2 className="text-center sm:text-left gradient-text text-[20px] xl:text-[28px] 2xl:text-m-6xl leading-none mb-mv-3">
            {title}
          </h2>
        </Link>
      ) : (
        <h2 className="text-center sm:text-left gradient-text text-[20px] xl:text-[28px] 2xl:text-m-6xl leading-none mb-mv-3">
          {title}
        </h2>
      )}
      {speaker && (
        <h3 className="text-center sm:text-left text-m-white text-m-xs 2xl:text-m-base w-auto sm:w-[250px] xl:w-auto">
          {speakerName.trim().length
            ? `${speakerName}, ${speakerShortDescription}`
            : speakerShortDescription}
        </h3>
      )}
    </div>
  );
};

type CourseHeaderBlockProps = {
  className?: string;
  speaker: SpeakerEntity | null;
  course: Course | null;
  courseId: string;
};

export const CourseHeaderBlock = ({
  className,
  course,
  speaker,
  courseId,
}: CourseHeaderBlockProps) => {
  const { isMobile } = useDevices();

  return (
    <Section className={clsx("bg-m-dark-blue", className)}>
      <Section.Content className="pt-mv-5 sm:pt-mv-6 2xl:pt-mv-9">
        <div className="flex flex-col xl:flex-row xl:justify-between xl:pr-mv-5 3xl:pr-0">
          <CourseHeader
            title={course?.title || ""}
            speaker={speaker}
            titleLink={paths.courseView(courseId)}
          />
          {!isMobile && (
            <div className="mt-mv-3 xl:mt-0 inline-flex">
              <LikeButton
                className="flex justify-center items-center button-init border-2 border-m-dark-grey border-solid rounded-[7px] overflow-hidden background-transparent text-m-white hover:border-m-orange w-[30px] h-[30px] transition-colors mr-4"
                courseId={courseId}
                iconClassName="scale-[0.8]"
              />
              {course && <CourseShare course={course} />}
            </div>
          )}
        </div>
      </Section.Content>
    </Section>
  );
};
