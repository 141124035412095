import { useStore } from "effector-react/scope";
import { FC } from "react";

import { ModalNotification } from "@/shared/ui";

import { confirmCodeSignUp } from "../../model/confirm-code";

type Props = { onClose: () => void };

export const SignUpConfirmationError: FC<Props> = ({ onClose }) => {
  const message = useStore(confirmCodeSignUp.$error);

  return <ModalNotification type="error" message={message} onClose={onClose} />;
};
