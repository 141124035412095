import { attach, createEffect, createEvent, Effect, merge, restore, sample } from "effector";
import fetch from "isomorphic-fetch";

import { CONFIG } from "@/shared/config";
import { Cookies } from "@/shared/lib/auth-lib";

import { COOKIES, DEFAULT_API_ERROR_MESSAGE, DEFAULT_API_ERROR_TYPE } from "../../constants";
import { AuthAPIError } from "../auth-api-error";
import { requestClient } from "./client";
import { requestServer } from "./server";
import { Answer, Request } from "./types";

export const setCookiesForRequest = createEvent<string>();
export const $cookiesForRequest = restore(setCookiesForRequest, "");

export const setCookiesFromResponse = createEvent<string>();
export const $cookiesFromResponse = restore(setCookiesFromResponse, "");

export const request = CONFIG.BUILD_ON_CLIENT ? requestClient : requestServer;

const sendRequestFx = createEffect<Request, Answer, Answer>(request);

export const BASE_API_AUTH_URL =
  process.env.BUILD_TARGET === "server" ? CONFIG.SERVER_REST_API_URL : CONFIG.BROWSER_REST_API_URL;

export const fetchCsrfToken = async () => {
  const response = await fetch(`${BASE_API_AUTH_URL}/v3/csrf`, {
    credentials: "include",
  });

  if (!response.ok) {
    throw new AuthAPIError(DEFAULT_API_ERROR_MESSAGE, response.status, DEFAULT_API_ERROR_TYPE);
  }

  const csrfToken = await response.text();

  if (!csrfToken) {
    throw new AuthAPIError(DEFAULT_API_ERROR_MESSAGE, response.status, DEFAULT_API_ERROR_TYPE);
  }

  return csrfToken;
};

export const createRequestFx = <T>(path: string) => {
  const requestFx = attach({
    effect: sendRequestFx,
    source: $cookiesForRequest,
    mapParams: (parameters: Request, cookies) => ({
      ...parameters,
      path: parameters.path || path,
      cookies,
    }),
  });

  return requestFx as Effect<Request, Answer<T>, Answer>;
};

export const createAuthRequestFx = <Done = unknown, Fail = unknown>(path: string) =>
  createEffect<Request, Answer<Done>, Fail>({
    async handler(params) {
      const csrfToken = await fetchCsrfToken();
      const response = await createRequestFx<Done>(path)({
        ...params,
        body: { ...params.body },
        headers: {
          ...params.headers,
          "Content-Type": "application/json",
          ...(Cookies.get(COOKIES.mindboxDeviceUUID)
            ? { DeviceUUID: Cookies.get(COOKIES.mindboxDeviceUUID) }
            : {}),
          "X-CSRF-Token": csrfToken,
        },
      });
      if (!response.ok) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result = response.body as any;

        const apiError = result?.errors?.[0] || result?.sboerror;

        throw new AuthAPIError(
          apiError?.message ?? DEFAULT_API_ERROR_MESSAGE,
          response.status,
          (apiError?.extensions?.code || apiError?.code) ?? DEFAULT_API_ERROR_TYPE,
        );
      }

      return response;
    },
  });

if (CONFIG.BUILD_ON_SERVER) {
  $cookiesForRequest.on(setCookiesForRequest, (_, cookies) => cookies);
  const respondedWithCookies = merge([sendRequestFx.doneData, sendRequestFx.failData]).map(
    ({ headers }) => {
      return headers ? headers["set-cookie"] : "";
    },
  );

  sample({
    source: respondedWithCookies,
    filter: (setCookie) => (setCookie ? setCookie.trim() !== "" : false),
    target: setCookiesFromResponse,
  });
}
