import clsx from "clsx";
import { useStore, useEvent } from "effector-react/scope";
import { FC, ReactNode, useEffect } from "react";

import { paths } from "@/pages/paths";

import { historyPush } from "@/entities/navigation";
import { logout, LogoutMethod, useUserNameOrDefault } from "@/entities/user";

import { AnimatePresence, motion, Menu, Image, OutsideClick } from "@/shared/ui";
import { ReactComponent as CloseIcon } from "@/shared/ui/assets/images/icons/close.svg";

import { useUserProfilePictureOrDefault } from "@/feature/student-profile-form";

import { $isOpen, closeProfileMenu } from "../../model/profile-menu-toggle";
import style from "./profile-menu.module.scss";

const enum MenuTitle {
  Profile = "Профиль",
  Courses = "Моё обучение",
  Exit = "Выйти",
}

type MenuItem = {
  title: MenuTitle;
  onClick?: () => void;
  to?: string;
};

const Layout: FC<{ children: ReactNode }> = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, y: -30 }}
    animate={{ opacity: 1, y: 0, transition: { duration: 0.2 } }}
    exit={{ opacity: 0, y: -30, transition: { duration: 0.2 } }}
    className={clsx(style.Wrapper, "shadow-blue")}
  >
    {children}
  </motion.div>
);

const UserName = () => {
  const { firstName: name, lastName: surname } = useUserNameOrDefault();
  const fullName = `${name} ${surname}`;

  return (
    <p className="break-words mt-[12px] mb-[31px] 3xl:mt-[19px] xs:mb-[23px] font-m-sb-display text-m-dark-orange font-semibold leading-[28px] text-center w-[208px] text-m-xl 3xl:text-m-3xl mx-auto">
      {fullName}
    </p>
  );
};

export const ProfileMenu = ({ outsideOff = false }) => {
  const push = useEvent(historyPush);
  const isOpen = useStore($isOpen);
  const handlers = useEvent({ closeProfileMenu, logout });
  const { userProfilePicture } = useUserProfilePictureOrDefault();

  const handleCloseProfileMenu = () => {
    handlers.closeProfileMenu();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleCloseProfileMenu, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleCloseProfileMenu);
    };
  }, []);

  const menu: MenuItem[] = [
    {
      title: MenuTitle.Profile,
      onClick: () => push(paths.studentProfile()),
    },
    {
      title: MenuTitle.Courses,
      onClick: () => push(paths.studentCourses()),
    },
    {
      title: MenuTitle.Exit,
      onClick: () => handlers.logout({ method: LogoutMethod.MANUAL }),
    },
  ];

  return (
    <AnimatePresence>
      {isOpen && (
        <OutsideClick onOutsideClick={() => (outsideOff ? null : handleCloseProfileMenu())}>
          <Layout>
            <div className="absolute right-7 lg:right-2.5 xl:right-3.5 top-5 lg:top-3.5">
              <button className={style.Close} onClick={handleCloseProfileMenu} aria-label="close">
                <CloseIcon height="18" width="18" />
              </button>
            </div>
            <div className={style.Avatar}>
              <Image
                className={clsx(style.AvatarImage, "rounded-full")}
                src={userProfilePicture}
                alt="avatar"
              />
            </div>

            <UserName />

            <Menu className="justify-center flex-col gap-y-5 relative">
              {menu.map(({ onClick, title }) => (
                <Menu.Button
                  onClick={() => {
                    if (onClick) {
                      onClick();
                    }
                    handlers.closeProfileMenu();
                  }}
                  variant="secondary"
                  className="flex self-center py-2 px-6 text-m-xs font-semibold"
                  key={title}
                >
                  {title}
                </Menu.Button>
              ))}
            </Menu>
          </Layout>
        </OutsideClick>
      )}
    </AnimatePresence>
  );
};
