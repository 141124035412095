import { useStore } from "effector-react/scope";
import { useParams } from "react-router-dom";

import { paths } from "@/pages/paths";

import { CurrentCourseId } from "@/entities/course/types";

import { useDevices } from "@/shared/lib/responsive";
import { ButtonRounded, Section } from "@/shared/ui";

import { CourseShare } from "@/feature/course-share";
import { LikeButton } from "@/feature/favorite-courses";

import { $currentCourse } from "../../model";

export const FeedbacksBackToCourse = () => {
  const { courseId }: CurrentCourseId = useParams();

  const { isMobile } = useDevices();
  const currentCourse = useStore($currentCourse);

  return (
    <Section className="bg-m-semi-dark pt-[40px] sm:pt-[30px] pb-mv-5 sm:pb-mv-6 2xl:pb-mv-9">
      <Section.Content className="flex justify-center sm:justify-start">
        <ButtonRounded
          variant="secondary"
          linkOptions={{
            to: paths.courseView(courseId),
          }}
          className="inline-flex items-center font-semibold"
        >
          назад к курсу
        </ButtonRounded>
      </Section.Content>
      {isMobile && (
        <Section.Content className="flex justify-center sm:justify-start">
          <div className="mt-mv-3 xl:mt-0 inline-flex">
            <LikeButton
              className="flex justify-center items-center button-init border-2 border-m-dark-grey border-solid rounded-[7px] overflow-hidden background-transparent text-m-white hover:border-m-orange w-[30px] h-[30px] transition-colors mr-4"
              courseId={courseId}
              iconClassName="w-[22px] h-[23px]"
            />
            {currentCourse && <CourseShare course={currentCourse} />}
          </div>
        </Section.Content>
      )}
    </Section>
  );
};
