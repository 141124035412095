import { createEffect, sample } from "effector";

import { $authenticatedUserId, getUserFx, userDataLoadedStatus } from "@/entities/user";
import { userPurchasesModel } from "@/entities/user-purchases";

export const appStarted = () => {
  const fetchInitialUserDataFx = createEffect(async (userId: string) => {
    await Promise.allSettled([
      getUserFx(),
      userPurchasesModel.getUserProductsFx(userId),
      userPurchasesModel.getUserCardsInfoFx(),
      userPurchasesModel.checkPreviousSubscriptionsFx(userId),
      userPurchasesModel.getBoughtEventsFx(userId),
      userPurchasesModel.getUserPaymentMethodFx(userId),
    ]);
  });

  sample({
    clock: $authenticatedUserId,
    target: fetchInitialUserDataFx,
  });

  sample({
    clock: fetchInitialUserDataFx.finally,
    filter: ({ status }) => status === "done",
    fn: () => true,
    target: userDataLoadedStatus,
  });
};
