import { TabEducationNames } from "./student-courses/model";

const withURLBuilder =
  <Q extends Record<string, string>, A = void>(url: string) =>
  (URLBuilderOptions?: { anchor?: A; queryParams?: Q }) => {
    let result = url;

    if (URLBuilderOptions?.queryParams) {
      const searchParams = new URLSearchParams(URLBuilderOptions.queryParams).toString();
      result = `${result}?${searchParams}`;
    }

    if (URLBuilderOptions?.anchor) {
      result = `${result}#${URLBuilderOptions.anchor}`;
    }

    return result;
  };

export const DOMAIN_SITE = "https://muse.edutoria.ru";

const dpoPaths = {
  teens: () => "https://muse.edutoria.ru/teens/camps/",
  artretreat: () => "https://muse.edutoria.ru/events/art-retreat",
  dpoPrograms: () => "https://muse.edutoria.ru/dpo/programs/",
  museum: () => "https://muse.edutoria.ru/dpo/museum/",
  festival: () => "https://muse.edutoria.ru/dpo/festival/",
  cultureMarketing: () => "https://muse.edutoria.ru/dpo/culture-marketing/",
  cultureBuisnes: () => "https://muse.edutoria.ru/dpo/culture-business/ ",
  cybermoda: () => "https://muse.edutoria.ru/dpo/cybermoda/",
  architecture: () => "https://muse.edutoria.ru/dpo/architecture",
  domKultury: () => "https://muse.edutoria.ru/dpo/dom-kultury/",
  neuro: () => "https://muse.edutoria.ru/dpo/neuro-art/",
  innovation: () => "https://muse.edutoria.ru/dpo/brand-innovation/",
  gameDesign: () => "https://muse.edutoria.ru/dpo/gamedesign/",
  speculativeDesign: () => "https://muse.edutoria.ru/dpo/speculative-design/",
  accelerator: () => "https://muse.edutoria.ru/dpo/accelerator/",
};

export const staticPaths = {
  home: () => "/",
  subscriptions: () => "/subscriptions",
  studentProfile: () => "/student-profile",
  studentCourses: withURLBuilder<{ tab?: TabEducationNames }>("/student-courses"),
  jazz100: () => "/jazz100",
  catalog: () => "/catalog",
  catalogCourses: () => "/catalog?education=online_course",
  catalogProgram: () => "/catalog?education=program",
  theatre: () => "/theatre",
  spring: () => "/spring",
  search: withURLBuilder<{ query: string }>("/search"),
};

export const paths = {
  courseView: (courseId = ":courseId") => `/courses/${courseId}`,
  courseFeedbacksView: (courseId = ":courseId") => `/courses/${courseId}/feedbacks`,
  edutainmentPrivacyPolicy: () => "https://assets.edutoria.ru/documents/privacy-policy.pdf",
  termsOfUseAgreement: () => `https://edutoria.ru/termsofuse/user/agreement`,
  termsOfUseAgreementAssets: () => `https://assets.edutoria.ru/documents/agreement.pdf`,
  termsOfUsePrivacyPolicy: () => `https://edutoria.ru/termsofuse/user/privacypolicy`,
  termsOfUsePrivacyAssets: () => `https://assets.edutoria.ru/documents/privacypolicy.pdf`,
  recommendationDoc: () => `https://assets.edutoria.ru/documents/recommendation.pdf`,
  cookies: () => "https://edutoria.ru/termsofuse/user/cookies",
  cookiesAssets: () => "https://assets.edutoria.ru/documents/cookies.pdf",
  paymentClose: () => "/payment_widget/close_me",
  status: () => "/status",
  live: withURLBuilder<{ events?: string }>("/live"),
  programView: (dpo = ":dpo") => `https://muse.edutoria.ru/dpo/${dpo}/`,
  ...dpoPaths,
  ...staticPaths,
};
