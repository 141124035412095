export function randomizeArray<T>(arr: Array<T>) {
  const res = [...arr];
  for (let i = 0; i < res.length; i += 1) {
    const currentValue = res[i];
    const randomIndex = Math.floor(Math.random() * res.length);
    res[i] = res[randomIndex];
    res[randomIndex] = currentValue;
  }

  return res;
}
