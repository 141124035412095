import { ReactComponent as EdutoriaIcon } from "@/shared/ui/assets/images/icons/edutoria.svg";

import { ButtonOutline, ButtonRounded, ButtonRoundedProps } from "./button";

type Props = Omit<ButtonRoundedProps, "variant" | "children">;

export const EdutoriaButton = (props: Props) => {
  return (
    <div>
      <div className="hidden md:block">
        <ButtonRounded variant="secondary" {...props}>
          <span className="flex justify-center items-center">
            <EdutoriaIcon className="mr-1 w-[22px]" /> EDUTORIA
          </span>
        </ButtonRounded>
      </div>
      <div className="md:hidden">
        <ButtonOutline {...props}>
          <span className="flex justify-center items-center text-m-white text-[11px]">
            <EdutoriaIcon className="mr-1 w-[19px]" /> EDUTORIA
          </span>
        </ButtonOutline>
      </div>
    </div>
  );
};

export const AcademyButton = (props: Props) => (
  <>
    <div className="hidden md:block">
      <ButtonRounded variant="secondary" {...props}>
        Академия
      </ButtonRounded>
    </div>
    <div className="md:hidden">
      <ButtonOutline {...props} className="text-m-white text-[11px]">
        Академия
      </ButtonOutline>
    </div>
  </>
);
