import { createEvent, createStore, sample } from "effector";
import { combineEvents } from "patronum/combine-events";
import { condition } from "patronum/condition";
import { pending } from "patronum/pending";
import { object, string } from "yup";

import {
  $currentUser,
  getUserFx,
  userAuthDataReceived,
  userFormSchema,
  loggedIn,
} from "@/entities/user";

import { AuthApi as AuthAPI } from "@/shared/api";
import { DEFAULT_API_ERROR_MESSAGE } from "@/shared/api/constants";
import { noop } from "@/shared/lib/effector";
import { errorMessages } from "@/shared/lib/form";

import { openAuthModal } from "@/app/authorization-modals/model";

export const signInErrorFromServerErrorType = {
  not_found_user: "Неверный логин или пароль",
  user_disabled: "Пользователь заблокирован. Попробуйте другой логин или зарегистрируйтесь заново",
  token_failed: "Неверный логин или пароль",
  field: "Неверный логин или пароль",
  invalid_role: "Ошибка доступа. Повторите вход",
};
const signInErrorFromServerMessage = {
  "invalid csrf token": "Ошибка запроса авторизации. Повторите позже",
};

export type SignInFormValues = AuthAPI.SignInPayload;

// effects
export const signInUserFx = AuthAPI.signIn;

// events
export const submittedSignInForm = createEvent<SignInFormValues>();
export const openSignInModal = createEvent<boolean | void>();
export const resendAddEmailConfirmationCode = createEvent();
export const resetSignInError = createEvent();

// stores
export const $signInError = createStore("");

// connections
$signInError.reset([
  submittedSignInForm,
  // clickedForgotPassword,
  openAuthModal.close,
  resetSignInError,
]);

export const $isSignInPending = pending({
  effects: [signInUserFx],
});

sample({
  clock: openSignInModal,
  target: openAuthModal.signIn,
});

sample({
  source: openSignInModal,
  filter: (redirect): redirect is boolean => redirect === false,
  target: openAuthModal.setIsRedirectToProfile,
});

sample({
  source: submittedSignInForm,
  target: signInUserFx,
});

condition({
  source: signInUserFx.done,
  if: openAuthModal.$isRedirectToProfile,
  then: openAuthModal.redirectToProfile,
  else: openAuthModal.close,
});

sample({
  clock: signInUserFx.doneData,
  fn: (result) => result.access_token,
  target: userAuthDataReceived,
});

sample({
  source: signInUserFx.failData,
  fn: ({ type, message }) =>
    signInErrorFromServerErrorType[type] ||
    signInErrorFromServerMessage[message] ||
    DEFAULT_API_ERROR_MESSAGE,
  target: $signInError,
});

sample({
  clock: combineEvents({
    events: [signInUserFx.done, getUserFx.done],
    reset: signInUserFx,
  }),
  source: $currentUser,
  filter: Boolean,
  fn: noop,
  target: loggedIn,
});

export const signInFormSchema = object().shape({
  username: userFormSchema.login.required(errorMessages.required),
  password: string().required(errorMessages.required),
});
