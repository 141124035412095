import { useEvent } from "effector-react/scope";
import { ReactNode } from "react";

import { courseClicked, courseListClicked } from "@/pages/home/modules/landing-blocks/analytics";
import { paths } from "@/pages/paths";

import { HorizontalLinearBlocks } from "@/shared/ui";
import image2 from "@/shared/ui/assets/images/zvuk-landing/2.webp";
import image3 from "@/shared/ui/assets/images/zvuk-landing/3.webp";
import image4 from "@/shared/ui/assets/images/zvuk-landing/4.webp";
import image5 from "@/shared/ui/assets/images/zvuk-landing/5.webp";
import image6 from "@/shared/ui/assets/images/zvuk-landing/6.webp";
import image1 from "@/shared/ui/assets/images/zvuk-landing/kleyn.webp";

export const speakersData = [
  {
    img: image5,
    title: "Джазовый стандарт Игоря Бутмана",
    link: paths.courseView("ebf9a1ac-c2f9-44df-85e9-7dbd53fa8494"),
  },
  {
    img: image2,
    title: "Джазовый вокал с&nbsp;Фантине",
    link: paths.courseView("05fce4c8-bbf0-4566-be16-20c0612aefd6"),
  },
  {
    img: image3,
    title: "Русская&nbsp;музыка с&nbsp;Владимиром Федосеевым",
    link: paths.courseView("70bb17da-49d0-40a8-b88c-2bc064d81aae"),
  },
  {
    img: image4,
    title: "Современная хореография с&nbsp;Владимиром Варнавой",
    link: paths.courseView("4d17ed03-6949-46fb-b7f1-378bded4ce6a"),
  },
  {
    img: image1,
    title: "Леонид Клейн: зачем читать стихи?",
    link: paths.courseView("d3687065-153b-490a-81bb-44f8e2521665"),
  },
  {
    img: image6,
    title: "Игра в&nbsp;бисер с&nbsp;Евгением Лебедевым",
    link: paths.courseView("65a90582-7ef2-4c97-bec9-bc35c53bd782"),
  },
];

export const BestSpeakers = ({
  title,
  subTitle,
  hideBottomArrow = false,
}: {
  title?: string;
  subTitle?: ReactNode | string;
  hideBottomArrow?: boolean;
}) => {
  const handlers = useEvent({ courseClicked, courseListClicked });

  const itemClickHandler = (item: { title: string; link?: string }) => {
    const url = item.link;
    if (!url) return;
    const id = url.split("/").slice(-1)[0];
    handlers.courseClicked(id);
  };

  return (
    <HorizontalLinearBlocks
      title={title}
      subTitle={subTitle}
      hideBottomArrow={hideBottomArrow}
      data={speakersData}
      redirectUrl={paths.catalogCourses()}
      onItemClick={itemClickHandler}
      onRedirect={courseListClicked}
    />
  );
};
