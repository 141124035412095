import { createAnalyticsEvent } from "@/shared/analytics/model/analytics";
import { CourseFormatEnum } from "@/shared/api";

const CourseViewPageEvents = {
  CLICK_STRUCTURE_POINT: "click_structure_point",
  SHARE_COURSE: "share_course",
  COMPLETE_TEXT: "complete_text",
  CLICK_MENU_COURSE: "click_menu_course",
  CLICK_TRY_TEST_AGAIN: "click_try_test_again",
  COMPLETE_TUTORIAL_COURSE: "complete_tutorial_course",
  BEGIN_TUTORIAL_COURSE: "begin_tutorial_course",
  INTERACTION_BLOCK: "interaction_block",
  OPEN_FORM_CERTIFICATE: "open_form_certificate",
  SEND_FORM_CERTIFICATE: "send_form_certificate",
  CLICK_COURSE_CHECKOUT: "click_course_checkout",
  VIEW_TASK_VIDEO: "view_task_video",
  ADD_TO_CALENDAR: "add_to_calendar",
};

const getTimezoneDate = () => {
  const date = new Date();
  return (date.getTime() + date.getTimezoneOffset() * 60 * 1000) / 1000;
};

export const addToCalendar = createAnalyticsEvent<{
  course_id: string;
  format_course: CourseFormatEnum;
  course_name: string;
}>(({ course_id, course_name, format_course }) => ({
  event: "autoEvent",
  event_name: CourseViewPageEvents.ADD_TO_CALENDAR,
  course_id,
  course_name,
  format_course,
}));

export const shareCourse = createAnalyticsEvent<{
  course_id: string;
  format_course: CourseFormatEnum;
  link_text: string;
}>(({ course_id, format_course, link_text }) => ({
  event: "autoEvent",
  event_name: CourseViewPageEvents.SHARE_COURSE,
  course_id,
  format_course,
  link_text,
}));

export const completeText = createAnalyticsEvent<{ course_id: string; course_name: string }>(
  ({ course_id, course_name }) => ({
    event: "autoEvent",
    link_text: "Перейти к тестированию",
    event_name: CourseViewPageEvents.COMPLETE_TEXT,
    course_id,
    course_name,
  }),
);

export const clickMenuCourse = createAnalyticsEvent<{ link_text: string }>(({ link_text }) => ({
  event: "autoEvent",
  event_name: CourseViewPageEvents.CLICK_MENU_COURSE,
  link_type: "course",
  link_text,
}));

export const clickTryTestAgain = createAnalyticsEvent<{ course_id: string; course_name: string }>(
  ({ course_id, course_name }) => ({
    event: "autoEvent",
    event_name: CourseViewPageEvents.CLICK_TRY_TEST_AGAIN,
    link_text: "Пройти тест еще раз",
    course_id,
    course_name,
  }),
);

export const completeTutorialCourse = createAnalyticsEvent<{
  course_id: string;
  course_name: string;
  price: number;
  progress: number;
}>(({ course_id, course_name, progress, price }) => ({
  event: "autoEvent",
  event_name: CourseViewPageEvents.COMPLETE_TUTORIAL_COURSE,
  link_text: "Завершить тестирование",
  school_name: "Муза",
  end_date: getTimezoneDate(),
  course_id,
  course_name,
  price,
  progress,
}));

export const beginTutorialCourse = createAnalyticsEvent<{
  course_id: string;
  course_name: string;
  lesson_id: string;
  lesson_name: string;
  lesson_num: string;
  lesson_all: string;
  price: number;
}>(({ course_id, course_name, lesson_name, lesson_num, lesson_all, lesson_id, price }) => ({
  event: "autoEvent",
  event_name: CourseViewPageEvents.BEGIN_TUTORIAL_COURSE,
  school_name: "Муза",
  start_date: getTimezoneDate(),
  course_id,
  course_name,
  price,
  lesson_id,
  lesson_name,
  lesson_num,
  lesson_all,
}));

export const openFormCertificate = createAnalyticsEvent<{
  course_id: string;
  course_name: string;
  price: number;
}>(({ course_id, course_name, price }) => ({
  event: "autoEvent",
  event_name: CourseViewPageEvents.OPEN_FORM_CERTIFICATE,
  link_text: "Оформить сертификат",
  course_id,
  course_name,
  price,
}));

export const sendFormCertificate = createAnalyticsEvent<{
  course_id: string;
  course_name: string;
  price: number;
}>(({ course_id, course_name, price }) => ({
  event: "autoEvent",
  event_name: CourseViewPageEvents.SEND_FORM_CERTIFICATE,
  link_text: "Получить сертификат",
  course_id,
  course_name,
  price,
}));

export const clickCourseCheckout = createAnalyticsEvent<{
  course_id: string;
  course_name: string;
  price: number;
}>(({ course_id, course_name, price }) => ({
  event: "autoEvent",
  event_name: CourseViewPageEvents.CLICK_COURSE_CHECKOUT,
  course_id,
  course_name,
  price,
}));

export const interactionBlockVideo = createAnalyticsEvent<{
  course_id: string;
  course_name: string;
  lesson_id: string;
  lesson_name: string;
  lesson_num: string;
  lesson_all: string;
}>(({ course_id, course_name, lesson_name, lesson_num, lesson_all, lesson_id }) => ({
  event: "autoEvent",
  event_name: CourseViewPageEvents.INTERACTION_BLOCK,
  task_type: "video",
  course_id,
  course_name,
  lesson_id,
  lesson_name,
  lesson_num,
  lesson_all,
}));

export const interactionBlockTest = createAnalyticsEvent<{
  course_id: string;
  course_name: string;
  task_id: string;
}>(({ course_id, course_name, task_id }) => ({
  event: "autoEvent",
  event_name: CourseViewPageEvents.INTERACTION_BLOCK,
  task_type: "test",
  course_id,
  course_name,
  task_id,
}));

export const interactionBlockMaterials = createAnalyticsEvent<{
  course_id: string;
  course_name: string;
}>(({ course_id, course_name }) => ({
  event: "autoEvent",
  event_name: CourseViewPageEvents.INTERACTION_BLOCK,
  task_type: "teхt",
  course_id,
  course_name,
}));

export const viewTaskVideo = createAnalyticsEvent<{
  course_id: string;
  course_name: string;
  lesson_id: string;
  lesson_name: string;
  task_progr: number;
}>(({ course_id, course_name, lesson_name, lesson_id, task_progr }) => {
  return {
    event: "autoEvent",
    event_name: CourseViewPageEvents.VIEW_TASK_VIDEO,
    task_type: "video",
    course_id,
    course_name,
    lesson_name,
    lesson_id,
    task_progr,
  };
});
