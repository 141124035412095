import clsx from "clsx";
import { useEvent } from "effector-react/scope";

import { Header } from "@/widgets/header";

import { analyticsEvents } from "@/shared/analytics";
import { scrollToElement } from "@/shared/lib/scroll";
import { ButtonRounded, Typography } from "@/shared/ui";

import { CTA_CERTIFICATE } from "./constants";
import styles from "./spring-landing.module.scss";

export const StartSection = () => {
  const sendAnalytics = useEvent(analyticsEvents.springPage.openContactSpring);

  const handleClick = () => {
    sendAnalytics();
    scrollToElement(CTA_CERTIFICATE);
  };

  return (
    <>
      <Header.Mobile />
      <section className="bg-m-dark-blue h-[670px] md:h-[570px] xl:h-[670px] 3xl:h-[1000px] relative">
        <Header.Desktop
          subscribeButtonFixed={false}
          className="md:absolute top-0 left-0 bg-transparent w-full z-10"
        />
        <div className={clsx(styles.StartSection, "absolute inset-0")} />
        <div className="text-m-white uppercase font-bold absolute top-mn-38 left-mn-20 md:top-auto md:left-mn-26 xl:left-mn-74 md:bottom-mn-111 3xl:bottom-mn-150 3xl:left-mn-111">
          <Typography tag="h1" size="m-h2" className="mb-mn-10 md:mb-mn-20 3xl:mb-mn-30">
            <span className="gradient-text">8 курсов</span> для той,
            <br /> кто вдохновляет
          </Typography>
          <Typography tag="h5" size="m-h5" className="mb-mn-20 3xl:mb-mn-30">
            Собрали все лучшее в одном
            <br className="block md:hidden" /> сертификате <br className="hidden md:block" /> для
            подарка <br className="block md:hidden" /> себе или любимым
          </Typography>
          <ButtonRounded onClick={handleClick}>Подарить сертификат</ButtonRounded>
        </div>
      </section>
    </>
  );
};
