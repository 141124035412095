import queryString from "query-string";

export function queryToString(query: Record<string, string> | undefined): string {
  return query ? `?${queryString.stringify(query)}` : "";
}

export const JSONToCookie = <T>(cookie: T) => {
  return Object.entries(cookie).reduce((cookieString: string, token) => {
    const [key, value] = token;
    // eslint-disable-next-line no-param-reassign
    cookieString += `${key}=${value};`;
    return cookieString;
  }, "");
};

export const parseCookieStringToJson = (cookie: string) =>
  cookie
    .split(";")
    .map((v) => v.split("="))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});

export const getCookieByKey = (cookie: string, key: string): string | undefined => {
  const cookiePairs = cookie.split("; ");

  for (const cookiePair of cookiePairs) {
    const [cookieKey, cookieValue] = cookiePair.split("=");

    if (cookieKey === key) {
      return cookieValue;
    }
  }

  return undefined;
};
