import {
  attach,
  combine,
  createDomain,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { createHatch } from "framework";
import { pending } from "patronum/pending";
import { some } from "patronum/some";

import {
  RecommendationFilter,
  Course,
  CourseFormatEnum,
  Level,
  Period,
  Program,
  TopicId,
  FilterPayload,
} from "@app/api";

import { searchParamsReset } from "@/entities/navigation";
import { sortProgramsOnDate } from "@/entities/program";
import { $authenticatedUserId } from "@/entities/user";

import { CourseApi, FilterApi, ProgramApi, TopicApi } from "@/shared/api";
import { createPagination, debounceRequest } from "@/shared/lib/effector";
import { scrollToElement } from "@/shared/lib/scroll";

import {
  createFilter,
  createListFilters,
  createTopicFilters,
  TriggerFilterMode,
} from "@/feature/filters";

import { filterBuilder } from "./modules/filters";
import { Anchor } from "./modules/shared";

type PageInitialized = {
  topics: TopicId[];
  filters: { name: string; value: string }[];
  triggerMode: TriggerFilterMode;
};

export const mapFiltersToRequest = (filters: { name: string; value: string }[]) => {
  if (!filters.length) return undefined;

  const filter = filters.reduce((acc, filterItem) => {
    acc[filterItem.name] = filterItem.value;
    return acc;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as Record<string, any>);

  const educationTypeFormats =
    filter.education === "any"
      ? [CourseFormatEnum.online_course, CourseFormatEnum.program]
      : [filter.education];

  const levelType = filter.level === "any" ? undefined : filter.level;
  const periodType = filter.period === "any" ? undefined : filter.period;

  return {
    courseFormats: educationTypeFormats,
    level: levelType,
    period: periodType,
    recommendation: filter.recommendation,
  };
};

type FilterMap = ReturnType<typeof mapFiltersToRequest>;

const mapTopicsToRequest = (topicIds: TopicId[]) =>
  topicIds.some((topic) => topic === "all") ? [] : topicIds;

export const hatch = createHatch(createDomain("AdultPage"));

const pageInitialized = createEvent<PageInitialized>();

export const resetAllFilters = createEvent();

export const topicFilter = createTopicFilters({
  name: "topics",
  syncWithUrl: true,
  defaultTopic: { id: "all", name: "Все направления" },
});

export const educationFilter = createFilter<CourseFormatEnum | "any">({
  name: "education",
  defaultValue: "any",
});

export const levelFilter = createFilter<Level | "any">({
  name: "level",
  defaultValue: "any",
});

export const periodFilter = createFilter<Period | "any">({
  name: "period",
  defaultValue: "any",
});

export const recommendationFilter = createFilter<RecommendationFilter>({
  name: "recommendation",
  defaultValue: "rec_off",
});

export const filterList = createListFilters([
  levelFilter,
  periodFilter,
  educationFilter,
  recommendationFilter,
]);

export const $hasSelectedFilters = some({
  stores: [filterList.$hasAppliedFilters, topicFilter.$hasAppliedFilters],
  predicate: Boolean,
});

const $filterParams = combine(filterList.$filters, (filters) => mapFiltersToRequest(filters));
const $topicParams = combine(topicFilter.$selectedTopicsIds, (topicIds) =>
  mapTopicsToRequest(topicIds),
);

export const LIMIT = 12;
export const OFFSET = 0;

export const getProductsByFilterFx = attach({
  source: $authenticatedUserId,
  effect: async (userId, { filters, topics }: { filters: FilterMap; topics: string[] }) => {
    const payload: FilterPayload = {
      userId,
      params: {
        period: filters?.period,
        level: filters?.level,
        recommendation: filters?.recommendation,
        courseFormats: filters?.courseFormats,
        limit: LIMIT,
        offset: OFFSET,
        topics,
      },
    };

    const filterResult = await FilterApi.getProductsByFilter(payload);

    return filterResult;
  },
});

export const paginationPrograms = createPagination({
  limit: LIMIT,
  offset: OFFSET,
  $params: $filterParams,
  mapParams: (params) => ({ ...params, courseFormats: [CourseFormatEnum.program] }),
  effect: ProgramApi.getPrograms,
});

export const paginationCourses = createPagination({
  limit: LIMIT,
  offset: OFFSET,
  $params: $filterParams,
  effect: CourseApi.getAllCourses,
  mapParams: (params) => ({ ...params, courseFormats: [CourseFormatEnum.online_course] }),
});

const getAllTopicsFx = createEffect(TopicApi.getTopics);

export const $courses = createStore<Course[]>([])
  .on(
    getProductsByFilterFx.doneData.map((product) => product.courses),
    (_, courses) => courses,
  )
  .on(paginationCourses.loadItemsFx.doneData, (state, courses) => [...state, ...courses]);

export const $programs = createStore<Program[]>([])
  .on(
    getProductsByFilterFx.doneData.map((product) => sortProgramsOnDate(product.programs)),
    (_, programs) => programs,
  )
  .on(paginationPrograms.loadItemsFx.doneData, (state, programs) => [...state, ...programs]);

export const $isProductsLoading = pending({
  effects: [getProductsByFilterFx],
});

sample({
  clock: hatch.enter,
  fn: ({ query }) => {
    const builtFilter = filterBuilder()
      .setEducation(query.education as CourseFormatEnum)
      .setLevel(query.level as Level)
      .setPeriod(query.period as Period)
      .setTopics(query.topics)
      .setRecommendation(query.recommendation as RecommendationFilter)
      .build();

    const initializedParams: PageInitialized = {
      filters: [
        { name: "education", value: builtFilter.education },
        { name: "level", value: builtFilter.level },
        { name: "period", value: builtFilter.period },
        { name: "recommendation", value: builtFilter.recommendation },
      ],
      topics: builtFilter.topics,
      triggerMode: "init",
    };

    return initializedParams;
  },
  target: pageInitialized,
});

sample({
  clock: getAllTopicsFx.doneData,
  target: topicFilter.topicsSettled,
});

sample({
  clock: hatch.enter,
  target: getAllTopicsFx,
});

const runSearchFilters = () => {
  // change topics request
  sample({
    clock: topicFilter.topicSelected,
    source: { filters: $filterParams, topics: $topicParams },
    fn: ({ filters, topics }) => ({
      filters,
      topics,
    }),
    target: getProductsByFilterFx,
  });

  sample({
    clock: $filterParams,
    source: {
      topics: $topicParams,
      triggerMode: filterList.$triggerMode,
    },
    filter: ({ triggerMode }) => triggerMode === "change" || triggerMode === "init",
    fn: ({ topics }, filters) => {
      return {
        filters,
        topics,
      };
    },
    target: debounceRequest({ effect: getProductsByFilterFx, timeout: 500 }),
  });

  // apply filters request
  sample({
    clock: $filterParams,
    source: {
      topics: $topicParams,
      triggerMode: filterList.$triggerMode,
      filters: $filterParams,
    },
    filter: ({ triggerMode }) => triggerMode === "apply",
    fn: ({ topics }, filters) => ({
      filters,
      topics,
    }),
    target: getProductsByFilterFx,
  });
};

runSearchFilters();

sample({
  clock: [filterList.changed, resetAllFilters],
  source: filterList.$triggerMode,
  filter: (triggerMode) => triggerMode === "change",
  target: createEffect(() => setTimeout(() => scrollToElement(Anchor.Catalog), 100)),
});

sample({
  clock: pageInitialized,
  fn: ({ topics }) => topics,
  target: topicFilter.initialized,
});

sample({
  clock: pageInitialized,
  fn: ({ triggerMode }) => triggerMode,
  target: filterList.setTriggerMode,
});

sample({
  clock: pageInitialized,
  fn: ({ filters }) => filters,
  target: filterList.initialized,
});

sample({
  clock: [$filterParams, filterList.applied],
  target: [paginationPrograms.reset, paginationCourses.reset],
});

sample({
  clock: resetAllFilters,
  target: [searchParamsReset, filterList.reset, topicFilter.reset],
});
