import { CardProps, CourseCard } from "@/entities/course";
import {
  CourseCompletionIndicator,
  CourseProgressIndicator,
} from "@/entities/course/ui/progress-indicator";

import { LikeButton } from "../../like-button/like-button";

export const FavoriteCourseCard = ({ course }: CardProps) => {
  return (
    <CourseCard
      course={course}
      renderNode={
        <LikeButton
          iconClassName="mt-[4px] scale-[0.8] 3xl:scale-[1]"
          className="rounded-[12px] overflow-hidden bg-m-white w-[35px] h-[35px] 3xl:w-[43px] 3xl:h-[43px] flex items-center justify-center"
          courseId={course.id}
        />
      }
    />
  );
};

export const CompletedCourseCard = ({ course }: CardProps) => (
  <CourseCard course={course} renderNode={<CourseCompletionIndicator />} />
);

export const ProgressableCourseCard = ({ course }: CardProps) => {
  return (
    <CourseCard course={course} renderNode={<CourseProgressIndicator courseId={course.id} />} />
  );
};
