import { KeenSliderInstance } from "keen-slider";
import React, { useState } from "react";

import loadable from "@loadable/component";

import { SliderControls, SliderBullets } from "../slider";

export const BaseCarousel = loadable(
  () => import("../slider").then((component) => component.BaseCarousel),
  {
    ssr: true,
  },
);

export const CarouselPreview = ({
  data,
  onArrowClick,
}: {
  data: JSX.Element[];
  onArrowClick?: () => void;
}) => {
  const [sliderInstance, setSliderInstance] = useState<KeenSliderInstance | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <BaseCarousel
      containerClassName="h-full relative"
      getInstanceRef={setSliderInstance}
      getActiveSlideIndex={setActiveSlide}
      slideClassName="h-full"
      data={data}
      enablePlugins={["autoplay"]}
    >
      {sliderInstance && (
        <div className="z-10 absolute bottom-mn-38 3xl:bottom-mn-8 w-full">
          <SliderControls
            controlsClass="!bg-m-white hidden md:flex"
            classNameContainer="child:text-m-orange justify-center"
            navigationRenderPreset="circle"
            renderBetweenControls={<SliderBullets data={data} activeSlide={activeSlide} />}
            onSlideNext={() => {
              sliderInstance?.next();
              if (onArrowClick) onArrowClick();
            }}
            onSlidePrev={() => {
              sliderInstance?.prev();
              if (onArrowClick) onArrowClick();
            }}
          />
        </div>
      )}
    </BaseCarousel>
  );
};
