import { useStoreMap } from "effector-react/scope";

import { mapCourseProgressToRoundedPercentage } from "./lib";
import { $courseProgressFetchMap, $courseProgressMap, FetchState } from "./model/course-progress";

export const useCourseProgress = (courseId: string) => {
  const isCourseProgressAlreadyFetched = useStoreMap($courseProgressMap, (map) => {
    return courseId in map;
  });

  const courseProgress = useStoreMap($courseProgressMap, (map) => {
    return map[courseId] ?? 0;
  });

  const fetchState = useStoreMap($courseProgressFetchMap, (map) => {
    const courseStatus = map[courseId];

    if (!courseStatus) {
      return { pending: false, done: false, fail: false };
    }

    return {
      pending: !isCourseProgressAlreadyFetched && courseStatus === FetchState.Pending,
      done: courseStatus === FetchState.Done,
      fail: courseStatus === FetchState.Error,
      refetching: isCourseProgressAlreadyFetched && courseStatus === FetchState.Pending,
    };
  });

  return { fetchState, courseProgress };
};

export const useMapNumberToRoundedPercentage = (number: number) =>
  mapCourseProgressToRoundedPercentage(number);
