import { useStore } from "effector-react/scope";
import React from "react";

import { $hasCourseAccess } from "../../../model";
import { CertificatePromoLayout } from "../../course-content";
import { GoToTestButton, MaterialHeaders } from "../../course-lesson-materials";

export const MaterialsRightBlock = () => {
  const hasAccess = useStore($hasCourseAccess);
  return (
    <div className="h-full relative">
      <CertificatePromoLayout />

      <div className="mt-[85px] sticky top-20 hidden xl:block">
        {hasAccess && <MaterialHeaders />}
      </div>

      {hasAccess && (
        <div className="mx-auto xl:hidden flex items-center justify-center">
          <GoToTestButton />
        </div>
      )}
    </div>
  );
};
