import { useEvent, useStore } from "effector-react/scope";
import React, { useEffect } from "react";

import { paths } from "@/pages/paths";

import { historyPush } from "@/entities/navigation";

import { Modal, ModalNotification } from "@/shared/ui";

import { feedbackSentModal } from "../../model/certificate-issueing";

export const FeedbackSuccessModal = () => {
  const close = useEvent(feedbackSentModal.close);
  const isOpenModal = useStore(feedbackSentModal.$isOpen);
  const push = useEvent(historyPush);

  useEffect(() => () => close(), []);

  return (
    <Modal
      isOpen={isOpenModal}
      close={close}
      render={() => (
        <ModalNotification
          onClose={() => close()}
          type="success"
          title="спасибо за ваш отзыв!"
          message="Он будет опубликован после модерации"
          renderButtons={[
            {
              title: "Перейти к курсам",
              onClick: () => push(paths.catalog()),
              variant: "primary",
            },
          ]}
        />
      )}
    />
  );
};
