import { combine, sample, createEvent } from "effector";

import {
  CancellationReason,
  getPaymentErrors,
  paymentModel,
  PaymentType,
  TransactionPaymentEvent,
  TransactionStatus,
} from "@/entities/payment";

import { analyticsEvents, AnalyticsType } from "@/shared/analytics";
import { PaymentApi, PurchaseType } from "@/shared/api";

import { paymentModalOpened, PaymentModalType } from "./model/payment-management";
import { paymentTransaction } from "./model/payment-transaction";

const mapPaymentTypeToAnalytics = (paymentType: PaymentType): AnalyticsType.PayType => {
  const map: { [type in PaymentType]: AnalyticsType.PayType } = {
    [PaymentType.Subscription]: "subscr",
    [PaymentType.Course]: "course",
  };

  return map[paymentType];
};

const hasPaymentType = (paymentType: PaymentType | null) => paymentType !== null;

const isSuccessBuyCourse = (
  transaction: TransactionPaymentEvent | null,
  paymentType: PaymentType | null,
): boolean =>
  transaction !== null &&
  transaction.status === TransactionStatus.SUCCESSFUL &&
  hasPaymentType(paymentType) &&
  paymentType === PaymentType.Course;

const isSuccessBuySubscription = (
  transaction: TransactionPaymentEvent | null,
  paymentType: PaymentType | null,
): boolean =>
  transaction !== null &&
  transaction.status === TransactionStatus.SUCCESSFUL &&
  hasPaymentType(paymentType) &&
  paymentType === PaymentType.Subscription;

const $paymentInfo = combine({
  paymentType: paymentModel.$paymentType,
  transaction: paymentTransaction.$transactionStatus,
  subscription: paymentModel.$selectedSubscription,
  course: paymentModel.$coursePayment,
});

export const startSubscriptionClicked = createEvent<{
  positionInfo: string;
  text: string;
}>();
export const termOfUseLinkClicked = createEvent();
export const privacyPolicyLinkClicked = createEvent();
export const edutoriaLinkClicked = createEvent<string>();

sample({
  clock: startSubscriptionClicked,
  fn: ({ positionInfo, text }) => ({ link_type: positionInfo, link_text: text }),
  target: analyticsEvents.clickMenu,
});

sample({
  clock: paymentModel.readyToSubscriptionPay,
  source: paymentModel.$selectedSubscription,
  fn: (subscription) => ({
    link_text: String(subscription?.definedTermPrice),
    link_type: String(subscription?.definedTermPeriod),
  }),
  target: analyticsEvents.clickPaySubscription,
});

sample({
  clock: paymentModalOpened,
  source: paymentModel.$paymentType,
  filter: (paymentType, modalType) =>
    hasPaymentType(paymentType) && modalType === PaymentModalType.Pay,
  fn: (paymentType) => ({ topic: mapPaymentTypeToAnalytics(paymentType as PaymentType) }),
  target: analyticsEvents.openWidgetPay,
});

sample({
  clock: paymentModalOpened,
  source: paymentModel.$paymentType,
  filter: (paymentType, modalType) =>
    hasPaymentType(paymentType) && modalType === PaymentModalType.Close,
  fn: (paymentType) => ({ topic: mapPaymentTypeToAnalytics(paymentType as PaymentType) }),
  target: analyticsEvents.closeWidgetPay,
});

const shouldInvokeBuyCourse = sample({
  clock: paymentTransaction.transactionStatusChanged,
  source: $paymentInfo,
  filter: (info) => isSuccessBuyCourse(info.transaction, info.paymentType),
});

sample({
  clock: shouldInvokeBuyCourse,
  fn: (info) => ({
    course_id: info.course?.platformId ?? "",
    price: String(info.course?.definedTermPrice) ?? "",
    course_name: info.course?.title ?? "",
  }),
  target: analyticsEvents.viewCoursePurchaseSuccess,
});

sample({
  clock: shouldInvokeBuyCourse,
  fn: (info) => ({
    courseId: info.course?.platformId ?? "",
    purchaseType: PurchaseType.buy,
  }),
  target: PaymentApi.sendCoursePurchaseEvent,
});

sample({
  clock: paymentTransaction.transactionStatusChanged,
  source: $paymentInfo,
  filter: (info) => isSuccessBuySubscription(info.transaction, info.paymentType),
  fn: (info) => ({
    id: info.subscription?.platformId ?? "",
    price: String(info.subscription?.definedTermPrice) ?? "",
  }),
  target: analyticsEvents.viewSubscrPurchaseSuccess,
});

sample({
  clock: paymentTransaction.transactionStatusChanged,
  source: $paymentInfo,
  filter: (info) => info.transaction?.status === TransactionStatus.CANCELED,
  fn: (info) => {
    const reason = info.transaction?.cancellationReason as CancellationReason;
    const errorTitle = getPaymentErrors(reason).title;
    const errorDescription = getPaymentErrors(reason).description;
    return {
      topic: mapPaymentTypeToAnalytics(info.paymentType as PaymentType),
      link_text: `${errorTitle}. ${errorDescription}`,
    };
  },
  target: analyticsEvents.errorPay,
});

sample({
  clock: termOfUseLinkClicked,
  target: analyticsEvents.clickTermsOfUse,
});

sample({
  clock: privacyPolicyLinkClicked,
  target: analyticsEvents.clickPrivacyPolicy,
});

sample({
  clock: edutoriaLinkClicked,
  fn: (position) => ({ link_type: position }),
  target: analyticsEvents.clickEdutoriaLink,
});

export const buyCourseClicked = createEvent<{
  courseId: string;
  courseName: string;
  coursePrice: number;
}>();

sample({
  clock: buyCourseClicked,
  fn: ({ courseId, courseName, coursePrice }) => ({
    course_id: courseId,
    course_name: courseName,
    price: coursePrice,
  }),
  target: analyticsEvents.clickCourseCheckout,
});
