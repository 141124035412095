import clsx from "clsx";
import { useStore } from "effector-react/scope";

import { disableCopyPasteHTMLElement, useCopyPasteDisable } from "@/shared/lib/keyboard";

import { MaterialsBuilder } from "@/feature/materials-builder";
import styles from "@/feature/materials-builder/ui/materials.module.scss";

import { $hasCourseAccess } from "../../../model";
import { $sections } from "../model/materials";

export const CourseMaterials = () => {
  useCopyPasteDisable();

  const sections = useStore($sections);
  const hasCourseAccess = useStore($hasCourseAccess);

  if (!sections.length) return null;

  return (
    <div
      {...disableCopyPasteHTMLElement()}
      onContextMenu={(e) => e.preventDefault()}
      className={clsx(styles.MaterialsContainer, "flex flex-col relative", {
        "h-[60vh] overflow-hidden": !hasCourseAccess,
      })}
    >
      {sections.map((section) => {
        return (
          <div
            className="first:mt-0 mt-mv-4 xl:mt-mv-5 3xl:mt-mv-6"
            id={section.sectionId}
            key={section.sectionId}
          >
            <MaterialsBuilder materials={section.materials} />
          </div>
        );
      })}
    </div>
  );
};
