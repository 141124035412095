import { createEffect, createEvent, createStore, sample } from "effector";

import { $authenticatedUserId } from "@/entities/user";

import { UserApi } from "@/shared/api";
import { uniqId } from "@/shared/lib/uuid";

export const studentProfilePictureChanged = createEvent<string>();
export const studentProfilePictureDeleteAttempted = createEvent();
export const resetProfilePictureUpdateError = createEvent();
export const resetProfilePictureUpdateSuccess = createEvent();

export const $studentProfilePicture = createStore<string | null>(null);

export const getUserAvatarFx = createEffect(UserApi.getUserAvatar);

const changeProfilePictureFx = createEffect(
  async ({ imageDataURL, userId }: { imageDataURL: string; userId: string }) => {
    const regExpArray = /:([^;]+);/.exec(imageDataURL);

    if (!regExpArray || regExpArray.length === 0) throw Error(); // need handler

    const [, contentType] = regExpArray;
    const [, data] = imageDataURL.split("base64,");
    const [, extension] = contentType.split("/");

    const imageUrl = await UserApi.uploadAvatar({
      content_type: contentType,
      dir_type: "users",
      file_data: data,
      file_name: `${uniqId()}.${extension}`,
      dir_id: userId,
    });

    return UserApi.updateUserAvatar({
      imageUrl,
      userId,
    });
  },
);

const deleteProfilePictureFx = createEffect(UserApi.deleteUserAvatar);

export const $isProfilePictureChangeFailed = createStore(false);
export const $isProfilePictureChangeSuccessful = createStore(false);

$isProfilePictureChangeFailed.reset(resetProfilePictureUpdateError);
$isProfilePictureChangeSuccessful.reset(resetProfilePictureUpdateSuccess);

sample({
  clock: [changeProfilePictureFx.fail, deleteProfilePictureFx.fail],
  fn: () => true,
  target: $isProfilePictureChangeFailed,
});

sample({
  clock: [changeProfilePictureFx.done, deleteProfilePictureFx.done],
  fn: () => true,
  target: $isProfilePictureChangeSuccessful,
});

sample({
  clock: studentProfilePictureChanged,
  source: $authenticatedUserId,
  fn: (userId, image) => ({ imageDataURL: image, userId }),
  target: changeProfilePictureFx,
});

sample({
  clock: [changeProfilePictureFx.doneData, deleteProfilePictureFx.doneData],
  fn: (avatar) => avatar ?? null,
  target: $studentProfilePicture,
});

sample({
  clock: studentProfilePictureDeleteAttempted,
  source: $authenticatedUserId,
  fn: (userId) => userId,
  target: deleteProfilePictureFx,
});

sample({
  clock: $authenticatedUserId,
  target: getUserAvatarFx,
});

sample({
  clock: getUserAvatarFx.doneData,
  fn: (avatar) => avatar ?? null,
  target: $studentProfilePicture,
});
