import closeIcon from "@/shared/ui/assets/images/icons/close.svg";

import { filterLabels, useApplyFilter } from "@/feature/filters";

import { filterList } from "../../../model";

type ApplyFilterChipProps = {
  onRemove: (name: string) => void;
  filter: { name: string; value: string };
};

const ApplyFilterChip = ({ onRemove, filter }: ApplyFilterChipProps) => {
  const label = filterLabels[filter.value];
  const hasLabel = Boolean(label);

  if (!hasLabel) return null;

  return (
    <div id={`apply-filter-${filter.name}`} className="bg-m-blue px-[12px] py-[8px] rounded-[6px]">
      <div className="flex items-center justify-between">
        <span className="uppercase text-m-xs text-m-white mr-[8px] font-semibold">{label}</span>
        <button onClick={() => onRemove(filter.name)}>
          <img className="h-[10px] w-[10px]" src={closeIcon} alt="Сбросить фильтр" />
        </button>
      </div>
    </div>
  );
};

export const AppliedFiltersList = () => {
  const applyFilter = useApplyFilter(filterList);

  if (!applyFilter.hasAppliedFilters) return null;

  return (
    <div className="flex gap-[10px] flex-wrap mt-mn-10">
      {applyFilter.filters
        .filter((selected) => selected.value !== "any")
        .map((selected) => (
          <ApplyFilterChip
            key={selected.name}
            filter={selected}
            onRemove={applyFilter.resetSelectedFilter}
          />
        ))}
    </div>
  );
};
