import React from "react";

import certificateImg from "@/shared/ui/assets/images/lesson-page/certificate.webp";

export const CertificatePromoLayout = () => {
  return (
    <div>
      <div className="mb-mv-3 xl:mb-mv-4">
        <img
          src={certificateImg}
          className="h-auto w-[246px] xl:w-[256px] 3xl:w-[326px] mx-auto"
          alt="Сертификат"
        />
      </div>
      <p className=" mx-auto text-center gradient-text text-m-base 3xl:text-m-xl font-m-sb-display font-semibold">
        Выполни все задания курса и&nbsp;получи сертификат, подтверждающий твои знания!
      </p>
    </div>
  );
};
