import clsx from "clsx";
import { memo, ReactNode } from "react";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";

import { NavButton } from "../../button/button";
import styles from "./menu.module.scss";

const LINK_REGEX = /^http(s?):\/\/.*/;

type ContainerProps = {
  children: ReactNode;
  className?: string;
};

type MenuLinkProps = {
  classNameLink?: string;
  variant?: "normal" | "underline";
  nativeLink?: boolean;
  target?: "_blank" | "_self" | "_parent" | "_top";
} & Pick<NavLinkProps, "to" | "exact" | "onClick"> &
  ContainerProps;

type MenuButtonProps = {
  onClick?: () => void;
  variant?: "primary" | "secondary";
} & ContainerProps;

const MenuContainer = ({ children, className }: ContainerProps) => (
  <ul className={clsx("flex flex-row", className)}>{children}</ul>
);

const Link = memo(
  ({
    children,
    exact,
    onClick,
    to = "#!",
    className,
    classNameLink,
    variant = "normal",
    target = "_blank",
    nativeLink = false,
    ...rest
  }: MenuLinkProps) => {
    const { pathname } = useLocation();

    const renderText = () => (
      <span
        className={clsx(
          {
            [`${to === pathname ? "gradient-text" : "text-m-white"} hover:gradient-text`]:
              variant === "normal",
            "uppercase text-m-white hover:text-m-orange font-normal": variant === "underline",
          },
          styles.LinkMenu,
          classNameLink,
        )}
      >
        {children}
      </span>
    );

    const spaHref = !LINK_REGEX.test(to as string) && !nativeLink;
    const nativeHref = LINK_REGEX.test(to as string) || nativeLink;

    return (
      <li className={className}>
        {spaHref && (
          <NavLink to={to} onClick={onClick} exact={exact} {...rest}>
            {renderText()}
          </NavLink>
        )}
        {nativeHref && (
          <a href={to as string} target={target} rel="noreferrer" {...rest}>
            {renderText()}
          </a>
        )}
      </li>
    );
  },
);

const Button = ({ children, className, variant = "primary", onClick }: MenuButtonProps) => {
  return (
    <NavButton variant={variant} onClick={onClick} className={className}>
      {children}
    </NavButton>
  );
};

export const Menu = MenuContainer as typeof MenuContainer & {
  Link: typeof Link;
  Button: typeof Button;
};

Menu.Link = Link;
Menu.Button = Button;
