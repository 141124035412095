import clsx from "clsx";
import { forwardRef, useState } from "react";
import { IMaskInput, IMask } from "react-imask";

import { DateUtils, formatDate } from "@/shared/lib/date";
import { Calendar, OutsideClick } from "@/shared/ui";
import { ReactComponent as CaretLeftIcon } from "@/shared/ui/assets/images/icons/arrow-pagination-left.svg";
import { ReactComponent as CaretRightIcon } from "@/shared/ui/assets/images/icons/arrow-pagination-right.svg";
import { ReactComponent as CalendarIcon } from "@/shared/ui/assets/images/icons/calendar.svg";

import style from "./style.module.scss";

const apiDateFormat = "dd.MM.yyyy";
const inputDateFormat = "dd.MM.yyyy";

const dateAge18 = new Date(
  new Date().getFullYear() - 18,
  new Date().getMonth(),
  new Date().getDate(),
);

const dateAge120 = new Date(
  new Date().getFullYear() - 120,
  new Date().getMonth(),
  new Date().getDate(),
);

interface InputDateProps {
  value: string;
  onChange: (date: string) => void;
  onBlur: () => void;
  onFocus?: () => void;
  isError?: boolean;
  disabled?: boolean;
}

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
  ({ value, onChange, isError, onBlur, onFocus, disabled }, ref) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [blockOutsideClick, setBlockOutsideClick] = useState(false);

    const toggleCalendar = () => {
      if (disabled) return;
      if (
        !value ||
        !DateUtils.isValid(DateUtils.parse(value, apiDateFormat, new Date())) ||
        value.length < apiDateFormat.length
      ) {
        onChange(formatDate(dateAge18, apiDateFormat));
      }
      setShowCalendar(!showCalendar);
    };

    const handleClickOutside = () => {
      setBlockOutsideClick(true);
      toggleCalendar();
      setTimeout(() => {
        setBlockOutsideClick(false);
      });
    };

    return (
      <div>
        <div className={style.InputDateWrapper}>
          <IMaskInput
            disabled={disabled}
            mask={Date}
            placeholder="Введите дату рождения"
            // @ts-expect-error: hot fix for https://github.com/uNmAnNeR/imaskjs/pull/576
            onAccept={(acceptValue: string) => {
              onChange(acceptValue);
            }}
            value={value}
            className={clsx(style.InputDate, isError && style.InputDateError)}
            onFocus={() => {
              if (!value) {
                onChange(formatDate(dateAge18, apiDateFormat));
              }
              if (showCalendar) {
                setShowCalendar(false);
              }
              if (onFocus) onFocus();
            }}
            onBlur={onBlur}
            pattern="d.`m.`Y"
            parse={(str: string) => {
              return DateUtils.parse(str, inputDateFormat, new Date());
            }}
            format={(date: Date) => {
              return formatDate(date, inputDateFormat);
            }}
            blocks={{
              d: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31,
                maxLength: 2,
              },
              m: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
                maxLength: 2,
              },
              Y: {
                mask: IMask.MaskedRange,
                from: 1900,
                to: 2100,
              },
            }}
            // @ts-expect-error: hot fix for https://github.com/uNmAnNeR/imaskjs/pull/576
            min={dateAge120}
            // @ts-expect-error: hot fix for https://github.com/uNmAnNeR/imaskjs/pull/576
            max={dateAge18}
            ref={ref}
          />
          {!disabled && (
            <div
              className={clsx(
                "svg-dark-to-orange",
                { "hover:cursor-default right-[10px]": disabled },
                style.CalendarIconWrapper,
              )}
              onClick={() => {
                if (!blockOutsideClick) toggleCalendar();
              }}
              onKeyDown={toggleCalendar}
              role="button"
              tabIndex={0}
            >
              <CalendarIcon className="w-[15px]" />
            </div>
          )}
        </div>
        {showCalendar && (
          <OutsideClick onOutsideClick={handleClickOutside}>
            <Calendar
              onChange={(date: Date) => {
                setShowCalendar(false);
                onChange(formatDate(date, apiDateFormat));
              }}
              value={DateUtils.parse(value, apiDateFormat, new Date())}
              locale="ru-RU"
              navigationLabel={({ label, view }) =>
                view === "month" ? `${label.charAt(0).toUpperCase() + label.slice(1, -2)}` : label
              }
              showNeighboringMonth={false}
              next2Label={null}
              prev2Label={null}
              className={style.Calendar}
              nextLabel={<CaretRightIcon className="stroke-white" />}
              prevLabel={<CaretLeftIcon className="stroke-white" />}
              maxDate={dateAge18}
              minDate={dateAge120}
            />
          </OutsideClick>
        )}
      </div>
    );
  },
);
