import clsx from "clsx";
import { ReactNode } from "react";

import { AnimatePresence, motion } from "@/shared/ui";

type HintProps = {
  className?: string;
  children: ReactNode;
  isHintVisible?: boolean;
  animationShowMode?: "topBottom" | "bottomTop";
};

const animationTopBottom = {
  initial: { y: -10, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: -5, opacity: 0 },
};

const animationBottomTop = {
  initial: { y: 10, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 10, opacity: 0 },
};

const animationMode = {
  topBottom: animationTopBottom,
  bottomTop: animationBottomTop,
};

export const Hint = ({
  className,
  children,
  isHintVisible,
  animationShowMode = "topBottom",
}: HintProps) => {
  return (
    <AnimatePresence exitBeforeEnter initial={false} key="start">
      {isHintVisible && (
        <motion.div
          {...animationMode[animationShowMode]}
          className={clsx("relative z-20", className)}
        >
          <div className="top-[5px] absolute bg-m-semi-dark p-mn-20 min-w-[350px] w-[15vw] rounded-[3px]">
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
