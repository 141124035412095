import { attach, createEvent, createStore, sample } from "effector";

import { CourseApi } from "@/shared/api";
import { randomizeArray } from "@/shared/lib/array/randomize";

import { getFavoriteCoursesIdFx } from "@/feature/favorite-courses";

import { Course } from "../types";

export const readyToLoadCourses = createEvent();

export const getAllProductsFx = attach({ effect: CourseApi.getAllCourses });
export const $courses = createStore<Course[]>([]);
$courses.on(getAllProductsFx.doneData, (_, products) => randomizeArray(products));

sample({
  source: readyToLoadCourses,
  target: [getAllProductsFx, getFavoriteCoursesIdFx],
});
