import { useEvent } from "effector-react/scope";
import React from "react";
import { NavLink } from "react-router-dom";

import { paths } from "@/pages/paths";

import { CourseCard } from "@/entities/course";
import { historyPush } from "@/entities/navigation";

import { ButtonRounded, EmptyDataLayout, Typography } from "@/shared/ui";

import { ViewOption } from "../model/education";
import { CompletedCourseCard, FavoriteCourseCard, ProgressableCourseCard } from "./user-cards";

export const mapSelectedOptionToCourseCard = (value: ViewOption) => {
  const cards = {
    [ViewOption.favourite]: FavoriteCourseCard,
    [ViewOption.completed]: CompletedCourseCard,
    [ViewOption.progressing]: ProgressableCourseCard,
  };

  return cards[value] || CourseCard;
};

export const EmptyLayout = () => {
  const push = useEvent(historyPush);

  return (
    <EmptyDataLayout>
      <Typography
        tag="h3"
        size="m-h5"
        className="w-[220px] md:w-full text-m-white font-m-sb-display font-semibold text-center mb-6"
      >
        <NavLink to={paths.catalog()}>Найти свой источник вдохновения</NavLink>
      </Typography>
      <ButtonRounded variant="secondary" onClick={() => push(paths.catalog())}>
        К курсам
      </ButtonRounded>
    </EmptyDataLayout>
  );
};
