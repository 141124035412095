import clsx from "clsx";
import { useEvent, useStore } from "effector-react/scope";

import { paths } from "@/pages/paths";

import { historyPush } from "@/entities/navigation";
import { getTrialState, TrialState } from "@/entities/payment";
import { userPurchasesModel } from "@/entities/user-purchases";

import { ButtonRounded, ButtonRoundedProps } from "@/shared/ui";

import { startSubscriptionClicked } from "../analytics";
import { paymentModalOpened, PaymentModalType } from "../model/payment-management";

type ButtonStartSubscriptionProps = {
  buttonText?: string;
  variant?: ButtonRoundedProps["variant"];
  className?: string;
  onSendAnalytics?: (currentButtonText: string) => void;
  /** analytics: информация для аналитики */
  analytics?: {
    positionInfo: string;
  };
  onAfterClick?: () => void;
};

export const ButtonStartSubscription = ({
  buttonText = "Начать бесплатно",
  analytics,
  variant,
  className,
  onSendAnalytics,
  onAfterClick,
}: ButtonStartSubscriptionProps) => {
  const handlers = useEvent({ paymentModalOpened, startSubscriptionClicked });
  const hasSubscription = useStore(userPurchasesModel.$hasUserMuseSubscription);
  const userSubscription = useStore(userPurchasesModel.$userSubscription);

  const previousSubscription = useStore(userPurchasesModel.$hasPreviousSubscriptions);
  const isTrialActive = useStore(userPurchasesModel.$hasTrialSubscriptionActive);

  const push = useEvent(historyPush);

  const trialState = getTrialState({
    userSubscription,
    isTrialActive,
    hasPreviousTrial: previousSubscription,
  });

  const buttonStates = {
    [TrialState.TRIAL_ACTIVE]: "Начать учиться",
    [TrialState.TRIAL_NOT_USED]: buttonText,
    [TrialState.TRIAL_WAS_USED]: "Оформить подписку",
    [TrialState.TRIAL_INACTIVE_BUT_HAS_SUBSCRIPTION]: "Начать учиться",
  };

  const getButtonText = () => buttonStates[trialState] || buttonText;

  const handleStartClick = () => {
    onSendAnalytics?.(getButtonText());

    if (analytics) {
      handlers.startSubscriptionClicked({
        positionInfo: analytics.positionInfo,
        text: getButtonText(),
      });
    }

    if (hasSubscription) push(paths.studentCourses());
    else handlers.paymentModalOpened(PaymentModalType.Subscription);
    onAfterClick?.();
  };

  return (
    <ButtonRounded
      variant={variant}
      onClick={handleStartClick}
      className={clsx("text-m-xs xl:text-m-xs 2xl:text-m-xs", className)}
    >
      {getButtonText()}
    </ButtonRounded>
  );
};
