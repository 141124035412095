import { apiInstance } from "../../bff";
import { createGqlEffect } from "../../client";

export const getCourseBySpeakersIds = createGqlEffect(apiInstance.course.getCourseBySpeakersIds);

export const getCoursesByIds = createGqlEffect(apiInstance.course.getCoursesByIds);
export const getCourseByIdFx = createGqlEffect(apiInstance.course.getCourseById);

export const getAllCourses = createGqlEffect(apiInstance.course.getAllCourses);

export const getPopularCourses = createGqlEffect(apiInstance.course.getPopularCourses);

export const getCoursesByTopicFx = createGqlEffect(apiInstance.course.getCoursesByTopic);

export const getProgressCoursesFx = createGqlEffect(apiInstance.course.getProgressCourses);

export const getCompletedCoursesFx = createGqlEffect(apiInstance.course.getCompletedCourses);

export const getFavoriteCoursesFx = createGqlEffect(apiInstance.course.getFavoriteCourses);

export const getFavoriteCoursesIds = createGqlEffect(apiInstance.course.getFavoriteCoursesIds);

export const markCourseAsFavorite = createGqlEffect(apiInstance.course.markCourseAsFavorite);

export const unmarkCourseAsFavorite = createGqlEffect(apiInstance.course.unmarkCourseAsFavorite);

export const markCourseAsCompleted = createGqlEffect(apiInstance.course.markCourseAsCompleted);

export const verifyUserCourseCertification = createGqlEffect(
  apiInstance.course.verifyUserCourseCertification,
);

export const createCertificate = createGqlEffect(apiInstance.course.createCertificate);

export const sendCourseStartEvent = createGqlEffect(apiInstance.course.sendCourseStartEvent);

export const sendCourseEndEvent = createGqlEffect(apiInstance.course.sendCourseEndEvent);
