import clsx from "clsx";
import { useGate, useStore } from "effector-react/scope";
import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";

import { CourseFeedbacks } from "@/pages/course-view/modules/course-feedbacks/ui/course-feedbacks";
import { DOMAIN_SITE, paths } from "@/pages/paths";

import { CourseHeaderBlock } from "@/widgets/course-header-block";
import { Footer } from "@/widgets/footer";
import { HeaderDesktop, HeaderMobile } from "@/widgets/header/ui/header";
import { BuyCourseButton } from "@/widgets/payment/ui/buy-course-button";
import { PopularCourse } from "@/widgets/popular-course";
import { SectionToCourses } from "@/widgets/section-to-courses";
import { SectionTogether } from "@/widgets/section-together";

import { CurrentCourseId } from "@/entities/course/types";
import { SpeakerCard, SpeakerCardSkeleton } from "@/entities/speaker";

import { contentStatusBuilder } from "@/shared/api";
import { useDevices } from "@/shared/lib/responsive";
import { scrollToTop } from "@/shared/lib/scroll/scroll-to-top";
import { Section, SEO } from "@/shared/ui";

import {
  $speaker,
  tabCourse,
  $courseLessonsCount,
  $isCourseFetchPending,
  getCourseSpeakerFx,
  $currentCourse,
  CourseViewGate,
} from "./model";
import { SCROLL_TO_LESSON_TABS } from "./modules/constants";
import { CourseAnnounce } from "./modules/course-announce";
import {
  CourseContent,
  MaterialsRightBlock,
  CertificatePromoLayout,
} from "./modules/course-content";
import { CourseContentTabs } from "./modules/course-content-tabs";
import { CourseVideoPlayerSkeleton, CourseVideoPlayerWidget } from "./modules/course-player";

const MetaTags = () => {
  const currentCourse = useStore($currentCourse);

  return (
    <SEO
      title={currentCourse?.title ?? ""}
      description={currentCourse?.about ?? ""}
      image={currentCourse?.image}
      canonical={`${DOMAIN_SITE}${paths.courseView(currentCourse?.id)}`}
    >
      {contentStatusBuilder()
        .outOfSale()
        .has(currentCourse?.status ?? 0) && <meta name="robots" content="noindex, nofollow" />}
    </SEO>
  );
};

const SpeakerLayout = memo(() => {
  const speaker = useStore($speaker);
  const currentCourse = useStore($currentCourse);
  const lessonsCount = useStore($courseLessonsCount);
  const isSpeakerLoading = useStore(getCourseSpeakerFx.pending);

  return (
    <div className="flex flex-col-reverse	xl:flex-col xl:items-start text-center">
      {lessonsCount && currentCourse && (
        <BuyCourseButton course={currentCourse} className="mt-mv-5 xl:-mt-0 xl:mb-mv-6 self-center" />
      )}

      {isSpeakerLoading ? (
        <SpeakerCardSkeleton />
      ) : (
        speaker && (
          <SpeakerCard
            name={speaker.name}
            description={speaker.description}
            surname={speaker.surname}
            image={speaker.imageUrl}
          />
        )
      )}
    </div>
  );
});

const renderRightAsideBlock = {
  about: <SpeakerLayout />,
  materials: <MaterialsRightBlock />,
  tasks: <CertificatePromoLayout />,
};

const CoursePageHeader = () => {
  const lessonsCount = useStore($courseLessonsCount);
  const currentCourse = useStore($currentCourse);
  const speaker = useStore($speaker);
  const { courseId }: CurrentCourseId = useParams();

  return lessonsCount ? (
    <>
      <CourseHeaderBlock courseId={courseId} course={currentCourse} speaker={speaker} />
      <CourseVideoPlayerWidget />
    </>
  ) : (
    <CourseAnnounce />
  );
};

export function CourseViewPage() {
  const currentTab = useStore(tabCourse.$currentTab);
  const courseFetchPending = useStore($isCourseFetchPending);
  const { courseId }: CurrentCourseId = useParams();
  const { isDesktop } = useDevices();
  useGate(CourseViewGate, { courseId });

  useEffect(() => {
    scrollToTop({ behavior: undefined });
  }, []);

  return (
    <>
      <MetaTags />
      <HeaderDesktop />
      <HeaderMobile />
      {courseFetchPending ? <CourseVideoPlayerSkeleton /> : <CoursePageHeader />}
      <section
        id={SCROLL_TO_LESSON_TABS}
        className="relative bg-white 2xl:min-h-[692px] sm:min-h-[509px] flex sm:pt-mv-6 py-mv-6 xl:py-mv-8 3xl:py-mv-10"
      >
        <Section.Content>
          <div className="flex flex-nowrap flex-1 space-x-128 flex-col xl:flex-row">
            <div className="flex flex-col w-full text-m-dark">
              <CourseContentTabs />
              <CourseContent />
            </div>
            {isDesktop && (
              <aside className="xl:flex flex-col mx-auto w-full mt-0 xl:w-[248px] 3xl:w-[452px] shrink-0 space-y-[80px] xl:ml-[128px] 3xl:ml-[150px]">
                {renderRightAsideBlock[currentTab]}
              </aside>
            )}
          </div>
        </Section.Content>
      </section>
      {!isDesktop && (
        <aside
          className={clsx(
            "xl:hidden flex flex-col mt-0 w-full shrink-0 space-y-[80px] pt-[40px] pb-[52px]",
            { "bg-m-grey": currentTab === "about" },
          )}
        >
          <div className="w-full max-w-[365px] lg:!w-grid-lg lg:mx-auto mx-auto">
            {renderRightAsideBlock[currentTab]}
          </div>
        </aside>
      )}
      <div className="bg-m-semi-dark">
        <CourseFeedbacks />
      </div>
      <div className="bg-m-dark-blue">
        <PopularCourse
          className="pt-[30px] md:pt-[38px]"
          containerMode="fixed"
          options={{ centeredSlides: undefined, loop: true }}
          cardClassName="!w-[49.5%] xl:!w-[32.7%]"
        />
        <SectionToCourses />
      </div>
      <SectionTogether />
      <Footer />
    </>
  );
}
