import React, { forwardRef } from "react";

import { FeedbackStats } from "@app/api";

import { StarRating } from "@/entities/feedback/ui/star-rating";

import { getNumberEndingsWords } from "@/shared/lib/get-number-endings-word";
import { Typography } from "@/shared/ui";

export const ChartBlock = forwardRef<HTMLDivElement, FeedbackStats>((props, ref) => {
  const meanRate = (
    props.numberByRating.reduce((acc, item, index) => acc + item * index) / (props.totalNumber || 1)
  ).toFixed(1);
  const percents = [
    [5, props.percentageByRating[5]],
    [4, props.percentageByRating[4]],
    [3, props.percentageByRating[3]],
    [2, props.percentageByRating[2]],
    [1, props.percentageByRating[1]],
  ] as const;
  const ratesCount = props.totalNumber;
  const feedbacksCount = props.totalNumber;

  return (
    <div className="flex flex-col gap-[17px]" ref={ref}>
      <Typography tag="h3" size="m-h3" className="text-m-white font-m-sb-display font-semibold">
        {meanRate.toString().replace(".", ",")}
      </Typography>
      {percents.map(([stars, percent]) => (
        <div key={stars} className="flex gap-[16px] items-center">
          <StarRating value={stars} className="gap-[6px]" starClassName="w-[18px] h-[18px]" />
          <div
            className="w-[257px] h-[5px] rounded-[5px]"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
          >
            <div className="h-[5px] rounded-[5px] bg-m-orange" style={{ width: `${percent}%` }} />
          </div>
          <Typography tag="span" size="m-label" className="font-m-sb-display text-m-orange">
            {percent}%
          </Typography>
        </div>
      ))}
      <div className="flex gap-[10px] m-typography-caption text-m-white">
        <div>
          {ratesCount} {getNumberEndingsWords(ratesCount, ["оценка", "оценки", "оценок"])}
        </div>
        <div>
          {feedbacksCount} {getNumberEndingsWords(ratesCount, ["отзыв", "отзыва", "отзывов"])}
        </div>
      </div>
    </div>
  );
});
