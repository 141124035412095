import { useEvent } from "effector-react/scope";

import { ButtonRounded } from "@/shared/ui";
import { ReactComponent as SberIdIcon } from "@/shared/ui/assets/images/icons/sberid.svg";

import { sberIdAuthClicked } from "../model/sberid-auth";

export const SberIdAuthButton = () => {
  const buttonClicked = useEvent(sberIdAuthClicked);

  return (
    <ButtonRounded className="text-m-xs" onClick={buttonClicked} variant="quaternary">
      <div className="flex items-center text-m-dark-blue">
        <SberIdIcon className="mr-2" />
        Войти по Сбер ID
      </div>
    </ButtonRounded>
  );
};
