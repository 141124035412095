import { useStore } from "effector-react/scope";

import { $paymentChooseModal, PaymentModalType } from "../model/payment-management";
import { BuyCourse } from "./buy-course";
import { Payment } from "./payment";
import { SubscriptionsModal } from "./subscriptions-modal";

const modalContents = {
  [PaymentModalType.Buy]: <BuyCourse />,
  [PaymentModalType.Pay]: <Payment />,
  [PaymentModalType.Subscription]: <SubscriptionsModal />,
  [PaymentModalType.Close]: null,
};

export const PaymentModalRenderer = () => {
  const paymentChooseModal = useStore($paymentChooseModal);

  return modalContents[paymentChooseModal];
};
