import {
  CancellationReason,
  TransactionPaymentEvent,
  TransactionStatus,
  paymentErrors,
  defaultErrorPayment,
} from "@/entities/payment";

import { ModalNotificationBody } from "@/shared/ui";

type CancelPaymentProps = {
  cancellationReason: CancellationReason;
};

type StatusLayoutProps = {
  status: "success" | "error" | "warning";
  title: string;
  description?: string;
};

export const StatusPaymentLayout = ({ status, description, title }: StatusLayoutProps) => {
  return (
    <ModalNotificationBody
      bodyClassName="w-full m-absolute-center"
      type={status}
      message={description}
      title={title}
    />
  );
};

const CanceledPayment = ({ cancellationReason }: CancelPaymentProps) => {
  const cancelScreens = {
    [CancellationReason.ALREADY_PAID]: (
      <StatusPaymentLayout status="warning" {...paymentErrors[CancellationReason.ALREADY_PAID]} />
    ),
    [CancellationReason.OTHER]: (
      <StatusPaymentLayout status="error" {...paymentErrors[CancellationReason.OTHER]} />
    ),
    [CancellationReason.INSUFFICIENT_FUNDS]: (
      <StatusPaymentLayout
        status="error"
        {...paymentErrors[CancellationReason.INSUFFICIENT_FUNDS]}
      />
    ),
    [CancellationReason.PRODUCT_EXPIRED]: (
      <StatusPaymentLayout status="error" {...paymentErrors[CancellationReason.PRODUCT_EXPIRED]} />
    ),
    [CancellationReason.EXPIRED_ON_CONFIRMATION]: (
      <StatusPaymentLayout
        status="error"
        {...paymentErrors[CancellationReason.EXPIRED_ON_CONFIRMATION]}
      />
    ),
  };

  return (
    cancelScreens[cancellationReason] || (
      <StatusPaymentLayout status="error" {...defaultErrorPayment} />
    )
  );
};

export const PaymentStatus = ({ status, cancellationReason }: TransactionPaymentEvent) => {
  const statusScreens = {
    [TransactionStatus.SUCCESSFUL]: (
      <StatusPaymentLayout status="success" title="Операция прошла успешно" />
    ),
    [TransactionStatus.CANCELED]: cancellationReason ? (
      <CanceledPayment cancellationReason={cancellationReason} />
    ) : (
      <StatusPaymentLayout
        status="error"
        title="Заказ не был оплачен"
        description="Попробуйте еще раз"
      />
    ),
  };

  return statusScreens[status] || <StatusPaymentLayout status="error" {...defaultErrorPayment} />;
};
