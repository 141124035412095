// noinspection CheckTagEmptyBody
import clsx from "clsx";

import { Difficulty } from "@/shared/lib/form/lib";
import { useDevices } from "@/shared/lib/responsive";

import style from "./difficulty-check.module.scss";

interface DifficultyCheckProps {
  description?: string;
  difficulty?: Difficulty;
  difficultyCaptions?: [string, string, string, string];
}

const DifficultyCheckBase = ({
  description,
  difficulty = Difficulty.DEFAULT,
  difficultyCaptions,
}: DifficultyCheckProps) => {
  const { isMobile } = useDevices();
  return (
    <div className={style.DifficultyCheck}>
      <div
        className={clsx([
          style.Indicator,
          "m-typography-modal-caption",
          style[`difficulty-${difficulty}`],
          isMobile && style.isMobileOnly,
        ])}
      >
        {difficultyCaptions && description ? (
          <span>
            {description}
            {difficultyCaptions && difficultyCaptions[difficulty]}
          </span>
        ) : null}
        <div className={style.items}>
          <i />
          <i />
          <i />
        </div>
      </div>
    </div>
  );
};

export const DifficultyCheck = DifficultyCheckBase as typeof DifficultyCheckBase;
