import clsx from "clsx";

import { SkeletonLoader } from "../skeleton-loader/skeleton-loader";
import styles from "./card.module.scss";

type CardSkeletonProps = {
  className?: string;
};

export const CardSkeleton = ({ className }: CardSkeletonProps) => (
  <div className={clsx(styles.CardSkeletonContainer, className)}>
    <div className="absolute bottom-[82px] left-[26px]">
      <SkeletonLoader count={1} height={16} width={88} variant="blue" duration={0.9} />
    </div>

    <div className="absolute bottom-[49px] left-[26px]">
      <SkeletonLoader count={1} height={16} width={186} variant="blue" duration={0.9} />
    </div>

    <div className="absolute bottom-[24px] left-[26px]">
      <SkeletonLoader count={1} height={16} width={186} variant="blue" duration={0.9} />
    </div>
  </div>
);
