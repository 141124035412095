import { useStore } from "effector-react/scope";
import { renderRoutes } from "react-router-config";

import { $isManuallySetNotFound } from "@/entities/navigation";

import { Error404Page } from "./error404";
import { routes } from "./routes";

export function Pages() {
  const isSetNotFound = useStore($isManuallySetNotFound);

  if (isSetNotFound) return <Error404Page />;

  return renderRoutes(routes);
}
