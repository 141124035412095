export const sanitizeWhitespaces = (str: string) => str.trim().replace(/\s(\s+)/g, " ");

export const capitalize = (str: string) =>
  str
    .split(" ")
    .map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1))
    .join(" ");

export const toSnakeCase = (str: string) =>
  sanitizeWhitespaces(str).toLowerCase().replace(/\s/g, "_");

export const trimSpaceAndDash = (value: string) => {
  const SpaceAndDash = " -";
  let [begin, end] = [0, value.length - 1];
  for (let i = 0; i < value.length; i += 1) {
    if (SpaceAndDash.includes(value[i])) {
      begin += 1;
    } else {
      break;
    }
  }

  if (begin === end + 1) return "";

  for (let i = value.length - 1; i >= 0; i -= 1) {
    if (SpaceAndDash.includes(value[i])) {
      end -= 1;
    } else {
      break;
    }
  }

  return value.slice(begin, end + 1);
};

export const getFileExtension = (path: string) => {
  const splitPathRe = /^(\/?)([\s\S]*?)((?:\.{1,2}|[^/]+?)(\.([^./?]+)))(?:[/]*|[?].*)$/;
  const pathParts = splitPathRe.exec(path);

  if (pathParts) {
    const extension = pathParts.pop();
    return extension ? extension.toLowerCase() : "";
  }

  return "";
};
