import { createEffect, createStore, restore, sample } from "effector";
import { status } from "patronum/status";

import { UserCardsInfo, UserProduct } from "@app/api";

import { $authenticatedUserId } from "@/entities/user";

import { PaymentApi, UserApi, ProductApi } from "@/shared/api";

import {
  getSubscriptionProductType,
  isEdPlusSubscription,
  isMuseSubscription,
} from "../lib/user-subscription";

export const getUserProductsFx = createEffect(async (userId: string) => {
  const [boughtCourses, boughtSubscription] = await Promise.all([
    ProductApi.getBoughtCourses(userId),
    ProductApi.getBoughtSubscription(userId),
  ]);

  return { boughtCourses, boughtSubscription };
});

export const getUserCardsInfoFx = createEffect(UserApi.getUserCards);
export const getUserPaymentMethodFx = createEffect(UserApi.getUserPaymentMethod);
export const cancelSubscriptionFx = createEffect(ProductApi.cancelSubscription);
export const restoreSubscriptionFx = createEffect(ProductApi.restoreSubscription);
export const checkPreviousSubscriptionsFx = createEffect(PaymentApi.checkPreviousSubscriptions);
export const getBoughtEventsFx = createEffect(ProductApi.getBoughtEvents);

export const $statusCheckPreviousSubscriptionRequest = status({
  effect: checkPreviousSubscriptionsFx,
});
export const $hasPreviousSubscriptions = createStore<boolean>(false).on(
  checkPreviousSubscriptionsFx.doneData,
  (_, subscriptions) => subscriptions.filter(isMuseSubscription).length > 0,
);

export const $boughtEvents = restore(getBoughtEventsFx.doneData, []);
export const $userSubscription = createStore<Nullable<UserProduct>>(null).on(
  getUserProductsFx.doneData.map(({ boughtSubscription }) => {
    const { newMuseSubscription, edPlusSubscription, oldMuseSubscription } =
      getSubscriptionProductType(boughtSubscription);

    if (newMuseSubscription) return newMuseSubscription;

    if (oldMuseSubscription) return oldMuseSubscription;

    if (edPlusSubscription) return edPlusSubscription;

    return null;
  }),
  (_, payload) => payload,
);

export const $allUserMuseSubscriptions = createStore<UserProduct[]>([]).on(
  getUserProductsFx.doneData,
  (_, { boughtSubscription }) => boughtSubscription.filter(isMuseSubscription),
);

export const $boughtCourses = createStore<UserProduct[]>([]).on(
  getUserProductsFx.doneData.map((product) => {
    return product.boughtCourses;
  }),
  (_, payload) => payload,
);

export const $userCardsInfo = createStore<UserCardsInfo>([]).on(
  getUserCardsInfoFx.doneData,
  (_, cards) => cards,
);

export const $subscriptionCard = createStore<Nullable<string>>(null).on(
  getUserPaymentMethodFx.doneData,
  (_, token) => token,
);

export const $hasUserMuseSubscription = $userSubscription.map(isMuseSubscription);
export const $hasUserEdPlusSubscription = $userSubscription.map(isEdPlusSubscription);
export const $hasTrialSubscriptionActive = $userSubscription.map((state) =>
  isMuseSubscription(state) ? state?.trial ?? false : false,
);

sample({
  clock: [cancelSubscriptionFx.done, restoreSubscriptionFx.done],
  source: $authenticatedUserId,
  target: [getUserProductsFx, getUserCardsInfoFx, getUserPaymentMethodFx],
});
