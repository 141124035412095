import { createApi, createEvent, createStore, sample } from "effector";

export enum AUTH_MODAL_TYPE {
  signIn = "signIn",
  signUp = "signUp",
  signUpError = "signUpError",
  signUpConfirmationError = "signUpConfirmationError",
  addEmail = "addEmail",
  addEmailConfirmation = "addEmailConfirmation",
  signUpConfirmCode = "signUpConfirmCode",
  singUpLoading = "signUpLoading",
  confirmedSignUp = "confirmedSignUp",
  forgotPassword = "forgotPassword",
  forgotPasswordError = "forgotPasswordError",
  forgotPasswordConfirmCode = "forgotPasswordConfirmCode",
  resetPasswordConfirmCode = "resetPasswordConfirmCode",
  resetNewPassword = "resetNewPassword",
  loadingChangePassword = "loadingChangePassword",
  changePasswordSuccess = "changePasswordSuccess",
  profilePasswordChange = "profilePasswordChange",
  supportContactsOnPassword = "supportContactsOnPassword",
  supportContactsOnSignUp = "supportContactsOnSignUp",
  supportContactsOnDelete = "supportContactsOnDelete",
  deleteUser = "deleteUser",
  deleteUserConfirmCode = "deleteUserConfirmCode",
  deleteUserConfirmCodeError = "deleteUserConfirmCodeError",
}

const apiAuthModal = Object.values(AUTH_MODAL_TYPE).reduce((acc, type) => {
  acc[type] = () => type;
  return acc;
}, {} as Record<AUTH_MODAL_TYPE, () => void>);

export const authModalCloseClicked = createEvent();

export const openAuthModal = (() => {
  const $ModalType = createStore<null | AUTH_MODAL_TYPE>(null);

  const setIsRedirectToProfile = createEvent<boolean>();
  const $isRedirectToProfile = createStore(true);

  const redirectToProfile = createEvent();

  // noinspection JSVoidFunctionReturnValueUsed
  const resetRedirectOnModalOpen = sample({
    source: $ModalType.updates,
    filter: (event) => event === null,
  });

  $isRedirectToProfile
    .on(setIsRedirectToProfile, (_, shouldRedirect) => shouldRedirect)
    .reset(resetRedirectOnModalOpen);

  return {
    $ModalType,
    redirectToProfile,
    $isRedirectToProfile,
    setIsRedirectToProfile,
    ...createApi($ModalType, {
      ...apiAuthModal,
      close: () => null,
    }),
  };
})();

sample({
  clock: openAuthModal.redirectToProfile,
  target: openAuthModal.close,
});

sample({
  clock: authModalCloseClicked,
  target: openAuthModal.close,
});
