import { createEffect, createEvent, createStore, sample } from "effector";

import { $authenticatedUserId } from "@/entities/user";

import { UserApi } from "@/shared/api";

export const certificateLoaded = createEvent();
export const getCertificatesFx = createEffect(UserApi.getUserCertificates);

export const $userCertificates = createStore<UserApi.UserCertificate[]>([]);

sample({
  clock: [$authenticatedUserId, certificateLoaded],
  target: getCertificatesFx,
});

sample({
  source: getCertificatesFx.doneData,
  target: $userCertificates,
});
