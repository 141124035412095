import { createApi, createStore } from "effector";

type CreateModalState = {
  defaultState?: boolean;
};

export const createModalState = ({ defaultState = false }: CreateModalState) => {
  const $isOpen = createStore(defaultState);

  const events = createApi($isOpen, {
    open: () => true,
    close: () => false,
  });

  return { $isOpen, ...events };
};
