import { attach, createEvent, createStore, sample } from "effector";

import { AuthApi } from "@/shared/api";

import { openAuthModal } from "@/app/authorization-modals/model";
import { createConfirmCodeModel } from "@/feature/confirm-code-modal";
import { createCountdownTimer } from "@/feature/countdown-button";

import { $forgotPasswordUserToken } from "../model/password-forgot";

const enum PasswordChangeErrorTypes {
  TOKEN_NOT_FOUND = "not_found_token",
  TOKEN_FAILED = "token_failed",
  ACTION_NOT_CREATED_TIMEOUT = "action_not_created_timeout",
  ACTION_NOT_CREATED = "action_not_created",
  ACTION_SEND_FAILED = "action_send_failed",
}

const enum PasswordChangeErrorMessages {
  ACTION_ERROR_MESSAGE = "Ошибка доступа. Повторите попытку",
  TOKEN_ERROR_MESSAGE = "Ошибка доступа. Повторите вход",
  WRONG_PASSWORD_ERROR_MESSAGE = "Неверный пароль",
  WRONG_CODE_ERROR_MESSAGE = "Неверный код",
}

const passwordChangeErrorToMessage: Record<PasswordChangeErrorTypes, PasswordChangeErrorMessages> =
  {
    [PasswordChangeErrorTypes.TOKEN_NOT_FOUND]: PasswordChangeErrorMessages.TOKEN_ERROR_MESSAGE,
    [PasswordChangeErrorTypes.TOKEN_FAILED]: PasswordChangeErrorMessages.TOKEN_ERROR_MESSAGE,
    [PasswordChangeErrorTypes.ACTION_NOT_CREATED_TIMEOUT]:
      PasswordChangeErrorMessages.ACTION_ERROR_MESSAGE,
    [PasswordChangeErrorTypes.ACTION_NOT_CREATED]: PasswordChangeErrorMessages.ACTION_ERROR_MESSAGE,
    [PasswordChangeErrorTypes.ACTION_SEND_FAILED]: PasswordChangeErrorMessages.ACTION_ERROR_MESSAGE,
  };

export const $profilePasswordValue = createStore("");
export const $passwordFormErrors = createStore("");
export const $passwordChangeErrors = createStore("");

export const profilePasswordChanged = createEvent<string>();
export const profilePasswordConfirmClicked = createEvent();

export const passwordChangeFx = attach({ effect: AuthApi.changePasswordConfirm });
const passwordChangeConfirmCodeFx = attach({ effect: AuthApi.changePassword2Phase });
const passwordNewFx = attach({ effect: AuthApi.changePassword });

$profilePasswordValue.on(profilePasswordChanged, (_, value) => value).reset(openAuthModal.close);

$passwordFormErrors.reset(passwordChangeFx.done, openAuthModal.close);

export const $resultPasswordChangeConfirm = createStore<Nullable<AuthApi.RequestConfirmCodeData>>(
  null,
).on(
  passwordChangeFx.doneData.map((payload) => ({
    timeout: payload.timeout,
    token: payload.token,
  })),
  (_, payload) => payload,
);

sample({
  source: passwordChangeFx.failData,
  fn: ({ type }) =>
    passwordChangeErrorToMessage[type] || PasswordChangeErrorMessages.WRONG_PASSWORD_ERROR_MESSAGE,
  target: $passwordFormErrors,
});

sample({
  source: passwordChangeConfirmCodeFx.failData,
  fn: ({ type }) =>
    passwordChangeErrorToMessage[type] || PasswordChangeErrorMessages.WRONG_CODE_ERROR_MESSAGE,
  target: $passwordFormErrors,
});

sample({
  source: passwordNewFx.failData,
  fn: ({ type }) => passwordChangeErrorToMessage[type],
  target: $passwordChangeErrors,
});

export const confirmCodeChangePassword = createConfirmCodeModel({
  source: $resultPasswordChangeConfirm,
  sendFx: AuthApi.changePassword2Phase,
});

export const countdownTimerChangePassword = createCountdownTimer({});

sample({
  clock: [profilePasswordConfirmClicked, confirmCodeChangePassword.sendAgain],
  source: $profilePasswordValue,
  fn: (password) => ({ password }),
  target: passwordChangeFx,
});

sample({
  clock: passwordChangeFx.doneData,
  target: [openAuthModal.resetPasswordConfirmCode],
});

sample({
  clock: passwordChangeFx.doneData,
  fn: ({ timeout }) => timeout,
  target: countdownTimerChangePassword.startCountdown,
});

sample({
  clock: confirmCodeChangePassword.requestFx.failData,
  fn: ({ type }) => passwordChangeErrorToMessage[type],
  target: $passwordChangeErrors,
});

sample({
  clock: confirmCodeChangePassword.requestFx.doneData,
  fn: ({ token }) => token,
  target: [openAuthModal.resetNewPassword, $forgotPasswordUserToken],
});
