import { memo } from "react";

import { SkeletonLoader } from "@/shared/ui";

type SpeakerCardProps = {
  image: string;
  name: string;
  surname: string;
  description: string;
};

export const SpeakerCardSkeleton = () => (
  <div className="flex justify-center flex-col xl:flex-row xl:block w-full">
    <SkeletonLoader
      className="block w-full xl:w-auto h-[144px] xl:h-[300px] self-center 3xl:mx-auto"
      variant="light"
    />
    <div className="3xl:text-m-xl xl:text-m-base text-m-sm text-m-dark font-m-sb-display font-semibold mt-mv-4 xl:mt-mv-5 xl:ml-0">
      <SkeletonLoader count={5} height="1em" variant="light" />
    </div>
  </div>
);

export const SpeakerCard = memo(({ image, surname, name, description }: SpeakerCardProps) => {
  return (
    <div className="flex justify-center flex-col xl:flex-row xl:block">
      {image && (
        <img
          src={image}
          className="object-cover w-[147px] xl:w-auto h-[144px] xl:h-[300px] self-center 3xl:mx-auto"
          alt={`${name} ${surname}`}
        />
      )}
      <div className="3xl:text-m-xl xl:text-m-base text-m-sm text-m-dark font-m-sb-display font-semibold mt-mv-4 xl:mt-mv-5 xl:ml-0">
        {description}
      </div>
    </div>
  );
});
