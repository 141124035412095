import { ReactNode, useEffect } from "react";

import { scrollToTop } from "@/shared/lib/scroll";

type ScrollToTopProps = {
  children: ReactNode;
  options?: ScrollIntoViewOptions;
};
export const ScrollToTop = ({ children, options }: ScrollToTopProps) => {
  useEffect(() => {
    scrollToTop(options);
  }, [options]);

  return <div>{children}</div>;
};
