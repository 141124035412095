import { createStore, sample } from "effector";

import { courseModel } from "@/entities/course";

import { CourseNames } from "../lib/content";

export const $courseIds = createStore<Record<string, string> | null>(null);

sample({
  source: courseModel.$courses,
  fn: (source) =>
    source
      .filter((course) => Object.values(CourseNames).includes(course.title))
      .reduce((courses, course) => ({ ...courses, [course.title]: course.id }), {}),
  target: $courseIds,
});
