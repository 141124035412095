import { parseISO, isAfter, isSameDay, parse, isValid, differenceInSeconds } from "date-fns";
import format from "date-fns/format";
import ru from "date-fns/locale/ru";

const parseDateToISOString = (startDate: string, endDate: string) => {
  if (startDate === "" || endDate === "") return null;
  const startDateISO = parseISO(startDate);
  const endDateISO = parseISO(endDate);

  const formattedEndDate = format(endDateISO, "dd.MM.yyyy", { locale: ru });
  const formattedStartDate = format(startDateISO, "dd.MM.yyyy", { locale: ru });

  return {
    formattedStartDate,
    formattedEndDate,
  };
};

const isSameOrAfter = (start: Date, end: Date) => isSameDay(start, end) || isAfter(start, end);
const isDateSameDay = (start: Date, end: Date) => isSameDay(start, end);

export const DateUtils = {
  parseDateToISOString,
  isSameOrAfter,
  isSameDay,
  isDateSameDay,
  parse,
  isValid,
  differenceInSeconds,
};
