import { ReactNode } from "react";

import { Program } from "@app/api";

import { DateUtils } from "@/shared/lib/date";
import { getNumberEndingsWords } from "@/shared/lib/get-number-endings-word";
import { fromCDN } from "@/shared/lib/image/image";
import { Card } from "@/shared/ui";

export type CardProps = {
  program: Program;
  renderNode?: ReactNode;
  hoveredNode?: ReactNode;
};

export const ProgramCard = (props: CardProps) => {
  const { program, renderNode = null, hoveredNode = null } = props;

  const img = fromCDN(program.image);
  const programTopic = program?.courseTopics?.[0]?.topic.name ?? "";

  const duration = `${program.intensity} ${getNumberEndingsWords(Number(program.intensity), [
    "месяц",
    "месяца",
    "месяцев",
  ])}`;

  const date = DateUtils.parseDateToISOString(program.startDate, program.endDate);

  const dateRender = date ? `${date.formattedStartDate} — ${date.formattedEndDate}` : "";

  return (
    <Card
      showNodeOnHover={hoveredNode}
      labels={[programTopic, program.target?.title ?? ""]}
      chips={[dateRender, duration]}
      img={img}
      title={program.title}
      leftTopNode={renderNode}
    />
  );
};
