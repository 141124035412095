import { useInView } from "react-intersection-observer";

type InViewParams = {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
  triggerOnce?: boolean;
};

type InViewResponse = {
  ref: (node?: Element | null) => void;
  inView: boolean;
  entry?: IntersectionObserverEntry;
};

type UseIntersectionObserver = (params?: InViewParams) => InViewResponse;

export const useIntersectionObserver: UseIntersectionObserver = (params) => {
  const { ref, inView, entry } = useInView(params || undefined);

  return { ref, inView, entry };
};
