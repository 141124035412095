import { createEvent, createStore, sample } from "effector";

type CalculateVideoProgress = {
  progressSteps?: number[];
};

const DEFAULT_PROGRESS_STEPS = [1, 25, 50, 75, 99];

export const createVideoWatchedPercentage = ({
  progressSteps = DEFAULT_PROGRESS_STEPS,
}: CalculateVideoProgress) => {
  const durationSet = createEvent<number>();
  const progressChanged = createEvent<number>();
  const stepChanged = createEvent<number>();

  const $videoDuration = createStore(0).on(durationSet, (_, duration) => duration);

  const calculateProgressPercentage = (duration: number, progress: number) =>
    Math.round((progress / duration) * 100);

  const $stepPercentage = createStore(0).on(stepChanged, (_, step) => step);

  sample({
    clock: progressChanged,
    source: { duration: $videoDuration, currentStep: $stepPercentage },
    filter: ({ duration, currentStep }, progress) =>
      duration > 0 && calculateProgressPercentage(duration, progress) > currentStep,
    fn: ({ duration }, progress) => {
      const percentage = calculateProgressPercentage(duration, progress);
      const newSteps = progressSteps.filter((step) => percentage >= step);
      return newSteps[newSteps.length - 1];
    },
    target: stepChanged,
  });

  return { durationSet, progressChanged, $stepPercentage };
};
