import clsx from "clsx";
import { forwardRef } from "react";

const Star = ({ className }: { className: string }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1026 1.13789L18.6555 8.66661C19.0695 9.54565 19.8839 10.1649 20.8358 10.3103L28.7812 11.517C29.0377 11.5561 29.1645 11.9645 28.9401 12.1931L23.1925 18.0534C22.5242 18.7346 22.2228 19.7041 22.3784 20.6522L23.7351 28.9269C23.7895 29.2577 23.515 29.4662 23.3082 29.3525L16.2033 25.4453C15.3473 24.9759 14.3209 24.9759 13.4662 25.4446L6.35988 29.3526C6.15276 29.4664 5.87867 29.258 5.933 28.9273L7.28981 20.6519C7.44542 19.7041 7.14404 18.7346 6.47553 18.0532L0.728329 12.1934C0.503997 11.9646 0.630833 11.5562 0.888502 11.5169L8.83057 10.3104C9.78331 10.1652 10.5975 9.54673 11.0132 8.66743L14.5657 1.1376C14.6874 0.879867 14.9808 0.879867 15.1026 1.13789Z"
      fill="current"
    />
  </svg>
);

export type StarRatingProps = {
  value: 0 | 1 | 2 | 3 | 4 | 5;
  starClassName?: string;
  className?: string;
  onStarClick?: (value: number) => void;
};
export const StarRating = forwardRef<HTMLDivElement, StarRatingProps>(
  ({ value, starClassName, className, onStarClick }, ref) => {
    return (
      <div className={clsx("flex gap-[10px]", className)} ref={ref}>
        {Array(5)
          .fill(0)
          .map((_, index) =>
            onStarClick ? (
              <button key={index} onClick={onStarClick ? () => onStarClick(index + 1) : undefined}>
                <Star
                  className={clsx(
                    value <= index ? "fill-m-dark-grey" : "fill-m-orange",
                    starClassName,
                  )}
                />
              </button>
            ) : (
              <Star
                key={index}
                className={clsx(
                  value <= index ? "fill-m-dark-grey" : "fill-m-orange",
                  starClassName,
                )}
              />
            ),
          )}
      </div>
    );
  },
);
