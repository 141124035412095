export const secondsToHms = (time: number) => {
  const seconds = Number(time / 60);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hDisplay = h > 0 ? `${h}:` : "";
  const mDisplay = m > 0 ? `${m < 10 && hDisplay ? "0" : ""}${m}:` : "00:";
  const sDisplay = s > 0 ? `${s < 10 ? "0" : ""}${s}` : "00";
  return hDisplay + mDisplay + sDisplay;
};
