import { useStoreMap, useEvent } from "effector-react/scope";

import { userPurchasesModel } from "@/entities/user-purchases";

import { analyticsEvents } from "@/shared/analytics";
import { useCallbackOnEnter } from "@/shared/lib/keyboard";
import { Modal, ModalNotification } from "@/shared/ui";

import { readyToRemoveUserPaymentCard } from "../model/delete-saved-payment-card";

type Props = {
  paymentMethodId: string;
  isOpen: boolean;
  close: () => void;
  onManageSubscriptionClick: () => void;
};

const Confirm = ({ close, onConfirm }: { onConfirm: () => void; close: Props["close"] }) => {
  const handlers = useEvent({
    clickThinkAboutDeletePaymentCard: analyticsEvents.clickThinkAboutDeletePaymentCard,
    clickYesCancelPaymentCard: analyticsEvents.clickYesCancelPaymentCard,
  });

  const onClose = () => {
    handlers.clickThinkAboutDeletePaymentCard();
    close();
  };

  const confirm = () => {
    handlers.clickYesCancelPaymentCard();
    onConfirm();
  };

  useCallbackOnEnter(onClose);

  return (
    <ModalNotification
      onClose={onClose}
      type="warning"
      title="Вы точно хотите удалить карту?"
      renderButtons={[
        {
          onClick: onClose,
          title: "ещё подумаю",
          variant: "primary",
        },
        {
          onClick: confirm,
          title: "Да, удалить",
        },
      ]}
    />
  );
};

export const RemoveCardModal = ({
  isOpen,
  close,
  paymentMethodId,
  onManageSubscriptionClick: onManageSubscription,
}: Props) => {
  const userHasSubscription = useStoreMap(
    userPurchasesModel.$userCardsInfo,
    (cards) =>
      cards.find((card) => card.paymentMethodId === paymentMethodId)?.isUsedForSubscription ??
      false,
  );
  const confirm = useEvent(readyToRemoveUserPaymentCard);

  const onConfirm = () => {
    confirm(paymentMethodId);
    close();
  };

  const handleManageSubscription = () => {
    onManageSubscription();
    close();
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      render={() => {
        if (userHasSubscription) {
          return (
            <ModalNotification
              type="warning"
              onClose={close}
              message={
                <>
                  С&nbsp;этой карты оплачивается подписка. <br />
                  Чтобы удалить карту, сначала отмените подписку
                </>
              }
              renderButtons={[
                {
                  onClick: handleManageSubscription,
                  title: "управлять подпиской",
                },
              ]}
            />
          );
        }

        return <Confirm onConfirm={onConfirm} close={close} />;
      }}
    />
  );
};
