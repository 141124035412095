import { createEvent, sample } from "effector";

import { analyticsEvents } from "@/shared/analytics";

export const feedbackOpened = createEvent();
export const subscriptionBenefitsClicked = createEvent();

sample({
  clock: feedbackOpened,
  target: analyticsEvents.openFeedback,
});

sample({
  clock: subscriptionBenefitsClicked,
  target: analyticsEvents.clickSubscriptionBenefitsMenu,
});
