import { useStore } from "effector-react/scope";
import { FC } from "react";

import { ModalNotification } from "@/shared/ui";

import { $forgotPasswordError } from "../model/password-forgot";

type Props = { onClose: () => void };

export const PasswordForgotError: FC<Props> = ({ onClose }) => {
  const message = useStore($forgotPasswordError);

  return <ModalNotification type="error" message={message} onClose={onClose} />;
};
