import { Typography } from "@/shared/ui";
import beInOpen from "@/shared/ui/assets/images/home/partners/be-in-open.webp";
import brusnikina from "@/shared/ui/assets/images/home/partners/brusnikina.webp";
import bso from "@/shared/ui/assets/images/home/partners/bso.webp";
import butman from "@/shared/ui/assets/images/home/partners/butman.webp";
import kizhi from "@/shared/ui/assets/images/home/partners/kizhi.webp";
import kruzhok from "@/shared/ui/assets/images/home/partners/kruzhok_workshop.webp";
import nikola from "@/shared/ui/assets/images/home/partners/nikola_lenivec.webp";
import pole from "@/shared/ui/assets/images/home/partners/pole.webp";
import sber from "@/shared/ui/assets/images/home/partners/sber.webp";
import vag from "@/shared/ui/assets/images/home/partners/vag.webp";
import zvuk from "@/shared/ui/assets/images/home/partners/zvuk.webp";

const partners = [
  kruzhok,
  beInOpen,
  brusnikina,
  kizhi,
  nikola,
  pole,
  butman,
  bso,
  zvuk,
  vag,
  beInOpen,
  sber,
  brusnikina,
  beInOpen,
  sber,
];

const PartnersGrid = ({ countPartners = partners.length }: { countPartners?: number }) => {
  return (
    <ul className="flex flex-wrap border-t-[1.5px] border-l-[1.5px] border-m-grey">
      {partners.slice(0, countPartners).map((partner, i) => (
        <li
          key={i}
          className="w-1/3 md:w-1/5 flex items-center justify-center h-[77px] md:h-[152px] 3xl:h-[228px] border-b-[1.5px] border-r-[1.5px] border-m-grey p-[24px]"
        >
          <div className="relative w-full w-[60px] h-[38px] xl:w-[102px] xl:h-[68px] 3xl:w-[154px] 3xl:h-[102px]">
            <div
              className="flex justify-center items-center bg-no-repeat bg-contain absolute inset-0"
              style={{ backgroundImage: `url(${partner})`, backgroundPosition: "50%" }}
            />
          </div>
        </li>
      ))}
    </ul>
  );
};

export const Partners = ({ className }: { className?: string }) => {
  return (
    <section className={className}>
      <Typography
        tag="h3"
        size="m-h3"
        className="uppercase font-bold font-m-sb-display mb-mn-38 md:mb-mn-50 3xl:mb-mn-74 text-m-blue px-mn-20 md:px-mn-26 3xl:px-mn-38"
      >
        спасибо за&nbsp;вдохновение
        <br />
        нашим партнерам
      </Typography>
      <div className="hidden md:block">
        <PartnersGrid countPartners={10} />
      </div>
      <div className="block md:hidden">
        <PartnersGrid countPartners={9} />
      </div>
    </section>
  );
};
