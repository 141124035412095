import { useEvent } from "effector-react/scope";

import { DOMAIN_SITE, paths } from "@/pages/paths";

import { Footer } from "@/widgets/footer";
import { HeaderDesktop, HeaderMobile } from "@/widgets/header";

import { LandingBlock, SEO } from "@/shared/ui";
import { ReactComponent as JazzIcon } from "@/shared/ui/assets/images/icons/logo_100let.svg";
import mainImgLow from "@/shared/ui/assets/images/promo/jazz100/01-low.webp";
import mainImgMobile from "@/shared/ui/assets/images/promo/jazz100/01-mob.webp";
import butmanSlide from "@/shared/ui/assets/images/promo/jazz100/01-slide.webp";
import mainImg from "@/shared/ui/assets/images/promo/jazz100/01.webp";
import lebedevSlide from "@/shared/ui/assets/images/promo/jazz100/02-slide.webp";
import retro from "@/shared/ui/assets/images/promo/jazz100/02.webp";
import orchestraRetroDesktop from "@/shared/ui/assets/images/promo/jazz100/03-mob.webp";
import fantineSlide from "@/shared/ui/assets/images/promo/jazz100/03-slide.webp";
import orchestraRetroMobile from "@/shared/ui/assets/images/promo/jazz100/03.webp";
import butmanDesktopLow from "@/shared/ui/assets/images/promo/jazz100/04-low.webp";
import butmanMobile from "@/shared/ui/assets/images/promo/jazz100/04-mob.webp";
import chekurovSlide from "@/shared/ui/assets/images/promo/jazz100/04-slide.webp";
import butmanDesktop from "@/shared/ui/assets/images/promo/jazz100/04.webp";
import butmanInfoBlock from "@/shared/ui/assets/images/promo/jazz100/05.webp";
import lebedevDesktopLow from "@/shared/ui/assets/images/promo/jazz100/06-low.webp";
import lebedevMobile from "@/shared/ui/assets/images/promo/jazz100/06-mob.webp";
import lebedevDesktop from "@/shared/ui/assets/images/promo/jazz100/06.webp";
import lebedevInfoBlock from "@/shared/ui/assets/images/promo/jazz100/07.webp";
import fantinDesktopLow from "@/shared/ui/assets/images/promo/jazz100/08-low.webp";
import fantinMobile from "@/shared/ui/assets/images/promo/jazz100/08-mob.webp";
import fantinDesktop from "@/shared/ui/assets/images/promo/jazz100/08.webp";
import fantineInfoBlock from "@/shared/ui/assets/images/promo/jazz100/09.webp";
import chekurovDesktopLow from "@/shared/ui/assets/images/promo/jazz100/10-low.webp";
import chekurovDesktop from "@/shared/ui/assets/images/promo/jazz100/10.webp";
import chekurovMobile from "@/shared/ui/assets/images/promo/jazz100/11-mob.webp";
import chekurovInfoBlock from "@/shared/ui/assets/images/promo/jazz100/11.webp";
import generalPartnerOrchestraDesktop from "@/shared/ui/assets/images/promo/jazz100/12-.webp";
import generalPartnerOrchestraMobile from "@/shared/ui/assets/images/promo/jazz100/12-mob.webp";
import { ReactComponent as ButmanSign } from "@/shared/ui/assets/images/promo/jazz100/butman_sign.svg";
import butmanMusicGroup from "@/shared/ui/assets/images/promo/jazz100/logo_IBMG.jpeg";

import {
  courseClickedSendAnalytics,
  arrowClickedSendAnalytics,
  jazz100ClickedSendAnalytics,
  generalPartnerClickedSendAnalytics,
} from "./analytics";

const slides = [
  {
    title: `Джазовый\nстандарт Игоря\nБутмана`,
    subTitle: "Игорь Бутман",
    img: butmanSlide,
    url: "https://muse.edutoria.ru/courses/ebf9a1ac-c2f9-44df-85e9-7dbd53fa8494",
  },
  {
    title: `Игра в бисер:\nкурс-концерт`,
    subTitle: "Евгений лебедев",
    img: lebedevSlide,
    url: "https://muse.edutoria.ru/courses/65a90582-7ef2-4c97-bec9-bc35c53bd782",
  },
  {
    title: `Джазовый вокал`,
    subTitle: "Фантине",
    img: fantineSlide,
    url: "https://muse.edutoria.ru/courses/05fce4c8-bbf0-4566-be16-20c0612aefd6",
  },
  {
    title: `Джазовая\nимпровизация`,
    subTitle: "Антон чекуров",
    img: chekurovSlide,
    url: "https://muse.edutoria.ru/courses/00226cf2-0399-4e66-9dac-14237c52e2d0",
  },
];

export const Jazz100Page = () => {
  const handlers = useEvent({
    courseClickedSendAnalytics,
    arrowClickedSendAnalytics,
    jazz100ClickedSendAnalytics,
    generalPartnerClickedSendAnalytics,
  });

  return (
    <>
      <SEO
        title="Влюбитесь в джаз. Вместе с Музой"
        description="Авторские курсы Игоря Бутмана и звезд российской джазовой сцены"
        canonical={`${DOMAIN_SITE}${paths.jazz100()}`}
      />
      <LandingBlock.WelcomeSection
        mainImage={{
          desktop: mainImg,
          mobile: mainImgMobile,
          low: mainImgLow,
        }}
        contentRight={
          <>
            <JazzIcon />
            <div
              className="!font-m-sb-display font-semibold text-m-white
              xl:m-typography-text text-m-2xs uppercase text-center
              3xl:mt-mn-38 xl:mt-mn-16 mt-mn-20"
            >
              специальный проект <span className="text-m-orange">МУЗЫ</span> <br />
              и&nbsp;Igor Butman Music Group
            </div>
          </>
        }
        header={
          <>
            <HeaderDesktop
              subscribeButtonFixed
              className="absolute top-0 left-0 bg-transparent w-full"
            />
            <HeaderMobile />
          </>
        }
      />
      <LandingBlock.SliderAndPromoText
        carouselClassName="hidden xl:block"
        content={
          <>
            В&nbsp;год <span className="gradient-text">100-летия российского джаза</span>
            &nbsp;&mdash; авторские курсы от&nbsp;
            <span className="gradient-text">Игоря&nbsp;Бутмана</span> и&nbsp;звезд российской
            джазовой сцены
          </>
        }
        slides={slides.map((slide) => (
          <LandingBlock.BlockSliderInfo
            imageClassName="object-[68%]"
            onClick={() =>
              handlers.courseClickedSendAnalytics({
                course_id: slide.url.split("/")[4],
                course_name: slide.title,
              })
            }
            {...slide}
          />
        ))}
        onArrowClick={() => handlers.arrowClickedSendAnalytics()}
      />
      <LandingBlock.QuoteHistory
        imgTopLeft={retro}
        imgBottomRight={{ desktop: orchestraRetroDesktop, mobile: orchestraRetroMobile }}
        contentTopRight={
          <>
            Историю джаза в&nbsp;России мы&nbsp;отсчитываем от&nbsp;первого джазового концерта
            в&nbsp;Москве 1&nbsp;октября 1922 года
            <ButmanSign className="w-[250px] xl:w-[338px] xl:mr-[80px] 3xl:w-[508px] 3xl:mr-[155px] ml-auto mt-mn-38" />
          </>
        }
        contentBottomLeft={
          <>
            Первый в&nbsp;РСФСР концерт джазовой музыки состоялся в&nbsp;ГИТИСе и&nbsp;дал его
            &laquo;эксцентрический оркестр джаз-банд Валентина Парнаха&raquo;. Оркестр состоял
            из&nbsp;энтузиастов и&nbsp;репетировал меньше месяца, а&nbsp;сам Валентин Яковлевич
            Парнах не&nbsp;был профессиональным музыкантом, он&nbsp;был талантливым танцором&nbsp;и,
            как его называли, &laquo;новатором эксцентрического танца&raquo;...
          </>
        }
      />
      <LandingBlock.BigPreviewImage
        img={{ desktop: butmanDesktop, mobile: butmanMobile, low: butmanDesktopLow }}
        title={`Джазовый\nстандарт Игоря\nБутмана`}
        subTitle="Игорь бутман"
        onClick={() => {
          handlers.courseClickedSendAnalytics({
            course_id: "ebf9a1ac-c2f9-44df-85e9-7dbd53fa8494",
            course_name: "Джазовый стандарт Игоря Бутмана",
            link_type: "middle",
          });
          window.location.href =
            "https://muse.edutoria.ru/courses/ebf9a1ac-c2f9-44df-85e9-7dbd53fa8494";
        }}
      />
      <LandingBlock.ContentInformationWithImage
        redirectUrl="https://muse.edutoria.ru/courses/ebf9a1ac-c2f9-44df-85e9-7dbd53fa8494"
        img={butmanInfoBlock}
        title={`Игорь Бутман — российский джазовый\nмузыкант, саксофонист, народный\nартист России`}
        description={
          <>
            <p className="mb-[20px]">
              Игорь Бутман награждён медалью &laquo;Пяти континентов&raquo; Организации Объединённых
              Наций по&nbsp;вопросам образования, науки и&nbsp;культуры (ЮНЕСКО)
              &laquo;за&nbsp;выдающиеся достижения в&nbsp;области культуры и&nbsp;искусства,
              а&nbsp;также за&nbsp;вклад в&nbsp;развитие международных культурных связей&raquo;.
              Также в&nbsp;2018 году Игорь Бутман стал первым российским деятелем культуры,
              удостоенным премии Института устойчивого диалога за&nbsp;&laquo;монументальный вклад
              в&nbsp;преодоление противоречий благодаря искусству&raquo; и&nbsp;организацию
              музыкальных обменов ради сближения народов разных стран.
            </p>
            <p>
              <a
                className="gradient-text"
                href="https://muse.edutoria.ru/courses/ebf9a1ac-c2f9-44df-85e9-7dbd53fa8494"
              >
                Первый и&nbsp;единственный в&nbsp;своём роде онлайн-курс народного артиста России
                Игоря Бутмана
              </a>
              &nbsp;&mdash; это откровенный рассказ о&nbsp;своём творческом становлении
              и&nbsp;истории зарождения джазовой культуры в&nbsp;России, её&nbsp;развитии
              и&nbsp;легендарных именах, вдохновляющих Маэстро на&nbsp;творчество и&nbsp;создание
              собственных музыкальных композиций и&nbsp;проектов.
            </p>
          </>
        }
        onArrowClick={() =>
          handlers.courseClickedSendAnalytics({
            course_id: "ebf9a1ac-c2f9-44df-85e9-7dbd53fa8494",
            course_name: "Джазовый стандарт Игоря Бутмана",
            link_type: "footer",
          })
        }
      />
      <LandingBlock.BigPreviewImage
        img={{ desktop: lebedevDesktop, mobile: lebedevMobile, low: lebedevDesktopLow }}
        title={`Игра в бисер:\nКурс-концерт`}
        subTitle="Евгений лебедев"
        onClick={() => {
          handlers.courseClickedSendAnalytics({
            course_id: "65a90582-7ef2-4c97-bec9-bc35c53bd782",
            course_name: "Игра в бисер: Курс-концерт",
            link_type: "middle",
          });

          window.location.href =
            "https://muse.edutoria.ru/courses/65a90582-7ef2-4c97-bec9-bc35c53bd782";
        }}
      />
      <LandingBlock.ContentInformationWithImage
        redirectUrl="https://muse.edutoria.ru/courses/65a90582-7ef2-4c97-bec9-bc35c53bd782"
        img={lebedevInfoBlock}
        reverse
        title={`Евгений Лебедев — выпускник Berklee\nCollege of Music, призер конкурса\nпианистов в\u00A0Монтре и обладатель\nспециальной премии журнала Billboard`}
        description={
          <>
            <p className="mb-[20px]">
              Как повлияла русская музыкальная культура на&nbsp;развитие джаза? Возможно&nbsp;ли
              исполнить блюз, опираясь на&nbsp;музыкальные открытия Скрябина, был&nbsp;бы Чайковский
              против импровизаций на&nbsp;свои темы и&nbsp;&laquo;свингует&raquo;&nbsp;ли музыка
              Рахманинова? На&nbsp;все эти вопросы отвечает джазовый пианист, участник коллектива
              LRK TRIO, член Московского Союза композиторов и&nbsp;обладатель специальной премии
              журнала Billboard Евгений Лебедев.
            </p>
            <p>
              <a
                href="https://muse.edutoria.ru/courses/65a90582-7ef2-4c97-bec9-bc35c53bd782"
                className="gradient-text"
              >
                Курс &laquo;Игра в&nbsp;бисер&raquo;
              </a>
              &nbsp;&mdash; это не&nbsp;просто набор лекций, но&nbsp;концерт-рассказ, игра, суть
              и&nbsp;правила которой до&nbsp;конца не&nbsp;ясны, а&nbsp;ответы находятся
              в&nbsp;процессе импровизации. Подобно &laquo;Игре в&nbsp;бисер&raquo; Германа Гессе,
              в&nbsp;которой смешивается музыка, математика и&nbsp;другие науки, слушатели курса
              смогут увидеть парадоксальные связи между различными эпохами, культурами, традициями
              и&nbsp;видами искусств.
            </p>
          </>
        }
        onArrowClick={() =>
          handlers.courseClickedSendAnalytics({
            course_id: "65a90582-7ef2-4c97-bec9-bc35c53bd782",
            course_name: "Игра в бисер: Курс-концерт",
            link_type: "footer",
          })
        }
      />
      <LandingBlock.BigPreviewImage
        img={{ desktop: fantinDesktop, mobile: fantinMobile, low: fantinDesktopLow }}
        title="Джазовый вокал"
        subTitle="Фантине"
        onClick={() => {
          handlers.courseClickedSendAnalytics({
            course_id: "05fce4c8-bbf0-4566-be16-20c0612aefd6",
            course_name: "Джазовый вокал",
            link_type: "middle",
          });

          window.location.href =
            "https://muse.edutoria.ru/courses/05fce4c8-bbf0-4566-be16-20c0612aefd6";
        }}
      />
      <LandingBlock.ContentInformationWithImage
        redirectUrl="https://muse.edutoria.ru/courses/05fce4c8-bbf0-4566-be16-20c0612aefd6"
        img={fantineInfoBlock}
        title={`Фантине — уникальная российско-\nдоминиканская певица, солистка\nМосковского Джазового Оркестра,\nфиналистка шоу «Голос-9»\nна Первом канале`}
        description={
          <>
            <p className="mb-[20px]">
              Свой музыкальный путь Фантине начала в&nbsp;Австралии, откуда позднее перебралась
              в&nbsp;Майами, а&nbsp;затем в&nbsp;Нью-Йорк. За&nbsp;время своей карьеры Фантине
              успела поработать с&nbsp;такими музыкантами, как Дэйв Стюарт (Eurythmics) и&nbsp;Эрика
              Баду. В&nbsp;2014 году начала выступать в&nbsp;качестве приглашённой солистки
              Московского джазового оркестра под руководством народного артиста России, саксофониста
              Игоря Бутмана.
            </p>
            <p>
              <a
                href="https://muse.edutoria.ru/courses/05fce4c8-bbf0-4566-be16-20c0612aefd6"
                className="gradient-text"
              >
                Первый авторский курс Фантине&nbsp;
              </a>
              &mdash; не&nbsp;просто погружение в&nbsp;историю джазового вокала, но&nbsp;предельно
              ясный пошаговый мануал для начинающего вокалиста, в&nbsp;котором певица делится своими
              творческими лайфхаками: от&nbsp;ежедневной разминки и&nbsp;поиска вдохновения
              до&nbsp;вопросов вокального дыхания, написания собственных песен и&nbsp;построения
              музыкальной карьеры.
            </p>
          </>
        }
        onArrowClick={() =>
          handlers.courseClickedSendAnalytics({
            course_id: "05fce4c8-bbf0-4566-be16-20c0612aefd6",
            course_name: "Джазовый вокал",
            link_type: "footer",
          })
        }
      />
      <LandingBlock.BigPreviewImage
        img={{ desktop: chekurovDesktop, mobile: chekurovMobile, low: chekurovDesktopLow }}
        title={`Джазовая\nимпровизация`}
        subTitle="Антон чекуров"
        onClick={() => {
          handlers.courseClickedSendAnalytics({
            course_id: "00226cf2-0399-4e66-9dac-14237c52e2d0",
            course_name: "Джазовая импровизация",
            link_type: "middle",
          });
          window.location.href =
            "https://muse.edutoria.ru/courses/00226cf2-0399-4e66-9dac-14237c52e2d0";
        }}
      />
      <LandingBlock.ContentInformationWithImage
        redirectUrl="https://muse.edutoria.ru/courses/00226cf2-0399-4e66-9dac-14237c52e2d0"
        img={chekurovInfoBlock}
        reverse
        title={`Антон Чекуров — один из\u00A0самых\nталантливых саксофонистов\nнового поколения, солист Московского\nджазового оркестра под управлением\nИгоря Бутмана`}
        description={
          <>
            <p className="mb-[20px]">
              На&nbsp;счету музыканта участие в&nbsp;ряде международных фестивалей, а&nbsp;также
              выпуск студийного альбома A&nbsp;Beautiful Mind, релиз которого состоялся
              на&nbsp;престижном звукозаписывающем лейбле Butman Music и&nbsp;вызвал самые
              положительные оценки как критиков, так и&nbsp;публики.
            </p>
            <p>
              <a
                href="https://muse.edutoria.ru/courses/00226cf2-0399-4e66-9dac-14237c52e2d0"
                className="gradient-text"
              >
                Авторский курс Антона Чекурова
              </a>{" "}
              посвящён основам искусства импровизации. Вместе с&nbsp;солистом Московского джазового
              оркестра вы&nbsp;изучите базовые гармонические основы, ритмические конструкции
              и&nbsp;архитектурные соединения, узнаете, как комплексно подходить к&nbsp;изучению
              джазовой импровизации и&nbsp;почему в&nbsp;занятиях важна система.
            </p>
          </>
        }
        onArrowClick={() =>
          handlers.courseClickedSendAnalytics({
            course_id: "00226cf2-0399-4e66-9dac-14237c52e2d0",
            course_name: "Джазовая импровизация",
            link_type: "footer",
          })
        }
      />
      <LandingBlock.Partner
        leftImage={{
          mobileImage: generalPartnerOrchestraMobile,
          desktopImage: generalPartnerOrchestraDesktop,
        }}
        title={
          <>
            Генеральный <br className="hidden xl:block" /> партнер
          </>
        }
      >
        <a
          href="https://ibmg.ru/"
          target="_blank"
          rel="noreferrer noopener"
          onClick={() => handlers.generalPartnerClickedSendAnalytics()}
        >
          <img
            src={butmanMusicGroup}
            alt="Igor Butman Music Group"
            className="m-auto w-[300px] xl:w-[370px] 3xl:w-[553px]"
          />
        </a>
      </LandingBlock.Partner>
      <Footer className="bg-none bg-m-dark-blue" />
    </>
  );
};
