import clsx from "clsx";

import { Section, SkeletonLoader } from "@/shared/ui";

const CourseHeader = () => {
  return (
    <div className="w-full md:w-[40%]">
      <div className="text-center sm:text-left gradient-text text-[20px] xl:text-[28px] 2xl:text-m-6xl leading-none mb-mv-3">
        <SkeletonLoader variant="blue" height="1em" />
      </div>
      <div className="mx-auto sm:ml-0 text-center sm:text-left w-[80%] text-m-white text-m-xs 2xl:text-m-base sm:w-[250px]">
        <SkeletonLoader variant="blue" height="1em" />
      </div>
    </div>
  );
};
export const CourseHeaderBlockSkeleton = ({ className }: { className?: string }) => {
  return (
    <Section className={clsx("bg-m-dark-blue", className)}>
      <Section.Content className="pt-mv-5 sm:pt-mv-6 2xl:pt-mv-9">
        <div className="flex flex-col xl:flex-row xl:justify-between xl:pr-mv-5 3xl:pr-0">
          <CourseHeader />
        </div>
      </Section.Content>
    </Section>
  );
};
