import React from "react";
import { Helmet } from "react-helmet-async";

export const ApplicationMeta = () => (
  <Helmet
    htmlAttributes={{ lang: "ru" }}
    defaultTitle="Муза — онлайн-университет творческих профессий"
    titleTemplate="%s"
  >
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
    <meta
      name="keywords"
      content="курсы рисования, онлайн курсы с сертификатом, обучение искусству, творческие занятия, курсы актерского мастерства, профессии искусства, творческие мастер классы, творческие вузы, курсы вокала, курсы живописи, курсы по искусству, образование в сфере искусства"
    />
    <link rel="icon" href="/favicon.ico" sizes="any" />
    <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
    <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
    <link rel="manifest" href="/manifest.webmanifest" crossOrigin="use-credentials" />
    <meta
      name="description"
      content="Онлайн-курсы по искусству и профессиональное образование в области творческих индустрий: цифровые технологии, дизайн, нейросети, музыка, рисунок, режиссура, актерское мастерство."
    />
    <meta
      name="og:description"
      content="Онлайн-курсы по искусству и профессиональное образование в области творческих индустрий: цифровые технологии, дизайн, нейросети, музыка, рисунок, режиссура, актерское мастерство."
    />
    <meta name="og:image" content="/apple-touch-icon.png" />
  </Helmet>
);
