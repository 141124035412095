import { createEvent, createStore, Store, Event, createEffect, sample, combine } from "effector";

import { updateUrlSearchParams } from "@/entities/navigation";

export type TriggerFilterMode = "change" | "apply" | "init";

type CreateFilters<T> = {
  name: string;
  defaultValue: T;
  syncWithUrl?: boolean;
  trigger?: TriggerFilterMode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Filter<T = any> = {
  $value: Store<T>;
  changed: Event<T>;
  reset: Event<void>;
  initialized: Event<T>;
  name: string;
  setTriggerMode: Event<TriggerFilterMode>;
  defaultValue: T;
  disabled: Event<boolean>;
  $isDisabled: Store<boolean>;
};

type FilterObject = { name: string; value: string };

export type ApplyFilters = {
  applied: Event<void>;
  $filters: Store<FilterObject[]>;
  setTriggerMode: Event<TriggerFilterMode>;
  reset: Event<void>;
  $hasAppliedFilters: Store<boolean>;
  initialized: Event<FilterObject[]>;
  resetSelectedFilter: Event<string>;
  $triggerMode: Store<TriggerFilterMode>;
  applyCanceled: Event<void>;
  changed: Event<void>;
};

export const createFilter = <T>(params: CreateFilters<T>): Filter<T> => {
  const { name, defaultValue, syncWithUrl = true } = params;

  const changed = createEvent<T>();
  const reset = createEvent();
  const initialized = createEvent<T>();
  const setTriggerMode = createEvent<TriggerFilterMode>();
  const disabled = createEvent<boolean>();

  const $triggerMode = createStore<TriggerFilterMode>("change", { sid: `${name}-mode` }).on(
    setTriggerMode,
    (_, trigger) => trigger,
  );
  const $value = createStore<T>(defaultValue, { sid: name })
    .on(changed, (_, value) => value)
    .on(initialized, (_, value) => value)
    .reset(reset);

  const $isDisabled = createStore<boolean>(false).on(disabled, (_, state) => state);

  const synchronizeWithUrlFx = createEffect(async ({ value }: { value: T }) => {
    const filter = { [name]: String(value) };
    await updateUrlSearchParams(filter);
  });

  if (syncWithUrl) {
    sample({
      clock: changed,
      source: $triggerMode,
      filter: (triggerMode) => triggerMode === "change",
      fn: (_, value) => ({ value }),
      target: synchronizeWithUrlFx,
    });

    sample({
      clock: reset,
      source: $value,
      fn: (value) => ({ value }),
      target: synchronizeWithUrlFx,
    });
  }

  return {
    $value,
    changed,
    reset,
    initialized,
    name,
    setTriggerMode,
    defaultValue,
    disabled,
    $isDisabled,
  };
};

export const createListFilters = (filters: Filter[]) => {
  const applied = createEvent();
  const updateFilter = createEvent<FilterObject>();
  const setTriggerMode = createEvent<TriggerFilterMode>();
  const reset = createEvent();
  const initialized = createEvent<FilterObject[]>();
  const resetSelectedFilter = createEvent<string>();
  const applyCanceled = createEvent();
  const changed = createEvent();
  const disabledAllFilters = createEvent<boolean>();

  const defaultValues = filters.map((filter) => {
    return { name: filter.name, value: filter.defaultValue };
  });

  const synchronizeWithUrlFx = createEffect(
    async ({ filterObjects }: { filterObjects: FilterObject[] }) => {
      const filtersKeyValue = filterObjects.map((filter) => {
        return { [filter.name]: filter.value };
      });

      await updateUrlSearchParams(filtersKeyValue);
    },
  );

  const $triggerMode = createStore<TriggerFilterMode>("apply").on(
    setTriggerMode,
    (_, trigger) => trigger,
  );

  const $selectedFilters = createStore<FilterObject[]>([], { sid: "filters" })
    .on(updateFilter, (current, newFilter) => {
      return current.map((filter) => {
        if (filter.name === newFilter.name) {
          return newFilter;
        }
        return filter;
      });
    })
    .on(initialized, (_, initializedFilters) => initializedFilters)
    .on(reset, () => [...defaultValues]);

  const $appliedFilters = createStore<FilterObject[]>([], { sid: "applied-filters" })
    .on(initialized, (_, initializedFilters) => initializedFilters)
    .on(resetSelectedFilter, (current, filterName) =>
      current.map((currentFilter) => {
        const defaultValue = defaultValues.find((value) => value.name === filterName);

        if (currentFilter.name === filterName && defaultValue) {
          return {
            ...currentFilter,
            value: defaultValue.value,
          };
        }

        return currentFilter;
      }),
    )
    .on(reset, () => [...defaultValues]);

  const $filters = combine(
    $triggerMode,
    $selectedFilters,
    $appliedFilters,
    (mode, selectedFilter, appliedFilters) => {
      if (mode === "change") return selectedFilter;

      return appliedFilters;
    },
  );

  const $hasAppliedFilters = $selectedFilters.map((selectedFilters) =>
    hasAppliedFilters(selectedFilters, defaultValues),
  );

  for (const filter of filters) {
    sample({
      clock: filter.$value,
      fn: (value) => ({ name: filter.name, value }),
      target: updateFilter,
    });

    sample({
      clock: initialized,
      fn: (initializedFilters) =>
        getNewValueOrDefault(initializedFilters, defaultValues, filter.name),
      target: filter.initialized,
    });

    sample({
      clock: reset,
      target: filter.reset,
    });

    sample({
      clock: setTriggerMode,
      target: filter.setTriggerMode,
    });

    sample({
      clock: disabledAllFilters,
      target: filter.disabled,
    });

    sample({
      clock: resetSelectedFilter,
      filter: (name) => name === filter.name,
      target: filter.reset,
    });

    sample({
      clock: applyCanceled,
      source: $appliedFilters,
      fn: (appliedFilters) => getNewValueOrDefault(appliedFilters, defaultValues, filter.name),
      target: filter.changed,
    });

    sample({
      clock: filter.changed,
      target: changed,
    });
  }

  sample({
    clock: applied,
    source: $selectedFilters,
    target: $appliedFilters,
  });

  sample({
    clock: applied,
    source: { filterObjects: $appliedFilters, triggerMode: $triggerMode },
    filter: ({ triggerMode }) => triggerMode === "apply",
    fn: ({ filterObjects }) => ({ filterObjects }),
    target: synchronizeWithUrlFx,
  });

  return {
    applied,
    $filters,
    setTriggerMode,
    reset,
    $hasAppliedFilters,
    initialized,
    resetSelectedFilter,
    $triggerMode,
    applyCanceled,
    changed,
    disabledAllFilters,
  };
};

function hasAppliedFilters(selectedFilters: FilterObject[], defaultFilters: FilterObject[]) {
  return (
    selectedFilters.length > 0 &&
    selectedFilters.some(
      (filter) =>
        filter.value !==
        defaultFilters.find((defaultFilter) => defaultFilter.name === filter.name)?.value,
    )
  );
}

function getNewValueOrDefault(
  filters: FilterObject[],
  defaultValues: FilterObject[],
  filterMatchName: string,
) {
  const currentAppliedFilter = filters.find((filter) => filter.name === filterMatchName);
  if (currentAppliedFilter) {
    return currentAppliedFilter.value;
  }
  return defaultValues.find((defaultFilter) => defaultFilter.name === filterMatchName)?.value;
}
