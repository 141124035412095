import { createEvent, createStore, split } from "effector";

import { $hasAuthenticatedUserId } from "@/entities/user";

import { openSignInModal } from "@/feature/sign-in";

export const openProfileMenu = createEvent();
export const closeProfileMenu = createEvent();

export const avatarClicked = createEvent();

export const $isOpen = createStore<boolean>(false);

$isOpen.on(openProfileMenu, () => true).on(closeProfileMenu, () => false);

split({
  clock: avatarClicked,
  source: $hasAuthenticatedUserId,
  match: {
    hasAuth: (isAuth) => isAuth,
    notAuth: (isAuth) => !isAuth,
  },
  cases: {
    hasAuth: openProfileMenu,
    notAuth: openSignInModal,
  },
});
