export const DEFAULT_API_ERROR_MESSAGE = "Что-то пошло не так";
export const DEFAULT_API_ERROR_MESSAGE_EXTRA = "Что-то пошло не так,\n попробуйте еще раз";
export const DEFAULT_API_SBERID_ERROR_MESSAGE = "Ошибка авторизации по СберID. Повторите попытку";
export const DEFAULT_API_ERROR_TYPE = "unknown_error";

export enum COOKIES {
  mindboxDeviceUUID = "mindboxDeviceUUID",
}

export enum ChangeEmailErrorCode {
  NOT_FOUND_TOKEN = "not_found_token",
  TOKEN_FAILED = "token_failed",
  ACTION_NOT_CREATED_TIMEOUT = "action_not_created_timeout",
  ACTION_NOT_CREATED = "action_not_created",
  ACTION_SEND_FAILED = "action_send_failed",
}

export enum ConfirmCodeErrorCode {
  ACTION_NOT_FOUND = "action_not_found",
  ACTION_IS_EXPIRED = "action_is_expired",
  ACTION_CODE_IS_INVALID = "action_code_is_invalid",
  ACTION_IS_USED = "action_is_used",
  ACTION_IS_FAILED = "action_is_failed",
  ACTION_CONFIRM_ATTEMPTS_SAVE_ERROR = "action_confirm_attempts_save_error", // TODO: документ "Обработка ошибок" в процессе, этот code не указан
  INVALID_EMAIL_FORMAT = "invalid_email_format",

  ACTION_NOT_FOUND_V3_1 = "400100",
  ACTION_IS_EXPIRED_V3_1 = "400104",
  ACTION_CODE_IS_INVALID_V3_1 = "400103",
  ACTION_IS_USED_V3_1 = "400102",
  ACTION_IS_FAILED_V3_1 = "400101",
  ACTION_NOT_CREATED_TIMEOUT = "action_not_created_timeout",
}

export const confirmError = {
  [ConfirmCodeErrorCode.ACTION_NOT_FOUND]: "Ошибка подтверждения. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_IS_EXPIRED]: "Истек срок действия кода. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_CODE_IS_INVALID]: "Неверный код. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_IS_USED]: "Неверный код. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_CONFIRM_ATTEMPTS_SAVE_ERROR]:
    "Ошибка подтверждения. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_IS_FAILED]:
    "Исчерпан лимит на ошибки ввода кода. Повторите попытку позже",

  [ConfirmCodeErrorCode.ACTION_NOT_FOUND_V3_1]: "Ошибка подтверждения. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_IS_EXPIRED_V3_1]: "Истек срок действия кода. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_CODE_IS_INVALID_V3_1]: "Неверный код. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_IS_USED_V3_1]: "Неверный код. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_IS_FAILED_V3_1]:
    "Исчерпан лимит на ошибки ввода кода. Повторите попытку позже",
};

export const changeEmailErrors = {
  [ChangeEmailErrorCode.NOT_FOUND_TOKEN]: "Ошибка доступа. Повторите вход",
  [ChangeEmailErrorCode.TOKEN_FAILED]: "Ошибка доступа. Повторите вход",
  [ChangeEmailErrorCode.ACTION_NOT_CREATED_TIMEOUT]: "Ошибка доступа. Повторите попытку",
  [ChangeEmailErrorCode.ACTION_NOT_CREATED]: "Ошибка доступа. Повторите попытку",
  [ChangeEmailErrorCode.ACTION_SEND_FAILED]: "Ошибка доступа. Повторите попытку",
};

export const confirmCodeErrors = {
  [ConfirmCodeErrorCode.ACTION_NOT_FOUND]: "Ошибка подтверждения. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_IS_EXPIRED]: "Истек срок действия кода. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_CODE_IS_INVALID]: "Неверный код. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_IS_USED]: "Неверный код. Повторите попытку",
  [ConfirmCodeErrorCode.ACTION_IS_FAILED]:
    "Исчерпан лимит на ошибки ввода кода. Повторите попытку позже",
  [ConfirmCodeErrorCode.ACTION_CONFIRM_ATTEMPTS_SAVE_ERROR]:
    "Ошибка подтверждения. Повторите попытку",
  [ConfirmCodeErrorCode.INVALID_EMAIL_FORMAT]: "Неверный формат e-mail",
  [ChangeEmailErrorCode.ACTION_NOT_CREATED_TIMEOUT]: "Ошибка доступа. Повторите попытку",
};

export enum DeleteAccountErrorCode {
  NOT_FOUND_TOKEN = "not_found_token",
  TOKEN_FAILED = "token_failed",
  REQUEST_FAILED = "request_failed",
  ACTION_NOT_CREATED_TIMEOUT = "action_not_created_timeout",
}

export const deleteAccountErrors = {
  [DeleteAccountErrorCode.NOT_FOUND_TOKEN]: "Ошибка доступа. Повторите вход",
  [DeleteAccountErrorCode.TOKEN_FAILED]: "Ошибка доступа. Повторите вход",
  [DeleteAccountErrorCode.REQUEST_FAILED]: "Ошибка доступа. Повторите вход",
  [DeleteAccountErrorCode.ACTION_NOT_CREATED_TIMEOUT]: "Ошибка доступа. Повторите попытку",
};

export const PLATFORM = "Muse";
export const PAYMENT_SUBSCRIPTION_PLATFORM = "SBO";

export const COURSE_FORMATS = {
  WEBINARS: "Вебинары",
  SELECTIONS: "Подборки",
};
