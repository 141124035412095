import { createGate } from "effector-react";

import { createVideoPlayer } from "@/feature/course-player";

import { $videos, $courseId, $hasCourseAccess } from "../../../model";

export const PlayerGate = createGate();

export const {
  viewedVideoChanged,
  nextVideoSelected,
  videoSelected,
  play,
  pause,
  start,
  toggle,
  playerUnmounted,
  $viewed,
  $viewedLessonsByCourseIdMap,
  $videoPlaying,
  $currentVideo,
  $displayVideoList,
  $introVideo,
  $currentVideoIndex,
  $isVideoBlocked,
} = createVideoPlayer({
  $videos,
  $hasCourseAccess,
  $currentCourseId: $courseId,
  reset: PlayerGate.close,
});
