import { createEvent, createStore, sample, Event, Store } from "effector";

import { CorrectAnswers, StatusTest, FullTest, VariantsAnswer } from "@/entities/lesson";

export type CheckAnswerTest = {
  testId: string;
  checkedAnswers: VariantsAnswer["id"][];
};

export type SelectedAnswers = Record<CheckAnswerTest["testId"], VariantsAnswer["id"][]>;

interface CreateLessonTest {
  resetTest: Event<void>;
  $correctTestAnswers: Store<CorrectAnswers[]>;
  $tests: Store<FullTest[]>;
  allTestStatusesUpdated: Event<FullTest[]>;
  successPercent?: number;
}

export const updateTestStatuses = ({
  tests,
  selectedAnswers,
  correctedAnswers,
}: {
  tests: FullTest[];
  selectedAnswers: SelectedAnswers;
  correctedAnswers: CorrectAnswers[];
}): FullTest[] => {
  return tests.map((test) => {
    const selectedAnswer = selectedAnswers[test.id];

    const foundSelectedTest = correctedAnswers.find((corrected) => corrected.test_id === test.id);

    const isSuccess =
      foundSelectedTest?.answers.length !== selectedAnswer.length
        ? false
        : foundSelectedTest?.answers.every((answer) => selectedAnswer.includes(answer));

    return {
      ...test,
      status: isSuccess ? StatusTest.Success : StatusTest.Fail,
    };
  });
};

export const calculateTestResult = (tests: FullTest[]): number => {
  if (!tests.length) return 0;

  const testStatuses = tests.map((test) => test.status);
  const successCount = testStatuses.filter((status) => status === StatusTest.Success).length;
  return Math.floor((successCount / tests.length) * 100);
};

const makeAnswersObject = (correctAnswers: CorrectAnswers[]): SelectedAnswers => {
  return correctAnswers.reduce((acc, answer) => {
    acc[answer.test_id] = [];
    return acc;
  }, {} as SelectedAnswers);
};

const hasAllAnswersChecked = (selectedAnswer: SelectedAnswers): boolean =>
  Object.values(selectedAnswer).every((answer) => answer.length > 0);

const MIN_SUCCESS_PERCENT = 60;

export const createLessonTest = ({
  resetTest,
  $correctTestAnswers,
  $tests,
  allTestStatusesUpdated,
  successPercent = MIN_SUCCESS_PERCENT,
}: CreateLessonTest) => {
  const initialize = createEvent<SelectedAnswers>();
  const answerChecked = createEvent<CheckAnswerTest>();
  const resultCalculating = createEvent();
  const resultCalculated = createEvent<number>();
  const certFormCanceled = createEvent();
  const testPassed = createEvent();
  const testRepeat = createEvent();
  const testPassedChecked = createEvent();
  const resetSelectedAnswers = createEvent<SelectedAnswers>();

  const $initializeTestStructure = createStore<SelectedAnswers>({}).on(
    initialize,
    (_, answers) => answers,
  );

  sample({
    clock: testRepeat,
    target: resetTest,
  });

  sample({
    clock: resetTest,
    source: $initializeTestStructure,
    target: resetSelectedAnswers,
  });

  const $selectedAnswers = createStore<SelectedAnswers>({})
    .on(initialize, (_, answers) => answers)
    .on(answerChecked, (state, checked) => ({
      ...state,
      [checked.testId]: [...checked.checkedAnswers],
    }))
    .on(resetSelectedAnswers, (_, payload) => payload);

  const $resultPercentageTest = createStore<number>(-1)
    .on(resultCalculated, (_, payload) => payload)
    .reset(resetTest);

  const $testResult = createStore<StatusTest>(StatusTest.Default)
    .on($resultPercentageTest, (_, percent) => {
      if (percent === -1) return StatusTest.Default;
      return percent >= successPercent ? StatusTest.Success : StatusTest.Fail;
    })
    .on(testPassed, () => StatusTest.Reset)
    .reset(certFormCanceled)
    .reset(resetTest);

  const $isResultCalculated = $testResult.map((result) => result !== StatusTest.Default);

  const $isTestPassed = createStore<boolean>(false)
    .on(testPassedChecked, (_, isPassed) => isPassed)
    .reset(resetTest);

  sample({
    clock: answerChecked,
    source: $selectedAnswers,
    fn: (answers) => hasAllAnswersChecked(answers),
    target: testPassedChecked,
  });

  sample({
    source: $correctTestAnswers.updates,
    fn: makeAnswersObject,
    target: initialize,
  });

  sample({
    clock: resultCalculating,
    source: {
      tests: $tests,
      selectedAnswers: $selectedAnswers,
      correctedAnswers: $correctTestAnswers,
    },
    fn: updateTestStatuses,
    target: allTestStatusesUpdated,
  });

  sample({
    clock: allTestStatusesUpdated,
    fn: calculateTestResult,
    target: resultCalculated,
  });

  return {
    answerChecked,
    resultCalculating,
    resultCalculated,
    certFormCanceled,
    testPassed,
    testRepeat,
    testPassedChecked,
    $selectedAnswers,
    $resultPercentageTest,
    $testResult,
    $isResultCalculated,
    $isTestPassed,
  };
};
