import React from "react";

import loadable from "@loadable/component";
import { WidgetHeader, WidgetImage, WidgetParagraph } from "@sbo/marketplace-player";

import { LessonApi } from "@/shared/api";
import { parse, DOMPurify, json2html } from "@/shared/lib/html";

const HeaderPlayer = loadable(() => import("@sbo/marketplace-player"), {
  resolveComponent: (module) => module.HeaderPlayer,
});

const Paragraph = ({ data }: { data: WidgetParagraph["data"] }) => {
  const { text, htmlTree } = data;

  if (htmlTree) {
    const cleanHtml = DOMPurify.sanitize(json2html(htmlTree));
    const content = new DOMParser().parseFromString(cleanHtml, "text/html");
    const anchors = content.getElementsByTagName("a");

    for (const anchor of anchors) {
      anchor.setAttribute("target", "_blank");
    }

    return <>{parse(content.body.innerHTML)}</>;
  }

  return <p>{text}</p>;
};

const Header = ({ data }: { data: WidgetHeader["data"] }) => (
  <div className="font-semibold my-mv-4 xl:my-mv-5 3xl:my-mv-6 first:mt-0">
    <HeaderPlayer data={data} />
  </div>
);

const Image = ({ data }: { data: WidgetImage["data"] }) => {
  return (
    <div className="flex flex-col items-center justify-center mt-mv-6">
      <img src={data.file.url} alt={data.file.url.split("/").pop()} className="w-full h-full" />
    </div>
  );
};

const IFrame = ({
  data,
  height = "450",
  className,
}: {
  data: LessonApi.IFrameBlockData;
  height?: string;
  className?: string;
}) => {
  return (
    <div className={className} style={{ height }}>
      <iframe
        allowFullScreen
        frameBorder="0"
        style={{ borderStyle: "none" }}
        width="100%"
        height="100%"
        title={data.id}
        src={data.url}
      />
    </div>
  );
};

export const MaterialAttributes = {
  Header,
  Image,
  Paragraph,
  IFrame,
};
