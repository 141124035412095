import { useStore } from "effector-react/scope";
import { FC } from "react";

import { ModalNotification } from "@/shared/ui";

import { $signUpError } from "../model/sign-up";

type Props = { onClose: () => void };

export const SignUpError: FC<Props> = ({ onClose }) => {
  const message = useStore($signUpError);

  return <ModalNotification type="error" title={message} onClose={onClose} />;
};
