import { useEvent } from "effector-react/scope";

import { authModalCloseClicked, openAuthModal } from "@/app/authorization-modals/model";
import { ConfirmationCodeModal, useConfirmCode } from "@/feature/confirm-code-modal";
import { useCountdownTimer } from "@/feature/countdown-button";

import { confirmCodeDeleteProfile, countdownTimerDeleteProfile } from "../model/delete-profile";

export const DeleteStudentProfileConfirmCodeModal = () => {
  const onSupportClick = useEvent(openAuthModal.supportContactsOnDelete);
  const onClose = useEvent(authModalCloseClicked);

  const confirmCode = useConfirmCode(confirmCodeDeleteProfile);
  const countdownDeleteProfile = useCountdownTimer(countdownTimerDeleteProfile);

  return (
    <ConfirmationCodeModal
      confirmCode={confirmCode}
      countdownTimerModel={countdownDeleteProfile}
      onSupportContactsClick={onSupportClick}
      onClose={onClose}
    />
  );
};
