import { useEvent } from "effector-react/scope";

import { Modal } from "@/shared/ui";

import { paymentModalCloseClicked } from "../model/payment-management";
import {
  SubscriptionSectionCondition,
  SubscriptionSectionBenefits,
} from "./subscription-blocks-content-information";

export const SubscriptionsModal = () => {
  const handlers = useEvent({
    paymentModalCloseClicked,
  });

  return (
    <Modal.Surface className="relative md:max-w-[95vw] xl:max-w-[91vw] xl:top-[44px] 3xl:max-w-[1654px]">
      <Modal.Header close={() => handlers.paymentModalCloseClicked()} />
      <Modal.Body className="px-0 pb-0">
        <div className="pt-[32px] px-mn-20 md:pt-[96px] md:px-mn-38 xl:px-mn-50 3xl:pt-[100px] 3x:px-[62px]">
          <SubscriptionSectionCondition alwaysShowCondition={false} />
        </div>
        <SubscriptionSectionBenefits />
      </Modal.Body>
    </Modal.Surface>
  );
};
