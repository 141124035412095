import clsx from "clsx";
import { HTMLAttributeAnchorTarget, FC, MouseEventHandler } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import style from "./style.module.scss";
import { useOnLinkOpenEvent } from "./use-on-link-open-event";

interface LinkProps extends Pick<NavLinkProps, "to"> {
  display?: "flex" | "block" | "inline";
  target?: HTMLAttributeAnchorTarget;
  underline?: boolean;
  nativeLink?: boolean;
  className?: string;
  rel?: string;
  /**
   * Standard on click event handler.
   * Recommendation for analytics: use onLinkOpen event handler.
   */
  onClick?: MouseEventHandler;
  /**
   * Passive listener for link open event (left, middle clicks and enter press).
   * Primarily used for analytics.
   */
  onLinkOpen?: () => void;
}

export const Link: FC<LinkProps> = ({
  nativeLink = false,
  underline = false,
  display = "flex",
  target,
  className,
  children,
  onClick,
  onLinkOpen,
  rel,
  to = "#!",
  ...rest
}) => {
  const linkRef = useOnLinkOpenEvent(onLinkOpen);

  const classNames = clsx([
    style.Link,
    className,
    underline && style.underline,
    style[`display-${display}`],
  ]);

  if (nativeLink) {
    if (typeof to !== "string") {
      throw new TypeError("You should pass string based 'to' prop for native links");
    }
    return (
      <a
        rel={rel}
        ref={linkRef}
        href={to}
        target={target}
        className={classNames}
        onClick={onClick}
        {...rest}
      >
        {children}
      </a>
    );
  }

  return (
    <NavLink
      ref={linkRef}
      to={to}
      target={target}
      className={classNames}
      onClick={onClick}
      {...rest}
    >
      {children}
    </NavLink>
  );
};
