import { useStore } from "effector-react/scope";
import React, { useEffect, useRef, useState } from "react";

import { DOMAIN_SITE, paths } from "@/pages/paths";
import { $profileDataLoaded } from "@/pages/student-profile/model";
import { Promocode } from "@/pages/student-profile/modules/promocode/ui/promocode";

import { Footer } from "@/widgets/footer";
import { Header } from "@/widgets/header";
import { SavedPaymentCards } from "@/widgets/saved-payment-cards";

import { userPurchasesModel } from "@/entities/user-purchases";

import { Spinner, ContentHeightLayout, Section, SEO } from "@/shared/ui";

import { StudentProfilePicture, SectionForm } from "@/feature/student-profile-form";

import { CoursesList } from "./modules/course-list";
import { SubscriptionControl } from "./modules/subscription-control";

const Loader = () => (
  <div className="bg-m-dark-blue top-0 left-0 w-full h-[1000px] flex items-center justify-center">
    <Spinner.Clip size={120} color="#FF8000" />
  </div>
);

export const StudentProfilePage = () => {
  const subscriptionContainerRef = useRef<HTMLDivElement>(null);
  const [shouldGoToManageSubscription, setShouldGoToManageSubscription] = useState(false);

  useEffect(() => {
    if (shouldGoToManageSubscription) {
      if (subscriptionContainerRef.current) {
        subscriptionContainerRef.current.scrollIntoView({ behavior: "smooth" });
        setShouldGoToManageSubscription(false);
      }
    }
  }, [shouldGoToManageSubscription]);

  const loaded = useStore($profileDataLoaded);
  const userCards = useStore(userPurchasesModel.$userCardsInfo);
  const userHasSavedCards = userCards.length > 0;

  const renderPageContent = loaded ? (
    <>
      <StudentProfilePicture />
      <Section className="relative xl:min-h-[60vh] flex justify-center items-center overflow-hidden pt-mv-6 lg:pt-[66px] pb-mv-7 lg:pb-mv-8 3xl:pb-mv-10 pr-[16px] lg:pr-[150px] pl-[16px] lg:pl-[81px] bg-m-dark-blue">
        <Section.Content className="flex flex-col items-start !w-full lg:!w-grid-lg xl:!w-grid-xl 2xl:!w-grid-2xl">
          <SectionForm />
          <SubscriptionControl containerRef={subscriptionContainerRef} />
          <CoursesList coursesButton={<Promocode.Button />} />
          {userHasSavedCards && (
            <SavedPaymentCards
              className="mt-[48px] xl:mt-[64px]"
              onManageSubscription={() => setShouldGoToManageSubscription(true)}
            />
          )}
        </Section.Content>
      </Section>
    </>
  ) : (
    <Loader />
  );

  return (
    <>
      <SEO
        title="Профиль. Муза — учитесь творчеству"
        description="Найдите свой курс в библиотеке онлайн-университета Муза и приступайте к обучению"
        canonical={`${DOMAIN_SITE}${paths.studentProfile()}`}
      />
      <Header.Desktop />
      <Header.Mobile />
      <ContentHeightLayout className="bg-m-dark-blue">{renderPageContent}</ContentHeightLayout>
      <Promocode.Popup />
      <Footer />
    </>
  );
};
