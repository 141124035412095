export enum ContentStatus {
  DRAFT = 1,
  MODERATION = 2,
  IMPROVE = 3,
  PUBLISHED = 4,
  OUT_OF_SALE = 5,
  ARCHIVED = 6,
  PUBLISHED_NOT_MAIN = 7,
  MUSE_PUBLISH = 4,
}

interface BuilderParams {
  initialStatuses?: ContentStatus[];
}

export function contentStatusBuilder(params: BuilderParams = {}) {
  const { initialStatuses } = params;

  const statuses: ContentStatus[] = initialStatuses ?? [];

  const adder = (toBeAdded: ContentStatus[]) => () => {
    statuses.push(...toBeAdded);
    return contentStatusBuilder({ ...params, initialStatuses: statuses });
  };

  return {
    drafted: adder([ContentStatus.DRAFT]),
    inModeration: adder([ContentStatus.MODERATION]),
    inImprovement: adder([ContentStatus.IMPROVE]),
    published: adder([ContentStatus.PUBLISHED]),
    publishedNotMain: adder([ContentStatus.PUBLISHED_NOT_MAIN]),
    anyPublished: adder([ContentStatus.PUBLISHED, ContentStatus.PUBLISHED_NOT_MAIN]),
    outOfSale: adder([ContentStatus.OUT_OF_SALE]),
    archived: adder([ContentStatus.ARCHIVED]),
    musePublished: adder([ContentStatus.MUSE_PUBLISH]),
    has: (status: number | null) => status !== null && statuses.includes(status),
    getStatuses: () => statuses,
  };
}
