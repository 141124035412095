import { KeyboardEventHandler } from "react";

import { Input, InputProps } from "@/shared/ui";

export type NameInputProps = Omit<InputProps, "maxLength">;

export const preventDoubleSpaceOrDashInput: KeyboardEventHandler<HTMLInputElement> = (event) => {
  const { currentTarget } = event;

  const prevChar = currentTarget.value[(currentTarget.selectionStart ?? 0) - 1];
  const SpaceAndDash = " -";

  if (SpaceAndDash.includes(event.key) && prevChar === event.key) {
    event.preventDefault();
  }
};

const NameInput = (props: NameInputProps) => {
  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    preventDoubleSpaceOrDashInput(event);
  };
  return <Input {...props} maxLength={20} onKeyDown={onKeyDown} />;
};

export const FirstNameInput = NameInput;

export const LastNameInput = NameInput;
