import { useEvent, useStore } from "effector-react/scope";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { ButtonRounded, Input, Modal, NavButton } from "@/shared/ui";

import { authModalCloseClicked } from "@/app/authorization-modals/model";
import { SberIdAuthButton } from "@/feature/sberid-auth";

import {
  $isSignInPending,
  $signInError,
  signInFormSchema,
  SignInFormValues,
  submittedSignInForm,
  resetSignInError,
} from "../model/sign-in";

interface SignInProps {
  onClickForgotPassword?: () => void;
  onClickGoSignUp: () => void;
}

export const SignIn = ({ onClickGoSignUp, onClickForgotPassword }: SignInProps) => {
  const signInError = useStore($signInError);
  const isPending = useStore($isSignInPending);
  const closeModal = useEvent(authModalCloseClicked);
  const handlers = useEvent({ submittedSignInForm, resetSignInError });

  const { control, handleSubmit, formState, reset } = useForm<SignInFormValues>({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(signInFormSchema),
    delayError: 1000, // 1s
    mode: "all",
  });

  const isSubmitDisabled = isPending || formState.isSubmitting || !formState.isValid;

  useEffect(() => {
    if (!formState.isValid || formState.isDirty) {
      handlers.resetSignInError();
    }
  }, [formState.isValid, formState.isDirty]);

  useEffect(() => {
    if (formState.isSubmitting) {
      reset({}, { keepValues: true });
    }
  }, [formState.isSubmitting]);

  return (
    <Modal.Surface className="h-screen md:h-auto pb-mn-38">
      <Modal.Header close={closeModal} />
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(handlers.submittedSignInForm)();
          }}
          noValidate
        >
          <div>
            <Controller
              name="username"
              control={control}
              render={({ fieldState, field }) => {
                return (
                  <>
                    <Input
                      isError={fieldState.invalid}
                      value={field.value.trim()}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Введите e-mail"
                      className="w-full"
                    />
                    <Input.Description
                      isError={fieldState.invalid}
                      color="secondary"
                      className="h-[22px] 3xl:mb-[28px] m-typography-modal-caption"
                    >
                      {fieldState.error?.message}
                    </Input.Description>
                  </>
                );
              }}
            />
            <Controller
              name="password"
              control={control}
              render={({ fieldState, field }) => {
                return (
                  <>
                    <Input.Password
                      placeholder="Введите пароль"
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isError={fieldState.invalid}
                      hasShowButton
                    />
                    {!signInError && (
                      <Input.Description
                        isError={fieldState.invalid}
                        color="secondary"
                        className="h-[28px] mb-[20px] 3xl:mb-[39px] m-typography-modal-caption"
                      >
                        {fieldState.error?.message}
                      </Input.Description>
                    )}
                  </>
                );
              }}
            />
            {Boolean(signInError) && (
              <Input.Description
                isError
                className="mb-[19px] 3xl:mb-[38px] m-typography-modal-caption"
              >
                {signInError}
              </Input.Description>
            )}
          </div>
          <div className="mt-2 flex justify-center space-x-4 mb-4 3xl:mb-8">
            <ButtonRounded disabled={isSubmitDisabled} type="submit">
              Войти
            </ButtonRounded>
            <ButtonRounded onClick={onClickForgotPassword} variant="secondary">
              Забыли пароль?
            </ButtonRounded>
          </div>
          <div className="w-[183px] mx-auto my-mv-3">
            <div className="flex items-center px-[4px] text-m-dark-grey">
              <div className="grow h-[1px] bg-m-dark-grey" />
              <div className="px-[25px] m-typography-modal-caption">или</div>
              <div className="grow h-[1px] bg-m-dark-grey" />
            </div>
          </div>
          <div className="text-center">
            <SberIdAuthButton />
          </div>
          <div className="mt-mn-20 mb-2 3xl:mb-8 flex items-center justify-center">
            <span className="mr-2 text-m-white m-typography-modal-input">Нет аккаунта?</span>
            <NavButton
              className="m-typography-modal-input underline"
              onClick={() => {
                onClickGoSignUp();
                handlers.resetSignInError();
              }}
            >
              Зарегистрироваться
            </NavButton>
          </div>
        </form>
      </Modal.Body>
    </Modal.Surface>
  );
};
