import clsx from "clsx";

import styles from "./switch-toggle.module.scss";

type SwitchToggleProps = {
  checked: boolean;
  onToggle: () => void;
};

export const SwitchToggle = ({ checked, onToggle, ...rest }: SwitchToggleProps) => {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <input
        checked={checked}
        onChange={onToggle}
        className={styles.switchInput}
        id="switch"
        type="checkbox"
        {...rest}
      />
      {/* jsx-a11y/label-has-associated-control */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className={clsx(
          {
            "bg-m-gradient": checked,
            "bg-m-grey-gradient": !checked,
          },
          styles.switchLabel,
          "transition-all duration-200",
        )}
        htmlFor="switch"
        {...rest}
      >
        <span className={styles.switchButton} />
      </label>
    </>
  );
};
