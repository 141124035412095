import { createDomain, createStore, sample } from "effector";
import { createGate } from "effector-react";
import { createHatch } from "framework";

import { FiltrationResult } from "@app/api";

import { courseModel } from "@/entities/course";

import { initializedSearchInput, searchCoursesFx, searchCleared } from "@/feature/search";

export const SearchPageGate = createGate();
export const hatch = createHatch(createDomain("SearchPage"));

const $pageParams = hatch.$query.map((q) => q.query);

export const $searchResult = createStore<FiltrationResult>({ courses: [], programs: [] }).on(
  searchCoursesFx.doneData,
  (_, payload) => payload,
);

export const $searchPending = createStore(false).on(
  searchCoursesFx.pending,
  (_, pending) => pending,
);

sample({
  clock: hatch.enter,
  source: $pageParams,
  fn: (params) => ({ searchQuery: params }),
  target: [initializedSearchInput, searchCoursesFx],
});

sample({
  clock: hatch.enter,
  target: courseModel.readyToLoadCourses,
});

sample({
  clock: SearchPageGate.close,
  target: searchCleared,
});
