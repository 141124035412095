/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  attach,
  createEvent,
  createStore,
  Effect,
  EffectParams,
  sample,
  Store,
  Event,
} from "effector";

export type PaginationOptions<Item, Fx extends Effect<any, any, any>> = {
  limit: number;
  offset: number;
  effect: Fx;
  $params?: Store<Item>;
  mapParams: (params: { offset: number; limit: number }) => EffectParams<Fx>;
};

export type Pagination = {
  loadMore: Event<void>;
  reset: Event<void>;
  $offset: Store<number>;
  $allLoaded: Store<boolean>;
  $pending: Store<boolean>;
  loadItemsFx: Effect<any, any, any>;
  limit: number;
};

export const createPagination = <Item, Fx extends Effect<any, any, any>>(
  options: PaginationOptions<Item, Fx>,
): Pagination => {
  const loadMore = createEvent();
  const reset = createEvent();

  const $params = options.$params ?? createStore<null>(null);

  const $offset = createStore<number>(options.offset).on(
    loadMore,
    (offset) => offset + options.limit,
  );
  const $allLoaded = createStore<boolean>(false).reset(reset);

  const loadItemsFx = attach({
    source: { offset: $offset, params: $params },
    mapParams: (_, { offset, params }) => {
      return params
        ? options.mapParams({ offset, limit: options.limit, ...params })
        : options.mapParams({
            offset,
            limit: options.limit,
          });
    },
    effect: options.effect,
  });

  const $pending = loadItemsFx.pending;

  sample({
    clock: loadItemsFx.doneData,
    fn: (data) => data.length < options.limit,
    target: $allLoaded,
  });

  sample({
    clock: loadMore,
    target: loadItemsFx,
  });

  $offset.reset(reset);
  $allLoaded.reset(reset);

  return {
    loadMore,
    $allLoaded,
    $offset,
    reset,
    loadItemsFx,
    $pending,
    limit: options.limit,
  };
};
