import { createEvent, createStore } from "effector";

export const $editMode = createStore(false);
export const $photoEditHidden = createStore(false);

export const changedEditMode = createEvent<boolean>();
export const togglePhotoEditHidden = createEvent();

$editMode.on(changedEditMode, (editMode, newEditMode) => newEditMode);
$photoEditHidden.on(togglePhotoEditHidden, (photoEditHidden) => !photoEditHidden);
