import clsx from "clsx";
import React, { ReactNode, useCallback, useEffect } from "react";
import { Controller } from "react-hook-form";

import { InputNumberFormat, Checkbox, Typography } from "@/shared/ui";

import { FeedBackClassNames, FeedBackFormState } from "../lib/types";
import { useFormFos } from "../lib/useFormFos";
import { ErrorMessage } from "./errors";
import styles from "./styles.module.scss";
import { SubmitButton } from "./submit-button";

const InputWrapper = ({ children, className }: { children: ReactNode; className: string }) => {
  return <div className={className}>{children}</div>;
};

type Props = {
  onSubmit: (data: FeedBackFormState) => void;
  customStyles: Partial<FeedBackClassNames>;
  submitButtonName?: { name: string; submittedName: string };
};

const defaulStyles: FeedBackClassNames = {
  formClassName: "",
  formChildClassName: "flex flex-col justify-between",
  inputClassName: styles.b2bInput,
  inputErrorClassName: styles.error,
  inputWrapperClassName: "",
  checkboxClassName: "border-m-dark",
  checkboxWrapperClassName: "flex items-center mt-[6px]",
  patternFormatClassName: `md:mb-0, ${styles.b2bInput}`,
  patternFormatErrorClassName: styles.error,
  submitButtonClassName: `${styles.submitButton} w-[100%] md:w-full mt-[20px] h-[30px] md:h-[40px] md:!px-mn-20 xl:!px-mn-38 xl:!h-[50px] !xl:px-m-[40px] 3xl:!h-[64px] !3xl:px-mv-6 3xl:mt-[30px] xl:!text-m-lg 3xl:!text-m-3xl 3xl:!w-[505px] whitespace-nowrap disabled:text-m-dark-grey disabled:!border-m-dark-grey !bg-transparent text-m-blue`,
  submitButtonPressedClassName: "!bg-m-arctic !text-m-white border-none",
  submitButtonIsValidClassName: "hover:border-m-orange border-m-blue",
};

export function FeedbackForm({ onSubmit, customStyles, submitButtonName }: Props) {
  const {
    control,
    onSubmit: handleSubmit,
    formState: { isSubmitted, isValid },
    reset,
  } = useFormFos({ onSubmit });

  const {
    formClassName,
    formChildClassName,
    inputClassName,
    inputErrorClassName,
    inputWrapperClassName,
    checkboxClassName,
    checkboxWrapperClassName,
    patternFormatClassName,
    patternFormatErrorClassName,
    submitButtonClassName,
    submitButtonPressedClassName,
    submitButtonIsValidClassName,
  } = { ...defaulStyles, ...customStyles };

  const FeedBackSubmitButton = useCallback(
    () =>
      SubmitButton({
        isSubmitted,
        isValid,
        submitButtonName,
        submitButtonClassName,
        submitButtonPressedClassName,
        submitButtonIsValidClassName,
      }),
    [isSubmitted, isValid],
  );

  useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        reset();
      }, 2000);
    }
  }, [isSubmitted]);

  return (
    <form onSubmit={handleSubmit} className={formClassName}>
      <div className={formChildClassName}>
        <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <InputWrapper className={inputWrapperClassName}>
              <input
                className={clsx(inputClassName, {
                  [inputErrorClassName]: fieldState.invalid,
                })}
                value={value}
                onChange={onChange}
                placeholder="Имя"
                maxLength={50}
                aria-label="name"
                name="name"
              />
              <ErrorMessage isShow={fieldState.invalid} message={fieldState.error?.message} />
            </InputWrapper>
          )}
        />
        <Controller
          name="surname"
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <InputWrapper className={inputWrapperClassName}>
              <input
                className={clsx(inputClassName, {
                  [inputErrorClassName]: fieldState.invalid,
                })}
                value={value}
                onChange={onChange}
                placeholder="Фамилия"
                maxLength={50}
                aria-label="surname"
                name="surname"
              />
              <ErrorMessage isShow={fieldState.invalid} message={fieldState.error?.message} />
            </InputWrapper>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <InputWrapper className={inputWrapperClassName}>
              <input
                className={clsx(inputClassName, {
                  [inputErrorClassName]: fieldState.invalid,
                })}
                value={value}
                onChange={onChange}
                placeholder="E-mail"
                aria-label="email"
                name="email"
              />
              <ErrorMessage isShow={fieldState.invalid} message={fieldState.error?.message} />
            </InputWrapper>
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <InputWrapper className={inputWrapperClassName}>
              <InputNumberFormat
                format="+7 (###) ###-##-##"
                allowEmptyFormatting
                mask="_"
                className={clsx(patternFormatClassName, {
                  [patternFormatErrorClassName]: fieldState.invalid,
                  "text-m-dark-grey": !value,
                })}
                value={value}
                onChange={onChange}
                placeholder="Номер телефона"
                maxLength={50}
                aria-label="phone"
                name="phone"
              />
              <ErrorMessage isShow={fieldState.invalid} message={fieldState.error?.message} />
            </InputWrapper>
          )}
        />
        <Controller
          name="agreement"
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <InputWrapper className={inputWrapperClassName}>
              <div className={checkboxWrapperClassName}>
                <Checkbox
                  checkBoxClassName={checkboxClassName}
                  status={fieldState.invalid ? "fail" : "default"}
                  checked={value}
                  onChange={() => {
                    onChange(!value);
                  }}
                  labelClassName="text-m-dark"
                />
                <Typography tag="span" size="m-caption" className="text-m-dark whitespace-pre">
                  Даю{" "}
                  <a
                    href="https://assets.edutoria.ru/documents/emailmarketing.pdf"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    согласие на рассылку
                  </a>
                  &nbsp;по e-mail
                </Typography>
              </div>
              <ErrorMessage isShow={fieldState.invalid} message={fieldState.error?.message} />
            </InputWrapper>
          )}
        />
        <div className="flex flex-col-reverse items-start md:flex-row">
          <div className="text-m-dark m-typography-caption">
            Продолжая, вы&nbsp;соглашаетесь с&nbsp;
            <a
              className="underline"
              href="https://edutoria.ru/termsofuse/user/agreement"
              target="_blank"
              rel="noreferrer"
            >
              Пользовательским&nbsp;соглашением
            </a>{" "}
            и&nbsp;
            <a
              href="https://edutoria.ru/termsofuse/user/privacypolicy"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              Положением&nbsp;о&nbsp;конфиденциальности
            </a>
          </div>
        </div>
        <FeedBackSubmitButton />
      </div>
    </form>
  );
}
