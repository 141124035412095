import clsx from "clsx";
import { useEvent } from "effector-react/scope";
import React, { memo } from "react";

import { Course } from "@app/api";

import { useCourseAccess } from "@/entities/user-purchases";

import { ButtonRounded } from "@/shared/ui";

import { buyCourseClicked } from "../analytics";
import { paymentModalOpened, PaymentModalType } from "../model/payment-management";

type BuyCourseButtonProps = {
  className?: string;
  course: Course;
};

export const BuyCourseButton = memo(({ className, course }: BuyCourseButtonProps) => {
  const openModalPayment = useEvent(paymentModalOpened);

  const hasCourseAccess = useCourseAccess(course);

  const analyticsHandler = useEvent({ buyCourseClicked });

  if (hasCourseAccess) return null;

  return (
    <ButtonRounded
      onClick={() => {
        openModalPayment(PaymentModalType.Buy);
        analyticsHandler.buyCourseClicked({
          courseId: course.id,
          coursePrice: course.definedTermPrice,
          courseName: course.title,
        });
      }}
      className={clsx(className)}
    >
      Купить курс за {course.definedTermPrice}₽
    </ButtonRounded>
  );
});
