import { createEffect, restore, createEvent, sample } from "effector";

import { TopicApi } from "@/shared/api";

export enum TopicNames {
  Art = "Вдохновение",
  Profession = "Профессия",
  Creation = "Творчество",
}

const getTopicsFx = createEffect(TopicApi.getTopics);

export const courseTopicsReceived = createEvent();

export const $topics = restore(getTopicsFx, []);

sample({
  clock: courseTopicsReceived,
  target: getTopicsFx,
});
