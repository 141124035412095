import { Attribute, AttributeType } from "@/shared/api/modules/lesson";
import { isClient } from "@/shared/lib/is-client";

import { MaterialAttributes } from "./material-attributes";

const renderMaterials = (materials: Attribute[]) =>
  materials.map((material) => {
    const { type, data, id } = material;
    switch (type) {
      case AttributeType.HEADING:
        return <MaterialAttributes.Header key={id} data={data} />;
      case AttributeType.TEXT:
        return isClient ? <MaterialAttributes.Paragraph key={id} data={data} /> : null;
      case AttributeType.IMAGE:
        return <MaterialAttributes.Image key={id} data={data} />;
      case AttributeType.IFRAME:
        return (
          <MaterialAttributes.IFrame
            className="flex flex-col items-center justify-center mt-mv-6 h-[450px]"
            key={id}
            data={data}
          />
        );
      default:
        return null;
    }
  });

export const MaterialsBuilder = ({ materials }: { materials: Attribute[] }) => (
  <>{renderMaterials(materials)}</>
);
