import { createEvent, createStore, sample, Store, Unit, Event } from "effector";
import { condition } from "patronum/condition";

import { inverse } from "@/shared/lib/effector/mappers/inverse";

import { everyTruthy } from "./common";

interface RetryFlowProps<Payload> {
  source: Unit<Payload>;
  filter: Store<boolean>;
  interrupted: Event<void>;
}

export const retryFlow = <Payload>({
  source: flowSource,
  filter: flowFilter,
  interrupted: flowInterrupted,
}: RetryFlowProps<Payload>) => {
  const start = createEvent();
  const continuedFlow = createEvent();
  const interruptedFlow = createEvent();
  const retry = createEvent();

  const complete = createEvent<Payload>();

  const reset = createEvent();

  const $interrupted = createStore(false);

  condition({
    source: start,
    if: flowFilter,
    then: continuedFlow,
    else: interruptedFlow,
  });

  $interrupted.on(interruptedFlow, () => true).reset(reset);

  sample({
    clock: continuedFlow,
    source: flowSource,
    filter: $interrupted.map(inverse),
    target: complete,
  });

  sample({
    source: continuedFlow,
    filter: $interrupted,
    target: reset,
  });

  sample({
    source: interruptedFlow,
    target: flowInterrupted,
  });

  const $shouldRetryFlow = everyTruthy([$interrupted, flowFilter]);

  sample({
    source: retry,
    filter: $shouldRetryFlow,
    target: start,
  });

  return { start, complete, retry, reset, $interrupted };
};
