export const enum Difficulty {
  DEFAULT,
  LOW,
  MEDIUM,
  HIGH,
}

export const enum DifficultyCaptions {
  DEFAULT = "",
  LOW = "низкая",
  MEDIUM = "средняя",
  HIGH = "высокая",
}
export const passwordLowDifficultyCheck = (password: string) => password.length;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=\S{8,})/;
export const passwordHighDifficultyRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@№#^$%&*])(?=\S{12,})/;

export const getPasswordDifficulty: (password: string) => Difficulty = (password) => {
  if (passwordHighDifficultyRegex.test(password)) {
    return Difficulty.HIGH;
  }
  if (passwordRegex.test(password)) {
    return Difficulty.MEDIUM;
  }
  if (passwordLowDifficultyCheck(password)) {
    return Difficulty.LOW;
  }

  return Difficulty.DEFAULT;
};
