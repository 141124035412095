import { createAnalyticsEvent } from "@/shared/analytics/model/analytics";

const CatalogPageEvents = {
  CLICK_MAIN_PAGE: "click_main_page",
  VIEW_COURSE_SLIDER: "view_course_slider",
  CLICK_FILTER_TOPIC: "click_filter_topic",
  CLICK_FILTER_TYPE: "click_filter_type",
  CLICK_MORE_COURSE: "click_more_course",
  MOREABOUT_COURSE: "moreabout_course",
  CLICK_TELEGRAM: "click_telegram",
};

export const clickMainPage = createAnalyticsEvent(({ linkText }: { linkText: string }) => ({
  event: "autoEvent",
  event_name: CatalogPageEvents.CLICK_MAIN_PAGE,
  link_text: linkText,
}));

export const viewCourseSlider = createAnalyticsEvent(({ linkType }: { linkType: string }) => ({
  event: "autoEvent",
  event_name: CatalogPageEvents.VIEW_COURSE_SLIDER,
  link_type: linkType,
}));

export const clickFilterTopic = createAnalyticsEvent(({ topic }: { topic: string }) => ({
  event: "autoEvent",
  event_name: CatalogPageEvents.CLICK_FILTER_TOPIC,
  topic,
}));

export const clickFilterType = createAnalyticsEvent(({ linkText }: { linkText: string }) => ({
  event: "autoEvent",
  event_name: CatalogPageEvents.CLICK_FILTER_TYPE,
  link_text: linkText,
}));

export const clickMoreCourse = createAnalyticsEvent<{
  linkText: string;
}>(({ linkText }) => ({
  event: "autoEvent",
  event_name: CatalogPageEvents.CLICK_MORE_COURSE,
  link_text: linkText,
}));

export const moreaboutCourse = createAnalyticsEvent<{
  linkType: string;
  linkText?: string;
}>(({ linkType, linkText }) => {
  const payload: Record<string, string> = {
    event: "autoEvent",
    event_name: CatalogPageEvents.MOREABOUT_COURSE,
    link_type: linkType,
  };

  if (linkText) {
    payload.link_text = linkText;
  }

  return payload;
});

export const clickTelegram = createAnalyticsEvent<{
  linkText: string;
  linkType: string;
}>(({ linkText, linkType }) => ({
  event: "autoEvent",
  event_name: CatalogPageEvents.CLICK_TELEGRAM,
  link_text: linkText,
  link_type: linkType,
}));
