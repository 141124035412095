import { createEvent, sample } from "effector";
import { useEvent } from "effector-react/scope";

import { analyticsEvents } from "@/shared/analytics";

import { filterLabels } from "@/feature/filters";
import { productCardAnalyticsInitialized } from "@/feature/go-to-product-view";

import {
  topicFilter,
  paginationCourses,
  paginationPrograms,
  educationFilter,
  periodFilter,
  levelFilter,
  getProductsByFilterFx,
} from "./model";

const moreAboutClicked = createEvent();
const sliderClicked = createEvent<string>();
const socialLinkOnFormClicked = createEvent<string>();
const sendFormClicked = createEvent();
const socialLinkClicked = createEvent<string>();

sample({
  clock: getProductsByFilterFx.doneData,
  fn: (data) => ({ meta: data.recommendationDetails }),
  target: productCardAnalyticsInitialized,
});

sample({
  clock: moreAboutClicked,
  fn: () => ({ linkText: "Подробнее" }),
  target: analyticsEvents.catalogPage.clickMainPage,
});

sample({
  clock: sliderClicked,
  fn: (type) => ({ linkType: type }),
  target: analyticsEvents.catalogPage.viewCourseSlider,
});

sample({
  clock: topicFilter.topicSelected,
  fn: (topic) => ({ topic: topic.name }),
  target: analyticsEvents.catalogPage.clickFilterTopic,
});

sample({
  clock: [paginationCourses.loadMore, paginationPrograms.loadMore],
  fn: () => ({ linkText: "Показать еще" }),
  target: analyticsEvents.catalogPage.clickMoreCourse,
});

sample({
  clock: socialLinkOnFormClicked,
  fn: (socialName) => ({ linkType: "catalog", linkText: socialName }),
  target: analyticsEvents.catalogPage.moreaboutCourse,
});

sample({
  clock: sendFormClicked,
  fn: () => ({ linkType: "catalog" }),
  target: analyticsEvents.catalogPage.moreaboutCourse,
});

sample({
  clock: socialLinkClicked,
  fn: (socialName) => ({ linkType: "catalog", linkText: socialName }),
  target: analyticsEvents.catalogPage.clickTelegram,
});

sample({
  clock: [educationFilter.changed, levelFilter.changed, periodFilter.changed],
  fn: (filter) => ({ linkText: filterLabels[filter] ?? "Любой" }),
  target: analyticsEvents.catalogPage.clickFilterType,
});

export const useCatalogPageAnalytics = () => {
  return {
    moreAboutClicked: useEvent(moreAboutClicked),
    sliderClicked: useEvent(sliderClicked),
    socialLinkOnFormClicked: useEvent(socialLinkOnFormClicked),
    sendFormClicked: useEvent(sendFormClicked),
    socialLinkClicked: useEvent(socialLinkClicked),
  };
};
