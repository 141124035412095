import { RefObject, useEffect, useRef } from "react";

import { KeyboardEventKey, MouseEventButton } from "@/shared/constants";

/**
 * Invokes callback when user opened a link
 * using passive eventListeners of mouseup and keydown events for a returned ref.
 * Listens only Left, Middle mouse buttons and Enter key.
 * @param {() => void} [onLinkOpen] - Callback to invoke
 * @returns {RefObject<HTMLAnchorElement>} Ref to use for event listener
 */
export function useOnLinkOpenEvent(onLinkOpen?: () => void): RefObject<HTMLAnchorElement> {
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (!onLinkOpen) {
      return undefined;
    }

    function mouseUp(e: MouseEvent) {
      if (![MouseEventButton.Left, MouseEventButton.Middle].includes(e.button)) {
        return;
      }
      onLinkOpen?.();
    }

    function keyDown(e: KeyboardEvent) {
      if (e.key !== KeyboardEventKey.Enter) {
        return;
      }
      onLinkOpen?.();
    }

    linkRef.current?.addEventListener("mouseup", mouseUp, true);
    linkRef.current?.addEventListener("keydown", keyDown, true);

    return () => {
      linkRef.current?.removeEventListener("mouseup", mouseUp, true);
      linkRef.current?.removeEventListener("keydown", keyDown, true);
    };
  }, [onLinkOpen]);

  return linkRef;
}
