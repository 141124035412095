import { useStore, useGate } from "effector-react/scope";
import React, { useState } from "react";
import { CatalogListItems } from "widgets/catalog";

import { Course } from "@app/api";

import { Container, CardSkeleton } from "@/shared/ui";
import { SliderOptions } from "@/shared/ui/components/slider";

import { CardCourseView } from "@/feature/go-to-product-view";

import {
  $isPopularCourseLoading,
  PopularCoursesGate,
  $popularCourses,
} from "../model/popular-courses";
import { CarouselCourse } from "./carousel-course";

const getCourseCard = (item: Course) => <CardCourseView course={item} key={item.id} />;
const getCardSkeleton = (_: unknown, index: number) => (
  <CardSkeleton className="w-full" key={index} />
);

const useGetPopularCoursesCards = (): JSX.Element[] => {
  const courses = useStore($popularCourses);
  const isLoading = useStore($isPopularCourseLoading);

  const COUNT_SKELETON_CARDS = 3;

  if (isLoading && !courses.length) {
    return Array(COUNT_SKELETON_CARDS)
      .fill(0)
      .map((_, index) => getCardSkeleton(_, index));
  }

  return courses.map(getCourseCard);
};

type Props = {
  options?: SliderOptions;
  cardClassName?: string;
  containerMode?: "fluid-fixed" | "fixed";
  className: string;
};

export const PopularCourse = ({
  options,
  cardClassName,
  containerMode = "fluid-fixed",
  className,
}: Props) => {
  const COUNT_VISBLE_CARDS = 4;
  const popCourseCards = useGetPopularCoursesCards();

  useGate(PopularCoursesGate);

  const [visibleIndex, setVisibleIndex] = useState<number>(COUNT_VISBLE_CARDS);

  const visibleCourses = popCourseCards.slice(0, visibleIndex);

  const isFullDisplay = visibleCourses.length === popCourseCards.length;

  const loadMore = isFullDisplay
    ? undefined
    : () => {
        setVisibleIndex((prev) => prev + COUNT_VISBLE_CARDS);
      };

  return (
    <Container mode={containerMode} className={className}>
      <div className="hidden md:block">
        <CarouselCourse
          cards={popCourseCards}
          title="Популярное"
          options={options}
          cardClassName={cardClassName}
        />
      </div>
      <div className="md:hidden">
        <CatalogListItems
          renderCatalog={[
            {
              name: "popCourses",
              title: "Популярное",
              renderElements: () => visibleCourses,
              isHidden: visibleCourses.length === 0,
              loadMore,
            },
          ]}
          isEmpty={popCourseCards.length === 0}
        />
      </div>
    </Container>
  );
};
