import { useStore } from "effector-react/scope";
import React, { useEffect, useState } from "react";
import { CatalogListItems } from "widgets/catalog";

import { Course } from "@app/api";

import { CardSkeleton } from "@/shared/ui";

import { GoToCourseView } from "@/feature/go-to-product-view";

import { mapSelectedOptionToCourseCard, userEducation } from "../modules/education";
import { $selectedCourseOption } from "../modules/education/model/education";
import { EmptyLayout } from "../modules/education/ui/education";

const getCardSkeleton = (_: unknown, index: number) => (
  <CardSkeleton className="w-full" key={index} />
);
const renderCard = (course: Course, selectedOption: userEducation.ViewOption) => {
  const CardComponent = mapSelectedOptionToCourseCard(selectedOption);
  return (
    <GoToCourseView key={course.id} courseId={course.id}>
      <CardComponent course={course} />
    </GoToCourseView>
  );
};

export const useGetCoursesCards = (): JSX.Element[] => {
  const courses = useStore(userEducation.$sliderCourses);
  const isLoading = useStore(userEducation.$isSelectedEmptyCoursesLoading);
  const selectedOption = useStore(userEducation.$selectedCourse);

  const COUNT_SKELETON_CARDS = 8;

  if (isLoading && !courses.length) {
    return Array(COUNT_SKELETON_CARDS)
      .fill(0)
      .map((_, index) => getCardSkeleton(_, index));
  }

  return courses.map((course) => renderCard(course, selectedOption));
};

export function CoursesTab() {
  const courseCards = useGetCoursesCards();

  const COUNT_VISBLE_CARDS = 8;

  const selectedOption = useStore($selectedCourseOption);

  const [visibleIndex, setVisibleIndex] = useState<number>(COUNT_VISBLE_CARDS);

  const visibleCourses = courseCards.slice(0, visibleIndex);

  const isFullDisplay = visibleCourses.length === courseCards.length;

  useEffect(() => {
    setVisibleIndex(COUNT_VISBLE_CARDS);
  }, [selectedOption]);

  const loadMore = isFullDisplay
    ? undefined
    : () => {
        setVisibleIndex((prev) => prev + COUNT_VISBLE_CARDS);
      };
  return (
    <CatalogListItems
      renderCatalog={[
        {
          name: "courses",
          title: "Курсы",
          renderElements: () => visibleCourses,
          loadMore,
          isHidden: visibleCourses.length === 0,
        },
      ]}
      isEmpty={courseCards.length === 0}
      EmptyLayout={<EmptyLayout />}
    />
  );
}
