import { createEvent, sample } from "effector";

import { analyticsEvents } from "@/shared/analytics";
import { CourseFormatEnum } from "@/shared/api";

export const shareCourseClicked = createEvent<{
  courseId: string;
  format_course: CourseFormatEnum;
  link_text: string;
}>();

sample({
  clock: shareCourseClicked,
  fn: ({ courseId, format_course, link_text }) => ({
    course_id: courseId,
    format_course,
    link_text,
  }),
  target: analyticsEvents.shareCourse,
});
