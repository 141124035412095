import { Event, sample, Store } from "effector";
import { every } from "patronum/every";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function everyTruthy(stores: Array<Store<any>>) {
  return every(stores, Boolean);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function everyBecameTruthy(stores: Array<Store<any>>): Event<void> {
  return sample({
    clock: stores,
    filter: everyTruthy(stores),
  });
}
