import { useEvent } from "effector-react/scope";
import { FC } from "react";

import { ModalNotification } from "@/shared/ui";

import { authModalCloseClicked } from "@/app/authorization-modals/model";

export const PasswordChangeSuccess: FC = () => {
  const handlers = useEvent({ close: authModalCloseClicked });

  return (
    <ModalNotification
      onClose={handlers.close}
      type="success"
      title="Пароль успешно обновлен"
      renderButtons={[{ title: "Ок", variant: "secondary", onClick: () => handlers.close() }]}
    />
  );
};
