import { createEvent, Effect } from "effector";
import { debounce } from "patronum/debounce";

export const debounceRequest = <T, R>({
  effect,
  timeout,
}: {
  effect: Effect<T, R>;
  timeout: number;
}) => {
  const runDebouncedRequest = createEvent<T>();

  debounce({ source: runDebouncedRequest, timeout, target: effect });

  return runDebouncedRequest;
};
