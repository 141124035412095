import { createEvent, createStore, Event, EventPayload, Effect } from "effector";
import { condition } from "patronum";

interface CreateFavoritesToggler<Id> {
  set: Effect<void, Id[]>;
  mark: Effect<Id, Id>;
  unmark: Effect<Id, Id>;
  reset: Event<void>;
}

export const createFavoritesToggler = <Id>({
  set,
  mark,
  unmark,
  reset,
}: CreateFavoritesToggler<Id>) => {
  // events
  const toggledFavorite = createEvent<{ id: Id; isToggled: boolean }>();

  // stores
  const $favoriteIds = createStore<Id[]>([]);

  // connections
  $favoriteIds
    .on(set.doneData, (_, ids) => ids)
    .on(mark.doneData, (currentFavoriteIds, newId) => [...currentFavoriteIds, newId])
    .on(unmark.doneData, (currentFavoriteIds, newId) =>
      currentFavoriteIds.filter((id) => id !== newId),
    )
    .reset(reset);

  condition({
    source: toggledFavorite,
    if: ({ isToggled }) => isToggled,
    then: unmark.prepend<EventPayload<typeof toggledFavorite>>((data) => data.id),
    else: mark.prepend<EventPayload<typeof toggledFavorite>>((data) => data.id),
  });

  return {
    $favoriteIds,
    toggledFavorite,
  };
};
