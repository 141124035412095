import { useStore } from "effector-react/scope";
import React, { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Program } from "@app/api";

import { DOMAIN_SITE, paths } from "@/pages/paths";

import { Catalog } from "@/widgets/catalog";
import { Footer } from "@/widgets/footer";
import { Header } from "@/widgets/header";
import { PopularCourse } from "@/widgets/popular-course";
import { SpecificProject } from "@/widgets/specific-project";

import { Course } from "@/entities/course";

import { usePagination } from "@/shared/lib/effector";
import { useIntersectionObserver } from "@/shared/lib/intersection-observer";
import { scrollToElement, scrollToTop } from "@/shared/lib/scroll";
import { Container, DeviceView, SEO, Typography } from "@/shared/ui";
import { CardSkeleton } from "@/shared/ui/components/card/card-skeleton";
import { ScrollToTargetButton } from "@/shared/ui/components/scroll-to-target-button/scroll-to-target-button";

import { CardProgramView, CardCourseView } from "@/feature/go-to-product-view";

import {
  $programs,
  $courses,
  $isProductsLoading,
  paginationCourses,
  paginationPrograms,
} from "./model";
import { EmptyCatalogLayout } from "./modules/empty-catalog-layout";
import { FeedbackFormBlock } from "./modules/feedbackFrom-block";
import {
  AppliedFiltersList,
  AsideFilters,
  FilterTopics,
  SettingFilterButton,
} from "./modules/filters";
import {
  StudentWork,
  OnboardBlock,
  WelcomeBlock,
  StatisticsBlock,
  SocialMediaBlock,
} from "./modules/landing-blocks";
import { Anchor } from "./modules/shared";

const getCourseCard = (item: Course) => <CardCourseView course={item} key={item.id} />;
const getProgramCard = (program: Program) => <CardProgramView key={program.id} program={program} />;
const getCardSkeleton = (_: unknown, index: number) => (
  <CardSkeleton className="w-full" key={index} />
);

const useGetCoursesCards = (): JSX.Element[] => {
  const courses = useStore($courses);
  const isLoading = useStore($isProductsLoading);

  const COUNT_SKELETON_CARDS = 6;

  if (isLoading && !courses.length) {
    return Array(COUNT_SKELETON_CARDS)
      .fill(0)
      .map((_, index) => getCardSkeleton(_, index));
  }

  return courses.map(getCourseCard);
};

const useGetProgramCards = (): JSX.Element[] => {
  const programs = useStore($programs);
  const isLoading = useStore($isProductsLoading);

  const COUNT_SKELETON_CARDS = 6;

  if (isLoading && !programs.length) {
    return Array(COUNT_SKELETON_CARDS)
      .fill(0)
      .map((_, index) => getCardSkeleton(_, index));
  }

  return programs.map(getProgramCard);
};

const PageTitle = memo(() => (
  <Container mode="fixed">
    <Typography className="uppercase mb-mn-20 3xl:mb-mn-30 font-bold" tag="h1" size="m-h1">
      Наши программы
    </Typography>
    <Typography tag="p" size="m-text" className="md:w-[60vw]">
      Профессиональное образование и&nbsp;онлайн-курсы для&nbsp;обучения творчеству, погружения
      в&nbsp;искусство и&nbsp;старта в&nbsp;креативных индустриях
    </Typography>
  </Container>
));

const MobileFiltersLayout = memo(({ inView }: { inView: boolean }) => {
  return (
    <>
      <DeviceView.Mobile>
        <Container className="pb-mn-30 block md:hidden" mode="fixed">
          <SettingFilterButton />
          <ScrollToTargetButton hidden={inView} targetId={Anchor.Catalog} />
          <AppliedFiltersList />
        </Container>
      </DeviceView.Mobile>
    </>
  );
});

const CatalogLayout = memo(() => {
  const courseCards = useGetCoursesCards();
  const programCards = useGetProgramCards();

  const paginationProgram = usePagination(paginationPrograms);
  const paginationCourse = usePagination(paginationCourses);

  return (
    <Catalog
      EmptyLayout={
        <>
          <EmptyCatalogLayout />
          <PopularCourse
            className="pt-[38px] md:pt-[50px] 3xl:pt-[74px]"
            cardClassName="!w-[50%] xl:!w-[32.5%]"
            options={{ centeredSlides: undefined, loop: true }}
          />
        </>
      }
      renderLeftAside={
        <DeviceView.Desktop>
          <AsideFilters />
        </DeviceView.Desktop>
      }
      isEmpty={programCards.length === 0 && courseCards.length === 0}
      renderCatalog={[
        {
          name: "programs",
          title: "Профессиональное образование",
          renderElements: () => programCards,
          loadMore: paginationProgram.loadMore,
          isHidden: programCards.length === 0,
          hideLoadMore:
            paginationProgram.allLoaded || paginationProgram.limit > programCards.length,
          loadMorePending: paginationProgram.pending,
        },
        {
          name: "courses",
          title: "Курсы",
          renderElements: () => courseCards,
          loadMore: paginationCourse.loadMore,
          isHidden: courseCards.length === 0,
          hideLoadMore: paginationCourse.allLoaded || paginationCourse.limit > courseCards.length,
          loadMorePending: paginationCourse.pending,
        },
      ]}
    />
  );
});

export const CatalogPage = () => {
  const location = useLocation();

  useEffect(() => {
    const hasSearchParams = location.search.length > 0;

    if (hasSearchParams) {
      scrollToElement(Anchor.Catalog);
      return;
    }

    scrollToTop();
  }, []);

  const { ref, inView } = useIntersectionObserver({
    rootMargin: "0px 0px -100% 0px",
  });

  return (
    <div className="bg-m-dark-blue">
      <SEO
        title="Курсы и профессии | Онлайн-университет творческих профессий «Муза» от Сбера"
        description="Профессиональное образование и онлайн-курсы для обучения творчеству, погружения в искусство и старта в креативных индустриях"
        canonical={`${DOMAIN_SITE}${paths.catalog()}`}
      />

      <WelcomeBlock
        header={
          <>
            <Header.Desktop className="absolute z-10 w-full bg-transparent" />
            <Header.Mobile />
          </>
        }
      >
        <div className="relative xl:absolute z-10 -mt-[20vw] w-full xl:-bottom-[100px]">
          <PageTitle />
          <FilterTopics />
        </div>
      </WelcomeBlock>

      <MobileFiltersLayout inView={!inView} />

      <div ref={ref} className="xl:mt-[124px] 3xl:mt-[150px]">
        <CatalogLayout />
      </div>
      <FeedbackFormBlock />
      <OnboardBlock />
      <StatisticsBlock />
      <StudentWork />
      <SpecificProject />
      <SocialMediaBlock />
      <Footer />
    </div>
  );
};
