import clsx from "clsx";
import React from "react";

import { DivProps } from "../types";
import style from "./style.module.scss";

export type BodyProps = DivProps;

export const Body: React.FC<BodyProps> = ({ className, children, ...rest }) => (
  <div className={clsx(style.Body, className)} {...rest}>
    {children}
  </div>
);
