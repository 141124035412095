import { createGqlEffect } from "@/shared/api";
import { apiInstance } from "@/shared/api/bff";

export const activatePromocodeV2 = createGqlEffect(apiInstance.product.activatePromocodeV2);
export const calculatePromo = createGqlEffect(apiInstance.product.calculatePromo);
export const activateB2CPromocode = createGqlEffect(apiInstance.product.activateB2CPromocode);
export const getTrialInfo = createGqlEffect(apiInstance.product.getTrialInfo);

export const getBoughtCourses = createGqlEffect(apiInstance.product.getBoughtCourses);

export const getBoughtEvents = createGqlEffect(apiInstance.product.getBoughtEvents);

export const getBoughtSubscription = createGqlEffect(apiInstance.product.getBoughtSubscription);
export const cancelSubscription = createGqlEffect(apiInstance.product.cancelSubscription);

export const restoreSubscription = createGqlEffect(apiInstance.product.restoreSubscription);
