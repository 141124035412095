import clsx from "clsx";

import { ButtonCircle } from "@/shared/ui";

import styles from "./avatar.module.scss";

type AvatarProps = {
  onClick?: () => void;
  className?: string;
  btnClassName?: string;
  avatarUrl?: string;
  hasUser?: boolean;
};

export const Avatar = ({ onClick, className, btnClassName, avatarUrl, hasUser }: AvatarProps) => {
  return (
    <ButtonCircle
      ariaLabel="Аватар"
      onClick={onClick}
      className={clsx(styles.AvatarBtn, btnClassName)}
    >
      <div
        className={clsx(
          {
            [styles.NotLoggedUserAvatar]: !hasUser,
            [styles.LoggedUserAvatar]: hasUser,
          },
          className,
        )}
        style={{ backgroundImage: avatarUrl ? `url(${avatarUrl})` : "" }}
      />
    </ButtonCircle>
  );
};
