import {
  Test,
  WidgetWebinar,
  WidgetAudio,
  WidgetFile,
  WidgetTable,
  WidgetQuote,
} from "@sbo/marketplace-player";

export enum AttributeType {
  HEADING = "header",
  TEXT = "paragraph",
  IMAGE = "image",
  VIDEO = "video",
  TEST = "test",
  WEBINAR = "webinar",
  AUDIO = "audio",
  FILE = "file",
  QUOTE = "quote",
  TABLE = "table",
  IFRAME = "iframe",
}

export interface HeadingBlock {
  id: string;
  type: AttributeType.HEADING;
  data: { text: string; level: number };
}

export interface TextBlock {
  id: string;
  type: AttributeType.TEXT;
  data: { text: string };
}

export interface ImageBlock {
  id: string;
  type: AttributeType.IMAGE;
  data: { file: { url: string } };
}

export interface VideoBlock {
  id: string;
  type: AttributeType.VIDEO;
  data: { url: string };
}

export interface TestSettings {
  total_attempts: number | null;
  is_show_right_answer: boolean;
  is_show_user_answer: boolean;
}

export interface UserTestBlock {
  id: string;
  type: AttributeType.TEST;
  data: Test;
  study_settings: TestSettings;
}

export interface WebinarBlock {
  id: string;
  type: AttributeType.WEBINAR;
  data: WidgetWebinar["data"];
}

export interface AudioBlock {
  id: string;
  type: AttributeType.AUDIO;
  data: WidgetAudio["data"];
}

export interface QuoteBlock {
  id: string;
  type: AttributeType.QUOTE;
  data: WidgetQuote["data"];
}

export interface FileBlock {
  id: string;
  type: AttributeType.FILE;
  data: WidgetFile["data"];
}

export interface TableBlock {
  id: string;
  type: AttributeType.TABLE;
  data: WidgetTable["data"];
}

export type IFrameBlockData = {
  id: string;
  url: string;
};

export interface IFrameBlock {
  id: string;
  type: AttributeType.IFRAME;
  data: IFrameBlockData;
}

// need to move types to lesson entity (@/entities/lesson/types.ts)
export type Attribute =
  | HeadingBlock
  | TextBlock
  | ImageBlock
  | VideoBlock
  | UserTestBlock
  | WebinarBlock
  | AudioBlock
  | FileBlock
  | QuoteBlock
  | TableBlock
  | IFrameBlock;

// need to move types to lesson entity (@/entities/lesson/types.ts)
export type LessonVideo = {
  title: string;
  id: string;
  duration: string;
  url: string;
};
