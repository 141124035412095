import React, { ReactElement, useRef } from "react";
import { createPortal } from "react-dom";

import { isClient } from "@/shared/lib/is-client";
import { useFocusLock, useScrollLock } from "@/shared/lib/locks";
import { AnimatePresence, motion } from "@/shared/ui";

import { animationModalConfig } from "./animation-config";
import {
  BodyProps,
  CloseProps,
  HeaderBoxProps,
  HeaderProps,
  Body,
  Close,
  Header,
  HeaderBox,
  Surface,
  Title,
  SurfaceProps,
  TitleProps,
  ButtonsProps,
  Buttons,
  DescriptionProps,
  Description,
  Caption,
  CaptionProps,
} from "./components";
import { Container, Overlay, Root, Screen } from "./layout";
import { Controls } from "./types";

export type ModalRenderer = React.FC<Controls>;

export interface ModalProps {
  isOpen: boolean;
  close: () => void;
  fullscreen?: boolean | "strict";
  closeOnClickOverlay?: boolean;
  render: ModalRenderer;
  allTouchedMove?: boolean;
}

export type ModalCompound = {
  (props: ModalProps): ReactElement | null;
  Surface: React.FC<SurfaceProps>;
  Header: React.FC<HeaderProps>;
  HeaderBox: React.FC<HeaderBoxProps>;
  Title: React.FC<TitleProps>;
  Close: React.FC<CloseProps>;
  Body: React.FC<BodyProps>;
  Buttons: React.FC<ButtonsProps>;
  Description: React.FC<DescriptionProps>;
  Caption: React.FC<CaptionProps>;
};

export const Modal: ModalCompound = ({
  isOpen,
  close,
  closeOnClickOverlay = true,
  render: RenderComponent,
  fullscreen,
  allTouchedMove = false,
}) => {
  const screen = useRef<HTMLDivElement | null>(null);
  const container = useRef<HTMLDivElement | null>(null);

  useScrollLock(isOpen, screen, {
    reserveScrollBarGap: true,
    allowTouchMove: () => allTouchedMove,
  });

  useFocusLock(isOpen, container);

  if (!isOpen) return null;

  /*
   * We don't use hooks like useOnClickOutside,
   * since it doesn't work properly with 1password extensions and elements like that
   *
   * Also, we use Screen element instead of Overlay for matching,
   * since the Overlay is placed below the Screen, so it's not accessible for click
   */
  const handleScreenClick = (event: React.MouseEvent) => {
    const clickedOutsideModal = event.target === screen.current;
    if (!clickedOutsideModal) return;
    if (!closeOnClickOverlay) return;
    close();
  };

  const RenderModal = () =>
    isClient
      ? createPortal(
          <Root>
            <Overlay />
            <Screen ref={screen} data-fullscreen={fullscreen} onMouseDown={handleScreenClick}>
              <Container ref={container}>
                <motion.div {...animationModalConfig}>
                  <RenderComponent close={close} />
                </motion.div>
              </Container>
            </Screen>
          </Root>,
          document.body,
        )
      : null;

  return (
    <AnimatePresence>
      <RenderModal />
    </AnimatePresence>
  );
};

Modal.Surface = Surface;
Modal.Header = Header;
Modal.HeaderBox = HeaderBox;
Modal.Title = Title;
Modal.Close = Close;
Modal.Body = Body;
Modal.Buttons = Buttons;
Modal.Description = Description;
Modal.Caption = Caption;
