import clsx from "clsx";
import { ReactNode } from "react";

import styles from "./background.module.scss";

type BackgroundNevmaProps = {
  children: ReactNode;
  variant?: 1 | 2;
  className?: string;
};

export const BackgroundNevma = ({ children, variant = 1, className }: BackgroundNevmaProps) => {
  const backgrounds = clsx(
    {
      [styles.NevmaOne]: variant === 1,
      [styles.NevmaTwo]: variant === 2,
    },
    className,
  );

  return <div className={backgrounds}>{children}</div>;
};
