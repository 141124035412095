import { useEvent } from "effector-react/scope";
import React from "react";

import { SCROLL_TO_LESSON_TABS } from "@/pages/course-view/modules/constants";

import { scrollToElement } from "@/shared/lib/scroll";
import { ButtonRounded } from "@/shared/ui";

import { tabCourse } from "../../../model";
import { goToTestClicked } from "../analytics";

export const GoToTestButton = () => {
  const analyticsHandlers = useEvent({ goToTestClicked });
  const tabChanged = useEvent(tabCourse.tabChanged);

  const handleClick = () => {
    analyticsHandlers.goToTestClicked();
    scrollToElement(SCROLL_TO_LESSON_TABS);
    tabChanged("tasks");
  };

  return (
    <ButtonRounded className="mt-mv-5" onClick={handleClick}>
      Перейти к тестированию
    </ButtonRounded>
  );
};
