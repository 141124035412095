import { DEFAULT_API_ERROR_TYPE } from "../constants";

export class AuthAPIError extends Error {
  code: number;

  name = "AuthApiError";

  type = DEFAULT_API_ERROR_TYPE;

  constructor(message: string, code: number, type: string) {
    super(message);
    this.code = code;
    this.type = type;
  }
}
