import { createAnalyticsEvent } from "../../model/analytics";

export const openCancelFormDeleteCard = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: "open_form_delete_card",
  link_text: "Удалить карту",
}));

export const clickThinkAboutDeletePaymentCard = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: "click_card_think_about",
  link_text: "Ещё подумаю",
}));

export const clickYesCancelPaymentCard = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: "click_ yes_delete_card",
  link_text: "Да, удалить",
}));
