/* eslint-disable effector/enforce-effect-naming-convention */
import { createEffect } from "effector";

import { createAuthRequestFx } from "@/shared/api/client/http";

import { AuthUrls, updateRefreshToken } from "../../client/fetch-auth";
import { CommonError } from "../../types";
import { AUTH_ROLE } from "./constants";
import type {
  ConfirmCodeSuccess,
  PasswordChangeCodeConfirm,
  PasswordChangeConfirm,
  ChangeEmailConfirmCodeSuccess,
  SignUpConfirmCodePayload,
  SignInPayload,
  SignInSuccess,
  SignUpSuccess,
  AddEmailSuccess,
  ChangeEmailSuccess,
  AddEmailPayload,
  AddPhoneResponse,
  AddPhonePayload,
  ConfirmCodePayload,
  ChangeEmailConfirmCodePayload,
  OfferConfirmationPayload,
  PasswordChangePayload,
  PasswordNewPayload,
  PasswordChange2PhasePayload,
  DeleteUserConfirmCodePayload,
  DeleteUserSuccess,
  CheckUserPayload,
  ResetPasswordPayload,
  ResetPasswordConfirmPayload,
  ResetPasswordSuccess,
  SignUpPayload,
  ChangeEmailNewConfirmCodePayload,
  ResetNewPasswordPayload,
} from "./types";

export const fetchRefreshToken = createEffect(updateRefreshToken);

export const callLogout = createEffect({
  async handler() {
    const response = await createAuthRequestFx(AuthUrls.LOGOUT)({
      method: "POST",
    });

    return response.body;
  },
});

export const confirmCode = createEffect<ConfirmCodePayload, ConfirmCodeSuccess, CommonError>({
  async handler(payload) {
    const response = await createAuthRequestFx<ConfirmCodeSuccess>(AuthUrls.CONFIRM_CODE)({
      method: "POST",
      body: { ...payload },
    });

    return response.body;
  },
});

export const resetPassword = createEffect<ResetPasswordPayload, ConfirmCodeSuccess, CommonError>({
  async handler({ username }) {
    const response = await createAuthRequestFx<ResetPasswordSuccess>(AuthUrls.RESET_PASSWORD)({
      method: "POST",
      body: { username },
    });

    const result: ConfirmCodeSuccess = {
      expired_at: response.body.expired_at,
      token: response.body.action_id,
      timeout: response.body.timeout,
    };

    return result;
  },
});

export const resetPasswordConfirmSms = createEffect<
  ResetPasswordConfirmPayload,
  ConfirmCodeSuccess,
  CommonError
>({
  async handler({ code, token }) {
    const response = await createAuthRequestFx<ConfirmCodeSuccess>(
      AuthUrls.RESET_PASSWORD_CONFIRM_SMS,
    )({
      method: "POST",
      body: { code, id: token },
    });

    return response.body;
  },
});

export const checkUser = createEffect<CheckUserPayload, unknown, CommonError>({
  async handler({ username, password }) {
    const response = await createAuthRequestFx(AuthUrls.CHECK_USER)({
      method: "POST",
      body: { username, password },
    });

    return response.body;
  },
});

export const signIn = createEffect<SignInPayload, SignInSuccess, CommonError>({
  async handler({ username, password }) {
    const response = await createAuthRequestFx<SignInSuccess>(AuthUrls.SIGN_IN)({
      method: "POST",
      body: { username, password },
    });

    return response.body;
  },
});

export const addEmail = createEffect<AddEmailPayload, AddEmailSuccess, CommonError>({
  async handler({ username, password }) {
    const response = await createAuthRequestFx<AddEmailSuccess>(AuthUrls.ADD_EMAIL)({
      method: "POST",
      body: { username, password },
    });

    return response.body;
  },
});

export const addEmailConfirm = createEffect<SignUpConfirmCodePayload, unknown, CommonError>({
  async handler({ code, token }) {
    const response = await createAuthRequestFx(AuthUrls.ADD_EMAIL_CONFIRM)({
      method: "POST",
      body: { code, token },
    });

    return response.body;
  },
});

export const fetchSignUp = createEffect<SignUpPayload, SignUpSuccess, CommonError>({
  async handler(payload) {
    const defaults = {
      add_android_app_hash: false,
      license_agreement: true,
      offer_agreement: true,
      subscribed: false,
      role: AUTH_ROLE, // muse-client
      registered_by: "muse",
    };
    const response = await createAuthRequestFx<SignUpSuccess>(AuthUrls.SIGN_UP)({
      method: "POST",
      body: { ...defaults, ...payload },
    });

    return response.body;
  },
});

export const signUpConfirm = createEffect<
  SignUpConfirmCodePayload,
  ConfirmCodeSuccess,
  CommonError
>({
  async handler({ code, token }) {
    const response = await createAuthRequestFx<ConfirmCodeSuccess>(AuthUrls.SIGNUP_CONFIRM)({
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: { code, token },
    });

    return response.body;
  },
});

export const resetNewPassword = createEffect<ResetNewPasswordPayload, unknown, CommonError>({
  async handler({ password, token }) {
    const response = await createAuthRequestFx(AuthUrls.RESET_PASSWORD_CONFIRM)({
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: { new_password: password, token },
    });

    return response.body;
  },
});

export const deleteUser = createEffect({
  async handler() {
    const response = await createAuthRequestFx<DeleteUserSuccess>(AuthUrls.DELETE_USER)({
      method: "DELETE",
    });

    return response.body;
  },
});

export const deleteUserConfirmCode = createEffect<
  DeleteUserConfirmCodePayload,
  ConfirmCodeSuccess,
  CommonError
>({
  async handler(payload) {
    const response = await createAuthRequestFx<ConfirmCodeSuccess>(AuthUrls.CONFIRM_CODE)({
      method: "POST",
      body: { ...payload },
    });

    return response.body;
  },
});

export const changePasswordConfirm = createEffect<
  PasswordChangePayload,
  ConfirmCodeSuccess,
  CommonError
>({
  async handler({ password }) {
    const response = await createAuthRequestFx<PasswordChangeConfirm>(AuthUrls.PASSWORD_CHANGE)({
      method: "POST",
      body: { password },
    });

    const result: ConfirmCodeSuccess = {
      token: response.body.action_id,
      timeout: response.body.timeout,
      expired_at: response.body.expired_at,
    };

    return result;
  },
});

export const changePassword2Phase = createEffect<
  PasswordChange2PhasePayload,
  PasswordChangeCodeConfirm,
  CommonError
>({
  async handler({ code, token }) {
    const response = await createAuthRequestFx<PasswordChangeCodeConfirm>(
      AuthUrls.PASSWORD_CHANGE_2PHASE,
    )({
      method: "POST",
      body: { code, id: token },
    });

    return response.body;
  },
});

export const changePassword = createEffect<
  PasswordNewPayload,
  PasswordChangeCodeConfirm,
  CommonError
>({
  async handler({ new_password, token }) {
    const response = await createAuthRequestFx<PasswordChangeCodeConfirm>(
      AuthUrls.PASSWORD_CHANGE_CONFIRM,
    )({
      method: "POST",
      body: { new_password, token },
    });

    return response.body;
  },
});

export const changeEmail = createEffect({
  async handler() {
    const response = await createAuthRequestFx<ChangeEmailSuccess>(AuthUrls.CHANGE_EMAIL)({
      method: "POST",
    });

    return response.body;
  },
});

export const changeEmailConfirmCode2Step = createEffect<
  ChangeEmailConfirmCodePayload,
  ChangeEmailConfirmCodeSuccess,
  CommonError
>({
  async handler({ code, id, new_email }) {
    const response = await createAuthRequestFx<ChangeEmailConfirmCodeSuccess>(
      AuthUrls.CONFIRM_CODE_2PHASE,
    )({
      method: "POST",
      body: { code, id, new_email },
    });

    return response.body;
  },
});

export const changeEmailConfirmCode = createEffect<
  ChangeEmailNewConfirmCodePayload,
  unknown,
  CommonError
>({
  async handler({ code, token }) {
    const response = await createAuthRequestFx(AuthUrls.CONFIRM_CODE)({
      method: "POST",
      body: { code, token },
    });

    return response.body;
  },
});

export const offerConfirmation = createEffect<OfferConfirmationPayload, unknown, CommonError>({
  async handler({ offer_agreement }) {
    await createAuthRequestFx(AuthUrls.OFFER_CONFIRM)({
      method: "POST",
      body: { offer_agreement, role: AUTH_ROLE },
    });
  },
});

export const addPhone = createEffect<AddPhonePayload, AddPhoneResponse, CommonError>({
  async handler({ phone }) {
    const response = await createAuthRequestFx<AddPhoneResponse>(AuthUrls.ADD_PHONE)({
      method: "POST",
      body: { phone },
    });

    return response.body;
  },
});
