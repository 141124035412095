import { createEffect, createEvent, createStore, Event, merge, sample, split } from "effector";
import { interval } from "patronum/interval";
import { status } from "patronum/status";

import { User } from "@app/api";

import { paths } from "@/pages/paths";

import { historyPush } from "@/entities/navigation";

import { AuthApi as AuthAPI, SharedDataFactory } from "@/shared/api";
import { $cookiesForRequest } from "@/shared/api/client/http";
import { getCookieByKey } from "@/shared/api/client/http/lib";
import { Cookies, jwt_decode } from "@/shared/lib/auth-lib";
import { isClient } from "@/shared/lib/is-client";

interface DecodedJWT {
  "https://hasura.io/jwt/claims": {
    "x-hasura-user-id": string;
    "x-hasura-allowed-roles": string[];
  };
}

const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE;

export const startRequestingRefreshToken = createEvent();
export const stopRequestingRefreshToken = createEvent();
const { tick } = interval({
  timeout: MILLISECONDS_IN_MINUTE * 4,
  start: startRequestingRefreshToken,
  stop: stopRequestingRefreshToken,
});

export const readyToLoadSession = createEvent();
export const userSettled = createEvent<User | null>();
export const userAuthDataReceived = createEvent<string>();

const checkHasRefreshToken = (cookieFromServer: string) => {
  const hasRefreshTokenFromServer = Boolean(getCookieByKey(cookieFromServer, "refresh_token"));
  const hasRefreshTokenFromClient = Boolean(Cookies.get("refresh_token"));

  return hasRefreshTokenFromServer || hasRefreshTokenFromClient;
};

export const refreshTokenFx = createEffect(async (cookie: string) => {
  if (!checkHasRefreshToken(cookie)) {
    return "";
  }

  const result = await AuthAPI.fetchRefreshToken();
  return result;
});
export const refreshToken = createEvent();

const isValidRoles = (roles: string[]) => roles.includes(AuthAPI.AUTH_ROLE);

const jwtTokenDecoded = userAuthDataReceived.filterMap((JWT) =>
  JWT ? (jwt_decode(JWT) as DecodedJWT) : undefined,
);

const jwtTokenValidated = jwtTokenDecoded.map((decodedJWT) => {
  const roles = decodedJWT["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"];

  if (Array.isArray(roles)) {
    return isValidRoles(decodedJWT["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"])
      ? decodedJWT
      : null;
  }

  return null;
});

const validJwtTokenReceived = jwtTokenValidated.filter({ fn: Boolean }) as Event<DecodedJWT>;
export const invalidRoleError = jwtTokenValidated.filter({
  fn: (jwt) => jwt === null,
}) as Event<null>;

export const $authenticatedUserId = createStore<string>("").on(
  validJwtTokenReceived,
  (_, decodedJWT) => decodedJWT["https://hasura.io/jwt/claims"]["x-hasura-user-id"],
);
export const $hasAuthenticatedUserId = $authenticatedUserId.map(Boolean);
export const $refreshRequestStatus = status({ effect: refreshTokenFx });
export const $refreshCompleted = createStore(false).on(refreshTokenFx.finally, () => true);
sample({
  clock: $authenticatedUserId,
  fn: Boolean,
  target: createEffect((has: boolean) => SharedDataFactory.set("isAuthenticate", has)),
});

sample({
  clock: refreshTokenFx.failData,
  fn: Boolean,
  target: createEffect(() => SharedDataFactory.set("isAuthenticate", false)),
});

sample({
  clock: readyToLoadSession,
  target: refreshToken,
});

sample({
  clock: refreshToken,
  source: $cookiesForRequest,
  filter: refreshTokenFx.pending.map((state) => !state),
  fn: (cookies) => cookies,
  target: refreshTokenFx,
});

sample({
  clock: refreshTokenFx.doneData,
  filter: Boolean,
  fn: (token) => token.access_token,
  target: userAuthDataReceived,
});

sample({
  clock: refreshTokenFx.fail,
  target: userSettled.prepend(() => null),
});

sample({
  clock: [tick, startRequestingRefreshToken],
  target: refreshToken,
});

export enum LogoutMethod {
  MANUAL = "MANUAL",
  FORCE = "FORCE",
}

export const logout = createEvent<{ method: LogoutMethod }>();
export const loggedIn = createEvent();

export const loggedOut = createEvent();

export const logoutFx = createEffect(AuthAPI.callLogout);

export const reloadPageFx = createEffect(() => {
  if (isClient) window.location.assign("/");
});

export const { logoutForce, logoutManual } = split(logout, {
  logoutForce: (payload) => payload.method === LogoutMethod.FORCE,
  logoutManual: (payload) => payload.method === LogoutMethod.MANUAL,
});

const logoutAnyway = merge([logoutForce, logoutFx.done]);

sample({
  clock: logoutFx.fail,
  fn: () => ({ method: LogoutMethod.FORCE }),
  target: logout,
});

sample({
  clock: logoutAnyway,
  target: loggedOut,
});

sample({
  clock: logoutManual,
  target: logoutFx,
});

sample({
  clock: logoutAnyway,
  fn: () => paths.home(),
  target: [historyPush, reloadPageFx],
});
