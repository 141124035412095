import { useEvent, useStore } from "effector-react/scope";
import { memo } from "react";

import { CalculatedPromo, SubscriptionPayInfo } from "@app/api";

import { TransactionPaymentEvent, TransactionStatus } from "@/entities/payment";
import { $authenticatedUserId } from "@/entities/user";

import { getNumberEndingsWords } from "@/shared/lib/get-number-endings-word";

import { PaymentWidgetModalLayout } from "@/feature/payment-widget";

import { createPromocodePaymentProps } from "../lib/create-promocode-payment-props";
import {
  promocodeTransaction,
  $promoCalculatedData,
  $promoCode,
  promocodeModalReset,
  $subscriptionOnPromocode,
} from "../model/promocode";

type PaymentDescriptionProps = {
  subscriptionInfo: SubscriptionPayInfo;
  calculatedPromo: CalculatedPromo;
};

const PaymentDescription = memo(
  ({ subscriptionInfo, calculatedPromo }: PaymentDescriptionProps) => {
    const periods = {
      day: ["день", "дня", "дней"],
      month: ["месяц", "месяца", "месяцев"],
      year: ["год", "года", "лет"],
    };

    const countPromocodeDate = getNumberEndingsWords(
      calculatedPromo.intervalCount,
      periods[calculatedPromo.intervalType.toLowerCase()],
    );

    const countSubscriptionDate = getNumberEndingsWords(subscriptionInfo.definedTermPeriod, [
      "месяц",
      "месяца",
      "месяцев",
    ]);

    return (
      <div className="my-mn-26 text-m-white">
        <h2 className="font-semibold text-m-orange">
          Пользуйтесь бесплатно {calculatedPromo.intervalCount} {countPromocodeDate}
        </h2>
        <p className="mt-1">
          Далее {subscriptionInfo.definedTermPeriod} {countSubscriptionDate} за{" "}
          {subscriptionInfo.definedTermPrice} ₽. Продление произойдет автоматически.
          <br />
          Отменить подписку можно в любой момент.
        </p>
        <p className="text-terms-sm my-1 text-neutral-400">
          Для завершения активации укажите карту - спишем c нее 1 ₽ и тут же вернем.
        </p>
      </div>
    );
  },
);

export const PromocodePaymentWidget = () => {
  const transactionStatus = useStore(promocodeTransaction.$transactionStatus);
  const calculatedPromo = useStore($promoCalculatedData);
  const promocode = useStore($promoCode);
  const subscriptionOnPromocode = useStore($subscriptionOnPromocode);
  const authenticatedUserId = useStore($authenticatedUserId);

  const paymentProps = createPromocodePaymentProps({
    calculatedPromo,
    userId: authenticatedUserId,
    subscriptionPaymentInfo: subscriptionOnPromocode,
    promocode,
  });

  const handlers = useEvent({
    transactionStatusReset: promocodeTransaction.transactionStatusReset,
    transactionStatusChanged: promocodeTransaction.transactionStatusChanged,
    closeModal: promocodeModalReset,
  });

  const handleTransactionChange = (event: TransactionPaymentEvent) => {
    if (event.status === TransactionStatus.REQUIRES_CONFIRMATION) {
      handlers.transactionStatusReset();
      return;
    }

    handlers.transactionStatusChanged(event);
  };

  const showPaymentDescription =
    subscriptionOnPromocode && calculatedPromo && transactionStatus === null;

  const productName = transactionStatus === null ? subscriptionOnPromocode?.productName : "";

  return (
    <PaymentWidgetModalLayout
      onCloseModal={() => handlers.closeModal()}
      transactionStatus={transactionStatus}
      productName={productName}
      paymentProps={paymentProps}
      handleTransactionChange={handleTransactionChange}
      renderPaymentDescription={
        <>
          {showPaymentDescription && (
            <PaymentDescription
              subscriptionInfo={subscriptionOnPromocode}
              calculatedPromo={calculatedPromo}
            />
          )}
        </>
      }
    />
  );
};
