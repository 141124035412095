import { useEvent, useGate, useStore } from "effector-react/scope";
import { memo } from "react";

import { ButtonStartSubscription } from "@/widgets/payment";

import { LockLayout, Section, VideoPlayer } from "@/shared/ui";

import { useConvertVideoUrl, VideoList, VIDEO_PLAYER } from "@/feature/course-player";

import { $hasCourseAccess, getCourseFx } from "../../../model";
import { durationSet, progressChanged } from "../analytics";
import {
  $currentVideo,
  $currentVideoIndex,
  $displayVideoList,
  $isVideoBlocked,
  $videoPlaying,
  nextVideoSelected,
  pause,
  play,
  PlayerGate,
  playerUnmounted,
  start,
  videoSelected,
  $viewed,
} from "../model/video-playlist";

const AccessOverlay = () => (
  <div className="absolute top-0 left-0 w-full h-full rounded-[1.25rem] overflow-hidden">
    <LockLayout>
      <h2 className="text-m-dark text-m-base xl:text-m-xl 3xl:text-m-3xl mb-mv-3 3xl:mb-mv-4 text-center">
        Для просмотра урока оформите
        <br /> подписку или купите курс
      </h2>
      <ButtonStartSubscription analytics={{ positionInfo: "video" }} />
    </LockLayout>
  </div>
);

export const CoursePlayer = memo(() => {
  useGate(PlayerGate);

  const handlers = useEvent({ durationSet, progressChanged, videoSelected, nextVideoSelected });
  const playerControls = useEvent({ pause, play, start, playerUnmounted });

  const viewed = useStore($viewed);
  const hasAccess = useStore($hasCourseAccess);
  const pending = useStore(getCourseFx.pending);
  const currentVideo = useStore($currentVideo);
  const videoIndex = useStore($currentVideoIndex);
  const isVideoBlocked = useStore($isVideoBlocked);
  const isVideoPlaying = useStore($videoPlaying);
  const videos = useStore($displayVideoList);

  const { url, isLoading } = useConvertVideoUrl(currentVideo?.url ?? "");

  return (
    <div id={VIDEO_PLAYER}>
      <Section className="relative bg-m-dark-blue min-h-[650px] xl:min-h-[500px] 3xl:min-h-[668px]">
        {!pending && (
          <Section.Content className="xl:pb-[67px] 2xl:pb-[92px] pt-mv-4 xl:pt-mv-5 3xl:pt-mv-6">
            <div className="flex items-center flex-col xl:flex-row">
              <div className="grow w-full">
                <div className="relative pt-[56.25%]">
                  <div
                    className="absolute top-0 left-0 w-full h-full"
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    <VideoPlayer
                      isLoading={isLoading}
                      isPlaying={isVideoPlaying}
                      onStart={() => playerControls.start()}
                      onPlay={() => playerControls.play()}
                      onPause={() => playerControls.pause()}
                      src={url}
                      onDuration={(duration) => handlers.durationSet(duration)}
                      onEnded={() => handlers.nextVideoSelected()}
                      onProgress={(progressSeconds) => handlers.progressChanged(progressSeconds)}
                      pipModeAvailable={hasAccess || videoIndex === 0}
                    />
                    {isVideoBlocked && <AccessOverlay />}
                  </div>
                </div>
              </div>
              <div className="pt-mv-4 xl:py-mv-4 w-full xl:max-w-[436px] 2xl:max-w-[512px]">
                {currentVideo && (
                  <VideoList
                    videos={videos}
                    currentVideo={currentVideo}
                    hasAccess={hasAccess}
                    onSelectVideo={(id) => handlers.videoSelected(id)}
                    viewed={viewed}
                  />
                )}
              </div>
            </div>
          </Section.Content>
        )}
      </Section>
    </div>
  );
});
