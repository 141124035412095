import React from "react";

import loadable from "@loadable/component";

const Desktop = loadable(() => import("./device-view").then((component) => component.Desktop), {
  fallback: <></>,
  ssr: false,
});

const Mobile = loadable(() => import("./device-view").then((component) => component.Mobile), {
  fallback: <></>,
  ssr: false,
});

export const DeviceView = {
  Mobile,
  Desktop,
};
