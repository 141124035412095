import React from "react";

import { CourseFormatEnum } from "@app/api";

import { useDevices } from "@/shared/lib/responsive";
import { ButtonRounded, DrawerBottom, Hint, Radio } from "@/shared/ui";

import { filterLabels } from "../lib/filter-labels";
import { FilterPanel } from "./filter-panel";
import { classNames } from "./radio-clsx";

const HintText = () => {
  const titleClasses =
    "text-m-arctic mb-mn-10 3xl:mb-mn-16 uppercase font-semibold text-m-sm 3xl:text-m-2xl";

  const paragraphClasses = "text-m-white text-m-sm 3xl:text-m-base";
  return (
    <article>
      <div className="mb-mn-20 3xl:mb-mn-26">
        <h1 className={titleClasses}>Профессия</h1>
        <p className={paragraphClasses}>
          Состоит из&nbsp;лекций и&nbsp;практики под&nbsp;руководством экспертов современного
          искусства и&nbsp;технологий. Вы&nbsp;сможете с&nbsp;нуля освоить творческую профессию,
          подготовить портфолио, защитить дипломный проект и&nbsp;стартовать в&nbsp;креативных
          индустриях
        </p>
      </div>
      <div>
        <h1 className={titleClasses}>Курс</h1>
        <p className={paragraphClasses}>
          Состоит из&nbsp;видеолекций, учебных материалов и&nbsp;тестовых заданий. Вы&nbsp;сможете
          учиться в&nbsp;удобном темпе: сделать первые шаги в&nbsp;профессии, освоить конкретный
          навык и&nbsp;найти творческое хобби
        </p>
      </div>
    </article>
  );
};

type FilterTypeEducationProps = {
  value: CourseFormatEnum | "any";
  onChange: (value: CourseFormatEnum | "any") => void;
  isDisabled: boolean;
};

export const FilterTypeEducation = ({ value, onChange, isDisabled }: FilterTypeEducationProps) => {
  const { isMobile } = useDevices();

  const renderHint = (isHintOpen: boolean, setOpenHint: (isHintOpen: boolean) => void) => {
    if (!isMobile) {
      return (
        <Hint isHintVisible={isHintOpen} className="-left-[35px] top-[20px]">
          <HintText />
        </Hint>
      );
    }

    return (
      <DrawerBottom isVisible={isHintOpen} onClose={() => setOpenHint(false)}>
        <HintText />
        <ButtonRounded className="mt-mn-26" onClick={() => setOpenHint(false)}>
          Понятно
        </ButtonRounded>
      </DrawerBottom>
    );
  };

  return (
    <FilterPanel groupTitle="Тип обучения" onRenderHint={renderHint} disabled={isDisabled}>
      <Radio
        className={classNames}
        id="education-any"
        checked={value === "any"}
        onChange={() => onChange("any")}
        status="default"
        label="Любое"
        disabled={isDisabled}
        hasCircle={false}
      />
      <Radio
        className={classNames}
        id="education-profession"
        checked={value === CourseFormatEnum.program}
        onChange={() => onChange(CourseFormatEnum.program)}
        status="default"
        label={filterLabels[CourseFormatEnum.program]}
        disabled={isDisabled}
        hasCircle={false}
      />
      <Radio
        className={classNames}
        id="education-course"
        checked={value === CourseFormatEnum.online_course}
        onChange={() => onChange(CourseFormatEnum.online_course)}
        status="default"
        label={filterLabels[CourseFormatEnum.online_course]}
        disabled={isDisabled}
        hasCircle={false}
      />
    </FilterPanel>
  );
};
