import clsx from "clsx";
import { KeenSliderInstance } from "keen-slider";
import React, { ReactNode, useState } from "react";

import { BaseCarousel, SliderControls } from "@/shared/ui";

type CarouselPhotosProps = {
  photos: ReactNode[];
  onButtonPrevClick?: () => void;
  onButtonNextClick?: () => void;
};

export const CarouselPhotos = ({
  photos,
  onButtonNextClick,
  onButtonPrevClick,
}: CarouselPhotosProps) => {
  const [sliderInstance, setSliderInstance] = useState<KeenSliderInstance | null>(null);

  return (
    <div
      className={clsx(
        "block md:h-auto shrink-0 3xl:w-[789px] xl:w-[45vw] xl:max-w-[789px] w-full bg-m-blue text-m-white uppercase font-m-sb-display font-semibold text-m-3xl leading-none 3xl:text-m-5xl 3xl:leading-[1.1]",
      )}
    >
      <BaseCarousel
        containerClassName="h-full relative bg-m-dark-blue"
        getInstanceRef={setSliderInstance}
        slideClassName="h-full"
        data={photos}
        enablePlugins={["autoplay"]}
      >
        {sliderInstance && (
          <div className="z-10 absolute bottom-1/2 w-full">
            <SliderControls
              controlsClass="!bg-m-white flex"
              classNameContainer="child:text-m-orange justify-between px-mn-20 md:px-mn-26 3xl:px-mn-38"
              navigationRenderPreset="circle"
              onSlideNext={() => {
                onButtonNextClick?.();
                sliderInstance?.next();
              }}
              onSlidePrev={() => {
                onButtonPrevClick?.();
                sliderInstance?.prev();
              }}
            />
          </div>
        )}
      </BaseCarousel>
    </div>
  );
};
