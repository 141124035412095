import clsx from "clsx";
import { useEvent } from "effector-react/scope";
import { ReactNode, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { boolean, object, string } from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

import { errorMessages } from "@/shared/lib/form";
import { isEmpty } from "@/shared/lib/object";
import { trimSpaceAndDash } from "@/shared/lib/strings";
import { InputNumberFormat, ButtonRounded, Checkbox, Image, Typography } from "@/shared/ui";
import b2bMobileImg from "@/shared/ui/assets/images/home/b2b-mobile.webp";
import b2bImg from "@/shared/ui/assets/images/home/b2b.webp";
import { ReactComponent as ArrowIcon } from "@/shared/ui/assets/images/icons/arrow-right-gradient.svg";

import { subscriptionFormSubmitted } from "../model/b2b";
import styles from "./section-b2b.module.scss";

const nameRegExp = /^[A-Za-zА-яЁё-\s]*$/gi;
const phoneRegExp = /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/;

const defaultValues = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  agreement: false,
};

const formSchema = object().shape({
  name: string()
    .transform(trimSpaceAndDash)
    .trim()
    .max(50, errorMessages.max(50))
    .required(errorMessages.required)
    .matches(nameRegExp, errorMessages.match),
  surname: string()
    .transform(trimSpaceAndDash)
    .trim()
    .max(50, errorMessages.max(50))
    .required(errorMessages.required)
    .matches(nameRegExp, errorMessages.match),
  email: string()
    .required(errorMessages.required)
    .test("customEmailValidator", errorMessages.email, (value) => !value || isEmail(value)),
  phone: string().matches(phoneRegExp, errorMessages.match),
  agreement: boolean().notOneOf([false], errorMessages.required),
});

type BenefitItemProps = {
  children: ReactNode;
};

const ErrorMessage = ({
                        message = "Поле не заполнено",
                        isShow,
                      }: {
  message?: string;
  isShow: boolean;
}) => {
  return (
    <div className="min-h-[24px] mt-[4px] flex items-center">
      {isShow && (
        <Typography tag="span" size="m-caption" className="text-m-magenta">
          {message}
        </Typography>
      )}
    </div>
  );
};

const InputWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex w-full flex-col md:mr-[8px] xl:mr-mn-20 mb-[6px] md:mb-0">{children}</div>
  );
};

const BenefitItem = ({ children }: BenefitItemProps) => (
  <div className={clsx(styles.b2bItem, "w-full xl:w-[22vw]")}>
    <div className={styles.arrow}>
      <ArrowIcon />
    </div>
    <div className="m-typography-text text-m-white">{children}</div>
  </div>
);

const B2bForm = () => {
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues,
    resolver: yupResolver(formSchema),
    delayError: 1000,
    mode: "all",
  });

  useEffect(() => {
    if (formState.isSubmitted) {
      setTimeout(() => {
        reset();
      }, 2000);
    }
  }, [formState.isSubmitted]);

  const formSubmitted = useEvent(subscriptionFormSubmitted);

  const SubmitButton = useCallback(() => {
    return (
      <ButtonRounded
        className={clsx(
          styles.submitButton,
          `w-[230px] md:w-full mt-[24px] h-[35px] md:h-[50px] md:!px-mn-20 xl:!px-mn-38 xl:!h-[50px] !xl:px-m-[40px] !3xl:px-m-6 xl:!text-m-lg whitespace-nowrap disabled:text-m-dark-grey disabled:!border-m-dark-grey ${
            formState.isSubmitted
              ? "!bg-m-arctic !text-m-white border-none"
              : "!bg-transparent text-m-blue"
          }`,
          {
            "hover:border-m-orange border-m-blue": formState.isValid,
          },
        )}
        variant="secondary"
        type="submit"
        disabled={formState.isSubmitting || !formState.isValid || isEmpty(formState.dirtyFields)}
      >
        {formState.isSubmitted ? "Заявка отправлена" : "Отправить заявку"}
      </ButtonRounded>
    );
  }, [formState.isSubmitting, formState.isValid, formState.dirtyFields]);

  return (
    <form onSubmit={handleSubmit(formSubmitted)} className="flex flex-col w-full">
      <div className="flex flex-col justify-between md:mb-[12px]">
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState }) => (
            <InputWrapper>
              <input
                className={clsx(styles.b2bInput, {
                  [styles.error]: fieldState.invalid,
                })}
                value={field.value}
                onChange={({ target: { value } }) => {
                  field.onChange(value);
                }}
                placeholder="Имя"
                maxLength={50}
                aria-label="name"
                name="name"
              />
              <ErrorMessage isShow={fieldState.invalid} message={fieldState.error?.message} />
            </InputWrapper>
          )}
        />
        <Controller
          name="surname"
          control={control}
          render={({ field, fieldState }) => (
            <InputWrapper>
              <input
                className={clsx(styles.b2bInput, {
                  [styles.error]: fieldState.invalid,
                })}
                value={field.value}
                onChange={({ target: { value } }) => {
                  field.onChange(value);
                }}
                placeholder="Фамилия"
                maxLength={50}
                aria-label="surname"
                name="surname"
              />
              <ErrorMessage isShow={fieldState.invalid} message={fieldState.error?.message} />
            </InputWrapper>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <InputWrapper>
              <input
                className={clsx(styles.b2bInput, {
                  [styles.error]: fieldState.invalid,
                })}
                value={field.value}
                onChange={({ target: { value } }) => {
                  field.onChange(value);
                }}
                placeholder="E-mail"
                aria-label="email"
                name="email"
              />
              <ErrorMessage isShow={fieldState.invalid} message={fieldState.error?.message} />
            </InputWrapper>
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field, fieldState }) => (
            <InputWrapper>
              <InputNumberFormat
                format="+7 (###) ###-##-##"
                allowEmptyFormatting
                mask="_"
                className={clsx("md:mb-0", styles.b2bInput, {
                  [styles.error]: fieldState.invalid,
                  "text-m-dark-grey": !field.value,
                })}
                value={field.value}
                onChange={({ target: { value } }) => {
                  field.onChange(value);
                }}
                placeholder="Номер телефона"
                maxLength={50}
                aria-label="phone"
                name="phone"
              />
              <ErrorMessage isShow={fieldState.invalid} message={fieldState.error?.message} />
            </InputWrapper>
          )}
        />
        <Controller
          name="agreement"
          control={control}
          render={({ field, fieldState }) => (
            <InputWrapper>
              <div className="flex items-center mt-[6px]">
                <Checkbox
                  checkBoxClassName="border-m-dark"
                  status={fieldState.invalid ? "fail" : "default"}
                  checked={field.value}
                  title=""
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                  labelClassName="text-m-dark"
                />
                <Typography tag="span" size="m-caption" className="text-m-dark whitespace-pre">
                  Даю{" "}
                  <a
                    href="https://assets.edutoria.ru/documents/emailmarketing.pdf"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    согласие на рассылку
                  </a>
                  &nbsp;по e-mail
                </Typography>
              </div>
              <ErrorMessage isShow={fieldState.invalid} message={fieldState.error?.message} />
            </InputWrapper>
          )}
        />
      </div>
      <div className="flex flex-col-reverse items-start md:flex-row">
        <div className="text-m-dark mb-mn-20 md:mb-0 m-typography-caption md:mr-mn-20 xl:mr-mn-74">
          Продолжая, вы соглашаетесь с{" "}
          <a
            className="underline"
            href="https://edutoria.ru/termsofuse/user/agreement"
            target="_blank"
            rel="noreferrer"
          >
            Пользовательским&nbsp;соглашением
          </a>{" "}
          и&nbsp;
          <a
            href="https://edutoria.ru/termsofuse/user/privacypolicy"
            target="_blank"
            className="underline"
            rel="noreferrer"
          >
            Положением&nbsp;о&nbsp;конфиденциальности
          </a>
        </div>
      </div>
      <SubmitButton />
    </form>
  );
};

export const SectionB2b = () => {
  const benefitItemClasses =
    "flex mb-[14px] xl:mb-mn-38 gap-[14px] xl:gap-[30px] flex-col xl:flex-row";

  return (
    <section className="flex flex-col md:flex-row">
      <div
        className={clsx(
          "flex w-full md:w-[55vw] xl:w-[65vw] pr-mn-20 md:pr-0 pl-mn-20 md:pl-mn-26 3xl:pl-mn-38 mr-mn-30",
          styles.section,
        )}
      >
        <div className="pb-mn-26">
          <Typography
            tag="h3"
            size="m-h3"
            className="text-m-white uppercase font-semibold font-m-sb-display mb-mn-30 xl:mb-mn-74"
          >
            Программа развития
            <br />
            креативности
            <br />
            для&nbsp;ваших сотрудников
          </Typography>
          <Image
            lazy
            containerClassName="block md:hidden"
            className="w-full object-cover mb-mn-30 max-h-[360px]"
            src={b2bMobileImg}
            alt="Программа развития креативности для ваших сотрудников"
          />
          <div>
            <div className={benefitItemClasses}>
              <BenefitItem>
                Уникальные пакеты онлайн-курсов по&nbsp;развитию творческих компетенций, собранные
                специально для ваших сотрудников
              </BenefitItem>
              <BenefitItem>
                Персональные образовательные траектории и&nbsp;поддержка ментора на&nbsp;протяжении
                всего обучения
              </BenefitItem>
            </div>
            <div className={benefitItemClasses}>
              <BenefitItem>
                Сертификаты о&nbsp;прохождении программы и&nbsp;доступ к&nbsp;уникальному сообществу
                творческих людей
              </BenefitItem>
              <BenefitItem>
                Скидки и&nbsp;программы лояльности на&nbsp;офлайн-мероприятия в&nbsp;вашем городе
              </BenefitItem>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-[57vw] xl:w-[45vw] 3xl:w-[35vw] bg-m-white pb-[56px]">
        <Image
          height={290}
          lazy
          containerClassName="min-h-[300px] hidden md:block"
          className="mb-mn-38 w-full object-cover md:h-[188px] 3xl:h-auto"
          src={b2bImg}
          alt="Программа развития креативности для ваших сотрудников"
        />
        <div className="px-mn-20 md:px-mn-26 3xl:px-mn-38 pt-[34px] md:pt-0">
          <B2bForm />
        </div>
        <div />
      </div>
    </section>
  );
};
