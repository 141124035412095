type Data = {
  isAuthenticate: boolean;
};

type ValueType<T extends keyof Data> = Data[T];

export interface ClientServerSharedData {
  KEY: Readonly<string>;
  data: Readonly<Data>;
  set: <T extends keyof Data>(key: T, value: ValueType<T>) => void;
  getObject: () => {
    isAuthenticate: boolean;
  };
}

export const SharedDataFactory: ClientServerSharedData = {
  KEY: "SHARED_CLIENT_SERVER_DATA",
  data: {
    isAuthenticate: false,
  },
  set(key, value) {
    if (key in this.data) {
      this.data = {
        ...this.data,
        [key]: value,
      };
    }
  },
  getObject() {
    return this.data;
  },
};
