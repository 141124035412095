import { createEffect, createEvent, createStore, sample } from "effector";
import { Hatch } from "framework";

import { $currentRoute, historyPush } from "@/entities/navigation";

type Tabs<T> = {
  tabs: T[];
  hatch: Hatch;
};

export const createTabs = <T extends string>({ tabs, hatch }: Tabs<T>) => {
  const tabChanged = createEvent<T>();
  const tabReset = createEvent();

  const changeRouteTabFx = createEffect(
    async ({ tab, currentLocation }: { tab: T; currentLocation: string }) => {
      await historyPush({ pathname: currentLocation, search: `?tab=${tab}` });
    },
  );

  const $tabParameter = hatch.$query.map((query) => query.tab as T);

  const $currentTab = createStore<T>(tabs[0])
    .on(tabChanged, (_, tab) => tab)
    .on($tabParameter, (currentTab, tab) => tab || currentTab)
    .on(hatch.update, (currentTab, { query }) => (query.tab ? currentTab : tabs[0]))
    .on(hatch.enter, () => tabs[0]);

  sample({
    clock: tabChanged,
    source: {
      tab: $currentTab,
      currentLocation: $currentRoute,
    },
    fn: ({ tab, currentLocation }) => ({ tab, currentLocation }),
    target: changeRouteTabFx,
  });

  return {
    $currentTab,
    tabChanged,
    tabReset,
  };
};
