import { TestData, TestType, VariantsAnswer, SingleTestStatus } from "@/entities/lesson";

import { CheckAnswerTest } from "../../types";
import { AnswerTest } from "../answer-test/answer-test";

type CourseLessonTestProps = {
  test: TestData;
  testId: string;
  setListSelectedAnswer: (checked: CheckAnswerTest) => void;
  testStatus: SingleTestStatus;
  selectedVariant: string[];
  isTestPassed: boolean;
};

const chooseAnswerMessage = {
  [TestType.Single]: "Выберите один ответ",
  [TestType.Multiple]: "Можно выбрать несколько вариантов ответов",
};

export const TestQuestion = ({
  test: { variants_answers, test_type, question },
  testId,
  testStatus,
  selectedVariant,
  setListSelectedAnswer,
  isTestPassed,
}: CourseLessonTestProps) => {
  const handleSelectAnswer = (checked: VariantsAnswer["id"][]) => {
    setListSelectedAnswer({
      testId,
      checkedAnswers: [...checked],
    });
  };

  return (
    <article>
      <h3 className="text-m-dark font-semibold text-max-3xl font-m-sb-display mb-mv-0">
        {question}
      </h3>
      <h4 className="text-m-dark-grey font-sans text-m-2xs xl:text-m-xs 3xl:text-m-base mb-mv-4">
        {chooseAnswerMessage[test_type]}
      </h4>
      <AnswerTest
        testStatus={testStatus}
        isTestPassed={isTestPassed}
        variantsAnswers={variants_answers}
        selectedAnswers={selectedVariant}
        setSelectedAnswers={handleSelectAnswer}
        type={test_type}
      />
    </article>
  );
};
