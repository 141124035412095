import { useGate, useEvent, useStore } from "effector-react/scope";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { DOMAIN_SITE, paths } from "@/pages/paths";
import {
  StudentCoursePageGate,
  TabEducationNames,
  tabsEducation,
} from "@/pages/student-courses/model";

import { Footer } from "@/widgets/footer";
import { Header } from "@/widgets/header";

import { COURSES_PAGE_CERTIFICATES_SECTION } from "@/shared/constants";
import { useIntersectionObserver } from "@/shared/lib/intersection-observer";
import { scrollToElement, useAnchorScroll } from "@/shared/lib/scroll";
import { TabMenu, ContentHeightLayout, SEO, Container, ScrollToTopButton } from "@/shared/ui";
import { Tab } from "@/shared/ui/components/tab/tab-menu";

import { StudentProfilePicture, togglePhotoEditHidden } from "@/feature/student-profile-form";

import { SCROLL_TO_TABS } from "./modules/constants";
import { StatusLessonChips } from "./modules/education";
import { CertificatesTab } from "./ui/certificates-tab";
import { CoursesTab } from "./ui/courses-tab";

export const StudentCoursesPage = () => {
  const location = useLocation();
  const queryTabParams = new URLSearchParams(location.search).get("tab");

  useGate(StudentCoursePageGate, { queryTabParams });

  const togglePhotoEditHiddenEvent = useEvent(togglePhotoEditHidden);

  const currentTab = useStore(tabsEducation.$currentTab);
  const changeTab = useEvent(tabsEducation.tabChanged);

  useAnchorScroll();

  useEffect(() => {
    togglePhotoEditHiddenEvent();
    return () => {
      togglePhotoEditHiddenEvent();
    };
  }, []);

  useEffect(() => {
    if (queryTabParams === COURSES_PAGE_CERTIFICATES_SECTION) {
      scrollToElement(COURSES_PAGE_CERTIFICATES_SECTION);
    }
  }, []);

  const { ref, inView } = useIntersectionObserver();

  const tabs: Tab[] = [
    {
      id: "courses",
      title: (
        <span className="font-m-sb-display text-m-sm md:text-m-xl xl:text-m-3xl 3xl:text-m-5xl">
          Мои курсы
        </span>
      ),
      content: <StatusLessonChips />,
    },
    {
      id: "certificates",
      title: (
        <span className="font-m-sb-display text-m-sm md:text-m-xl xl:text-m-3xl 3xl:text-m-5xl">
          Мои сертификаты
        </span>
      ),
    },
  ];

  const activeTab = tabs.find((t) => t.id === currentTab) as Tab;

  const catalog = {
    courses: <CoursesTab />,
    certificates: <CertificatesTab />,
  };

  return (
    <>
      <SEO
        title="Мое обучение. Муза — учитесь творчеству"
        description="Авторские курсы об искусстве, культуре и креативных индустриях"
        canonical={`${DOMAIN_SITE}${paths.studentCourses()}`}
      />
      <Header.Desktop />
      <Header.Mobile />
      <ContentHeightLayout className="bg-m-dark-blue pb-[36px]">
        <StudentProfilePicture />

        <section className="relative w-[100%] bg-m-dark-blue pt-mn-30 md:pt-mn-50 3xl:pt-mn-74">
          <Container mode="fixed">
            <div id={SCROLL_TO_TABS} className="w-full mx-auto mb-[42px] md:mb-[48px]">
              <div id="srollTarget" ref={ref} className="flex flex-col gap-y-6 md:gap-x-4">
                <TabMenu
                  tabs={tabs}
                  onSelect={(tab) => changeTab(tab.id as TabEducationNames)}
                  activeTab={activeTab}
                />
              </div>
            </div>
            {catalog[currentTab]}
          </Container>
        </section>
        <ScrollToTopButton targetId="srollTarget" hidden={inView} />
      </ContentHeightLayout>
      <Footer />
    </>
  );
};
