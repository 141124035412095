import clsx from "clsx";
import { ReactNode } from "react";

export type Tab = {
  id: string;
  title: string | ReactNode;
  content?: ReactNode;
};

type TabMenuProps = {
  tabs: Tab[];
  onSelect?: (tab: Tab) => void;
  activeTab?: Tab;
};

type TabHeaderProps = {
  tab: Tab;
  onClick: (tab: Tab) => void;
  className?: string;
  isActive: boolean;
};

type TabListProps = {
  tabs: TabMenuProps["tabs"];
  onSelect: (tab: Tab) => void;
  selectedTab: Tab;
};

const TabHeader = ({ tab, onClick, className, isActive }: TabHeaderProps) => {
  return (
    <button
      className={clsx(
        "px-mn-26 py-mn-10 xl:py-mn-16 text-m-3xl text-m-white rounded-tl-[20px] rounded-tr-[20px] font-m-sb-display font-bold",
        {
          "bg-m-blue": isActive,
          "bg-m-semi-dark hover:brightness-[.95]": !isActive,
        },
        className,
      )}
      role="tab"
      onClick={() => onClick(tab)}
    >
      {tab.title}
    </button>
  );
};

const TabContent = ({ children }: { children: ReactNode }) => {
  return (
    <section
      className="bg-m-blue rounded-tr-[20px] rounded-b-[20px] md:rounded-bl-none px-[17px] py-[18px] md:px-[32px] md:py-[32px]"
      role="tabpanel"
    >
      {children}
    </section>
  );
};

const TabList = ({ tabs, onSelect, selectedTab }: TabListProps) => {
  const tabItems = tabs.map((tab) => {
    return (
      <TabHeader
        className="mr-[7px]"
        key={tab.id}
        tab={tab}
        onClick={onSelect}
        isActive={selectedTab.id === tab.id}
      />
    );
  });

  return (
    <div role="tablist" aria-orientation="horizontal">
      {tabItems}
    </div>
  );
};

export const TabMenu = ({ tabs, onSelect, activeTab }: TabMenuProps) => {
  const selectedTab = activeTab?.id ? activeTab : tabs[0];

  const handleSelect = (tab: Tab) => {
    onSelect?.(tab);
  };

  const { content } = selectedTab;

  return (
    <div>
      <TabList tabs={tabs} onSelect={handleSelect} selectedTab={selectedTab} />
      {content && <TabContent>{content}</TabContent>}
      {!content && <div className="border-b-2 border-m-blue xl:border-b-[5px]" />}
    </div>
  );
};
