import { useEvent, useStore } from "effector-react/scope";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { Input, Modal, ButtonRounded } from "@/shared/ui";

import { authModalCloseClicked } from "@/app/authorization-modals/model";

import {
  $forgotPasswordError,
  forgotPasswordCodeSubmitted,
  passwordForgotFormSchema,
  PasswordForgotFormValues,
  $isPending,
  resetForgotPasswordError,
} from "../model/password-forgot";

export const PasswordForgot: FC = () => {
  const error = useStore($forgotPasswordError);
  const isPending = useStore($isPending);
  const isError = Boolean(error);
  const { control, handleSubmit, formState } = useForm<PasswordForgotFormValues>({
    mode: "all",
    defaultValues: {
      username: "",
    },
    resolver: yupResolver(passwordForgotFormSchema),
    delayError: 1000, // 1s
  });
  const handlers = useEvent({
    forgotPasswordCodeSubmitted,
    close: authModalCloseClicked,
    resetForgotPasswordError,
  });

  const isSubmitDisabled = isPending || formState.isSubmitting || !formState.isValid;

  return (
    <Modal.Surface>
      <Modal.Header close={handlers.close} />
      <Modal.Title>Забыли пароль?</Modal.Title>
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(handlers.forgotPasswordCodeSubmitted)();
          }}
          noValidate
        >
          <Controller
            name="username"
            control={control}
            render={({ fieldState, field }) => {
              return (
                <>
                  <Input
                    isError={fieldState.invalid || isError}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      if (isError) handlers.resetForgotPasswordError();
                    }}
                    placeholder="Введите адрес электронной почты"
                  />
                  <Input.Description
                    isError={Boolean(fieldState.error) || isError}
                    color="secondary"
                    className="m-typography-modal-caption"
                  >
                    {fieldState.error?.message || error || "Пришлём вам код для сброса пароля"}
                  </Input.Description>
                </>
              );
            }}
          />

          <ButtonRounded
            className="mt-5 mb-10 block m-auto"
            disabled={isSubmitDisabled}
            type="submit"
          >
            Отправить
          </ButtonRounded>
        </form>
      </Modal.Body>
    </Modal.Surface>
  );
};
