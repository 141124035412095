import { sample } from "effector";

import { analyticsModel, analyticsEvents } from "@/shared/analytics";

import { $authenticatedUserId, logout } from "./model/auth";

sample({
  clock: $authenticatedUserId,
  target: analyticsModel.$analyticsUserId,
});

sample({
  clock: logout,
  target: analyticsEvents.logout,
});
