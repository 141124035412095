import { useEvent } from "effector-react/scope";
import { FC } from "react";

import { openAuthModal } from "@/app/authorization-modals/model";
import { ConfirmationCodeModal, useConfirmCode } from "@/feature/confirm-code-modal";
import { useCountdownTimer } from "@/feature/countdown-button";
import {
  confirmCodePassword,
  countdownTimerResetPassword,
} from "@/feature/password-forgot/model/password-forgot";

export const PasswordForgotConfirmCode: FC = () => {
  const handlers = useEvent({
    close: openAuthModal.close,
    supportContactsOnPassword: openAuthModal.supportContactsOnPassword,
  });

  const confirmCodePasswordReset = useConfirmCode(confirmCodePassword);
  const countdownTimer = useCountdownTimer(countdownTimerResetPassword);

  return (
    <ConfirmationCodeModal
      onClose={handlers.close}
      onSupportContactsClick={handlers.supportContactsOnPassword}
      countdownTimerModel={countdownTimer}
      confirmCode={confirmCodePasswordReset}
    />
  );
};
