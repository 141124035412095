import clsx from "clsx";
import { useEvent, useStore } from "effector-react/scope";
import { FC, useEffect, useRef, useState } from "react";

import { useFilePicker } from "@/shared/lib/file-picker";
import { useCallbackOnEnter } from "@/shared/lib/keyboard";
import { useDevices } from "@/shared/lib/responsive";
import { AvatarEditor, ButtonRounded, ModalNotification, Modal, Range } from "@/shared/ui";
import { ReactComponent as Bin } from "@/shared/ui/assets/images/icons/bin.svg";
import { ReactComponent as MagnifyingGlassMinus } from "@/shared/ui/assets/images/icons/magnifying-glass-minus.svg";
import { ReactComponent as MagnifyingGlassPlus } from "@/shared/ui/assets/images/icons/magnifying-glass-plus.svg";

import { useUserProfilePictureOrDefault } from "../../lib/use-user-profile-picture-or-default";
import {
  studentProfilePictureChanged,
  studentProfilePictureDeleteAttempted,
  $isProfilePictureChangeSuccessful,
  resetProfilePictureUpdateSuccess,
  $isProfilePictureChangeFailed,
  resetProfilePictureUpdateError,
} from "../../model/profile-picture";
import styles from "./change-profile-picture-modal.module.scss";

type ChangeProfilePictureModalProps = {
  isOpen: boolean;
  close: () => void;
};

type RenderedModalProps = {
  close: () => void;
};

type AvatarEditorProps = {
  image: string;
  onChange: () => void;
};

type AvatarProps = {
  image: string;
};

const Avatar: FC<AvatarProps> = ({ image }) => (
  <img
    src={image}
    alt="avatar"
    className="object-cover h-[200px] w-[200px] 3xl:h-[300px] 3xl:w-[300px] bg-black rounded-full relative inline-block"
  />
);

const EditAvatar: FC<AvatarEditorProps> = ({ image, onChange }) => {
  const ref = useRef<AvatarEditor>(null);
  const [zoom, setZoom] = useState(50);
  const { isBigDesktop } = useDevices();

  const [reactEditorWidth, reactEditorHeight] = isBigDesktop ? [320, 320] : [200, 200];

  const onSlide = (value: number) => setZoom(value);
  const onMagnifyUp = () => setZoom((value) => value + 1);
  const onMagnifyDown = () => setZoom((value) => value - 1);

  const scale = zoom / 100 + 1;

  const saveImage = useEvent(studentProfilePictureChanged);

  const onSaveClick = () => {
    if (ref.current) {
      const imageDataURL = ref.current?.getImageScaledToCanvas().toDataURL();
      saveImage(imageDataURL);
    }
  };

  useCallbackOnEnter(onSaveClick);

  return (
    <>
      <AvatarEditor
        ref={ref}
        className="mx-auto mb-[30px]"
        image={image}
        width={reactEditorWidth}
        height={reactEditorHeight}
        borderRadius={9999}
        color={[0, 0, 0, 0.6]}
        scale={scale}
        rotate={0}
      />

      <div className="flex justify-between items-center">
        <button className={styles.Magnify} onMouseDown={onMagnifyDown}>
          <MagnifyingGlassMinus />
        </button>

        <Range className={styles.Slider} value={zoom} onChange={onSlide} />

        <button className={styles.Magnify} onMouseDown={onMagnifyUp}>
          <MagnifyingGlassPlus />
        </button>
      </div>

      <div className="flex mt-[28px] justify-center">
        <ButtonRounded className="font-semibold" onClick={onSaveClick}>
          Сохранить
        </ButtonRounded>
        <ButtonRounded className="font-semibold ml-[16px]" onClick={onChange} variant="secondary">
          Выбрать другое фото
        </ButtonRounded>
      </div>
    </>
  );
};

const RenderedModal: FC<RenderedModalProps> = ({ close }) => {
  const { userProfilePicture, userHasUploadedPicture } = useUserProfilePictureOrDefault();

  const [onUploadClick, { filesContent }] = useFilePicker({
    accept: "image/*",
    readAs: "DataURL",
    multiple: false,
  });

  const editableImage = filesContent.length > 0 ? filesContent[0].content : null;

  const onDeleteClick = useEvent(studentProfilePictureDeleteAttempted);
  const resetProfilePictureUpdateSuccessEvent = useEvent(resetProfilePictureUpdateSuccess);

  useEffect(() => {
    return resetProfilePictureUpdateSuccessEvent;
  }, []);

  useCallbackOnEnter(() => {
    if (!editableImage) {
      onUploadClick();
    }
  });

  return (
    <Modal.Surface>
      <Modal.Header close={close} />
      <Modal.Body className="text-center">
        {editableImage ? (
          <EditAvatar onChange={onUploadClick} image={filesContent[0].content} />
        ) : (
          <>
            <Avatar image={userProfilePicture} />
            <Modal.Caption>Добавьте изображение в формате PNG или JPEG</Modal.Caption>

            <div
              className={clsx("mt-[24px]", {
                "flex justify-center": userHasUploadedPicture,
              })}
            >
              <ButtonRounded className="font-semibold" variant="secondary" onClick={onUploadClick}>
                Выбрать фото
              </ButtonRounded>

              {userHasUploadedPicture && (
                <ButtonRounded
                  className="font-semibold ml-[16px]"
                  variant="legacy"
                  onClick={onDeleteClick}
                >
                  <span className="inline-flex items-center">
                    <Bin className="inline-block mr-[10px]" />
                    <span className="relative top-[1px]">Удалить фото</span>
                  </span>
                </ButtonRounded>
              )}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal.Surface>
  );
};

export const ChangeProfilePictureModal: FC<ChangeProfilePictureModalProps> = ({
  isOpen,
  close,
}) => {
  const isProfilePictureChangeSuccessful = useStore($isProfilePictureChangeSuccessful);
  const isProfilePictureChangeFailed = useStore($isProfilePictureChangeFailed);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const resetProfilePictureUpdateErrorEvent = useEvent(resetProfilePictureUpdateError);

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    resetProfilePictureUpdateErrorEvent();
  };

  useEffect(() => {
    if (isProfilePictureChangeSuccessful) {
      close();
    }
  }, [isProfilePictureChangeSuccessful]);

  useEffect(() => {
    if (isProfilePictureChangeFailed) {
      close();
      setIsErrorModalOpen(true);
    }
  }, [isProfilePictureChangeFailed]);

  return (
    <>
      <Modal render={RenderedModal} isOpen={isOpen} close={close} />
      <Modal
        render={() => (
          <ModalNotification
            type="error"
            onClose={close}
            message="Что-то пошло не так. Попробуйте повторить позднее"
          />
        )}
        isOpen={isErrorModalOpen}
        close={closeErrorModal}
      />
    </>
  );
};
