import { createEffect, sample } from "effector";

import { FavoritesEvents, toggle } from "@/shared/analytics/events/modules/favorites";
import { CourseApi } from "@/shared/api";

type LikeDispatchPayload = { courseId: string; value: boolean };
type CourseInfo = {
  id: string;
  title: string;
  courseFormat: string | null;
  marked: boolean;
};

const getCourseInfoFx = createEffect(async ({ courseId, value }: LikeDispatchPayload) => {
  const { id, courseFormat, title } = await CourseApi.getCourseByIdFx({ courseId });
  const result: CourseInfo = {
    id,
    title,
    courseFormat,
    marked: value,
  };

  return result;
});

sample({
  source: CourseApi.markCourseAsFavorite.done,
  fn: ({ params: id }) => ({ courseId: id, value: true }),
  target: getCourseInfoFx,
});

sample({
  source: CourseApi.unmarkCourseAsFavorite.done,
  fn: ({ params: id }) => ({ courseId: id, value: false }),
  target: getCourseInfoFx,
});

sample({
  source: getCourseInfoFx.doneData,
  fn: ({ courseFormat, id, title, marked }) => ({
    courseId: id,
    title,
    courseFormat,
    event_name: marked ? FavoritesEvents.MARK : FavoritesEvents.UNMARK,
  }),
  target: toggle,
});
