import { CourseFormatEnum, Level, Period, RecommendationFilter, TopicId } from "@app/api";

type Filter = {
  education: CourseFormatEnum | "any";
  level: Level | "any";
  period: Period | "any";
  topics: TopicId[];
  recommendation: RecommendationFilter;
};

export function filterBuilder() {
  const filters: Filter = {
    education: "any",
    level: "any",
    period: "any",
    topics: [],
    recommendation: "rec_off",
  };

  return {
    setEducation(education?: Filter["education"]) {
      if (!education) return this;

      filters.education = education;
      return this;
    },
    setLevel(level?: Filter["level"]) {
      if (!level) return this;

      filters.level = level;
      return this;
    },
    setPeriod(period?: Filter["period"]) {
      if (!period) return this;

      filters.period = period;
      return this;
    },
    setTopics(topics?: Filter["topics"] | string) {
      const currentTopics: string[] = [];
      const SEPARATOR = ",";

      if (!topics) return this;

      if (typeof topics === "string") {
        currentTopics.push(...topics.split(SEPARATOR));
      } else {
        currentTopics.push(...topics);
      }

      filters.topics = currentTopics;
      return this;
    },
    setRecommendation(recommendation?: RecommendationFilter) {
      if (!recommendation) return this;

      filters.recommendation = recommendation;
      return this;
    },
    build() {
      return filters;
    },
  };
}
