import { CourseFormatEnum, Period } from "@app/api";

export const filterLabels = {
  beginner: "Для новичков",
  professional: "PRO",
  [Period.LESS_THAN_1]: "До 1 месяца",
  [Period.FROM_1_TO_6]: "До полугода",
  [Period.MORE_THAN_6]: "Более 6 месяцев",
  [CourseFormatEnum.online_course]: "Курс",
  [CourseFormatEnum.program]: "Профессия",
  rec_on: "Рекомендации",
};
