import { LazyMotion } from "framer-motion";
import { ReactNode } from "react";

const domAnimation = () => import("framer-motion").then((res) => res.domAnimation);

type AnimationContainerProps = {
  children: ReactNode;
};
export const AnimationContainer = ({ children }: AnimationContainerProps) => (
  <LazyMotion features={domAnimation}>{children}</LazyMotion>
);
