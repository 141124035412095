import { memo } from "react";

import { paths } from "@/pages/paths";

import { Course, CourseCard } from "@/entities/course";

import { ButtonRounded } from "@/shared/ui";

import { useCourseCardAnalytics } from "../analytics";
import { GoToCourseView } from "./go-to-course-view";

type CardCourseViewProps = {
  course: Course;
};

export const CardCourseView = memo(
  ({ course }: CardCourseViewProps) => {
    const cardAnalytics = useCourseCardAnalytics();

    const onCardClick = () => {
      cardAnalytics.cardClicked({
        courseId: course.id,
        formatCourse: course.courseFormat,
        courseName: course.title,
        price: Number(course.definedTermPrice),
        linkText: `${course.title} - карточка курса`,
        linkUrl: paths.courseView(course.id),
      });
    };

    return (
      <GoToCourseView onClick={onCardClick} courseId={course.id}>
        <CourseCard course={course} hoveredNode={<ButtonRounded>Смотреть</ButtonRounded>} />
      </GoToCourseView>
    );
  },
  (prev, next) => prev.course.id === next.course.id,
);
