import clsx from "clsx";

/* eslint-disable import/no-duplicates */
import React, { ChangeEventHandler, ComponentProps, FC, forwardRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import InputMask, { Props } from "react-input-mask";

import { ReactComponent as PasswordHiddenIcon } from "@/shared/ui/assets/images/icons/eye_closed_outline.svg";
import { ReactComponent as PasswordShownIcon } from "@/shared/ui/assets/images/icons/eye_outline.svg";

import { CodeInput } from "./code-input";
import { InputDate } from "./date-input";
import style from "./style.module.scss";

interface DescriptionProps {
  color?: "primary" | "secondary";
  isError?: boolean;
  className?: string;
}

const Description: FC<DescriptionProps> = ({
  children,
  color = "primary",
  isError = false,
  className,
}) => {
  return (
    <p
      className={clsx(className, [
        style.Description,
        style[`color-${color}`],
        isError && style.isErrorDescription,
      ])}
    >
      {children}
    </p>
  );
};

type ExcludeHTMLInputAttributes = "value" | "onChange" | "type" | "size";

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, ExcludeHTMLInputAttributes> {
  value: string;
  onChange: ChangeEventHandler<{ value: string }>;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  isError?: boolean;
  type?: "tel" | "password" | "email" | "text" | "date";
  color?: "primary" | "secondary";
  size?: "small" | "normal";
}

const InputBase = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      type,
      onChange,
      placeholder,
      className,
      maxLength = undefined,
      isError = false,
      color = "primary",
      size = "normal",
      disabled,
      ...rest
    },
    ref,
  ) => {
    return (
      <input
        aria-label="input"
        disabled={disabled}
        className={clsx([
          style.Input,
          style[`color-${color}`],
          style[`size-${size}`],
          disabled && style.disabled,
          isError && style.isErrorInput,
          className,
        ])}
        placeholder={placeholder}
        type={type}
        value={value}
        maxLength={maxLength}
        onChange={onChange}
        ref={ref}
        {...rest}
      />
    );
  },
);

interface PasswordProps extends Omit<InputProps, "type" | "color"> {
  hasShowButton?: boolean;
}

const Password = forwardRef<HTMLInputElement, PasswordProps>(function Input(
  {
    hasShowButton,
    size = "normal",
    isError = false,
    placeholder,
    onBlur,
    onChange,
    defaultValue,
    value,
    name,
    autoComplete,
    ...rest
  },
  ref,
) {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const togglePassword = () => setPasswordVisible(!passwordVisible);

  return (
    <div
      className={clsx([style.Password, style[`size-${size}`], isError && style.isErrorPassword])}
    >
      <input
        ref={ref}
        type={passwordVisible ? "text" : "password"}
        autoComplete={autoComplete}
        defaultValue={defaultValue}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        {...rest}
      />

      {hasShowButton ? (
        <button type="button" onClick={togglePassword}>
          {passwordVisible ? <PasswordShownIcon /> : <PasswordHiddenIcon />}
        </button>
      ) : null}
    </div>
  );
});

export type MaskedInputProps = {
  maskOptions: Props;
} & ComponentProps<typeof InputBase>;

export const MaskedInput = (props: MaskedInputProps) => {
  const { maskOptions, onBlur, value, onChange, ...rest } = props;
  return (
    <InputMask {...maskOptions} value={value} onBlur={onBlur} onChange={onChange}>
      {() => <InputBase {...rest} value={value} onChange={onChange} />}
    </InputMask>
  );
};

export const Input = InputBase as typeof InputBase & {
  Description: typeof Description;
  Password: typeof Password;
  InputDate: typeof InputDate;
  Code: typeof CodeInput;
  Masked: typeof MaskedInput;
};
Input.Description = Description;
Input.Password = Password;
Input.InputDate = InputDate;
Input.Code = CodeInput;
Input.Masked = MaskedInput;
