import { BodyScrollOptions, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { MutableRefObject, useEffect } from "react";

import { isClient } from "@/shared/lib/is-client";

export function useScrollLock(
  enabled: boolean,
  ref: MutableRefObject<HTMLElement | null>,
  options?: BodyScrollOptions,
) {
  useEffect(() => {
    if (!isClient) return;

    if (!enabled) return;
    const element = ref.current;
    if (!element) return;
    const storedScrollY = window.scrollY;

    disableBodyScroll(element, options);
    document.body.style.setProperty("top", `${window.scrollY * -1}px`);

    const { scrollTop } = window.document.documentElement;
    const prevBodyWidth = window.document.body.style.width;
    window.document.body.style.width = "100%";

    return () => {
      enableBodyScroll(element);
      document.body.style.setProperty("top", "");
      document.body.scrollTo(0, storedScrollY);
      window.document.body.style.width = prevBodyWidth;
      window.document.documentElement.scrollTo({ top: scrollTop });
    };
  }, [enabled, ref]);
}
