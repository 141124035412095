import clsx from "clsx";
import { useEvent, useStore } from "effector-react/scope";
import { KeenSliderInstance } from "keen-slider";
import { useState, useEffect } from "react";

import loadable from "@loadable/component";

import { paths } from "@/pages/paths";

import { $courseIds } from "@/widgets/slider-category-courses/model/course-ids-by-names";

import { historyPush } from "@/entities/navigation";

import {
  Container,
  SliderControls,
  LinkWithArrow,
  ButtonRounded,
  ButtonRoundedProps,
  Typography,
} from "@/shared/ui";
import { SliderBulletsRectangle } from "@/shared/ui/components/slider/slider-attributes";

import { watchCourseClicked } from "../analytics";
import { SlideItem, content as defaultContent, content as libContent } from "../lib/content";
import styles from "./styles.module.scss";

export const BaseCarousel = loadable(
  () => import("@/shared/ui").then((component) => component.BaseCarousel),
  {
    ssr: true,
    fallback: (
      <picture className="h-full w-full">
        <source srcSet={libContent[0].image.srcS} media="(max-width: 640px)" />
        <source srcSet={libContent[0].image.srcM} media="(max-width: 1280px)" />
        <source srcSet={libContent[0].image.srcL} media="(min-width: 1920px)" />
        <img
          height={1200}
          width="100%"
          className="w-full object-cover"
          src={libContent[0].image.srcL}
          alt=""
        />
      </picture>
    ),
  },
);

type CategorySliderProps = {
  className?: string;
  slideClassName?: string;
  slideImageClassName?: string;
  slideContentContainerClassName?: string;
  controlsClassName?: string;
  content?: SlideItem[];
  onSliderReady?: () => void;
  containerClassName?: string;
  controlType?: "arrow" | "button";
  controlVariant?: ButtonRoundedProps["variant"];
  controlText?: string;
  onButtonPrevClick?: () => void;
  onButtonNextClick?: () => void;
};

type CategorySlideProps = SlideItem & {
  imageClassName?: string;
  contentContainerClassName?: string;
  controlType?: "arrow" | "button";
  controlVariant?: ButtonRoundedProps["variant"];
  controlText?: string;
};

const CategorySlide = ({
  image,
  alt,
  text,
  courseId,
  link,
  imageClassName,
  onClick,
  itemId,
  contentContainerClassName,
  controlType = "arrow",
  controlVariant,
  controlText = "Смотреть",
}: CategorySlideProps) => {
  const handlers = useEvent({ historyPush, watchCourseClicked });
  const courseIds = useStore($courseIds);

  const handleGoToCourse = () => {
    if (onClick && itemId) {
      onClick(itemId);
    }

    if (courseIds && courseId) {
      handlers.watchCourseClicked({ courseId });
      handlers.historyPush({ pathname: paths.courseView(courseIds[text.plainTitle]) });
    }

    if (link) window.location.href = link;
  };

  return (
    <div
      onKeyDown={handleGoToCourse}
      className={clsx(styles.wrapper)}
      tabIndex={0}
      role="link"
      onClick={handleGoToCourse}
    >
      <Container>
        <div className={contentContainerClassName}>
          <div className="mb-mn-16 md:mb-mn-20 3xl:mb-mn-30">
            <div className="text-m-arctic m-typography-label leading-[120%] font-m-sb-display font-bold uppercase">
              {text.category}
            </div>
          </div>

          <div className="mb-mn-16 md:mb-mn-20 3xl:mb-mn-30 xl:w-full">
            <div className="font-m-sb-display m-typography-h2 font-bold text-left text-m-white uppercase">
              {text.title}
            </div>
          </div>

          <div className="mb-mn-20 md:mb-mn-38 3xl:mb-mn-57">
            <div className={clsx("text-m-white m-typography-text xl:w-[50vw] w-[90vw]")}>
              {text.paragraph}
            </div>
          </div>

          {controlType === "arrow" && (
            <div tabIndex={0} onKeyDown={handleGoToCourse} role="link" onClick={handleGoToCourse}>
              <LinkWithArrow text={controlText} arrowClassName="cursor-pointer" />
            </div>
          )}

          {controlType === "button" && (
            <ButtonRounded data-testid={`big-slide_${alt.split(" ").join("-")}`} variant={controlVariant} onClick={handleGoToCourse}>
              {controlText}
            </ButtonRounded>
          )}
        </div>
      </Container>
      <picture className="h-full w-full">
        {image.srcS && <source srcSet={image.srcS} media="(max-width: 767px)" />}
        {image.srcM && <source srcSet={image.srcM} media="(max-width: 1280px)" />}
        <source srcSet={image.srcL} media="(min-width: 1920px)" />
        <img height={1200} width="100%" className={imageClassName} src={image.srcL} alt={alt} />
      </picture>
    </div>
  );
};

export const SliderCategoryCourses = ({
  slideImageClassName,
  slideContentContainerClassName,
  controlsClassName,
  content = defaultContent,
  onSliderReady,
  containerClassName,
  controlType,
  controlVariant,
  controlText,
  onButtonPrevClick,
  onButtonNextClick,
}: CategorySliderProps) => {
  const [sliderInstance, setSliderInstance] = useState<KeenSliderInstance | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [heightInitialized, setHeightInitialized] = useState(false);

  useEffect(() => {
    if (sliderInstance && sliderInstance.size > 0 && !heightInitialized) {
      if (onSliderReady) onSliderReady();
      setHeightInitialized(true);
    }
  }, [sliderInstance?.size]);

  return (
    <BaseCarousel
      enablePlugins={["autoplay"]}
      getActiveSlideIndex={setActiveSlide}
      getInstanceRef={setSliderInstance}
      containerClassName={containerClassName}
      data={content.map((contentData) => (
        <CategorySlide
          controlVariant={controlVariant}
          controlType={controlType}
          controlText={controlText}
          contentContainerClassName={slideContentContainerClassName}
          key={contentData.courseId}
          imageClassName={slideImageClassName}
          {...contentData}
        />
      ))}
    >
      {sliderInstance && (
        <div className={controlsClassName}>
          <SliderControls
            navigationRenderPreset="empty"
            renderBetweenControls={
              <SliderBulletsRectangle
                data={content}
                activeSlide={activeSlide}
                setSliderInstance={(value: number) => sliderInstance.moveToIdx(value)}
              />
            }
            onSlideNext={() => {
              onButtonNextClick?.();
              sliderInstance?.next();
            }}
            onSlidePrev={() => {
              onButtonPrevClick?.();
              sliderInstance?.prev();
            }}
            controlsClass="!bg-m-white hidden md:flex"
            classNameContainer="child:text-m-orange"
          />
        </div>
      )}
    </BaseCarousel>
  );
};
