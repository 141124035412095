import { ButtonStartSubscription } from "@/widgets/payment";

import { Breakpoint } from "@/shared/lib/responsive";
import { Image, Section } from "@/shared/ui";
import certificate375 from "@/shared/ui/assets/images/home/certificate_375.webp";
import certificate1920 from "@/shared/ui/assets/images/home/certificate_1920.webp";

import style from "./section-to-courses.module.scss";

export const SectionToCourses = () => {
  return (
    <Section
      className={`${style.Background} xl:h-[630px] 2xl:h-[920px] py-mv-8 xl:py-0 bg-m-dark-blue`}
    >
      <Section.Content className="relative flex flex-col h-full flex-wrap text-m-white font-semibold text-m-xl xl:text-2xl 2xl:text-m-5xl max-w-[100vw] justify-center">
        <div className="flex flex-col xl:flex-row items-center">
          <p className="text-center block xl:hidden xl:text-left xl:text-m-xl 2xl:text-m-5xl font-m-sb-display mb-mv-6 w-[312px]">
            Смотри видео, выполняй задания и получай образовательный сертификат для подтверждения
            знаний
          </p>

          <div className="mx-auto flex items-center xl:my-0 xl:mr-[90px] relative xl:-left-[48px] mb-mv-7">
            <Image
              lazy
              className={`h-auto w-[345px] xl:w-[510px] 2xl:w-[689px] min-w-0 ${style.Image}`}
              src={certificate1920}
              containerClassName={`flex-shrink-0 h-auto w-[345px] xl:w-[510px] 2xl:w-[689px] min-w-0 ${style.Image}`}
              screens={[
                {
                  breakpoint: Breakpoint.S,
                  src: certificate375,
                },
                {
                  breakpoint: Breakpoint.M,
                  src: certificate1920,
                },
              ]}
            />
          </div>

          <div>
            <p className="mb-mv-5 text-center hidden xl:block xl:text-left xl:text-m-xl 2xl:text-m-5xl font-m-sb-display font-medium">
              Смотри видео, выполняй задания <br /> и получай образовательный <br /> сертификат для
              подтверждения знаний
            </p>
            <ButtonStartSubscription analytics={{ positionInfo: "middle" }} />
          </div>
        </div>
      </Section.Content>
    </Section>
  );
};
