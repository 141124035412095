import { Typography } from "@/shared/ui";

export const ErrorMessage = ({
  message = "Поле не заполнено",
  isShow,
}: {
  message?: string;
  isShow: boolean;
}) => {
  return (
    <div className="pb-[6px] min-h-[17px] mt-[4px] flex items-center 3xl:min-h-[30px]">
      {isShow && (
        <Typography tag="span" size="m-caption" className="text-m-magenta">
          {message}
        </Typography>
      )}
    </div>
  );
};
