import { useEvent, useStoreMap } from "effector-react/scope";
import React, { MouseEventHandler } from "react";

import { ReactComponent as HeartIcon } from "@/shared/ui/assets/images/icons/heard-outline-gradient.svg";
import { ReactComponent as HeartFilledIcon } from "@/shared/ui/assets/images/icons/heart-filled.svg";

import { $favouriteCoursesIds, courseFavouriteButtonClicked } from "../education/model/education";

export const LikeButton = ({
  courseId,
  className,
  iconClassName,
}: {
  courseId: string;
  className?: string;
  iconClassName?: string;
}) => {
  const handlers = useEvent({ courseFavouriteButtonClicked });

  const isActive = useStoreMap({
    store: $favouriteCoursesIds,
    keys: [courseId],
    fn: (favouritesIds, [id]) => favouritesIds.includes(id),
  });

  const onClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    return handlers.courseFavouriteButtonClicked(courseId);
  };

  const Icon = isActive ? HeartFilledIcon : HeartIcon;

  return (
    <button className={className} onClick={onClick} aria-label="Добавить в избранное">
      <Icon className={iconClassName} />
    </button>
  );
};
