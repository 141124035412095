import React, { ReactNode } from "react";
import { Helmet } from "react-helmet-async";

type OpenGraphMetaProps = {
  title: string;
  description?: string;
  children?: ReactNode;
  canonical?: string;
  image?: string;
};

export const SEO = ({ title, description, children, canonical, image }: OpenGraphMetaProps) => {
  return (
    <Helmet defaultTitle={title} title={title}>
      <meta name="og:title" content={title} />
      <meta name="description" content={description} />
      {image && <meta name="og:image" content={image} />}
      {description && <meta name="og:description" content={description} />}
      {canonical && <link rel="canonical" href={canonical} />}
      {children}
    </Helmet>
  );
};
