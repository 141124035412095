import { Pages } from "@/pages/pages";
import { PrivateRoute } from "@/pages/private-route";

import { CookieInformationBanner } from "@/widgets/cookie-information-banner";
import { PaymentModal } from "@/widgets/payment/ui/payment-modal";

import { useRefreshToken } from "@/entities/user";

import { analyticsModel } from "@/shared/analytics";
import { useMindboxScript } from "@/shared/lib/mindbox/use-mindbox-script";
import { useWindowScrollHandler } from "@/shared/lib/scroll";
import { useUsedeskScript } from "@/shared/lib/usedesk/usedesk-script";

import { AuthorizationModals } from "@/app/authorization-modals";
import { ApplicationMeta } from "@/app/meta";
import { UserAgreementModal } from "@/feature/sign-in/ui/user-agreement-modal";

import "./application.css";
import { appStarted } from "./model/app-start";
import "./usedesk.css";

analyticsModel.initAnalytics();
appStarted();

export const Application = () => {
  useRefreshToken();
  useMindboxScript();
  useUsedeskScript();
  useWindowScrollHandler();

  return (
    <main>
      <CookieInformationBanner />
      <ApplicationMeta />
      <Pages />
      <PrivateRoute />
      <AuthorizationModals />
      <PaymentModal />
      <UserAgreementModal />
    </main>
  );
};
