import { useEvent, useStoreMap } from "effector-react/scope";
import { memo, MouseEvent, ReactNode, useCallback } from "react";

import { ReactComponent as HeartFilledIcon } from "@/shared/ui/assets/images/icons/heart-filled.svg";
import { ReactComponent as HeartIcon } from "@/shared/ui/assets/images/icons/heart.svg";

import { $favoriteCoursesIds, toggledCourseFavorite } from "../model/favorite-courses";

export interface LikeButtonProps {
  className?: string;
  iconClassName?: string;
  courseId: string;
  renderIcon?: (isActive: boolean) => ReactNode;
}

export const LikeButton = memo(
  ({ className, iconClassName, courseId, renderIcon }: LikeButtonProps) => {
    const handlers = useEvent({ toggledCourseFavorite });

    const isActive = useStoreMap({
      store: $favoriteCoursesIds,
      keys: [courseId],
      fn: (ids, [id]) => ids.includes(id),
    });

    const handleAddToFavorites = useCallback(() => {
      handlers.toggledCourseFavorite({ id: courseId, isToggled: isActive });
    }, [courseId, isActive]);

    const Icon = isActive ? HeartFilledIcon : HeartIcon;

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      handleAddToFavorites();
    };

    return (
      <button aria-label="Добавить в избранное" className={className} onClick={handleClick}>
        {renderIcon ? renderIcon(isActive) : <Icon className={iconClassName} />}
      </button>
    );
  },
);
