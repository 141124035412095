import { createStore, createEvent, sample } from "effector";
import { interval } from "patronum/interval";

const TIMEOUT = 1000;
const TIMEOUT_SECONDS = 60;

type CreateCountdownTimerParams = {
  timeout?: number;
  timeoutSeconds?: number;
};

export const createCountdownTimer = ({
  timeout = TIMEOUT,
  timeoutSeconds = TIMEOUT_SECONDS,
}: CreateCountdownTimerParams) => {
  const preventCountdownStart = createEvent();
  const startCountdown = createEvent<number>();
  const stopInterval = createEvent();
  const startInterval = createEvent();

  const { isRunning, tick } = interval({
    timeout,
    start: startInterval,
    stop: stopInterval,
  });

  const $counterStartLocked = createStore(false);
  const $timeout = createStore(timeoutSeconds).on(tick, (seconds) => seconds - 1);

  $counterStartLocked.on(preventCountdownStart, () => true);

  sample({
    source: $timeout,
    filter: (seconds) => seconds === 0,
    target: stopInterval,
  });

  sample({
    source: startCountdown,
    target: $timeout,
  });

  sample({
    source: $counterStartLocked,
    clock: startCountdown,
    fn: (counterStartLocked) => !counterStartLocked,
    target: startInterval,
  });

  return {
    preventCountdownStart,
    startCountdown,
    startInterval,
    stopInterval,
    $timeout,
    $countdownDisabled: isRunning,
  };
};

export type CountdownTimerModel = ReturnType<typeof createCountdownTimer>;
