import { useEvent, useStore } from "effector-react/scope";
import { FC } from "react";

import { SupportContacts } from "@/widgets/support-contacts-modal";

import { Modal, ModalRenderer } from "@/shared/ui";

import {
  DeleteStudentProfileConfirmCodeErrorModal,
  DeleteStudentProfileConfirmCodeModal,
  DeleteStudentProfileModal,
} from "@/feature/delete-student-profile";
import {
  ProfilePasswordChange,
  PasswordForgot,
  PasswordForgotConfirmCode,
  PasswordReset,
  clickedForgotPassword,
  PasswordChangeSuccess,
  ResetPasswordConfirmCode,
} from "@/feature/password-forgot";
import { PasswordForgotError } from "@/feature/password-forgot/ui/password-forgot-error";
import { SignIn } from "@/feature/sign-in";
import {
  SignUpConfirmed,
  SignUp,
  SignUpError,
  openSignUpModal,
  loginUser,
  SignUpConfirmationCode,
  SignUpConfirmationError,
} from "@/feature/sign-up";

import { authModalCloseClicked, AUTH_MODAL_TYPE, openAuthModal } from "../model";

const AuthorizationRenderer: ModalRenderer = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const modalType = useStore(openAuthModal.$ModalType)!;
  const closeModal = useEvent(authModalCloseClicked);
  const openModal = useEvent(openSignUpModal);
  const forgotPassword = useEvent(clickedForgotPassword);

  const neededModal = {
    [AUTH_MODAL_TYPE.signIn]: (
      <SignIn onClickGoSignUp={openModal} onClickForgotPassword={forgotPassword} />
    ),
    [AUTH_MODAL_TYPE.signUp]: <SignUp />,
    [AUTH_MODAL_TYPE.signUpError]: <SignUpError onClose={closeModal} />,
    [AUTH_MODAL_TYPE.signUpConfirmCode]: <SignUpConfirmationCode />,
    [AUTH_MODAL_TYPE.signUpConfirmationError]: <SignUpConfirmationError onClose={closeModal} />,
    [AUTH_MODAL_TYPE.confirmedSignUp]: <SignUpConfirmed />,
    [AUTH_MODAL_TYPE.forgotPassword]: <PasswordForgot />,
    [AUTH_MODAL_TYPE.forgotPasswordError]: <PasswordForgotError onClose={closeModal} />,
    [AUTH_MODAL_TYPE.forgotPasswordConfirmCode]: <PasswordForgotConfirmCode />,
    [AUTH_MODAL_TYPE.resetPasswordConfirmCode]: <ResetPasswordConfirmCode />,
    [AUTH_MODAL_TYPE.resetNewPassword]: <PasswordReset />,
    [AUTH_MODAL_TYPE.changePasswordSuccess]: <PasswordChangeSuccess />,
    [AUTH_MODAL_TYPE.profilePasswordChange]: <ProfilePasswordChange />,
    [AUTH_MODAL_TYPE.supportContactsOnPassword]: <SupportContacts onClose={closeModal} />,
    [AUTH_MODAL_TYPE.supportContactsOnSignUp]: (
      <SupportContacts
        onClose={() => {
          closeModal();
        }}
      />
    ),
    [AUTH_MODAL_TYPE.supportContactsOnDelete]: <SupportContacts onClose={closeModal} />,
    [AUTH_MODAL_TYPE.deleteUser]: <DeleteStudentProfileModal />,
    [AUTH_MODAL_TYPE.deleteUserConfirmCode]: <DeleteStudentProfileConfirmCodeModal />,
    [AUTH_MODAL_TYPE.deleteUserConfirmCodeError]: <DeleteStudentProfileConfirmCodeErrorModal />,
  };

  return neededModal[modalType];
};

export const AuthorizationModals: FC = () => {
  const modalType = useStore(openAuthModal.$ModalType);
  const handlers = useEvent({ authModalCloseClicked, closeModalQuietly: openAuthModal.close });
  const login = useEvent(loginUser);
  const isConfirmedSignup = modalType === AUTH_MODAL_TYPE.confirmedSignUp;

  const handleCloseModal = () => {
    if (isConfirmedSignup) {
      login();
      handlers.closeModalQuietly();
    } else {
      handlers.authModalCloseClicked();
    }
  };

  return (
    <Modal isOpen={Boolean(modalType)} close={handleCloseModal} render={AuthorizationRenderer} />
  );
};
