import clsx from "clsx";
import React from "react";

import { ReactComponent as CloseIcon } from "@/shared/ui/assets/images/icons/close.svg";
import { Title } from "@/shared/ui/components/modal/components/typography";

import { CloseCallback, DivProps } from "../types";
import style from "./style.module.scss";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type Size = "normal" | "medium" | "small";

export type HeaderBoxProps = {
  size?: Size;
} & DivProps;

export type CloseProps = {
  close: CloseCallback;
  size?: Size;
  iconSize?: string;
  iconClassName?: string;
} & Omit<ButtonProps, "color">;

export type HeaderProps = {
  title?: string;
  close?: CloseCallback;
  iconSize?: string;
  iconClassName?: string;
} & HeaderBoxProps;

export const HeaderBox: React.FC<HeaderBoxProps> = ({ className, children, ...rest }) => (
  <header className={clsx(style.Header, className)} {...rest}>
    {children}
  </header>
);

export const Close: React.FC<CloseProps> = ({
  className,
  close,
  iconSize = 18.5,
  iconClassName,
  ...rest
}) => (
  <button className={clsx(style.Close, className)} onClick={close} aria-label="close" {...rest}>
    <CloseIcon className={iconClassName} height={iconSize} width={iconSize} />
  </button>
);

export const Header: React.FC<HeaderProps> = ({
  children,
  title,
  close,
  iconSize,
  iconClassName,
  ...rest
}) => (
  <HeaderBox {...rest}>
    {children}
    {!children && title && <Title className="mb-0">{title}</Title>}
    {close && (
      <Close className="ml-auto" close={close} iconSize={iconSize} iconClassName={iconClassName} />
    )}
  </HeaderBox>
);
