import clsx from "clsx";
import { useStore } from "effector-react/scope";
import { memo } from "react";
import { useParams } from "react-router-dom";

import { BuyCourseButton } from "@/widgets/payment";

import { CurrentCourseId } from "@/entities/course/types";

import { useDevices } from "@/shared/lib/responsive";

import { CourseShare } from "@/feature/course-share";
import { LikeButton } from "@/feature/favorite-courses";

import { $currentCourse } from "../../model";
import { CoursePlayer } from "../course-video";

export const CourseVideoPlayerWidget = memo(() => {
  const { courseId }: CurrentCourseId = useParams();
  const { isMobile } = useDevices();

  const currentCourse = useStore($currentCourse);

  return (
    <>
      <CoursePlayer />
      {isMobile && (
        <div className={clsx("py-mv-5 px-mv-4 flex justify-end w-grid-sm mx-auto")}>
          {currentCourse && <BuyCourseButton className="mr-[31px]" course={currentCourse} />}

          <div className="flex">
            <LikeButton
              className="flex justify-center items-center button-init border-[1px] border-m-blue border-solid rounded-[7px] overflow-hidden background-transparent text-m-blue hover:border-m-orange w-[30px] h-[30px] transition-colors mr-3"
              iconClassName="scale-[0.8]"
              courseId={courseId}
            />

            {currentCourse && (
              <CourseShare
                course={currentCourse}
                buttonClassName="text-m-blue border-m-blue border-[1px]"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
});
