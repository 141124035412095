import { useEvent } from "effector-react/scope";

import { ModalNotification } from "@/shared/ui";

import { loginUser } from "../model/sign-up";

export const SignUpConfirmed = () => {
  const login = useEvent(loginUser);

  return (
    <ModalNotification
      onClose={login}
      type="success"
      title="Регистрация прошла успешно"
      renderButtons={[
        {
          title: "ОК",
          variant: "secondary",
          onClick: login,
        },
      ]}
    />
  );
};
