import { createEvent, createStore, sample } from "effector";

import { AuthApi } from "@/shared/api";
import { DEFAULT_API_ERROR_MESSAGE } from "@/shared/api/constants";
import { everyBecameTruthy } from "@/shared/lib/effector";
import { Difficulty, getPasswordDifficulty, passwordRegex } from "@/shared/lib/form";

import { openAuthModal } from "@/app/authorization-modals/model";

import { $forgotPasswordUserToken } from "./password-forgot";

export const resetNewPasswordFx = AuthApi.resetNewPassword;

const clearNewPasswordValues = createEvent();
export const resetPasswordValueChanged = createEvent<string>();
export const resetPasswordChecked = createEvent();
export const resetNewPasswordSubmitted = createEvent();

export const $resetPasswordValue = createStore("")
  .on(resetPasswordValueChanged, (_, value) => value)
  .reset(clearNewPasswordValues);

export const $resetPasswordDifficulty = createStore<Difficulty>(Difficulty.DEFAULT)
  .on(resetPasswordValueChanged, (_, value) => getPasswordDifficulty(value))
  .reset(clearNewPasswordValues);

export const $isResetPasswordIncorrect = createStore(false)
  .on($resetPasswordDifficulty.updates, (_, difficulty) => difficulty === Difficulty.LOW)
  .reset(clearNewPasswordValues);

export const $resetPasswordError = createStore("").reset(
  resetNewPasswordFx.done,
  clearNewPasswordValues,
);

export const $isResetPasswordSucceeded = createStore(false)
  .on(resetNewPasswordFx.done, () => true)
  .reset([openAuthModal.close, openAuthModal.changePasswordSuccess]);

sample({
  clock: resetNewPasswordSubmitted,
  source: $resetPasswordValue,
  fn: (password) => !passwordRegex.test(password),
  target: $isResetPasswordIncorrect,
});

sample({
  clock: resetNewPasswordSubmitted,
  source: $isResetPasswordIncorrect,
  filter: (isResetPasswordIncorrect) => !isResetPasswordIncorrect,
  target: resetPasswordChecked,
});

sample({
  clock: resetPasswordChecked,
  source: { password: $resetPasswordValue, token: $forgotPasswordUserToken },
  target: resetNewPasswordFx,
});

sample({
  clock: everyBecameTruthy([$isResetPasswordSucceeded]),
  target: openAuthModal.changePasswordSuccess,
});

sample({
  clock: resetNewPasswordFx.failData,
  fn: () => DEFAULT_API_ERROR_MESSAGE,
  target: $resetPasswordError,
});

sample({
  clock: resetNewPasswordFx.fail,
  target: openAuthModal.resetNewPassword,
});

// clear new password values
sample({
  clock: [openAuthModal.close, resetNewPasswordFx.done],
  target: clearNewPasswordValues,
});
