interface Config {
  IMAGE_PROXY_URL: string;
  BASE_API_AUTH_URL: string;
  BROWSER_GQL_API_URL: string;
  SERVER_GQL_API_URL: string;
  BROWSER_REST_API_URL: string;
  SERVER_REST_API_URL: string;
  INITIAL_STATE_NAME: string;
  BUILD_ON_SERVER: boolean;
  BUILD_ON_CLIENT: boolean;
  IS_DEV: boolean;
  IS_PROD: boolean;
  GTM_ID: string;
  CDN_REPLACE: string;
  CDN_PREFIX: string;
  CLIENT_GQL_API_URL: string;
  CLIENT_REST_API_URL: string;
  DOMAIN: string;
  MINDBOX_ENDPOINT_ID: string;
  REST_API_AUTH: string;
  SITE_URL: string;
  SERVER_BFF_URL: string;
  LOGGER: "on" | "off";
  ENVIRONMENT: string;
  SENTRY_DSN: string;
  SENTRY_RELEASE: string;
  SENTRY_ORGANIZATION: string;
  POSTHOG_URL: string;
  POSTHOG_KEY: string;
  PAYMENT_API_URL: string;
  AUTH_API_VERSION_V3: string;
  AUTH_API_VERSION_V3_1: string;
  AMAZEME_API_URL: string;
}

export const CLIENT_CONFIG_NAME = `${process.env.GENERATED_UNIQUE_VALUE}`;
export const BUILD_ENVIRONMENT = process.env.BUILD_TARGET;
export const BUILD_ON_CLIENT = BUILD_ENVIRONMENT === "client";
export const BUILD_ON_SERVER = BUILD_ENVIRONMENT === "server";
const IS_DEV = process.env.NODE_ENV === "development";
const IS_PROD = process.env.NODE_ENV === "production";

export const CONFIG: Config = {
  IMAGE_PROXY_URL: resolveVariable("IMAGE_PROXY_URL"),
  BASE_API_AUTH_URL: resolveVariable("REST_API_URL"),
  BROWSER_GQL_API_URL: resolveVariable("BROWSER_GQL_API_URL"),
  SERVER_GQL_API_URL: resolveVariable("SERVER_GQL_API_URL"),
  BROWSER_REST_API_URL: resolveVariable("BROWSER_REST_API_URL"),
  SERVER_REST_API_URL: resolveVariable("SERVER_REST_API_URL"),
  INITIAL_STATE_NAME: resolveVariable(
    "INITIAL_STATE_NAME",
    (Math.random() + 1).toString(36).substring(2, 7),
  ),
  BUILD_ON_CLIENT,
  BUILD_ON_SERVER,
  IS_DEV,
  IS_PROD,
  GTM_ID: resolveVariable("GTM_ID"),
  CDN_REPLACE: resolveVariable("CDN_REPLACE"),
  CDN_PREFIX: resolveVariable("CDN_PREFIX"),
  CLIENT_GQL_API_URL: resolveVariable("CLIENT_GQL_API_URL"),
  CLIENT_REST_API_URL: resolveVariable("CLIENT_REST_API_URL"),
  DOMAIN: resolveVariable("DOMAIN"),
  MINDBOX_ENDPOINT_ID: resolveVariable("MINDBOX_ENDPOINT_ID"),
  REST_API_AUTH: resolveVariable("REST_API_AUTH") || "",
  SITE_URL: resolveVariable("SITE_URL") || "https://localhost:3000",
  LOGGER: resolveVariable("LOGGER") || "on",
  SERVER_BFF_URL: resolveVariable("SERVER_BFF_URL"),
  ENVIRONMENT: resolveVariable("ENVIRONMENT"),
  SENTRY_DSN: resolveVariable("SENTRY_DSN"),
  SENTRY_RELEASE: resolveVariable("SENTRY_RELEASE"),
  SENTRY_ORGANIZATION: resolveVariable("SENTRY_ORGANIZATION"),
  POSTHOG_URL: resolveVariable("POSTHOG_URL"),
  POSTHOG_KEY: resolveVariable("POSTHOG_KEY"),
  PAYMENT_API_URL: resolveVariable("PAYMENT_API_URL"),
  AUTH_API_VERSION_V3: "v3",
  AUTH_API_VERSION_V3_1: "v3.1",
  AMAZEME_API_URL: resolveVariable("AMAZEME_API_URL"),
};

export const IS_QA_ENVIRONMENT = CONFIG.ENVIRONMENT === "qa";
export const IS_DEV_ENVIRONMENT = CONFIG.ENVIRONMENT === "dev";
export const IS_PROD_ENVIRONMENT = CONFIG.ENVIRONMENT === "prod";

function resolveVariable(name: string, defaultValue = "") {
  if (BUILD_ON_SERVER) {
    return process.env[name] || defaultValue;
  }
  if (BUILD_ON_CLIENT) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any)[CLIENT_CONFIG_NAME][name] || defaultValue;
  }
  return defaultValue;
}
