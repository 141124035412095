import { Component, ErrorInfo, ReactNode } from "react";

import * as Sentry from "@sentry/react";

import { Logo } from "@/shared/ui";

type Props = { children: ReactNode };

const ErrorLayout = () => (
  <div className="h-screen bg-m-dark-blue text-center p-[30px] flex flex-col justify-center">
    <Logo.Static className="w-[82px] mx-auto mb-[19px] lg:mb-[27px]" />

    <h1 className="mx-auto font-semibold text-m-xl leading-[20px] xl:text-m-5xl xl:leading-8 3xl:text-m-6xl text-m-white uppercase font-m-sb-display">
      Совсем скоро здесь будет вдохновение, <br /> а пока ведутся технические работы
    </h1>

    <div className="text-white text-m-sm leading-[18px] mx-auto mt-[16px] xl:mt-[24px] xl:text-m-lg 3xl:text-m-3xl">
      <p>Приносим извинения за&nbsp;доставленные&nbsp;неудобства.</p>

      <p className="mt-[8px]">
        Для связи с техподдержкой напишите&nbsp;на&nbsp;
        <a href="mailto:muse-help@edutoria.ru" className="underline text-m-orange">
          muse-help@edutoria.ru
        </a>
      </p>
    </div>
  </div>
);

export class ErrorBoundary extends Component<Props, { error: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  static componentDidCatch(error: Error, { componentStack }: ErrorInfo): void {
    Sentry.captureException(error, { contexts: { react: { componentStack } } });
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <ErrorLayout />;
    }

    const { children } = this.props;
    return children;
  }
}
