import { FC, useCallback } from "react";
import { FieldValues, UseFormHandleSubmit } from "react-hook-form";

import { trimSpaceAndDash } from "@/shared/lib/strings";

import { NameInputProps, FirstNameInput, LastNameInput } from "./user-form-input";

type Props<T extends FieldValues> = {
  setName: (value: string) => void;
  getName: () => string;
  handleSubmit: UseFormHandleSubmit<T>;
};

type UseNameInputReturnType<T extends FieldValues> = {
  Input: FC<NameInputProps>;
  handleSubmit: UseFormHandleSubmit<T>;
};

const useNameInput = <T extends FieldValues>(
  props: Props<T>,
  NameInput: FC<NameInputProps>,
): UseNameInputReturnType<T> => {
  const Input = useCallback(
    ({ onBlur, ...xs }: NameInputProps) => (
      <NameInput
        {...xs}
        onBlur={(event) => {
          props.setName(trimSpaceAndDash(event.currentTarget.value));
          onBlur?.(event);
        }}
      />
    ),
    [],
  );

  const handleSubmit: UseFormHandleSubmit<T> = (handler) =>
    props.handleSubmit((values) => {
      props.setName(trimSpaceAndDash(props.getName()));
      return handler(values);
    });

  return { Input, handleSubmit };
};

export const useFirstNameInput = <T extends FieldValues>(props: Props<T>) =>
  useNameInput(props, FirstNameInput);

export const useLastNameInput = <T extends FieldValues>(props: Props<T>) =>
  useNameInput(props, LastNameInput);
