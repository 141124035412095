import { createEffect, createEvent, sample } from "effector";

import { $authenticatedUserId } from "@/entities/user";
import { userPurchasesModel } from "@/entities/user-purchases";

import { UserApi } from "@/shared/api";

export const readyToRemoveUserPaymentCard = createEvent<string>();

export const deleteUserCardFx = createEffect(UserApi.deleteUserCard);

sample({
  clock: readyToRemoveUserPaymentCard,
  source: userPurchasesModel.$userCardsInfo,
  fn: (cards, id) => cards.filter((card) => card.paymentMethodId !== id),
  target: userPurchasesModel.$userCardsInfo,
});

sample({
  clock: readyToRemoveUserPaymentCard,
  target: deleteUserCardFx,
});

sample({
  clock: deleteUserCardFx.finally,
  source: $authenticatedUserId,
  target: [
    userPurchasesModel.getUserCardsInfoFx,
    userPurchasesModel.getUserPaymentMethodFx,
    userPurchasesModel.getUserProductsFx,
  ],
});
