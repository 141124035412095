import clsx from "clsx";
import { ReactNode } from "react";

import styles from "./empty-layout.module.scss";

type EmptyLayoutProps = {
  children: ReactNode;
  className?: string;
};

export const EmptyDataLayout = ({ children, className }: EmptyLayoutProps) => {
  return (
    <div
      className={clsx(
        styles.emptyLayout,
        className,
        "h-[304px] xl:h-[327px] -my-[30px] xl:-my-[25px]",
      )}
    >
      {children}
    </div>
  );
};
