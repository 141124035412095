import clsx from "clsx";
import { ReactNode, useEffect, useRef } from "react";

import { isReactElement } from "@/shared/lib/is-react-element";
import { ButtonRounded as Button, Input, Modal, Typography } from "@/shared/ui";

import { ConfirmCode } from "@/feature/confirm-code-modal/model/confirm-code.connection";
import { CountdownButton, CountdownTimer } from "@/feature/countdown-button";

import styles from "./confirmation-code.module.scss";

type Props = {
  onClose?: () => void;
  onSupportContactsClick?: () => void;
  countdownTimerModel: CountdownTimer;
  confirmCode: ConfirmCode;
  description?: string | ReactNode;
  sender?: "phone" | "email";
};

export const ConfirmationCodeModal = ({
  confirmCode,
  onClose,
  onSupportContactsClick,
  countdownTimerModel,
  description,
  sender = "email",
}: Props) => {
  const isError = Boolean(confirmCode.error);
  const shouldHighlightError = isError && confirmCode.code.length === 0;

  const authCodeRef = useRef({ clear: () => undefined, focus: () => undefined });

  const onAuthCodeChange = (value?: string) => {
    const code = value ?? "";
    confirmCode.change(code);
  };

  const isSubmitDisabled = confirmCode.code.length !== 6;

  useEffect(() => {
    if (isError && confirmCode.code.length === 0) {
      authCodeRef.current.clear();
    }
  }, [isError, confirmCode]);

  const senderText = {
    phone: "номер телефона",
    email: "e-mail",
  };

  return (
    <Modal.Surface>
      <Modal.Header close={onClose} />
      <Modal.Body className={styles.ModalBody}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            confirmCode.submit();
          }}
          noValidate
        >
          <div className="text-xs m:text-sm text-center">
            <Typography tag="h6" size="m-h6" className="uppercase font-bold mb-mn-10">
              Введите код подтверждения
            </Typography>
            {isReactElement(description) ? (
              description
            ) : (
              <Modal.Description>{description}</Modal.Description>
            )}
            <div className="mt-5 mb-2">
              <Input.Code
                ref={authCodeRef}
                allowedCharacters="numeric"
                containerClassName="flex justify-center"
                inputClassName={styles.Input}
                onChange={onAuthCodeChange}
                isError={shouldHighlightError}
              />
            </div>

            <div className="text-center">
              <Input.Description
                isError={shouldHighlightError}
                className="w-[235px] sm:w-[280px] 2xl:w-[330px] m-auto min-h-[24px] sm:min-h-[32px] 2xl:min-h-[40px] m-typography-modal-caption"
              >
                {shouldHighlightError ? (
                  confirmCode.error
                ) : (
                  <span>
                    Код выслан на указанный {senderText[sender]} и&nbsp;действует в течение 5 минут
                  </span>
                )}
              </Input.Description>
            </div>
          </div>
          <Modal.Buttons className="mt-6 flex justify-center">
            <Button disabled={isSubmitDisabled} type="submit">
              Отправить
            </Button>

            <CountdownButton
              countdownTimerModel={countdownTimerModel}
              onClick={confirmCode.sendAgain}
              variant="legacy"
              className="font-bold tracking-tight whitespace-nowrap"
              countdownText="повтор через"
            >
              прислать код ещё раз
            </CountdownButton>
          </Modal.Buttons>

          {onSupportContactsClick && (
            <div className="text-center mt-6 pb-[30px] text-white">
              <button
                className={clsx(
                  styles.CodeDidNotArriveButton,
                  "m-typography-modal-input underline",
                )}
                onClick={onSupportContactsClick}
              >
                Не приходит код?
              </button>
            </div>
          )}
        </form>
      </Modal.Body>
    </Modal.Surface>
  );
};
