import React, { memo } from "react";

import { Container } from "@/shared/ui";

import {
  FilterDuration,
  FilterLevel,
  FilterRecommendation,
  FilterTopicChips,
  FilterTypeEducation,
  useFilter,
  useTopicFilters,
} from "@/feature/filters";

import {
  topicFilter,
  educationFilter,
  levelFilter,
  periodFilter,
  recommendationFilter,
} from "../../../model";
import { Anchor } from "../../shared";

const FilterTypeEducationPanel = () => {
  const filterEducation = useFilter(educationFilter);

  return (
    <FilterTypeEducation
      value={filterEducation.value}
      onChange={filterEducation.change}
      isDisabled={filterEducation.isDisabled}
    />
  );
};

const FilterLevelPanel = () => {
  const filterLevel = useFilter(levelFilter);

  return (
    <FilterLevel
      value={filterLevel.value}
      onChange={filterLevel.change}
      isDisabled={filterLevel.isDisabled}
    />
  );
};

const FilterDurationPanel = () => {
  const filterDuration = useFilter(periodFilter);

  return (
    <FilterDuration
      value={filterDuration.value}
      onChange={filterDuration.change}
      isDisabled={filterDuration.isDisabled}
    />
  );
};

export const FilterRecommendationPanel = () => {
  const filterRecommendation = useFilter(recommendationFilter);

  return (
    <FilterRecommendation
      value={filterRecommendation.value}
      onChange={filterRecommendation.change}
    />
  );
};

export const filters = [
  <FilterTypeEducationPanel />,
  <FilterDurationPanel />,
  <FilterLevelPanel />,
  <FilterRecommendationPanel />,
];

export const FilterTopics = memo(() => {
  const filterTopic = useTopicFilters(topicFilter);

  return (
    <>
      <div id={Anchor.Catalog} />
      <Container mode="fixed">
        <div className="mb-mn-30 md:mb-mn-50 mt-mn-30 3xl:mt-[45px] md:max-w-[80vw]">
          <FilterTopicChips
            topics={filterTopic.allTopics}
            onClick={filterTopic.selectTopic}
            selectedTopics={filterTopic.selectedTopics}
            isDisabled={filterTopic.isDisabled}
          />
        </div>
      </Container>
    </>
  );
});
