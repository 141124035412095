import { FC, useEffect } from "react";

import { ButtonRounded, ButtonRoundedProps } from "@/shared/ui";

import { CountdownTimer } from "../model/countdown-timer.connection";

interface CountdownButtonProps extends ButtonRoundedProps {
  countdownText?: string;
  countdownTimerModel: CountdownTimer;
}

export const CountdownButton: FC<CountdownButtonProps> = ({
  countdownText,
  onClick,
  children,
  disabled,
  countdownTimerModel,
  ...rest
}) => {
  useEffect(() => {
    return countdownTimerModel.stopInterval;
  }, []);

  return (
    <ButtonRounded
      {...rest}
      disabled={disabled || countdownTimerModel.countdownDisabled}
      onClick={() => {
        onClick?.();
      }}
    >
      <div className="py-2 m:py-0">
        {countdownTimerModel.countdownDisabled
          ? `${countdownText} ${countdownTimerModel.timeout} сек`
          : children}
      </div>
    </ButtonRounded>
  );
};
