import { createAnalyticsEvent } from "../../model/analytics";

enum TheatrePageEvents {
  CLICK_COURSE = "click_course",
  VIEW_COURSE_COMPILATION = "view_course_compilation",
  CLICK_SUBSCRIBE = "click_subscribe",
}

type ClickCoursePayload = {
  course_id: string;
  format_course: string;
  course_name: string;
  link_text: string;
  link_url: string;
};

export const clickCourse = createAnalyticsEvent((payload: ClickCoursePayload) => ({
  event_name: TheatrePageEvents.CLICK_COURSE,
  course_id: payload.course_id,
  format_course: payload.format_course,
  course_name: payload.course_name,
  link_text: payload.link_text,
  link_url: payload.link_url,
  link_type: "theatre",
}));

export const viewCourseCompilation = createAnalyticsEvent(() => ({
  event_name: TheatrePageEvents.VIEW_COURSE_COMPILATION,
  link_type: "theatre",
}));

export const clickSubscribe = createAnalyticsEvent(({ link_text }: { link_text: string }) => ({
  event_name: TheatrePageEvents.CLICK_SUBSCRIBE,
  link_text,
  link_type: "theatre",
}));
