import { createEffect, createStore, sample } from "effector";
import { createGate } from "effector-react";

import { Program } from "@app/api";

import { sortProgramsOnDate } from "@/entities/program";

import { ProgramApi } from "@/shared/api";

export const OurProgramsGate = createGate();

const getProgramsFx = createEffect(async () => {
  const program = await ProgramApi.getPrograms({});
  return sortProgramsOnDate(program);
});

export const $program = createStore<Program[]>([]).on(
  getProgramsFx.doneData.map((courses) => courses),
  (_, courses) => courses,
);

export const $isProgramLoading = getProgramsFx.pending;

sample({
  clock: OurProgramsGate.open,
  target: getProgramsFx,
});
