import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { paths } from "@/pages/paths";

import logo from "@/shared/ui/assets/images/logo/logo_muse.svg";

import fullMuseLogo from "../../assets/images/logo/full_logo.svg";
import { ReactComponent as LogoSvgCondensed } from "../../assets/images/logo/logo_muse_condensed.svg";

type LogoProps = {
  className?: string;
  variant?: "normal" | "condensed" | "mobile" | "full";
};

const getVariants = (variant: LogoProps["variant"], className: string) => {
  const variants = {
    condensed: <LogoSvgCondensed className={className} />,
    full: <img src={fullMuseLogo} className={className} alt="logo" />,
  };

  if (variant)
    return (
      variants[variant] ?? (
        <img src={logo} className={`h-[14px] w-[62px] ${className}`} alt="logo" />
      )
    );

  return <img src={logo} className={`h-[14px] w-[62px] ${className}`} alt="logo" />;
};

const Static = ({ className = "", variant = "normal" }: LogoProps) => {
  return getVariants(variant, className);
};

const Link = ({ className, variant, onClick }: LogoProps & { onClick?: () => void }) => (
  <RouterLink to={paths.home()} onClick={onClick}>
    <Static {...{ className, variant }} />
  </RouterLink>
);

export const Logo = {
  Link,
  Static,
};
