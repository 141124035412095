import { useEvent, useStore } from "effector-react/scope";
import React, { FC, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { paths } from "@/pages/paths";

import { DifficultyCheck } from "@/entities/user";

import { Difficulty, DifficultyCaptions, getPasswordDifficulty } from "@/shared/lib/form";
import { removeSpaces } from "@/shared/lib/string";
import { ButtonRounded, Checkbox, Input, Modal, NavButton } from "@/shared/ui";
import { Link } from "@/shared/ui/components/navigation/link";

import { authModalCloseClicked } from "@/app/authorization-modals/model";
import { SberIdAuthButton } from "@/feature/sberid-auth";
import { openSignInModal } from "@/feature/sign-in";

import {
  $isEmailInUseError,
  $isPending,
  $signUpError,
  SignUpFormData,
  signUpFormSchema,
  submittedSignUpForm,
} from "../model/sign-up";

export const SignUp: FC = () => {
  const signUpError = useStore($signUpError);
  const isEmailInUseError = useStore($isEmailInUseError);
  const isPending = useStore($isPending);
  const [passwordDifficulty, setPasswordDifficulty] = useState<Difficulty>(Difficulty.DEFAULT);
  const closeModal = useEvent(authModalCloseClicked);
  const openSignIn = useEvent(openSignInModal);
  const submitSignUpForm = useEvent(submittedSignUpForm);
  const { control, handleSubmit, formState, setError } = useForm<SignUpFormData>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      subscribed: false,
    },
    resolver: yupResolver(signUpFormSchema),
    delayError: 1000, // 1s
  });

  const passwordChanged: (password: string) => void = (password) => {
    setPasswordDifficulty(getPasswordDifficulty(password));
  };

  const isSubmitDisabled = isPending || formState.isSubmitting || !formState.isValid;

  useEffect(() => {
    if (signUpError && isEmailInUseError) {
      setError("email", { message: signUpError });
    }
  }, [setError, isEmailInUseError, signUpError]);

  return (
    <Modal.Surface className="h-screen md:h-auto pb-mn-38">
      <Modal.Header close={closeModal} />
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(submitSignUpForm)();
          }}
          noValidate
        >
          <Controller
            name="email"
            control={control}
            render={({ fieldState, field }) => {
              return (
                <>
                  <Input
                    isError={fieldState.invalid}
                    type="email"
                    placeholder="Адрес электронной почты"
                    value={field.value}
                    onChange={(e) => field.onChange(removeSpaces(e.target.value))}
                    className="mb-3"
                  />
                  <Input.Description
                    className="m-typography-modal-caption"
                    isError={fieldState.invalid}
                    color="secondary"
                  >
                    {fieldState.error?.message || "Пришлём код подтверждения"}
                  </Input.Description>
                </>
              );
            }}
          />
          <Controller
            name="password"
            control={control}
            render={({ fieldState, field }) => {
              return (
                <>
                  <Input.Password
                    autoComplete="new-password"
                    placeholder="Придумайте пароль"
                    isError={fieldState.invalid}
                    value={field.value}
                    hasShowButton
                    onChange={(e) => {
                      field.onChange(removeSpaces(e.target.value));
                      passwordChanged(e.target.value);
                    }}
                  />
                  <Input.Description
                    className="m-typography-modal-caption"
                    isError={fieldState.invalid}
                    color="secondary"
                  >
                    Пароль должен содержать не&nbsp;менее 8&nbsp;символов, буквы латинского алфавита
                    (a&mdash;z и A&mdash;Z), цифры (0&ndash;9), а&nbsp;также специальные символы
                    (!@№#^$%&*). Не&nbsp;используйте пробел в&nbsp;пароле.
                  </Input.Description>
                </>
              );
            }}
          />
          <DifficultyCheck
            difficulty={passwordDifficulty}
            difficultyCaptions={[
              DifficultyCaptions.DEFAULT,
              DifficultyCaptions.LOW,
              DifficultyCaptions.MEDIUM,
              DifficultyCaptions.HIGH,
            ]}
            description={
              passwordDifficulty === 0 ? "Проверка надежности пароля" : "Надежность пароля — "
            }
          />

          {Boolean(signUpError) && !isEmailInUseError && (
            <Input.Description className="m-typography-modal-caption" isError>
              {signUpError}
            </Input.Description>
          )}

          <div className="pt-2 ys flex items-center">
            <Controller
              name="subscribed"
              control={control}
              render={({ field }) => {
                return (
                  <Checkbox
                    id="subscribed"
                    labelClassName="text-m-dark-grey m-typography-modal-caption"
                    checked={field.value}
                    onChange={() => field.onChange(!field.value)}
                    title={
                      <Modal.Caption className="text-m-grey mv-0">
                        Даю согласие на рассылку по e-mail
                      </Modal.Caption>
                    }
                  />
                );
              }}
            />
          </div>

          <ButtonRounded
            className="my-4 mt-6 block m-auto"
            disabled={isSubmitDisabled}
            type="submit"
          >
            Зарегистрироваться
          </ButtonRounded>
          <div className="w-[183px] mx-auto my-mv-3">
            <div className="flex items-center px-[4px] text-m-dark-grey">
              <div className="grow h-[1px] bg-m-dark-grey" />
              <div className="px-[25px] m-typography-modal-caption">или</div>
              <div className="grow h-[1px] bg-m-dark-grey" />
            </div>
          </div>
          <div className="text-center">
            <SberIdAuthButton />
          </div>
          <div className="mt-4 flex items-center justify-center">
            <Modal.Caption className="text-center text-m-dark-grey">
              <span>Продолжая, вы соглашаетесь с </span>
              <Link
                rel="noopener noreferrer"
                to={paths.termsOfUseAgreement()}
                nativeLink
                target="_blank"
                display="inline"
                underline
                className="text-m-dark-grey"
              >
                Пользовательским соглашением
              </Link>
              <span> и&nbsp;</span>
              <Link
                rel="noopener noreferrer"
                to={paths.termsOfUsePrivacyPolicy()}
                nativeLink
                target="_blank"
                display="inline"
                className="text-m-dark-grey"
                underline
              >
                Положением&nbsp;о&nbsp;конфиденциальности
              </Link>
            </Modal.Caption>
          </div>
          <div className="flex items-center justify-center">
            <span className="mr-2 text-m-white m-typography-modal-input">Есть аккаунт?</span>
            <NavButton className="m-typography-modal-input underline" onClick={() => openSignIn()}>
              Войти
            </NavButton>
          </div>
        </form>
      </Modal.Body>
    </Modal.Surface>
  );
};
