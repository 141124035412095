import { useStore } from "effector-react/scope";

import { Section } from "@/shared/ui";

import { CourseShare } from "@/feature/course-share";
import { LikeButton } from "@/feature/favorite-courses";

import { $courseId, $courseImage, $courseName, $speaker, $currentCourse } from "../../model";

export const CourseAnnounce = () => {
  const courseTitle = useStore($courseName);
  const courseId = useStore($courseId);
  const speaker = useStore($speaker);
  const image = useStore($courseImage);

  const currentCourse = useStore($currentCourse);

  return (
    <Section className="bg-m-semi-dark min-h-[328px]">
      <Section.Content className="pt-mv-5 sm:pt-mv-6 2xl:pt-mv-9 flex flex-col xl:flex-row justify-between pb-mv-8 xl:pb-[55px]">
        <div className="flex flex-col basis-[45%]">
          <div className="order-3 xl:order-1 font-m-sb-display font-semibold text-m-base xl:text-m-xl text-m-white uppercase mb-mn-10 xl:mb-mn-20 text-center xl:text-left">
            Скоро
          </div>
          <h2 className="order-1 xl:order-2 uppercase font-semibold gradient-text text-[20px] xl:text-[28px] 2xl:text-m-6xl leading-none mb-[6px] xl:mb-mn-10 text-center xl:text-left">
            {courseTitle}
          </h2>
          {speaker && (
            <h3 className="order-2 xl:order-3 text-m-white text-m-xs 2xl:text-m-base mb-mv-4 xl:mb-mn-20 text-center xl:text-left">{`${speaker?.name} ${speaker?.surname}, ${speaker?.shortDescription}`}</h3>
          )}
          <div className="flex order-last justify-center xl:justify-start mb-mv-5 xl:mb-0">
            <LikeButton
              courseId={courseId}
              className="bg-m-white hover:bg-m-white-80 rounded-md text-m-orange !border-none !p-0 w-mn-30 h-mn-30 mr-m-2"
              iconClassName="h-[20px] w-[20px] mx-auto"
            />
            {currentCourse && (
              <CourseShare
                course={currentCourse}
                buttonClassName="bg-m-white hover:bg-m-white-80 text-m-orange w-mn-30 h-mn-30 border-none"
              />
            )}
          </div>
        </div>
        <div className="basis-[35.67%] shrink-0 overflow-hidden rounded-lg">
          <img src={image} alt={courseTitle} />
        </div>
      </Section.Content>
    </Section>
  );
};
