import { useEvent, useStore } from "effector-react/scope";

import loadable from "@loadable/component";

import { Modal } from "@/shared/ui";

import { $isModalCertificateOpen, certificateModalClosed } from "../../model/certificate-issueing";

const CertificateForm = loadable(
  () => import("./form").then((component) => component.CertificateForm),
  {
    ssr: false,
  },
);

export const CertificateModal = () => {
  const isOpenModal = useStore($isModalCertificateOpen);
  const close = useEvent(certificateModalClosed);

  return (
    <Modal
      isOpen={isOpenModal}
      close={close}
      closeOnClickOverlay={false}
      render={() => (
        <Modal.Surface>
          <Modal.Header close={close} />
          <Modal.Body>
            <CertificateForm />
          </Modal.Body>
        </Modal.Surface>
      )}
    />
  );
};
