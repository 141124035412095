import { createEvent, createStore, sample } from "effector";
import { delay } from "patronum/delay";
import { pending } from "patronum/pending";
import { boolean, object } from "yup";

import { userFormSchema } from "@/entities/user";

import { AuthApi } from "@/shared/api";
import { DEFAULT_API_ERROR_MESSAGE } from "@/shared/api/constants";
import { errorMessages } from "@/shared/lib/form";

import { openAuthModal } from "@/app/authorization-modals/model";
import { signInUserFx } from "@/feature/sign-in";

export const signUpError = {
  invalid_password:
    "Пароль должен содержать от 8 до 50 символов, включая строчные и прописные латинские буквы, цифры. Не используйте пробел в пароле",
  invalid_role: "Ошибка доступа. Повторите вход",
  invalid_email_format: "Неверный формат e-mail",
  invalid_phone_format: "Неверный формат телефона",
  requst_user_creation_failed: "Ошибка создания аккаунта. Попробуйте ещё раз",
  request_failed: "Ошибка создания аккаунта. Попробуйте ещё раз",
  user_exists: "Такой аккаунт уже существует",
  action_not_created_timeout: "Ошибка доступа. Повторите попытку позже",
};

const errorModalCase: Set<keyof typeof signUpError> = new Set([
  "requst_user_creation_failed",
  "request_failed",
  "invalid_role",
  "action_not_created_timeout",
]);

export interface SignUpFormData {
  email: string;
  password: string;
  subscribed: boolean;
}

export const signUpFormSchema = object().shape({
  email: userFormSchema.email.required(errorMessages.required),
  password: userFormSchema.password.required(errorMessages.required),
  subscribed: boolean(),
});

export const openSignUpModal = createEvent<boolean | void>();
export const submittedSignUpForm = createEvent<SignUpFormData>();

export const signUpUserFx = AuthApi.fetchSignUp;

export const $signUpError = createStore("").reset([
  signUpUserFx,
  signUpUserFx.doneData,
  openAuthModal.close,
]);
export const $isEmailInUseError = $signUpError.map((text) => text === signUpError.user_exists);

export const $signUpPayload = createStore<Nullable<AuthApi.RequestConfirmCodeData>>(null).on(
  signUpUserFx.doneData.map((payload) => ({
    timeout: payload.timeout,
    token: payload.token,
  })),
  (_, payload) => payload,
);

sample({
  source: openSignUpModal,
  target: openAuthModal.signUp,
});

sample({
  source: openSignUpModal,
  filter: (redirect): redirect is boolean => redirect === false,
  target: openAuthModal.setIsRedirectToProfile,
});

sample({
  source: submittedSignUpForm,
  target: signUpUserFx,
});

export const $isPending = pending({
  effects: [signUpUserFx],
});

sample({
  source: signUpUserFx.doneData,
  target: openAuthModal.signUpConfirmCode,
});

sample({
  source: signUpUserFx.failData,
  fn: ({ type }) => {
    return signUpError[type] ?? DEFAULT_API_ERROR_MESSAGE;
  },
  target: $signUpError,
});

sample({
  clock: signUpUserFx.failData,
  filter: ({ type }) => {
    if (!signUpError[type]) return true;
    return errorModalCase.has(type as keyof typeof signUpError);
  },
  target: openAuthModal.signUpError,
});

export const loginUser = createEvent();

// noinspection JSVoidFunctionReturnValueUsed
const loginAfterCodeConfirmation = sample({
  clock: loginUser,
  source: signUpUserFx.done,
  fn: ({ params }) => {
    return { username: params.email, password: params.password };
  },
});

delay({
  source: loginAfterCodeConfirmation,
  timeout: 1000,
  target: signInUserFx,
});
