import { useEffect } from "react";
import { Link } from "react-router-dom";

import { paths } from "@/pages/paths";

import { scrollToTop } from "@/shared/lib/scroll/scroll-to-top";
import { ButtonRounded, Container, Section } from "@/shared/ui";

export const NotFound = () => {
  useEffect(() => {
    if (typeof window === "undefined") return;
    scrollToTop();
  }, []);

  return (
    <Section className="text-center text-m-white font-semibold">
      <Container>
        <div className="mt-20 md:mb-[93px] mb-[214px] md:mt-36 3xl:mt-[122px]">
          <h1 className="gradient-text w-min mx-auto text-[92px] leading-[106px] md:text-[150px] md:leading-[172px] 3xl:text-[232px] 3xl:leading-[1] md:-mb-[0.1em]">
            404
          </h1>
          <h2 className="text-m-xl md:text-m-3xl 3xl:text-4xl">
            Скоро здесь будет <br /> вдохновение и <br className="block md:hidden" /> творчество
          </h2>
          <Link to={paths.catalog()}>
            <ButtonRounded
              className="mt-mv-5 h-[30px] md:h-[30px] px-mv-5 font-semibold"
              variant="secondary"
              fontSize="m-xs"
            >
              К курсам
            </ButtonRounded>
          </Link>
        </div>
      </Container>
    </Section>
  );
};
