import { useEvent, useGate, useStore } from "effector-react/scope";
import { useState } from "react";

import { getTrialState, paymentModel, TrialState } from "@/entities/payment";
import {
  $paymentCourseType,
  $subscriptionsPayment,
  paymentTypeChanged,
} from "@/entities/payment/model/payment";
import { PaymentType } from "@/entities/payment/types";
import { userPurchasesModel } from "@/entities/user-purchases";

import { EVENT_FORMATS } from "@/shared/api/modules/event";
import { ButtonRounded, Checkbox, Modal, SkeletonLoader, Typography } from "@/shared/ui";

import {
  $aboutSpeaker,
  courseChosen,
  paymentModalCloseClicked,
  paymentModalOpened,
  PaymentModalType,
  SubscriptionsGate,
} from "../model/payment-management";

const SelectPayTypes = {
  subscription: "subscription",
  onlyCourse: "onlyCourse",
} as const;

type SelectPay = keyof typeof SelectPayTypes | null;

const checkBoxLabelCss =
  "relative top-[3px] text-m-white font-m-sb-display font-semibold m-typography-input leading-[140%]";

const checkArrowClassName = "w-[15px] h-[11px]";

const SkeletonLoaderCheckboxes = () => {
  return (
    <>
      <div className="flex items-center">
        <SkeletonLoader className="h-[30px] w-[30px] mr-[24px]" variant="blue" />
        <SkeletonLoader className="h-[30px]" containerClassName="w-full" variant="blue" />
      </div>

      <div className="flex items-center">
        <SkeletonLoader className="h-[30px] w-[30px] mr-[24px]" variant="blue" />
        <SkeletonLoader className="h-[30px]" containerClassName="w-full" variant="blue" />
      </div>
    </>
  );
};

const FirstCheckboxLabel = ({ trialState }: { trialState: TrialState }) => {
  const subscriptionInfo = useStore($subscriptionsPayment);
  const trialPeriodString = subscriptionInfo[0].trialInfo.fullPeriodString;

  if (
    trialState === TrialState.TRIAL_INACTIVE_BUT_HAS_SUBSCRIPTION ||
    trialState === TrialState.TRIAL_NOT_USED
  ) {
    return (
      <span>
        Получить <span className="text-m-arctic">{trialPeriodString} бесплатного доступа</span>{" "}
        <br className="hidden xl:block" />
        ко&nbsp;всем курсам по&nbsp;подписке
      </span>
    );
  }

  return (
    <span>
      Получить доступ ко всем курсам <span className="text-m-arctic">по&nbsp;подписке</span>
    </span>
  );
};

const SecondCheckboxLabel = ({
  definedTermPrice,
  definedTermPeriod,
  isEvent,
}: {
  definedTermPrice: number | undefined;
  definedTermPeriod: number | undefined;
  isEvent: boolean;
}) => {
  if (isEvent) {
    return (
      <span>
        Купить за&nbsp;<span className="text-m-arctic">{definedTermPrice}₽</span>
      </span>
    );
  }

  return (
    <span>
      Доступ к&nbsp;курсу на&nbsp;{definedTermPeriod}&nbsp;месяцев{" "}
      <span className="text-m-arctic">за&nbsp;{definedTermPrice}₽</span>
    </span>
  );
};

const AboutSpeaker = () => {
  const aboutSpeaker = useStore($aboutSpeaker);

  if (!aboutSpeaker) {
    return null;
  }

  return (
    <p className="mt-[12px] text-m-sm text-white text-center md:mt-[24px] 3xl:text-m-xl">
      {aboutSpeaker}
    </p>
  );
};

export const BuyCourse = () => {
  const [selectedPay, setSelectedPay] = useState<SelectPay>(null);
  useGate(SubscriptionsGate);

  const previousSubscription = useStore(userPurchasesModel.$hasPreviousSubscriptions);
  const isTrialActive = useStore(userPurchasesModel.$hasTrialSubscriptionActive);
  const subscriptionsInfo = useStore($subscriptionsPayment);
  const isLoadingSubscriptionData = useStore(paymentModel.$isLoadingSubscriptionData);
  const courseFormat = useStore($paymentCourseType);
  const isEvent = courseFormat !== null ? EVENT_FORMATS.includes(courseFormat) : false;
  const userSubscription = useStore(userPurchasesModel.$userSubscription);

  const trialState = getTrialState({
    userSubscription,
    isTrialActive,
    hasPreviousTrial: previousSubscription,
  });

  const coursePayment = useStore(paymentModel.$coursePayment);
  const handlers = useEvent({
    paymentModalOpened,
    paymentTypeChanged,
    paymentModalCloseClicked,
    courseChosen,
  });

  const disabled = selectedPay === null;

  const handlePay = () => {
    if (selectedPay === SelectPayTypes.subscription) {
      handlers.paymentModalOpened(PaymentModalType.Subscription);
      handlers.paymentTypeChanged(PaymentType.Subscription);
    }

    if (selectedPay === SelectPayTypes.onlyCourse) {
      handlers.courseChosen();
    }
  };

  const renderOfferCheckboxes = () => {
    if (isLoadingSubscriptionData) {
      return <SkeletonLoaderCheckboxes />;
    }

    if (subscriptionsInfo.length) {
      return (
        <>
          <div className={isEvent ? "order-1" : ""}>
            <Checkbox
              checkBoxClassName="!w-[30px] !h-[30px] mr-[24px]"
              checkArrowClassName={checkArrowClassName}
              id={SelectPayTypes.subscription}
              labelClassName={checkBoxLabelCss}
              title={<FirstCheckboxLabel trialState={trialState} />}
              checked={selectedPay === SelectPayTypes.subscription}
              onChange={() => setSelectedPay(SelectPayTypes.subscription)}
            />
          </div>
          <Checkbox
            checkBoxClassName="!w-[30px] !h-[30px] mr-[24px]"
            checkArrowClassName={checkArrowClassName}
            id={SelectPayTypes.onlyCourse}
            labelClassName={checkBoxLabelCss}
            title={
              <SecondCheckboxLabel
                definedTermPeriod={coursePayment?.definedTermPeriod}
                definedTermPrice={coursePayment?.definedTermPrice}
                isEvent={isEvent}
              />
            }
            checked={selectedPay === SelectPayTypes.onlyCourse}
            onChange={() => setSelectedPay(SelectPayTypes.onlyCourse)}
          />
        </>
      );
    }

    return null;
  };
  return (
    <Modal.Surface className="md:max-w-none md:w-[90vw] xl:w-[70vw] xl:px-mn-26 3xl:w-[1256px]">
      <Modal.Header
        size="small"
        className="mb-[5px] items-start xl:mb-[22px] 3xl:mb-[72px]"
        close={() => handlers.paymentModalCloseClicked()}
      />
      <Modal.Body className="mx-auto xl:w-auto xl:pb-[60px] 3xl:pb-[76px]">
        <div className="flex flex-col md:w-[70vw] xl:w-auto m-auto items-center justify-center">
          <Modal.Title className="text-m-arctic mb-mn-20">Курс</Modal.Title>

          <Typography
            tag="h1"
            size="m-h3"
            className="text-white font-m-sb-display uppercase xl:block 3xl:inline font-bold text-center 3xl:w-[1132px] 3xl:m-auto"
          >
            {coursePayment?.title}
          </Typography>
        </div>

        <AboutSpeaker />

        <div className="mt-[32px] px-mn-16 3xl:mt-[48px] md:w-[50vw] xl:w-[495px] md:mx-auto 3xl:w-[580px] flex flex-col gap-[19px] xl:gap-[24px]">
          {renderOfferCheckboxes()}
        </div>

        <div className="mt-[32px] text-center 3xl:mt-[48px]">
          <ButtonRounded
            className={
              disabled
                ? "!bg-none !bg-m-dark-grey !cursor-default !hover:bg-m-dark-grey !hover:bg-none transition-opacity"
                : ""
            }
            disabled={disabled}
            onClick={handlePay}
          >
            оформить
          </ButtonRounded>
        </div>
      </Modal.Body>
    </Modal.Surface>
  );
};
