import { combine, createEffect, createEvent, createStore, restore, sample, split } from "effector";
import { reset } from "patronum";

import { readyToCountStudentProgressForCourses } from "@/entities/course";

import { CourseApi } from "@/shared/api";
import { ACADEMY_URL, EDUTORIA_EDUCATION_URL } from "@/shared/constants";

import { makeFavoritesToggler } from "@/feature/favorite-courses";

export const enum ViewOption {
  progressing = "progressing",
  completed = "completed",
  favourite = "favourite",
  edutoria = "edutoria",
  academy = "academy",
}

export const coursesStatuses = [
  {
    label: "В процессе",
    value: ViewOption.progressing,
  },
  {
    label: "Завершенные",
    value: ViewOption.completed,
  },
  {
    label: "Избранные",
    value: ViewOption.favourite,
  },
];

export const readyToStartEducationComponent = createEvent();
export const readyToResetEducationComponent = createEvent();

export const courseStatusSelected = createEvent<ViewOption>();

const getProgressedCoursesFx = createEffect(CourseApi.getProgressCoursesFx);
const getCompletedCoursesFx = createEffect(CourseApi.getCompletedCoursesFx);
const getFavoriteCoursesFx = createEffect(CourseApi.getFavoriteCoursesFx);

const openEdutoriaFx = createEffect(() =>
  window.open(EDUTORIA_EDUCATION_URL, "_blank", "noopener"),
);
const openAcademyFx = createEffect(() => window.open(ACADEMY_URL, "_blank", "noopener"));

const markCourseAsFavoriteFx = createEffect(CourseApi.markCourseAsFavorite);
const unMarkCourseAsFavoriteFx = createEffect(CourseApi.unmarkCourseAsFavorite);

const $favouriteCourses = restore(getFavoriteCoursesFx, []);
const $completedCourses = restore(getCompletedCoursesFx, []);
const $progressedCourses = restore(getProgressedCoursesFx, []);

export const $favouriteCoursesIds = createStore<string[]>([]);

export const $selectedCourse = createStore(ViewOption.progressing);
export const $selectedCourseOption = $selectedCourse.map(
  (id) =>
    coursesStatuses.find((status) => status.value === id) ?? {
      label: "",
      value: ViewOption.progressing,
    },
);

export const $isSelectedEmptyCoursesLoading = combine(
  getFavoriteCoursesFx.pending,
  getCompletedCoursesFx.pending,
  getProgressedCoursesFx.pending,
  $favouriteCourses,
  $completedCourses,
  $progressedCourses,
  $selectedCourse,
  (
    favourite,
    completed,
    progressed,
    favouriteCourses,
    completedCourses,
    progressedCourses,
    selected,
  ) => {
    if (selected === ViewOption.progressing && progressedCourses.length === 0) {
      return progressed;
    }

    if (selected === ViewOption.completed && completedCourses.length === 0) {
      return completed;
    }

    if (selected === ViewOption.favourite && favouriteCourses.length === 0) {
      return favourite;
    }

    return false;
  },
);

export const $sliderCourses = combine(
  $favouriteCourses,
  $completedCourses,
  $progressedCourses,
  $selectedCourse,
  (favouriteCourses, completedCourses, progressedCourses, selected) => {
    if (selected === ViewOption.progressing) {
      return progressedCourses;
    }

    if (selected === ViewOption.completed) {
      return completedCourses;
    }

    if (selected === ViewOption.favourite) {
      return favouriteCourses;
    }

    return [];
  },
);

export const { toggledFavorite: courseFavouriteButtonClicked } = makeFavoritesToggler({
  $favouriteIds: $favouriteCoursesIds,
  mark: markCourseAsFavoriteFx,
  unmark: unMarkCourseAsFavoriteFx,
  reset: readyToResetEducationComponent,
});

sample({
  clock: readyToStartEducationComponent,
  target: [getProgressedCoursesFx, getCompletedCoursesFx, getFavoriteCoursesFx],
});

reset({
  clock: readyToResetEducationComponent,
  target: [$favouriteCourses, $completedCourses, $progressedCourses, $selectedCourse],
});

split({
  source: courseStatusSelected,
  match: {
    progressing: (value) => value === ViewOption.progressing,
    completed: (value) => value === ViewOption.completed,
    favorite: (value) => value === ViewOption.favourite,
    edutoria: (value) => value === ViewOption.edutoria,
    academy: (value) => value === ViewOption.academy,
  },
  cases: {
    progressing: getProgressedCoursesFx,
    completed: getCompletedCoursesFx,
    favorite: getFavoriteCoursesFx,
    edutoria: openEdutoriaFx,
    academy: openAcademyFx,
  },
});

sample({
  clock: courseStatusSelected,
  source: $selectedCourse,
  fn: (selected, value) => (value === ViewOption.edutoria ? selected : value),
  target: $selectedCourse,
});

sample({
  clock: getFavoriteCoursesFx.doneData,
  fn: (courses) => courses.map((course) => course.id),
  target: $favouriteCoursesIds,
});

sample({
  clock: [markCourseAsFavoriteFx.finally, unMarkCourseAsFavoriteFx.finally],
  target: getFavoriteCoursesFx,
});

sample({
  clock: $progressedCourses,
  fn: (courses) => courses.map((course) => course.id),
  target: readyToCountStudentProgressForCourses,
});
