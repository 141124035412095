import { useEvent, useGate, useStore } from "effector-react/scope";
import { useLocation } from "react-router-dom";

import { Program } from "@app/api";

import { DOMAIN_SITE, paths } from "@/pages/paths";

import { CatalogListItems } from "@/widgets/catalog";
import { Footer } from "@/widgets/footer";
import { Header } from "@/widgets/header";

import { Course } from "@/entities/course";
import { historyPush } from "@/entities/navigation";

import {
  ButtonRounded,
  CardSkeleton,
  Container,
  ContentHeightLayout,
  EmptyDataLayout,
  SEO,
  Typography,
} from "@/shared/ui";

import { CardCourseView, CardProgramView } from "@/feature/go-to-product-view";
import { SearchBar } from "@/feature/search";

import { PopularCourse } from "../../widgets/popular-course";
import { $searchPending, $searchResult, SearchPageGate } from "./model";

const SearchEmptyResult = () => {
  const push = useEvent(historyPush);

  return (
    <EmptyDataLayout className="min-h-[500px] px-mn-26 md:px-mn-0">
      <h2 className="text-m-white mb-[24px] text-m-base xl:text-m-xl 3xl:text-m-5xl font-m-sb-display font-semibold text-center">
        К&nbsp;сожалению, такой курс не&nbsp;найден. Вы&nbsp;можете{" "}
        <br className="hidden xl:block" />
        изменить свой запрос или посмотреть другие курсы
      </h2>
      <ButtonRounded variant="secondary" onClick={() => push(paths.catalog())}>
        К курсам
      </ButtonRounded>
    </EmptyDataLayout>
  );
};

const SkeletonContainer = (): JSX.Element => {
  return (
    <div className="flex flex-col md:flex-row justify-center w-full overflow-hidden">
      {Array(3)
        .fill(0)
        .map((_, i) => (
          <div key={i} className="mr-[12px] w-full">
            <CardSkeleton className="w-full" />
          </div>
        ))}
    </div>
  );
};

export const SearchPage = () => {
  useGate(SearchPageGate);
  const searchResultItems = useStore($searchResult);
  const isLoading = useStore($searchPending);

  const params = useLocation();
  const decodedQueryString = decodeURIComponent(params.search.split("?query=")[1]);

  return (
    <>
      <SEO
        title={`Результаты поиска – ${decodedQueryString.toLowerCase()}`}
        canonical={`${DOMAIN_SITE}${paths.search()}`}
      />
      <Header.Desktop />
      <Header.Mobile />
      <ContentHeightLayout className="bg-m-dark-blue">
        <div className="pt-mn-50 md:pt-mn-74">
          <Container mode="fixed">
            <div className="block xl:hidden mb-mn-30">
              <SearchBar />
            </div>
            <Typography tag="h2" size="m-h5" className="uppercase text-m-white mb-8">
              Результаты поиска
            </Typography>
          </Container>
          <Container mode="fluid-fixed" className="px-5 md:px-[26px] 3xl:px-[38px]">
            <CatalogListItems
              EmptyLayout={isLoading ? <SkeletonContainer /> : <SearchEmptyResult />}
              renderCatalog={[
                {
                  name: "search-programs",
                  title: "Профессиональное образование",
                  renderElements: () =>
                    searchResultItems.programs.map((item: Program) => (
                      <CardProgramView program={item} key={item.id} />
                    )),
                  isHidden: searchResultItems.programs.length === 0,
                },
                {
                  name: "search-courses",
                  title: "Курсы",
                  renderElements: () =>
                    searchResultItems.courses.map((item: Course) => (
                      <CardCourseView course={item} key={item.id} />
                    )),
                  isHidden: searchResultItems.courses.length === 0,
                },
              ]}
              isEmpty={
                searchResultItems.courses.length === 0 && searchResultItems.programs.length === 0
              }
            />
          </Container>
        </div>
        <PopularCourse
          className="pt-[30px] md:pt-[38px]"
          containerMode="fixed"
          options={{ centeredSlides: undefined, loop: true }}
          cardClassName="!w-[49.5%] xl:!w-[32.7%]"
        />
      </ContentHeightLayout>
      <Footer />
    </>
  );
};
