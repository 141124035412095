import React from "react";

import loadable from "@loadable/component";

export { ButtonRounded, ButtonCircle, NavButton, ButtonOutline } from "./components/button/button";
export type { ButtonRoundedProps } from "./components/button/button";

export { Background } from "./components/background/background";
export { BackgroundNevma } from "./components/background/background-nevma";

export { BurgerButton } from "./components/burger-button/burger-button";
export { Chip } from "./components/chip/chip";
export {
  DropdownContainer,
  DropdownBackground,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "./components/dropdown/dropdown-elements";
export type { MenuOption } from "./components/dropdown/dropdown-elements";
export { Dropdown } from "./components/dropdown/dropdown";

export {
  Container,
  Section,
  ContentHeightLayout,
  HorizontalLinearBlocks,
  EmptyDataLayout,
} from "./components/layout";

export { Navbar } from "./components/navigation/navbar/navbar";
export { Menu } from "./components/navigation/menu/menu";

export { Logo } from "./components/logo/logo";
export { Typography } from "./components/typography/typography";
export { Checkbox } from "./components/checkbox/checkbox";
export { Radio } from "./components/radio/radio";

export { Input } from "./components/input/input";
export { CodeInput } from "./components/input/code-input";
export type { InputProps } from "./components/input/input";

export { LockLayout } from "./components/lock-layout/lock-layout";
export { Modal, ModalNotification, ModalNotificationBody } from "./components/modal";
export type { ModalRenderer } from "./components/modal/modal";
export { Range } from "./components/range";
export type { RangeProps } from "./components/range";

export { Tab } from "./components/tab/tab";
export { TabMenu } from "./components/tab/tab-menu";

export { CountdownTimer } from "./components/countdown";
export { AnimationContainer, AnimatePresence, motion } from "./components/animation";
export type { Transition } from "./components/animation";
export { EdutoriaButton, AcademyButton } from "./components/button/partner-button";

export {
  BaseCarousel,
  SliderControls,
  Slider,
  SliderBullets,
  SliderCountSeparator,
  CardSlider,
  CertificateSlider,
} from "./components/slider";
export { Card, CardContainer } from "./components/card/card";
export { LandingBlock, CarouselPreview } from "./components/landing-blocks";
export { LinkWithArrow } from "./components/link-with-arrow/link-with-arrow";
export { SkeletonLoader, Skeleton } from "./components/skeleton-loader";
export const VideoPlayer = loadable(
  () => import("./components/video-player/video-player").then((component) => component.VideoPlayer),
  {
    fallback: <></>,
    ssr: false,
  },
);

export const Calendar = loadable(
  () => import("./components/calendar/calendar").then((component) => component.Calendar),
  {
    fallback: <></>,
    ssr: false,
  },
);

export const InputNumberFormat = loadable(
  () =>
    import("./components/input/input-number-format").then(
      (component) => component.InputNumberFormat,
    ),
  {
    fallback: <></>,
    ssr: false,
  },
);

export { SEO } from "./components/seo/seo";
export { Spinner } from "./components/spinner";

export { Link } from "./components/navigation/link";
export { Image } from "./components/image/image";
export { Hint } from "./components/hint/hint";
export type { TypographyProps } from "./components/typography/typography";

export const DrawerBottom = loadable(
  () =>
    import("./components/drawer-bottom/drawer-bottom").then((component) => component.DrawerBottom),
  {
    fallback: <></>,
    ssr: false,
  },
);

export { DeviceView } from "./components/device-view-wrapper";
export { Title } from "./components/title/title";
export { CardSkeleton } from "./components/card/card-skeleton";
export { ScrollToTopButton } from "./components/scroll-to-target-button";
export { ScrollToTop } from "./components/scroll-wrapper/scroll-to-top";
export { SwitchToggle } from "./components/switch-toggle/switch-toggle";
export { SliderBulletsRectangle } from "./components/slider/slider-attributes";
export { AvatarEditor } from "./components/avatar/avatar-editor";
export { OutsideClick } from "./components/outside-click";
