import { AttributeType } from "@/shared/api/modules/lesson";

export type LessonsContent<T> = {
  lesson_id: string;
  lesson_attributes: T[];
};

export type Lesson = {
  id: string;
  order: number;
  type: AttributeType;
  data: unknown;
  name: string;
  duration: number;
};

export enum TestType {
  Single = "single",
  Multiple = "multiple",
}

export type VariantsAnswer = {
  id: string;
  text: string;
};

export type AnswerStatus = {
  answer_id?: string;
  status?: boolean;
};

export type CorrectAnswers = {
  test_id: string;
  answers: string[];
};

export type TestData = {
  test_type: TestType;
  variants_answers: VariantsAnswer[];
  question: string;
};

export type Test = {
  id: string;
  order: number;
  type: "test";
  data: TestData;
};

export enum StatusTest {
  Success = "success",
  Fail = "fail",
  Default = "default",
  Reset = "reset",
}

export type SingleTestStatus = StatusTest.Success | StatusTest.Fail | StatusTest.Default;

export type FullTest = Test & { status: SingleTestStatus };

export type LessonVideo = {
  title: string;
  id: string;
  duration: string;
  url: string;
};
