import { sample } from "effector";

import { analyticsEvents } from "@/shared/analytics";

import { AUTH_MODAL_TYPE, openAuthModal } from "./model";

sample({
  clock: openAuthModal.$ModalType,
  filter: (modalType) => modalType === AUTH_MODAL_TYPE.signIn,
  target: analyticsEvents.openFormLogin,
});

sample({
  clock: openAuthModal.$ModalType,
  filter: (modalType) => modalType === AUTH_MODAL_TYPE.signUp,
  target: analyticsEvents.openFormSignUp,
});

sample({
  clock: openAuthModal.supportContactsOnSignUp,
  target: analyticsEvents.clickMailPswError,
});
