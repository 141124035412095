import { Control, FormState, UseFormReset, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { schema } from "./shema";
import { FeedBackFormState } from "./types";

type Input = {
  onSubmit: (data: FeedBackFormState) => void;
};

type Output = {
  control: Control<FeedBackFormState>;
  onSubmit: () => void;
  formState: FormState<FeedBackFormState>;
  reset: UseFormReset<FeedBackFormState>;
};

const defaultValues = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  agreement: false,
};

export function useFormFos({ onSubmit }: Input): Output {
  const { handleSubmit, control, formState, reset } = useForm<FeedBackFormState>({
    defaultValues,
    resolver: yupResolver(schema),
    delayError: 1000,
    mode: "all",
  });

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    formState,
    reset,
  };
}
