import { CancellationReason } from "../types";

type ErrorPayment = {
  [error in CancellationReason]: {
    title: string;
    description?: string;
  };
};

export const defaultErrorPayment = {
  title: "Заказ не был оплачен",
  description: "Пожалуйста, убедитесь, что введенные вами данные верны и повторите попытку",
};

export const paymentErrors: ErrorPayment = {
  [CancellationReason.ALREADY_PAID]: {
    title: "Покупка уже совершена",
    description:
      "Вы получите доступ в течение одной минуты. Обновите страницу, если этого не произошло",
  },
  [CancellationReason.OTHER]: {
    title: "Заказ не был оплачен",
    description:
      "Оплата данным платежным средством отклонена по неизвестным причинам. Обратитесь в организацию, выпустившую платежное средство или используйте другое платежное средство",
  },
  [CancellationReason.INSUFFICIENT_FUNDS]: {
    title: "Недостаточно средств",
    description: "Пополните баланс или используйте другое платежное средство",
  },
  [CancellationReason.PRODUCT_EXPIRED]: {
    title: "Недостаточно средств",
    description: "Обратитесь за помощью в техническую поддержку",
  },
  [CancellationReason.EXPIRED_ON_CONFIRMATION]: {
    title: "Срок оплаты заказа истек",
  },
  [CancellationReason.NO_SUCH_PRODUCT]: {
    title: "Нет такого заказа",
  },
  [CancellationReason.INVALID_CARD_DATA]: {
    title: "Неверные данные карты",
  },
};

export const getPaymentErrors = (reason: CancellationReason) =>
  paymentErrors[reason] || defaultErrorPayment;
