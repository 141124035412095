import { createAnalyticsEvent } from "../../model/analytics";

export enum HeaderEvents {
  CLICK_MAIN_PAGE = "click_main_page",
  CLICK_MENU = "click_menu",
}

export const clickLogo = createAnalyticsEvent(() => ({
  event_name: HeaderEvents.CLICK_MAIN_PAGE,
  link_text: "logo",
}));

export const clickMenuLink = createAnalyticsEvent<{ link_text: string }>(({ link_text }) => ({
  event_name: HeaderEvents.CLICK_MENU,
  link_type: "top",
  link_text,
}));
