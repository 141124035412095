import { DateUtils } from "./date-utils";

type States = "finished" | "progress" | "future";

const states: Record<States, States> = {
  finished: "finished",
  progress: "progress",
  future: "future",
};

export const determineStateOnDate = (date: { startDate: string; endDate: string }): States => {
  if (!date?.startDate || !date?.endDate) {
    return states.progress;
  }

  const startDate = new Date(date.startDate);
  const now = new Date();
  const endDate = new Date(date.endDate);

  if (DateUtils.isDateSameDay(startDate, now)) {
    return states.progress;
  }

  if (DateUtils.isSameOrAfter(startDate, now)) {
    return states.future;
  }

  if (DateUtils.isSameOrAfter(now, startDate) && DateUtils.isSameOrAfter(now, endDate)) {
    return states.finished;
  }

  return states.progress;
};
