import { ReactNode, useEffect, useMemo } from "react";

import { useCountdown } from "@/shared/lib/timer";

type DateTimeDisplay = {
  value: number;
  type: string;
};

type ShowCounterProps = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const isNumber = (value: number | undefined): value is number => typeof value === "number";

const DateTimeDisplay = ({ value, type }: DateTimeDisplay) => {
  return (
    <div className="flex flex-col items-center mx-[10px] xl:mx-[20px] 3xl:mx-[30px] justify-between">
      <span className="font-bold leading-none">
        {String(value).length === 1 ? `0${value}` : value}
      </span>
      <span className="font-normal text-m-xs 3xl:text-[18px]">{type}</span>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }: Partial<ShowCounterProps>) => {
  const separator = <span className="font-m-playfair font-bold relative bottom-[4px]">:</span>;

  return (
    <div className="flex text-m-3xl xl:text-[48px] 3xl:text-m-[82px] font-m-sb-display">
      {isNumber(days) && <DateTimeDisplay value={days} type="дни" />}

      {isNumber(hours) && (
        <>
          {separator}
          <DateTimeDisplay value={hours} type="часы" />
        </>
      )}

      {isNumber(minutes) && (
        <>
          {separator}
          <DateTimeDisplay value={minutes} type="минуты" />
        </>
      )}

      {isNumber(seconds) && (
        <>
          {separator}
          <DateTimeDisplay value={seconds} type="секунды" />
        </>
      )}
    </div>
  );
};

type CountdownProps = {
  deadline: Date;
  renderExpiredComponent?: ReactNode;
  onExpired?: (isExpired: boolean) => void;
};

export const CountdownTimer = ({ deadline, renderExpiredComponent, onExpired }: CountdownProps) => {
  const { days, hours, minutes, seconds } = useCountdown(deadline);
  const isExpired = useMemo(
    () => days + hours + minutes + seconds <= 0,
    [days, hours, minutes, seconds],
  );

  useEffect(() => {
    if (onExpired && isExpired) {
      onExpired(isExpired);
    }
  }, [isExpired]);

  if (isExpired) {
    return renderExpiredComponent ? <div>{renderExpiredComponent}</div> : null;
  }

  return <ShowCounter days={days} hours={hours} minutes={minutes} />;
};
