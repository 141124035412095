import { createDomain, sample } from "effector";
import { createGate } from "effector-react";
import { createHatch } from "framework";

import { createTabs } from "@/widgets/content-tabs";

import { certificateLoaded } from "@/feature/profile-certificates";

import { userEducation } from "./modules/education";

export const hatch = createHatch(createDomain("StudentCourses"));

export const StudentCoursePageGate = createGate<{ queryTabParams: string | null }>();

export type TabEducationNames = "courses" | "certificates";
export const studentCoursesTabs = ["courses", "certificates"];
export const tabsEducation = createTabs({ hatch, tabs: studentCoursesTabs });

sample({
  clock: hatch.exit,
  target: userEducation.readyToResetEducationComponent,
});

sample({
  clock: StudentCoursePageGate.open,
  target: [certificateLoaded, userEducation.readyToStartEducationComponent],
});

sample({
  clock: StudentCoursePageGate.open,
  filter: ({ queryTabParams }) =>
    queryTabParams !== null && studentCoursesTabs.includes(queryTabParams),
  fn: ({ queryTabParams }) => queryTabParams as string,
  target: tabsEducation.tabChanged,
});
