import { createEvent, sample } from "effector";

import { paths } from "@/pages/paths";

import { Course, courseModel } from "@/entities/course";
import { $currentRoute } from "@/entities/navigation";

import { analyticsEvents } from "@/shared/analytics";

export const watchCourseClicked = createEvent<{ courseId: string }>();
export const slideCategoryArrowClicked = createEvent();

const analyticsSent = createEvent<Course | undefined>();

sample({
  clock: slideCategoryArrowClicked,
  source: $currentRoute,
  fn: (currentRoute) => {
    const adultsPage = currentRoute === paths.catalog();
    return { link_type: adultsPage ? "Верхний слайдер" : "Баннеры" };
  },
  target: analyticsEvents.viewCourseSlider,
});

sample({
  clock: watchCourseClicked,
  source: courseModel.$courses,
  fn: (courses, { courseId }) => courses.find(({ id }) => id === courseId),
  target: analyticsSent,
});

sample({
  clock: analyticsSent,
  filter: Boolean,
  fn: (course) => {
    return {
      course_id: course.id,
      course_name: course.title,
      link_text: "Смотреть",
      link_url: `/courses/${course.id}`,
      price: course.definedTermPrice,
      format_course: course.courseFormat || "",
    };
  },
  target: analyticsEvents.clickCourse,
});
