import { useStore, useGate } from "effector-react/scope";
import React, { useState } from "react";
import { CatalogListItems } from "widgets/catalog";

import { Program } from "@app/api";

import { CardSkeleton } from "@/shared/ui";

import { CardProgramView } from "@/feature/go-to-product-view";

import { $isProgramLoading, OurProgramsGate, $program } from "../model/programs";

const getCourseCard = (item: Program) => <CardProgramView program={item} key={item.id} />;
const getCardSkeleton = (_: unknown, index: number) => (
  <CardSkeleton className="w-full" key={index} />
);

const useGetProgramCards = (): JSX.Element[] => {
  const courses = useStore($program);
  const isLoading = useStore($isProgramLoading);

  const COUNT_SKELETON_CARDS = 6;

  if (isLoading && !courses.length) {
    return Array(COUNT_SKELETON_CARDS)
      .fill(0)
      .map((_, index) => getCardSkeleton(_, index));
  }

  return courses.map(getCourseCard);
};

export const OurProgramsList = () => {
  const COUNT_VISIBLE_CARDS = 6;
  const programCards = useGetProgramCards();

  useGate(OurProgramsGate);

  const [visibleIndex, setVisibleIndex] = useState<number>(COUNT_VISIBLE_CARDS);

  const visiblePrograms = programCards.slice(0, visibleIndex);

  const isFullDisplay = visiblePrograms.length === programCards.length;

  const loadMore = isFullDisplay
    ? undefined
    : () => {
        setVisibleIndex((prev) => prev + COUNT_VISIBLE_CARDS);
      };

  return (
    <CatalogListItems
      renderCatalog={[
        {
          name: "ourPrograms",
          renderElements: () => visiblePrograms,
          isHidden: visiblePrograms.length === 0,
          loadMore,
        },
      ]}
      isEmpty={programCards.length === 0}
    />
  );
};
