type Params = {
  currentSearch: string;
  valueObject: Record<string, string | undefined> | Record<string, string | undefined>[];
};

export const getNewUrlSearchParams = ({ currentSearch, valueObject }: Params) => {
  const searchParams = new URLSearchParams(currentSearch);

  if (Array.isArray(valueObject)) {
    valueObject.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (value) {
          searchParams.set(key, value);
        }
      });
    });

    return searchParams.toString();
  }

  Object.entries(valueObject).forEach(([key, value]) => {
    if (value) {
      searchParams.set(key, value);
    }
  });

  return searchParams.toString();
};
