import { createAnalyticsEvent } from "../../model/analytics";

export const openCancelFormSubscription = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: "open_cancel_form_subscr",
  link_text: "Отменить подписку",
}));

export const clickSubscriptionThinkAbout = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: "click_subscr_think_about",
  link_text: "Ещё подумаю",
}));

export const clickSubscriptionYesCancel = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: "click_subscr_yes_cancel",
  link_text: "Да, отменить",
}));

export const clickSubscriptionResume = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: "click_resume_subscr",
  link_text: "Возобновить",
}));
