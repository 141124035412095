import clsx from "clsx";
import React from "react";

import styles from "./style.module.scss";

export type TypographyProps = {
  className?: string;
  children?: React.ReactNode;
};

export type TitleProps = TypographyProps;

export const Title: React.FC<TitleProps> = ({ className, children }) => (
  <h1 className={clsx(styles.ModalTitle, "m-typography-modal-title", className)}>{children}</h1>
);

export type DescriptionProps = TypographyProps;

export const Description: React.FC<DescriptionProps> = ({ className, children }) => (
  <p className={clsx(styles.ModalDescription, "m-typography-modal-text", className)}>{children}</p>
);

export type CaptionProps = TypographyProps;

export const Caption: React.FC<CaptionProps> = ({ className, children }) => (
  <span className={clsx(styles.ModalCaption, "m-typography-modal-caption", className)}>
    {children}
  </span>
);
