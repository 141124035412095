import { useEvent, useStore } from "effector-react/scope";

import {
  paymentModel,
  PaymentType,
  TransactionPaymentEvent,
  TransactionStatus,
} from "@/entities/payment";
import { $currentUser } from "@/entities/user";
import { userPurchasesModel } from "@/entities/user-purchases";

import { PaymentWidgetModalLayout } from "@/feature/payment-widget";

import { createPaymentProps } from "../lib/create-payment-props";
import { paymentModalCloseClicked } from "../model/payment-management";
import { paymentTransaction } from "../model/payment-transaction";

export const Payment = () => {
  const handlers = useEvent({
    paymentModalCloseClicked,
    paymentSucceed: paymentTransaction.paymentSucceed,
    transactionStatusChanged: paymentTransaction.transactionStatusChanged,
    transactionStatusReset: paymentTransaction.transactionStatusReset,
  });
  const selectedPaymentType = useStore(paymentModel.$paymentType);
  const transactionStatus = useStore(paymentTransaction.$transactionStatus);
  const status = useStore(userPurchasesModel.$statusCheckPreviousSubscriptionRequest);

  const subscriptionPaymentInfo = useStore(paymentModel.$selectedSubscription);
  const coursePaymentInfo = useStore(paymentModel.$coursePayment);
  const currentUser = useStore($currentUser);
  const hasPreviousSubscriptions = useStore(userPurchasesModel.$hasPreviousSubscriptions);

  const paymentProps = createPaymentProps({
    subscriptionPaymentInfo,
    coursePaymentInfo,
    hasPreviousSubscriptions,
    selectedPaymentType,
    userId: currentUser?.id || "",
  });

  const isShowPaymentWidget = !!selectedPaymentType;

  const handleTransactionChange = (event: TransactionPaymentEvent) => {
    if (event.status === TransactionStatus.REQUIRES_CONFIRMATION) {
      handlers.transactionStatusReset();
      return;
    }

    handlers.transactionStatusChanged(event);
  };

  if (status !== "done") return null;

  return (
    <PaymentWidgetModalLayout
      onCloseModal={() => handlers.paymentModalCloseClicked()}
      transactionStatus={transactionStatus}
      isShowPaymentWidget={isShowPaymentWidget}
      handleTransactionChange={handleTransactionChange}
      enableSberPay={selectedPaymentType !== PaymentType.Subscription}
      paymentProps={paymentProps}
    />
  );
};
