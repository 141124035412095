import { KeenSliderInstance } from "keen-slider";
import { ReactNode, useEffect, useState } from "react";

import { GifteryCode } from "@/shared/lib/giftery/giftery-code";
import { BaseCarousel, Image, SliderBullets, SliderControls, Typography } from "@/shared/ui";
import type { TypographyProps } from "@/shared/ui";
import sberObrImage from "@/shared/ui/assets/images/home/banners/1920-1.webp";
import banner4 from "@/shared/ui/assets/images/home/banners/banner-4.webp";
import sberObrLogo from "@/shared/ui/assets/images/home/banners/sber-obr-logo.svg";
import arrowBlue from "@/shared/ui/assets/images/icons/arrow-direction-blue.svg";

type BaseWidgetProps = {
  img: string;
  children: ReactNode;
};

const BannerContainer = ({ img, children }: BaseWidgetProps) => {
  return (
    <div className="flex-col flex md:flex-row items-center h-full">
      <div className="w-full md:w-[33vw] h-[140px] md:h-full">
        <Image src={img} className="w-full h-full object-cover" />
      </div>
      <div className="w-full bg-m-white h-full md:w-[67vw] pb-[40px] md:pb-0">{children}</div>
    </div>
  );
};

const EventContainer = ({
  title,
  description,
  redirectUrl,
  linkOptions,
}: {
  title: string | ReactNode;
  description: ReactNode;
  redirectUrl?: string;
  titleSize?: TypographyProps["size"];
  descriptionSize?: TypographyProps["size"];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  linkOptions?: any;
}) => {
  return (
    <a href={redirectUrl || linkOptions?.href} {...linkOptions}>
      <div className="flex px-mn-20 xl:pl-[55px] 3xl:pl-[66px] py-mn-20 xl:py-[35px] items-center h-full bg-m-white container-with-arrow font-bold">
        <div className="md:mr-[20px] xl:mr-[105px] 3xl:mr-[220px]">
          <Typography
            size="m-h4"
            tag="h4"
            className="mb-[10px] leading-[100%] font-m-sb-display uppercase text-m-blue"
          >
            {title}
          </Typography>
          <Typography
            size="m-h6"
            tag="p"
            className="uppercase text-m-blue md:w-[44vw] font-m-sb-display xl:w-[512px] 3xl:w-[690px]"
          >
            {description}
          </Typography>
        </div>
        <img
          src={arrowBlue}
          className="hidden md:block arrow-right-animation md:w-[56px] xl:w-[88px] 3xl:w-[126px]"
          alt=""
        />
      </div>
    </a>
  );
};

const SberEducationWidget = () => {
  return (
    <BannerContainer img={sberObrImage}>
      <div className="bg-m-white w-full flex justify-center h-full">
        <Image
          containerClassName="flex"
          src={sberObrLogo}
          className="w-[280px] xl:w-[450px] 3xl:w-[630px]"
        />
      </div>
    </BannerContainer>
  );
};

const SubscriptionGiftery = () => {
  return (
    <BannerContainer img={banner4}>
      <EventContainer
        linkOptions={{
          "target": "_blank",
          "href": "https://widget.giftery.cards/?productId=19679",
          "data-giftery-widget": "19679",
          "rel": "noreferrer noopener",
        }}
        title="Подписка в подарок"
        description={
          <>
            <span className="gradient-text">Подари годовой доступ</span> ко всем онлайн-курсам
            и&nbsp;событиям&nbsp;Музы
          </>
        }
      />
      <GifteryCode />
    </BannerContainer>
  );
};

const content = [<SberEducationWidget />, <SubscriptionGiftery />];

export const ActualEventsSlider = () => {
  const [sliderInstance, setSliderInstance] = useState<KeenSliderInstance | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [heightInitialized, setHeightInitialized] = useState(false);

  useEffect(() => {
    if (sliderInstance && sliderInstance.size > 0 && !heightInitialized) {
      setHeightInitialized(true);
    }
  }, [sliderInstance?.size]);

  return (
    <BaseCarousel
      enablePlugins={["autoplay"]}
      containerClassName="md:h-[16vw] xl:h-[12vw] relative"
      getActiveSlideIndex={setActiveSlide}
      getInstanceRef={setSliderInstance}
      data={content}
    >
      {sliderInstance && (
        <>
          <div className="hidden md:block absolute z-10 top-[50%] left-[50%] -translate-x-1/2 w-full">
            <SliderControls
              onSlideNext={() => {
                sliderInstance?.next();
              }}
              onSlidePrev={() => {
                sliderInstance?.prev();
              }}
              classNameControlRight="right-mn-26"
              classNameControlLeft="left-mn-26"
              controlsClass="!bg-m-grey md:flex absolute z-10"
              classNameContainer="child:text-m-orange"
            />
          </div>

          <div className="md:hidden absolute left-1/2 -translate-x-1/2 bottom-[14px]">
            <SliderBullets data={content} activeSlide={activeSlide} />
          </div>
        </>
      )}
    </BaseCarousel>
  );
};
