import { attach, createEvent, createStore, sample } from "effector";

import { invalidRoleError, logout, LogoutMethod, refreshToken } from "@/entities/user";

import { AuthApi } from "@/shared/api";
import { createModalState } from "@/shared/lib/effector";

export const offerConfirmationAgreementSubmitted = createEvent();
export const agreementToggle = createEvent();
export const closeAgreementModalClicked = createEvent();

export const $isAgreementChecked = createStore(false).on(agreementToggle, (is) => !is);
export const agreementModal = createModalState({ defaultState: false });

const offerConfirmationFx = attach({
  source: { offer_agreement: $isAgreementChecked },
  effect: AuthApi.offerConfirmation,
});

sample({
  clock: offerConfirmationAgreementSubmitted,
  target: offerConfirmationFx,
});

sample({
  clock: offerConfirmationFx.done,
  target: [refreshToken, agreementModal.close],
});

sample({
  clock: invalidRoleError,
  target: agreementModal.open,
});

sample({
  clock: closeAgreementModalClicked,
  fn: () => ({ method: LogoutMethod.MANUAL }),
  target: [logout, agreementModal.close],
});
