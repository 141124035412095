import React from "react";

import { Period } from "@app/api";

import { Radio } from "@/shared/ui";

import { filterLabels } from "../lib/filter-labels";
import { FilterPanel } from "./filter-panel";
import { classNames } from "./radio-clsx";

type FilterDurationProps = {
  value: Period | "any";
  onChange: (value: Period | "any") => void;
  isDisabled: boolean;
};
export const FilterDuration = ({ value, onChange, isDisabled }: FilterDurationProps) => {
  return (
    <FilterPanel groupTitle="Длительность" disabled={isDisabled}>
      <Radio
        className={classNames}
        id="period-any"
        checked={value === "any"}
        onChange={() => onChange("any")}
        status="default"
        label="Любая"
        disabled={isDisabled}
        hasCircle={false}
      />
      <Radio
        className={classNames}
        id="period-less_than_1"
        checked={value === Period.LESS_THAN_1}
        onChange={() => onChange(Period.LESS_THAN_1)}
        status="default"
        label={filterLabels[Period.LESS_THAN_1]}
        disabled={isDisabled}
        hasCircle={false}
      />
      <Radio
        className={classNames}
        id="period-from_1_to_6"
        checked={value === Period.FROM_1_TO_6}
        onChange={() => onChange(Period.FROM_1_TO_6)}
        status="default"
        label={filterLabels[Period.FROM_1_TO_6]}
        disabled={isDisabled}
        hasCircle={false}
      />
      <Radio
        className={classNames}
        id="period-more_than_6"
        checked={value === Period.MORE_THAN_6}
        onChange={() => onChange(Period.MORE_THAN_6)}
        status="default"
        label={filterLabels[Period.MORE_THAN_6]}
        disabled={isDisabled}
        hasCircle={false}
      />
    </FilterPanel>
  );
};
