import { useUserNameOrDefault } from "@/entities/user";

import { UserApi } from "@/shared/api";
import { useNameFontSize } from "@/shared/lib/adjust-font-size/use-adjust-font-size";
import { formatDate } from "@/shared/lib/date";
import { saveCertificate } from "@/shared/lib/pdf";
import { useDevices } from "@/shared/lib/responsive";
import { Logo } from "@/shared/ui";
import { ReactComponent as DownloadIcon } from "@/shared/ui/assets/images/certificates/icon-download.svg";

import styles from "./certificate.module.scss";

type CertificateInformationProps = {
  courseName: string;
  speaker: {
    name: string;
    surname: string;
  };
  issueDate: string;
  userName: string;
  nameFontSize: number;
  nameFontSizePdf: number;
};

const nameClass =
  "gradient-text font-semibold text-m-lg xl:text-m-2xl w-fit min-h-[40px] xl:min-h-0 xl:mb-mv-1";

export const CertificateInformation = ({
  courseName,
  speaker,
  issueDate,
  userName,
  nameFontSize,
  nameFontSizePdf,
}: CertificateInformationProps) => {
  return (
    <div className="w-full">
      <div className={`${styles.CertificateSlide} pl-[18px] pt-[24px]`}>
        <Logo.Static className="w-[66px]" />
      </div>
      <div className="h-[190px] bg-m-white px-[20px] py-[15px] font-m-sb-display text-m-blue relative">
        <h2 className={nameClass} style={{ fontSize: `${nameFontSize}px` }}>
          {userName}
        </h2>
        <div className="text-[11px] xl:text-m-xs leading-[1.4] mb-mv-0">
          успешно завершил(а) курс
          <br />
          <div className={styles.Truncate}>
            <b>{`«${courseName}»`}</b>
          </div>
        </div>
        <div className="mb-1">
          <span className="text-m-xs">
            Автор курса: <b>{`${speaker.name} ${speaker.surname}`}</b>
          </span>
        </div>
        <div className="flex items-center xl:items-end">
          <span className="text-[6.5px] text-m-dark mr-[28px] grow">
            {formatDate(issueDate, "dd MMMM yyyy г.")}
            <br />
            ООО «СберОбразование»
          </span>
          <button
            className="w-[40px] h-[40px] border-m-blue hover:border-m-orange border-[1px] rounded-full flex items-center justify-center shrink-0 text-m-blue hover:text-m-orange"
            onClick={() =>
              saveCertificate({
                initials: userName,
                nameFontSize: nameFontSizePdf,
                courseName,
                speakerName: `${speaker.name} ${speaker.surname}`,
                issueDate,
              })
            }
          >
            <DownloadIcon className="w-[17px] h-[17px]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export const ProfileCertificate = ({ certificate }: { certificate: UserApi.UserCertificate }) => {
  const { firstName, lastName } = useUserNameOrDefault();
  const { isDesktop } = useDevices();

  const nameFontSize = useNameFontSize(
    `${firstName} ${lastName}`,
    isDesktop ? 348 : 268,
    nameClass,
  );
  const nameFontSizePdf = useNameFontSize(
    `${firstName} ${lastName}`,
    480,
    "font-semibold text-[42px]",
  );

  return (
    <CertificateInformation
      userName={`${firstName} ${lastName}`}
      nameFontSize={nameFontSize}
      nameFontSizePdf={nameFontSizePdf}
      {...certificate}
    />
  );
};
