import { createStore, sample } from "effector";

import { userPurchasesModel } from "@/entities/user-purchases";

import { analyticsEvents } from "@/shared/analytics";
import { LessonApi } from "@/shared/api";
import { createVideoWatchedPercentage } from "@/shared/lib/video";

import { $courseId, $videos, $courseName, $hasCourseAccess, $coursePrice } from "../../model";
import { $viewed, viewedVideoChanged } from "./model/video-playlist";

const { $stepPercentage, progressChanged, durationSet } = createVideoWatchedPercentage({});

const getVideoNumber = (currentVideoId: string, videos: LessonApi.LessonVideo[]) => {
  return videos.findIndex((video) => video.id === currentVideoId) + 1;
};

const getCurrentVideoObject = (currentVideoId: string, videos: LessonApi.LessonVideo[]) => {
  return videos.find((video) => video.id === currentVideoId);
};

const $currentVideoId = createStore("").on(viewedVideoChanged, (_, videoId) => videoId);

sample({
  clock: $stepPercentage.updates,
  source: {
    course_name: $courseName,
    course_id: $courseId,
    videos: $videos,
    currentVideoId: $currentVideoId,
  },
  fn: ({ course_name, course_id, videos, currentVideoId }, percentage) => {
    const currentVideo = getCurrentVideoObject(currentVideoId, videos);
    return {
      course_name,
      course_id,
      lesson_id: currentVideo?.id || "",
      lesson_name: currentVideo?.title || "",
      task_progr: percentage,
    };
  },
  target: analyticsEvents.viewTaskVideo,
});

sample({
  clock: viewedVideoChanged,
  source: {
    course_id: $courseId,
    course_name: $courseName,
    videos: $videos,
  },
  fn: ({ course_id, course_name, videos }, currentVideoId) => {
    const currentVideo = getCurrentVideoObject(currentVideoId, videos);
    const lessonNum = getVideoNumber(currentVideoId, videos);
    return {
      lesson_id: currentVideo?.id || "",
      lesson_name: currentVideo?.title || "",
      lesson_num: String(lessonNum),
      lesson_all: String(videos.length),
      course_id,
      course_name,
    };
  },
  target: analyticsEvents.interactionBlockVideo,
});

sample({
  clock: viewedVideoChanged,
  source: {
    course_id: $courseId,
    course_name: $courseName,
    videos: $videos,
    price: $coursePrice,
    viewedVideo: $viewed,
    hasSubscription: userPurchasesModel.$userSubscription,
    hasAccessCourse: $hasCourseAccess,
  },
  filter: ({ viewedVideo, hasSubscription, hasAccessCourse }) => {
    const courseHasPayment = Boolean(hasSubscription) || Boolean(hasAccessCourse);
    const countViewedVideos = Object.keys(viewedVideo).length;

    return countViewedVideos === 0 && courseHasPayment;
  },
  fn: ({ course_id, course_name, videos, price }, currentVideoId) => {
    const currentVideo = getCurrentVideoObject(currentVideoId, videos);
    const lessonNum = getVideoNumber(currentVideoId, videos);
    return {
      lesson_id: currentVideo?.id || "",
      lesson_name: currentVideo?.title || "",
      lesson_num: String(lessonNum),
      lesson_all: String(videos.length),
      course_id,
      course_name,
      price,
    };
  },
  target: analyticsEvents.beginTutorialCourse,
});

export { progressChanged, durationSet };
