import clsx from "clsx";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { parse } from "@/shared/lib/html";
import { Typography } from "@/shared/ui";

import { LinkWithArrow } from "../../link-with-arrow/link-with-arrow";
import style from "./horizontal-line-block.module.scss";

type DataProps = {
  img: string;
  title: string;
  link?: string;
  target?: "_blank" | "_self";
};

const ImageBlock = ({
  item,
  imgClassName,
  onClick = () => null,
}: {
  item: DataProps;
  imgClassName?: string;
  onClick?: () => void;
}) => {
  return (
    <Link to={item.link || ""} className={style.item} onClick={onClick} target={item.target}>
      <div className={style.overlay} />
      <img src={item.img} alt={item.title} className={imgClassName} />
      <Typography tag="p" size="m-label" className={style.itemTitle}>
        {parse(item.title)}
      </Typography>
    </Link>
  );
};

export const HorizontalLinearBlocks = ({
  title,
  subTitle,
  hideBottomArrow = false,
  data,
  redirectUrl,
  imgClassName,
  onRedirect = () => null,
  onItemClick = () => null,
}: {
  title?: string;
  subTitle?: ReactNode | string;
  hideBottomArrow?: boolean;
  data: DataProps[];
  redirectUrl?: string;
  imgClassName?: string;
  onRedirect?: () => void;
  onItemClick?: (data: DataProps) => void;
}) => {
  return (
    <div className={style.wrapper}>
      <div className="relative flex flex-col items-start justify-between">
        <div className="flex w-full justify-between mb-mn-16 md:mb-mn-20 3xl:mb-mn-30 items-center">
          <Typography tag="h2" size="m-h2" className={clsx(style.title)}>
            {title}
          </Typography>
          <LinkWithArrow
            text="Все курсы"
            link={redirectUrl}
            classNameLink="hidden md:flex"
            onClick={onRedirect}
          />
        </div>
        <div className={clsx(style.description, "m-typography-h5")}>{subTitle}</div>
      </div>

      <div className={style.items}>
        {data.map((item, idx) => (
          <ImageBlock
            item={item}
            key={idx}
            imgClassName={imgClassName}
            onClick={() => {
              onItemClick(item);
            }}
          />
        ))}
      </div>

      {!hideBottomArrow && (
        <div className="xl:hidden mt-mn-50">
          <LinkWithArrow
            text="Все курсы"
            link={redirectUrl}
            classNameLink="flex md:hidden"
            onClick={onRedirect}
          />
        </div>
      )}
    </div>
  );
};
