import { createAnalyticsEvent } from "@/shared/analytics/model/analytics";

const MainPageEvents = {
  CLICK_MENU: "click_menu",
  CLICK_BANNER: "click_banner",
  CLICK_COURSE_CATALOG: "click_course_catalog",
  SEND_CONTACT_B2B: "send_contact_b2b",
  CLICK_TELEGRAM: "click_telegram",
  CLICK_BANNER_SLIDER: "click_banner_slider",
};

export const clickMenu = createAnalyticsEvent(
  ({ linkText, linkUrl }: { linkText?: string; linkUrl?: string }) => ({
    event: "autoEvent",
    event_name: MainPageEvents.CLICK_MENU,
    link_type: "main",
    link_text: linkText,
    link_url: linkUrl,
  }),
);
export const clickBanner = createAnalyticsEvent(
  ({ linkText, linkUrl }: { linkText?: string; linkUrl?: string }) => ({
    event: "autoEvent",
    event_name: MainPageEvents.CLICK_BANNER,
    link_type: "main",
    link_text: linkText,
    link_url: linkUrl,
  }),
);

export const clickBannerSlider = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: MainPageEvents.CLICK_BANNER_SLIDER,
  link_type: "main",
}));

export const clickCourseCatalog = createAnalyticsEvent(
  ({ linkText, linkUrl }: { linkText?: string; linkUrl?: string }) => ({
    event: "autoEvent",
    event_name: MainPageEvents.CLICK_COURSE_CATALOG,
    link_type: "main",
    link_text: linkText,
    link_url: linkUrl,
  }),
);

export const sendContactB2b = createAnalyticsEvent(
  ({ linkText, linkUrl }: { linkText?: string; linkUrl?: string }) => ({
    event: "autoEvent",
    event_name: MainPageEvents.SEND_CONTACT_B2B,
    link_type: "main",
    link_text: linkText,
    link_url: linkUrl,
  }),
);

export const clickTelegram = createAnalyticsEvent<{
  linkText: string;
}>(({ linkText }) => ({
  event: "autoEvent",
  event_name: MainPageEvents.CLICK_TELEGRAM,
  link_text: linkText,
  link_type: "main",
}));
