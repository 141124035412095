import { memo } from "react";

import { Program } from "@app/api";

import { paths } from "@/pages/paths";

import { ProgramCard } from "@/entities/program";

import { ButtonRounded, Link } from "@/shared/ui";

import { useCourseCardAnalytics } from "../analytics";

export type CardProgramViewProps = {
  program: Program;
};

export const CardProgramView = memo(
  ({ program }: CardProgramViewProps) => {
    const cardAnalytics = useCourseCardAnalytics();

    const onCardClick = () => {
      cardAnalytics.cardClicked({
        courseId: program.id,
        formatCourse: program.courseFormat,
        courseName: program.title,
        price: 0,
        linkText: `${program.title} - карточка ДПО`,
        linkUrl: program.additionalInfo?.url || "",
      });
    };

    return (
      <Link
        onClick={onCardClick}
        className="w-full"
        to={paths.programView(program.additionalInfo?.url)}
        nativeLink
      >
        <ProgramCard
          hoveredNode={<ButtonRounded>Присоединиться</ButtonRounded>}
          program={program}
        />
      </Link>
    );
  },
  (prev, next) => prev.program.id === next.program.id,
);
