import { useEvent } from "effector-react/scope";

import { ModalNotification } from "@/shared/ui";

import { authModalCloseClicked } from "@/app/authorization-modals/model";
import { useConfirmCode } from "@/feature/confirm-code-modal";

import { confirmCodeDeleteProfile } from "../model/delete-profile";

export const DeleteStudentProfileConfirmCodeErrorModal = () => {
  const confirmCode = useConfirmCode(confirmCodeDeleteProfile);

  const onClose = useEvent(authModalCloseClicked);

  return <ModalNotification type="error" onClose={onClose} message={confirmCode.error} />;
};
