import { createStore, sample, Store } from "effector";

import { Attribute, AttributeType } from "@/shared/api/modules/lesson";

type HeaderMaterial = {
  id: string;
  position: number;
  title: string;
};

type SectionMaterial = {
  sectionId: string;
  materials: Attribute[];
};

export const buildSections = (
  headers: HeaderMaterial[],
  materials: Attribute[],
): SectionMaterial[] => {
  return headers.reduce<SectionMaterial[]>((acc, header, i) => {
    const sectionId = materials.find((item) => item.type === AttributeType.HEADING)?.id;
    const nextHeaderPosition = headers[i + 1]?.position ?? materials.length;
    const materialsPart = materials.slice(header.position, nextHeaderPosition);

    if (sectionId) {
      acc.push({
        sectionId: header.id,
        materials: materialsPart,
      });
    }
    return acc;
  }, []);
};

const isMaterialsNotNull = (materials: Attribute[] | null): materials is Attribute[] => {
  return materials !== null;
};

interface Attributes {
  materials: Store<Attribute[] | null>;
}

export const createMaterialsSections = ({ materials }: Attributes) => {
  const $sections = createStore<SectionMaterial[]>([]);

  const $headingsMaterials = materials.map((materialsData) => {
    if (!materialsData) return [];

    return materialsData.reduce<HeaderMaterial[]>((acc, material, index) => {
      if (material.type === AttributeType.HEADING)
        acc.push({
          position: index,
          id: material.id,
          title: material.data.text,
        });
      return acc;
    }, []);
  });

  sample({
    clock: $headingsMaterials.updates,
    source: materials,
    fn: (materialsData, headers) =>
      isMaterialsNotNull(materialsData) ? buildSections(headers, materialsData) : [],
    target: $sections,
  });

  return {
    $headingsMaterials,
    $sections,
  };
};
