import { useMediaQuery } from "react-responsive";

import { devices } from "./devices";

export const useDevices = () => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${devices.xl})` });
  const isBigDesktop = useMediaQuery({ query: `(min-width: ${devices["3xl"]})` });
  const isTablet = useMediaQuery({ query: `(min-width: ${devices.md})` });
  const isMobile = useMediaQuery({ query: `(max-width: ${devices.md})` });
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${devices.lg})` });

  return {
    isDesktop,
    isMobile,
    isTablet,
    isTabletOrMobile,
    isBigDesktop,
  };
};
