import { createEvent, createStore, sample } from "effector";
import { getHatch, HatchParams } from "framework";
import { splitMap } from "patronum/split-map";
import { matchRoutes } from "react-router-config";

import { routes } from "@/pages/routes";

import { history, historyUpdated, pageNotFound } from "@/entities/navigation";

import { shouldHideRoute } from "./should-hide-route";

export const createClientApplication = () => {
  const { routeResolved, __: routeNotResolved } = splitMap({
    source: historyUpdated,
    cases: {
      routeResolved(update) {
        const matchedRoutes = matchRoutes(routes, update.pathname);
        if (matchedRoutes.length > 0) {
          return {
            route: matchedRoutes[0].route,
            match: matchedRoutes[0].match,
            update,
          };
        }

        return undefined; // explicitly skip to `routeNotResolved` case
      },
    },
  });

  routeNotResolved.watch(() => {
    console.error(`FATAL: Route not found for url`);
  });

  function extractCurrentRoutePath() {
    const matchedRoutes = matchRoutes(routes, history?.location.pathname ?? "/");

    if (matchedRoutes.length > 0) {
      return matchedRoutes[0].route.path;
    }
    return "/";
  }

  const $currentRoute = createStore(extractCurrentRoutePath());

  for (const { component, path } of routes) {
    if (!component) continue;
    const hatch = getHatch(component);
    if (!hatch) {
      if (process.env.NODE_ENV !== "production") {
        console.warn(
          `Route ${path} created without hatch. Please, check how you imported component, it should be \`withHatch\``,
        );
      }
      continue;
    }

    const {
      routeMatched,
      routeHidden,
      __: routeNotMatched,
    } = splitMap({
      source: routeResolved,
      cases: {
        routeHidden({ route }) {
          if (route.hidden && shouldHideRoute(window.location.hostname)) {
            return true;
          }
          return undefined;
        },
        routeMatched({ route, match, update }) {
          if (route.path === path) {
            return {
              params: match.params,
              query: Object.fromEntries(new URLSearchParams(update.search)),
            } as HatchParams;
          }

          return undefined; // explicitly skip to `routeNotMatched` case
        },
      },
    });

    sample({ clock: routeHidden, target: pageNotFound });

    // You can add chunk loading logic here

    const hatchEnter = createEvent<HatchParams>();
    const hatchUpdate = createEvent<HatchParams>();
    const hatchExit = createEvent<void>();

    if (hatch) {
      // If you add chunk loading, hatch will appear only after required chunk finished loading
      sample({ clock: hatchEnter, target: hatch.enter });
      sample({ clock: hatchUpdate, target: hatch.update });
      sample({ clock: hatchExit, target: hatch.exit });
    }

    const $onCurrentPage = $currentRoute.map((route) => route === path);

    sample({
      clock: routeNotMatched,
      source: $currentRoute,
      filter: (currentRoute, { route: { path: newRoute } }) => {
        const pageRoute = path;

        const isANewRouteDifferent = currentRoute !== newRoute;
        const isCurrentRouteOfCurrentPage = currentRoute === pageRoute;

        return isCurrentRouteOfCurrentPage && isANewRouteDifferent;
      },
      target: hatchExit,
    });

    sample({
      clock: routeMatched,
      filter: $onCurrentPage,
      target: hatchUpdate,
    });

    const shouldEnter = sample({
      clock: routeMatched,
      filter: $onCurrentPage.map((on) => !on),
    });

    sample({ clock: shouldEnter, target: hatchEnter });

    sample({
      clock: shouldEnter,
      fn: () => path,
      target: $currentRoute,
    });
  }
};
