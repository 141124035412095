import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
  split,
  type Event,
} from "effector";
import { condition, reset } from "patronum";
import { pending } from "patronum/pending";

import { CalculatedPromo, SubscriptionPayInfo } from "@app/api";

import { PromocodeStatus } from "@/entities/course-promocode";
import { $authenticatedUserId, $currentUser, getUserFx } from "@/entities/user";
import { isOldMuseSubscription, userPurchasesModel } from "@/entities/user-purchases";

import { AuthApi, PaymentApi, ProductApi } from "@/shared/api";
import { ConfirmCodeErrorCode, confirmCodeErrors } from "@/shared/api/constants";
import { scrollToElement } from "@/shared/lib/scroll";

import { createConfirmCodeModel } from "@/feature/confirm-code-modal";
import { createCountdownTimer } from "@/feature/countdown-button";
import { createPaymentTransactions } from "@/feature/payment-widget";

const CANCELED_STATUS = "canceled";

const isSuccessCalculated = (result: CalculatedPromo): boolean => {
  return result.status !== CANCELED_STATUS;
};

export const enum PromocodeModalType {
  PROMOCODE_INPUT = "input",
  SUCCESS = "success",
  ERROR = "error",
  PROMOCODE_PAYMENT_WIDGET = "promocode_payment_widget",
  DEFAULT = "default",
  WARNING_HAS_OLD_SUBSCRIPTION = "warning_has_old_subscription",
  PROMOCODE_FOR_NEW_USERS = "promocode_for_new_users",
  PHONE_SUBMIT = "phone_submit",
  PHONE_CONFIRM = "phone_confirm",
}

export const promocodeModalTypeChanged = createEvent<PromocodeModalType>();
export const promocodeModalReset = createEvent<void>();
export const modalWarningOpened = createEvent<void>();
export const modalSuccessActivateOpened = createEvent<void>();
const openPaymentWidgetModal = createEvent<void>();
const openPhoneSubmitModal = createEvent();

const promocodeInputValidationChanged = createEvent<PromocodeStatus | null>();

const hasNewUserChecked = createEvent();
const currentUserSubscriptionChecked = createEvent();
const previousUserSubscriptionChecked = createEvent<void>();
export const warningConfirmed = createEvent<void>();
export const readyToResetCourses = createEvent();
export const successButtonClicked = createEvent();
export const formSubmitted = createEvent();
export const promoCodeButtonClicked = createEvent();
export const promoCodeInputChanged = createEvent<string>();
export const phoneSubmitted = createEvent<string>();
const checkBusinessGroupPromocode = createEvent();
const checkAllUserSubscription = createEvent();

export const $promocodeModalType = createStore<PromocodeModalType>(PromocodeModalType.DEFAULT)
  .on(promocodeModalTypeChanged, (_, type) => type)
  .on(openPaymentWidgetModal, () => PromocodeModalType.PROMOCODE_PAYMENT_WIDGET)
  .on(modalWarningOpened, () => PromocodeModalType.WARNING_HAS_OLD_SUBSCRIPTION)
  .on(modalSuccessActivateOpened, () => PromocodeModalType.SUCCESS)
  .on(openPhoneSubmitModal, () => PromocodeModalType.PHONE_SUBMIT)
  .reset(promocodeModalReset);

const mapStatusPromocodeReceived = (status: string): PromocodeStatus => {
  const mapStatuses = {
    [PromocodeStatus.APPROVED]: PromocodeStatus.APPROVED,
    [PromocodeStatus.ALREADY_ACTIVATED]: PromocodeStatus.ALREADY_ACTIVATED,
    [PromocodeStatus.DOES_NOT_EXIST]: PromocodeStatus.DOES_NOT_EXIST,
    [PromocodeStatus.EXPIRED]: PromocodeStatus.EXPIRED,
    [PromocodeStatus.PROMOCODE_IS_ALREADY_USED]: PromocodeStatus.PROMOCODE_IS_ALREADY_USED,
    [PromocodeStatus.PROMOCODE_NOT_FOUND]: PromocodeStatus.PROMOCODE_NOT_FOUND,
    [PromocodeStatus.PROMOCODE_IS_EXPIRED]: PromocodeStatus.PROMOCODE_IS_EXPIRED,
  };

  return mapStatuses[status] || PromocodeStatus.UNKNOWN;
};

export const activateB2bPromocodeFx = createEffect(ProductApi.activatePromocodeV2);

export const promocodeFieldReset = createEvent();
export const $promoCode = createStore("").reset(promocodeFieldReset);

export const calculatePromoFx = attach({
  source: { userId: $authenticatedUserId, code: $promoCode },
  effect: async ({ userId, code }) => {
    const result = await ProductApi.calculatePromo({ userId, code });
    return result;
  },
});

export const $promoCalculatedData = createStore<Nullable<CalculatedPromo>>(null).on(
  calculatePromoFx.doneData,
  (prev, calculateResult) => (isSuccessCalculated(calculateResult) ? calculateResult : prev),
);

export const activateB2CPromocodeFx = attach({
  source: { userId: $authenticatedUserId, code: $promoCode },
  effect: async ({ userId, code }) => {
    const activatePromoResult = await ProductApi.activateB2CPromocode({ code, userId });
    return activatePromoResult;
  },
});

export const fetchSubscriptionByPlatformIdFx = attach({
  source: $promoCalculatedData,
  effect: async (promoResult: Nullable<CalculatedPromo>) => {
    const currentSubscription = await PaymentApi.getPayInfoSubscription({
      platformId: promoResult?.platformId,
    });

    return currentSubscription;
  },
});

export const $phoneValue = createStore<string>("").on(phoneSubmitted, (_, phone) => phone);

const addPhoneFx = attach({
  source: $phoneValue,
  effect: async (phone) => {
    const result = await AuthApi.addPhone({ phone });
    return result;
  },
});

const $addPhonePayload = createStore<Nullable<AuthApi.RequestConfirmCodeData>>(null).on(
  addPhoneFx.doneData.map((data) => ({ token: data.token, timeout: data.timeout })),
  (_, payload) => payload,
);

sample({
  clock: addPhoneFx.done,
  target: promocodeModalTypeChanged.prepend(() => PromocodeModalType.PHONE_CONFIRM),
});

export const confirmCodePhone = createConfirmCodeModel({
  source: $addPhonePayload,
  sendFx: AuthApi.confirmCode,
});

export const countdownTimerPhone = createCountdownTimer({});

export const $addPhoneError = createStore<string>("")
  .on(
    addPhoneFx.failData.map((err) => err.message),
    (_, message) =>
      confirmCodeErrors[message] ??
      confirmCodeErrors[ConfirmCodeErrorCode.ACTION_NOT_CREATED_TIMEOUT],
  )
  .reset(confirmCodePhone.confirmCodeChanged);

sample({
  clock: confirmCodePhone.sendAgain,
  target: addPhoneFx,
});

sample({
  clock: addPhoneFx.doneData,
  fn: ({ timeout }) => timeout,
  target: countdownTimerPhone.startCountdown,
});

split({
  clock: confirmCodePhone.requestFx.done,
  source: $promoCalculatedData,
  match: {
    isNewUser: (promoCalculated) => Boolean(promoCalculated?.isNewUser),
    notNewUser: (promoCalculated) => !promoCalculated?.isNewUser,
  },
  cases: {
    isNewUser: [openPaymentWidgetModal, getUserFx],
    notNewUser: currentUserSubscriptionChecked,
  },
});

export const $subscriptionOnPromocode = createStore<Nullable<SubscriptionPayInfo>>(null).on(
  fetchSubscriptionByPlatformIdFx.doneData,
  (_, [subscription]) => subscription,
);

export const $promoCodeError = createStore<Nullable<PromocodeStatus>>(null)
  .on(promocodeInputValidationChanged, (_, value) => value)
  .reset(promocodeFieldReset);

export const $promoCodeErrorMessage = $promoCodeError.map((error) => {
  if (
    error === PromocodeStatus.ALREADY_ACTIVATED ||
    error === PromocodeStatus.PROMOCODE_IS_ALREADY_USED
  ) {
    return "Промокод уже активирован";
  }

  if (error === PromocodeStatus.PROMOCODE_IS_EXPIRED) {
    return "Срок действия промокода истек";
  }

  if (error === PromocodeStatus.PROMOCODE_NOT_FOUND) {
    return "Такой промокод не существует";
  }

  return "";
});

export const promocodeTransaction = createPaymentTransactions({
  reset: promocodeModalReset,
  targetAfterSucceed: [
    userPurchasesModel.getUserProductsFx,
    userPurchasesModel.getUserCardsInfoFx,
    userPurchasesModel.getBoughtEventsFx,
  ],
});

const verifyExistPhoneOnUser = ({
  exist,
  notExist,
}: {
  exist: Event<void>;
  notExist: Event<void>;
}) => {
  const trigger = createEvent<void>();

  split({
    clock: trigger,
    source: $currentUser,
    match: {
      exist: (user) => Boolean(user?.phone),
      notExist: (user) => !user?.phone,
    },
    cases: {
      exist,
      notExist,
    },
  });

  return trigger;
};

const checkBusinessGroup = ({
  isPolkaGroup,
  isDefaultGroup,
}: {
  isPolkaGroup: Event<void>;
  isDefaultGroup: Event<void>;
}) => {
  const BUSINESS_POLKA = "polka";
  const BUSINESS_DEFAULT = "default";

  const trigger = createEvent();

  split({
    clock: trigger,
    source: $promoCalculatedData,
    match: {
      polkaPromo: (promo) => promo?.businessGroup === BUSINESS_POLKA,
      defaultPromo: (promo) => promo?.businessGroup === BUSINESS_DEFAULT,
    },
    cases: {
      polkaPromo: isPolkaGroup,
      defaultPromo: isDefaultGroup,
    },
  });

  return trigger;
};

sample({
  clock: promocodeTransaction.transactionStatusChanged,
  target: promocodeFieldReset,
});

sample({
  clock: promoCodeButtonClicked,
  fn: () => PromocodeModalType.PROMOCODE_INPUT,
  target: promocodeModalTypeChanged,
});

sample({
  clock: promoCodeInputChanged,
  fn: (value) => value.toUpperCase().replace(/[^A-Z0-9-]/g, ""),
  target: $promoCode,
});

sample({
  clock: formSubmitted,
  source: $promoCode,
  target: activateB2bPromocodeFx,
});

export const $pendingActivatePromocode = pending({
  effects: [
    activateB2CPromocodeFx,
    activateB2bPromocodeFx,
    calculatePromoFx,
    fetchSubscriptionByPlatformIdFx,
  ],
});

sample({
  clock: calculatePromoFx.doneData,
  filter: (data) => isSuccessCalculated(data),
  target: hasNewUserChecked,
});

split({
  clock: hasNewUserChecked,
  source: $promoCalculatedData,
  match: {
    isNewUser: (promoCalculated) => Boolean(promoCalculated?.isNewUser),
    notNewUser: (promoCalculated) => !promoCalculated?.isNewUser,
  },
  cases: {
    isNewUser: previousUserSubscriptionChecked,
    notNewUser: checkBusinessGroup({
      isPolkaGroup: verifyExistPhoneOnUser({
        exist: currentUserSubscriptionChecked,
        notExist: openPhoneSubmitModal,
      }),
      isDefaultGroup: currentUserSubscriptionChecked,
    }),
  },
});

split({
  clock: currentUserSubscriptionChecked,
  source: userPurchasesModel.$userSubscription,
  match: {
    oldSubscription: (currentSubscription) => isOldMuseSubscription(currentSubscription),
    newSubscription: (currentSubscription) => !isOldMuseSubscription(currentSubscription),
  },
  cases: {
    oldSubscription: modalWarningOpened,
    newSubscription: activateB2CPromocodeFx,
  },
});

condition({
  source: previousUserSubscriptionChecked,
  if: userPurchasesModel.$hasPreviousSubscriptions,
  then: promocodeModalTypeChanged.prepend(() => PromocodeModalType.PROMOCODE_FOR_NEW_USERS),
  else: fetchSubscriptionByPlatformIdFx,
});

sample({
  clock: warningConfirmed,
  target: activateB2CPromocodeFx,
});

const containSubscriptionByPromocode = ({
  subscriptionOnPromocode,
  calculatedPromo,
}: {
  subscriptionOnPromocode: SubscriptionPayInfo | null;
  calculatedPromo: CalculatedPromo | null;
}): boolean => {
  return Boolean(calculatedPromo?.isNewUser) && subscriptionOnPromocode !== null;
};

const userHasAlreadySubscription = sample({
  clock: fetchSubscriptionByPlatformIdFx.doneData,
  source: {
    subscriptionOnPromocode: $subscriptionOnPromocode,
    calculatedPromo: $promoCalculatedData,
  },
  fn: ({ subscriptionOnPromocode, calculatedPromo }) =>
    containSubscriptionByPromocode({
      calculatedPromo,
      subscriptionOnPromocode,
    }),
});

condition({
  source: userHasAlreadySubscription,
  if: Boolean,
  then: checkAllUserSubscription,
  else: promocodeModalTypeChanged.prepend(() => PromocodeModalType.ERROR),
});

const userSubscriptionChecked = sample({
  clock: checkAllUserSubscription,
  source: {
    allUserMuseSubscription: userPurchasesModel.$allUserMuseSubscriptions,
    subscriptionOnPromocode: $subscriptionOnPromocode,
  },
  fn: ({ allUserMuseSubscription, subscriptionOnPromocode }) => {
    return (
      subscriptionOnPromocode !== null &&
      allUserMuseSubscription.some((sub) =>
        sub.productPaySubscriptions.includes(subscriptionOnPromocode?.paySubscriptionType),
      )
    );
  },
});

condition({
  source: userSubscriptionChecked,
  if: Boolean,
  then: promocodeModalTypeChanged.prepend(() => PromocodeModalType.PROMOCODE_FOR_NEW_USERS),
  else: checkBusinessGroup({
    isPolkaGroup: verifyExistPhoneOnUser({
      exist: openPaymentWidgetModal,
      notExist: openPhoneSubmitModal,
    }),
    isDefaultGroup: openPaymentWidgetModal,
  }),
});

sample({
  clock: phoneSubmitted,
  target: addPhoneFx,
});

sample({
  clock: activateB2bPromocodeFx.failData,
  fn: (error) => mapStatusPromocodeReceived(error.message),
  target: promocodeInputValidationChanged,
});

const activatePromocodeStatusReceived = sample({
  clock: activateB2bPromocodeFx.doneData,
  fn: (status) => mapStatusPromocodeReceived(status),
});

split({
  source: activatePromocodeStatusReceived,
  match: {
    successStatus: (status) => status === PromocodeStatus.APPROVED,
    notExistError: (error) => error === PromocodeStatus.DOES_NOT_EXIST,
    otherError: (error) => error !== PromocodeStatus.DOES_NOT_EXIST,
  },
  cases: {
    notExistError: calculatePromoFx,
    otherError: promocodeInputValidationChanged,
    successStatus: modalSuccessActivateOpened,
  },
});

sample({
  clock: calculatePromoFx.doneData,
  filter: (result) => !isSuccessCalculated(result),
  fn: (result) => mapStatusPromocodeReceived(result.cancellationReason),
  target: promocodeInputValidationChanged,
});

sample({
  clock: calculatePromoFx.failData,
  fn: () => PromocodeModalType.ERROR,
  target: promocodeModalTypeChanged,
});

export function toStatus(message: string): PromocodeStatus {
  const statuses = Object.values<string>(PromocodeStatus);
  const isStatus = statuses.includes(message);
  if (isStatus) return message as PromocodeStatus;

  return PromocodeStatus.UNKNOWN;
}

sample({
  clock: $promoCodeError,
  filter: (error) => error !== null && toStatus(error) === PromocodeStatus.UNKNOWN,
  fn: () => PromocodeModalType.ERROR,
  target: promocodeModalTypeChanged,
});

sample({
  clock: activateB2CPromocodeFx.doneData,
  fn: ({ status }) => (status === 200 ? PromocodeModalType.SUCCESS : PromocodeModalType.ERROR),
  target: promocodeModalTypeChanged,
});

sample({
  clock: [activateB2CPromocodeFx.doneData, activateB2bPromocodeFx.doneData],
  source: $authenticatedUserId,
  target: userPurchasesModel.getUserProductsFx,
});

sample({
  clock: successButtonClicked,
  target: createEffect(() => scrollToElement("courses", { behavior: "smooth" })),
});

reset({
  clock: [promocodeModalReset, readyToResetCourses, successButtonClicked],
  target: [$promocodeModalType, $promoCode, $promoCodeError, $promoCalculatedData],
});
