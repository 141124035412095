import { createEffect } from "effector";
import { RouteHandlerMethod } from "fastify";
import { RouteGenericInterface } from "fastify/types/route";
import { IncomingMessage, Server, ServerResponse } from "http";

import { CourseFormatEnum } from "@app/api";

import { isClient } from "@/shared/lib/is-client";

import { apiInstance } from "../../bff";
import { createGqlEffect } from "../../client";
import artistData from "./artists.json";
import { ArtistData, FullArtistData } from "./types";

export const EVENT_FORMATS = [
  CourseFormatEnum.webinar,
  CourseFormatEnum.broadcast,
  CourseFormatEnum.master_class,
];

export const getEvent = createGqlEffect(apiInstance.event.getEvent);

export const getAllActiveEvents = createGqlEffect(apiInstance.event.getAllActiveEvents);

export const getAllActiveBroadcasts = createGqlEffect(apiInstance.event.getAllActiveBroadcasts);

export const getEventByTitleName = createGqlEffect(apiInstance.event.getEventByTitleName);

export const getArtist: RouteHandlerMethod<
  Server,
  IncomingMessage,
  ServerResponse,
  RouteGenericInterface
  /*   unknown, */
  /*   FastifyLoggerInstance */
> = async (req, res) => {
  const { artistId } = req.params as { artistId?: string };
  const artists = artistData as ArtistData[];

  if (!artistId) {
    res.code(402).send({});
    return;
  }

  const artist = artists.find((currentArtist) => currentArtist.id === artistId);

  if (!artist) {
    res.code(404).send({});
  }

  const eventsData = artists
    .filter((currentArtist) => currentArtist.id !== artistId)
    .map((currentArtist) => ({
      id: currentArtist.id,
      title: currentArtist.title,
      subtitle: currentArtist.subtitle,
      startDate: currentArtist.startDate,
    }));

  res
    .code(200)
    .header("Content-Type", "application/json; charset=utf-8")
    .send({ ...artist, events: eventsData });
};

// TODO - is really need ?
const fetchArtist = async (artistId: string) => {
  let res: FullArtistData;

  const origin = isClient ? window.location.origin : "localhost:3000";
  const proto = isClient ? window.location.protocol : "http";

  try {
    res = (await (
      await fetch(`${proto}://${origin}/artists/${artistId}`)
    ).json()) as FullArtistData;
  } catch (e) {
    throw new Error(e as string);
  }

  return res;
};

export const fetchArtistFx = createEffect(fetchArtist);
