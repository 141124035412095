import clsx from "clsx";
import { useEvent } from "effector-react/scope";
import { ReactNode } from "react";

import { Image, Typography } from "@/shared/ui";
import arrowBlue from "@/shared/ui/assets/images/icons/arrow-direction-blue.svg";

import { courseClicked } from "../analytics";

type AnnouncementBlockProps = {
  title: string | ReactNode;
  description: string | ReactNode;
  url: string;
  img: string;
};

const paddings = "py-mn-26 px-mn-20 md:px-mn-26 md:pt-mn-38 md:pb-mn-50 3xl:px-mn-38 3xl:pt-[58px]";

export const AnnouncementBlock = ({ title, description, url, img }: AnnouncementBlockProps) => {
  const handleCourseClick = useEvent(courseClicked);
  return (
    <a
      onClick={() => {
        handleCourseClick(url);
      }}
      target="_self"
      href={url}
      className="container-with-arrow"
    >
      <article className="flex bg-m-white flex-col md:flex-row justify-between">
        <div className={clsx("flex flex-col justify-between basis-3/4", paddings)}>
          <Typography
            tag="h5"
            size="m-h5"
            className="font-m-sb-display gradient-text uppercase font-bold mb-[30px] md:mb-0"
          >
            Новое на проекте
          </Typography>
          <div className="flex flex-col w-full md:w-[47vw] xl:w-[46vw]">
            <Typography
              tag="h1"
              size="m-h2"
              className="text-m-blue uppercase font-bold mb-[20px] 3xl:mb-[30px] w-full"
            >
              {title}
            </Typography>
            <div className="flex flex-col w-full md:w-[48vw] xl:w-[34vw] items-start md:flex-row md:items-center justify-between">
              <Typography tag="p" size="m-text" className="text-m-dark xl:w-full md:mb-mn-38">
                {description}
              </Typography>
            </div>

            <div className="flex w-full justify-end">
              <Image
                className="w-[50px] md:w-[10vw] xl:w-[110px] 3xl:w-[8vw] cursor-pointer arrow-right-animation"
                src={arrowBlue}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="h-[388px] md:w-[54vw] xl:w-[80vw] 3xl:w-[72vw] md:h-[503px] xl:h-[43vw] 3xl:h-[833px] overflow-hidden ">
          <Image
            className="h-full object-cover w-full object-[50%] md:object-[70%] xl:object-[100%]"
            src={img}
            lazy
            alt="Новое на проекте"
          />
        </div>
      </article>
    </a>
  );
};
