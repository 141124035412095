import { useEffect, useState } from "react";

import { CONFIG } from "@/shared/config";

const addHlsToDomain = (url: string): string => {
  const ASSETS = "assets";
  const HLS = "hls";

  return url.replace(ASSETS, HLS);
};

const convertOriginalUrlToHlsUrl = (originalUrl: string) => {
  const FOLDER_NAME = "CONVERTED";

  const path = originalUrl.split(CONFIG.CDN_PREFIX)[1];
  const convertedPath = `${FOLDER_NAME}/${path}`;

  const domain = addHlsToDomain(CONFIG.CDN_PREFIX);
  const convertedUrl = `${domain}${convertedPath}/playlist.m3u8`;

  return convertedUrl;
};

export const useConvertVideoUrl = (videoUrl: string) => {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!videoUrl) return;

    const hlsUrl = convertOriginalUrlToHlsUrl(videoUrl);

    setIsLoading(true);

    fetch(hlsUrl, {
      method: "GET",
    })
      .then((data) => {
        setUrl(hlsUrl);
        if (data.ok) {
          setUrl(hlsUrl);
        } else {
          throw new Error("Failed to convert video url");
        }
      })
      .catch(() => {
        setUrl(videoUrl);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      setUrl("");
    };
  }, [videoUrl]);

  return { url, isLoading };
};
