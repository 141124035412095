import clsx from "clsx";
import React, { HTMLProps, ReactNode } from "react";

import { scrollToElement } from "@/shared/lib/scroll";
import { ReactComponent as Arrow } from "@/shared/ui/assets/images/icons/arrow-scroll.svg";

type Props = {
  onClick?: () => void;
  className?: HTMLProps<HTMLElement>["className"];
  arrow?: ReactNode;
  targetId: string;
  hidden: boolean;
};

export const ScrollToTargetButton = ({
  onClick,
  className,
  arrow = <Arrow className="mb-[5px]" />,
  targetId,
  hidden,
}: Props) => {
  const handleClick =
    onClick ||
    (() => {
      scrollToElement(targetId);
    });
  return (
    <button
      className={clsx(
        "bg-m-blue w-[60px] h-[60px] rounded-full border-2 border-m-white flex justify-center items-center fixed right-[18px] bottom-[18px] z-10",
        className,
        { hidden },
      )}
      onClick={handleClick}
    >
      {arrow}
    </button>
  );
};
