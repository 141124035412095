import { ReactNode } from "react";

import { topicModel } from "@/entities/course";

import artS from "@/shared/ui/assets/images/home-slider-category/art375.webp";
import artM from "@/shared/ui/assets/images/home-slider-category/art1280.webp";
import artL from "@/shared/ui/assets/images/home-slider-category/art1920.webp";
import craftS from "@/shared/ui/assets/images/home-slider-category/craft375.webp";
import craftM from "@/shared/ui/assets/images/home-slider-category/craft1280.webp";
import craftL from "@/shared/ui/assets/images/home-slider-category/craft1920.webp";
import technologyS from "@/shared/ui/assets/images/home-slider-category/technology375.webp";
import technologyM from "@/shared/ui/assets/images/home-slider-category/technology1280.webp";
import technologyL from "@/shared/ui/assets/images/home-slider-category/technology1920.webp";

const { TopicNames } = topicModel;

export const CourseNames = {
  art: "Русская симфоническая музыка",
  creation: "Основы композиции в электронной музыке",
  profession: "Как создать локальный бренд?",
};

export type SlideItem = {
  itemId?: string;
  image: {
    srcS?: string;
    srcM?: string;
    srcL: string;
  };
  alt: string;
  cssClassName?: string;
  text: {
    category: string | ReactNode;
    plainTitle: string;
    title: string | ReactNode;
    paragraph?: ReactNode;
  };
  courseId?: string;
  link?: string;
  onClick?: (el: string) => void;
};

export const content: SlideItem[] = [
  {
    image: {
      srcS: artS,
      srcM: artM,
      srcL: artL,
    },
    alt: "Art",
    cssClassName: "category-slide-art",
    text: {
      category: TopicNames.Art,
      plainTitle: CourseNames.art,
      title: (
        <>
          Русская <br /> симфоническая музыка
        </>
      ),
      paragraph: (
        <>
          Русская симфоническая музыка за&nbsp;1,5&nbsp;часа вместе с&nbsp;выдающимся музыкантом,
          народным артистом СССР и&nbsp;главным дирижером БСО имени П.И.&nbsp;Чайковского Владимиром
          Федосеевым
        </>
      ),
    },
    courseId: "e61f7bce-53f2-4c06-87ff-b46dc1f8af1b",
  },
  {
    image: {
      srcS: craftS,
      srcM: craftM,
      srcL: craftL,
    },
    alt: "Craft",
    cssClassName: "category-slide-craft",
    text: {
      category: TopicNames.Creation,
      plainTitle: CourseNames.creation,
      title: (
        <>
          Основы композиции
          <br />в электронной музыке
        </>
      ),
      paragraph: (
        <>
          Один из&nbsp;&laquo;отцов-основателей&raquo; российской электронной музыки новой волны
          Андриеш Гандрабур о&nbsp;том, как сделать первые шаги в&nbsp;создании музыкальной
          композиции
        </>
      ),
    },
    courseId: "7eb4362d-e5cc-4b14-b6d9-7c3b695619be",
  },
  {
    image: {
      srcS: technologyS,
      srcM: technologyM,
      srcL: technologyL,
    },
    alt: "Technology",
    cssClassName: "category-slide-technology",
    text: {
      category: TopicNames.Profession,
      plainTitle: CourseNames.profession,
      title: (
        <>
          Как создать
          <br />
          локальный бренд?
        </>
      ),
      paragraph: (
        <>
          Курс для тех, кто хочет запустить собственную линейку одежды и&nbsp;пройти путь
          &laquo;от&nbsp;идеи до витрины&raquo; вместе с&nbsp;основательницей питерского модного
          бренда &laquo;OLA OLA&raquo; Ольгой Гинзбург
        </>
      ),
    },
    courseId: "a889f050-9c7f-441a-b00d-99fc69202773",
  },
];
