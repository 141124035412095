import { createEvent, sample } from "effector";
import { useEffect } from "react";

import { CONFIG } from "@/shared/config";
import { debounce } from "@/shared/lib/lodash";
import { getWindowScrollPercentage } from "@/shared/lib/scroll/scroll-persentage";

import { analyticsEvents } from "../../analytics";

const SCROLL_DEBOUNCE_TIME = 500;

const scrollWindowChanged = createEvent<number>();

const debouncedChangeHandler = debounce(() => {
  const scrollPercentage = getWindowScrollPercentage();
  scrollWindowChanged(scrollPercentage);
}, SCROLL_DEBOUNCE_TIME);

const useScrollListener = (
  element: Element | Window | Document | null,
  handler: EventListenerOrEventListenerObject | void | undefined = () => ({}),
) => {
  useEffect(() => {
    if (element) {
      element.addEventListener("scroll", handler, {
        capture: true,
        passive: true,
      });
    }
    return () => {
      element?.removeEventListener("scroll", handler);
    };
  }, [element, handler]);
};

export const useScrollHandler: (handler: () => void) => void = (handler) => {
  useScrollListener(CONFIG.BUILD_ON_CLIENT ? window : null, handler);
};

export const useWindowScrollHandler = () => {
  useScrollHandler(debouncedChangeHandler);
};

sample({
  clock: scrollWindowChanged,
  fn: (depth) => ({ depth }),
  target: analyticsEvents.scroll,
});
