import { apiInstance } from "../../bff";
import { createGqlEffect } from "../../client";

export const getPayInfoSubscription = createGqlEffect(apiInstance.payment.getPayInfoSubscription);

export const getPayInfoByCourse = createGqlEffect(apiInstance.payment.getPayInfoByCourse);

export const cancelSubscription = createGqlEffect(apiInstance.payment.cancelSubscription);

export const checkPreviousSubscriptions = createGqlEffect(
  apiInstance.payment.checkPreviousSubscriptions,
);

export const sendCoursePurchaseEvent = createGqlEffect(apiInstance.payment.sendCoursePurchaseEvent);
