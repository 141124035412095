import clsx from "clsx";
import { useStore, useEvent } from "effector-react/scope";
import { memo, Ref, useEffect } from "react";
import { Link } from "react-router-dom";

import { UserProduct } from "@app/api";

import { paths } from "@/pages/paths";

import { ButtonStartSubscription } from "@/widgets/payment";

import {
  isNewMuseSubscription,
  isOldMuseSubscription,
  userPurchasesModel,
  SubscriptionType,
} from "@/entities/user-purchases";

import { formatDate } from "@/shared/lib/date";
import { useCallbackOnEnter } from "@/shared/lib/keyboard";
import { ButtonRounded, Modal, ModalNotification } from "@/shared/ui";
import { ReactComponent as BinIcon } from "@/shared/ui/assets/images/icons/trash.svg";

import styles from "@/feature/student-profile-form/ui/section-form/section-profile.module.scss";

import {
  cancelSubscriptionModal,
  confirmCanceledSelectedSubscription,
  userRestoredSubscription,
  thinkAboutButtonClicked,
  $selectedCancelSubscription,
  cancelSubscriptionSelected,
} from "./model";

type CancelSubscriptionModal = {
  selectedUserSubscription: UserProduct | null;
  closeCancelModal: () => void;
};

const CancelSubscriptionModal = memo(
  ({ selectedUserSubscription, closeCancelModal }: CancelSubscriptionModal) => {
    const handlers = useEvent({ confirmCanceledSelectedSubscription, thinkAboutButtonClicked });

    useCallbackOnEnter(handlers.thinkAboutButtonClicked);

    const cancelDescription = isOldMuseSubscription(selectedUserSubscription) ? (
      <>
        После отключения подписки текущий тариф будет недоступен, так как его стоимость изменилась.
        Подробнее на&nbsp;странице{" "}
        <Link className="underline text-m-orange" to={paths.subscriptions()}>
          «Преимущества подписки»
        </Link>
      </>
    ) : (
      <>
        До окончания срока действия вам будут доступны все&nbsp;материалы,&nbsp;входящие
        в&nbsp;подписку
      </>
    );

    return (
      <ModalNotification
        type="warning"
        onClose={closeCancelModal}
        title="Вы точно хотите отменить подписку?"
        message={cancelDescription}
        renderButtons={[
          {
            title: "Ещё подумаю",
            onClick: handlers.thinkAboutButtonClicked,
            variant: "primary",
          },
          {
            title: "Да, отменить",
            onClick: handlers.confirmCanceledSelectedSubscription,
            variant: "secondary",
          },
        ]}
      />
    );
  },
);

type SubscriptionControlProps = {
  userSubscription: UserProduct;
  restoreSubscription: (subscriptionId: string) => void;
  isTrialActive: boolean;
  onSelectSubscriptionForCancel: (selectedUserSubscription: UserProduct) => void;
};

const not = (state: boolean) => !state;

const SubscriptionPanel = memo(
  ({
    userSubscription,
    restoreSubscription,
    isTrialActive,
    onSelectSubscriptionForCancel,
  }: SubscriptionControlProps) => {
    const isSubscriptionActive = userSubscription?.subscriptionType === SubscriptionType.Recurring;

    const showRestoreSubscriptionButton = [
      not(isSubscriptionActive),
      userSubscription.userPaymentMethod,
      not(isOldMuseSubscription(userSubscription)),
    ].every(Boolean);

    const renderSubscriptionLabel = () => {
      if (isTrialActive) return "Бесплатный пробный период до:";
      if (isNewMuseSubscription(userSubscription)) return "Подписка Муза активна до:";
      return "Подписка активна до:";
    };

    const getSubscriptionRenewalInfo = () => {
      if (isTrialActive && isSubscriptionActive) {
        return (
          <p>
            Плата за{" "}
            <Link className="underline text-m-arctic" to="/subscriptions">
              подписку по тарифу
            </Link>{" "}
            будет списана в течение 24 часов до окончания действия пробного периода
          </p>
        );
      }

      if (isSubscriptionActive) {
        return (
          <p>
            Плата за продление будет списана в течение 24 часов до&nbsp;окончания действия подписки
          </p>
        );
      }

      return <p className="text-m-orange">Автопродление отключено</p>;
    };

    return (
      <article className="mt-mn-26">
        <div
          className={clsx(
            styles.ProfileField,
            styles.isDisabled,
            "h-auto items-center xl:items-start",
          )}
        >
          <div className="flex flex-col items-start self-center lg:flex-row lg:items-center">
            <span className="inline-block text-m-sm lg:text-m-base text-m-orange">
              {renderSubscriptionLabel()}
            </span>
            <span
              className={clsx(
                "inline-block text-m-sm p-0 3xl:text-m-base font-semibold lg:pl-4 font-m-sb-display relative",
                !isSubscriptionActive ? "text-m-orange" : "text-m-white",
              )}
            >
              {formatDate(userSubscription?.expiredDate, "dd.MM.yyyy")}
            </span>
          </div>
          {isSubscriptionActive && (
            <button
              className="relative w-[2.5rem] h-[2.5rem] flex items-center justify-center rounded-full border-[1px] border-m-border lg:left-[20px] hover:border-m-orange"
              onClick={() => onSelectSubscriptionForCancel(userSubscription)}
            >
              <div className="w-[19px] h-[20px]">
                <BinIcon className="fill-m-dark-grey" />
              </div>
            </button>
          )}
          {showRestoreSubscriptionButton && (
            <ButtonRounded
              variant="secondary"
              className="relative lg:left-[20px] font-semibold "
              onClick={() => restoreSubscription(userSubscription.id)}
            >
              Возобновить
            </ButtonRounded>
          )}
        </div>
        <div
          className={clsx(
            "text-m-dark-grey font-sans text-m-2xs lg:text-m-xs 3xl:text-m-sm mt-mv-3 lg:mt-mv-2",
            { "text-m-orange": !isSubscriptionActive },
          )}
        >
          {getSubscriptionRenewalInfo()}
        </div>
      </article>
    );
  },
);

export const SubscriptionControl = ({ containerRef }: { containerRef: Ref<HTMLDivElement> }) => {
  const handlers = useEvent({ cancelSubscriptionSelected, userRestoredSubscription });
  const allUserSubscriptions = useStore(userPurchasesModel.$allUserMuseSubscriptions);
  const hasSubscription = useStore(userPurchasesModel.$hasUserMuseSubscription);
  const selectedSubscriptionForCancel = useStore($selectedCancelSubscription);
  const isOpenCancelModal = useStore(cancelSubscriptionModal.$isOpen);
  const cancelSubscriptionModalHandler = useEvent({
    open: cancelSubscriptionModal.open,
    close: cancelSubscriptionModal.close,
  });

  useEffect(() => () => cancelSubscriptionModalHandler.close(), []);

  return (
    <div className="w-full pt-[48px] xl:pt-0" ref={containerRef}>
      <h2 className="uppercase text-white font-semibold text-center text-m-base lg:text-m-xl 3xl:text-4xl lg:text-left mb-mv-3 lg:mb-mv-4 3xl:mb-mv-5">
        Моя подписка
      </h2>

      {hasSubscription &&
        allUserSubscriptions.map((subscription) => {
          return (
            <SubscriptionPanel
              onSelectSubscriptionForCancel={handlers.cancelSubscriptionSelected}
              key={subscription.id}
              userSubscription={subscription}
              isTrialActive={subscription.trial}
              restoreSubscription={handlers.userRestoredSubscription}
            />
          );
        })}

      <Modal
        render={() => (
          <CancelSubscriptionModal
            closeCancelModal={cancelSubscriptionModalHandler.close}
            selectedUserSubscription={selectedSubscriptionForCancel}
          />
        )}
        isOpen={isOpenCancelModal}
        close={cancelSubscriptionModalHandler.close}
      />

      {!hasSubscription && (
        <div className="mt-mv-3 lg:mt-mv-4 3xl:mt-mv-5 flex justify-center lg:justify-start">
          <ButtonStartSubscription buttonText="Оформить подписку" />
        </div>
      )}
    </div>
  );
};
