import * as Sentry from "@sentry/react";

import { queryToString } from "@/shared/api/client/http/lib";

import { CONFIG } from "../../../config";
import { Request } from "./types";

export async function requestClient<Response = unknown>({
  path,
  method,
  baseUrl = CONFIG.BROWSER_REST_API_URL,
  ...options
}: Request) {
  const headers = new Headers(options.headers);
  contentDefault(headers, "application/json; charset=utf-8");

  if (CONFIG.REST_API_AUTH) {
    headers.set("authorization", CONFIG.REST_API_AUTH);
  }

  const query = queryToString(options.query);
  const body =
    contentIs(headers, "application/json") && options.body
      ? JSON.stringify(options.body)
      : undefined;

  try {
    const response = await fetch(`${baseUrl}${path}${query}`, {
      method,
      headers,
      body,
      credentials: "include",
    });

    const answer: Response = contentIs(response.headers, "application/json")
      ? await response.json()
      : await response.text();

    return {
      ok: response.ok,
      body: answer,
      status: response.status,
      headers: toObject(response.headers),
    };
  } catch (error) {
    Sentry.captureException(error);
    return {
      ok: false,
      body: error,
      status: 900,
      headers: {},
    };
  }
}

/**
 * Check if content-type JSON
 */
function contentIs(headers: Headers, type: string): boolean {
  return headers.get("content-type")?.includes(type) ?? false;
}

function contentDefault(headers: Headers, type: string): Headers {
  if (!headers.has("content-type")) {
    headers.set("content-type", type);
  }
  return headers;
}

function toObject(headers: Headers): Record<string, string> {
  const target = {};
  headers.forEach((value, key) => {
    target[key] = value;
  });
  return target;
}
