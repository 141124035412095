import clsx from "clsx";
import { forwardRef, memo } from "react";

import { useIntersectionObserver } from "@/shared/lib/intersection-observer";
import { Breakpoint, devices } from "@/shared/lib/responsive";

type Screen = {
  breakpoint: `${Breakpoint}`;
  src: string;
};

type ImageProps = {
  className?: string;
  containerClassName?: string;
  alt?: string;
  src: string;
  screens?: Screen[];
  lazy?: boolean;
  startingLazyLoad?: number;
  width?: number;
  height?: number;
};

type PictureProps = {
  className?: string;
  screens?: Screen[];
  containerClassName?: string;
  isVisible?: boolean;
  src: string;
  alt: string;
  width?: number;
  height?: number;
};

const hasIsVisibleParam = (isVisible?: boolean) => typeof isVisible === "boolean";

const getSourceImage = (src: string, isVisible?: boolean) => {
  if (hasIsVisibleParam(isVisible)) {
    if (isVisible) {
      return src;
    }

    return undefined;
  }

  return src;
};

const setLazyClassNames = (isVisible?: boolean) => {
  return clsx({
    hidden: !isVisible && hasIsVisibleParam(isVisible),
    block: isVisible,
  });
};

const Picture = forwardRef<HTMLImageElement, PictureProps>(
  ({ className, containerClassName, alt, isVisible, src, screens, width, height }, ref) => {
    return (
      <picture ref={ref} className={containerClassName}>
        {screens?.map((screen) => (
          <source
            key={screen.breakpoint}
            srcSet={`${getSourceImage(screen.src, isVisible)} 2x`}
            media={`(max-width: ${devices[screen.breakpoint]})`}
          />
        ))}
        <img
          width={width}
          height={height}
          key="main"
          className={clsx(setLazyClassNames(isVisible), className)}
          src={getSourceImage(src, isVisible)}
          alt={alt}
        />
      </picture>
    );
  },
);

const DEFAULT_ROOT_MARGIN = 300;

export const Image = memo(
  ({
    className = "",
    containerClassName = "",
    alt = "#",
    src,
    screens,
    startingLazyLoad = DEFAULT_ROOT_MARGIN,
    lazy = false,
    width,
    height,
  }: ImageProps) => {
    const { inView, ref } = useIntersectionObserver({
      rootMargin: `${startingLazyLoad}px`,
      triggerOnce: true,
    });

    if (!lazy) {
      return (
        <Picture
          width={width}
          height={height}
          screens={screens}
          src={src}
          alt={alt}
          className={className}
          containerClassName={containerClassName}
        />
      );
    }

    return (
      <Picture
        width={width}
        height={height}
        ref={ref}
        screens={screens}
        src={src}
        alt={alt}
        className={className}
        containerClassName={containerClassName}
        isVisible={inView}
      />
    );
  },
);
