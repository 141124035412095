import { ReactNode } from "react";

import gradientLineWithBackground from "@/shared/ui/assets/images/adults-landing/gradient-line-with-background.svg";

import styles from "./styles.module.scss";

type WelcomeBlockProps = {
  header: ReactNode;
  children?: ReactNode;
};

export const WelcomeBlock = ({ header, children }: WelcomeBlockProps) => {
  return (
    <div className="bg-m-dark-blue relative">
      <div className="relative overflow-hidden">
        {header}
        <div className={styles.WelcomeOverlay} />
        <div className={styles.WelcomeBlockBackground} />
        <img
          className="w-full absolute bottom-0 scale-[1.5] md:scale-[1.2] xl:scale-[1]"
          src={gradientLineWithBackground}
          alt=""
        />
      </div>

      {children}
    </div>
  );
};
