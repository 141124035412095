import {
  attach,
  createDomain,
  sample,
  createEvent,
  createEffect,
  createStore,
  combine,
} from "effector";
import { createGate } from "effector-react";
import { createHatch } from "framework";
import { combineEvents } from "patronum/combine-events";
import { pending } from "patronum/pending";

import { Feedback } from "@app/api";

import { createTabs } from "@/widgets/content-tabs";
import { paymentManagement } from "@/widgets/payment";

import { courseModel } from "@/entities/course";
import { userCertificateVerified } from "@/entities/course/model/course-progress";
import { Course } from "@/entities/course/types";
import {
  CorrectAnswers,
  lessonModel,
  SingleTestStatus,
  StatusTest,
  FullTest,
} from "@/entities/lesson";
import { pageNotFound } from "@/entities/navigation";
import { paymentModel } from "@/entities/payment";
import { mapSpeakerToEntity, SpeakerEntity } from "@/entities/speaker";
import { $hasAuthenticatedUserId, refreshTokenFx } from "@/entities/user";
import {
  isCourseAvailable,
  userPurchasesModel,
  createCourseVisibilityChecker,
} from "@/entities/user-purchases";

import {
  CourseApi,
  LessonApi,
  SpeakerApi,
  FeedbacksAPI,
  UserApi,
  CourseFormatEnum,
} from "@/shared/api";
import { FEEDBACK_NAME_PLACEHOLDER } from "@/shared/constants";

export const hatch = createHatch(createDomain("CourseViewPage"));
export const CourseViewGate = createGate<{ courseId: string }>();
export const tabCourse = createTabs({ hatch, tabs: ["about", "materials", "tasks"] });

const currentCourseAccessChanged = createEvent<boolean>();
export const allTestStatusesUpdated = createEvent<FullTest[]>();
export const oneTestStatusesUpdated = createEvent<{ testId: string; status: SingleTestStatus }>();
export const resetTest = createEvent();
export const getCourseContentFx = createEffect(async (params: { courseId: string }) => {
  const course = await LessonApi.getLessonContent({ id: params.courseId });
  return course;
});
export const getCourseFx = createEffect(CourseApi.getCourseByIdFx);
export const getCourseFeedbacksFx = createEffect(FeedbacksAPI.fetchFeedbacks);
export const getUsersDisplayNamesFx = createEffect(UserApi.getUsersDisplayNames);
export const getCourseLessonsCountFx = attach({ effect: LessonApi.getCourseTotalLessonsCount });

const getProgressedCoursesFx = attach({
  source: $hasAuthenticatedUserId,
  effect: async (isAuth) => {
    if (isAuth) {
      const result = await CourseApi.getProgressCoursesFx();
      return result;
    }

    return [];
  },
});

export const getUserCoursesFx = createEffect(async ({ courseId }: { courseId: string }) => {
  return Promise.all([getCourseFx({ courseId }), getProgressedCoursesFx()]);
});

export const getCourseSpeakerFx = createEffect(async (params: { courseId: string }) => {
  const speaker = await SpeakerApi.getSpeakerByCourseId({ id: params.courseId });
  return mapSpeakerToEntity(speaker);
});

export const $currentCourse = createStore<Course | null>(null).on(
  getCourseFx.doneData,
  (_, course) => course,
);

export const $coursePrice = $currentCourse.map((course) => course?.definedTermPrice ?? 0);
export const $courseId = $currentCourse.map((course) => course?.id ?? "");
export const $courseName = $currentCourse.map((course) => course?.title ?? "");
export const $courseAbout = $currentCourse.map((course) => course?.about ?? "");
export const $courseImage = $currentCourse.map((course) => course?.image ?? "");

export const $speaker = createStore<SpeakerEntity | null>(null)
  .on(getCourseSpeakerFx.doneData, (_, speaker) => speaker)
  .reset(getCourseSpeakerFx.fail);

export const $tests = createStore<FullTest[]>([])
  .on(getCourseContentFx.doneData, (prev, courseContent) =>
    lessonModel.getLessonTests(courseContent),
  )
  .on(allTestStatusesUpdated, (_, updatedFullTest) => updatedFullTest)
  .on(oneTestStatusesUpdated, (state, payload) => {
    return state.map((test) => {
      if (test.id === payload.testId) {
        return {
          ...test,
          status: payload.status,
        };
      }
      return test;
    });
  })
  .on(resetTest, (state) => state.map((test) => ({ ...test, status: StatusTest.Default })));

export const $correctTestAnswers = createStore<CorrectAnswers[]>([]).on(
  getCourseContentFx.doneData,
  (_, course) => course.content.course.correct_answers,
);

export const $courseMaterials = createStore<LessonApi.Attribute[] | null>(null).on(
  getCourseContentFx.doneData,
  (_, courseContent) => (courseContent ? lessonModel.getLessonMaterials(courseContent) : null),
);

export const $videos = createStore<LessonApi.LessonVideo[]>([]).on(
  getCourseContentFx.doneData,
  (_, courseContent) => lessonModel.getLessonVideo(courseContent),
);

export const $hasCourseAccess = createStore(false).on(
  currentCourseAccessChanged,
  (_, hasAccess) => hasAccess,
);
export const $courseLessonsCount = createStore<number | null>(null).on(
  getCourseLessonsCountFx.doneData,
  (_, value) => (typeof value === "undefined" ? 0 : value),
);

export const $feedbacks = createStore<Feedback[]>([])
  .on(getCourseFeedbacksFx.doneData, (_, feedbacks) => [...feedbacks])
  .reset(getCourseFeedbacksFx.fail);

const $displayNames = createStore<Record<string, string>>({}).on(
  getUsersDisplayNamesFx.doneData,
  (_, displayNames) => ({ ...displayNames }),
);

export const $feedbacksFullInfo = combine($feedbacks, $displayNames, (feedbacksRaw, namesById) =>
  feedbacksRaw.map((f) => ({
    ...f,
    name: namesById[f.userId] || FEEDBACK_NAME_PLACEHOLDER,
  })),
);

export const $isCourseFetchPending = pending({
  effects: [getCourseFx, getCourseLessonsCountFx, getCourseContentFx],
  of: "some",
});

const courseVisibility = createCourseVisibilityChecker({ $course: $currentCourse });

sample({
  clock: getCourseFeedbacksFx.done,
  source: $feedbacks,
  fn: (feedbacks) => feedbacks.map((feedback) => feedback.userId),
  target: getUsersDisplayNamesFx,
});

sample({
  clock: getCourseFx.doneData,
  fn: () => CourseFormatEnum.online_course,
  target: paymentModel.loadPaymentCourseType,
});

sample({
  clock: getCourseSpeakerFx.doneData,
  target: paymentManagement.loadSpeakerForPayment,
});

sample({
  source: {
    boughtCourses: userPurchasesModel.$boughtCourses,
    currentCourse: $currentCourse,
    userSubscription: userPurchasesModel.$userSubscription,
  },
  fn: ({ boughtCourses, currentCourse, userSubscription }) =>
    currentCourse
      ? isCourseAvailable({ boughtCourses, userSubscription, course: currentCourse })
      : false,
  target: currentCourseAccessChanged,
});

sample({
  clock: hatch.enter,
  target: courseModel.readyToLoadCourses,
});

const initialEvents = [
  resetTest,
  getUserCoursesFx,
  paymentModel.coursePayInfoLoaded,
  getCourseSpeakerFx,
  getCourseContentFx,
  getCourseLessonsCountFx,
  userCertificateVerified,
];

sample({
  clock: CourseViewGate.state,
  filter: ({ courseId }) => Boolean(courseId),
  target: [getCourseFeedbacksFx, ...initialEvents, userCertificateVerified],
});

export const pageInitialized = combineEvents({
  events: {
    page: hatch.enter,
    user: refreshTokenFx.done,
  },
});

sample({
  clock: pageInitialized,
  fn: ({ page }) => ({ courseId: page.params.courseId }),
  target: initialEvents,
});

sample({
  clock: getUserCoursesFx.finally,
  target: courseVisibility.readyCheck,
});

sample({
  clock: courseVisibility.$isShow,
  filter: (isShow) => !isShow,
  target: pageNotFound,
});

sample({
  clock: getCourseFx.fail,
  target: pageNotFound,
});
