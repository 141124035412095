import { createEffect } from "effector";

import { CONFIG } from "@/shared/config";
import { Cookies } from "@/shared/lib/auth-lib";

import { createRequestFx } from "../../client/http";
import { COOKIES } from "../../constants";

export type FormSubmitPayload = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  agreement: boolean;
};

export type SubscribePayload = {
  name: string;
  surname: string;
  email: string;
};

const BASE_URL = "https://api.mindbox.ru/v3";

export const sendFormDataToMindboxFx = (pointOfContact: string) =>
  createEffect(async ({ name, surname, email, phone }: FormSubmitPayload) => {
    if (CONFIG.IS_DEV) {
      console.info(`Request sent with data: ${JSON.stringify({ name, surname, email, phone })}`);
      return;
    }
    const UUID = Cookies.get(COOKIES.mindboxDeviceUUID);
    const body = {
      customer: {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        firstName: name,
        lastName: surname,
        email,
        mobilePhone: phone,
        subscriptions: [
          {
            brand: "Muse",

            pointOfContact: "Email",
          },

          {
            brand: "Muse",

            pointOfContact: "SMS",
          },
        ],
      },
      pointOfContact,
    };

    await createRequestFx(
      `/operations/async?endpointId=${
        CONFIG.MINDBOX_ENDPOINT_ID
      }&operation=Website.SubscriptionInLending${UUID ? `&deviceUUID=${UUID}` : ""}`,
    )({
      baseUrl: BASE_URL,
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    });
  });

export const sendSubscribeDataToMindbox = async ({ name, surname, email }: SubscribePayload) => {
  const UUID = Cookies.get(COOKIES.mindboxDeviceUUID);

  const body = {
    customer: {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      firstName: name,
      lastName: surname,
      email,
      subscriptions: [
        {
          brand: "Muse",
          pointOfContact: "Email",
        },
        {
          brand: "Muse",
          pointOfContact: "SMS",
        },
      ],
    },
    pointOfContact: "muse.main_page.subscribe",
  };

  await createRequestFx(
    `/operations/async?endpointId=${
      CONFIG.MINDBOX_ENDPOINT_ID
    }&operation=Website.SubscriptionInLending${UUID ? `&deviceUUID=${UUID}` : ""}`,
  )({
    baseUrl: BASE_URL,
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
