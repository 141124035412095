import { boolean, object, string } from "yup";

import { errorMessages } from "@/shared/lib/form";
import { trimSpaceAndDash } from "@/shared/lib/strings";
import { validator } from "@/shared/lib/validators";

const nameRegExp = /^[A-Za-zА-яЁё-\s]*$/gi;
const phoneRegExp = /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/;

export const schema = object().shape({
  name: string()
    .transform(trimSpaceAndDash)
    .trim()
    .max(50, errorMessages.max(50))
    .required(errorMessages.required)
    .matches(nameRegExp, errorMessages.match),
  surname: string()
    .transform(trimSpaceAndDash)
    .trim()
    .max(50, errorMessages.max(50))
    .required(errorMessages.required)
    .matches(nameRegExp, errorMessages.match),
  email: string()
    .required(errorMessages.required)
    .test(
      "customEmailValidator",
      errorMessages.email,
      (value) => !value || validator.isEmail(value),
    ),
  phone: string().matches(phoneRegExp, errorMessages.match),
  agreement: boolean().notOneOf([false], errorMessages.required),
});
