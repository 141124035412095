/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { allSettled, createEvent, fork } from "effector";
import { Provider } from "effector-react/scope";
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Router } from "react-router";

import { history, initializeClientHistory } from "@/entities/navigation";

import { SharedDataFactory } from "@/shared/api";
import { CONFIG } from "@/shared/config";

import { initializePostHog } from "@/app/lib/posthog";
import { initializeClientSentry } from "@/app/lib/sentry";
import { createClientApplication } from "@/app/ssr/create-client-application";

import { Application } from "./application";
import { ErrorBoundary } from "./error-boundary";

const ready = createEvent();
createClientApplication();

const scope = fork({ values: (window as any)[CONFIG.INITIAL_STATE_NAME] });
delete (window as any)[CONFIG.INITIAL_STATE_NAME];

const sharedData = (window as any)[SharedDataFactory.KEY];
SharedDataFactory.set("isAuthenticate", sharedData.isAuthenticate);

initializeClientHistory(scope);
initializePostHog();
initializeClientSentry();

allSettled(ready, { scope }).then(() => {
  ReactDOM.hydrate(
    <ErrorBoundary>
      <HelmetProvider>
        <Router history={history!}>
          <Provider value={scope}>
            <Application />
          </Provider>
        </Router>
      </HelmetProvider>
    </ErrorBoundary>,
    document.querySelector("#root"),
  );
});

if (module.hot) {
  module.hot.accept();
}
