import clsx from "clsx";

import { HorizontalLinearBlocks, Typography } from "@/shared/ui";
import concert from "@/shared/ui/assets/images/home/communities/concert-industry.webp";
import culture from "@/shared/ui/assets/images/home/communities/culture.webp";
import digitalArt from "@/shared/ui/assets/images/home/communities/digital-art.webp";
import fashion from "@/shared/ui/assets/images/home/communities/fashion.webp";
import movie from "@/shared/ui/assets/images/home/communities/movie.webp";
import theatre from "@/shared/ui/assets/images/home/communities/theatre.webp";

type CommunityBlock = {
  title: string;
  img: string;
  className: string;
};

const bottom: CommunityBlock = {
  title: "концертная индустрия",
  img: concert,
  className: "h-[405px] 3xl:h-[607px]",
};

const rightFirst: CommunityBlock[] = [
  { title: "культурное\nнаследие", img: culture, className: "h-[220px] 3xl:h-[330px]" },
  { title: "мода", img: fashion, className: "h-[300px] 3xl:h-[450px]" },
  { title: "Цифровое\nискусство", img: digitalArt, className: "h-[275px] 3xl:h-[412px]" },
];

const rightLast: CommunityBlock[] = [
  { title: "современный\nтеатр", img: theatre, className: "h-[390px] 3xl:h-[585px]" },
  { title: "кино", img: movie, className: "h-[405px] 3xl:h-[607px]" },
];

const ZoomImage = ({ className, img, title }: CommunityBlock) => {
  return (
    <div className="relative w-full overflow-hidden">
      <Typography
        tag="h5"
        size="m-h5"
        className="whitespace-pre-wrap absolute md:top-mn-26 md:left-mn-26 3xl:top-mn-38 3xl:left-mn-38 z-10"
      >
        {title}
      </Typography>
      <img
        loading="lazy"
        src={img}
        className={clsx(
          "w-full hover:scale-[1.1] duration-500 transition-all object-cover object-bottom",
          className,
        )}
        alt=""
      />
    </div>
  );
};

export const CommunitiesGridBlock = () => {
  return (
    <div className="uppercase font-bold text-m-white flex">
      <div className="flex flex-col basis-1/2">
        <div className="bg-m-dark-blue md:pt-mn-50 md:pl-mn-20 3xl:pt-mn-74 3xl:pl-mn-38 3xl:h-[585px] h-[390px]">
          <Typography tag="h2" size="m-h2" className="md:mb-[20px] 3xl:mb-[30px]">
            Сообщества
          </Typography>
          <Typography tag="p" size="m-h6" className="font-m-sb-display">
            присоединяйся к сообществам
            <br /> творческих людей и становись
            <br />
            частью креативных индустрий
          </Typography>
        </div>
        <ZoomImage img={bottom.img} className={bottom.className} title={bottom.title} />
      </div>
      <div className="flex flex-row basis-1/2">
        <div className="flex flex-col w-1/2">
          {rightFirst.map((item) => (
            <ZoomImage
              key={item.title}
              title={item.title}
              img={item.img}
              className={item.className}
            />
          ))}
        </div>
        <div className="flex flex-col w-1/2">
          {rightLast.map((item) => (
            <ZoomImage
              key={item.title}
              title={item.title}
              img={item.img}
              className={item.className}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const CommunitiesHorizontalBlock = () => {
  const mergedCommunity = [bottom, ...rightLast, ...rightFirst];
  return (
    <HorizontalLinearBlocks
      imgClassName="w-[100px] h-[100px] object-cover"
      data={mergedCommunity}
      title="Сообщества"
      hideBottomArrow
      subTitle={
        <Typography tag="p" size="m-h6" className="font-m-sb-display font-bold">
          присоединяйся к сообществам
          <br /> творческих людей и становись
          <br />
          частью креативных индустрий
        </Typography>
      }
    />
  );
};
