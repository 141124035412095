import { useEvent, useStore } from "effector-react/scope";
import React from "react";

import { paths } from "@/pages/paths";

import { ButtonRounded, Modal, Link, Typography } from "@/shared/ui";

import { feedbackModal, testCompleteModal } from "../../model/certificate-issueing";

export const SuccessTestCompleteModal = () => {
  const isOpenModal = useStore(testCompleteModal.$isOpen);
  const close = useEvent(testCompleteModal.close);
  const openFeedbackModal = useEvent(feedbackModal.open);

  return (
    <Modal
      isOpen={isOpenModal}
      close={close}
      closeOnClickOverlay={false}
      render={() => (
        <Modal.Surface>
          <Modal.Header close={close} />
          <Modal.Body className="flex flex-col justify-center align-center text-center text-m-white">
            <Modal.Title>Ваш сертификат успешно оформлен</Modal.Title>
            <Typography tag="p" size="m-caption" className="font-m-sb-text font-normal pb-[24px]">
              И находится в{" "}
              <Link
                rel="noopener"
                nativeLink
                to={paths.studentCourses({ queryParams: { tab: "certificates" } })}
                target="_blank"
                className="text-m-orange !pt-0 underline relative"
                display="inline"
              >
                личном кабинете
              </Link>
              .
            </Typography>
            <Typography tag="p" size="m-caption" className="font-m-sb-text font-normal">
              Расскажите, как прошло ваше обучение.
            </Typography>
            <Typography tag="p" size="m-caption" className="font-m-sb-text font-normal pb-[24px]">
              Мы учитываем все отзывы при разработке <br />
              будущих курсов.
            </Typography>
            <div>
              <ButtonRounded
                variant="primary"
                className="font-semibold w-auto mb-mn-20"
                onClick={openFeedbackModal}
              >
                ОСТАВИТЬ ОТЗЫВ
              </ButtonRounded>
            </div>
          </Modal.Body>
        </Modal.Surface>
      )}
    />
  );
};
