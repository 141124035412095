import { createEvent, createStore, sample, Store } from "effector";

import { Course, UserProduct } from "@app/api";

import { $hasAuthenticatedUserId } from "@/entities/user";

import { contentStatusBuilder } from "@/shared/api";

import { isCoursePurchased } from "../lib/use-course-access";
import { $boughtCourses, $hasUserMuseSubscription } from "./user-product";

type ShowCourseToUser = {
  $course: Store<Course | null>;
};

const getVisibleState = ({
  boughtCourses,
  hasMuseSubscription,
  isAuthorized,
  course,
}: {
  boughtCourses: UserProduct[];
  hasMuseSubscription: boolean;
  isAuthorized: boolean;
  course: Course | null;
}) => {
  if (!course) return false;

  if (contentStatusBuilder().anyPublished().has(course.status)) {
    return true;
  }

  if (contentStatusBuilder().outOfSale().has(course.status)) {
    if (!isAuthorized) return false;

    if (isCoursePurchased(boughtCourses, course)) {
      return true;
    }

    return hasMuseSubscription;
  }

  return false;
};

export const createCourseVisibilityChecker = ({ $course }: ShowCourseToUser) => {
  const $isShow = createStore(false);
  const readyCheck = createEvent();

  sample({
    clock: readyCheck,
    source: {
      boughtCourses: $boughtCourses,
      hasMuseSubscription: $hasUserMuseSubscription,
      course: $course,
      isAuthorized: $hasAuthenticatedUserId,
    },
    fn: (data) => getVisibleState({ ...data }),
    target: $isShow,
  });

  return {
    $isShow,
    readyCheck,
  };
};
