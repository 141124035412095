import clsx from "clsx";

import { ButtonRounded } from "@/shared/ui";

type SubmitButtonProps = {
  isSubmitted: boolean;
  isValid: boolean;
  submitButtonClassName: string;
  submitButtonPressedClassName: string;
  submitButtonIsValidClassName: string;
  submitButtonName?: { name: string; submittedName: string };
};

export const SubmitButton = ({
  isSubmitted,
  isValid,
  submitButtonClassName,
  submitButtonPressedClassName,
  submitButtonIsValidClassName,
  submitButtonName = { name: "Отправить", submittedName: "Отправленно" },
}: SubmitButtonProps) => {
  return (
    <ButtonRounded
      className={clsx(submitButtonClassName, {
        [submitButtonPressedClassName]: isSubmitted,
        [submitButtonIsValidClassName]: isValid,
      })}
      variant="secondary"
      type="submit"
      disabled={isSubmitted || !isValid}
    >
      {isSubmitted ? submitButtonName.submittedName : submitButtonName.name}
    </ButtonRounded>
  );
};
