import clsx from "clsx";
import { ReactNode } from "react";

import styles from "./layout.module.scss";

type ContentHeightLayoutProps = {
  children?: ReactNode;
  className?: string;
};

export const ContentHeightLayout = ({ children, className }: ContentHeightLayoutProps) => {
  return <div className={clsx(styles.ContentLayoutHeight, className)}>{children}</div>;
};
