import clsx from "clsx";

import { ReactComponent as Trash } from "@/shared/ui/assets/images/icons/trash.svg";
import Maestro from "@/shared/ui/assets/images/payment-card-type-logo/maestro.png";
import Mastercard from "@/shared/ui/assets/images/payment-card-type-logo/mastercard.png";
import Mir from "@/shared/ui/assets/images/payment-card-type-logo/mir.png";
import Visa from "@/shared/ui/assets/images/payment-card-type-logo/visa_black.png";

type Props = {
  type: string;
  last4: string;
  paymentMethodId: string;
  className?: string;
  onRemove: (id: string) => void;
  isActive: boolean;
};

const cardTypeToLogoMap = {
  mir: Mir,
  maestro: Maestro,
  visa: Visa,
  mastercard: Mastercard,
};

export const SavedPaymentCard = ({
  onRemove,
  paymentMethodId,
  type,
  last4,
  isActive,
  className,
}: Props) => {
  const handleRemove = () => onRemove(paymentMethodId);

  return (
    <div
      className={clsx(
        `border-4 hover:border-m-dark-grey flex flex-col bg-m-grey rounded-[10px] h-[138px] w-[232px] px-[15px] pt-[15px] pb-[17px] ${className}`,
        isActive ? "border-m-orange" : "border-transparent",
      )}
    >
      <div className="flex justify-end">
        <button
          onClick={handleRemove}
          className="inline-flex items-center justify-center rounded-full bg-white h-[40px] w-[41px] group"
        >
          <Trash className="h-[20px] w-[19px] fill-m-magenta group-hover:fill-m-orange" />
        </button>
      </div>

      <div className="flex justify-between items-center mt-auto">
        <p className="font-m-sb-interface">&#8226;&#8226;&#8226;&#8226; {last4}</p>
        <div>
          <img alt="logo" src={cardTypeToLogoMap[type.toLowerCase()] ?? ""} />
        </div>
      </div>
    </div>
  );
};
