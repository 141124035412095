import React from "react";

import { Footer } from "@/widgets/footer";
import { Header } from "@/widgets/header";

import { ContentHeightLayout, SEO } from "@/shared/ui";
import { NotFound } from "@/shared/ui/components/error-layouts";

export const Error404Page = () => (
  <div className="bg-m-dark-blue">
    <SEO title="404" />
    <Header.Desktop subscribeButtonFixed />
    <Header.Mobile />
    <ContentHeightLayout>
      <NotFound />
    </ContentHeightLayout>
    <Footer />
  </div>
);
