import clsx from "clsx";
import { useEvent, useGate, useStore } from "effector-react/scope";

import { paths } from "@/pages/paths";

import { subscriptionChosen, SubscriptionsGate } from "@/widgets/payment/model/payment-management";

import {
  FullSubscriptionPayInfo,
  getTrialState,
  paymentModel,
  TrialState,
} from "@/entities/payment";
import { userPurchasesModel } from "@/entities/user-purchases";

import { formatDate } from "@/shared/lib/date";
import { getNumberEndingsWords } from "@/shared/lib/get-number-endings-word";
import { ButtonRounded, SkeletonLoader, Typography } from "@/shared/ui";
import Percent from "@/shared/ui/assets/images/icons/30.svg";
import { ReactComponent as Gift } from "@/shared/ui/assets/images/icons/gift-arctic.svg";
import Live from "@/shared/ui/assets/images/icons/live.svg";
import MuseIcon from "@/shared/ui/assets/images/icons/muse.svg";
import { ReactComponent as Reload } from "@/shared/ui/assets/images/icons/reload-arctic.svg";
import { ReactComponent as Settings } from "@/shared/ui/assets/images/icons/settings-arctic.svg";
import { ReactComponent as Tumbler } from "@/shared/ui/assets/images/icons/tumbler-arctic.svg";

type SubscriptionVariantProps = FullSubscriptionPayInfo & { trialState: TrialState };

const subscriptionPanelClasses =
  "pt-mn-20 pb-[14px] px-[20px] 3xl:pt-mn-30 3xl:px-[30px] 3xl:pb-mn-20 bg-m-white uppercase rounded-[20px] mb-mn-10 md:mb-mn-20 3xl:mb-mn-30";

export const SubscriptionVariantSkeleton = () => {
  return (
    <div className={subscriptionPanelClasses}>
      <div className="flex justify-between flex-col">
        <div className="mb-mn-10 3xl:mb-mn-20">
          <SkeletonLoader
            className="mb-[5px]"
            height={30}
            width="100%"
            variant="light"
            duration={0.9}
          />
          <SkeletonLoader height={15} width={200} variant="light" duration={0.9} />
        </div>
        <SkeletonLoader height={25} width={100} variant="light" duration={0.9} />
      </div>
    </div>
  );
};

export const SubscriptionBenefits = () => {
  const benefits = [
    {
      icon: (
        <img src={Percent} alt="30%" className="3xl:w-[185px] xl:w-[105px] w-[123px] md:w-[82px]" />
      ),
      text: (
        <div className="mt-mn-16 md:mt-mn-20 xl:mt-mn-38 3xl:mt-[65px]">
          <p className="mb-mn-10 3xl:mb-[18px]">
            Скидка на программы
            <br className="hidden md:block" /> дополнительного
            <br className="block md:hidden" /> профессионального образования
          </p>
        </div>
      ),
    },
    {
      icon: (
        <img
          src={MuseIcon}
          className="w-[56px] md:w-[58px] xl:w-[80px] 3xl:w-[120px]"
          alt="Muse logo"
        />
      ),
      text: (
        <p className="mt-mn-16 xl:mt-mn-26 3xl:mt-[46px]">
          Доступ к&nbsp;курсам, заданиям, дополнительным
          <br className="block md:hidden" /> материалам и&nbsp;возможность получать сертификаты
        </p>
      ),
    },
    {
      icon: (
        <img src={Live} alt="live" className="w-[100px] md:-[94px] xl:w-[129px] 3xl:w-[194px]" />
      ),
      text: (
        <p className="mt-mn-16 md:mt-mn-20 xl:mt-mn-38 3xl:mt-[65px]">
          Эксклюзивные трансляции, прямые включения
          <br className="block md:hidden" /> с&nbsp;лидерами индустрии и&nbsp;мастер-классы
          <br className="block md:hidden" /> в&nbsp;разделе{" "}
          <span className="text-m-arctic">LIVE</span>
        </p>
      ),
    },
  ];

  return (
    <div>
      <Typography
        tag="h2"
        size="m-h5"
        className="mb-[20px] md:mb-[46px] 3xl:mb-[65px] font-bold text-center md:text-left uppercase text-m-white"
      >
        Преимущества подписки
      </Typography>
      <ul className="flex flex-col md:flex-row items-center md:items-start justify-between">
        {benefits.map((item, i) => (
          <li
            key={i}
            className={clsx(
              "text-center text-m-white flex flex-col items-center mb-mn-30 md:basis-1/3 md:mr-mn-20 xl:mr-[54px] 3xl:mr-[82px] last:mr-0",
              {
                "order-1 md:order-none md:pt-mn-10": i === 0,
                "order-2 md:order-none md:pt-mn-10": i === 2,
              },
            )}
          >
            <div>{item.icon}</div>
            <div className="m-typography-input leading-[16px] md:leading-auto md:text-m-sm xl:text-m-base 3xl:text-[22px] font-sans">
              {item.text}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const SubscriptionCondition = ({
  alwaysShowCondition = true,
}: {
  alwaysShowCondition?: boolean;
}) => {
  const conditions = [
    {
      icon: <Settings />,
      text: (
        <p>
          Оформляя подписку, вы&nbsp;принимаете{" "}
          <a
            className="underline"
            target="_blank"
            href={paths.termsOfUseAgreementAssets()}
            rel="noopener noreferrer"
          >
            Пользовательское соглашение
          </a>{" "}
          и&nbsp;
          <a
            className="underline"
            target="_blank"
            href={paths.termsOfUsePrivacyAssets()}
            rel="noopener noreferrer"
          >
            Положение о&nbsp;конфиденциальности
          </a>
        </p>
      ),
    },
    {
      icon: <Gift />,
      text: (
        <p>
          В&nbsp;течение 24&nbsp;часов до&nbsp;окончания пробного периода произойдёт списание
          средств по&nbsp;выбранному тарифу. Воспользоваться пробным периодом можно один раз
        </p>
      ),
    },
    {
      icon: <Reload />,
      text: (
        <p>
          Подписка продлевается автоматически. Списание произойдёт в&nbsp;течение 24&nbsp;часов
          до&nbsp;окончания текущего расчётного периода
        </p>
      ),
    },
    {
      icon: <Tumbler />,
      text: (
        <p>
          Вы&nbsp;можете отказаться от&nbsp;автопродления подписки в&nbsp;любой момент,
          но&nbsp;не&nbsp;позднее чем за&nbsp;24&nbsp;часа до&nbsp;окончания её&nbsp;действия
        </p>
      ),
    },
  ];

  return (
    <>
      <div
        className={clsx("text-m-white pb-mn-50 md:pb-[64px] 3xl:pb-[100px]", {
          "hidden md:block": !alwaysShowCondition,
        })}
      >
        <Typography
          tag="h2"
          size="m-h5"
          className="text-center md:text-left mb-mn-20 md:mb-mn-30 3xl:mb-[45px] font-bold uppercase"
        >
          Условия подписки
        </Typography>
        <ul className="flex flex-col md:grid xl:flex xl:flex-row md:grid-cols-2 xl:items-baseline justify-between">
          {conditions.map((item, i) => (
            <li
              key={i}
              className="flex xl:basis-1/4 3xl:basis-auto 3xl:mr-mn-30 items-center md:flex-col md:items-start md:mr-mn-20 mb-mn-16 xl:mb-0 last:mr-0"
            >
              <div className="mr-mn-16 md:mb-[18px] 3xl:mb-[28px]">{item.icon}</div>
              <div className="m-typography-caption">{item.text}</div>
            </li>
          ))}
        </ul>
      </div>

      {!alwaysShowCondition && (
        <div className="md:hidden mb-[25px] flex items-center justify-center">
          <a
            rel="noreferrer noopener"
            target="_blank"
            className="text-center text-m-orange font-bold font-m-sb-display m-typography-h6 uppercase underline"
            href={paths.subscriptions()}
          >
            Условия подписки
          </a>
        </div>
      )}
    </>
  );
};

const SubscriptionPanelInfo = ({
  platformId,
  definedTermPrice,
  definedTermPeriod,
  trialInfo,
  trialState,
  ...rest
}: SubscriptionVariantProps) => {
  const currentSubscription = useStore(userPurchasesModel.$userSubscription);

  const handlers = useEvent({
    subscriptionChosen,
  });

  const handleChooseSubscription = () => {
    handlers.subscriptionChosen({
      platformId,
      definedTermPeriod,
      definedTermPrice,
      ...rest,
    });
  };

  const monthWord = getNumberEndingsWords(definedTermPeriod, ["месяц", "месяца", "месяцев"]);
  const buttonDisabledState = {
    [TrialState.TRIAL_ACTIVE]: true,
    [TrialState.TRIAL_INACTIVE_BUT_HAS_SUBSCRIPTION]: true,
    [TrialState.TRIAL_WAS_USED]: false,
    [TrialState.TRIAL_NOT_USED]: false,
  };

  const title = {
    [TrialState.TRIAL_ACTIVE]: "Действует пробный период",
    [TrialState.TRIAL_WAS_USED]: `Подписка на ${definedTermPeriod} ${monthWord}`,
    [TrialState.TRIAL_NOT_USED]: `${trialInfo.fullPeriodString} бесплатного доступа`,
    [TrialState.TRIAL_INACTIVE_BUT_HAS_SUBSCRIPTION]: `Подписка на ${definedTermPeriod} ${monthWord}`,
  };

  const description = {
    [TrialState.TRIAL_ACTIVE]: (
      <>
        Сумма списывается <br />
        однократным платежом
      </>
    ),
    [TrialState.TRIAL_WAS_USED]: (
      <>
        Сумма списывается <br />
        однократным платежом
      </>
    ),
    [TrialState.TRIAL_NOT_USED]: (
      <>
        Далее доступ ко всему контенту
        <br /> платформы на{" "}
        <span className="text-m-orange">
          {definedTermPeriod} {monthWord}
        </span>
      </>
    ),
    [TrialState.TRIAL_INACTIVE_BUT_HAS_SUBSCRIPTION]: (
      <>
        Сумма списывается однократным платежом <br />
        <span className="text-m-orange">
          Активна до {formatDate(currentSubscription?.expiredDate, "dd.MM.yyyy")}
        </span>
      </>
    ),
  };

  const buttonText = {
    [TrialState.TRIAL_ACTIVE]: "Оформлено",
    [TrialState.TRIAL_WAS_USED]: `Оформить`,
    [TrialState.TRIAL_NOT_USED]: `Оформить`,
    [TrialState.TRIAL_INACTIVE_BUT_HAS_SUBSCRIPTION]: `Оформлено`,
  };

  return (
    <div className={subscriptionPanelClasses}>
      <Typography
        tag="h5"
        size="m-h5"
        className="gradient-text mb-[5px] md:mb-[10px] 3xl:mb-[15px]"
      >
        {title[trialState]}
      </Typography>
      <Typography tag="p" size="m-label" className="text-m-blue mb-[12px] mb-[20px]">
        {description[trialState]}
      </Typography>
      <div className="w-full flex justify-between items-center">
        <span
          className={clsx("text-[20px] md:text-[28px] 3xl:text-[42px]", {
            "gradient-text": !buttonDisabledState[trialState],
            "text-m-dark-grey": buttonDisabledState[trialState],
          })}
        >
          {definedTermPrice}₽
        </span>

        <ButtonRounded
          variant="tertiary"
          onClick={handleChooseSubscription}
          disabled={buttonDisabledState[trialState]}
        >
          {buttonText[trialState]}
        </ButtonRounded>
      </div>
    </div>
  );
};

export const SubscriptionAccess = () => {
  useGate(SubscriptionsGate);
  const isLoadingSubscriptionData = useStore(paymentModel.$isLoadingSubscriptionData);

  const subscriptionsInfo = useStore(paymentModel.$subscriptionsPayment);
  const previousSubscription = useStore(userPurchasesModel.$hasPreviousSubscriptions);
  const isTrialActive = useStore(userPurchasesModel.$hasTrialSubscriptionActive);
  const userSubscription = useStore(userPurchasesModel.$userSubscription);

  const trialState = getTrialState({
    userSubscription,
    isTrialActive,
    hasPreviousTrial: previousSubscription,
  });

  return (
    <div className="flex justify-between font-bold font-m-sb-display mb-[25px] md:mb-mn-50 xl:mb-mn-111 flex-col md:flex-row">
      <div className="text-m-white md:mr-mn-20 md:w-[54%] xl:w-[60%]">
        <Typography tag="h1" size="m-h3" className="mb-[12px] md:mb-mn-20 3xl:mb-mn-30 uppercase">
          Бесплатный доступ ко&nbsp;всем творческим курсам
        </Typography>
        <Typography tag="p" size="m-text" className="font-normal font-sans mb-mn-20 md:mb-0">
          Образовательные программы, курсы <br className="block md:hidden" /> и&nbsp;мастер-классы
          <br className="hidden xl:block" /> для влюблённых
          <br className="block md:hidden" /> в&nbsp;культуру, искусство и&nbsp;технологии
        </Typography>
      </div>
      <div className="md:w-[42%] xl:w-[40%]">
        {isLoadingSubscriptionData ? (
          <>
            <SubscriptionVariantSkeleton />
          </>
        ) : (
          subscriptionsInfo.map((subscriptionInfo) => (
            <SubscriptionPanelInfo
              key={subscriptionInfo.productId}
              trialState={trialState}
              {...subscriptionInfo}
            />
          ))
        )}
      </div>
    </div>
  );
};

export const SubscriptionSectionCondition = ({
  alwaysShowCondition,
}: {
  alwaysShowCondition: boolean;
}) => {
  return (
    <>
      <SubscriptionAccess />
      <SubscriptionCondition alwaysShowCondition={alwaysShowCondition} />
    </>
  );
};

export const SubscriptionSectionBenefits = () => {
  return (
    <div className="py-[32px] px-mn-20 md:px-mn-26 xl:px-mn-50 md:py-mn-50 3xl:px-[62px] 3xl:py-[85px] bg-m-blue">
      <SubscriptionBenefits />
    </div>
  );
};
