import { ReactNode } from "react";

import { fromCDN } from "@/shared/lib/image/image";
import { Card } from "@/shared/ui";

import { Course } from "../../types";

export type CardProps = {
  course: Course;
  renderNode?: ReactNode;
  hoveredNode?: ReactNode;
};

export const CourseCard = (props: CardProps) => {
  const { course, renderNode = null, hoveredNode = null } = props;

  const img = fromCDN(course.image);
  const courseTopic = course?.courseTopics?.[0]?.topic.name ?? "";
  const target = course.target?.title ?? "";

  return (
    <Card
      labels={[courseTopic, target]}
      img={img}
      title={course.title}
      leftTopNode={renderNode}
      showNodeOnHover={hoveredNode}
    />
  );
};
