import clsx from "clsx";
import { HTMLAttributes } from "react";

import { ReactComponent as ShareIcon } from "@/shared/ui/assets/images/icons/share-icon.svg";

export const CourseShareButton = ({
  className,
  ...restProps
}: Omit<HTMLAttributes<HTMLButtonElement>, "className"> & { className?: string }) => (
  <button
    className={clsx(
      "flex justify-center items-center button-init border-2 border-m-dark-grey border-solid rounded-[7px] overflow-hidden background-transparent text-m-white hover:border-m-orange w-[30px] h-[30px] transition-colors p-mn-4",
      className,
    )}
    title="Поделиться"
    {...restProps}
  >
    <ShareIcon height="100%" />
  </button>
);
