import { paths } from "@/pages/paths";

import { SlideItem, SliderCategoryCourses } from "@/widgets/slider-category-courses";

import desktop1 from "@/shared/ui/assets/images/home/slides/desktop-1.webp";
import desktop2 from "@/shared/ui/assets/images/home/slides/desktop-2.webp";
import desktop3 from "@/shared/ui/assets/images/home/slides/desktop-3.webp";
import desktop4 from "@/shared/ui/assets/images/home/slides/desktop-4.webp";
import mobile1 from "@/shared/ui/assets/images/home/slides/mobile-1.webp";
import mobile2 from "@/shared/ui/assets/images/home/slides/mobile-2.webp";
import mobile3 from "@/shared/ui/assets/images/home/slides/mobile-3.webp";
import mobile4 from "@/shared/ui/assets/images/home/slides/mobile-4.webp";

import { useCourseCardAnalytics } from "@/feature/go-to-product-view";

import { useSpecificSliderAnalitics } from "./analytics";

export const SpecificProject = () => {
  const cardAnalytics = useCourseCardAnalytics();
  const sliderAnalytics = useSpecificSliderAnalitics();
  const content: SlideItem[] = [
    {
      itemId: "1",
      image: {
        srcS: mobile2,
        srcL: desktop2,
      },
      alt: "лаборатория спекулятивного дизайна",
      text: {
        category: <>практический интенсив</>,
        plainTitle: "лаборатория спекулятивного дизайна",
        title: (
          <>
            лаборатория
            <br /> спекулятивного
            <br /> дизайна
          </>
        ),

        paragraph: (
          <>
            Практический интенсив для тех, кто хочет <br className="md:hidden" />
            создавать <br className="hidden md:block" />
            проекты и продукты, <br className="md:hidden" />
            опережающие конкурентов
          </>
        ),
      },
      link: paths.speculativeDesign(),
      onClick: () =>
        cardAnalytics.cardClicked({
          courseId: "slide-2",
          formatCourse: "практический интенсив",
          courseName: "лаборатория спекулятивного дизайна",
          price: 0,
          linkText: "лаборатория спекулятивного дизайна - слайдер",
          linkUrl: paths.speculativeDesign(),
        }),
    },
    {
      itemId: "2",
      image: {
        srcS: mobile3,
        srcL: desktop3,
      },
      alt: "музейный менеджмент",
      text: {
        category: (
          <>
            дополнительное <br />
            профессиональное <br />
            образование
          </>
        ),
        plainTitle: "музейный менеджмент",
        title: (
          <>
            музейный
            <br /> менеджмент
          </>
        ),
        paragraph: (
          <>
            Программа для тех, кто хочет научиться
            <br className="md:hidden" /> создавать
            <br className="hidden md:block" /> современные проекты в области
            <br className="md:hidden" /> культурного наследия
          </>
        ),
      },
      link: paths.museum(),
      onClick: () =>
        cardAnalytics.cardClicked({
          courseId: "slide-3",
          formatCourse: "дополнительное профессиональное образование",
          courseName: "музейный менеджмент",
          price: 0,
          linkText: "музейный менеджмент - слайдер",
          linkUrl: paths.museum(),
        }),
    },
    {
      itemId: "3",
      image: {
        srcS: mobile1,
        srcL: desktop1,
      },
      alt: "Культура как бизнес",
      text: {
        category: (
          <>
            дополнительное <br />
            профессиональное <br />
            образование
          </>
        ),
        plainTitle: "Культура как бизнес",
        title: (
          <>
            Культура <br />
            как бизнес
          </>
        ),
        paragraph: (
          <>
            Программа для тех, кто хочет создавать современные <br className="hidden md:block" />и
            востребованные проекты в сфере культуры
          </>
        ),
      },
      link: paths.cultureBuisnes(),
      onClick: () =>
        cardAnalytics.cardClicked({
          courseId: "slide-1",
          formatCourse: "дополнительное профессиональное образование",
          courseName: "Культура как бизнес",
          price: 0,
          linkText: "Культура как бизнес - слайдер",
          linkUrl: paths.cultureBuisnes(),
        }),
    },
    {
      itemId: "4",
      image: {
        srcS: mobile4,
        srcL: desktop4,
      },
      alt: "фестивальный менеджмент",
      text: {
        category: (
          <>
            дополнительное <br />
            профессиональное <br />
            образование
          </>
        ),
        plainTitle: "фестивальный менеджмент",
        title: (
          <>
            фестивальный <br />
            менеджмент
          </>
        ),
        paragraph: (
          <>
            Программа для тех, кто хочет создавать, <br className="md:hidden" />
            продвигать <br className="hidden md:block" />и монетизировать собственные{" "}
            <br className="md:hidden" />
            музыкальные <br className="hidden md:block" />
            фестивали и концерты
          </>
        ),
      },
      link: paths.festival(),
      onClick: () =>
        cardAnalytics.cardClicked({
          courseId: "slide-4",
          formatCourse: "дополнительное профессиональное образование",
          courseName: "фестивальный менеджмент",
          price: 0,
          linkText: "фестивальный менеджмент - слайдер",
          linkUrl: paths.festival(),
        }),
    },
  ];

  return (
    <section>
      <SliderCategoryCourses
        controlType="button"
        controlVariant="secondary"
        slideImageClassName="h-[670px] md:h-[400px] object-cover xl:h-[43vw]"
        content={content}
        containerClassName="h-[670px] md:h-[400px] xl:h-[43vw]"
        onButtonNextClick={() => sliderAnalytics.sliderClicked("Спецпроекты")}
        onButtonPrevClick={() => sliderAnalytics.sliderClicked("Спецпроекты")}
        slideContentContainerClassName="absolute z-10 top-[70%] md:top-1/2 -translate-y-1/2 w-full md:w-[80vw] xl:w-full left-[20px] md:left-[30px] 3xl:left-[40px] pr-2"
        controlsClassName="absolute z-10 bottom-[31px] md:bottom-[20px] xl:bottom-[50px] left-1/2 -translate-x-1/2"
        controlText="Учиться"
      />
    </section>
  );
};
