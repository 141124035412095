import dzenLogo from "shared/ui/assets/images/socials/footer/dzen-logo.svg";
import mailLogo from "shared/ui/assets/images/socials/footer/mail-logo.svg";
import messageLogo from "shared/ui/assets/images/socials/footer/message-logo.svg";
import telegramLogo from "shared/ui/assets/images/socials/footer/telega-logo.svg";
import vkLogo from "shared/ui/assets/images/socials/footer/vk-logo.svg";
import whatsappLogo from "shared/ui/assets/images/socials/footer/whatsup-logo.svg";
import youtubeLogo from "shared/ui/assets/images/socials/footer/youtube.svg";

import { Image } from "@/shared/ui";

const SocialMap = {
  telegramLogo,
  vkLogo,
  whatsappLogo,
  dzenLogo,
  mailLogo,
  messageLogo,
  youtubeLogo,
};

const SocialMapHref = {
  telegramLogo: "https://t.me/muse_university",
  vkLogo: "https://vk.com/club219946225",
  whatsappLogo: "https://wa.me/message/AKASUR6YE77WN1",
  dzenLogo: "https://dzen.ru/muse_university",
  youtubeLogo: "https://www.youtube.com/@muse_university",
};

type Props = {
  type: keyof typeof SocialMap;
  link?: string;
  className?: string;
  classNameSvg?: string;
  onClick?: (e: React.MouseEvent) => void;
  socialGroupTitle?: string;
};

export const SocialButton = ({
  type,
  link,
  className,
  classNameSvg,
  onClick,
  socialGroupTitle = "default",
}: Props) => {
  if (onClick)
    return (
      <button
        data-testid={`social_button_${socialGroupTitle}_${type}`}
        onClick={onClick}
        className={
          className ||
          "w-10 h-10 flex justify-center items-center rounded-full border-[1px] border-m-blue hover:border-m-orange"
        }
      >
        <Image src={SocialMap[type]} alt={type} className={classNameSvg || ""} />
      </button>
    );

  return (
    <a
      data-testid={`social_button_${socialGroupTitle}_${type}`}
      href={link || SocialMapHref[type]}
      target="_blank"
      rel="noreferrer"
    >
      <div
        className={
          className ||
          "w-10 h-10 flex justify-center items-center rounded-full border-[1px] border-m-blue hover:border-m-orange"
        }
      >
        <Image src={SocialMap[type]} alt={type} className={classNameSvg || ""} />
      </div>
    </a>
  );
};
