import clsx from "clsx";
import { memo, ReactNode } from "react";

import greyCheck from "@/shared/ui/assets/images/icons/check-gradient-grey.svg";
import errorCheck from "@/shared/ui/assets/images/icons/check-gradient-magenta.svg";
import successCheck from "@/shared/ui/assets/images/icons/check-gradient.svg";

type CheckboxProps = {
  checked: boolean;
  onChange?: () => void;
  className?: string;
  checkBoxClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  checkArrowClassName?: string;
  id?: string;
  status?: "success" | "fail" | "default";
  title?: string | ReactNode;
};

const getCheckIcon = (status: CheckboxProps["status"]) => {
  const icons = {
    success: greyCheck,
    fail: errorCheck,
    default: successCheck,
  };

  return icons[status || "default"];
};

export const Checkbox = memo(
  ({
    checked = false,
    onChange,
    className,
    checkBoxClassName,
    checkArrowClassName,
    labelClassName,
    disabled,
    id,
    status,
    title,
    ...rest
  }: CheckboxProps) => {
    const successStatus = status === "success";
    const failStatus = status === "fail";

    const containerClasses = clsx("flex items-center h-5 relative hover:cursor-default", className);
    const checkBoxClasses = clsx(
      "mr-2 flex justify-center items-center overflow-hidden cursor-pointer w-mv-3 h-mv-3 border-m-dark-grey border border-solid",
      {
        "border border-m-dark bg-none": successStatus,
        "border border-m-dark-magenta bg-none": failStatus,
      },
      checkBoxClassName,
    );

    return (
      <div className="flex items-center">
        <div className={containerClasses}>
          <input
            aria-label="input"
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            className="sr-only"
            type="checkbox"
            id={id}
            {...rest}
          />
          <div
            id={id}
            onKeyUp={onChange}
            tabIndex={0}
            className={checkBoxClasses}
            onClick={onChange}
            role="checkbox"
            aria-checked={checked}
          >
            {checked && (
              <img src={getCheckIcon(status)} className={checkArrowClassName} alt="check" />
            )}
          </div>
        </div>

        <span
          onClick={onChange}
          role="presentation"
          className={clsx("cursor-pointer", labelClassName)}
        >
          {title}
        </span>
      </div>
    );
  },
);
