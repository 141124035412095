import { createEffect, createEvent, sample } from "effector";

import { authBySberId } from "@/shared/api/client/fetch-auth";

const authBySberIdFx = createEffect(authBySberId);

export const sberIdAuthClicked = createEvent();

sample({
  clock: sberIdAuthClicked,
  fn: () => null,
  target: authBySberIdFx,
});
