import clsx from "clsx";
import { useEvent, useStore } from "effector-react/scope";
import { useEffect } from "react";

import { pageOpened } from "@/shared/analytics/events/modules/favorites";
import { AcademyButton, ButtonOutline, Dropdown, EdutoriaButton, MenuOption } from "@/shared/ui";
import { ReactComponent as EdutoriaIcon } from "@/shared/ui/assets/images/icons/edutoria.svg";

import {
  $selectedCourseOption,
  coursesStatuses,
  courseStatusSelected,
  ViewOption,
} from "../model/education";

const isViewOption = (str: string): str is ViewOption =>
  str === ViewOption.completed ||
  str === ViewOption.edutoria ||
  str === ViewOption.favourite ||
  str === ViewOption.progressing ||
  str === ViewOption.academy;

export const StatusLessonChips = () => {
  const selectedOption = useStore($selectedCourseOption);
  const handlers = useEvent({ courseStatusSelected });

  const handleViewOptionChange = (value: string) => {
    handlers.courseStatusSelected(isViewOption(value) ? value : ViewOption.completed);
    if (value === ViewOption.favourite) pageOpened();
    if (value === ViewOption.edutoria || value === ViewOption.academy)
      handlers.courseStatusSelected(ViewOption.progressing);
  };

  useEffect(() => {
    return () => {
      handlers.courseStatusSelected(ViewOption.progressing);
    };
  }, []);

  return (
    <div className="flex flex-col md:flex-row md:gap-y-6 md:items-center md:justify-between">
      <div className="flex gap-x-[16px] xl:gap-x-[24px]">
        {coursesStatuses.map(({ value, label }) => {
          return (
            <ButtonOutline
              className={clsx(
                selectedOption.value === value ? "text-orange-gradient" : "text-m-white",
                "text-[11px] md:m-typography-h6",
              )}
              onClick={() => handleViewOptionChange(value)}
              key={value}
            >
              {label}
            </ButtonOutline>
          );
        })}
      </div>

      <div className="flex gap-x-4 3xl:gap-x-[24px]">
        <EdutoriaButton onClick={() => handleViewOptionChange(ViewOption.edutoria)} />
        <AcademyButton onClick={() => handleViewOptionChange(ViewOption.academy)} />
      </div>
    </div>
  );
};

const edutoriaOption: MenuOption = {
  value: ViewOption.edutoria,
  label: "EDUTORIA",
  icon: <EdutoriaIcon className="w-[22px]" />,
};

const academyOption: MenuOption = {
  value: ViewOption.academy,
  label: "Академия",
};

export const CourseStatusMenu = () => {
  const selectedOption = useStore($selectedCourseOption);
  const handlers = useEvent({ courseStatusSelected });

  const handleViewOptionChange = (value: string) => {
    handlers.courseStatusSelected(isViewOption(value) ? value : ViewOption.completed);
    if (value === ViewOption.favourite) pageOpened();
  };

  return (
    <Dropdown
      onSelect={({ value }) => {
        handleViewOptionChange(value);
      }}
      menuItems={[...coursesStatuses, edutoriaOption, academyOption]}
      selectedOptionLabel={selectedOption.label}
      classNameMobileMenu="!bg-m-dark-blue-80"
    />
  );
};
