import { createEvent, restore } from "effector";

import { CourseFormatEnum } from "@/shared/api";

export const setCourseToShare = createEvent<{
  courseId: string;
  courseName: string;
  format_course: CourseFormatEnum;
}>();

export const resetCourseToShare = createEvent();

export const $courseToShare = restore(setCourseToShare, null);

$courseToShare.reset(resetCourseToShare);
