import clsx from "clsx";
import { CSSProperties, FC, forwardRef, PropsWithChildren, ReactNode } from "react";

import styles from "./navbar.module.scss";

type NavbarProps = PropsWithChildren<{
  style?: CSSProperties;
  className?: string;
}>;

const NavbarBase = forwardRef<HTMLElement, NavbarProps>(
  ({ className, style: externalStyles, children }, ref) => (
    <header ref={ref} style={externalStyles} className={clsx([styles.Header, className])}>
      {children}
    </header>
  ),
);

NavbarBase.displayName = "NavbarBase";

const Body: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => (
  <div className={clsx("flex justify-between items-center w-full", className)}>{children}</div>
);

const Left: FC = ({ children }) => <div>{children}</div>;

const Menu: FC = ({ children }) => (
  <div className="flex justify-between items-center">{children}</div>
);

const Right: FC = ({ children }) => <div>{children}</div>;

export const Navbar = NavbarBase as typeof NavbarBase & {
  Left: typeof Left;
  Body: typeof Body;
  Menu: typeof Menu;
  Right: typeof Right;
};

Navbar.Left = Left;
Navbar.Body = Body;
Navbar.Menu = Menu;
Navbar.Right = Right;
