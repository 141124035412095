import { useEffect } from "react";

import { CONFIG } from "@/shared/config";

export const useMindboxScript = () => {
  useEffect(() => {
    let webpushTimer: NodeJS.Timeout | null = null;
    if (CONFIG.IS_DEV) return () => null;

    if (!document) return () => null;
    const scriptContent = document.createElement("script");
    const webpushContent = document.createElement("script");

    scriptContent.innerHTML = `
      mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
      mindbox.queue = mindbox.queue || [];
      mindbox('create', {
        endpointId: "${CONFIG.MINDBOX_ENDPOINT_ID}",
        firebaseMessagingSenderId: "43166582920",
      });
      mindbox("webpush.create");
  `;

    webpushContent.innerHTML = `
      mindbox("webpush.subscribe", {
        getSubscriptionOperation: "GetWebPushSubscription",
        subscribeOperation: "SubscribeToWebpush",
        onGranted: function () {},
        onDenied: function () {}
      });
  `;

    document.head.appendChild(scriptContent);

    const script = document.createElement("script");

    script.src = "https://api.mindbox.ru/scripts/v1/tracker.js";
    script.async = true;

    document.body.appendChild(script);

    webpushTimer = setTimeout(() => {
      document.body.appendChild(webpushContent);
    }, 5000);

    return () => {
      document.body.removeChild(script);
      if (webpushTimer) {
        clearTimeout(webpushTimer);
      }
    };
  }, []);
};
