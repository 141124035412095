import { ButtonStartSubscription } from "@/widgets/payment";

import { Breakpoint } from "@/shared/lib/responsive";
import { Image, Section } from "@/shared/ui";
import devicesMobile from "@/shared/ui/assets/images/home/devices_375.webp";
import devicesDesktop from "@/shared/ui/assets/images/home/devices_1920.webp";

export const SectionTogether = () => (
  <Section className="relative bg-white 2xl:min-h-[871px] xl:min-h-[613px] flex items-center py-20 xl:py-0 ">
    <Section.Content className="relative">
      <div>
        <div className="flex flex-col font-medium items-center text-m-blue text-m-xl xl:text-xl 2xl:text-m-5xl xl:max-w-[21rem] 2xl:max-w-md">
          <div className="px-9 sm:px-0">
            <h2>Изучай искусство, ремёсла и&nbsp;творческие профессии.</h2>
            <h2 className="gradient-text">Вместе с Музой.</h2>
          </div>
          <Image
            lazy
            className="mt-14 xl:mt-0 xl:absolute w-[372px] xl:w-[709px] 2xl:w-[56vw] xl:top-1/2 transform xl:-translate-y-1/2 xl:left-[22rem] 2xl:left-[29rem]"
            screens={[
              {
                breakpoint: Breakpoint.S,
                src: devicesMobile,
              },
              {
                breakpoint: Breakpoint.M,
                src: devicesDesktop,
              },
            ]}
            src={devicesDesktop}
          />

          <div className="mt-14 xl:mt-mv-5 self-center xl:self-start">
            <ButtonStartSubscription analytics={{ positionInfo: "footer" }} />
          </div>
        </div>
      </div>
    </Section.Content>
  </Section>
);
