import { attach, createEffect, createEvent, createStore, sample } from "effector";

import { CourseApi, LessonApi } from "@/shared/api";
import { sendCourseEndEvent, sendCourseStartEvent } from "@/shared/api/modules/course";

import { checkIfCourseCompleted, countProgressForCourse } from "../lib";

export const enum FetchState {
  Pending = "pending",
  Done = "done",
  Error = "error",
}

type CourseProgressMap = Record<string, number>;
type CourseProgressFetchMap = Record<string, FetchState>;

const getProgressForCourse = async (courseId: string) => {
  const [totalLessonsCount, completedLessonsCount] = await Promise.all([
    LessonApi.getCourseTotalLessonsCount({ courseId }),
    LessonApi.getCourseCompletedLessonsCount({ courseId }),
  ]);

  if (typeof totalLessonsCount !== "number" || typeof completedLessonsCount !== "number")
    throw Error();

  return countProgressForCourse(totalLessonsCount, completedLessonsCount);
};

export const courseLessonCompleted = createEvent<{ courseId: string }>();
export const readyToCountStudentProgressForCourses = createEvent<string[]>();

export const $courseProgressMap = createStore<CourseProgressMap>({});
export const $courseProgressFetchMap = createStore<CourseProgressFetchMap>({});

export const markCourseAsCompletedFx = attach({ effect: CourseApi.markCourseAsCompleted });

export const getProgressForCourseFx = createEffect(getProgressForCourse);
export const courseTestPassed = createEvent<{ courseId: string }>();

export const userCertificateVerified = createEvent<{ courseId: string }>();

const verifyUserCourseCertificationFx = createEffect(async ({ courseId }: { courseId: string }) => {
  const data = await CourseApi.verifyUserCourseCertification({ courseId });
  return data;
});

export const $isLoadingCheckCertificates = verifyUserCourseCertificationFx.pending;

export const $courseHasIssuedCertificate = createStore(false).on(
  verifyUserCourseCertificationFx.doneData,
  (_, xs) => Boolean(xs),
);

sample({
  clock: userCertificateVerified,
  target: verifyUserCourseCertificationFx,
});

$courseProgressMap.on(
  getProgressForCourseFx.done,
  (map, { params: courseId, result: progress }) => ({
    ...map,
    [courseId]: progress,
  }),
);

$courseProgressFetchMap
  .on(getProgressForCourseFx, (map, courseId) => ({
    ...map,
    [courseId]: FetchState.Pending,
  }))
  .on(getProgressForCourseFx.finally, (map, { params: courseId, status }) => ({
    ...map,
    [courseId]: status === "done" ? FetchState.Done : FetchState.Error,
  }));

sample({
  clock: courseLessonCompleted,
  fn: ({ courseId }) => courseId,
  target: getProgressForCourseFx,
});

sample({
  clock: getProgressForCourseFx.done,
  source: $courseHasIssuedCertificate,
  filter: (hasCertificate, { result: progress }) =>
    !hasCertificate && checkIfCourseCompleted(progress),
  fn: (_, { params: courseId }) => courseId,
  target: markCourseAsCompletedFx,
});

sample({
  clock: courseLessonCompleted,
  source: $courseProgressMap,
  filter: (progressMap, { courseId }) => !Object.keys(progressMap).includes(courseId),
  fn: (_, { courseId }) => courseId,
  target: sendCourseStartEvent,
});

sample({
  clock: markCourseAsCompletedFx.done,
  fn: ({ params }) => params,
  target: sendCourseEndEvent,
});

sample({
  clock: courseTestPassed,
  source: $courseProgressMap,
  filter: (map, { courseId }) => !map[courseId] || map[courseId] < 1,
  fn: (map, { courseId }) => courseId,
  target: markCourseAsCompletedFx,
});

readyToCountStudentProgressForCourses.watch((ids) => ids.forEach(getProgressForCourseFx));
