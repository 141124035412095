import {
  attach,
  createEffect,
  createEvent,
  createStore,
  merge,
  sample,
  Scope,
  scopeBind,
} from "effector";
import { createBrowserHistory } from "history";

import { getNewUrlSearchParams } from "./get-new-url-search-params";

/**
 * When history is already updated for some reason
 */
export interface HistoryUpdate {
  pathname: string;
  hash: string;
  search: string;
  state: unknown;
  action: "PUSH" | "POP" | "REPLACE";
}

/**
 * When you need to update history from effector model
 */
export interface HistoryChange {
  pathname?: string | undefined;
  search?: string | undefined;
  state?: unknown | undefined;
  hash?: string | undefined;
  key?: string | undefined;
}

export const history = process.env.BUILD_TARGET === "client" ? createBrowserHistory() : null;

export const $redirectTo = createStore("", { serialize: "ignore" });
export const $isManuallySetNotFound = createStore(false);

export const historyPush = createEvent<string | HistoryChange>();
export const historyReplace = createEvent<string | HistoryChange>();
export const pageNotFound = createEvent();

export const historyUpdated = createEvent<HistoryUpdate>();
export const searchParamsReset = createEvent();

$isManuallySetNotFound.reset(historyUpdated).on(pageNotFound, () => true);

export function initializeClientHistory(scope: Scope) {
  historyPush.watch((update) => history?.push(update));
  historyReplace.watch((update) => history?.replace(update));

  const boundHistoryUpdated = scopeBind(historyUpdated, { scope });
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  history!.listen(({ pathname, search, hash, state }, action) => {
    boundHistoryUpdated({
      pathname,
      search,
      state,
      hash,
      action,
    });
  });
}

// TODO: required to set $redirectTo on server side to the url that was requested
// If $redirectTo is different from url requested, then redirect to $redirectTo
export function initializeServerHistory() {
  const historyChange = merge([historyPush, historyReplace]);
  $redirectTo.on(historyChange, (prevPath, pathOrChange) => {
    if (typeof pathOrChange === "string") {
      return pathOrChange;
    }
    const url = new URL(pathOrChange.pathname || prevPath);
    url.search = pathOrChange.search || url.search;
    url.hash = pathOrChange.hash || url.hash;
    return url.toString();
  });
}

export const $currentRoute = createStore(history?.location.pathname || "").on(
  historyUpdated,
  (_, update) => update.pathname,
);

export const $currentLocation = createStore<HistoryChange>(history?.location || {}).on(
  historyUpdated,
  (_, update) => update,
);

type SearchParams = Record<string, string | undefined> | Record<string, string | undefined>[];

export const updateUrlSearchParams = async (searchParams: SearchParams) => {
  const updateFx = attach({
    source: $currentLocation,
    effect: createEffect(async (currentLocation: HistoryChange) => {
      historyReplace({
        pathname: currentLocation.pathname,
        search: getNewUrlSearchParams({
          currentSearch: currentLocation.search || "",
          valueObject: searchParams,
        }),
      });
    }),
  });

  await updateFx();
};

sample({
  clock: searchParamsReset,
  source: $currentLocation,
  fn: (currentLocation) => ({
    pathname: currentLocation.pathname,
    search: "",
  }),
  target: historyReplace,
});
