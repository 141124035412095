import clsx from "clsx";
import React from "react";

import { DivProps } from "../types";
import style from "./style.module.scss";

export type SurfaceProps = DivProps;

export const Surface: React.FC<SurfaceProps> = ({ className, children, ...rest }) => (
  <div className={clsx(style.Surface, className)} {...rest}>
    {children}
  </div>
);
