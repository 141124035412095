import { useStore, useEvent } from "effector-react/scope";

import { ContentTabs } from "@/widgets/content-tabs";

import { $courseLessonsCount, tabCourse } from "../../model";

export const CourseContentTabs = () => {
  const lessonsCount = useStore($courseLessonsCount);
  const currentTab = useStore(tabCourse.$currentTab);
  const changeTab = useEvent(tabCourse.tabChanged);

  const defaultTabs = [
    {
      title: "О курсе",
      name: "about",
      onClick: () => changeTab("about"),
    },
  ];

  const fullCourseTabs = [
    ...defaultTabs,
    {
      title: "Учебные материалы",
      name: "materials",
      onClick: () => changeTab("materials"),
    },
    {
      title: "Задания",
      name: "tasks",
      onClick: () => changeTab("tasks"),
    },
  ];

  return <ContentTabs tabs={lessonsCount ? fullCourseTabs : defaultTabs} activeTab={currentTab} />;
};
