import clsx from "clsx";
import { useEvent, useStore } from "effector-react/scope";
import { memo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Swiper from "swiper";

import { userPurchasesModel } from "@/entities/user-purchases";

import { analyticsEvents } from "@/shared/analytics";
import { UserApi } from "@/shared/api";
import { devices } from "@/shared/lib/responsive";
import { Slider, SliderControls } from "@/shared/ui";

import { RemoveCardModal } from "@/feature/delete-saved-payment-card";

import { SavedPaymentCard } from "./saved-payment-card";

type Props = {
  className?: string;
  onManageSubscription: () => void;
};

type SavedCards = UserApi.UserCardsInfo;

type ViewProps = {
  cards: SavedCards;
  onCardRemove: (id: string) => void;
};

const SliderView = ({
  cards,
  getSwiperInstance,
  onCardRemove,
}: ViewProps & {
  getSwiperInstance: (swiper: Swiper) => void;
}) => {
  return (
    <Slider
      data={cards.map(({ cardType, last4, paymentMethodId, isUsedForSubscription }) => (
        <SavedPaymentCard
          isActive={isUsedForSubscription}
          onRemove={onCardRemove}
          key={paymentMethodId}
          paymentMethodId={paymentMethodId}
          last4={last4}
          type={cardType}
        />
      ))}
      options={{
        autoplay: false,
        loop: true,
        breakpoints: {
          530: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        },
      }}
      getSwiperInstance={getSwiperInstance}
      slideClassName="flex justify-center"
    />
  );
};

const ListView = ({ cards, onCardRemove }: ViewProps) => {
  return (
    <>
      {cards.map(({ cardType, last4, paymentMethodId, isUsedForSubscription }) => (
        <SavedPaymentCard
          isActive={isUsedForSubscription}
          onRemove={onCardRemove}
          key={paymentMethodId}
          className="md:mr-[32px]"
          paymentMethodId={paymentMethodId}
          last4={last4}
          type={cardType}
        />
      ))}
    </>
  );
};

export const SavedPaymentCards = memo(({ className, onManageSubscription }: Props) => {
  const cards = useStore(userPurchasesModel.$userCardsInfo);
  const [swiperInstance, setSwiperInstance] = useState<Swiper | null>(null);
  const mdOrLess = useMediaQuery({ maxDeviceWidth: devices.md });
  const isSliderView = cards.length > 1 && mdOrLess;
  const handlers = useEvent({ openCancelFormDeleteCard: analyticsEvents.openCancelFormDeleteCard });

  const [selectedForDeletePaymentMethodId, setSelectedForDeletePaymentMethodId] = useState<
    null | string
  >(null);

  const onPaymentMethodRemove = (methodId: string) => {
    handlers.openCancelFormDeleteCard();
    setSelectedForDeletePaymentMethodId(methodId);
  };

  return (
    <div className={`w-full ${className}`}>
      <div className={clsx("flex items-center", { "justify-between": isSliderView })}>
        <h3
          className={clsx(
            "font-m-sb-display grow text-m-white font-semibold text-m-base leading-[22px] uppercase sm:text-left xl:text-m-xl 3xl:text-[36px] 3xl:leading-[41px]",
            { "text-center justify-center": !isSliderView },
            { "h-10 flex items-center": mdOrLess },
          )}
        >
          Мои карты
        </h3>

        {isSliderView && swiperInstance && (
          <SliderControls
            onSlideNext={() => swiperInstance.slideNext()}
            onSlidePrev={() => swiperInstance.slidePrev()}
            controlsClass="bg-m-white-10 border border-m-white child:text-m-white h-10 w-10"
          />
        )}
      </div>

      <div className="flex justify-center mt-[32px] md:justify-start">
        {isSliderView ? (
          <SliderView
            onCardRemove={onPaymentMethodRemove}
            cards={cards}
            getSwiperInstance={setSwiperInstance}
          />
        ) : (
          <ListView onCardRemove={onPaymentMethodRemove} cards={cards} />
        )}
      </div>

      {selectedForDeletePaymentMethodId && (
        <RemoveCardModal
          onManageSubscriptionClick={onManageSubscription}
          close={() => setSelectedForDeletePaymentMethodId(null)}
          isOpen
          paymentMethodId={selectedForDeletePaymentMethodId}
        />
      )}
    </div>
  );
});
