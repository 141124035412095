import clsx from "clsx";
import React, { FC } from "react";

import { VariantsAnswer, TestType, SingleTestStatus, StatusTest } from "@/entities/lesson";

import { Checkbox, Radio } from "@/shared/ui";

interface Props {
  selectedAnswers: string[];
  setSelectedAnswers: (selectedAnswers: VariantsAnswer["id"][]) => void;
  type: TestType;
  variantsAnswers: VariantsAnswer[];
  isTestPassed: boolean;
  testStatus: SingleTestStatus;
}

export const AnswerTest: FC<Props> = ({
  variantsAnswers,
  selectedAnswers,
  setSelectedAnswers,
  type,
  isTestPassed,
  testStatus,
}) => {
  const onToggleSingle = (id: string): void => {
    if (isTestPassed) return;

    setSelectedAnswers([id]);
  };

  const onToggleMultiple = (ids: string[]): void => {
    if (isTestPassed) return;

    const newAnswersIds = [...selectedAnswers];

    ids.forEach((id) => {
      if (newAnswersIds.includes(id)) {
        newAnswersIds.splice(newAnswersIds.indexOf(id), 1);
      } else {
        newAnswersIds.push(id);
      }
    });

    setSelectedAnswers(newAnswersIds);
  };

  const labelClasses = "flex-1 cursor-pointer -mt-[2px] 3xl:-mt-[4px]";
  const controlClasses = "mr-mv-0 mt-0 flex-2";

  return (
    <div className="mb-mv-4">
      {variantsAnswers.map(({ text, id }) => {
        const checked = selectedAnswers.includes(id);

        const testRowClasses = clsx("flex items-center text-m-sm 2xl:text-m-base text mb-0 p-mv-0", {
          "bg-m-green": testStatus === StatusTest.Success && checked,
          "bg-m-incorrect": testStatus === StatusTest.Fail && checked,
        });

        return (
          <div key={id + text} className={testRowClasses}>
            {
              {
                [TestType.Multiple]: (
                  <Checkbox
                    status={testStatus}
                    id={id}
                    checked={checked}
                    onChange={() => onToggleMultiple([id])}
                    title={text}
                  />
                ),
                [TestType.Single]: (
                  <>
                    <Radio
                      status={testStatus}
                      className={{ radio: controlClasses }}
                      checked={checked}
                      id={id}
                      onChange={() => onToggleSingle(id)}
                    />
                    <label
                      className={labelClasses}
                      role="presentation"
                      aria-label="radio"
                      onClick={(e) => {
                        e.preventDefault();
                        onToggleSingle(id);
                      }}
                      htmlFor={id}
                    >
                      {text}
                    </label>
                  </>
                ),
              }[type]
            }
          </div>
        );
      })}
    </div>
  );
};
