import { useCallback, useEffect } from "react";

export const useCopyPasteDisable = () => {
  const handleUserKeyPress = useCallback((event) => {
    const { keyCode } = event;
    if (keyCode === 17 || keyCode === 67) {
      event.preventDefault();
      return false;
    }

    return true;
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress, { passive: true });
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
};

export const disableCopyPasteHTMLElement = () => {
  return {
    onCopy: (e: React.ClipboardEvent<HTMLDivElement>) => {
      e.preventDefault();
      return false;
    },
    onPaste: (e: React.ClipboardEvent<HTMLDivElement>) => {
      e.preventDefault();
      return false;
    },
  };
};
