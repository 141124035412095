import { attach, createEffect, createEvent, createStore, sample } from "effector";
import posthog from "posthog-js";
import TagManager from "react-gtm-module";

import { CONFIG, IS_DEV_ENVIRONMENT, IS_QA_ENVIRONMENT } from "@/shared/config";

import { AnalyticsEventPayload, DataLayer } from "./types";

export const $analyticsUserId = createStore("");
export const $analyticsHasSubscription = createStore<1 | 0>(0);

const sendGoogleAnalyticsFx = attach({
  source: { userId: $analyticsUserId, hasSubscription: $analyticsHasSubscription },
  effect: ({ userId, hasSubscription }, data: DataLayer) => {
    const dataLayer: DataLayer = { ...data };

    if (userId) {
      dataLayer.userId = userId;
      dataLayer.role = "student";
      dataLayer.subscr = hasSubscription;
    }

    if (!dataLayer.event) {
      dataLayer.event = "autoEvent";
    }

    if (CONFIG.IS_DEV || IS_QA_ENVIRONMENT || IS_DEV_ENVIRONMENT) {
      console.info(
        '[Analytics] event: "%s", payload: %O',
        dataLayer.event_name ?? dataLayer.event,
        dataLayer,
      );

      return;
    }

    TagManager.dataLayer({ dataLayer });
  },
});

const sendPostHogAnalyticsFx = createEffect((data: DataLayer) => {
  const { event_name, ...rest } = data;

  const body = {
    event_name: event_name ?? "",
    payload: { ...rest },
  };

  posthog.capture(body.event_name, {
    ...body.payload,
  });
});

export const createAnalyticsEvent = <T = void>(fn: AnalyticsEventPayload<T>) => {
  const source = createEvent<T>();

  sample({
    source,
    fn,
    target: [sendGoogleAnalyticsFx, sendPostHogAnalyticsFx],
  });

  return source;
};

export const initAnalytics = () => {
  if (CONFIG.BUILD_ON_CLIENT && CONFIG.IS_PROD) {
    TagManager.initialize({
      gtmId: CONFIG.GTM_ID,
    });
  }
};
