import { useStore } from "effector-react/scope";

import { $authenticatedUserId } from "./auth";
import { $currentUser } from "./user";

export const useUserNameOrDefault = () => {
  const user = useStore($currentUser);

  const firstName = user?.first_name;
  const lastName = user?.last_name;

  if (!firstName && !lastName) {
    return { firstName: "Пётр", lastName: "Чайковский" };
  }

  return { firstName, lastName };
};

export const useIsUserAuthenticated = () => {
  const authenticatedUserId = useStore($authenticatedUserId);
  return Boolean(authenticatedUserId);
};
