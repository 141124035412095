import { TrialInfo, UserProduct } from "@/shared/api/modules/user";

export type SubscriptionPayInfo = {
  productId: string;
  productName: string;
  definedTermPeriod: number;
  definedTermPrice: number;
  platformId: string;
  monthPrice: string;
  productType: string;
};

type FullTrialInfo = TrialInfo & { fullPeriodString: string };

export type FullSubscriptionPayInfo = SubscriptionPayInfo & {
  trialInfo: FullTrialInfo;
};

export type CoursePayInfo = {
  definedTermPeriod: number;
  definedTermPrice: number;
  platformId: string;
  title: string;
  includedInSberEd: boolean;
};

export type PreselectedProduct = Pick<UserProduct, "id" | "productType">;

export enum PaymentType {
  Subscription = "Subcription",
  Course = "Course",
}

export enum TransactionStatus {
  SUCCESSFUL = "successful",
  CANCELED = "canceled",
  REQUIRES_CONFIRMATION = "requires_confirmation",
}

export enum CancellationReason {
  INVALID_CARD_DATA = "invalid_card_data",
  INSUFFICIENT_FUNDS = "insufficient_funds",
  OTHER = "other",
  ALREADY_PAID = "already_paid",
  NO_SUCH_PRODUCT = "no_such_product",
  EXPIRED_ON_CONFIRMATION = "expired_on_confirmation",
  PRODUCT_EXPIRED = "product_expired",
}

export type TransactionPaymentEvent = {
  status: TransactionStatus;
  cancellationReason: CancellationReason | null;
};
