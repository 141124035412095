import { createEvent, createStore, Effect, Event, sample } from "effector";

import { TransactionPaymentEvent, TransactionStatus } from "@/entities/payment";
import { $authenticatedUserId } from "@/entities/user";

type CreatePaymentTransactions = {
  reset: Event<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targetAfterSucceed: Effect<any, any>[];
};

export const createPaymentTransactions = ({
  reset,
  targetAfterSucceed,
}: CreatePaymentTransactions) => {
  const paymentSucceed = createEvent();
  const transactionStatusReset = createEvent();

  const transactionStatusChanged = createEvent<TransactionPaymentEvent>();

  const $transactionStatus = createStore<TransactionPaymentEvent | null>(null)
    .on(transactionStatusChanged, (_, status) => status)
    .reset(transactionStatusReset);

  sample({
    clock: reset,
    target: transactionStatusReset,
  });

  sample({
    clock: transactionStatusChanged,
    source: $transactionStatus,
    fn: (transaction) => transaction?.status === TransactionStatus.SUCCESSFUL,
    target: paymentSucceed,
  });

  sample({
    clock: paymentSucceed,
    source: $authenticatedUserId,
    target: targetAfterSucceed,
  });

  return {
    paymentSucceed,
    transactionStatusChanged,
    $transactionStatus,
    transactionStatusReset,
  };
};
