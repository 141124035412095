import clsx from "clsx";
import { ReactNode } from "react";

type TabProps = {
  children: string | ReactNode;
  isActive: boolean;
  onClick?: () => void;
};

export const Tab = ({ children, isActive, onClick }: TabProps) => {
  const tabClass = clsx(
    "relative text-m-dark font-semibold font-m-sb-display text-m-base xl:text-m-xl 3xl:text-m-3xl pb-mv-0 xl:pb-m-3 3xl:pb-mv-4 pt-1 pr-0 xl:pr-12 pl-0 -mb-[1px] grow",
    "after:content-[''] after:absolute after:h-[3px] after:bottom-0 after:left-0 after:right-mv-3 xl:after:right-mv-5 xl:after:last:right-0",
    "cursor-pointer whitespace-nowrap hover:gradient-text",
    "max-w-[50%] lg:max-w-[40%]",
    {
      "gradient-text after:bg-m-gradient": isActive,
    },
  );

  return (
    <div role="presentation" onClick={onClick} className={tabClass}>
      {children}
    </div>
  );
};
