import { createAnalyticsEvent } from "../../model/analytics";
import { PayType } from "../../model/types";

const enum PaymentEventName {
  OPEN_FORM_SUBSCRIPTION = "open_form_subscr",
  CLICK_PAY_SUBSCRIPTION = "click_pay_subscr",
  OPEN_WIDGET_PAY = "open_widget_pay",
  CLOSE_WIDGET_PAY = "close_widget_pay",
  ERROR_PAY = "error_pay",
  VIEW_COURSE_PURCHASE_SUCCESS = "view_course_purchase_success",
  VIEW_SUBSCR_PURCHASE_SUCCESS = "view_subscr_purchase_success",
  CLICK_CATALOG_PAGE = "click_catalog_page",
  CLICK_MENU = "click_menu",
  CLICK_EXTERNAL_CLICK = "click_external_click",
}

export const openFormSubscription = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: PaymentEventName.OPEN_FORM_SUBSCRIPTION,
  link_text: "Выбрать вариант подписки",
}));

export const clickPaySubscription = createAnalyticsEvent<{ link_text: string; link_type: string }>(
  ({ link_text, link_type }) => ({
    event: "autoEvent",
    event_name: PaymentEventName.CLICK_PAY_SUBSCRIPTION,
    link_text,
    link_type,
    topic: "subscr",
  }),
);

export const openWidgetPay = createAnalyticsEvent<{ topic: PayType }>(({ topic }) => ({
  event: "autoEvent",
  event_name: PaymentEventName.OPEN_WIDGET_PAY,
  topic,
}));

export const closeWidgetPay = createAnalyticsEvent<{ topic: PayType }>(({ topic }) => ({
  event: "autoEvent",
  event_name: PaymentEventName.CLOSE_WIDGET_PAY,
  topic,
}));

export const errorPay = createAnalyticsEvent<{
  topic: PayType;
  link_text: string;
}>(({ topic, link_text }) => ({
  event: "autoEvent",
  event_name: PaymentEventName.ERROR_PAY,
  topic,
  link_text,
}));

export const viewCoursePurchaseSuccess = createAnalyticsEvent<{
  course_id: string;
  price: string;
  course_name: string;
}>(({ course_id, course_name, price }) => ({
  event: "autoEvent",
  event_name: PaymentEventName.VIEW_COURSE_PURCHASE_SUCCESS,
  price,
  course_id,
  course_name,
  topic: "course",
}));

export const viewSubscrPurchaseSuccess = createAnalyticsEvent<{
  id: string;
  price: string;
}>(({ id, price }) => ({
  event: "autoEvent",
  event_name: PaymentEventName.VIEW_SUBSCR_PURCHASE_SUCCESS,
  price,
  id,
  topic: "subscr",
}));

export const clickCatalogPage = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: PaymentEventName.CLICK_CATALOG_PAGE,
  link_type: "button",
  link_text: "К курсам",
}));

export const clickSubscriptionBenefitsMenu = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: PaymentEventName.CLICK_MENU,
  link_text: "Преимущества подписки",
  link_type: "footer",
}));

export const clickTermsOfUse = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: PaymentEventName.CLICK_MENU,
  link_text: "Пользовательское соглашение",
  link_url: "https://edutoria.ru/termsofuse/user/agreement",
}));

export const clickPrivacyPolicy = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: PaymentEventName.CLICK_MENU,
  link_text: "Положение о конфиденциальности",
  link_url: "https://edutoria.ru/termsofuse/user/privacypolicy",
}));

export const clickEdutoriaLink = createAnalyticsEvent<{ link_type: string }>(({ link_type }) => ({
  event: "autoEvent",
  event_name: PaymentEventName.CLICK_EXTERNAL_CLICK,
  link_text: "edutoria.ru",
  link_url: "https://edutoria.ru",
  link_type,
}));
