import { contract, withHatch } from "framework";

import * as model from "./model";
import * as page from "./page";

contract({
  page,
  model,
});

export const Jazz100Page = withHatch(model.hatch, page.Jazz100Page);
