import { createEvent, sample } from "effector";

import { analyticsEvents } from "@/shared/analytics";

import { $courseId, $courseName } from "../../model";

export const goToTestClicked = createEvent();

export const trackScrollHeaderMaterials = createEvent();

sample({
  clock: goToTestClicked,
  source: { course_id: $courseId, course_name: $courseName },
  fn: ({ course_id, course_name }) => ({
    course_id,
    course_name,
  }),
  target: analyticsEvents.completeText,
});

sample({
  clock: trackScrollHeaderMaterials,
  source: {
    course_id: $courseId,
    course_name: $courseName,
  },
  target: analyticsEvents.interactionBlockMaterials,
});
