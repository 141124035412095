import { useEvent, useStore } from "effector-react/scope";

import type { TopicFilter } from "../model/create-topic-filters";
import type { Filter } from "./create-filter";
import { ApplyFilters } from "./create-filter";

export const useTopicFilters = (topicUnit: TopicFilter) => {
  return {
    selectedTopics: useStore(topicUnit.$selectedTopicsIds),
    allTopics: useStore(topicUnit.$allTopics),
    selectTopic: useEvent(topicUnit.topicSelected),
    setTopics: useEvent(topicUnit.topicsSettled),
    initialize: useEvent(topicUnit.initialized),
    isDisabled: useStore(topicUnit.$isDisabled),
  };
};

export const useFilter = <T>(filterUnit: Filter<T>) => {
  return {
    change: useEvent(filterUnit.changed),
    value: useStore(filterUnit.$value),
    reset: useEvent(filterUnit.reset),
    isDisabled: useStore(filterUnit.$isDisabled),
  };
};

export const useApplyFilter = (filterListUnit: ApplyFilters) => {
  return {
    apply: useEvent(filterListUnit.applied),
    filters: useStore(filterListUnit.$filters),
    hasAppliedFilters: useStore(filterListUnit.$hasAppliedFilters),
    resetSelectedFilter: useEvent(filterListUnit.resetSelectedFilter),
    applyCancel: useEvent(filterListUnit.applyCanceled),
  };
};
