import { useStore, useStoreMap } from "effector-react/scope";
import React, { memo, ReactNode } from "react";
import { Link } from "react-router-dom";

import { paths } from "@/pages/paths";

import {
  $courseHasIssuedCertificate,
  $isLoadingCheckCertificates,
} from "@/entities/course/model/course-progress";

import { ButtonRounded, SkeletonLoader, Spinner } from "@/shared/ui";

import { $courseAbout, $hasCourseAccess, $isCourseFetchPending, tabCourse } from "../../../model";
import { CourseMaterials, GoToTestButton } from "../../course-lesson-materials";
import {
  SuccessTestCompleteModal,
  CourseTest,
  FeedbackFormModal,
  CertificateModal,
  FeedbackSuccessModal,
} from "../../course-lesson-test";
import { ContentLockLayout } from "./content-lock-layout";

const TestFinished = () => {
  return (
    <div className="text-center mt-[48px] xl:mt-[94px] 3xl:mt-[122px]">
      <p className="font-m-sb-display font-semibold text-m-xl leading-[22px] text-m-dark mb-[16px] 3xl:text-m-3xl 3xl:leading-[26px] 3xl:mb-[24px]">
        Тест успешно пройден!
      </p>

      <Link to={`${paths.studentCourses({ queryParams: { tab: "certificates" } })}`}>
        <ButtonRounded>посмотреть сертификат</ButtonRounded>
      </Link>
    </div>
  );
};

const ShowTab = ({ tab, children }: { tab: string; children: ReactNode }) => {
  const isActive = useStoreMap({
    store: tabCourse.$currentTab,
    keys: [tab],
    fn: (currentTab, [target]) => currentTab === target,
  });

  if (!isActive) {
    return null;
  }

  return <div className="font-sans text-m-xl pt-mv-6">{children}</div>;
};

export const ModalsAfterTestComplete = memo(() => {
  const hasCourseAccess = useStore($hasCourseAccess);

  if (!hasCourseAccess) return null;

  return (
    <>
      <CertificateModal />
      <FeedbackFormModal />
      <SuccessTestCompleteModal />
      <FeedbackSuccessModal />
    </>
  );
});

const TestSection = () => {
  const userHasCourseCertificate = useStore($courseHasIssuedCertificate);
  const isLoadingCheckCertificates = useStore($isLoadingCheckCertificates);

  if (isLoadingCheckCertificates)
    return (
      <div className="flex justify-center items-center h-[300px]">
        <Spinner.Puff />
      </div>
    );

  return (
    <>
      {userHasCourseCertificate ? (
        <TestFinished />
      ) : (
        <div className="relative">
          <CourseTest />
          <ContentLockLayout />
        </div>
      )}
    </>
  );
};

export const CourseContent = () => {
  const courseAbout = useStore($courseAbout);
  const hasCourseAccess = useStore($hasCourseAccess);
  const isCourseFetchPending = useStore($isCourseFetchPending);

  return (
    <>
      <ShowTab tab="about">
        <div className="text-paragraph whitespace-pre-wrap">
          {isCourseFetchPending ? (
            <SkeletonLoader count={5} height="1em" variant="light" />
          ) : (
            courseAbout
          )}
        </div>
      </ShowTab>
      <ShowTab tab="materials">
        <div className="relative">
          <CourseMaterials />
          <ContentLockLayout />
        </div>
        {hasCourseAccess && (
          <div className="hidden xl:block">
            <GoToTestButton />
          </div>
        )}
      </ShowTab>
      <ShowTab tab="tasks">
        <ModalsAfterTestComplete />
        <TestSection />
      </ShowTab>
    </>
  );
};
