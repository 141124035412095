import { createGqlEffect } from "@/shared/api";

import { apiInstance } from "../../bff";

export const fetchFeedbacks = createGqlEffect(apiInstance.feedback.fetchFeedbacks);

export const fetchFeedbacksStatistic = createGqlEffect(
  apiInstance.feedback.fetchFeedbacksStatistic,
);

export const sendFeedback = createGqlEffect(apiInstance.feedback.sendFeedback);
