import { useEvent, useStore } from "effector-react/scope";
import { Link } from "react-scroll";

import { trackScrollHeaderMaterials } from "../analytics";
import { $headingsMaterials } from "../model/materials";

export const MaterialHeaders = () => {
  const headings = useStore($headingsMaterials);

  const analyticsHandlers = useEvent({ trackScrollHeaderMaterials });

  if (!headings) return null;

  return (
    <ul role="menu" className="text-m-dark">
      {headings.map((header) => (
        <li
          role="menuitem"
          className="hover:text-m-orange xl:text-m-base 3xl:text-m-3xl mb-mv-3 cursor-pointer font-m-sb-display"
          key={header.id}
        >
          <Link
            smooth
            spy
            activeClass="text-m-orange"
            to={header.id}
            offset={-50}
            onSetActive={() => analyticsHandlers.trackScrollHeaderMaterials()}
          >
            {header.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};
