import { useEvent } from "effector-react/scope";
import { useEffect } from "react";

import { startRequestingRefreshToken, stopRequestingRefreshToken } from "./auth";

export const useRefreshToken = () => {
  const startRefreshRequest = useEvent(startRequestingRefreshToken);
  const stopRefreshRequest = useEvent(stopRequestingRefreshToken);

  useEffect(() => {
    startRefreshRequest();

    return () => {
      stopRefreshRequest();
    };
  }, []);
};
