import { useEvent, useStore } from "effector-react/scope";

import { CountdownTimerModel } from "./countdown-timer";

export type CountdownTimer = {
  preventCountdownStart: () => void;
  startCountdown: (payload: number) => number;
  startInterval: () => void;
  stopInterval: () => void;
  timeout: number;
  countdownDisabled: boolean;
};

export const useCountdownTimer = (unit: CountdownTimerModel): CountdownTimer => {
  return {
    preventCountdownStart: useEvent(unit.preventCountdownStart),
    startCountdown: useEvent(unit.startCountdown),
    startInterval: useEvent(unit.startInterval),
    stopInterval: useEvent(unit.stopInterval),
    timeout: useStore(unit.$timeout),
    countdownDisabled: useStore(unit.$countdownDisabled),
  };
};
