import { useStore } from "effector-react/scope";
import React, { useRef, useState } from "react";
import { Swiper as SwiperClass } from "swiper";

import loadable from "@loadable/component";

import { $courseId, $feedbacksFullInfo } from "@/pages/course-view/model";
import { paths } from "@/pages/paths";

import { FeedbackCard, FeedbackCardHandle } from "@/entities/feedback/ui/feedback-card";
import { StarRatingProps } from "@/entities/feedback/ui/star-rating";

import { useDevices } from "@/shared/lib/responsive";
import { Container, LinkWithArrow, SliderBullets, SliderControls, Typography } from "@/shared/ui";
import DefaultAvatar from "@/shared/ui/assets/images/user-avatar/default.jpeg";

const ReviewsSlider = loadable(
  () =>
    import("@/pages/course-view/modules/course-feedbacks/ui/slider").then(
      (component) => component.ReviewsSlider,
    ),
  {
    fallback: <></>,
  },
);

const AllReviewsLink = () => {
  const courseId = useStore($courseId);

  return (
    <LinkWithArrow
      text="Все отзывы"
      redirectType="spa"
      link={paths.courseFeedbacksView(courseId)}
    />
  );
};

export const CourseFeedbacks = () => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { isMobile } = useDevices();

  const feedbacks = useStore($feedbacksFullInfo);

  const hasCourses = feedbacks.length > 0;

  const cardRefs = useRef<(FeedbackCardHandle | null)[]>([]);

  const cards = feedbacks.map((feedback, index) => (
    <Container>
      <FeedbackCard
        ref={(handle) => {
          cardRefs.current[index] = handle;
        }}
        key={feedback.id}
        title={feedback.name}
        text={feedback.feedback || ""}
        rating={feedback.rating as StarRatingProps["value"]}
        avatarSrc={feedback.avatar || DefaultAvatar}
      />
    </Container>
  ));

  const onNext = () => {
    swiperInstance?.slideNext();
  };

  const onPrev = () => {
    swiperInstance?.slidePrev();
  };

  const getSwiperInstance = (swiper: SwiperClass) => {
    setSwiperInstance(swiper);
    swiper.on("activeIndexChange", ({ activeIndex: activeIndexNumber }) => {
      setActiveIndex(activeIndexNumber % cards.length);
      cardRefs.current.forEach((handle) => handle?.resetExpand());
    });
  };

  if (!hasCourses) return null;

  return (
    <section className="py-[50px]">
      <Container className="pb-[55px]">
        <div className="flex justify-between items-center">
          <Typography tag="h2" size="m-h5" className="text-m-white uppercase font-bold">
            отзывы
          </Typography>
          {!isMobile && swiperInstance && cards.length > 1 && (
            <SliderControls
              onSlideNext={onNext}
              onSlidePrev={onPrev}
              classNameContainer="gap-mv-3"
              controlsClass="bg-transparent border border-m-white child:text-m-white hover:border-m-orange"
            />
          )}
          {isMobile && <AllReviewsLink />}
        </div>
      </Container>
      <Container className="mb-[37px]" mode="fluid">
        <ReviewsSlider getSwiperInstance={getSwiperInstance} cardsData={cards} />
        {isMobile && swiperInstance && (
          <SliderControls
            controlsClass="!bg-m-white hidden md:flex"
            classNameContainer="child:text-m-orange justify-center mt-[33px] md:mt-[0px]"
            navigationRenderPreset="circle"
            renderBetweenControls={<SliderBullets data={cards} activeSlide={activeIndex} />}
            onSlideNext={onNext}
            onSlidePrev={onPrev}
          />
        )}
      </Container>
      {!isMobile && (
        <Container className="flex justify-end align-center">
          <AllReviewsLink />
        </Container>
      )}
    </section>
  );
};
