import clsx from "clsx";

import { Menu } from "@/shared/ui";

import { useMenuItems } from "../lib/use-menu-items";

type HeaderMenuProps = {
  menuClassName?: string;
  linkClassName?: string;
  handleMenuClick?: () => void;
};

export const HeaderMenu = ({
  menuClassName = "",
  linkClassName = "",
  handleMenuClick = () => null,
}: HeaderMenuProps) => {
  const menu = useMenuItems({ device: "desktop" });

  return (
    <Menu className={menuClassName}>
      {menu.map(({ to, title, onClick, nativeLink }) => (
        <Menu.Link
          target="_self"
          classNameLink="active:text-red"
          className={clsx(linkClassName, "md:mr-mv-4 text-m-sm 3xl:text-m-base")}
          to={to}
          key={title}
          onClick={onClick || handleMenuClick}
          exact
          nativeLink={nativeLink}
        >
          {title}
        </Menu.Link>
      ))}
    </Menu>
  );
};
