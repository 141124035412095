import { combine, createEffect, createEvent, createStore, restore, sample, split } from "effector";

import { courseTestPassed, userCertificateVerified } from "@/entities/course";
import { SpeakerEntity } from "@/entities/speaker";
import { $currentUser, getUserFx } from "@/entities/user";

import { CourseApi, FeedbacksAPI, UserApi } from "@/shared/api";
import { createModalState } from "@/shared/lib/effector";
import { scrollToElement } from "@/shared/lib/scroll";

import { $courseId, $courseName, $speaker } from "../../../model";
import { SCROLL_TO_LESSON_TABS } from "../../constants";

export const enum CanIssueCertificateCheck {
  CanIssue,
  MustFillName,
}

type CourseCertificatePayload = {
  name: string;
  surname: string;
  courseName: string;
  courseId: string;
  speaker: SpeakerEntity | null;
  issueDate: string;
  nameFontSize: number;
};

export type CertificateForm = { name: string; surname: string };
export type FeedbackForm = { rating: number; commentText: string };

const issueCertificate = async ({ courseName, courseId }: CourseCertificatePayload) => {
  await CourseApi.markCourseAsCompleted(courseId);
  await CourseApi.createCertificate(courseId);
  return { courseId, courseName };
};

const sendFeedback = async (data: FeedbackForm & { courseId: string }) => {
  return FeedbacksAPI.sendFeedback(data);
};

export const modalOpenCertificateChanged = createEvent<boolean>();
export const certificateModalOpened = modalOpenCertificateChanged.prepend(() => true);
export const certificateModalClosed = modalOpenCertificateChanged.prepend(() => false);
export const $isModalCertificateOpen = restore(modalOpenCertificateChanged, false);

export const studentTriedToGetCertificate = createEvent();
export const certificateFormSubmitted = createEvent<CertificateForm>();

const certificateReadyToBeIssued = createEvent();
const certificateIssued = createEvent();

export const feedbackSent = createEvent<FeedbackForm>();
export const nameFontSizeCalculated = createEvent<number>();

const $nameFontSize = createStore<number>(0).on(nameFontSizeCalculated, (_, value) => value);

export const feedbackModal = createModalState({ defaultState: false });
export const feedbackSentModal = createModalState({ defaultState: false });
export const testCompleteModal = createModalState({ defaultState: false });

const handleSubmittedCertificateFormFx = createEffect(
  async ({
    name,
    surname,
    courseName,
    courseId,
    speaker,
    issueDate,
    nameFontSize,
  }: CourseCertificatePayload) => {
    await UserApi.updateUser({ userInfo: { first_name: name, last_name: surname } });
    return issueCertificate({
      name,
      surname,
      courseName,
      courseId,
      speaker,
      issueDate,
      nameFontSize,
    });
  },
);

const issueCertificateFx = createEffect(issueCertificate);
const sendFeedbackFx = createEffect(sendFeedback);

export const $canIssueCertificateCheck = combine([$currentUser], ([currentUser]) => {
  if (!currentUser?.first_name || !currentUser?.last_name) {
    return CanIssueCertificateCheck.MustFillName;
  }

  return CanIssueCertificateCheck.CanIssue;
});

sample({
  clock: certificateFormSubmitted,
  source: {
    speaker: $speaker,
    courseName: $courseName,
    courseId: $courseId,
    nameFontSize: $nameFontSize,
  },
  fn: (course, form) => ({
    ...course,
    ...form,
    issueDate: new Date().toISOString(),
  }),
  target: handleSubmittedCertificateFormFx,
});

// если нет имени/фамилии, то открываем модалку
split({
  source: sample({
    clock: studentTriedToGetCertificate,
    source: $canIssueCertificateCheck,
  }),
  match: {
    mustFillName: (e) => e === CanIssueCertificateCheck.MustFillName,
  },
  cases: {
    mustFillName: certificateModalOpened,
    __: certificateReadyToBeIssued,
  },
});

// когда уже есть имя/фамилия
sample({
  clock: certificateReadyToBeIssued,
  source: {
    name: $currentUser.map((user) => user?.first_name),
    surname: $currentUser.map((user) => user?.last_name),
    courseName: $courseName,
    courseId: $courseId,
    speaker: $speaker,
    nameFontSize: $nameFontSize,
  },
  filter: (payload): payload is CourseCertificatePayload =>
    Boolean(payload.name && payload.surname && payload.courseName && payload.courseId),
  target: issueCertificateFx,
});

sample({
  clock: handleSubmittedCertificateFormFx.finally,
  target: [certificateModalClosed],
});

sample({
  clock: [handleSubmittedCertificateFormFx.done, issueCertificateFx.done],
  target: certificateIssued,
});

sample({
  clock: certificateIssued,
  source: { courseId: $courseId },
  target: courseTestPassed,
});

sample({
  clock: certificateIssued,
  target: testCompleteModal.open,
});

sample({
  clock: feedbackSent,
  target: sendFeedbackFx,
  fn: (courseId, feedbackForm) => ({ courseId, ...feedbackForm }),
  source: $courseId,
});

sample({
  clock: [sendFeedbackFx.failData, sendFeedbackFx.done],
  target: [testCompleteModal.close, feedbackModal.close],
});

sample({
  clock: [testCompleteModal.close, feedbackModal.close],
  source: $courseId,
  fn: (courseId) => ({ courseId }),
  target: userCertificateVerified,
});

sample({
  clock: feedbackModal.open,
  target: testCompleteModal.close,
});

sample({
  clock: studentTriedToGetCertificate,
  target: createEffect(() => {
    if (document) {
      setTimeout(() => scrollToElement(SCROLL_TO_LESSON_TABS, { behavior: undefined }), 100);
    }
  }),
});

sample({
  clock: sendFeedbackFx.done,
  target: feedbackSentModal.open,
});

sample({
  clock: [issueCertificateFx.done, handleSubmittedCertificateFormFx.done],
  target: getUserFx,
});
