import { sample } from "effector";

import { analyticsEvents } from "@/shared/analytics";

import { avatarClicked } from "./model/profile-menu-toggle";

sample({
  clock: avatarClicked,
  target: analyticsEvents.clickProfileButton,
});
