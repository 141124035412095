import { sample, createEvent } from "effector";

import { userPurchasesModel } from "@/entities/user-purchases";

import { createPaymentTransactions } from "@/feature/payment-widget";

import { paymentModalOpened, PaymentModalType } from "./payment-management";

const paymentReset = createEvent();

export const paymentTransaction = createPaymentTransactions({
  reset: paymentReset,
  targetAfterSucceed: [
    userPurchasesModel.getUserProductsFx,
    userPurchasesModel.getUserCardsInfoFx,
    userPurchasesModel.getBoughtEventsFx,
    userPurchasesModel.checkPreviousSubscriptionsFx,
  ],
});

sample({
  clock: paymentModalOpened,
  filter: (modalType) => modalType === PaymentModalType.Close,
  target: paymentReset,
});
