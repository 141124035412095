import clsx from "clsx";
import { useEvent } from "effector-react/scope";
import React from "react";

import { analyticsEvents } from "@/shared/analytics";
import { scrollToElement } from "@/shared/lib/scroll";
import { ButtonRounded, Typography } from "@/shared/ui";
import arrowDirection from "@/shared/ui/assets/images/icons/arrow-direction-blue.svg";
import giftCardImg1920 from "@/shared/ui/assets/images/spring-landing/2sc-1920.webp";
import giftCardImgMobile from "@/shared/ui/assets/images/spring-landing/2sc-mobile.webp";

import { CTA_CERTIFICATE } from "./constants";
import styles from "./spring-landing.module.scss";

const leftContainer =
  "h-[370px] md:h-full pt-[48px] md:pt-[80px] xl:pt-[100px] 3xl:pt-[150px] px-mn-20 pb-[42px] md:pl-mn-26 md:pr-mn-50 xl:pr-[42px] md:pb-[66px] 2xl:pl-[40px] 2xl:pr-mn-74 2xl:pb-[100px]";

export const MakeGiftSection = () => {
  const sendAnalytics = useEvent(analyticsEvents.springPage.openContactSpring1);

  const handleClick = () => {
    sendAnalytics();
    scrollToElement(CTA_CERTIFICATE);
  };

  return (
    <section className="md:h-[556px] 2xl:h-[834px] flex flex-col md:flex-row">
      <div
        className={clsx(
          "bg-m-white flex flex-col justify-between w-full",
          leftContainer,
          styles.MakeGiftSection,
        )}
      >
        <Typography tag="p" size="m-h4" className="uppercase font-bold text-m-blue indent-[1em]">
          Искусство, поэзия, хореография и&nbsp;музыка&nbsp;&mdash; восемь вдохновляющих авторских
          курсов для прекрасной половины человечества в&nbsp;специальной подборке
          к&nbsp;международному женскому дню
        </Typography>

        <div className="flex md:justify-end">
          <div className="flex items-center">
            <img className="md:w-[54px] 3xl:w-[80px] hidden md:block" src={arrowDirection} alt="" />
            <ButtonRounded onClick={handleClick} className="md:ml-mn-26 3xl:ml-mn-38">
              подарочный сертификат
            </ButtonRounded>
          </div>
        </div>
      </div>
      <div className="w-full md:w-[49%] bg-m-blue h-full">
        <picture>
          <source srcSet={giftCardImg1920} media="(min-width: 768px)" />
          <img
            className="w-full object-contain md:h-[556px] 2xl:h-[834px]"
            src={giftCardImgMobile}
            alt="Подарок"
          />
        </picture>
      </div>
    </section>
  );
};
