import { createAnalyticsEvent } from "@/shared/analytics/model/analytics";

export const clickPromocodeButton = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: "click_promocode_button",
  link_text: "Ввести промокод",
}));

export const viewSuccessPromocode = createAnalyticsEvent((code: string) => ({
  event: "autoEvent",
  event_name: "promocode_sucсess",
  link_text: code,
}));

export const promocodeInvalid = createAnalyticsEvent<{ widgetText: string }>(({ widgetText }) => ({
  event: "autoEvent",
  event_name: "promocode_invalid",
  widget_text: widgetText,
}));
