import { contract, withHatch } from "framework";

import * as model from "./model";
import * as page from "./page";

contract({
  page,
  model,
});

export const SpringPage = withHatch(model.hatch, page.SpringPage);
