import clsx from "clsx";
import { useStore, useEvent } from "effector-react/scope";
import React, { memo, useState } from "react";

import { paths } from "@/pages/paths";

import { history, historyPush } from "@/entities/navigation";
import { useUserNameOrDefault } from "@/entities/user";
import { userPurchasesModel } from "@/entities/user-purchases";

import { formatDate } from "@/shared/lib/date";
import { Container } from "@/shared/ui";
import { ReactComponent as Camera } from "@/shared/ui/assets/images/icons/camera.svg";

import { useUserProfilePictureOrDefault } from "../../lib/use-user-profile-picture-or-default";
import { $photoEditHidden } from "../../model/edit-mode";
import { ChangeProfilePictureModal } from "../change-profile-picture-modal/change-profile-picture-modal";
import styles from "./profile-picture.module.scss";

const StudentName = () => {
  const { firstName, lastName } = useUserNameOrDefault();

  return (
    <p className="text-center text-m-xl text-m-dark-orange bg-clip-text font-semibold font-m-sb-display xl:text-[36px] xl:leading-[41px] xl:text-left 3xl:text-m-7xl">
      {firstName} <br /> {lastName}
    </p>
  );
};

const SubscriptionInfo = () => {
  const userSubscription = useStore(userPurchasesModel.$userSubscription);
  const hasMuseSubscription = useStore(userPurchasesModel.$hasUserMuseSubscription);

  if (!hasMuseSubscription) return null;

  const subscriptionLabel = userSubscription?.trial
    ? "Бесплатный пробный период до:"
    : "Подписка активна до:";

  return (
    <div className="text-m-white mt-mv-4 3xl:mt-mv-5">
      <div className="text-m-2xs xl:text-m-sm 3xl:text-m-base mb-1 3xl:mb-2">
        {subscriptionLabel}
      </div>
      <div className="font-semibold font-m-sb-display text-m-xl xl:text-m-3xl 3xl:text-4xl">
        {formatDate(userSubscription?.expiredDate, "dd.MM.yyyy")}
      </div>
      <div className="mt-2 sm:ml-auto" />
    </div>
  );
};

const Avatar = () => {
  const { userProfilePicture } = useUserProfilePictureOrDefault();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const photoEditHidden = useStore($photoEditHidden);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="relative">
      <img
        src={userProfilePicture}
        alt="avatar"
        className="object-cover h-[150px] w-[150px] xl:h-[200px] xl:w-[200px] 3xl:h-[300px] 3xl:w-[300px] bg-black rounded-full relative inline-block"
      />
      {photoEditHidden ? null : (
        <button onClick={openModal} className={styles.CameraButton}>
          <Camera />
        </button>
      )}
      <ChangeProfilePictureModal isOpen={isModalOpen} close={closeModal} />
    </div>
  );
};

const GoToProfileWrapper = React.memo(({ children }: { children: React.ReactElement }) => {
  const push = useEvent(historyPush);

  const isStudentCoursesPage = history?.location.pathname === paths.studentCourses();

  const goToProfilePage = () => {
    push(paths.studentProfile());
  };
  if (!isStudentCoursesPage) return children;

  return (
    <div onClick={goToProfilePage} onKeyUp={goToProfilePage} role="button" tabIndex={0}>
      {children}
    </div>
  );
});

export const StudentProfilePicture = memo(() => {
  return (
    <div
      className={clsx(
        styles.Section,
        "w-100 xl:h-[256px] 3xl:h-[404px] bg-m-blue py-[32px] xl:py-[28px] 3xl:py-[52px] relative",
      )}
    >
      <Container>
        <div className="flex items-center flex-col xl:justify-start xl:flex-row">
          <GoToProfileWrapper>
            <Avatar />
          </GoToProfileWrapper>

          <div className="mt-[32px] xl:mt-0 xl:ml-[64px]">
            <GoToProfileWrapper>
              <StudentName />
            </GoToProfileWrapper>
            <SubscriptionInfo />
          </div>
        </div>
      </Container>
    </div>
  );
});
