import { useEvent, useStore } from "effector-react/scope";

import { ConfirmCodeModel } from "./create-confirm-code-model";

export type ConfirmCode = {
  change: (payload: string) => void;
  submit: () => void;
  sendAgain: () => void;
  code: string;
  error: string;
};

export const useConfirmCode = (unit: ConfirmCodeModel): ConfirmCode => {
  return {
    change: useEvent(unit.confirmCodeChanged),
    code: useStore(unit.$confirmCode),
    sendAgain: useEvent(unit.sendAgain),
    submit: useEvent(unit.confirmCodeSubmitted),
    error: useStore(unit.$error),
  };
};
