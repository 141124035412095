import { ComponentProps } from "react";

import { CoursePayInfo, FullSubscriptionPayInfo, PaymentType } from "@/entities/payment";
import { User } from "@/entities/user";

import { getClientId } from "@/shared/analytics";
import { PAYMENT_SUBSCRIPTION_PLATFORM } from "@/shared/api/constants";

import { PaymentWidget } from "@/feature/payment-widget";

const everyHas = <T>(states: T[]) => states.every(Boolean);
const not = (is: boolean) => !is;

type PaymentData = {
  selectedPaymentType: PaymentType | null;
  subscriptionPaymentInfo: FullSubscriptionPayInfo | null;
  coursePaymentInfo: CoursePayInfo | null;
  userId: User["id"];
  hasPreviousSubscriptions: boolean;
};

export const createPaymentProps = ({
  selectedPaymentType,
  subscriptionPaymentInfo,
  hasPreviousSubscriptions,
  coursePaymentInfo,
  userId,
}: PaymentData) => {
  const isSubscriptionPaymentSelected = selectedPaymentType === PaymentType.Subscription;
  const isTrial = everyHas([isSubscriptionPaymentSelected, not(hasPreviousSubscriptions)]);

  const getProductPrice = (): string => {
    const TRIAL_PRICE = "1";

    if (selectedPaymentType === PaymentType.Course)
      return String(coursePaymentInfo?.definedTermPrice);

    if (isTrial) return TRIAL_PRICE;

    return String(subscriptionPaymentInfo?.definedTermPrice);
  };

  const getPeriod = (): number => {
    if (selectedPaymentType === PaymentType.Course)
      return Number(coursePaymentInfo?.definedTermPeriod);

    if (isTrial) {
      return Number(subscriptionPaymentInfo?.trialInfo.period);
    }

    return Number(subscriptionPaymentInfo?.definedTermPeriod);
  };

  const paymentProps: ComponentProps<typeof PaymentWidget>["processParams"] = {
    platform: PAYMENT_SUBSCRIPTION_PLATFORM,
    isTrial,
    period: getPeriod(),
    periodType: "defined_period",
    gaClientId: getClientId(),
    subscribeType: isSubscriptionPaymentSelected ? "recurring" : "instant",
    definedTermPeriodType: isTrial ? subscriptionPaymentInfo?.trialInfo.periodType : "month",
    user: { userId },
    product: {
      productId: isSubscriptionPaymentSelected
        ? subscriptionPaymentInfo?.platformId || ""
        : coursePaymentInfo?.platformId || "",
      productName: isSubscriptionPaymentSelected
        ? subscriptionPaymentInfo?.productName || ""
        : coursePaymentInfo?.title || "",
      productPrice: getProductPrice(),
      productType: isSubscriptionPaymentSelected ? "Подписка" : "Курс",
    },
    promocodeType: "b2c",
  };

  return paymentProps;
};
