import clsx from "clsx";
import { ReactNode } from "react";

import { KeyboardEventKey } from "@/shared/constants";
import { OutsideClick } from "@/shared/ui";
import { ReactComponent as Arrow } from "@/shared/ui/assets/images/icons/arrow-select.svg";

type DropdownButtonProps = {
  children: ReactNode;
  onClick: () => void;
  isOpen: boolean;
  className?: string;
};

export type MenuOption<K = string, V = string> = {
  value: V;
  label: K;
  icon?: ReactNode;
};

type DropdownItemProps = {
  children: ReactNode;
  option: MenuOption;
  onClick: (value: MenuOption) => void;
};

type DropdownMenuProps = {
  children: ReactNode;
};

type DropdownContainerProps = {
  children: ReactNode;
  onClose: () => void;
  className?: string;
};

export const DropdownBackground = ({ children }: { children: ReactNode }) => (
  <div
    className={clsx(
      "absolute bg-m-dark-blue w-full pt-[5.5rem] -top-7 pb-mv-4 shadow-primary z-10",
      // styles.DropdownBackground,
    )}
  >
    {/* <div className={styles.OrangeGradient} /> */}
    {children}
  </div>
);

export const DropdownButton = ({ children, onClick, isOpen, className }: DropdownButtonProps) => {
  return (
    <button
      type="button"
      aria-haspopup="listbox"
      aria-expanded={isOpen}
      className={clsx(
        "min-w-[166px] h-[30px] rounded-full uppercase text-m-xs flex items-center justify-center font-semibold relative z-20",
        {
          "bg-m-white text-m-orange": isOpen,
          "bg-m-white-10 border border-m-white border-solid hover:border-m-orange text-m-white":
            !isOpen,
        },
        className,
      )}
      onClick={onClick}
    >
      <span className="mr-1.5 leading-none">{children}</span>

      <span
        className={clsx({
          "rotate-180": isOpen,
        })}
      >
        <Arrow className="h-[18px] w-[16px]" />
      </span>
    </button>
  );
};

export const DropdownMenu = ({ children }: DropdownMenuProps) => {
  return (
    <ul role="listbox" tabIndex={-1} className={clsx("flex flex-col items-center relative")}>
      {children}
    </ul>
  );
};

export const DropdownItem = ({ children, option, onClick }: DropdownItemProps) => {
  return (
    <li
      onKeyDown={(e) => {
        if (e.key === KeyboardEventKey.Enter) {
          onClick(option);
        }
      }}
      role="option"
      aria-selected
      tabIndex={0}
      className={clsx(
        "cursor-pointer text-m-white text-m-xs font-semibold uppercase mb-mv-3 hover:text-m-orange",
      )}
      onClick={() => onClick(option)}
    >
      {children}
    </li>
  );
};

export const DropdownContainer = ({ children, onClose, className }: DropdownContainerProps) => {
  return (
    <OutsideClick onOutsideClick={onClose}>
      <div className={clsx("w-[198px] relative", className)}>{children}</div>
    </OutsideClick>
  );
};
