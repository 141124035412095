import { createEvent, sample } from "effector";

import { analyticsEvents } from "@/shared/analytics";

export const clickedSupportContact = createEvent();

sample({
  clock: clickedSupportContact,
  target: analyticsEvents.openFeedback,
});
