import { createEvent, createStore, sample, createEffect, attach } from "effector";

import { FiltrationResult } from "@app/api";

import { paths } from "@/pages/paths";

import { historyPush } from "@/entities/navigation";

import { CourseApi, ProgramApi, SearchApi } from "@/shared/api";

type SearchInput = string;
type QueryUrlInput = string;

export const initializedSearchInput = createEvent<{ searchQuery: QueryUrlInput }>();

const getResultIds = (searchResult: SearchApi.SearchResult[]) =>
  searchResult.map((result) => result.entityId);

const findUniqCourses = ({ courses, programs }: FiltrationResult): FiltrationResult => ({
  courses: courses.filter((obj, index, self) => index === self.findIndex((el) => el.id === obj.id)),
  programs: programs.filter(
    (obj, index, self) => index === self.findIndex((el) => el.id === obj.id),
  ),
});

export const searchCoursesFx = createEffect(async ({ searchQuery }: { searchQuery: string }) => {
  const searchResult = await SearchApi.searchItems({ pattern: searchQuery });

  const courses = searchResult.filter((s) => s.entityType === "course");
  const speakers = searchResult.filter((s) => s.entityType === "speaker");

  const resultItems: FiltrationResult = {
    courses: [],
    programs: [],
  };

  if (courses.length) {
    const idsCourses = getResultIds(courses);
    const searchCourses = await CourseApi.getCoursesByIds({ ids: idsCourses });
    const searchPrograms = await ProgramApi.getProgramsByIds({ ids: idsCourses });

    resultItems.courses.push(...searchCourses);
    resultItems.programs.push(...searchPrograms);
  }

  if (speakers.length) {
    const idsSpeakers = getResultIds(speakers);
    const searchSpeakers = await CourseApi.getCourseBySpeakersIds({ ids: idsSpeakers });

    resultItems.courses.push(...searchSpeakers);
  }

  return findUniqCourses(resultItems);
});

export const searchChanged = createEvent<SearchInput>();
export const searchCleared = createEvent();
export const searchStarted = createEvent();

export const $searchValue = createStore<SearchInput>("")
  .on(searchChanged, (_, search) => search)
  .on(initializedSearchInput, (_, query) => query.searchQuery)
  .reset(searchCleared);

const runSearchFx = attach({
  source: $searchValue,
  effect: async (params) => {
    historyPush(paths.search({ queryParams: { query: params } }));
    await searchCoursesFx({ searchQuery: params });
  },
});

sample({
  clock: searchStarted,
  target: runSearchFx,
});
