import clsx from "clsx";
import React, { useRef, useState } from "react";

import { ButtonCircle } from "@/shared/ui";
import { ReactComponent as EditIcon } from "@/shared/ui/assets/images/icons/edit.svg";

import styles from "./section-profile.module.scss";

interface ProfileFieldProps {
  label: string;
  name: string;
  errorMessage?: string;
  disabled?: boolean;
  className?: string;
  children: React.ReactElement;
  onBlur?: (arg: Event) => void;
  onFocus?: (arg: Event) => void;
  hasEditButton?: boolean;
  onEditButtonClick?: () => void;
  onClick?: (name: string) => void;
}

type InputProps = {
  name: string;
  disabled?: boolean;
  setFocused: (arg: boolean) => void;
  onFocus?: (arg: Event) => void;
  onBlur?: (arg: Event) => void;
  children: React.ReactElement;
};

const InputElement = React.forwardRef<HTMLInputElement, React.PropsWithChildren<InputProps>>(
  (props, ref) =>
    React.cloneElement(props.children, {
      id: props.name,
      disabled: props.disabled,
      onFocus: (e: Event) => {
        props.setFocused(true);
        if (props.onFocus) props.onFocus(e);
      },
      onBlur: (e: Event) => {
        props.setFocused(false);
        if (props.onBlur) props.onBlur(e);
      },
      className: `${styles.profileInput} ${props.children?.props.className}`,
      ref,
    }),
);

export const ProfileField = ({
  label,
  name,
  errorMessage,
  disabled,
  className,
  children,
  onFocus,
  onBlur,
  hasEditButton,
  onEditButtonClick,
  onClick,
}: ProfileFieldProps) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnFieldClick = () => {
    if (!disabled) return;
    if (onClick) onClick(name);
    setTimeout(() => {
      if (inputRef.current?.focus) {
        inputRef.current?.focus();
      } else {
        // exception for IMaksInput
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (inputRef as any).current.element.focus();
      }
    }, 0);
  };

  return (
    <div
      className={clsx(
        {
          [styles.withIcon]: hasEditButton,
          [styles.isDisabled]: disabled,
          [styles.isFocused]: focused,
          [styles.isError]: Boolean(errorMessage),
          [styles.isClickable]: Boolean(onClick),
        },
        styles.ProfileField,
        className,
      )}
      {...(Boolean(onClick) && {
        onClick: handleOnFieldClick,
        role: "button",
        tabIndex: 0,
      })}
    >
      <label htmlFor={name}>
        <span className={styles.labelText}>{label}:</span>
        <InputElement
          setFocused={setFocused}
          ref={inputRef}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          name={name}
        >
          {children}
        </InputElement>
      </label>
      <div className={styles.profileError}>{errorMessage}</div>
      {hasEditButton && (
        <ButtonCircle
          onClick={onEditButtonClick}
          className="svg-dark-to-orange text-m-dark w-[40px] h-[40px] -top-[2px] lg:-top-[11px] relative !bg-m-white"
        >
          <EditIcon />
        </ButtonCircle>
      )}
    </div>
  );
};
