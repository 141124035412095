import clsx from "clsx";
import { FC } from "react";

const SectionBase: FC<{ className?: string }> = ({ className, children }) => (
  <section className={clsx(className)}>{children}</section>
);

const Content: FC<{ className?: string }> = ({ className, children }) => (
  <div
    className={clsx(
      "w-grid-sm lg:w-grid-lg lg:mx-auto xl:w-grid-xl 2xl:w-grid-2xl mx-auto",
      className,
    )}
  >
    {children}
  </div>
);

export const Section = SectionBase as typeof SectionBase & {
  Content: typeof Content;
};

Section.Content = Content;
