import clsx from "clsx";
import React, { memo, ReactNode } from "react";

import { ButtonRounded, Container, Title } from "@/shared/ui";

const ProductGrid = ({
  children,
  className,
  gridCol = clsx("md:grid-cols-2 xl:grid-cols-3 3xl:grid-col-3"),
}: {
  children: ReactNode;
  className?: string;
  gridCol?: string;
}) => {
  return (
    <div
      className={clsx(
        `w-full grid 3xl:gap-x-6 3xl:gap-y-8 xl:gap-x-[20px] xl:gap-y-[25px] gap-x-3 gap-y-4 justify-items-center mx-auto`,
        className,
        gridCol,
      )}
    >
      {children}
    </div>
  );
};

type ElementRenderer = {
  name: string;
  renderElements: () => JSX.Element | JSX.Element[] | null;
  title?: string;
  isHidden?: boolean;
  loadMore?: () => void;
  hideLoadMore?: boolean;
  loadMorePending?: boolean;
};

type CatalogProps = {
  renderCatalog: ElementRenderer[];
  renderLeftAside?: ReactNode;
  isEmpty?: boolean;
  EmptyLayout?: ReactNode;
};

type CatalogListItemsProps = {
  renderCatalog: CatalogProps["renderCatalog"];
  isEmpty: boolean;
  EmptyLayout?: ReactNode;
  gridCol?: string;
  className?: string;
};

const LeftAside = memo(
  ({ renderLeftAside }: { renderLeftAside: CatalogProps["renderLeftAside"] }) => {
    return (
      <div className="relative z-20 pb-mn-50">
        <aside className="hidden md:block w-[244px] xl:w-[312px] sticky self-start top-mn-20 3xl:top-mn-50">
          {renderLeftAside}
        </aside>
      </div>
    );
  },
);

const CatalogContainer = ({ children }: { children: ReactNode }) => (
  <div className="px-mn-20 md:px-0 w-full md:basis-full md:min-w-[472px] 3xl:xl:max-w-[1380px]">
    {children}
  </div>
);

export const CatalogListItems = memo(
  ({ renderCatalog, isEmpty, EmptyLayout = null, gridCol, className }: CatalogListItemsProps) => {
    if (isEmpty) return <>{EmptyLayout}</>;
    return (
      <>
        {renderCatalog.map((render) => {
          if (render.renderElements() === null || render.isHidden) return null;

          return (
            <div className={className || "md:mb-0 m-padding-bottom-spacing "} key={render.name}>
              <Title text={render.title} className="mb-mn-26 md:mb-mn-30 3xl:mb-mn-38" />

              <div>
                <ProductGrid gridCol={gridCol} className="mb-[64px] last:mb-0">
                  {render.renderElements()}
                </ProductGrid>
              </div>

              {render.loadMore && !render.hideLoadMore && (
                <ButtonRounded
                  data-testid={`load-more_${render.name}`}
                  isLoading={render.loadMorePending}
                  variant="secondary"
                  className="mt-mn-38 flex items-center mx-auto"
                  onClick={render.loadMore}
                >
                  Показать еще
                </ButtonRounded>
              )}
            </div>
          );
        })}
      </>
    );
  },
);

export const Catalog = memo(
  ({ renderCatalog, renderLeftAside, isEmpty = false, EmptyLayout }: CatalogProps) => {
    return (
      <section className="bg-m-dark-blue">
        <Container mode="fluid-fixed">
          <div className="flex justify-between">
            <LeftAside renderLeftAside={renderLeftAside} />
            <CatalogContainer>
              <CatalogListItems
                gridCol="md:grid-cols-1 lg:grid-cols-2"
                renderCatalog={renderCatalog}
                isEmpty={isEmpty}
                EmptyLayout={EmptyLayout}
              />
            </CatalogContainer>
          </div>
        </Container>
      </section>
    );
  },
);
