import { useEvent } from "effector-react/scope";
import { useCallback } from "react";

import { paths } from "@/pages/paths";

import { useIsUserAuthenticated } from "@/entities/user";

import { analyticsEvents } from "@/shared/analytics";

type UseMenuItemsProps = {
  device: "mobile" | "desktop";
};

export enum NavItemTitle {
  Children = "Детям",
  Courses = "Курсы",
  Home = "Главная",
  Programs = "Программы",
  Profile = "Профиль",
  Catalog = "Каталог",
  MY_EDUCATION = "Мое обучение",
}

type MenuItem = {
  to: string;
  title: NavItemTitle;
  onClick?: () => void;
  nativeLink?: boolean;
};

export const useMenuItems = ({ device }: UseMenuItemsProps) => {
  const sendClickAnalytic = useEvent(analyticsEvents.clickMenuLink);
  const isAuthorized = useIsUserAuthenticated();

  const onClickMenu = (title: NavItemTitle, cb?: () => void) => {
    sendClickAnalytic({ link_text: title });
    cb?.();
  };

  const getDesktopMenu = useCallback((): MenuItem[] => {
    return [
      {
        to: paths.catalog(),
        title: NavItemTitle.Programs,
        onClick: () => onClickMenu(NavItemTitle.Catalog),
        nativeLink: true,
      },
      {
        to: paths.teens(),
        title: NavItemTitle.Children,
        onClick: () => onClickMenu(NavItemTitle.Children),
      },
    ];
  }, []);

  const getMobileMenu = useCallback((): MenuItem[] => {
    const menu: MenuItem[] = [
      {
        to: paths.home(),
        title: NavItemTitle.Home,
        onClick: () => onClickMenu(NavItemTitle.Home),
      },
      {
        to: paths.catalog(),
        title: NavItemTitle.Programs,
        onClick: () => onClickMenu(NavItemTitle.Catalog),
        nativeLink: true,
      },
      {
        to: paths.teens(),
        title: NavItemTitle.Children,
        onClick: () => onClickMenu(NavItemTitle.Children),
      },
    ];

    if (isAuthorized) {
      const authorizedMenu: MenuItem[] = [
        {
          to: paths.studentProfile(),
          title: NavItemTitle.Profile,
          onClick: () => onClickMenu(NavItemTitle.Profile),
        },
        {
          to: paths.studentCourses(),
          title: NavItemTitle.MY_EDUCATION,
          onClick: () => onClickMenu(NavItemTitle.MY_EDUCATION),
        },
      ];

      menu.push(...authorizedMenu);
    }

    return menu;
  }, [isAuthorized]);

  return device === "mobile" ? getMobileMenu() : getDesktopMenu();
};
