import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Arrow } from "@/shared/ui/assets/images/icons/arrow-direction.svg";

import styles from "./link-with-arrow.module.scss";

type LinkWithArrowProps = {
  link?: string;
  text: string;
  classNameLink?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  arrowClassName?: string;
  reverse?: boolean;
  onClick?: () => void;
  redirectType?: "spa" | "default";
};

export const LinkWithArrow = ({
  link,
  target = "_self",
  classNameLink,
  text,
  arrowClassName,
  reverse = false,
  onClick,
  redirectType = "default",
  ...rest
}: LinkWithArrowProps) => {
  const children = (
    <div
      className={clsx("flex items-center", {
        "flex-row-reverse": reverse,
        "flex-row": !reverse,
      })}
    >
      <span>{text}</span>
      <Arrow
        className={clsx(
          "translate-x-0 scale-[0.4] xl:scale-[1] 3xl:scale-[1.5]",
          {
            "xl:mr-[15px] 3xl:mr-[25px]": reverse,
            "xl:ml-[15px] 3xl:ml-[25px]": !reverse,
          },

          arrowClassName,
        )}
      />
    </div>
  );

  const className = clsx(
    styles.arrowText,
    classNameLink,
    "font-m-montserrat uppercase font-bold flex items-center cursor-pointer text-m-white m-typography-h5",
  );

  if (redirectType === "spa" && link) {
    return (
      <Link to={link} className={className} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <a
      target={target}
      href={link}
      className={className}
      rel="noreferrer"
      onClick={onClick}
      {...rest}
    >
      {children}
    </a>
  );
};
