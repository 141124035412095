import { useEvent, useStore } from "effector-react/scope";

import { ButtonRounded, Checkbox, Modal, Typography } from "@/shared/ui";

import {
  $isAgreementChecked,
  agreementModal,
  agreementToggle,
  offerConfirmationAgreementSubmitted,
  closeAgreementModalClicked,
} from "../model/user-agreement";

export const Agreement = () => {
  const handlers = useEvent({
    toggle: agreementToggle,
    offerConfirmationAgreementSubmitted,
    closeAgreementModalClicked,
  });
  const isAgreementChecked = useStore($isAgreementChecked);
  return (
    <Modal.Surface>
      <Modal.Header close={() => handlers.closeAgreementModalClicked()} title="Вход в аккаунт" />
      <Modal.Body className="text-center mt-[32px] whitespace-pre-line">
        <div className="m-typography-caption text-left text-m-dark-grey mb-mn-26">
          <p>
            Нажимая «Перейти», вы соглашаетесь с условиями{" "}
            <a
              href="https://edutoria.ru/termsofuse/user/agreement"
              className="text-m-dark-grey underline"
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              Пользовательского соглашения
            </a>{" "}
            и&nbsp;
            <a
              href="https://edutoria.ru/termsofuse/user/privacypolicy"
              className="text-m-dark-grey underline"
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              Положения о&nbsp;конфиденциальности
            </a>
            .
          </p>
          <br />
          <p>
            Если коротко, вы&nbsp;подтверждаете, что вам исполнилось 18&nbsp;лет, что
            вы&nbsp;согласны на&nbsp;обработку персональных данных, а&nbsp;также что будете
            соблюдать правила общения и&nbsp;создания контента.
          </p>
        </div>
        <div className="flex items-start mb-mn-26">
          <Checkbox
            checked={isAgreementChecked}
            onChange={() => handlers.toggle()}
            title={
              <Typography tag="p" size="m-caption" className="text-left text-m-dark-grey ml-mn-4">
                Даю согласие на получение персональных предложений и&nbsp;рекламных материалов.
              </Typography>
            }
          />
        </div>
        <ButtonRounded onClick={() => handlers.offerConfirmationAgreementSubmitted()}>
          Перейти
        </ButtonRounded>
      </Modal.Body>
    </Modal.Surface>
  );
};

export const UserAgreementModal = () => {
  const isOpenAgreementModal = useStore(agreementModal.$isOpen);
  const handlers = useEvent({ close: agreementModal.close });

  return (
    <Modal
      closeOnClickOverlay={false}
      isOpen={isOpenAgreementModal}
      close={() => handlers.close()}
      render={() => <Agreement />}
    />
  );
};
