import { SpeakerApi } from "@/shared/api";

import { SpeakerEntity } from "../types";

export const mapSpeakerToEntity = (speaker: SpeakerApi.SpeakerResponse): SpeakerEntity => {
  return {
    id: speaker.id,
    name: speaker.name,
    surname: speaker.surname,
    description: speaker.description,
    shortDescription: speaker.short_description,
    imageUrl: speaker.image_url,
  };
};
