import { createEffect, createEvent, createStore, sample, Store, Event } from "effector";

import { courseLessonCompleted } from "@/entities/course";
import { Lesson, LessonVideo } from "@/entities/lesson";

import { LessonApi } from "@/shared/api";

type ViewedVideos = {
  [lessonId: string]: boolean;
};

type ViewedLessonsByCourseId = Record<string, Record<string, boolean>>;

type CreateVideoPlayer<T> = {
  $videos: Store<LessonVideo[]>;
  $currentCourseId: Store<string>;
  $hasCourseAccess: Store<boolean>;
  reset: Event<T>;
};

export const createVideoPlayer = <T,>({
  $videos,
  $currentCourseId,
  $hasCourseAccess,
  reset,
}: CreateVideoPlayer<T>) => {
  const checkCanMarkLessonAsCompleted = async (params: { courseId: string; lessonId: string }) => {
    const [course, isLessonCompleted] = await Promise.all([
      LessonApi.getLessonContent({ id: params.courseId }),
      LessonApi.checkIsLessonCompleted({ lessonId: params.lessonId }),
    ]);

    const lessons = course.content.course.course_tree.lessons as Lesson[];

    const previewLessons = lessons.slice(0, 3).map((lesson) => lesson.id);
    const isTryingToAddNonMarkableLesson = previewLessons.includes(params.lessonId);

    return !(isLessonCompleted || isTryingToAddNonMarkableLesson);
  };

  const markLessonAsCompletedFx = createEffect(
    async (params: { courseId: string; lessonId: string }) => {
      const canMarkLessonAsCompleted = await checkCanMarkLessonAsCompleted(params);

      if (!canMarkLessonAsCompleted) {
        return null;
      }

      return LessonApi.markLessonAsCompleted(params);
    },
  );

  const getCompletedCourseLessonsFx = createEffect(LessonApi.getCompletedCourseLessonsIds);
  const getInitialCompletedCourseLessonsFx = createEffect(getCompletedCourseLessonsFx);

  const viewedVideoChanged = createEvent<string>();
  const nextVideoSelected = createEvent();
  const videoSelected = createEvent<LessonVideo["id"]>();
  const play = createEvent();
  const pause = createEvent();
  const start = createEvent();
  const toggle = createEvent();
  const playerUnmounted = createEvent();

  const $viewed = createStore<ViewedVideos>({});
  const $viewedLessonsByCourseIdMap = createStore<ViewedLessonsByCourseId>({});
  const $videoPlaying = createStore(false);
  const $currentVideo = createStore<LessonVideo | null>(null);
  const $displayVideoList = createStore<LessonVideo[]>([]);
  const $introVideo = createStore<LessonVideo | null>(null);
  const $currentVideoIndex = createStore(0);
  const $isVideoBlocked = createStore(false);

  $videoPlaying
    .on(play, () => true)
    .on(start, () => true)
    .on(pause, () => false)
    .on(toggle, (state) => !state);

  $viewedLessonsByCourseIdMap.on(
    [getCompletedCourseLessonsFx.done, getInitialCompletedCourseLessonsFx.done],
    (map, { params: { courseId }, result: lessonsIds }) => ({
      ...map,
      [courseId]: lessonsIds.reduce((acc, id) => (id ? { ...acc, [id]: true } : acc), {}),
    }),
  );

  $viewed.on($viewedLessonsByCourseIdMap, (_, map) =>
    Object.keys(map)
      .flatMap((courseId) => Object.keys(map[courseId]))
      .reduce((acc, lessonId) => ({ ...acc, [lessonId]: true }), {}),
  );

  sample({
    clock: start,
    source: $currentVideo,
    filter: (video) => !!video,
    fn: (video) => video?.id || "",
    target: viewedVideoChanged,
  });

  sample({
    clock: videoSelected,
    source: { hasAccess: $hasCourseAccess, videos: $displayVideoList, currentVideo: $currentVideo },
    filter: ({ currentVideo }, id) => currentVideo?.id !== id,
    fn: ({ hasAccess, videos }, id) => {
      const newVideoIndex = videos.findIndex((video) => video.id === id);
      return hasAccess || newVideoIndex === 0;
    },
    target: $videoPlaying,
  });

  sample({
    clock: videoSelected,
    source: $currentVideo,
    filter: (currentVideo, selectedVideo) => currentVideo?.id === selectedVideo,
    fn: () => undefined,
    target: toggle,
  });

  sample({
    source: $videos,
    fn: (videos) => videos[0],
    target: $introVideo,
  });

  sample({
    source: $isVideoBlocked,
    filter: (blocked) => blocked,
    fn: () => false,
    target: $videoPlaying,
  });

  sample({
    source: { currentIndex: $currentVideoIndex, hasAccess: $hasCourseAccess },
    fn: ({ currentIndex, hasAccess }) => !hasAccess && currentIndex > 0,
    target: $isVideoBlocked,
  });

  sample({
    clock: [$displayVideoList, getInitialCompletedCourseLessonsFx.done],
    source: {
      videos: $displayVideoList,
      hasAccess: $hasCourseAccess,
      viewed: $viewed,
      currentVideo: $currentVideo,
    },
    fn: ({ videos, hasAccess, viewed }) => {
      return hasAccess
        ? videos.slice(1).find((video) => !(video.id in viewed)) || videos[0]
        : videos[0];
    },
    target: $currentVideo,
  });

  sample({
    clock: videoSelected,
    source: { videos: $displayVideoList, intro: $introVideo },
    fn: ({ videos, intro }, id) => videos.find((video) => video.id === id) || intro,
    target: $currentVideo,
  });

  sample({
    clock: nextVideoSelected,
    source: { videos: $displayVideoList, currentIndex: $currentVideoIndex },
    filter: ({ videos, currentIndex }) => currentIndex < videos.length - 1,
    fn: ({ videos, currentIndex }) => {
      const { id } = videos[currentIndex + 1];
      return id;
    },
    target: videoSelected,
  });

  sample({
    clock: $currentVideo,
    source: { currentVideo: $currentVideo, videos: $displayVideoList },
    filter: ({ currentVideo }) => !!currentVideo,
    fn: ({ currentVideo, videos }) => videos.findIndex((video) => video.id === currentVideo?.id),
    target: $currentVideoIndex,
  });

  sample({
    source: { videos: $videos, hasAccess: $hasCourseAccess, intro: $introVideo },
    fn: ({ videos, hasAccess }) => {
      if (hasAccess) return videos;
      return videos.map((video) => ({ ...video, url: videos[0].url }));
    },
    target: $displayVideoList,
  });

  sample({
    clock: viewedVideoChanged,
    source: { courseId: $currentCourseId, viewed: $viewed },
    filter: ({ viewed }, lessonId) => !(lessonId in viewed),
    fn: ({ courseId }, lessonId) => {
      return { courseId, lessonId };
    },
    target: markLessonAsCompletedFx,
  });

  sample({
    clock: markLessonAsCompletedFx.finally,
    source: { courseId: $currentCourseId, hasAccessCourse: $hasCourseAccess },
    filter: ({ hasAccessCourse }) => Boolean(hasAccessCourse),
    fn: ({ courseId }) => ({ courseId }),
    target: getCompletedCourseLessonsFx,
  });

  sample({
    clock: [$currentCourseId, $hasCourseAccess],
    source: { courseId: $currentCourseId, hasAccessCourse: $hasCourseAccess },
    filter: ({ hasAccessCourse }) => Boolean(hasAccessCourse),
    fn: ({ courseId }) => ({ courseId }),
    target: getInitialCompletedCourseLessonsFx,
  });

  sample({
    clock: markLessonAsCompletedFx.done,
    source: $displayVideoList,
    filter: (videoList, { params: { lessonId } }) =>
      videoList.findIndex((video) => video.id === lessonId) > 0,
    fn: (_, { params: { courseId } }) => ({ courseId }),
    target: courseLessonCompleted,
  });

  [
    $displayVideoList,
    $currentVideo,
    $introVideo,
    $currentVideoIndex,
    $isVideoBlocked,
    $videoPlaying,
  ].forEach((state) => state.reset(reset));

  return {
    viewedVideoChanged,
    nextVideoSelected,
    videoSelected,
    play,
    pause,
    start,
    toggle,
    playerUnmounted,
    $viewed,
    $viewedLessonsByCourseIdMap,
    $videoPlaying,
    $currentVideo,
    $displayVideoList,
    $introVideo,
    $currentVideoIndex,
    $isVideoBlocked,
  };
};
