import { createEvent, sample } from "effector";
import { useEvent } from "effector-react/scope";

import { analyticsEvents } from "@/shared/analytics";

const sliderClicked = createEvent<string>();

sample({
  clock: sliderClicked,
  fn: (type) => ({ linkType: type }),
  target: analyticsEvents.catalogPage.viewCourseSlider,
});

export const useSpecificSliderAnalitics = () => {
  return {
    sliderClicked: useEvent(sliderClicked),
  };
};
