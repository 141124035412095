import { useState } from "react";

import { uniqId } from "@/shared/lib/uuid";

import { isClient } from "../is-client";

function getFontSize(containerElement: HTMLElement): number {
  let fontSize = parseFloat(window.getComputedStyle(containerElement?.children[1]).fontSize);

  if (containerElement?.children[0]?.clientHeight < containerElement?.children[1]?.clientHeight) {
    fontSize -= 1;
    // eslint-disable-next-line
    (containerElement.children[1] as HTMLElement).style.fontSize = `${fontSize}px`;
    return getFontSize(containerElement);
  }
  return fontSize;
}

export const useNameFontSize = (name: string, width: number, componentClassName: string) => {
  const [size, setSize] = useState(0);

  if (!isClient) return size;

  const tempId = uniqId();
  let containerElementName = document.getElementById(tempId);
  if (!containerElementName) {
    containerElementName = document.createElement("div");
    containerElementName.style.width = `${width}px`;
    containerElementName.style.position = "fixed";
    containerElementName.style.top = "0";
    containerElementName.style.opacity = "0";
    containerElementName.id = tempId;
    const testElement = document.createElement("h2");
    testElement.classList.value = componentClassName;
    testElement.textContent = "o";
    containerElementName.append(testElement);
    const nameElement = document.createElement("h2");
    nameElement.classList.value = componentClassName;
    nameElement.textContent = name;
    containerElementName.append(nameElement);
    document.body.append(containerElementName);
  }

  const observer = new MutationObserver(() => {
    if (document.contains(containerElementName)) {
      setSize(getFontSize(containerElementName as HTMLElement));
      containerElementName?.remove();
      observer.disconnect();
    }
  });

  observer.observe(document, {
    attributes: false,
    childList: true,
    characterData: false,
    subtree: true,
  });

  return size;
};
