import { Program } from "@app/api";

import { DateUtils, determineStateOnDate } from "@/shared/lib/date";

export const sortProgramsOnDate = (programs: Program[]): Program[] => {
  const programsWithState = programs.map((program) => {
    return {
      ...program,
      additionalInfo: {
        ...program.additionalInfo,
        state: determineStateOnDate({ startDate: program.startDate, endDate: program.endDate }),
      },
    };
  });

  const finishedPrograms = programsWithState.filter(
    (program) => program.additionalInfo.state === "finished",
  );
  const progressPrograms = programsWithState.filter(
    (program) => program.additionalInfo.state === "progress",
  );
  const futurePrograms = programsWithState
    .filter((program) => program.additionalInfo.state === "future")
    .sort((a, b) => {
      const aStartDate = new Date(a.startDate);
      const bStartDate = new Date(b.startDate);

      if (DateUtils.isSameOrAfter(aStartDate, bStartDate)) {
        return 1;
      }

      return -1;
    });

  return [...futurePrograms, ...progressPrograms, ...finishedPrograms];
};
