import { CONFIG } from "@/shared/config";

export { parseCookieStringToJson } from "./lib";
export {
  $cookiesForRequest,
  $cookiesFromResponse,
  createRequestFx,
  createAuthRequestFx,
  setCookiesForRequest,
  request,
  fetchCsrfToken,
} from "./base";
export type { Answer, Request } from "./types";

if (CONFIG.BUILD_ON_SERVER) {
  // eslint-disable-next-line global-require
  require("./server");
} else {
  // eslint-disable-next-line global-require
  require("./client");
}
