import { useEvent } from "effector-react/scope";

import { SlideItem, SliderCategoryCourses } from "@/widgets/slider-category-courses";

import { analyticsEvents } from "@/shared/analytics";
import { scrollToElement } from "@/shared/lib/scroll";
import desktop1 from "@/shared/ui/assets/images/spring-landing/6sc-1920-1.webp";
import desktop2 from "@/shared/ui/assets/images/spring-landing/6sc-1920-2.webp";
import desktop3 from "@/shared/ui/assets/images/spring-landing/6sc-1920-3.webp";
import mobile1 from "@/shared/ui/assets/images/spring-landing/6sc-mobile-1.webp";
import mobile2 from "@/shared/ui/assets/images/spring-landing/6sc-mobile-2.webp";
import mobile3 from "@/shared/ui/assets/images/spring-landing/6sc-mobile-3.webp";
import beInOpen from "@/shared/ui/assets/images/spring-landing/be-in-open.svg";

import { CTA_CERTIFICATE } from "./constants";

export const SliderCourses = () => {
  const sendAnalytics = useEvent(analyticsEvents.springPage.openContactSpring2);

  const content: SlideItem[] = [
    {
      itemId: "1b38990e-003d-48df-9dfa-f336cc3d0344",
      image: {
        srcS: mobile1,
        srcL: desktop1,
      },
      alt: "Ольга Гинзбург, основатель марки OLA OLA",
      text: {
        category: (
          <>
            Ольга Гинзбург,
            <br />
            основатель марки OLA OLA
          </>
        ),
        plainTitle: "Как создать локальный бренд одежды?",
        title: (
          <>
            <span>
              Как создать
              <br /> локальный бренд
              <br /> одежды?
            </span>
            <img
              className="absolute left-[8em] -top-[60px] md:-top-[50px] xl:-top-[25px] scale-[.6] md:scale-75 3xl:scale-100"
              src={beInOpen}
              alt=""
            />
          </>
        ),
      },
      onClick: (id) => {
        scrollToElement(CTA_CERTIFICATE);
        sendAnalytics({ course_id: id, course_name: "Как создать локальный бренд одежды?" });
      },
    },
    {
      itemId: "5a9f4097-61f3-40cd-bd15-b14a374d710b",
      image: {
        srcS: mobile2,
        srcL: desktop2,
      },
      alt: "Татьяна Глама, художник",
      text: {
        category: "Татьяна Глама, художник",
        plainTitle: "Ботаника в акварели",
        title: (
          <>
            Ботаника <br className="block md:hidden" />в акварели
          </>
        ),
      },
      onClick: (id) => {
        scrollToElement(CTA_CERTIFICATE);
        sendAnalytics({ course_id: id, course_name: "Ботаника в акварели" });
      },
    },
    {
      itemId: "9d2bd79d-6dcc-44a1-b8c9-78e77d583550",
      image: {
        srcS: mobile3,
        srcL: desktop3,
      },
      alt: "Нина Вересова, преподаватель Британской высшей школы дизайна",
      text: {
        category: (
          <>
            Нина Вересова, преподаватель
            <br />
            Британской высшей школы дизайна
          </>
        ),
        plainTitle: "Экопринт: носить и радоваться!",
        title: (
          <>
            Экопринт: носить <br />и радоваться!
          </>
        ),
      },
      onClick: (id) => {
        scrollToElement(CTA_CERTIFICATE);
        sendAnalytics({ course_id: id, course_name: "Экопринт: носить и радоваться!" });
      },
    },
  ];

  return (
    <SliderCategoryCourses
      controlType="button"
      controlText="подарочный сертификат"
      slideImageClassName="md:h-[555px] object-cover xl:h-auto"
      content={content}
      containerClassName="h-auto md:h-[555px] xl:h-auto bg-m-dark-blue"
      slideContentContainerClassName="absolute z-10 top-[70%] md:top-1/2 -translate-y-1/2 w-full md:w-[80vw] xl:w-full left-[20px] md:left-[30px] 3xl:left-[40px]"
      controlsClassName="absolute z-10 bottom-[50px] md:bottom-[20px] xl:bottom-[50px] left-1/2 -translate-x-1/2"
    />
  );
};
