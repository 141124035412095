"use strict";
exports.__esModule = true;
exports.order_by = exports.course_formats_enum = exports.PurchaseType = void 0;
var PurchaseType;
(function (PurchaseType) {
    PurchaseType["b2b"] = "b2b";
    PurchaseType["buy"] = "buy";
    PurchaseType["free"] = "free";
})(PurchaseType = exports.PurchaseType || (exports.PurchaseType = {}));
var course_formats_enum;
(function (course_formats_enum) {
    course_formats_enum["broadcast"] = "broadcast";
    course_formats_enum["master_class"] = "master_class";
    course_formats_enum["online_course"] = "online_course";
    course_formats_enum["webinar"] = "webinar";
})(course_formats_enum = exports.course_formats_enum || (exports.course_formats_enum = {}));
/** column ordering options */
var order_by;
(function (order_by) {
    /** in ascending order, nulls last */
    order_by["asc"] = "asc";
    /** in ascending order, nulls first */
    order_by["asc_nulls_first"] = "asc_nulls_first";
    /** in ascending order, nulls last */
    order_by["asc_nulls_last"] = "asc_nulls_last";
    /** in descending order, nulls first */
    order_by["desc"] = "desc";
    /** in descending order, nulls first */
    order_by["desc_nulls_first"] = "desc_nulls_first";
    /** in descending order, nulls last */
    order_by["desc_nulls_last"] = "desc_nulls_last";
})(order_by = exports.order_by || (exports.order_by = {}));
