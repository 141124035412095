import clsx from "clsx";
import React, { memo, ReactNode, useState } from "react";

import { useDevices } from "@/shared/lib/responsive";
import { OutsideClick } from "@/shared/ui";
import { ReactComponent as QuestionIcon } from "@/shared/ui/assets/images/icons/question-icon.svg";

type FilterGroup = {
  children?: ReactNode;
  groupTitle: string | ReactNode;
  onRenderHint?: (
    isRender: boolean,
    setRender: (isRender: boolean) => void,
  ) => JSX.Element | JSX.Element[] | null;
  disabled?: boolean;
  additionalComponent?: ReactNode;
};

export const FilterPanel = memo(
  ({ children, groupTitle, onRenderHint, disabled, additionalComponent }: FilterGroup) => {
    const [isHintVisible, setIsHintVisible] = useState(false);
    const { isMobile } = useDevices();

    const handleHintShow = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      setIsHintVisible((prev) => !prev);
    };

    const handleHintEvents = {
      onMouseEnter: (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isMobile) {
          handleHintShow(e);
        }
      },
      onMouseLeave: (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isMobile) {
          handleHintShow(e);
        }
      },
      onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (isMobile) {
          handleHintShow(e);
        }
      },
    };

    return (
      <div>
        <div className="flex mb-[15px] md:mb-mn-20 3xl:mb-[22px] items-center relative w-fit">
          <div className="flex items-center relative w-fit">
            {onRenderHint && (
              <OutsideClick
                onOutsideClick={() => {
                  setIsHintVisible(false);
                }}
              >
                <div
                  data-testid="opening-hint"
                  role="presentation"
                  {...handleHintEvents}
                  className="absolute w-full h-full top-0 z-10 cursor-pointer"
                />
              </OutsideClick>
            )}

            <span
              className={clsx(
                "text-m-dark-grey font-semibold text-m-sm 3xl:text-m-xl uppercase mr-[14px]",
                {
                  "opacity-20": disabled,
                },
              )}
            >
              {groupTitle}
            </span>

            {onRenderHint && !disabled && (
              <div className="relative flex items-center mr-[14px]">
                <div>
                  <span className="relative top-0 right-0 flex items-center bg-m-semi-dark h-[20px] w-[20px] rounded-full cursor-pointer">
                    <QuestionIcon className="m-absolute-center" />
                  </span>
                </div>

                {onRenderHint?.(isHintVisible, setIsHintVisible)}
              </div>
            )}
          </div>

          {additionalComponent}
        </div>

        <div>{children}</div>
      </div>
    );
  },
);
