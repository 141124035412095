import { useEffect } from "react";

import { CONFIG } from "@/shared/config";

export const useUsedeskScript = () => {
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (CONFIG.IS_DEV) {
      return;
    }
    timer = setTimeout(() => {
      const script = document.createElement("script");

      script.src = "https://lib.usedesk.ru/secure.usedesk.ru/widget_164484_42027.js";
      script.async = true;

      document.body.append(script);
    }, 1000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);
};
