import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { paths } from "@/pages/paths";

import { history } from "@/entities/navigation";

import { scrollToTop } from "@/shared/lib/scroll/scroll-to-top";

type GoToCourseViewProps = {
  children: ReactNode;
  courseId: string;
  onClick?: () => void;
};

const isCoursePage = (pathname: string) => pathname.includes("/courses/");

export const GoToCourseView = ({ children, courseId, onClick }: GoToCourseViewProps) => {
  const handleClickLink = () => {
    if (
      isCoursePage(paths.courseView(courseId)) &&
      isCoursePage(history?.location.pathname || "")
    ) {
      scrollToTop();
    }

    onClick?.();
  };

  return (
    <Link className="w-full" to={paths.courseView(courseId)} onClick={handleClickLink}>
      {children}
    </Link>
  );
};
