import { useEvent } from "effector-react/scope";
import React from "react";

import { useCatalogPageAnalytics } from "@/pages/catalog/analytics";

import { Container, Typography } from "@/shared/ui";

import { FeedbackForm } from "@/feature/feedback-form";
import type { FeedBackFormState } from "@/feature/feedback-form/lib/types";

import { ContactsLink } from "../contacts-link";
import { subscriptionFormSubmitted } from "./model/feedback-model";
import styles from "./styles.module.scss";

const submitButtonName = {
  name: "Получить консультацию",
  submittedName: "Заявка отправлена",
};

const contactsLinkTitle = (
  <Typography tag="h5" size="m-h5" className="text-m-blue uppercase font-semibold">
    или <br /> напишите нам
  </Typography>
);

export function FeedbackFormBlock() {
  const catalogAnalytics = useCatalogPageAnalytics();
  const onSubmit = useEvent(subscriptionFormSubmitted);

  const handleSubmit = (data: FeedBackFormState) => {
    catalogAnalytics.sendFormClicked();
    onSubmit(data);
  };

  return (
    <section className="bg-m-dark-blue py-[36px]">
      <Container
        className="h-[837px] md:h-[490px] xl:h-[507px] 3xl:h-[760px] p-0 md:grid md:grid-cols-[55%_45%] xl:grid-cols-[59%_41%]"
        mode="fixed"
      >
        <div className="relative h-[381px] flex flex-col justify-between bg-m-white md:h-[100%]">
          <div className="relative h-[281px] overflow-hidden md:h-[100%]">
            <div className={styles.FosImage} />
            <div className={styles.Line} />
          </div>
          <Typography
            className="absolute bottom-0 text-m-blue mx-[20px] font-m-sb-display font-semibold uppercase z-10 md:top-[36px] md:mx-[24px] md:text-m-white"
            tag="h4"
            size="m-h4"
          >
            Поможем подобрать обучение и&nbsp;ответим на&nbsp;любые вопросы
          </Typography>
        </div>
        <div className="flex flex-col justify-between h-[456px] bg-m-white px-[20px] py-[30px] md:h-[100%] md:px-[30px] xl:px-[65px] 3xl:px-[100px] 3xl:pt-[60px] 3xl:pb-[90px]">
          <FeedbackForm
            onSubmit={handleSubmit}
            submitButtonName={submitButtonName}
            customStyles={{}}
          />
          <ContactsLink
            socialLinkClassName="grid grid-cols-[60%_40%] items-center mt-[30px] 3xl:w-[505px]"
            iconBlockClassName="flex gap-[16px] md:gap-[20px] 3xl:gap-[30px] 3xl:px-[30px]"
            title={contactsLinkTitle}
            isDark
          />
        </div>
      </Container>
    </section>
  );
}
