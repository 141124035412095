export const MUSE_ID = "6b2cdebc-9330-4262-ab04-4c050a948eab";

export const ADULT_PAGE_SLIDER_COURSES_SECTION = "courses-slider";
export const COURSES_PAGE_CERTIFICATES_SECTION = "certificates";

export enum MouseEventButton {
  Left = 0,
  Middle = 1,
  Right = 2,
}

export enum KeyboardEventKey {
  Enter = "Enter",
}

export const EDUTORIA_EDUCATION_URL = "https://edutoria.ru/education";
export const ACADEMY_URL = "https://academy.edutoria.ru/auth/signin";

export const CONFIRMATION_CODE_LENGTH = 6;

export const FEEDBACK_NAME_PLACEHOLDER = "Анонимный пользователь";
