import Skeleton, { SkeletonProps } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import tailwindConfig from "@/shared/ui/tailwind.params.js";

type SkeletonLoaderProps = SkeletonProps & {
  variant?: "light" | "blue";
};

const colorProps = {
  light: {
    baseColor: tailwindConfig.colors["m-grey"],
    highlightColor: tailwindConfig.colors["m-white-40"],
  },
  blue: {
    baseColor: tailwindConfig.colors["m-semi-dark"],
    highlightColor: tailwindConfig.colors["m-blue"],
  },
};

export const SkeletonLoader = ({ variant = "blue", ...props }: SkeletonLoaderProps) => {
  return <Skeleton {...colorProps[variant]} {...props} />;
};
