import clsx from "clsx";
import { useEvent } from "effector-react/scope";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";

import { paths } from "@/pages/paths";

import { scrollToTop } from "@/shared/lib/scroll/scroll-to-top";
import { Logo, Menu, Typography } from "@/shared/ui";

import { feedbackOpened } from "../analytics";
import { SocialButton } from "./social-button";

type TermsTypeLink = {
  title: string;
  url: string;
  mobileUrl?: string;
  onClick?: () => void;
};

const linkClasses =
  "normal-case m-typography-caption font-light text-m-dark-grey underline underline-offset-2 hover:underline hover:text-m-orange whitespace-nowrap";

const listElementClasses = "leading-[14px]";

const Copyright = () => {
  return (
    <Typography
      tag="p"
      size="m-caption"
      className="relative whitespace-nowrap font-sans text-m-dark-grey font-normal"
    >
      © ООО «СберОбразование», {new Date().getFullYear()}
    </Typography>
  );
};

const getMobileOrDesktopLink = ({
  desktopUrl,
  mobileUrl,
}: {
  desktopUrl: string;
  mobileUrl?: string;
}) => {
  if (mobileUrl) return mobileUrl;
  return desktopUrl;
};

const SubscribeBenefitsItemLink = () => {
  const currentLocation = useLocation();

  const subscribeLink = {
    title: "Преимущества подписки",
    url: paths.subscriptions(),
    onClick: () => {
      scrollToTop({
        behavior: currentLocation.pathname === paths.subscriptions() ? "smooth" : "auto",
      });
    },
  };

  return (
    <Link onClick={subscribeLink.onClick} to={subscribeLink.url}>
      <Typography tag="p" size="m-label" className="uppercase">
        преимущества подписки
      </Typography>
    </Link>
  );
};

const TermsBlock = () => {
  const terms: TermsTypeLink[] = [
    {
      title: "Политика обработки персональных данных",
      url: paths.edutainmentPrivacyPolicy(),
    },
    {
      title: "Положение о конфиденциальности",
      url: paths.termsOfUsePrivacyPolicy(),
      mobileUrl: paths.termsOfUsePrivacyAssets(),
    },
    {
      title: "Пользовательское соглашение",
      url: paths.termsOfUseAgreement(),
      mobileUrl: paths.termsOfUseAgreementAssets(),
    },
    {
      title: "Согласие на использование cookies",
      url: paths.cookies(),
      mobileUrl: paths.cookiesAssets(),
    },
  ];
  return (
    <Menu className="flex flex-col gap-2 md:flex-row md:grid md:grid-cols-2 md:w-[60vw] md:mb-7 xl:flex xl:gap-3 xl:w-fit">
      {terms.map(({ url, title, onClick, mobileUrl }) => (
        <Menu.Link
          onClick={() => onClick?.()}
          className={listElementClasses}
          classNameLink={linkClasses}
          to={getMobileOrDesktopLink({ mobileUrl, desktopUrl: url })}
          key={title}
          variant="underline"
        >
          {title}
        </Menu.Link>
      ))}
    </Menu>
  );
};

const MuseLogo = () => {
  return (
    <div className="w-[95px]" role="presentation" onClick={() => setTimeout(() => scrollToTop())}>
      <Logo.Link className="md:-mt-[10px]" variant="mobile" />
    </div>
  );
};

const Education = () => (
  <div className="flex flex-col gap-4">
    <Typography tag="p" size="m-label" className="uppercase text-m-dark-grey">
      образование
    </Typography>
    <Link to={paths.catalogProgram()} className="">
      <Typography tag="p" size="m-label" className="uppercase">
        программы
      </Typography>
    </Link>
    <a href={paths.teens()} target="_blank" rel="noreferrer">
      <Typography tag="p" size="m-label" className="uppercase">
        детям
      </Typography>
    </a>
  </div>
);

const AboutProject = () => (
  <div className="flex flex-col gap-4 w-32">
    <Typography tag="p" size="m-label" className="uppercase text-m-dark-grey">
      О проекте
    </Typography>
    <SubscribeBenefitsItemLink />
  </div>
);

const Social = () => {
  return (
    <div className="flex flex-col gap-4">
      <Typography tag="p" size="m-label" className="uppercase text-m-dark-grey">
        социальные сети
      </Typography>
      <div className="flex gap-[16px] md:gap-[20px] 3xl:gap-[30px]">
        <SocialButton type="telegramLogo" />
        <SocialButton type="vkLogo" />
        <SocialButton type="dzenLogo" />
      </div>
    </div>
  );
};

const SendUs = () => {
  const isUsedeskMobile = useMediaQuery({ query: `(max-width: 600px)` });

  const clickFeedback = useEvent(feedbackOpened);

  const onCloseButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.scrollTo({
      top: document.body.scrollHeight,
    });
    window.usedeskMessenger.close();
  };

  const findCloseButton = () => {
    const button = document.getElementById("uw-close-chat-button");
    if (button) {
      button.addEventListener("click", onCloseButtonClick, { once: true });
    } else {
      setTimeout(() => {
        findCloseButton();
      }, 500);
    }
  };

  const handleClickMessage = (e: React.MouseEvent) => {
    if (window.usedeskMessenger) {
      window.usedeskMessenger.openChat();
      if (isUsedeskMobile) {
        findCloseButton();
      }
      e.preventDefault();
      e.stopPropagation();
    } else {
      setTimeout(() => {
        handleClickMessage(e);
      }, 1000);
    }
  };
  return (
    <div className="flex flex-col gap-4">
      <Typography tag="p" size="m-label" className="uppercase text-m-dark-grey">
        напишите нам
      </Typography>
      <div className="flex gap-[16px] md:gap-[20px] 3xl:gap-[30px]">
        <SocialButton type="telegramLogo" link="https://t.me/Muse_dpo" />
        <SocialButton type="whatsappLogo" />
        <SocialButton type="messageLogo" onClick={handleClickMessage} />
        <a href="mailto:muse-help@edutoria.ru">
          <SocialButton type="mailLogo" onClick={clickFeedback} />
        </a>
      </div>
    </div>
  );
};
const Divider = () => (
  <div className="border-b-[1px] border-m-blue mt-[20px] mb-[12px] md:mt-[28px] md:mb-[28px]" />
);
const DividerMobile = ({ mt = "30px", mb = "18px" }: { mt?: string; mb?: string }) => (
  <div className={`border-b-[1px] border-m-blue block md:hidden mt-[${mt}] mb-[${mb}]`} />
);

const MuseBlock = () => (
  <div className="flex flex-col md:flex-row justify-between xl:justify-start xl:gap-[6vw] 3xl:gap-[9vw]">
    <div className="flex justify-between min-w-[217px] max-w-[480px] md:w-[30vw] xl:w-[25vw]">
      <Education />
      <AboutProject />
    </div>
    <DividerMobile />
    <Social />
    <div className="md:hidden h-4" />
    <SendUs />
  </div>
);

const MuseContainer = () => (
  <div className="flex flex-col gap-8 xl:grid xl:grid-cols-[25%_75%]">
    <MuseLogo />
    <MuseBlock />
  </div>
);

const DownBlock = () => (
  <div className="flex flex-col xl:flex-row-reverse xl:justify-between">
    <TermsBlock />
    <DividerMobile mt="20px" />
    <Copyright />
  </div>
);

export const Footer = ({ className }: { className?: string }) => {
  return (
    <footer
      className={clsx(
        "pb-[20px] md:pb-[20px] xl:pb-[0px] pt-[33px] px-[20px] md:pt-[33px] md:px-[24px] xl:pt-[48px] xl:px-[26px] 3xl:pt-[48px] 3xl:px-[38px] bg-m-dark-blue font-m-sb-display font-semibold",
        className,
      )}
    >
      <div className="flex flex-col">
        <MuseContainer />
        <Divider />
        <DownBlock />
      </div>
    </footer>
  );
};
