import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";

import { useIntersectionObserver } from "@/shared/lib/intersection-observer";
import { useDevices } from "@/shared/lib/responsive";
import { AnimatePresence, motion } from "@/shared/ui";

import {
  DropdownBackground,
  DropdownButton,
  DropdownContainer,
  DropdownItem,
  DropdownMenu,
  MenuOption,
} from "./dropdown-elements";

type MenuItemsProps = {
  menuItems: MenuOption[];
  onSelect: (option: MenuOption) => void;
};

type MobileMenuItemsProps = {
  onClose: () => void;
  isOpen: boolean;
  toggle: () => void;
  dropdownButtonLabel: string;
  classNameMobileMenu?: string;
} & MenuItemsProps;

const DropdownDesktopMenu = ({ menuItems, onSelect }: MenuItemsProps) => (
  <DropdownBackground>
    <DropdownMenu>
      {menuItems.map((option) => (
        <DropdownItem key={option.value} option={option} onClick={() => onSelect(option)}>
          <div className="flex justify-center items-center">
            {option.icon && <span className="mr-1">{option.icon}</span>}
            {option.label}
          </div>
        </DropdownItem>
      ))}
    </DropdownMenu>
  </DropdownBackground>
);

export const DropdownMobileMenu = ({
  menuItems,
  dropdownButtonLabel,
  isOpen,
  toggle,
  onClose,
  classNameMobileMenu,
  onSelect,
}: MobileMenuItemsProps) => {
  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{
        x: 0,
      }}
      exit={{
        x: "-100%",
      }}
      transition={{ type: "spring", bounce: 0, duration: 0.4 }}
      className={clsx(
        "absolute left-0 h-full w-[214px] z-50 top-0 bg-m-blue-80 backdrop-blur-[6px] shadow-[0px 0px 30px rgba(39, 34, 100, 0.6)]",
        classNameMobileMenu,
      )}
    >
      <DropdownContainer onClose={onClose} className="mx-auto">
        <DropdownMenu>
          <div className="flex justify-center flex-col items-center pt-11 mr-4">
            <DropdownButton className="relative z-20 mb-8" isOpen={isOpen} onClick={toggle}>
              {dropdownButtonLabel}
            </DropdownButton>

            {menuItems.map((option) => (
              <DropdownItem key={option.value} option={option} onClick={() => onSelect(option)}>
                <div className="flex justify-center items-center">
                  {option.icon && <span className="mr-1">{option.icon}</span>}
                  {option.label}
                </div>
              </DropdownItem>
            ))}
          </div>
        </DropdownMenu>
      </DropdownContainer>
    </motion.div>
  );
};

type DropdownProps = {
  onSelect: (option: MenuOption) => void;
  menuItems: MenuOption[];
  selectedOptionLabel: string;
  classNameMobileMenu?: string;
  buttonClass?: string;
  isOpenProp?: boolean;
  close?: boolean;
};

export const Dropdown = ({
  onSelect,
  menuItems,
  selectedOptionLabel,
  classNameMobileMenu,
  buttonClass,
  close,
  isOpenProp = true,
}: DropdownProps) => {
  const [isOpenState, setIsOpenState] = useState(false);
  const { isMobile } = useDevices();

  const isOpenedDesktopMenu = useMemo(() => !isMobile && isOpenState, [isMobile, isOpenState]);
  const isOpenedMobileMenu = useMemo(
    () => isMobile && isOpenState && isOpenProp,
    [isMobile, isOpenState, isOpenProp],
  );

  const { ref, inView } = useIntersectionObserver({
    rootMargin: "200px 0px 0px 0px",
    threshold: 0.5,
  });

  const toggle = () => setIsOpenState((prev) => !prev);

  useEffect(() => {
    if (!inView && !isMobile) setIsOpenState(false);
  }, [inView]);

  useEffect(() => {
    setIsOpenState(false);
  }, [close]);

  const handleSelect = (option: MenuOption) => {
    setIsOpenState(false);
    onSelect(option);
  };

  return (
    <div ref={ref}>
      <DropdownContainer
        className="mx-auto"
        onClose={() => (isMobile ? () => null : setIsOpenState(false))}
      >
        <DropdownButton
          className={clsx("relative z-20", buttonClass, {
            "opacity-0": isOpenedMobileMenu,
          })}
          isOpen={isOpenState}
          onClick={toggle}
        >
          {selectedOptionLabel}
        </DropdownButton>

        {isOpenedDesktopMenu && (
          <DropdownDesktopMenu menuItems={menuItems} onSelect={handleSelect} />
        )}
      </DropdownContainer>

      <AnimatePresence>
        {isOpenedMobileMenu && (
          <DropdownMobileMenu
            onSelect={handleSelect}
            isOpen={isOpenState}
            menuItems={menuItems}
            dropdownButtonLabel={selectedOptionLabel ?? ""}
            onClose={() => setIsOpenState(false)}
            toggle={toggle}
            classNameMobileMenu={classNameMobileMenu}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
