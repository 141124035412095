import { useStore } from "effector-react/scope";

import DefaultAvatar from "@/shared/ui/assets/images/user-avatar/default.jpeg";

import { $studentProfilePicture } from "../model/profile-picture";

export const useUserProfilePictureOrDefault = () => {
  const uploadedPicture = useStore($studentProfilePicture);

  const userHasUploadedPicture = Boolean(uploadedPicture);
  const userProfilePicture = userHasUploadedPicture ? uploadedPicture : DefaultAvatar;

  return {
    userProfilePicture,
    userHasUploadedPicture,
  };
};
