import { createDomain, sample, createEffect, createStore, combine, createEvent } from "effector";
import { createGate } from "effector-react";
import { createHatch } from "framework";

import { Feedback, FeedbackStats } from "@app/api";

import { getCourseFx, getCourseSpeakerFx } from "@/pages/course-view/model";

import { FeedbacksAPI, UserApi } from "@/shared/api";
import { FEEDBACK_NAME_PLACEHOLDER } from "@/shared/constants";

export {
  $courseName,
  $courseAbout,
  $courseImage,
  $isCourseFetchPending,
  $speaker,
  $coursePrice,
  $currentCourse,
  $courseId,
} from "@/pages/course-view/model";

const FEEDBACKS_LIMIT = 5;

export const hatch = createHatch(createDomain("CourseFeedbacksViewPage"));
export const FeedbackListGate = createGate<{ courseId: string }>();

export const getUsersDisplayNamesFx = createEffect(UserApi.getUsersDisplayNames);
export const getCourseFeedbacksFx = createEffect(FeedbacksAPI.fetchFeedbacks);
export const getCourseFeedbacksStatisticFx = createEffect(FeedbacksAPI.fetchFeedbacksStatistic);

export const onShowMoreClickEvent = createEvent();

export const showMoreButtonToggled = createEvent<boolean>();

export const $feedbacks = createStore<Feedback[]>([])
  .on(getCourseFeedbacksFx.doneData, (prev, feedbacks) => {
    const res = [...prev, ...feedbacks];

    const seenIds = new Map();

    return res.filter((item) =>
      seenIds.get(`${item.id}${item?.feedback}`) === undefined
        ? (seenIds.set(`${item.id}${item?.feedback}`, true), true)
        : false,
    );
  })
  .reset(FeedbackListGate.close);

export const $feedbacksStatistic = createStore<FeedbackStats | null>(null)
  .on(getCourseFeedbacksStatisticFx.doneData, (_, stats) => ({ ...stats }))
  .reset(FeedbackListGate.close);

export const $totalNumber = createStore(0)
  .on(getCourseFeedbacksStatisticFx.doneData, (_, stats) => stats.totalNumber ?? 0)
  .reset(FeedbackListGate.close);

const $displayNames = createStore<Record<string, string>>({})
  .on(getUsersDisplayNamesFx.doneData, (_, displayNames) => ({ ...displayNames }))
  .reset(FeedbackListGate.close);

const $firstItemIndex = createStore(0)
  .on(onShowMoreClickEvent, (prev) => prev + FEEDBACKS_LIMIT)
  .reset(FeedbackListGate.close);

export const $displayShowMoreButton = createStore(true)
  .on(showMoreButtonToggled, (_, isShow) => isShow)
  .reset(FeedbackListGate.close);

export const $feedbacksFullInfo = combine($feedbacks, $displayNames, (feedbacksRaw, namesById) =>
  feedbacksRaw.map((f) => ({
    ...f,
    name: namesById[f.userId] || FEEDBACK_NAME_PLACEHOLDER,
  })),
);

sample({
  clock: [$feedbacksStatistic, getCourseFeedbacksFx.doneData],
  source: [$firstItemIndex, $totalNumber],
  fn: ([firstItemIndex, totalNumber]) => totalNumber > FEEDBACKS_LIMIT + firstItemIndex,
  target: showMoreButtonToggled,
});

sample({
  clock: getCourseFeedbacksFx.done,
  source: $feedbacks,
  fn: (feedbacks) => feedbacks.map((feedback) => feedback.userId),
  target: getUsersDisplayNamesFx,
});

sample({
  clock: hatch.enter,
  fn: ({ params }) => ({ courseId: params.courseId }),
  target: [getCourseFx, getCourseSpeakerFx],
});

sample({
  clock: FeedbackListGate.open,
  fn: ({ courseId }) => courseId,
  target: getCourseFeedbacksStatisticFx,
});

sample({
  clock: FeedbackListGate.open,
  source: $firstItemIndex,
  fn: (firstItemIndex, { courseId }) => ({ courseId, offset: firstItemIndex }),
  target: getCourseFeedbacksFx,
});

sample({
  clock: $firstItemIndex,
  source: FeedbackListGate.open,
  filter: (_, firstItemIndex) => !!firstItemIndex,
  fn: ({ courseId }, firstItemIndex) => ({ courseId, offset: firstItemIndex }),
  target: getCourseFeedbacksFx,
});
