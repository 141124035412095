import { createEffect, sample } from "effector";
import posthog from "posthog-js";
import { matchPath } from "react-router";

import { ClientServerFactory } from "@app/api";
import * as Sentry from "@sentry/react";

import { routes } from "@/pages/routes";

import { history } from "@/entities/navigation";
import { userSettled } from "@/entities/user";

import { CONFIG, IS_PROD_ENVIRONMENT } from "@/shared/config";

const setUserInSentry = () => {
  sample({
    clock: userSettled,
    fn: (user) => user?.id,
    target: createEffect((id: string | undefined) => Sentry.setUser({ id })),
  });
};

export const initializeClientSentry = () => {
  if (IS_PROD_ENVIRONMENT) {
    const projectId = CONFIG.SENTRY_DSN.split("/").pop() ?? "";

    Sentry.init({
      dsn: CONFIG.SENTRY_DSN,
      initialScope: {
        tags: {
          corellationId: ClientServerFactory.getCorrelationId(),
        },
      },
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation({
            history,
            routes,
            matchPath,
          }),
        }),

        new posthog.SentryIntegration(posthog, CONFIG.SENTRY_ORGANIZATION, +projectId),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      environment: CONFIG.ENVIRONMENT,
      release: CONFIG.SENTRY_RELEASE,
      tracesSampleRate: 1.0,
    });

    setUserInSentry();
  }
};
