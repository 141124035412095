import { PreviousSubscription, UserProduct } from "@app/api";

import { ProductType } from "../types";

type SubscriptionProduct = Nullable<UserProduct>;
type Subscription = SubscriptionProduct | PreviousSubscription;

type SubscriptionsType = {
  newMuseSubscription: SubscriptionProduct;
  oldMuseSubscription: SubscriptionProduct;
  edPlusSubscription: SubscriptionProduct;
};

export const isOldMuseSubscription = (subscription: Nullable<Subscription>): boolean =>
  subscription?.productType === ProductType.Subscription && subscription.notVisible;

export const isNewMuseSubscription = (subscription: Nullable<Subscription>): boolean =>
  subscription?.productType === ProductType.MuseSubscription;

export const isMuseSubscription = (subscription: Nullable<Subscription>): boolean =>
  isOldMuseSubscription(subscription) || isNewMuseSubscription(subscription);

export const isEdPlusSubscription = (subscription: Nullable<Subscription>): boolean =>
  subscription?.productType === ProductType.Subscription && !subscription.notVisible;

export const getSubscriptionProductType = (
  boughtSubscription: UserProduct[],
): SubscriptionsType => {
  return boughtSubscription.reduce<SubscriptionsType>(
    (acc, subscription) => {
      if (isOldMuseSubscription(subscription)) {
        acc.oldMuseSubscription = subscription;
      }
      if (isNewMuseSubscription(subscription)) {
        acc.newMuseSubscription = subscription;
      }
      if (isEdPlusSubscription(subscription)) {
        acc.edPlusSubscription = subscription;
      }
      return acc;
    },
    {
      oldMuseSubscription: null,
      newMuseSubscription: null,
      edPlusSubscription: null,
    },
  );
};
