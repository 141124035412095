import { sample } from "effector";

import { AuthApi } from "@/shared/api";
import { confirmError } from "@/shared/api/constants";

import { openAuthModal } from "@/app/authorization-modals/model";
import { createConfirmCodeModel } from "@/feature/confirm-code-modal";
import { createCountdownTimer } from "@/feature/countdown-button";

import { $signUpPayload, signUpUserFx, submittedSignUpForm } from "./sign-up";

export const confirmCodeSignUp = createConfirmCodeModel({
  source: $signUpPayload,
  sendFx: AuthApi.signUpConfirm,
  reset: [openAuthModal.close],
});

export const countdownTimerSignUp = createCountdownTimer({});

sample({
  clock: confirmCodeSignUp.requestFx.done,
  target: openAuthModal.confirmedSignUp,
});

sample({
  clock: confirmCodeSignUp.requestFx.failData,
  filter: ({ type }) => !confirmError[type],
  target: openAuthModal.signUpConfirmationError,
});

sample({
  clock: confirmCodeSignUp.sendAgain,
  source: submittedSignUpForm,
  target: signUpUserFx,
});

sample({
  source: signUpUserFx.doneData,
  fn: ({ timeout }) => timeout,
  target: countdownTimerSignUp.startCountdown,
});
