/* eslint-disable  @typescript-eslint/no-explicit-any */
import { createDomain, createEffect, Effect, sample } from "effector";
import { GQtyError } from "gqty";

import * as Sentry from "@sentry/react";

export class GqlError extends Error {
  extensions?: {
    code: string;
    scope: string;
    message_vars: Array<number | string>;
  };

  constructor(error: GQtyError, originalError?: unknown) {
    super(error.message);
    this.stack = error.stack;
    if ((originalError as any)?.stack) {
      this.stack += `\r\n${(originalError as any).stack}`;
    }
    if (!error.graphQLErrors) return;

    const [{ message, extensions }] = error.graphQLErrors;

    this.message = message;
    this.extensions = {
      code: (extensions.code as string) ?? "",
      scope: (extensions.scope as string) ?? "",
      message_vars: (extensions.message_vars as Array<string>) ?? [],
    };
  }
}

const logErrorsDomain = createDomain();

if (process.env.NODE_ENV !== "production") {
  logErrorsDomain.onCreateEffect((fx) => {
    // TODO: replace to `is.attached` when https://github.com/effector/effector/pull/670 released
    if ((fx as any).graphite.meta.attached)
      fx.fail.watch(({ params, error }) => {
        console.warn(`Effect "${fx.compositeName.fullName}" failed`, params, error);
      });
  });
}

const sentryExceptionFx = createEffect((error: unknown) => {
  return Sentry.captureException(error);
});

/**
 * Creates Effect, infers Params and Result types from parameters
 * Please do not use generic parameters!
 * It's intended to infer them from function parameters
 */
export function createGqlEffect<TParams = void, TDone = void>(
  handler: (params: TParams) => Promise<TDone>,
  name?: string,
): Effect<TParams, TDone, GqlError> {
  const gqlFx = createEffect({ handler, name });

  sample({
    clock: gqlFx.failData,
    target: sentryExceptionFx,
  });

  return gqlFx;
}
