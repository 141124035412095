import clsx from "clsx";
import { useEvent, useStore } from "effector-react/scope";
import { ReactNode } from "react";

import loadable from "@loadable/component";

import { ButtonStartSubscription } from "@/widgets/payment";

import { userPurchasesModel } from "@/entities/user-purchases";

import { analyticsEvents } from "@/shared/analytics";
import { useIntersectionObserver } from "@/shared/lib/intersection-observer";
import { useDevices } from "@/shared/lib/responsive";
import { AnimatePresence, motion, BurgerButton, Logo, Navbar } from "@/shared/ui";

import { SearchBar } from "@/feature/search";

import { $isMenuVisible, toggleMobileMenu } from "../model/mobile-menu-toggle";
import { ProfileMenu } from "../ui/profile-menu/profile-menu";
import { AuthUserAvatar } from "./auth-user-avatar";
import { HeaderMenu } from "./header-menu";
import styles from "./header.module.scss";

export const MobileMenu = loadable(
  () => import("./mobile-menu").then((component) => component.MobileMenu),
  {
    ssr: false,
  },
);

type HeaderProps = {
  subscribeButtonFixed?: boolean;
  className?: string;
};

const MenuBar = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div className="hidden md:block md:mr-[24px] md:w-[20vw] xl:w-[300px] xl:mr-[48px]">
        <SearchBar />
      </div>
      <HeaderMenu />
      {children}
    </>
  );
};

const HeaderStartButton = ({ isMoving }: { isMoving: boolean }) => {
  return (
    <div className={styles.HeaderStartButtonWrapper}>
      <ButtonStartSubscription analytics={{ positionInfo: isMoving ? "moving" : "top" }} />
    </div>
  );
};

export const HeaderDesktop = ({ subscribeButtonFixed = false, className = "" }: HeaderProps) => {
  const { isMobile } = useDevices();
  const hasMuseSubscription = useStore(userPurchasesModel.$hasUserMuseSubscription);

  const handlers = useEvent({ logoClicked: analyticsEvents.clickLogo });

  const { ref, inView } = useIntersectionObserver({
    threshold: 0.25,
    rootMargin: "200px 0px 600px 0px",
  });

  const startButtonNode = !hasMuseSubscription && (
    <HeaderStartButton isMoving={subscribeButtonFixed} />
  );

  return (
    <div className="relative" ref={ref}>
      <Navbar
        className={clsx("hidden md:block bg-m-dark-blue xl:min-h-header-1920-height", className)}
      >
        <Navbar.Body>
          <Navbar.Left>
            <Logo.Link className="h-[30px] w-auto" onClick={() => handlers.logoClicked()} />
          </Navbar.Left>

          <Navbar.Menu>
            <AnimatePresence initial={false} exitBeforeEnter key="start">
              {!inView && subscribeButtonFixed && (
                <motion.div
                  initial={{ opacity: 0, y: -30, scale: 0.3 }}
                  animate={{ opacity: 1, y: 0, scale: 1, transition: { duration: 0.3 } }}
                  exit={{ opacity: 0, y: 0, scale: 0.3, transition: { duration: 0.1 } }}
                  className="fixed z-50 right-mv-6 xl:right-[68px]"
                >
                  {startButtonNode}
                </motion.div>
              )}
            </AnimatePresence>

            <MenuBar>{startButtonNode}</MenuBar>

            <div className={styles.HeaderProfile}>
              <AuthUserAvatar />
              <ProfileMenu outsideOff={isMobile} />
            </div>
          </Navbar.Menu>
        </Navbar.Body>
      </Navbar>
    </div>
  );
};

export const HeaderMobile = () => {
  const isMenuVisible = useStore($isMenuVisible);
  const handlers = useEvent({ toggleMobileMenu, logoClicked: analyticsEvents.clickLogo });

  return (
    <>
      <Navbar
        className={clsx(
          "z-[60] inset-0 md:hidden bg-m-dark-blue fixed w-[100vw] flex opacity-90 duration-200 transition-all",
          {
            "opacity-100": isMenuVisible,
          },
        )}
      >
        <Navbar.Body>
          <Navbar.Left>
            <div>
              <Navbar.Menu>
                <Logo.Link
                  onClick={() => {
                    handlers.toggleMobileMenu(false);
                    handlers.logoClicked();
                  }}
                />
              </Navbar.Menu>
            </div>
          </Navbar.Left>
          <Navbar.Right>
            <BurgerButton
              isOpen={isMenuVisible}
              onClick={() => handlers.toggleMobileMenu(!isMenuVisible)}
            />
          </Navbar.Right>
        </Navbar.Body>
        <MobileMenu
          isMenuVisible={isMenuVisible}
          onMenuClose={() => {
            handlers.toggleMobileMenu(false);
          }}
        />
      </Navbar>
      <Navbar className="z-[59] md:hidden bg-m-dark-blue w-[100vw] flex" />
    </>
  );
};

export const Header = {
  Desktop: HeaderDesktop,
  Mobile: HeaderMobile,
};
