import focusLock from "dom-focus-lock";
import { MutableRefObject, useEffect } from "react";

export function useFocusLock(enabled: boolean, ref: MutableRefObject<HTMLElement | null>) {
  useEffect(() => {
    if (!enabled) return;
    const element = ref.current;
    if (!element) return;
    focusLock.on(element);
    return () => focusLock.off(element);
  }, [enabled, ref]);
}
