import clsx from "clsx";
import { useEvent } from "effector-react/scope";
import React from "react";

import { paths } from "@/pages/paths";

import { ButtonStartSubscription, paymentManagement } from "@/widgets/payment";

import { ButtonRounded, Container, Typography } from "@/shared/ui";
import poster from "@/shared/ui/assets/images/home/video_poster.webp";

import { FormSubscribe } from "../../form-subscribe";
import { subscribeButtonClicked, topicButtonClicked } from "../analytics";
import styles from "./styles.module.scss";

export const VideoSection = () => {
  return (
    <div className={clsx("brightness-[0.8]")}>
      <video className={styles.Video} autoPlay loop muted poster={poster} playsInline>
        <source src="https://assets.edutoria.ru/courses/bk/muse_main.mp4" type="video/mp4" />
      </video>
    </div>
  );
};

export const FreePromoBlock = () => {
  const handlers = useEvent({
    paymentModalOpened: paymentManagement.paymentModalOpened,
    subscribeButtonClicked,
  });

  const subscribeButtonHandler = () => {
    handlers.paymentModalOpened(paymentManagement.PaymentModalType.Subscription);
    handlers.subscribeButtonClicked("Получить");
  };

  return (
    <div className="bg-m-white w-full xl:w-[25vw] md:max-w-[350px] xl:max-w-[500px] font-bold p-mn-26 3xl:p-mn-38 rounded-[20px]">
      <Typography
        tag="h5"
        size="m-h5"
        className="gradient-text mb-mn-10 3xl:mb-mn-16 uppercase font-m-sb-display"
      >
        3 дня бесплатного доступа ко&nbsp;всем курсам и&nbsp;скидка&nbsp;10%
      </Typography>
      <Typography
        tag="p"
        size="m-label"
        className="uppercase mb-mn-20 3xl:mb-mn-26 text-m-blue font-m-sb-display"
      >
        на любую программу дополнительного образования
      </Typography>
      <ButtonRounded
        onClick={subscribeButtonHandler}
        variant="tertiary"
        className="w-full 3xl:h-[45px]"
      >
        Получить
      </ButtonRounded>
    </div>
  );
};

type Chip = {
  name: string;
  link: string;
};

const chips: Chip[] = [
  {
    name: "культурное наследие",
    link: paths.domKultury(),
  },
  { name: "мода", link: paths.cybermoda() },
  {
    name: "современный театр",
    link: paths.theatre(),
  },
  { name: "кино", link: "https://muse.edutoria.ru/courses/b3f94fc4-c4a0-4c3a-9b07-f377b134109e" },
  {
    name: "генеративный дизайн",
    link: paths.neuro(),
  },
  {
    name: "цифровое искусство",
    link: paths.gameDesign(),
  },
  {
    name: "концертная индустрия",
    link: paths.festival(),
  },
];

const TopicChip = ({
  text,
  className,
  link,
}: {
  text: string;
  className?: string;
  link: string;
}) => {
  const spacingClassNames =
    "px-[32px] py-[12px] 3xl:px-[48px] 3xl:py-[18px] m-[2.5px] md:m-[5px] cursor-pointer";
  const sendClickEventToAnalytics = useEvent(topicButtonClicked);

  const handleTopicClick = () => {
    sendClickEventToAnalytics(text);
  };

  return (
    <a
      href={link}
      className={clsx(
        "text-m-xs 3xl:text-m-lg rounded-[30px] md:hover:bg-m-white bg-m-white-10 md:bg-m-blue text-m-white md:child:hover:gradient-text uppercase font-bold",
        className,
        spacingClassNames,
      )}
      onClick={handleTopicClick}
    >
      <span>{text}</span>
    </a>
  );
};

const Topics = () => {
  return (
    <div className="mb-mn-50 md:mb-0 md:max-w-[700px] xl:max-w-[820px] 3xl:max-w-[1200px] flex flex-wrap">
      {chips.map((chip) => (
        <TopicChip link={chip.link} key={chip.name} text={chip.name} />
      ))}
    </div>
  );
};

export const WelcomeSection = () => {
  return (
    <div className="relative min-h-full">
      <VideoSection />

      <Container
        mode="full"
        className={clsx(styles.Wrapper, "flex flex-col justify-between absolute")}
      >
        <div>
          <h1
            className={clsx(
              "font-bold text-m-white uppercase mb-mn-38 3xl:mb-mn-74 md:mb-mn-26",
              styles.MainTitle,
            )}
          >
            Учитесь <br />
            творчеству
          </h1>
          <Topics />
          <ButtonStartSubscription className="block w-full md:w-auto md:hidden mx-auto" />
        </div>
        <FormSubscribe />
      </Container>
    </div>
  );
};
