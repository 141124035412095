import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { StarRating, StarRatingProps } from "@/entities/feedback/ui/star-rating";

import { Typography } from "@/shared/ui";

const MAX_FEEDBACK_TEXT_HEIGHT = 1000;

type FeedbackCardProps = {
  title: string;
  text: string;
  rating: StarRatingProps["value"];
  avatarSrc: string;
};

export type FeedbackCardHandle = {
  resetExpand: () => void;
};

export const FeedbackCard = forwardRef<FeedbackCardHandle, FeedbackCardProps>(
  ({ title, text, rating, avatarSrc }, ref) => {
    const [expanded, setExpanded] = useState(false);
    const [isLineClampUsed, setIsLineClampUsed] = useState(false);
    const [startHeight, setStartHeight] = useState<number | undefined>(undefined);

    const feedbackTextRef = useRef<HTMLDivElement>(null);

    const onExpandClick = () => {
      setExpanded(!expanded);
    };

    useEffect(() => {
      if (!feedbackTextRef.current) return;
      setIsLineClampUsed(
        feedbackTextRef.current.scrollHeight > feedbackTextRef.current.clientHeight,
      );
      setStartHeight(feedbackTextRef.current?.getBoundingClientRect()?.height);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        resetExpand() {
          setExpanded(false);
        },
      }),
      [],
    );

    const capitalizedTitle = useMemo(
      () =>
        title
          ?.toLowerCase()
          ?.split(" ")
          ?.map((word) => `${word?.[0].toUpperCase()}${word?.slice(1)}`)
          .join(" "),
      [title],
    );

    return (
      <article className="text-m-white w-full">
        <div className="flex pb-[37px] gap-[23px] flex-col md:flex-row items-center">
          <img src={avatarSrc} alt={title} className="w-[85px] h-[85px] rounded-full" />
          <div className="flex gap-[16px] flex-col items-center md:items-start">
            <Typography tag="h3" size="m-lead" className="font-m-sb-display font-semibold">
              {capitalizedTitle}
            </Typography>
            <StarRating value={rating} />
          </div>
        </div>
        <div className="max-w-full xl:max-w-[706px] 3xl:max-w-[920px]">
          <div
            ref={feedbackTextRef}
            className="font-sans font-medium m-typography-text"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: expanded ? "1000" : "5",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              transition: "max-height 1s ease-in",
              maxHeight: expanded ? MAX_FEEDBACK_TEXT_HEIGHT : startHeight,
            }}
          >
            {text}
          </div>
          {isLineClampUsed && (
            <div className="flex justify-end pt-2">
              <button
                onClick={onExpandClick}
                className="font-m-sb-display font-semibold text-m-dark-grey uppercase border-b-[2px] border-m-dark-grey m-typography-label xl:hover:border-m-orange xl:hover:text-m-orange"
              >
                {expanded ? "Скрыть" : "Узнать больше"}
              </button>
            </div>
          )}
        </div>
      </article>
    );
  },
);
