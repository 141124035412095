import React from "react";

import { Container, Typography } from "@/shared/ui";

import { useCatalogPageAnalytics } from "../../analytics";
import styles from "./styles.module.scss";

const SocialMediaButtonGroup = () => {
  const catalogAnalytics = useCatalogPageAnalytics();

  return (
    <div className="flex flex-col justify-between">
      <Typography
        className="font-m-sb-display font-semibold leading-none uppercase mb-[32px] md:mb-[20px]"
        tag="h3"
        size="m-h3"
      >
        СЛЕДИТЕ ЗА&nbsp;НАМИ В&nbsp;СОЦИАЛЬНЫХ СЕТЯХ
      </Typography>
      <div className="flex gap-[16px] md:gap-[20px] 3xl:gap-[30px]">
        <a
          onClick={() => catalogAnalytics.socialLinkClicked("telegram")}
          href="https://t.me/muse_university "
          className={styles.TelegramIcon}
          target="_blank"
          rel="noreferrer"
        />
        <a
          onClick={() => catalogAnalytics.socialLinkClicked("vkontakte")}
          href="https://vk.com/club219946225 "
          className={styles.VKIcon}
          target="_blank"
          rel="noreferrer"
        />
      </div>
    </div>
  );
};

export function SocialMediaBlock() {
  return (
    <section className="bg-m-dark-blue">
      <Container
        className="h-[566px] relative flex items-end text-m-white py-[43px] overflow-hidden md:items-start md:h-[603px] md:py-[50px] xl:pl-[756px] xl:pt-[76px] xl:h-[485px] 3xl:h-[728px] 3xl:pl-[1134px]"
        mode="fixed"
      >
        <div className={styles.SocialMediaImage1} />
        <div className={styles.SocialMediaImage2} />
        <div className={styles.SocialMediaImage3} />
        <div className={styles.SocialMediaLine} />
        <SocialMediaButtonGroup />
      </Container>
    </section>
  );
}
