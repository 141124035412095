import clsx from "clsx";
import { ReactNode } from "react";

import loadable from "@loadable/component";

import { Chip } from "@/shared/ui";
import defaultImage from "@/shared/ui/assets/images/home/banners/1920-2.webp";

import styles from "./card.module.scss";

export const MobileNode = loadable(
  () => import("./card-render-node").then((component) => component.CardMobileNode),
  {
    ssr: false,
  },
);

export const DesktopNode = loadable(
  () => import("./card-render-node").then((component) => component.CardDesktopNode),
  {
    ssr: false,
  },
);

type CardProps = {
  img?: string;
  title: string;
  labels?: string[];
  chips?: string[];
  leftTopNode?: ReactNode;
  showNodeOnHover?: ReactNode;
};

export const Background = ({ image }: { image: string }) => {
  return (
    <div>
      <div
        className={styles.CardCover}
        style={{
          backgroundImage: `url('${image}')`,
        }}
      >
        <div className={styles.CardOverlay} />
      </div>
    </div>
  );
};

export const CardContainer = ({ children }: { children: ReactNode }) => (
  <article className={styles.CardContainer}>{children}</article>
);

const ListChips = ({ chips }: { chips?: string[] }) => {
  if (!chips?.length) return null;

  return (
    <div className="flex flex-wrap">
      {chips.map((chip) => {
        if (chip) return <Chip key={chip} text={chip} className={clsx(styles.CardChip)} />;

        return null;
      })}
    </div>
  );
};

const Body = ({ children }: { children: ReactNode }) => {
  return <div className={styles.CardBody}>{children}</div>;
};

const Title = ({ children }: { children: ReactNode }) => (
  <h1 className={styles.CardTitle}>{children}</h1>
);

export const Labels = ({ labels }: { labels?: CardProps["labels"] }) => {
  if (!labels?.length) return null;

  return (
    <ul className={styles.CardLabels}>
      {labels.map((label) => {
        if (label) {
          return <li key={label}>{label}</li>;
        }

        return null;
      })}
    </ul>
  );
};

const Bottom = ({ children }: { children: ReactNode }) => {
  return <div>{children}</div>;
};

const Top = ({ children }: { children: ReactNode }) => {
  return <div className={styles.CardTop}>{children}</div>;
};

const LeftTopNode = ({ children }: { children: ReactNode }) => {
  return <div>{children}</div>;
};

const ShowOnHover = ({ children }: { children: ReactNode }) => {
  return <div className={styles.HoverBlock}>{children}</div>;
};

export const Card = ({ img, showNodeOnHover, leftTopNode, labels, chips, title }: CardProps) => {
  return (
    <CardContainer>
      <Background image={img || defaultImage} />
      <Body>
        <Top>
          <LeftTopNode>{leftTopNode}</LeftTopNode>
          <ListChips chips={chips} />
        </Top>
        <Bottom>
          <ShowOnHover>{showNodeOnHover}</ShowOnHover>
          <Labels labels={labels} />
          <Title>{title}</Title>
        </Bottom>
      </Body>
    </CardContainer>
  );
};
