export function isEmpty<T>(obj: T) {
  return Object.keys(obj).length === 0;
}

export function notEmptyObjectOrNull<T>(obj: T | null): T | null {
  if (obj && Object.keys(obj).length !== 0) {
    return obj;
  }

  return null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createObjectWithoutUndefined(obj: Record<string, any>): Record<string, any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newObj: Record<string, any> = {};
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}
