import { CalculatedPromo, SubscriptionPayInfo } from "@app/api";

import { SubscriptionType } from "@/entities/user-purchases";

import { getClientId } from "@/shared/analytics";
import { PAYMENT_SUBSCRIPTION_PLATFORM } from "@/shared/api/constants";

import { PaymentProcessParam } from "@/feature/payment-widget";

type CreatePromocodePaymentProps = {
  userId: string;
  promocode: string;
  subscriptionPaymentInfo: SubscriptionPayInfo | null;
  calculatedPromo: CalculatedPromo | null;
};

export const createPromocodePaymentProps = ({
  userId,
  promocode,
  subscriptionPaymentInfo,
  calculatedPromo,
}: CreatePromocodePaymentProps): PaymentProcessParam => {
  return {
    platform: PAYMENT_SUBSCRIPTION_PLATFORM,
    isTrial: true,
    periodType: "defined_period",
    subscribeType: SubscriptionType.Recurring,
    user: { userId },
    partnerPromocode: promocode,
    gaClientId: getClientId(),
    definedTermPeriodType:
      (calculatedPromo?.intervalType.toLowerCase() as PaymentProcessParam["definedTermPeriodType"]) ||
      undefined,
    period: calculatedPromo?.intervalCount || 0,
    product: {
      productId: calculatedPromo?.platformId || "",
      productPrice: "1",
      productName: subscriptionPaymentInfo?.productType,
    },
    promocodeType: "b2c",
  };
};
