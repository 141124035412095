import clsx from "clsx";
import React from "react";

export function Title({ text, className }: { text?: string; className?: string }) {
  return (
    <h3
      className={clsx(
        "uppercase text-m-white font-m-sb-display md:px-0 font-semibold text-m-lg xl:text-m-2xl 3xl:text-m-5xl",
        className,
      )}
    >
      {text}
    </h3>
  );
}
