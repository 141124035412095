import { useStore } from "effector-react/scope";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { FeedbacksList } from "@/pages/course-feedbacks-view/modules/feedbacks-list";
import { DOMAIN_SITE, paths } from "@/pages/paths";

import { CourseHeaderBlock } from "@/widgets/course-header-block/course-header-block";
import { CourseHeaderBlockSkeleton } from "@/widgets/course-header-block/course-header-block-skeleton";
import { Footer } from "@/widgets/footer";
import { HeaderDesktop, HeaderMobile } from "@/widgets/header/ui/header";

import { CurrentCourseId } from "@/entities/course/types";

import { scrollToTop } from "@/shared/lib/scroll/scroll-to-top";
import { ContentHeightLayout, SEO } from "@/shared/ui";

import {
  $courseName,
  $courseAbout,
  $courseImage,
  $isCourseFetchPending,
  $speaker,
  $currentCourse,
  $courseId,
} from "./model";
import { FeedbacksBackToCourse } from "./modules/feedbacks-back-to-course";

const MetaTags = () => {
  const courseName = useStore($courseName);
  const description = useStore($courseAbout);
  const image = useStore($courseImage);
  const courseId = useStore($courseId);

  return (
    <SEO
      title={`Отзывы ${courseName}`}
      description={description}
      image={image}
      canonical={`${DOMAIN_SITE}${paths.courseFeedbacksView(courseId)}`}
    />
  );
};

export function CourseFeedbacksViewPage() {
  const courseFetchPending = useStore($isCourseFetchPending);

  const { courseId }: CurrentCourseId = useParams();
  const currentCourse = useStore($currentCourse);
  const speaker = useStore($speaker);

  useEffect(() => {
    scrollToTop({ behavior: undefined });
  }, []);

  return (
    <>
      <MetaTags />
      <HeaderDesktop />
      <HeaderMobile />
      <ContentHeightLayout className="bg-m-dark-blue">
        {courseFetchPending ? (
          <CourseHeaderBlockSkeleton className="bg-m-semi-dark" />
        ) : (
          <CourseHeaderBlock
            className="bg-m-semi-dark"
            courseId={courseId}
            course={currentCourse}
            speaker={speaker}
          />
        )}
        <FeedbacksBackToCourse />
        <FeedbacksList className="flex-grow bg-m-semi-dark md:bg-m-dark-blue" />
      </ContentHeightLayout>
      <Footer />
    </>
  );
}
