import clsx from "clsx";
import React from "react";

import { DOMAIN_SITE, paths } from "@/pages/paths";

import { Footer } from "@/widgets/footer";
import { Header } from "@/widgets/header";

import { analyticsEvents } from "@/shared/analytics";
import { GifteryCode } from "@/shared/lib/giftery/giftery-code";
import { scrollToElement } from "@/shared/lib/scroll";
import { ButtonRounded, LandingBlock, SEO, Typography } from "@/shared/ui";
import arrowBlue from "@/shared/ui/assets/images/icons/arrow-direction-blue.svg";
import bigImage1 from "@/shared/ui/assets/images/theatre-landing/bigImage1.webp";
import bigImage2 from "@/shared/ui/assets/images/theatre-landing/bigImage2.webp";
import bigImage3 from "@/shared/ui/assets/images/theatre-landing/bigImage3.webp";
import bigImage4 from "@/shared/ui/assets/images/theatre-landing/bigImage4.webp";
import bigImageMobile1 from "@/shared/ui/assets/images/theatre-landing/bigImageMobile1.webp";
import bigImageMobile2 from "@/shared/ui/assets/images/theatre-landing/bigImageMobile2.webp";
import bigImageMobile3 from "@/shared/ui/assets/images/theatre-landing/bigImageMobile3.webp";
import bigImageMobile4 from "@/shared/ui/assets/images/theatre-landing/bigImageMobile4.webp";
import cover1 from "@/shared/ui/assets/images/theatre-landing/cover1.webp";
import cover2 from "@/shared/ui/assets/images/theatre-landing/cover2.webp";
import cover3 from "@/shared/ui/assets/images/theatre-landing/cover3.webp";
import cover4 from "@/shared/ui/assets/images/theatre-landing/cover4.webp";
import slide1 from "@/shared/ui/assets/images/theatre-landing/slide1.webp";
import slide2 from "@/shared/ui/assets/images/theatre-landing/slide2.webp";
import slide3 from "@/shared/ui/assets/images/theatre-landing/slide3.webp";
import slide4 from "@/shared/ui/assets/images/theatre-landing/slide4.webp";
import st2 from "@/shared/ui/assets/images/theatre-landing/st-2-desktop.webp";
import st1 from "@/shared/ui/assets/images/theatre-landing/st-3-desktop.webp";
import theatreImg from "@/shared/ui/assets/images/theatre-landing/st-4-desktop.webp";

import styles from "./theatre-landing.module.scss";

const getAnalyticsDataSlides = () => {
  return [
    {
      id: 1,
      course_id: "5ceffda1-514a-4ea8-ade7-8626517331d2",
      format_course: "online_course",
      course_name: "Режиссер в современном театре",
      link_text: "К курсу",
      link_url: "https://muse.edutoria.ru/courses/5ceffda1-514a-4ea8-ade7-8626517331d2",
    },
    {
      id: 2,
      course_id: "b3f94fc4-c4a0-4c3a-9b07-f377b134109e",
      format_course: "online_course",
      course_name: "Актерское мастерство",
      link_text: "К курсу",
      link_url: "https://muse.edutoria.ru/courses/b3f94fc4-c4a0-4c3a-9b07-f377b134109e",
    },
    {
      id: 3,
      course_id: "8633d016-1152-40d2-8e71-d6fc8ef1a8e7",
      format_course: "online_course",
      course_name: "Как делать современный театр",
      link_text: "К курсу",
      link_url: "https://muse.edutoria.ru/courses/8633d016-1152-40d2-8e71-d6fc8ef1a8e7",
    },
    {
      id: 4,
      course_id: "5084041f-8aea-4193-ace8-ddea98b98592",
      format_course: "online_course",
      course_name: "Стиль в современном театре",
      link_text: "К курсу",
      link_url: "https://muse.edutoria.ru/courses/5084041f-8aea-4193-ace8-ddea98b98592",
    },
  ];
};

const slides = [
  {
    id: 1,
    title: `Режиссер\nв современном\nтеатре`,
    subTitle: "Алексей Мартынов",
    img: slide1,
    url: "https://muse.edutoria.ru/courses/5ceffda1-514a-4ea8-ade7-8626517331d2",
  },
  {
    id: 2,
    title: `Актерское\nмастерство`,
    subTitle: "Сергей Карабань",
    img: slide2,
    url: "https://muse.edutoria.ru/courses/b3f94fc4-c4a0-4c3a-9b07-f377b134109e",
  },
  {
    id: 3,
    title: `Как делать\nсовременный\nтеатр`,
    subTitle: "Алиса Кретова",
    img: slide3,
    url: "https://muse.edutoria.ru/courses/8633d016-1152-40d2-8e71-d6fc8ef1a8e7",
  },
  {
    id: 4,
    title: `Стиль\nв современном\nтеатре`,
    subTitle: "Ясмина Омерович",
    img: slide4,
    url: "https://muse.edutoria.ru/courses/5084041f-8aea-4193-ace8-ddea98b98592",
  },
];

const SECTION_ID_BUY = "MODERN-THEATRE";

const scrollToBuyBlock = () => scrollToElement(SECTION_ID_BUY);

export const StartSection = () => {
  return (
    <>
      <Header.Mobile />
      <section className="bg-m-dark-blue h-[670px] md:h-[52vw] relative">
        <Header.Desktop
          subscribeButtonFixed={false}
          className="md:absolute top-0 left-0 bg-transparent w-full z-10"
        />
        <div className={clsx(styles.TheatreStartSection, "absolute inset-0")} />
        <div className="text-m-white uppercase font-bold absolute left-mn-20 md:top-auto md:left-mn-26 xl:left-mn-74 bottom-mn-150 md:bottom-[7vw] 3xl:left-mn-111">
          <Typography tag="h1" size="m-h1" className="mb-mn-10 md:mb-mn-20 3xl:mb-mn-30 uppercase">
            Я был вчера
            <br /> в театре
          </Typography>
          <Typography tag="p" size="m-h5" className="mb-mn-20 3xl:mb-mn-30">
            Специальный проект Музы
            <br />и Мастерской Брусникина
          </Typography>
          <ButtonRounded onClick={scrollToBuyBlock}>Оформить сертификат</ButtonRounded>
        </div>
        <div className="absolute right-mn-20 xl:right-mn-26 3xl:right-mn-38 bottom-[30px] md:bottom-[34px]">
          <Typography
            tag="span"
            size="m-caption"
            className="text-m-dark-grey text-right font-normal inline-block"
          >
            Фото: архив Мастерской Брусникина,
            <br className="block md:hidden" /> «Человек из Подольска Серёжа очень тупой»
          </Typography>
        </div>
      </section>
    </>
  );
};

export const TryForFreeBlock = () => {
  return (
    <section id={SECTION_ID_BUY} className="flex flex-col md:flex-row">
      <div className="bg-m-white h-[370px] md:h-auto pt-[25px] px-mn-20 md:py-mn-50 md:px-mn-30 3xl:pt-mn-74 3xl:px-[46px] uppercase font-bold w-full md:w-[65%]">
        <Typography tag="h3" size="m-h3" className="text-m-blue mb-mn-10 md:mb-mn-20 3xl:mb-mn-30">
          вдохновитесь на&nbsp;изучение искусства.
          <br />
          <span className="gradient-text">Вместе с Музой</span>
        </Typography>
        <Typography tag="p" size="m-h5" className="mb-mn-10 md:mb-mn-20 3xl:mb-mn-30 text-m-dark">
          Оформите сертификат и&nbsp;получите доступ
          <br className="hidden md:block" /> к&nbsp;4 авторским онлайн-курсам{" "}
          <span className="text-m-orange">на&nbsp;12&nbsp;месяцев</span>
        </Typography>

        <div className="mb-[36px] 3xl:mb-[54px] flex items-center text-[30px] md:text-[32px] xl:text-[40px] 3xl:text-[62px]">
          <span className="gradient-text pt-0">5990₽</span>
        </div>

        <ButtonRounded
          data-giftery-widget="20857"
          linkOptions={{
            to: "https://widget.giftery.cards/?productId=20857",
            target: "_blank",
            rel: "noreferrer",
          }}
          className="border-[2px] w-full md:w-auto md:text-[18px] py-[12px] 3xl:text-[27px] !h-auto 3xl:py-[20px] px-[60px]"
          variant="tertiary"
        >
          Купить сертификат
        </ButtonRounded>
      </div>

      <div className="w-full md:w-[40%]">
        <img
          src={theatreImg}
          className="object-cover w-full h-[51vh] md:h-[500px] 2xl:h-full 3xl:h-[750px]"
          alt=""
        />
      </div>

      <GifteryCode />
    </section>
  );
};

export const TheatrePage = () => {
  return (
    <>
      <SEO
        title="В театр с Мастерской Брусникина"
        description="Режиссура, актерское мастерство, театральный менеджмент — в авторских курсах онлайн-университета Муза"
        canonical={`${DOMAIN_SITE}${paths.theatre()}`}
      />
      <StartSection />
      <LandingBlock.SliderAndPromoText
        onArrowClick={() => analyticsEvents.theatrePage.viewCourseCompilation()}
        carouselClassName="!h-[556px] md:!h-auto"
        leftContainerClassName="md:items-start w-full h-full h-[370px] md:h-[556px] 3xl:h-[835px]"
        contentClassName="w-full h-full"
        content={
          <div className="h-full flex flex-col justify-end md:justify-between">
            <div>
              <p className="md:indent-[1em] mb-mn-20">
                Хотите стать актером,
                <br className="hidden md:block" /> разобраться в&nbsp;современном
                <br className="hidden md:block" /> театре или создать свой?
              </p>

              <Typography tag="p" size="m-h5">
                <span className="gradient-text">Четыре курса от&nbsp;Мастерской Брусникина</span>{" "}
                &mdash; <br className="hidden md:block" />
                чтобы почувствовать живое дыхание
                <br className="hidden md:block" /> молодого искусства и&nbsp;узнать о&nbsp;том, что
                такое
                <br className="hidden md:block" /> быть актером и&nbsp;режиссером сегодня
              </Typography>
            </div>

            <div className="hidden md:block">
              <div className="flex justify-end">
                <img src={arrowBlue} className="w-[50px] mr-mn-26 arrow-right-animation" alt="" />
                <ButtonRounded onClick={scrollToBuyBlock}>Оформить сертификат</ButtonRounded>
              </div>
            </div>
          </div>
        }
        slides={slides.map((slide) => {
          const slideAnalytics = getAnalyticsDataSlides().find((s) => s.id === slide.id);
          return (
            <LandingBlock.BlockSliderInfo
              buttonText="Смотреть курс"
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onClick={() => analyticsEvents.theatrePage.clickCourse(slideAnalytics!)}
              imageClassName="object-[68%] !h-[556px] md:!h-full"
              {...slide}
            />
          );
        })}
      />

      <LandingBlock.RhombusBlock
        classNameTopContainer="flex-col-reverse md:flex-row"
        topClassBackground={clsx(styles.Quote, "py-[160px]")}
        topRightDescriptionClasses="2xl:!pr-mn-74 relative"
        renderTopAuthor={
          <div className="w-full md:w-[65%] relative font-bold uppercase">
            <img
              className="w-full h-[347px] object-cover object-top md:object-cover md:h-full"
              src={st2}
              alt="Дмитрий Брусникин, актер и режиссер"
            />
          </div>
        }
        renderTopDescription={
          <div className="flex flex-col">
            <div className="text-[20px] md:text-[2.2vw] indent-[1em] uppercase font-bold font-m-sb-display leading-[100%]">
              <h3>
                Возникновение театра&nbsp;&mdash; это&nbsp;какая-то необходимость.
                <br />
                Например, когда возникал театр &laquo;Современник&raquo;, в&nbsp;этом была
                необходимость. Просто было необходимо, чтобы возник театр поколения. Мне кажется,
                настало время, когда необходимо, чтобы возник театр этого поколения.
              </h3>
              <h3 className="mt-[58px]">Станет ли этим театром наша мастерская?</h3>
              <h3 className="indent-[1em]">Я бы очень этого хотел.</h3>
            </div>
            <span className="gradient-text uppercase font-m-sb-display text-right left-mn-26 leading-[100%] text-m-base md:text-[1.2vw] font-bold absolute -bottom-[50px] right-[20px] md:bottom-[2vw] md:right-[2vw]">
              Дмитрий Брусникин, <br className="block md:hidden" /> мастер, актер и режиссер
            </span>
          </div>
        }
        renderBottomDescription={
          <p className="font-m-sb-display">
            Мастерская Брусникина&nbsp;&mdash; независимый театр, созданный в&nbsp;2015 году
            на&nbsp;основе курса Школы-студии МХАТ под руководством Дмитрия Брусникина.
            Многообещающий актёрский коллектив, с&nbsp;которым работают едва&nbsp;ли не&nbsp;все
            главные режиссёры современного отечественного театра, выступает на&nbsp;разных
            площадках: в&nbsp;театре &laquo;Практика&raquo;, Центре имени Вс. Мейерхольда,
            Театральном центре &laquo;На&nbsp;Страстном&raquo;, Боярских палатах, ARTPLAY...
          </p>
        }
        renderBottomAuthor={
          <div className="w-full hidden md:block md:w-[65.2%] relative">
            <img className="object-cover w-full h-full" src={st1} alt="Мастерская Брусникина" />
            <Typography
              tag="span"
              size="m-caption"
              className="text-m-grey absolute bottom-mn-26 right-mn-26"
            >
              Фото: архив Мастерской Брусникина, «Чапаев и Пустота»
            </Typography>
          </div>
        }
      />
      <LandingBlock.BigPreviewImage
        img={{ desktop: bigImage1, mobile: bigImageMobile1 }}
        title={`Режиссер\nв современном\nтеатре`}
        subTitle="Алексей Мартынов"
        buttonText="Оформить сертификат"
        onClick={() => {
          analyticsEvents.theatrePage.clickCourse(getAnalyticsDataSlides()[0]);
          scrollToBuyBlock();
        }}
      />
      <LandingBlock.ContentInformationWithImage
        onArrowClick={() => analyticsEvents.theatrePage.clickCourse(getAnalyticsDataSlides()[0])}
        img={cover1}
        title={
          <>
            <span className="text-m-arctic">Алексей Мартынов</span> — режиссер и&nbsp;актер
            Мастерской Брусникина. Среди его режиссерских работ спектакли Lorem Ipsum и&nbsp;Shoot /
            Get treasure / Repeat
          </>
        }
        description={
          <>
            <p className="mb-[20px]">
              В&nbsp;современном театре режисс&euml;ру отведена роль капитана корабля. Именно
              он&nbsp;определяет, каким будет спектакль,&nbsp;&mdash; его тему, художественную форму
              и&nbsp;творческий замысел. Тем не&nbsp;менее пройти путь от&nbsp;идеи
              до&nbsp;её&nbsp;реализации невозможно в&nbsp;одиночку&nbsp;&mdash; без людей,
              объедин&euml;нных общими взглядами.
            </p>
            <p>
              Где найти единомышленников, которые помогут вам воплощать самые смелые идеи? Почему
              режисс&euml;р всегда должен проверять свою концепцию на&nbsp;прочность? Как
              не&nbsp;бояться ошибаться, анализировать процесс со&nbsp;стороны и&nbsp;всегда
              двигаться вперёд к&nbsp;воплощению замысла? О&nbsp;возможностях реализации идеи
              и&nbsp;пошаговом процессе создания спектакля расскажет{" "}
              <span className="gradient-text">актёр и&nbsp;режиссёр Алексей Мартынов</span>.
            </p>
          </>
        }
      />

      <LandingBlock.BigPreviewImage
        img={{ desktop: bigImage2, mobile: bigImageMobile2 }}
        title={`Актерское\nмастерство`}
        subTitle="Сергей Карабань"
        buttonText="Оформить сертификат"
        onClick={() => {
          analyticsEvents.theatrePage.clickCourse(getAnalyticsDataSlides()[1]);
          scrollToBuyBlock();
        }}
      />
      <LandingBlock.ContentInformationWithImage
        onArrowClick={() => analyticsEvents.theatrePage.clickCourse(getAnalyticsDataSlides()[1])}
        img={cover2}
        title={
          <>
            <span className="text-m-arctic">Сергей Карабань</span> — режиссер и&nbsp;актер
            Мастерской Брусникина, педагог Школы-Студии МХАТ, мастер Gogol School
          </>
        }
        description={
          <>
            <p className="mb-[20px]">
              &laquo;Не&nbsp;верю&raquo;&nbsp;&mdash; возможно, одна из&nbsp;самых известных цитат
              Константина Сергеевича Станиславского, знакомая даже людям, никогда не&nbsp;ходившим
              в&nbsp;театр. Станиславский стал основоположником не&nbsp;только российского театра,
              но&nbsp;и&nbsp;целой системы работы актёров, которой уже более 100 лет пользуются
              во&nbsp;всём мире.
            </p>
            <p>
              Сегодня навыки актёрского мастерства необходимы не&nbsp;только на&nbsp;сцене,
              но&nbsp;и&nbsp;в&nbsp;повседневной жизни. Умение убеждать и&nbsp;доносить свои мысли,
              способность наблюдать за&nbsp;происходящим и&nbsp;анализировать обстоятельства,
              контролировать свои эмоции, владеть телом и&nbsp;речью&nbsp;&mdash; эти и&nbsp;другие
              техники самосовершенствования в&nbsp;фокусе{" "}
              <span className="gradient-text"> авторского курса Сергея Карабаня</span>.
            </p>
          </>
        }
      />

      <LandingBlock.BigPreviewImage
        img={{ desktop: bigImage3, mobile: bigImageMobile3 }}
        title={`Как делать\nсовременный\nтеатр`}
        subTitle="Алиса Кретова"
        buttonText="Оформить сертификат"
        onClick={() => {
          analyticsEvents.theatrePage.clickCourse(getAnalyticsDataSlides()[2]);
          scrollToBuyBlock();
        }}
      />

      <LandingBlock.ContentInformationWithImage
        onArrowClick={() => analyticsEvents.theatrePage.clickCourse(getAnalyticsDataSlides()[2])}
        img={cover3}
        title={
          <>
            <span className="text-m-arctic">Алиса Кретова</span> — актриса Мастерской Брусникина,
            режиссер перформансов и&nbsp;мероприятий в Новой Третьяковке, ГЭС-2 и&nbsp;Музее Москвы
          </>
        }
        description={
          <>
            <p className="mb-[20px]">
              Современный театр всё чаще выходит за&nbsp;рамки привычного сценического пространства.
              Можно поставить &laquo;Три сестры&raquo; Чехова на&nbsp;театральной сцене, имитирующей
              усадьбу начала XX&nbsp;века, а&nbsp;можно поместить героев в&nbsp;реальную квартиру
              и&nbsp;впустить туда зрителей. Текст пьесы будет тот&nbsp;же, но&nbsp;эмоции
              и&nbsp;впечатления&nbsp;&mdash; совершенно разные. Вариантов, где может быть показан
              спектакль, множество: заброшенное здание, бизнес-центр, улица, лес, бассейн, ваш
              собственный дом&nbsp;&mdash; вс&euml; зависит от&nbsp;фантазии, воображения
              и&nbsp;целей режисс&euml;ра.
            </p>
            <p>
              Где может существовать современный театр, почему важно мыслить шире сцены и&nbsp;как
              искать нестандартные локации для спектакля&nbsp;
              <span className="gradient-text">рассказывает Алиса Кретова</span>.
            </p>
          </>
        }
      />

      <LandingBlock.BigPreviewImage
        img={{ desktop: bigImage4, mobile: bigImageMobile4 }}
        title={`Стиль\nв современном\nтеатре`}
        subTitle="Ясмина Омерович"
        buttonText="Оформить сертификат"
        onClick={() => {
          analyticsEvents.theatrePage.clickCourse(getAnalyticsDataSlides()[3]);
          scrollToBuyBlock();
        }}
      />

      <LandingBlock.ContentInformationWithImage
        onArrowClick={() => analyticsEvents.theatrePage.clickCourse(getAnalyticsDataSlides()[3])}
        img={cover4}
        title={
          <>
            <span className="text-m-arctic">Ясмина Омерович</span> — актриса, художник Мастерской
            Брусникина. Дебютировала в&nbsp;качестве художника спектакля «Хорошо, что я такой»,
            выступила куратором фестиваля Brus Help Fest 2022
          </>
        }
        description={
          <>
            <p className="mb-[20px]">
              Сегодня театральный художник&nbsp;&mdash; гораздо больше, чем просто декоратор.
              Он&nbsp;становится творцом, автором пластической режиссуры. В&nbsp;его арсенале есть
              множество инструментов: от&nbsp;звуковых партитур и&nbsp;графического контента
              до&nbsp;технологий дополненной и&nbsp;виртуальной реальности. В&nbsp;зависимости
              от&nbsp;замысла режисс&euml;ра и&nbsp;необходимого зрительского опыта он&nbsp;выбирает
              медиумы и&nbsp;созда&euml;т &laquo;сценарий&raquo; их&nbsp;использования&nbsp;&mdash;
              с&nbsp;хронометражем по&nbsp;каждой сцене.
            </p>
            <p>
              Художник и&nbsp;актриса Мастерской Брусникина
              <span className="gradient-text"> Ясмина Омерович делится своим опытом </span>
              и&nbsp;объясняет, как выбрать стилистические решения, кто проектирует опыт зрителя
              и&nbsp;что может стать источником вдохновения для сценографа.
            </p>
          </>
        }
      />
      <TryForFreeBlock />
      <Footer />
    </>
  );
};
