import { useEvent, useStore } from "effector-react/scope";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { $courseName } from "@/pages/course-view/model";
import {
  feedbackModal,
  FeedbackForm,
  feedbackSent,
} from "@/pages/course-view/modules/course-lesson-test/model/certificate-issueing";

import { StarRating, StarRatingProps } from "@/entities/feedback/ui/star-rating";

import { ButtonRounded, Modal, Typography } from "@/shared/ui";

import styles from "./feedback-form.module.scss";

const Form = () => {
  const courseName = useStore($courseName);
  const send = useEvent(feedbackSent);

  const {
    register,
    control,
    getValues,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm<FeedbackForm>({
    mode: "all",
    defaultValues: {
      rating: 0,
      commentText: "",
    },
  });

  const onSubmit = (data: FeedbackForm) => {
    send(data);
  };

  return (
    <div className="flex flex-col justify-center align-center text-center text-m-white">
      <Modal.Title>{courseName}</Modal.Title>
      <Modal.Description className="mb-mn-10">Оцените курс</Modal.Description>

      <Controller
        control={control}
        name="rating"
        rules={{
          min: {
            value: 1,
            message: "Поставьте оценку, чтобы оставить отзыв",
          },
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <StarRating
            ref={ref}
            className={styles.starRatingRoot}
            value={value as StarRatingProps["value"]}
            onStarClick={(v) => {
              onChange(v);
              onBlur();
              trigger("commentText");
            }}
            starClassName={styles.starRatingItem}
          />
        )}
      />

      <textarea
        {...register("commentText", {
          maxLength: {
            value: 500,
            message: "Максимум 500 символов",
          },
          validate: {
            requiredIfRatingLessThanThree(value) {
              if (getValues().rating >= 3) return true;
              if (value.trim().length > 0) return true;
              return "Поле обязательное для заполнения";
            },
          },
        })}
        className="h-[76px] py-[15px] px-[10px] bg-white m-typography-caption placeholder:m-typography-caption placeholder:text-m-dark-grey text-m-dark mb-[4px]"
        placeholder="Расскажите, как прошло ваше обучение"
      />
      <div className="w-full text-left mb-[20px] h-[15px]">
        {errors.commentText && (
          <Typography
            tag="p"
            size="m-caption"
            className="font-m-sb-text font-normal text-m-magenta"
          >
            {errors.commentText.message}
          </Typography>
        )}
      </div>

      <div className="pb-mn-16 3xl:pb-mn-20">
        <ButtonRounded
          variant="secondary"
          disabled={!isValid}
          className="font-semibold w-auto !bg-transparent"
          onClick={handleSubmit(onSubmit)}
        >
          ОСТАВИТЬ ОТЗЫВ
        </ButtonRounded>
      </div>
      <Typography
        tag="p"
        size="m-caption"
        className="font-m-sb-text font-normal pb-[24px] text-m-dark-grey"
      >
        Нажимая кнопку, я соглашаюсь
        <br /> с{" "}
        <a
          href="https://assets.edutoria.ru/documents/feedback_policy.pdf"
          target="_blank"
          className="underline"
          rel="noreferrer noopener"
        >
          правилами публикации отзывов
        </a>
      </Typography>
    </div>
  );
};

export const FeedbackFormModal = () => {
  const isOpenModal = useStore(feedbackModal.$isOpen);
  const close = useEvent(feedbackModal.close);

  return (
    <Modal
      isOpen={isOpenModal}
      close={close}
      closeOnClickOverlay={false}
      render={() => (
        <Modal.Surface>
          <Modal.Header close={close} />
          <Modal.Body>
            <Form />
          </Modal.Body>
        </Modal.Surface>
      )}
    />
  );
};
