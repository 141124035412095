import { sample } from "effector";

import { analyticsEvents } from "@/shared/analytics";

import { signInUserFx, submittedSignInForm, $signInError } from "./model/sign-in";

sample({
  clock: submittedSignInForm,
  target: [analyticsEvents.loginMail, analyticsEvents.loginPsw],
});

sample({
  clock: signInUserFx.done,
  fn: () => ({ method: "email" }),
  target: analyticsEvents.login,
});

sample({
  clock: signInUserFx.failData,
  source: $signInError,
  fn: (error) => ({ link_text: error }),
  target: analyticsEvents.errorFormLogin,
});
