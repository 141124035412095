import { useEvent } from "effector-react/scope";
import { memo, useState } from "react";

import { Course, CourseFormatEnum } from "@app/api";

import { Modal } from "@/shared/ui";

import { resetCourseToShare, setCourseToShare } from "../model";
import { CourseShareButton } from "./course-share-button";
import { CourseShareModal } from "./course-share-modal";

type CoursePayload = {
  id: string;
  title: string;
  courseFormat: CourseFormatEnum;
};

type CourseShareProps = {
  buttonClassName?: string;
  course: Course | CoursePayload;
};

export const CourseShare = memo(({ buttonClassName, course }: CourseShareProps) => {
  const [showModal, setShowModal] = useState(false);
  const setCourse = useEvent(setCourseToShare);
  const resetCourse = useEvent(resetCourseToShare);

  const handleShareButtonClick = () => {
    setCourse({
      courseId: course.id,
      courseName: course.title,
      format_course: course.courseFormat,
    });

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    resetCourse();
  };

  return (
    <>
      <CourseShareButton onClick={handleShareButtonClick} className={buttonClassName} />
      <Modal isOpen={showModal} close={handleCloseModal} render={CourseShareModal} />
    </>
  );
});
