import { format } from "date-fns";
import { ru } from "date-fns/locale";

const FULL_DATE_FORMAT = "d MMMM yyyy";

export const formatDate = (date?: string | number | Date, dateSchema: string = FULL_DATE_FORMAT) =>
  date
    ? format(new Date(date), dateSchema, {
        locale: ru,
      })
    : "";
