import { createAnalyticsEvent } from "../../model/analytics";

export enum FavoritesEvents {
  MARK = "add_to_wishlist",
  UNMARK = "remove_from_wishlist",
  OPEN_PAGE = "click_wishlist",
}

export const toggle = createAnalyticsEvent<{
  courseId: string;
  title: string;
  courseFormat: string | null;
  event_name: FavoritesEvents;
}>(({ courseId, courseFormat, title, event_name }) => ({
  event: "autoEvent",
  event_name,
  course_id: courseId,
  format_course: courseFormat,
  course_name: title,
}));

export const pageOpened = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: FavoritesEvents.OPEN_PAGE,
}));
