import { ComponentProps } from "react";

import loadable from "@loadable/component";

import { Skeleton } from "@/shared/ui";

export const PaymentWidget = loadable(
  () => import("./ui/payment-widget").then((component) => component.PaymentWidget),
  {
    ssr: false,
    fallback: <Skeleton.BankCard />,
  },
);

export const PaymentWidgetModalLayout = loadable(
  () =>
    import("./ui/payment-widget-modal-layout").then(
      (component) => component.PaymentWidgetModalLayout,
    ),
  {
    ssr: false,
  },
);

export { PaymentStatus } from "./ui/payment-status";
export { createPaymentTransactions } from "./model/create-payment-transactions";

export type PaymentProcessParam = ComponentProps<typeof PaymentWidget>["processParams"];
