export enum PromocodeStatus {
  APPROVED = "approved",
  ALREADY_ACTIVATED = "already_activated",
  EXPIRED = "cancelled",
  DOES_NOT_EXIST = "not_exist",
  UNKNOWN = "unknown",
  PROMOCODE_IS_ALREADY_USED = "Promocode_is_already_used",
  PROMOCODE_NOT_FOUND = "Promocode_not_found",
  PROMOCODE_IS_EXPIRED = "Promocode_is_expired",
}
