import { createObjectWithoutUndefined } from "@/shared/lib/object";

import { createAnalyticsEvent } from "../model/analytics";

export enum CommonEvents {
  SCROLL = "scroll",
  CLICK_COURSE = "click_course",
  CLICK_MENU = "click_menu",
  CLICK_COURSE_CATALOG = "click_course_catalog",
  VIEW_COURSE_SLIDER = "view_course_slider",
  CLICK_FILTER_TOPIC = "click_filter_topic",
  VIEW_PROMO_PREVIEW = "view_promo_preview",
}

export type CourseAnalyticsInfo = {
  course_id: string;
  course_name: string;
  link_text: string;
  link_url: string;
  price: number;
  format_course: string;
  link_type?: string;
  recommendationReference?: string;
};

export const scroll = createAnalyticsEvent<{ depth: number }>(({ depth }) => ({
  event: "autoEvent",
  event_name: CommonEvents.SCROLL,
  depth,
}));

export const clickCourse = createAnalyticsEvent<CourseAnalyticsInfo>(
  ({
    course_id,
    course_name,
    price,
    link_text,
    link_url,
    format_course,
    recommendationReference,
  }) => {
    const payload = createObjectWithoutUndefined({
      event: "autoEvent",
      event_name: CommonEvents.CLICK_COURSE,
      course_id,
      course_name,
      price,
      link_text,
      link_url,
      format_course,
      meta: recommendationReference,
    });

    return payload;
  },
);

export const clickMenu = createAnalyticsEvent<{
  link_type: string;
  link_text?: string;
}>(({ link_type, link_text = "Попробовать бесплатно" }) => ({
  event: "autoEvent",
  event_name: CommonEvents.CLICK_MENU,
  link_text,
  link_type,
}));

export const clickCourseCatalog = createAnalyticsEvent<{
  link_url: string;
  link_text: string;
  link_type: string;
}>(({ link_url, link_text, link_type }) => ({
  event: "autoEvent",
  event_name: CommonEvents.CLICK_COURSE_CATALOG,
  link_type,
  link_url,
  link_text,
}));

export const viewCourseSlider = createAnalyticsEvent<{ link_type: string }>(({ link_type }) => ({
  event: "autoEvent",
  event_name: CommonEvents.VIEW_COURSE_SLIDER,
  link_type,
}));

export const clickFilterTopic = createAnalyticsEvent<{ topic: string }>(({ topic }) => ({
  event: "autoEvent",
  event_name: CommonEvents.CLICK_FILTER_TOPIC,
  link_type: "top",
  topic,
}));

export const viewCoursePreview = createAnalyticsEvent<{ depth: number }>(({ depth }) => ({
  event: "autoEvent",
  event_name: CommonEvents.VIEW_PROMO_PREVIEW,
  depth,
}));
