import clsx from "clsx";
import { ReactNode } from "react";

type LockLayoutProps = {
  variant?: "solid" | "gradient";
  children?: string | ReactNode;
};

export const LockLayout = ({ variant = "solid", children = "" }: LockLayoutProps) => {
  const bgClasses = clsx(
    "absolute top-0 left-0 w-full h-full flex items-center justify-center flex-col",
    {
      "bg-m-white-80": variant === "solid",
      "bg-m-lock-gradient": variant === "gradient",
    },
  );
  return (
    <div className="relative w-full h-full overflow-hidden">
      <div className={bgClasses}>{children}</div>
    </div>
  );
};
