import { useStore, useEvent } from "effector-react/scope";
import { HTMLAttributes, useState } from "react";

import { copyTextToClipboard } from "@/shared/lib/to-clipboard/to-clipboard";
import { motion, AnimationContainer, Modal } from "@/shared/ui";
import { ReactComponent as LinkIcon } from "@/shared/ui/assets/images/socials/share-link.svg";
import { ReactComponent as OkIcon } from "@/shared/ui/assets/images/socials/share-ok.svg";
import { ReactComponent as TgIcon } from "@/shared/ui/assets/images/socials/share-tg.svg";
import { ReactComponent as VkIcon } from "@/shared/ui/assets/images/socials/share-vk.svg";

import { shareCourseClicked } from "../analytics";
import { $courseToShare } from "../model";

type CourseShareModalProps = {
  close: () => void;
};

const LinkButton = ({ children, ...props }: HTMLAttributes<HTMLButtonElement>) => (
  <button
    className="button-init border-solid border-[1px] border-m-white bg-m-white-10 rounded-full w-[30px] h-[30px] flex items-center justify-center active:border-m-orange xl:hover:border-m-orange transition-colors"
    {...props}
  >
    {children}
  </button>
);

export const CourseShareModal = ({ close }: CourseShareModalProps) => {
  const [showCopyMsg, setShowCopyMsg] = useState(false);
  const courseToShare = useStore($courseToShare);
  const analyticsEvents = useEvent({ shareCourseClicked });

  const handleCopyToClipboard = async () => {
    await copyTextToClipboard(`${window.location.origin}${window.location.pathname}`);
    if (showCopyMsg) return;

    setShowCopyMsg(true);
    setTimeout(() => setShowCopyMsg(false), 3000);

    if (!courseToShare) return;

    analyticsEvents.shareCourseClicked({ ...courseToShare, link_text: "Скопировать ссылку" });
  };

  const handleShare = (link: string, service: string) => {
    if (courseToShare) analyticsEvents.shareCourseClicked({ ...courseToShare, link_text: service });
    window.open(link, "_blank");
  };

  return (
    <Modal.Surface data-fullscreen="false" className="min-h-auto">
      <Modal.Header close={close} />
      <Modal.Body className="box-border min-h-[140px] md:min-h-[175px] w-full">
        <div className="flex flex-col items-center">
          <Modal.Title className="mb-mv-5">Поделиться</Modal.Title>
          <ul className="flex w-min">
            <li>
              <LinkButton
                onClick={() => {
                  handleShare(
                    `https://vk.com/share.php?url=${window.location.origin}${window.location.pathname}`,
                    "ВКонтакте",
                  );
                }}
              >
                <VkIcon />
              </LinkButton>
            </li>
            <li className="ml-mv-3">
              <LinkButton
                onClick={() => {
                  handleShare(
                    `https://t.me/share/url?url=${window.location.origin}${window.location.pathname}`,
                    "Телеграм",
                  );
                }}
              >
                <TgIcon />
              </LinkButton>
            </li>
            <li className="ml-mv-3">
              <LinkButton
                onClick={() => {
                  handleShare(
                    `https://connect.ok.ru/offer?url=${window.location.origin}${window.location.pathname}`,
                    "Одноклассники",
                  );
                }}
              >
                <OkIcon />
              </LinkButton>
            </li>
            <li className="ml-mv-3">
              <LinkButton onClick={handleCopyToClipboard}>
                <LinkIcon />
              </LinkButton>
            </li>
          </ul>
          <AnimationContainer>
            {showCopyMsg && (
              <motion.span
                initial={{
                  opacity: 0,
                  scale: 0.85,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    ease: "easeOut",
                    duration: 0.2,
                  },
                }}
                exit={{
                  opacity: 0,
                  scale: 0.75,
                  transition: {
                    ease: "easeIn",
                    duration: 0.15,
                  },
                }}
                className="text-m-dark-grey text-m-sm bg-m-dark-blue px-mv-2 py-[6.5px] mt-[18px] rounded-lg"
              >
                Ссылка скопирована в буфер
              </motion.span>
            )}
          </AnimationContainer>
        </div>
      </Modal.Body>
    </Modal.Surface>
  );
};
