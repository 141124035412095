import Pino, { LoggerOptions, pino } from "pino";

import { CONFIG } from "@/shared/config";

import { Request } from "./types";

let serverLogger: Pino.Logger | null = null;

const loggerConfig: LoggerOptions = {
  transport: {
    target: "pino-pretty",
    options: {
      translateTime: "SYS:dd-mm-yyyy HH:MM:ss",
      ignore: "pid,hostname",
    },
  },
};

const initialLogger = () => {
  if (CONFIG.BUILD_ON_SERVER) {
    serverLogger = pino(loggerConfig);
  }

  return serverLogger;
};

export const logger = initialLogger();

export const HttpLogger = () => {
  return {
    error(error: Error | unknown, req?: Request) {
      if (CONFIG.LOGGER === "off") return;

      const message = `[${req?.method}] | FAIL -→ ${req?.path}`;
      const obj = { response: error, request: req };

      logger?.error(obj, message);
    },

    info(res: Request) {
      if (CONFIG.LOGGER === "off") return;

      const message = `[${res.method}] | OK -→ ${res.path}`;

      logger?.info({}, message);
    },
  };
};
