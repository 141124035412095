import { useStore } from "effector-react/scope";
import React from "react";

import { ButtonStartSubscription } from "@/widgets/payment";

import { LockLayout } from "@/shared/ui";

import { $hasCourseAccess } from "../../../model";

export const ContentLockLayout = () => {
  const hasCourseAccess = useStore($hasCourseAccess);

  if (hasCourseAccess) return null;

  return (
    <div className="absolute w-full h-full top-0 left-0 pt-8">
      <LockLayout variant="gradient">
        <h2 className="text-m-dark text-m-base xl:text-m-xl 3xl:text-m-3xl mb-mv-3 3xl:mb-mv-4 text-center">
          Для просмотра
          <br /> оформите подписку или купите курс
        </h2>
        <ButtonStartSubscription analytics={{ positionInfo: "middle" }} />
      </LockLayout>
    </div>
  );
};
