import clsx from "clsx";
import React, { memo } from "react";

import { Tab } from "@/shared/ui";

type TabContent = {
  title: string;
  name: string;
  onClick?: () => void;
  hideTab?: boolean;
};

type TabItem = {
  tab: TabContent;
  isActive: boolean;
};

type ContentTabsProps = {
  tabs: TabContent[];
  activeTab: string;
  className?: string;
};

const TabItem = memo(({ tab, isActive }: TabItem) => {
  const { title, onClick, hideTab } = tab;

  const handleTabClick = () => {
    onClick?.();
  };

  if (hideTab) return null;

  return (
    <Tab isActive={isActive} onClick={handleTabClick}>
      <span>{title}</span>
    </Tab>
  );
});

export const ContentTabs = memo(({ tabs, activeTab, className }: ContentTabsProps) => {
  return (
    <div className={clsx("flex border-b-[1px] border-m-border", className)}>
      {tabs.map((tab) => (
        <TabItem key={tab.name} tab={tab} isActive={activeTab === tab.name} />
      ))}
    </div>
  );
});
