import clsx from "clsx";
import { KeenSliderInstance } from "keen-slider";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import React, { ReactNode, useEffect } from "react";

const TIMEOUT_DELAY_AUTOPLAY = 5000;

const runAutoplay = (slider: KeenSliderInstance) => {
  let timeout: ReturnType<typeof setTimeout>;

  function clearNextTimeout() {
    clearTimeout(timeout);
  }

  function nextTimeout() {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      slider.next();
    }, TIMEOUT_DELAY_AUTOPLAY);
  }

  slider.on("created", () => {
    nextTimeout();
  });
  slider.on("dragStarted", clearNextTimeout);
  slider.on("animationEnded", nextTimeout);
  slider.on("updated", nextTimeout);
};

type CarouselProps<T> = {
  data: T[];
  slideClassName?: string;
  getInstanceRef?: (instance: KeenSliderInstance | null) => void;
  onMountSlider?: (status: boolean) => void;
  getActiveSlideIndex?: (index: number) => void;
  children?: ReactNode;
  containerClassName?: string;
  enablePlugins?: ["autoplay"];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapPlugins = (plugins: CarouselProps<any>["enablePlugins"]) => {
  const map = {
    autoplay: runAutoplay,
  };

  return plugins?.map((plugin) => map[plugin]);
};

export const BaseCarousel = <T,>({
  data,
  slideClassName,
  getInstanceRef,
  onMountSlider,
  getActiveSlideIndex,
  children,
  containerClassName,
  enablePlugins,
}: CarouselProps<T>) => {
  const plugins = mapPlugins(enablePlugins);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      loop: true,
      defaultAnimation: {
        duration: 1000,
      },
      destroyed() {
        onMountSlider?.(false);
      },
      created() {
        onMountSlider?.(true);
      },
      slideChanged(slide) {
        getActiveSlideIndex?.(slide.track.details.rel);
      },
    },
    plugins,
  );

  useEffect(() => {
    getInstanceRef?.(instanceRef.current);
    return () => {
      getInstanceRef?.(null);
    };
  }, [instanceRef]);

  return (
    <div ref={sliderRef} className={clsx("keen-slider", containerClassName)}>
      {data.map((slide, index) => (
        <div key={`slide-${index}`} className={clsx(slideClassName, "keen-slider__slide")}>
          {slide}
        </div>
      ))}
      {children}
    </div>
  );
};
