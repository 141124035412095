import { sample } from "effector";

import { analyticsEvents } from "@/shared/analytics";

import { tabCourse } from "../../model";

const tabNames = {
  about: "О курсе",
  materials: "Учебные материалы",
  tasks: "Задания",
};

sample({
  clock: tabCourse.tabChanged,
  fn: (tab) => ({ link_text: tabNames[tab] }),
  target: analyticsEvents.clickMenuCourse,
});
