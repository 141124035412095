import { LessonApi } from "@/shared/api";
import { AttributeType } from "@/shared/api/modules/lesson";
import { secondsToHms } from "@/shared/lib/date";

import { Lesson, LessonsContent, StatusTest, Test, FullTest } from "./types";

type Content = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
};

export const getWebinarBlockData = (
  webinarAttributes: LessonApi.Attribute[],
): LessonApi.IFrameBlock | LessonApi.WebinarBlock | null => {
  if (webinarAttributes.length > 1) {
    const iframe = webinarAttributes.find((block) => block.type === AttributeType.IFRAME);
    return (iframe as LessonApi.IFrameBlock) ?? null;
  }

  const webinar = webinarAttributes.find((block) => block.type === AttributeType.WEBINAR);
  return (webinar as LessonApi.WebinarBlock) ?? null;
};

export const getLessonWebinars = <T extends Content>(
  contentData: T,
): LessonApi.Attribute[] | null => {
  const WEBINAR_ORDER = 3;
  const lessons = contentData.content.course.course_tree.lessons ?? [];
  const lesson = lessons.find((les: Lesson) => les.order === WEBINAR_ORDER);

  if (!lesson) return null;

  const lessonId = lesson.id;
  const lessonsContent = contentData.content.course.lessons_content ?? [];
  const lessonContent =
    lessonsContent.find((les: LessonsContent<LessonApi.Attribute>) => les.lesson_id === lessonId) ??
    null;

  if (!lessonContent) return null;

  return lessonContent.lesson_attributes;
};

export const getLessonMaterials = <T extends Content>(
  contentData: T,
): LessonApi.Attribute[] | null => {
  const MATERIALS_ORDER = 2;
  const lessons = contentData.content.course.course_tree.lessons ?? [];
  const lesson = lessons.find((les: Lesson) => les.order === MATERIALS_ORDER);

  if (!lesson) return null;

  const lessonId = lesson.id;
  const lessonsContent = contentData.content.course.lessons_content ?? [];
  const lessonContent =
    lessonsContent.find((les: LessonsContent<LessonApi.Attribute>) => les.lesson_id === lessonId) ??
    null;

  if (!lessonContent) return null;

  return lessonContent.lesson_attributes;
};

export const getLessonTests = <T extends Content>(contentData: T): FullTest[] => {
  const TEST_ORDER = 0;
  const lessons = contentData.content.course.course_tree.lessons ?? [];
  const lesson = lessons[TEST_ORDER];

  if (!lesson) return [];

  const lessonId = lesson.id;
  const lessonsContent = contentData.content.course.lessons_content ?? [];
  const lessonContent =
    lessonsContent.find((content: LessonsContent<Test>) => {
      return content.lesson_id === lessonId;
    }) ?? null;

  if (!lessonContent) return [];

  return lessonContent.lesson_attributes
    .filter((attr: Lesson) => attr.type === LessonApi.AttributeType.TEST)
    .map((attr: Lesson) => {
      return {
        ...attr,
        status: StatusTest.Default,
      };
    });
};

export const getLessonVideo = <T extends Content>(contentData: T): LessonApi.LessonVideo[] => {
  const VIDEO_ORDER = 2;

  const lessonVideos: LessonsContent<LessonApi.Attribute>[] =
    contentData.content.course.lessons_content ?? [];

  if (!lessonVideos.length) return [];

  const videoData = lessonVideos.reduce<LessonsContent<LessonApi.Attribute>[]>((res, content) => {
    const videoContent = content.lesson_attributes.find((attr) => attr.type === "video");
    return videoContent ? [...res, { ...content, lesson_attributes: [videoContent] }] : res;
  }, []) as LessonsContent<LessonApi.VideoBlock>[];

  const lessonData = contentData.content.course.course_tree.lessons.slice(VIDEO_ORDER) as Lesson[];
  const mappedLessonData = lessonData.reduce<{ [index: string]: Lesson }>((res, lesson) => {
    res[lesson.id] = lesson;
    return res;
  }, {});

  return videoData.map((videoLesson) => {
    const currentLesson = mappedLessonData[videoLesson.lesson_id] ?? {};
    return {
      id: videoLesson.lesson_id,
      url: videoLesson.lesson_attributes[0].data.url,
      title: currentLesson.name,
      duration: secondsToHms(currentLesson.duration),
    };
  });
};
