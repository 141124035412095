import {
  attach,
  createEffect,
  createEvent,
  Effect,
  Event,
  EventPayload,
  sample,
  Store,
} from "effector";
import { condition } from "patronum";

import {
  $authenticatedUserId,
  $hasAuthenticatedUserId,
  getUserFx,
  loggedOut,
} from "@/entities/user";

import { CourseApi } from "@/shared/api";
import { createFavoritesToggler } from "@/shared/lib/effector/fabrics/favorites-toggler";
import { retryFlow } from "@/shared/lib/effector/fabrics/retry-flow";

import { openSignUpModal } from "@/feature/sign-up";

interface ToggleFavoritePayload {
  id: string;
  isToggled: boolean;
}

export const getFavoriteCoursesIdFx = attach({
  source: $authenticatedUserId,
  effect: async (userId) => {
    if (!userId) return [];

    const result = await CourseApi.getFavoriteCoursesIds();
    return result;
  },
});
export const markCourseAsFavoriteFx = createEffect(CourseApi.markCourseAsFavorite);
export const unmarkCourseAsFavoriteFx = createEffect(CourseApi.unmarkCourseAsFavorite);

export const fetchFavorites = createEvent();
export const toggledCourseFavorite = createEvent<ToggleFavoritePayload>();

export const { toggledFavorite: toggleFavorite, $favoriteIds: $favoriteCoursesIds } =
  createFavoritesToggler({
    set: getFavoriteCoursesIdFx,
    mark: markCourseAsFavoriteFx,
    unmark: unmarkCourseAsFavoriteFx,
    reset: loggedOut,
  });

sample({
  clock: getUserFx,
  target: fetchFavorites,
});

sample({
  clock: fetchFavorites,
  filter: $hasAuthenticatedUserId,
  target: getFavoriteCoursesIdFx,
});

const courseFavoriteFlow = retryFlow({
  source: toggledCourseFavorite,
  filter: $hasAuthenticatedUserId,
  interrupted: openSignUpModal.prepend(() => false),
});

sample({
  source: loggedOut,
  target: courseFavoriteFlow.reset,
});

sample({
  source: courseFavoriteFlow.complete,
  target: toggleFavorite,
});

const setFavoriteAfterAuth = sample({
  clock: getFavoriteCoursesIdFx.done,
  source: toggledCourseFavorite,
  filter: courseFavoriteFlow.$interrupted,
});

sample({
  clock: setFavoriteAfterAuth,
  source: $favoriteCoursesIds,
  filter: (ids, { id }) => !ids.includes(id),
  fn: (_, payload) => payload,
  target: toggleFavorite,
});

sample({
  source: toggledCourseFavorite,
  target: courseFavoriteFlow.start,
});

interface CreateFavoritesToggler<Id> {
  $favouriteIds: Store<Id[]>;
  mark: Effect<Id, Id>;
  unmark: Effect<Id, Id>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reset: Event<any>;
}

export const makeFavoritesToggler = <Id>({
  $favouriteIds: $store,
  mark,
  unmark,
  reset,
}: CreateFavoritesToggler<Id>) => {
  const toggledFavorite = createEvent<Id>();

  const courseFavouriteToggled = sample({
    clock: toggledFavorite,
    source: $store,
    fn: (favouriteIds, clickedId) => ({
      id: clickedId,
      isToggledOn: !favouriteIds.includes(clickedId),
    }),
  });

  $store
    .on(courseFavouriteToggled, (ids, { id, isToggledOn }) =>
      isToggledOn ? ids.concat(id) : ids.filter((i) => i !== id),
    )
    .on(mark.doneData, (currentFavoriteIds, newId) => [...currentFavoriteIds, newId])
    .on(unmark.doneData, (currentFavoriteIds, newId) =>
      currentFavoriteIds.filter((id) => id !== newId),
    )
    .reset(reset);

  condition({
    source: courseFavouriteToggled,
    if: ({ isToggledOn }) => !isToggledOn,
    then: unmark.prepend<EventPayload<typeof courseFavouriteToggled>>((data) => data.id),
    else: mark.prepend<EventPayload<typeof courseFavouriteToggled>>((data) => data.id),
  });

  return {
    toggledFavorite,
  };
};
