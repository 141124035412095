import { createEvent, sample } from "effector";

import { FormSubmitPayload, sendFormDataToMindboxFx } from "@/shared/api/modules/mindbox";

export const subscriptionFormSubmitted = createEvent<FormSubmitPayload>();

sample({
  clock: subscriptionFormSubmitted,
  target: sendFormDataToMindboxFx("muse.main_page.b2b"),
});
