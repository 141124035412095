import { useEvent } from "effector-react/scope";

import { paths } from "@/pages/paths";

import { historyPush } from "@/entities/navigation";

import { ButtonRounded } from "@/shared/ui";

export const FutureCertificateInfo = () => {
  const push = useEvent(historyPush);

  return (
    <div className="w-full md:w-1/2 xl:w-1/3 h-[318px] flex items-center justify-center flex-col bg-m-blue">
      <span className="text-m-white uppercase font-m-sb-display font-semibold text-m-sm 3xl:text-m-base text-center mb-mv-2 3xl:mb-mv-3">
        Здесь будет ваш
        <br />
        новый сертификат
      </span>
      <ButtonRounded variant="secondary" onClick={() => push(paths.catalog())}>
        К курсам
      </ButtonRounded>
    </div>
  );
};
