import fetch from "isomorphic-fetch";

import { CONFIG } from "../../../config";
import { queryToString } from "./lib";
import { HttpLogger } from "./logger";
import { Request } from "./types";

export async function requestServer<Response = unknown>({
  path,
  method,
  baseUrl = CONFIG.SERVER_REST_API_URL,
  ...options
}: Request) {
  const headers = new Headers({
    ...options.headers,
    cookie: combineCookies(options.headers?.cookie, options.cookies),
  });

  if (CONFIG.REST_API_AUTH) {
    headers.set("authorization", CONFIG.REST_API_AUTH);
  }
  contentSetDefault(headers, "application/json; charset=utf-8");

  const query = queryToString(options.query);
  const body =
    contentIs(headers, "application/json") && options.body
      ? JSON.stringify(options.body)
      : undefined;

  const URL = `${baseUrl}${path}${query}`;

  try {
    const response = await fetch(URL, {
      method,
      headers,
      body,
    });

    const answer = contentIs(response.headers, "application/json")
      ? await response.json()
      : await response.text();

    const responder = {
      ok: response.ok,
      body: answer as Response,
      status: response.status,
      headers: Object.fromEntries(response.headers.entries()),
    };

    if (response.ok) {
      HttpLogger()?.info({ method, path: URL });
      return responder;
    }

    throw responder;
  } catch (error) {
    HttpLogger()?.error(error, { path: URL, method, ...options });
    return {
      ok: false,
      body: error,
      status: 900,
      headers: {},
    };
  }
}

function combineCookies(...cookies: Array<string | undefined>): string {
  return cookies.filter(Boolean).join("; ");
}

/**
 * Check if content-type JSON
 */
function contentIs(headers: Headers, type: string): boolean {
  return headers.get("content-type")?.includes(type) ?? false;
}

function contentSetDefault(headers: Headers, type: string): Headers {
  if (!headers.has("content-type")) {
    headers.set("content-type", type);
  }
  return headers;
}
