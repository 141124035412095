import { useEvent, useStore } from "effector-react/scope";

import { Modal } from "@/shared/ui";

import {
  $paymentChooseModal,
  paymentModalCloseClicked,
  PaymentModalType,
} from "../model/payment-management";
import { PaymentModalRenderer } from "./payment-modal-renderer";

export const PaymentModal = () => {
  const paymentModal = useStore($paymentChooseModal);
  const paymentModalCloseClickedEvent = useEvent(paymentModalCloseClicked);

  return (
    <Modal
      allTouchedMove
      isOpen={paymentModal !== PaymentModalType.Close}
      close={() => paymentModalCloseClickedEvent()}
      render={() => <PaymentModalRenderer />}
    />
  );
};
