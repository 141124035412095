import { sample } from "effector";

import { analyticsEvents } from "@/shared/analytics";

import { passwordChangeFx } from "./model/password-change";
import {
  clickedForgotPassword,
  confirmCodePassword,
  resetPasswordFx,
} from "./model/password-forgot";
import { resetNewPasswordSubmitted } from "./model/password-reset";

sample({
  clock: clickedForgotPassword,
  target: analyticsEvents.forgotPsw,
});

sample({
  clock: resetPasswordFx.done,
  target: analyticsEvents.resetPsw,
});

sample({
  clock: resetNewPasswordSubmitted,
  target: analyticsEvents.changePsw,
});

sample({
  clock: passwordChangeFx.doneData,
  target: analyticsEvents.enterEmailPsw,
});

sample({
  clock: passwordChangeFx.fail,
  fn: () => ({ link_text: "Неверный код" }),
  target: analyticsEvents.error,
});

sample({
  clock: resetPasswordFx.fail,
  fn: () => ({ link_text: "Пользователь не найден. Повторите попытку" }),
  target: analyticsEvents.error,
});

sample({
  clock: confirmCodePassword.requestFx.fail,
  fn: () => ({ link_text: "Неверный код. Повторите попытку" }),
  target: analyticsEvents.error,
});

sample({
  clock: confirmCodePassword.confirmCodeSubmitted,
  target: analyticsEvents.clickRepeatMailPsw,
});
