import { createEvent, createStore, sample } from "effector";
import { useEvent } from "effector-react/scope";
import { reset } from "patronum/reset";

import { AmazemeMetadata } from "@app/api";

import { analyticsEvents } from "@/shared/analytics";
import type { CourseAnalyticsInfo } from "@/shared/analytics/events";

const resetAnalytics = createEvent();

export const productCardAnalyticsInitialized = createEvent<{ meta?: Nullable<AmazemeMetadata> }>();

const $recommendationDetails = createStore<Nullable<AmazemeMetadata>>(null).on(
  productCardAnalyticsInitialized.map((init) => init.meta),
  (_, meta) => meta,
);

export const cardClicked = createEvent<{
  courseId: string;
  courseName: string;
  formatCourse: string;
  price: number;
  linkText: string;
  linkUrl: string;
}>();

reset({
  clock: resetAnalytics,
  target: [$recommendationDetails],
});

sample({
  clock: cardClicked,
  source: $recommendationDetails,
  fn: (recommendationDetails, courseInfo) => {
    const payloadClick: CourseAnalyticsInfo = {
      course_id: courseInfo.courseId,
      course_name: courseInfo.courseName,
      format_course: courseInfo.formatCourse,
      price: courseInfo.price,
      link_text: courseInfo.linkText,
      link_url: courseInfo.linkUrl,
    };

    if (recommendationDetails) {
      payloadClick.recommendationReference = recommendationDetails.reference;
    }

    return payloadClick;
  },
  target: analyticsEvents.clickCourse,
});

sample({
  clock: analyticsEvents.clickCourse,
  target: resetAnalytics,
});

export const useCourseCardAnalytics = () => {
  return {
    cardClicked: useEvent(cardClicked),
  };
};
