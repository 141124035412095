import React, { ReactNode } from "react";

import { Breakpoint } from "@/shared/lib/responsive";
import { Container, Image, Typography } from "@/shared/ui";
import desktop1 from "@/shared/ui/assets/images/adults-landing/student-works/desktop/students-1.webp";
import desktop2 from "@/shared/ui/assets/images/adults-landing/student-works/desktop/students-2.webp";
import desktop3 from "@/shared/ui/assets/images/adults-landing/student-works/desktop/students-3.webp";
import desktop4 from "@/shared/ui/assets/images/adults-landing/student-works/desktop/students-4.webp";
import desktop5 from "@/shared/ui/assets/images/adults-landing/student-works/desktop/students-5.webp";
import mobile1 from "@/shared/ui/assets/images/adults-landing/student-works/mobile/muse-s-1.webp";
import mobile2 from "@/shared/ui/assets/images/adults-landing/student-works/mobile/muse-s-2.webp";
import mobile3 from "@/shared/ui/assets/images/adults-landing/student-works/mobile/muse-s-3.webp";
import mobile4 from "@/shared/ui/assets/images/adults-landing/student-works/mobile/muse-s-4.webp";
import mobile5 from "@/shared/ui/assets/images/adults-landing/student-works/mobile/muse-s-5.webp";
import tablet1 from "@/shared/ui/assets/images/adults-landing/student-works/tablet/muse-s-1.webp";
import tablet2 from "@/shared/ui/assets/images/adults-landing/student-works/tablet/muse-s-2.webp";
import tablet3 from "@/shared/ui/assets/images/adults-landing/student-works/tablet/muse-s-3.webp";
import tablet4 from "@/shared/ui/assets/images/adults-landing/student-works/tablet/muse-s-4.webp";
import tablet5 from "@/shared/ui/assets/images/adults-landing/student-works/tablet/muse-s-5.webp";

import { useCatalogPageAnalytics } from "../../analytics";
import { CarouselPhotos } from "./carousel-photos";

type PhotoProps = {
  src: {
    mobile: string;
    tablet: string;
    desktop: string;
  };
  label?: ReactNode;
  title?: ReactNode;
};

const photos: PhotoProps[] = [
  {
    src: {
      mobile: mobile1,
      tablet: tablet1,
      desktop: desktop1,
    },
    label: (
      <>
        Александра Никипелова, <br />
        Styl-O Label. New perspective
      </>
    ),
    title: <>программа «ЦИФРОВОЕ ИСКУССТВО»</>,
  },
  {
    src: {
      mobile: mobile2,
      tablet: tablet2,
      desktop: desktop2,
    },
    label: (
      <>
        Александра Никипелова, <br />
        Styl-O Label. New perspective
      </>
    ),
    title: <>программа «ЦИФРОВОЕ ИСКУССТВО»</>,
  },
  {
    src: {
      mobile: mobile3,
      tablet: tablet3,
      desktop: desktop3,
    },
    label: (
      <>
        Daria Vetrova,
        <br />
        Fantasy VS Technology
      </>
    ),
    title: <>программа «ЦИФРОВОЕ ИСКУССТВО»</>,
  },
  {
    src: {
      mobile: mobile4,
      tablet: tablet4,
      desktop: desktop4,
    },
    label: (
      <>
        Daria Vetrova,
        <br />
        Fantasy VS Technology
      </>
    ),
    title: <>программа «ЦИФРОВОЕ ИСКУССТВО»</>,
  },
  {
    src: {
      mobile: mobile5,
      tablet: tablet5,
      desktop: desktop5,
    },
    label: (
      <>
        Daria Vetrova,
        <br />
        Fantasy VS Technology
      </>
    ),
    title: <>программа «ЦИФРОВОЕ ИСКУССТВО»</>,
  },
];

const Photo = ({ src, label, title }: PhotoProps) => {
  return (
    <div className="relative">
      <Image
        screens={[
          {
            breakpoint: Breakpoint.S,
            src: src.mobile,
          },
          {
            breakpoint: Breakpoint.L,
            src: src.tablet,
          },
          {
            breakpoint: Breakpoint.XL,
            src: src.desktop,
          },
        ]}
        lazy
        src={src.desktop}
        className="h-full object-cover w-full"
        alt=""
      />
      <div className="absolute left-[20px] md:left-[26px] xl:left-[32px] 3xl:left-[48px] bottom-mn-26 3xl:bottom-[44px]">
        <Typography
          tag="span"
          size="m-caption"
          className="text-m-white font-normal normal-case inline-block"
        >
          {label}
        </Typography>

        <Typography
          tag="h5"
          size="m-h5"
          className="text-m-white uppercase font-semibold font-m-sb-display mt-mn-10 md:mt-mn-20"
        >
          {title}
        </Typography>
      </div>
    </div>
  );
};

export const StudentWork = () => {
  const catalogAnalytics = useCatalogPageAnalytics();

  return (
    <section className="bg-m-dark-blue">
      <Container className="md:py-mn-50 3xl:py-[75px] px-0" mode="fluid-fixed">
        <div className="flex w-full items-center flex-col-reverse xl:flex-row">
          <div className="flex py-mn-38 md:py-mn-50 px-mn-20 md:px-mn-26 xl:pr-0 3xl:pl-mn-38 items-start flex-col w-full">
            <div className="">
              <Typography
                className="mb-mn-20 3xl:mb-mn-30 uppercase font-semibold font-m-sb-display"
                tag="h3"
                size="m-h3"
              >
                Сообщество
                <br />
                творческих
                <br />
                профессионалов
              </Typography>
              <Typography tag="p" size="m-text" className="xl:w-[40vw] 3xl:w-[31vw]">
                Мы&nbsp;создаем образовательную среду, в&nbsp;которой творческие люди могут
                развиваться в&nbsp;любимом направлении под руководством лучших практиков
                современного искусства и&nbsp;технологий
              </Typography>
            </div>
          </div>

          <CarouselPhotos
            onButtonNextClick={() => catalogAnalytics.sliderClicked("Работы выпускников")}
            onButtonPrevClick={() => catalogAnalytics.sliderClicked("Работы выпускников")}
            photos={photos.map(({ src, label, title }) => (
              <Photo src={src} label={label} title={title} />
            ))}
          />
        </div>
      </Container>
    </section>
  );
};
