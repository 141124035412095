import { useStore } from "effector-react/scope";

import { Course, UserProduct } from "@app/api";

import { $boughtCourses, $userSubscription } from "../model/user-product";
import { isEdPlusSubscription, isMuseSubscription } from "./user-subscription";

export type CourseAvailablePayload = {
  course?: Course;
  userSubscription: UserProduct | null;
  boughtCourses: UserProduct[];
};

type IsCourseAvailable = (payload: CourseAvailablePayload) => boolean;

export const isCoursePurchased = (boughtCourses: UserProduct[], course: Course) =>
  boughtCourses.some((boughtCourse) => boughtCourse.id === course.id);

export const isCourseAvailable: IsCourseAvailable = (payload) => {
  const { course, boughtCourses, userSubscription } = payload;

  if (!course) return false;

  if (isMuseSubscription(userSubscription)) return true;

  if (isEdPlusSubscription(userSubscription)) {
    if (isCoursePurchased(boughtCourses, course)) return true;

    return course.includedInSberEd;
  }

  return isCoursePurchased(boughtCourses, course);
};

export const useCourseAccess = (course?: Course) => {
  const boughtCourses = useStore($boughtCourses);
  const userSubscription = useStore($userSubscription);

  return isCourseAvailable({ course, boughtCourses, userSubscription });
};
