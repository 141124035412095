import clsx from "clsx";
import { useState } from "react";



import { LessonVideo } from "@/entities/lesson";



import { useDevices } from "@/shared/lib/responsive";
import { scrollToElement, scrollToTop } from "@/shared/lib/scroll";
import { ReactComponent as ChevronIcon } from "@/shared/ui/assets/images/icons/chevron-down.svg";
import { ReactComponent as DoneIcon } from "@/shared/ui/assets/images/icons/done.svg";
import { ReactComponent as LockIcon } from "@/shared/ui/assets/images/icons/lock-icon.svg";


export type VideoListItem = {
  title: string;
  id: string;
  duration: string;
};

type VideoListProps = {
  videos: LessonVideo[];
  currentVideo: LessonVideo;
  hasAccess: boolean;
  onSelectVideo: (id: string) => void;
  viewed: {
    [lessonId: string]: boolean;
  };
};

export const VIDEO_PLAYER = "VIDEO_PLAYER";

export const VideoList = ({
  videos,
  currentVideo,
  onSelectVideo,
  viewed,
  hasAccess,
}: VideoListProps) => {
  const [expand, setExpand] = useState(false);
  const { isDesktop } = useDevices();

  const currentLessonId = currentVideo?.id || "";

  const handleChangeVideo = (id: string) => {
    if (!isDesktop) scrollToTop();
    onSelectVideo(id);
  };

  return (
    <>
      <ul
        className={clsx(
          "xl:max-h-[352px] 2xl:max-h-[480px] xl:pr-m-5 overflow-hidden xl:overflow-y-auto xl:scrollbar",
          { "max-h-[352px]": !expand },
        )}
      >
        {videos &&
          videos.map(({ title, id, duration }, index) => (
            <li key={id}>
              <button
                className={clsx(
                  "video-list-item h-[44px] 2xl:h-[60px] px-mv-4 whitespace-nowrap flex items-center w-full text-white hover:text-m-orange text-m-xs 2xl:text-m-base",
                  { "bg-m-white-10": id === currentLessonId },
                )}
                onClick={() => {
                  handleChangeVideo(id);
                }}
                title={title}
              >
                <div className="video-viewed flex items-center mr-4 min-w-[13px]">
                  {(hasAccess || index === 0) && viewed[id] && <DoneIcon />}
                  {!hasAccess && index > 0 && <LockIcon />}
                </div>
                <span className="overflow-hidden text-ellipsis text-left grow">{title}</span>
                <span className="video-duration text-m-dark-grey ml-4 grow-0">{duration}</span>
              </button>
            </li>
          ))}
      </ul>
      {!isDesktop && (
        <div className="h-[44px] w-full mt-mv-2">
          {videos && videos?.length > 8 && (
            <button
              className="absolute bottom-0 left-0 w-screen h-[44px] button-init bg-m-white-10"
              title="Раскрыть список"
              onClick={() => {
                setExpand(!expand);
                if (expand) scrollToElement(VIDEO_PLAYER, { behavior: "instant" as ScrollBehavior });
              }}
            >
              <ChevronIcon
                className={clsx("mx-auto w-[20px] h-[10px]", {
                  "transform rotate-180": expand,
                })}
              />
            </button>
          )}
        </div>
      )}
    </>
  );
};
