import React, { useState } from "react";
import { Swiper as SwiperClass } from "swiper/types";

import { CardSlider, SliderControls, Title } from "@/shared/ui";
import { SliderOptions } from "@/shared/ui/components/slider";

type Props = {
  cards: JSX.Element[];
  title?: string;
  options?: SliderOptions;
  cardClassName?: string;
};

export function CarouselCourse({ cards, title, options, cardClassName }: Props) {
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);

  const onNext = () => {
    swiperInstance?.slideNext();
  };

  const onPrev = () => {
    swiperInstance?.slidePrev();
  };
  return (
    <>
      <div className="flex justify-between mb-[38px] items-end">
        <Title text={title} />
        <SliderControls
          onSlideNext={onNext}
          onSlidePrev={onPrev}
          classNameContainer="gap-mv-3 mr-10"
          controlsClass=" bg-transparent border border-m-white child:text-m-white hover:border-m-orange !h-[30px] !w-[30px]"
          // controlsClass="bg-transparent border border-m-white child:text-m-white hover:border-m-orange"
        />
      </div>
      <CardSlider
        getSwiperInstance={setSwiperInstance}
        cardsData={cards}
        cardClassName={cardClassName}
        options={options}
      />
    </>
  );
}
