import { createEvent, createStore, sample } from "effector";

import { UserProduct } from "@app/api";

import { userPurchasesModel } from "@/entities/user-purchases";

import { createModalState } from "@/shared/lib/effector";

type SubscriptionId = string;

export const cancelSubscriptionSelected = createEvent<UserProduct>();

export const $selectedCancelSubscription = createStore<UserProduct | null>(null)
  .on(cancelSubscriptionSelected, (_, subscription) => subscription)
  .reset(userPurchasesModel.cancelSubscriptionFx.finally);

export const confirmCanceledSelectedSubscription = createEvent();
export const userRestoredSubscription = createEvent<SubscriptionId>();

export const thinkAboutButtonClicked = createEvent();

export const cancelSubscriptionModal = createModalState({ defaultState: false });

sample({
  clock: cancelSubscriptionSelected,
  target: cancelSubscriptionModal.open,
});

sample({
  clock: confirmCanceledSelectedSubscription,
  source: $selectedCancelSubscription,
  filter: Boolean,
  fn: (selectedSubscription) => selectedSubscription.id,
  target: userPurchasesModel.cancelSubscriptionFx,
});

sample({
  clock: userRestoredSubscription,
  target: userPurchasesModel.restoreSubscriptionFx,
});

sample({
  clock: userPurchasesModel.cancelSubscriptionFx.done,
  target: cancelSubscriptionModal.close,
});

sample({
  clock: thinkAboutButtonClicked,
  target: cancelSubscriptionModal.close,
});
