import { attach, createEvent, createStore, sample } from "effector";

import { logout, LogoutMethod } from "@/entities/user";

import { AuthApi } from "@/shared/api";
import { confirmError } from "@/shared/api/constants";

import { openAuthModal } from "@/app/authorization-modals/model";
import { createConfirmCodeModel } from "@/feature/confirm-code-modal";
import { createCountdownTimer } from "@/feature/countdown-button";

export const deleteStudentProfileAttempted = createEvent();

const deleteUserFx = attach({ effect: AuthApi.deleteUser });

sample({
  source: deleteUserFx.doneData,
  target: openAuthModal.deleteUserConfirmCode,
});

const $deleteProfilePayload = createStore<Nullable<AuthApi.RequestConfirmCodeData>>(null).on(
  deleteUserFx.doneData.map((payload) => ({
    timeout: payload.timeout,
    token: payload.token,
  })),
  (_, payload) => payload,
);

export const confirmCodeDeleteProfile = createConfirmCodeModel({
  source: $deleteProfilePayload,
  sendFx: AuthApi.deleteUserConfirmCode,
  reset: [openAuthModal.close],
});

export const countdownTimerDeleteProfile = createCountdownTimer({});

sample({
  clock: confirmCodeDeleteProfile.requestFx.done,
  fn: () => ({ method: LogoutMethod.FORCE }),
  target: [openAuthModal.close, logout],
});

sample({
  clock: confirmCodeDeleteProfile.requestFx.failData,
  filter: ({ type }) => !confirmError[type],
  target: openAuthModal.deleteUserConfirmCodeError,
});

sample({
  clock: [deleteStudentProfileAttempted, confirmCodeDeleteProfile.sendAgain],
  target: deleteUserFx,
});

sample({
  source: deleteUserFx.doneData,
  fn: ({ timeout }) => timeout,
  target: countdownTimerDeleteProfile.startCountdown,
});
