import { createAnalyticsEvent } from "@/shared/analytics/model/analytics";

const enum AuthEventName {
  OPEN_FORM_LOGIN = "open_form_login",
  OPEN_FORM_SIGN_UP = "open_form_sign_up",
  SIGN_UP_MAIL = "sign_up_mail",
  SIGN_UP_PSW = "sign_up_psw",
  ENTER_MAIL_PSW = "enter_mail_psw",
  LOGIN_MAIL = "login_mail",
  LOGIN_PSW = "login_psw",
  CLICK_REPEAT_MAIL_PSW = "click_repeat_mail_psw",
  CLICK_REPEAT_PSW_ERROR = "click_mail_psw_error",
  LOGIN = "login",
  SIGN_UP = "sign_up",
  ERROR_FORM_SIGN_UP = "error_form_sign_up",
  ERROR_FORM_LOGIN = "error_form_login",
  FORGOT_PSW = "forgot_psw",
  RESET_PSW = "reset_psw",
  CHANGE_PSW = "change_psw",
  CLICK_PROFILE_BUTTON = "click_profile_button",
  LOGOUT = "logout",
  OPEN_FEEDBACK = "open_feedback",
  ERROR = "error",
}

export const openFormLogin = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.OPEN_FORM_LOGIN,
}));

export const openFormSignUp = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.OPEN_FORM_SIGN_UP,
}));

export const signUpMail = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.SIGN_UP_MAIL,
}));

export const signUpPsw = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.SIGN_UP_PSW,
}));

export const enterEmailPsw = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.ENTER_MAIL_PSW,
}));

export const loginMail = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.LOGIN_MAIL,
}));

export const loginPsw = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.LOGIN_PSW,
}));

export const clickRepeatMailPsw = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.CLICK_REPEAT_MAIL_PSW,
}));

export const clickMailPswError = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.CLICK_REPEAT_PSW_ERROR,
}));

export const login = createAnalyticsEvent<{ method: string }>(({ method }) => ({
  event: "autoEvent",
  event_name: AuthEventName.LOGIN,
  method,
}));

export const signUp = createAnalyticsEvent<{ method: string; link_text: string }>(
  ({ method, link_text }) => ({
    event: "autoEvent",
    event_name: AuthEventName.SIGN_UP,
    method,
    link_text,
  }),
);

export const errorFormSignUp = createAnalyticsEvent<{ link_text: string }>(({ link_text }) => ({
  event: "autoEvent",
  event_name: AuthEventName.ERROR_FORM_SIGN_UP,
  link_text,
}));

export const errorFormLogin = createAnalyticsEvent<{ link_text: string }>(({ link_text }) => ({
  event: "autoEvent",
  event_name: AuthEventName.ERROR_FORM_LOGIN,
  link_text,
}));

export const forgotPsw = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.FORGOT_PSW,
}));

export const resetPsw = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.RESET_PSW,
}));

export const changePsw = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.CHANGE_PSW,
}));

export const clickProfileButton = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.CLICK_PROFILE_BUTTON,
}));

export const logout = createAnalyticsEvent<{ method: string }>(({ method }) => ({
  event: "autoEvent",
  event_name: AuthEventName.LOGOUT,
  method,
}));

export const openFeedback = createAnalyticsEvent(() => ({
  event: "autoEvent",
  event_name: AuthEventName.OPEN_FEEDBACK,
}));

export const error = createAnalyticsEvent<{ link_text: string }>(({ link_text }) => ({
  event: "autoEvent",
  event_name: AuthEventName.ERROR,
  link_text,
}));
