import { useEvent, useStore } from "effector-react/scope";
import { FC } from "react";

import { DifficultyCheck } from "@/entities/user";

import { DifficultyCaptions } from "@/shared/lib/form/lib";
import { removeSpaces } from "@/shared/lib/string";
import { ButtonRounded, Input, Modal } from "@/shared/ui";

import { authModalCloseClicked } from "@/app/authorization-modals/model";

import {
  $isResetPasswordIncorrect,
  $resetPasswordDifficulty,
  $resetPasswordValue,
  $resetPasswordError,
  resetNewPasswordSubmitted,
  resetPasswordValueChanged,
} from "../model/password-reset";

export const PasswordReset: FC = () => {
  const resetPasswordValue = useStore($resetPasswordValue);
  const resetPasswordDifficulty = useStore($resetPasswordDifficulty);
  const isResetPasswordIncorrect = useStore($isResetPasswordIncorrect);
  const error = useStore($resetPasswordError);
  const handlers = useEvent({
    resetNewPasswordSubmitted,
    resetPasswordValueChanged,
    close: authModalCloseClicked,
  });

  return (
    <Modal.Surface>
      <Modal.Header close={handlers.close} />
      <Modal.Body>
        <Input.Password
          value={resetPasswordValue}
          onChange={(e) => handlers.resetPasswordValueChanged(removeSpaces(e.target.value))}
          isError={isResetPasswordIncorrect}
          placeholder="Придумайте пароль"
          hasShowButton
          size="small"
          maxLength={50}
        />
        <Input.Description
          isError={isResetPasswordIncorrect}
          color="secondary"
          className="m-typography-modal-caption"
        >
          Пароль должен содержать не менее 8 символов, буквы латинского алфавита (a–z и A–Z), цифры
          (0–9). Не используйте пробел в пароле.
        </Input.Description>
        <DifficultyCheck
          difficulty={resetPasswordDifficulty}
          difficultyCaptions={[
            DifficultyCaptions.DEFAULT,
            DifficultyCaptions.LOW,
            DifficultyCaptions.MEDIUM,
            DifficultyCaptions.HIGH,
          ]}
          description={
            resetPasswordDifficulty === 0 ? "Проверка надежности пароля" : "Надежность пароля — "
          }
        />
        {Boolean(error) && (
          <Input.Description className="m-typography-modal-caption" isError>
            {error}
          </Input.Description>
        )}
        <ButtonRounded
          className="flex-1 mt-7 block m-auto"
          disabled={!resetPasswordValue || isResetPasswordIncorrect}
          onClick={handlers.resetNewPasswordSubmitted}
        >
          Обновить пароль
        </ButtonRounded>
      </Modal.Body>
    </Modal.Surface>
  );
};
