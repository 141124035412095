/* eslint-disable effector/enforce-effect-naming-convention */
import { createEffect } from "effector";

import { CommonError } from "@/shared/api";
import { createAuthRequestFx } from "@/shared/api/client/http/base";
import { CONFIG } from "@/shared/config";

import { apiInstance } from "../../bff";
import { createGqlEffect } from "../../client";

export const getUser = createGqlEffect(apiInstance.user.getUser);

export const getUserCertificates = createGqlEffect(apiInstance.user.getUserCertificates);

export const getUserPaymentMethod = createGqlEffect(apiInstance.user.getUserPaymentMethod);

export const getUserCards = createGqlEffect(apiInstance.user.getUserCards);

export const deleteUserCard = createGqlEffect(apiInstance.user.deleteUserCard);

export const updateUser = createGqlEffect(apiInstance.user.updateUser);

export const getUserAvatar = createGqlEffect(apiInstance.user.getUserAvatar);

export const updateUserAvatar = createGqlEffect(apiInstance.user.updateUserAvatar);

export const deleteUserAvatar = createGqlEffect(apiInstance.user.deleteUserAvatar);

export const uploadAvatar = createGqlEffect(apiInstance.user.uploadAvatar);

type DisplayNamesPayload = string[];
type DisplayNamesResult = Record<string, string>;

export const getUsersDisplayNames = createEffect<
  DisplayNamesPayload,
  DisplayNamesResult,
  CommonError
>({
  async handler(data) {
    const response = await createAuthRequestFx<DisplayNamesResult>(
      `/${CONFIG.AUTH_API_VERSION_V3}/user/display-name`,
    )({
      method: "POST",
      body: { users_ids: data },
    });

    return response.body;
  },
});
