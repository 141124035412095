import { useEvent, useStore } from "effector-react/scope";

import { useIsUserAuthenticated } from "@/entities/user";

import { Avatar } from "@/shared/ui/components/avatar/avatar";

import { useUserProfilePictureOrDefault } from "@/feature/student-profile-form";

import { $isOpen, avatarClicked } from "../model/profile-menu-toggle";

export const AuthUserAvatar = () => {
  const handlers = useEvent({ avatarClicked });
  const isMenuOpen = useStore($isOpen);
  const { userProfilePicture } = useUserProfilePictureOrDefault();
  const isUserAuthenticated = useIsUserAuthenticated();

  return (
    <Avatar
      hasUser={isUserAuthenticated}
      onClick={() => handlers.avatarClicked()}
      avatarUrl={isUserAuthenticated ? userProfilePicture : ""}
      btnClassName={`${isMenuOpen && "active:pointer-events-none"}`}
    />
  );
};
