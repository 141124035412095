import { createEvent, sample } from "effector";

import { sendFormDataToMindboxFx } from "@/shared/api/modules/mindbox";

import { FeedBackFormState } from "@/feature/feedback-form/lib/types";

export const subscriptionFormSubmitted = createEvent<FeedBackFormState>();

sample({
  clock: subscriptionFormSubmitted,
  target: sendFormDataToMindboxFx("muse.edu.all"),
});
