import clsx from "clsx";
import React, { ReactNode } from "react";

import { useCatalogPageAnalytics } from "../../analytics";
import styles from "./styles.module.scss";

type Props = {
  socialLinkClassName: string;
  iconBlockClassName: string;
  title?: ReactNode;
  isDark?: boolean;
};

export function ContactsLink({ socialLinkClassName, iconBlockClassName, title, isDark }: Props) {
  const catalogAnalytics = useCatalogPageAnalytics();

  return (
    <div className={socialLinkClassName}>
      {title && <div>{title}</div>}
      <div className={iconBlockClassName}>
        <a
          onClick={() => catalogAnalytics.socialLinkOnFormClicked("telegram")}
          href="https://t.me/Muse_dpo"
          className={clsx(styles.TelegramIcon, {
            [styles.TelegramIconDark]: isDark,
          })}
          target="_blank"
          rel="noreferrer"
        />
        <a
          onClick={() => catalogAnalytics.socialLinkOnFormClicked("whatsapp")}
          href="https://wa.me/message/AKASUR6YE77WN1"
          className={clsx(styles.WhatsAppIcon, {
            [styles.WhatsAppIconDark]: isDark,
          })}
          target="_blank"
          rel="noreferrer"
        />
      </div>
    </div>
  );
}
