const colors = {
  "m-border": "#c6c6c6",
  "m-dark": "#374151",
  "m-semi-dark": "#1F1C4F",
  "m-blue": "#272264",
  "m-blue-710": "#1D2773",
  "m-dark-blue": "#121939",
  "m-blue-80": "rgba(39, 34, 100, 0.8);",
  "m-dark-blue-80": "rgba(27 ,26, 70, 0.8)",
  "m-orange": "#FF8000",
  "m-orange-80": "rgba(255, 128, 0, 0.8)",
  "m-orange-25": "rgba(255, 128, 0, 0.25)",
  "m-dark-orange": "#E36002",
  "m-magenta": "#FF3C8A",
  "m-magenta-80": "rgba(255, 60, 138, 0.8)",
  "m-dark-magenta": "#B40057",
  "m-transparent": "rgba(255, 255, 2555, 0)",
  "m-white": "#FFFFFF",
  "m-white-10": "rgba(255, 255, 255, 0.1)",
  "m-white-40": "rgba(255, 255, 255, 0.41)",
  "m-white-80": "rgba(255, 255, 255, 0.8)",
  "m-white-90": "rgba(255, 255, 255, 0.9)",
  "m-black": "#000000",
  "m-grey": "#EAEAF0",
  "m-dark-grey": "#A1A1AA",
  "m-green": "#CBECE0",
  "m-arctic": "#42E3B4",
};

const themeColor = {
  "m-error": colors["m-magenta"],
  "m-success": colors["m-arctic"],
  "m-warning": colors["m-orange"],
  "m-incorrect": "#FDDBEB",
  "m-secondary": "#f4f4f5",
  "m-dropdown": "#363361",
  "m-popup": "rgba(0, 0, 0, 0.6)",
};

const linearBorderGradients = {
  directions: {
    tr: "to top right",
    r: "to right",
    b: "to bottom",
  },
  colors: {
    "m-orange-gradient": [colors["m-orange"], colors["m-magenta"]],
    "m-orange-reversed": [colors["m-magenta"], colors["m-orange"]],
  },
  background: {
    ...colors,
    "btn-secondary": "rgba(255, 255, 255, 0.1)",
  },
  border: {
    1: "1px",
    2: "2px",
    3: "3px",
    4: "4px",
  },
};

const backgroundImage = {
  "m-gradient-magenta-orange": `linear-gradient(44.65deg, ${colors["m-magenta"]} 0%, ${colors["m-orange"]} 50.94%, ${colors["m-magenta"]} 105.94%)`,
  "m-gradient": `linear-gradient(273.68deg, ${colors["m-orange"]} 30.82%, ${colors["m-magenta"]} 105.94%)`,
  "m-gradient-hover": `linear-gradient(44.65deg, ${colors["m-orange"]} 33.05%, ${colors["m-magenta"]} 97.28%)`,
  "m-gradient-80": `linear-gradient(273.68deg, ${colors["m-orange-80"]} 30.82%, ${colors["m-magenta-80"]} 105.94%)`,
  "m-gradient-80-hover": `linear-gradient(44.65deg, ${colors["m-orange-80"]} 33.05%, ${colors["m-magenta-80"]} 97.28%)`,
  "m-lock-gradient": `linear-gradient(180deg, ${colors["m-transparent"]} 0%, ${colors["m-white-90"]} 15%, #FFFFFF 100%)`,
  "m-gradient-green-blue": `linear-gradient(91.36deg, ${colors["m-arctic"]} 1.16%, #068871 99.23%);`,
  "m-white-to-gradient": `linear-gradient(270deg, ${colors["m-orange"]} 20%, ${colors["m-magenta"]} 40%, ${colors["m-white"]} 60%);`,
  "m-grey-gradient": `linear-gradient(265deg, ${colors["m-dark-grey"]} 8.24%, #36454C 128.28%);`,
};

// need remove this config after refactoring
const spacingV1 = {
  "mv-0": "8px",
  "mv-1": "10px",
  "mv-2": "12px",
  "mv-3": "16px",
  "mv-4": "24px",
  "mv-5": "32px",
  "mv-6": "48px",
  "mv-7": "52px",
  "mv-8": "64px",
  "mv-9": "72px",
  "mv-10": "96px",
};

// need remove this config after refactoring
const spacingV2 = {
  "mn-4": "4px",
  "mn-10": "10px",
  "mn-16": "16px",
  "mn-20": "20px",
  "mn-26": "26px",
  "mn-30": "30px",
  "mn-38": "38px",
  "mn-50": "50px",
  "mn-74": "74px",
  "mn-111": "111px",
  "mn-150": "150px",
};

// ui-kit actual spacing
const spacingV3 = {
  "m-0": "4px",
  "m-1": "10px",
  "m-2": "16px",
  "m-3": "20px",
  "m-4": "26px",
  "m-5": "30px",
  "m-6": "38px",
  "m-7": "50px",
  "m-8": "80px",
  "m-9": "100px",
  "m-10": "150px",
};

// remove after refactoring
const grid = {
  "grid-sm": "343px",
  "grid-md": "600px",
  "grid-xl": "1190px",
  "grid-2xl": "1408px",
  "grid-3xl": "1623px",
  "grid-lg": "928px",
};

const spacing = {
  ...grid,
  ...spacingV2,
  ...spacingV3,
  ...spacingV1,
};

const fontFamily = {
  "sans": ["SB Sans Text", "sans-serif"],
  "m-sb-display": ["SB Sans Display", "sans-serif"],
  "m-sb-interface": ["SB Sans Interface", "sans-serif"],
  "m-playfair": ["PlayfairDisplay", "serif"],
  "m-playfair-italic": ["PlayfairItalic", "serif"],
  "m-montserrat": ["MontserratRegular", "sans-serif"],
};

const backgroundSize = { ...spacing, "size-200": "200% auto" };

const getFontSizeKit = (typographyName, screenSizeMax) => {
  const sizes = {
    h1: {
      1920: ["clamp(4.5rem, 5.7vw, 6.75rem)", "100%"], // 72px to 108px
      1280: ["clamp(2.25rem, 6.6vw, 4.5rem)", "100%"], // 36px to 72px
    },
    h2: {
      1920: ["clamp(3.4125rem, 4.26vw, 5.125rem)", "100%"], // 54px to 82px
      1280: ["clamp(1.75rem, 4.73vw, 3.4125rem)", "100%"], // 28px to 54px
    },
    h3: {
      1920: ["clamp(2.625rem, 3.4vw, 4rem)", "120%"], // 42px to 64px
      1280: ["clamp(1.5rem, 3.8vw, 2.625rem)", "120%"], // 24px to 42px
    },
    h4: {
      1920: ["clamp(2rem, 2.5vw, 3rem)", "120%"], // 32px to 48px
      1280: ["clamp(1.25rem, 3.6vw, 2rem)", "120%"], // 20px to 32px
    },
    h5: {
      1920: ["clamp(1.25rem, 1.6vw, 1.875rem)", "120%"], // 20px to 30px
      1280: ["clamp(1rem, 2.6vw, 1.25rem)", "120%"], // 16px to 20px
    },
    h6: {
      1920: ["clamp(1.125rem, 1.2vw, 1.25rem)", "120%"], // 18px to 20px,
      1280: ["clamp(0.875rem, 1.9vw, 1.125rem)", "120%"], // 14px to 18px
    },
    text: {
      1920: ["clamp(1.125rem, 1.3vw, 1.5rem)", "130%"], // 18px to 24px
      1280: ["clamp(0.875rem, 2vw, 1.125rem)", "130%"], // 14px to 18px
    },
    label: {
      1920: ["clamp(0.75rem, 0.95vw, 1.125rem)", "100%"], // 12px to 18px
      1280: ["clamp(0.625rem, 1vw, 0.75rem)", "100%"], // 10px to 12px
    },
    lead: {
      1920: ["clamp(1.5rem, 1.7vw, 2rem)", "100%"], // 24px to 32px
      1280: ["clamp(1.125rem, 2vw, 1.5rem)", "100%"], // 18px to 24px
    },
    caption: {
      1920: ["clamp(0.75rem, 0.9vw, 0.875rem)", "120%"], // 12px to 14px
      1280: ["clamp(0.625rem, 1vw, 0.75rem)", "120%"], // 10px to 12px
    },
    input: {
      1920: ["clamp(1rem, 1.2vw, 1.375rem)", "120%"], // 16px to 22px,
      1280: ["clamp(0.75rem, 1.66vw, 1rem)", "120%"], // 12px to 16px,
    },
    textSmall: {
      1920: ["clamp(0.875rem, 1.1vw, 1.25rem)", "130%"], // 14px to 20px,
      1280: ["clamp(0.75rem, 2vw, 0.875rem)", "130%"], // 12px to 14px,
    },
  };

  return sizes[typographyName][screenSizeMax];
};

const fontSize = {
  "m-8xl": "4.5rem", // 72px
  "m-7xl": ["3.5rem", "65px"], // 56px
  "m-6xl": ["2.625rem", "2.625rem"], // 42px
  "m-5xl": ["2rem", "46px"], // 32px
  "m-3xl": ["1.5rem", "1.5rem"], // 24px
  "m-2xl": "1.375rem", // 22px
  "m-xl": ["1.25rem", "28px"], // 20px
  "m-xl-base": ["1.25rem", "1.5rem"], // 20px
  "m-lg": ["1.125rem", "23px"], // 18px
  "m-base": ["1rem", "24px"], // 16px
  "m-base-sm": ["1rem", "19.2px"], // 16px
  "m-sm": ["0.875rem", "20px"], // 14px
  "m-xs": ["0.75rem", "14px"], // 12px
  "m-2xs": ["0.625rem", "12px"], // 10px

  "m-h1-1920": getFontSizeKit("h1", 1920),
  "m-h1-1280": getFontSizeKit("h1", 1280),

  "m-h2-1920": getFontSizeKit("h2", 1920),
  "m-h2-1280": getFontSizeKit("h2", 1280),

  "m-h3-1920": getFontSizeKit("h3", 1920),
  "m-h3-1280": getFontSizeKit("h3", 1280),

  "m-h4-1920": getFontSizeKit("h4", 1920),
  "m-h4-1280": getFontSizeKit("h4", 1280),

  "m-h5-1920": getFontSizeKit("h5", 1920),
  "m-h5-1280": getFontSizeKit("h5", 1280),

  "m-h6-1920": getFontSizeKit("h6", 1920),
  "m-h6-1280": getFontSizeKit("h6", 1280),

  "m-text-1920": getFontSizeKit("text", 1920),
  "m-text-1280": getFontSizeKit("text", 1280),

  "m-label-1920": getFontSizeKit("label", 1920),
  "m-label-1280": getFontSizeKit("label", 1280),

  "m-lead-1920": getFontSizeKit("lead", 1920),
  "m-lead-1280": getFontSizeKit("lead", 1280),

  "m-caption-1920": getFontSizeKit("caption", 1920),
  "m-caption-1280": getFontSizeKit("caption", 1280),

  "m-input-1920": getFontSizeKit("input", 1920),
  "m-input-1280": getFontSizeKit("input", 1280),

  "m-text-small-1920": getFontSizeKit("textSmall", 1920),
  "m-text-small-1280": getFontSizeKit("textSmall", 1280),
};

const screens = {
  "3xl": "1900px",
  "2xl": "1536px",
  "xl": "1280px",
  "lg": "1024px",
  "md": "767px",
  "sm": "640px",
  "xs": "375px",
};

const backgroundPosition = {
  "top-center": "top center",
  "right-center": "right center",
};

const minHeight = {
  "header-mobile-height": "var(--header-mobile-height)",
  "header-desktop-height": "var(--header-desktop-height)",
  "footer-desktop-height": "var(--footer-desktop-height)",
};

const container = {
  center: true,
  screens: {
    sm: "100%",
    md: "100%",
    lg: "1280px",
    xl: "1408px",
  },
};

const boxShadow = {
  primary: "0px 30px 30px rgba(39, 34, 100, 0.6)",
  blue: "0px 42px 36px rgba(25, 21, 82, 0.8)",
};

const dropShadow = {
  primary: "0px 20px 48px rgba(27, 26, 70, 0.47)",
};

module.exports = {
  colors: {
    ...colors,
    ...themeColor,
  },
  linearBorderGradients,
  backgroundImage,
  spacing,
  fontFamily,
  fontSize,
  screens,
  backgroundPosition,
  backgroundSize,
  minHeight,
  container,
  boxShadow,
  dropShadow,
};
