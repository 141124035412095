import { paths } from "@/pages/paths";

import { OurProgramsList } from "@/widgets/our-programs/ui/our-programs";

import { Typography, Image, Container, ButtonRounded } from "@/shared/ui";
import arrow from "@/shared/ui/assets/images/icons/arrow-direction.svg";

export const OurPrograms = () => {
  return (
    <div id="dpo-programs">
      <Container mode="fluid" className="px-mn-20 md:px-mn-26 3xl:px-mn-38 pt-mn-50 3xl:pt-mn-74">
        <a href={paths.catalogProgram()} className="container-with-arrow" target="_self">
          <div className="mb-mn-56 md:mb-mn-50 3xl:mb-mn-74 pr-5">
            <div className="text-m-white flex justify-between mb-mn-20 3xl:mb-mn-26 items-center">
              <Typography tag="h2" size="m-h2" className="uppercase font-bold">
                Наши программы
              </Typography>
              <Image
                src={arrow}
                alt=""
                className="hidden xl:block w-[6.4vw] arrow-right-animation"
              />
            </div>
            <Typography
              tag="h3"
              size="m-lead"
              className="font-semibold mb-mn-16 md:mb-mn-20 font-m-sb-display"
            >
              Освоить инструменты, сформировать портфолио и&nbsp;войти
              <br />
              в&nbsp;профессию под руководством лучших экспертов-практиков
            </Typography>
            <ButtonRounded className="block xl:hidden" variant="secondary">
              ВСЕ ПРОГРАММЫ
            </ButtonRounded>
          </div>
        </a>
        <OurProgramsList />
      </Container>
    </div>
  );
};
