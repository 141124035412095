import { combine, createEvent, createStore, sample } from "effector";
import { createGate } from "effector-react";

import { CoursePayInfo } from "@app/api";

import { paymentModel, SubscriptionPayInfo, PaymentType } from "@/entities/payment";
import { SpeakerEntity } from "@/entities/speaker";
import {
  $hasAuthenticatedUserId,
  $isUserDataLoaded,
  getUserFx,
  UserProductsEntity,
} from "@/entities/user";
import { userPurchasesModel } from "@/entities/user-purchases";

import { authModalCloseClicked } from "@/app/authorization-modals/model";
import { openSignUpModal } from "@/feature/sign-up";

const not = (is: boolean) => !is;
const everyFalsy = (data: boolean[]) => data.every(not);

const shouldAccessCourseWithEdPlus = (
  hasEdPlusSubscription: boolean,
  selectedCourse: CoursePayInfo,
) => (hasEdPlusSubscription ? selectedCourse.includedInSberEd : false);

export const canBuySelectedCourse = ({
  boughtCourses,
  boughtEvents,
  selectedCourse,
  hasMuseSubscription,
  hasEdPlusSubscription,
}: {
  boughtCourses: UserProductsEntity;
  boughtEvents: UserProductsEntity;
  hasMuseSubscription: boolean;
  selectedCourse: CoursePayInfo | null;
  hasEdPlusSubscription: boolean;
}): boolean => {
  if (!selectedCourse) return false;

  const foundCourse = boughtCourses.find((course) => course.id === selectedCourse.platformId);
  const foundEvent = boughtEvents.find((course) => course.id === selectedCourse.platformId);

  return everyFalsy([
    Boolean(foundEvent),
    Boolean(foundCourse),
    hasMuseSubscription,
    shouldAccessCourseWithEdPlus(hasEdPlusSubscription, selectedCourse),
  ]);
};

export enum PaymentModalType {
  Subscription = "subscription",
  Buy = "buy",
  Pay = "pay",
  Close = "",
}

export const buyEventClicked = createEvent();
export const paymentModalOpened = createEvent<PaymentModalType>();
export const paymentModalCloseClicked = createEvent();

export const speakerReset = createEvent();
export const loadSpeakerForPayment = createEvent<SpeakerEntity>();

export const $aboutSpeaker = createStore<string>("")
  .on(loadSpeakerForPayment, (_, { name, surname, shortDescription }) =>
    [name, surname, shortDescription].every(Boolean)
      ? `${name} ${surname}, ${shortDescription}`
      : "",
  )
  .reset(speakerReset);

export const $paymentChooseModal = createStore<PaymentModalType>(PaymentModalType.Close).on(
  paymentModalOpened,
  (_, modalType) => {
    return Object.values(PaymentModalType).includes(modalType) ? modalType : PaymentModalType.Close;
  },
);

export const subscriptionChosen = createEvent<SubscriptionPayInfo>();
export const courseChosen = createEvent();

export const SubscriptionsGate = createGate();

const $paymentInfo = combine({
  selectedSubscription: paymentModel.$selectedSubscription,
  selectedCourse: paymentModel.$coursePayment,
  hasSubscription: userPurchasesModel.$hasUserMuseSubscription,
  boughtCourses: userPurchasesModel.$boughtCourses,
  boughtEvents: userPurchasesModel.$boughtEvents,
  isAuthenticated: $hasAuthenticatedUserId,
  paymentType: paymentModel.$paymentType,
  hasEdPlusSubscription: userPurchasesModel.$hasUserEdPlusSubscription,
});

sample({
  clock: paymentModalCloseClicked,
  fn: () => PaymentModalType.Close,
  target: paymentModalOpened,
});

sample({
  clock: SubscriptionsGate.open,
  target: paymentModel.subscriptionInfoLoaded,
});

sample({
  clock: subscriptionChosen,
  fn: () => PaymentType.Subscription,
  target: paymentModel.paymentTypeChanged,
});

sample({
  clock: courseChosen,
  fn: () => PaymentType.Course,
  target: paymentModel.paymentTypeChanged,
});

sample({
  clock: subscriptionChosen,
  target: paymentModel.readyToSubscriptionPay,
});

sample({
  clock: [subscriptionChosen, courseChosen],
  source: $paymentInfo,
  fn: ({ isAuthenticated }) => (isAuthenticated ? PaymentModalType.Pay : PaymentModalType.Close),
  target: paymentModalOpened,
});

sample({
  clock: [subscriptionChosen, courseChosen],
  source: $paymentInfo,
  filter: ({ isAuthenticated }) => not(isAuthenticated),
  fn: () => true,
  target: openSignUpModal,
});

sample({
  clock: [authModalCloseClicked, paymentModalCloseClicked],
  source: $paymentInfo,
  target: [paymentModel.subscriptionPayReset, paymentModel.paymentTypeReset],
});

sample({
  clock: getUserFx.done,
  source: $paymentInfo,
  filter: (info) => info.paymentType === PaymentType.Subscription && not(info.hasSubscription),
  fn: () => PaymentModalType.Pay,
  target: paymentModalOpened,
});

sample({
  clock: $isUserDataLoaded,
  source: $paymentInfo,
  filter: (info, isLoaded) => {
    return (
      isLoaded &&
      info.paymentType === PaymentType.Course &&
      canBuySelectedCourse({
        hasMuseSubscription: info.hasSubscription,
        boughtCourses: info.boughtCourses,
        selectedCourse: info.selectedCourse,
        boughtEvents: info.boughtEvents,
        hasEdPlusSubscription: info.hasEdPlusSubscription,
      })
    );
  },
  fn: () => PaymentModalType.Pay,
  target: paymentModalOpened,
});

sample({
  clock: buyEventClicked,
  fn: () => PaymentModalType.Buy,
  target: paymentModalOpened,
});
