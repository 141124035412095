import { createEffect, createEvent, sample } from "effector";

import { MindboxApi } from "@/shared/api";

export type FormSubscribePayload = {
  name: string;
  surname: string;
  email: string;
  agreement: boolean;
};

export const formSubscribeSubmitted = createEvent<FormSubscribePayload>();

const sendSubscribeToMindboxFx = createEffect(
  async (params: Omit<FormSubscribePayload, "agreement">) => {
    await MindboxApi.sendSubscribeDataToMindbox(params);
  },
);

sample({
  clock: formSubscribeSubmitted,
  fn: (subscribeFormData) => ({
    name: subscribeFormData.name,
    email: subscribeFormData.email,
    surname: subscribeFormData.surname,
  }),
  target: sendSubscribeToMindboxFx,
});
