import { useEvent, useStore } from "effector-react/scope";
import { FC } from "react";

import { removeSpaces } from "@/shared/lib/string";
import { Input, Modal, ButtonRounded } from "@/shared/ui";

import { authModalCloseClicked } from "@/app/authorization-modals/model";

import {
  $passwordFormErrors,
  $profilePasswordValue,
  profilePasswordChanged,
  profilePasswordConfirmClicked,
} from "../model/password-change";

export const ProfilePasswordChange: FC = () => {
  const profilePasswordValue = useStore($profilePasswordValue);
  const errorMessage = useStore($passwordFormErrors);
  const isError = Boolean(errorMessage);

  const handlers = useEvent({
    profilePasswordChanged,
    profilePasswordConfirmClicked,
    close: authModalCloseClicked,
  });

  return (
    <Modal.Surface>
      <Modal.Header close={handlers.close} />
      <Modal.Body>
        <Modal.Title>Введите старый пароль</Modal.Title>
        <Input.Password
          placeholder="Введите пароль"
          value={profilePasswordValue}
          onChange={(e) => handlers.profilePasswordChanged(removeSpaces(e.target.value))}
          isError={isError}
          hasShowButton
          size="small"
          maxLength={50}
        />
        <div className="min-h-[18px]">
          {isError && (
            <Input.Description className="m-typography-modal-caption" isError>
              {errorMessage}
            </Input.Description>
          )}
        </div>
        <ButtonRounded
          className="mt-6 mb-10 block m-auto"
          disabled={!profilePasswordValue}
          onClick={handlers.profilePasswordConfirmClicked}
        >
          Отправить
        </ButtonRounded>
      </Modal.Body>
    </Modal.Surface>
  );
};
